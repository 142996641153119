import { contentTranslator } from "../../utils/translatorUtils";
import ContentTranslator from "../translator/ContentTranslator";
import FormAddAction from "./FormAddAction";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { UncontrolledTooltip } from "reactstrap";

const buttonActionPrimaryStyle = {
    height: "32px",
    width: "80px",
};

class TableBodyActionPlan extends React.Component {
    static display = TableBodyActionPlan.name;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showPopup: false,
            showActionForm: false,
        };

        this.handleAddActionClick = this.handleAddActionClick.bind(this);
        this.handleActionfinish = this.handleActionfinish.bind(this);
        this.handleActionPlanAdded = this.handleActionPlanAdded.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.showHiddenSkills !== this.props.showHiddenSkills) {
            this.setState({ showActionForm: false });
        }
    }

    handleAddActionClick = () => {
        this.setState({
            showActionForm: !this.state.showActionForm,
        });
    };

    handleActionfinish = () => {
        this.setState({
            showActionForm: false,
        });
    };

    togleDescriptionModal() {
        const showPopup = this.state.showPopup;
        this.setState({ showPopup: !showPopup });
    }

    handleActionPlanAdded(addedActionPlans) {
        this.setState({
            showActionForm: false,
        });

        this.props.handleActionPlanAdded(addedActionPlans);
    }

    render() {
        const { showAll, levelIndex, skill, level } = this.props;
        const selectedDefaultSkill = {
            skillName: skill.name,
            skillCode: skill.skillsCode,
            skillLevel: level.level,
            skillType: "Skill",
        };

        return (
            <React.Fragment key={levelIndex}>
                <tr style={{ display: !this.props.skillHidden ? "" : "none" }}>
                    <td className={"pt-1 pb-1 border-0"}></td>
                    <td className={"pt-1 border-0"}>{(showAll ? levelIndex === 0 : level.skillNameShown) && `${this.contentTranslator(this.props.contentTranslations, "Skills", skill.name, skill.name)} (${skill.skillsCode})`}</td>
                    <td colSpan="2" className={"pt-1 pb-1 td-item "}>
                        {`${skill.skillsCode}${level.level} - `}
                        <label
                            style={{ cursor: "pointer", color: "#212529", textDecoration: "underline" }}
                            onClick={() =>
                                this.props.openSkillLevelDescriptionModal({
                                    skillsCode: skill.skillsCode,
                                    level: level.level,
                                    description: this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${skill.skillsCode} ${level.level} Description`, level.description),
                                })
                            }
                        >
                            <ContentTranslator page="SfiaPlan" name="SfiaPlanViewDescription" contentText="View Description" />
                        </label>
                    </td>
                    <td className={"pt-1 pb-1 td-item "}>
                        <div
                            id={`DivGapMark${skill.id}${level.id}`}
                            className={
                                "mx-auto mt-2 " +
                                (level.jobSkillProfile
                                    ? level.skillProfile && level.skillProfile.stringVal == "M"
                                        ? "rounded-circle circleMark"
                                        : level.action
                                        ? "rounded-circle circleMark-green"
                                        : level.skillProfile && level.skillProfile.stringVal == "P"
                                        ? "circleMark-amber"
                                        : level.skillProfile && level.skillProfile.stringVal == "NA"
                                        ? "rounded-circle circleMark-purple"
                                        : level.jobSkillProfile
                                        ? "rounded-circle circleMark-red"
                                        : "rounded-circle circleMark"
                                    : "rounded-circle circleMark")
                            }
                        ></div>
                        <UncontrolledTooltip target={`DivGapMark${skill.id}${level.id}`} placement="bottom">
                            {level.jobSkillProfile ? (
                                level.skillProfile && level.skillProfile.stringVal == "M" ? (
                                    ""
                                ) : level.action ? (
                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanTooltipActioned" contentText="You have one or more actions to address this gap" />
                                ) : level.skillProfile && level.skillProfile.stringVal == "P" ? (
                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanTooltipProficiencySkill" contentText="You have a partial skill gap (Proficiency) at this level and no action to address it" />
                                ) : level.skillProfile && level.skillProfile.stringVal == "NA" ? (
                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanTooltipKnowledgeSkill" contentText="You have a partial skill gap (Knowledge) at this level and no action to address it" />
                                ) : level.jobSkillProfile ? (
                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanTooltipNoSkill" contentText="You do not have this skill at this level and no action to address it" />
                                ) : (
                                    ""
                                )
                            ) : (
                                ""
                            )}
                        </UncontrolledTooltip>
                    </td>
                    <td className={"pt-1 pb-1 td-item "}>
                        <div id={`DivReqMark${skill.id}${level.id}`} className={"rounded-circle mx-auto mt-2 " + (level.jobSkillProfile ? (level.jobSkillProfile.stringVal == "M" ? "circleMark-red" : "circleMark-amber") : "circleMark")}></div>
                        <UncontrolledTooltip target={`DivReqMark${skill.id}${level.id}`} placement="bottom">
                            {level.jobSkillProfile ? (
                                level.jobSkillProfile.stringVal == "M" ? (
                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanTooltipRequiredSkill" contentText="This is a Required skill at this level" />
                                ) : (
                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanTooltipDesirableSkill" contentText="This is a Desirable skill at this level" />
                                )
                            ) : (
                                ""
                            )}
                        </UncontrolledTooltip>
                    </td>
                    <td className={"pt-1 pb-1 td-item "}>
                        {!this.state.showActionForm ? (
                            <button className={`btn btn-primary ${!this.props.allowUpdate ? "btnDisabled-Style" : level.action ? "btnAmber-Style" : "btnPrimary-Style"}`} style={buttonActionPrimaryStyle} onClick={this.handleAddActionClick} disabled={!this.props.allowUpdate}>
                                <FontAwesomeIcon icon={faPlus} style={{ fontSize: "13px" }} />
                            </button>
                        ) : (
                            <button className={`btn btn-secondary`} style={buttonActionPrimaryStyle} onClick={this.handleAddActionClick} disabled={!this.props.allowUpdate}>
                                <FontAwesomeIcon icon={faMinus} style={{ fontSize: "13px", position: "relative", top: "-5px" }} />
                            </button>
                        )}
                    </td>
                </tr>
                {this.state.showActionForm && (
                    <tr>
                        <td className="border-0"></td>
                        <td className="border-0" colSpan="6">
                            <FormAddAction
                                showHiddenSkills={this.props.showHiddenSkills}
                                actionPlanId={level.id}
                                optionsActionTypes={this.props.optionsActionTypes}
                                optionsActionSkills={this.props.optionsActionSkills}
                                jobLorProfiles={this.props.jobLorProfiles}
                                jobSkillProfiles={this.props.jobSkillProfiles}
                                lorProfiles={this.props.lorProfiles}
                                skillProfiles={this.props.skillProfiles}
                                handleClose={this.handleActionfinish}
                                handleActionPlanAdded={this.handleActionPlanAdded}
                                providers={this.props.providers}
                                selectedDefaultSkill={selectedDefaultSkill}
                                optionsJob={this.props.optionsJob}
                                categories={this.props.categories}
                                generateAlert={this.props.generateAlert}
                            ></FormAddAction>
                        </td>
                    </tr>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contentTranslations: state.content.contentTranslations,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TableBodyActionPlan);
