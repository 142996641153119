import AdminContentAPI from "./../../../common/api/adminservice/AdminContentService";
import * as actionTypes from "./adminActionTypes";

export const getContentPagesStart = () => {
    return {
        type: actionTypes.GET_CONTENT_PAGES_START,
    };
};

export const getContentPagesSuccess = (pages) => {
    return {
        type: actionTypes.GET_CONTENT_PAGES_SUCCESS,
        pages,
    };
};

export const getContentPagesFail = (error) => {
    return {
        type: actionTypes.GET_CONTENT_PAGES_FAIL,
        error: error,
    };
};

export const getContentPages = () => {
    return (dispatch) => {
        dispatch(getContentPagesStart());

        return AdminContentAPI.getContentPages()
            .then((res) => {
                dispatch(getContentPagesSuccess(res));
            })
            .catch((err) => {
                dispatch(getContentPagesFail(err));
            });
    };
};

export const getContentsStart = () => {
    return {
        type: actionTypes.GET_CONTENTS_START,
    };
};

export const getContentsSuccess = (contents) => {
    return {
        type: actionTypes.GET_CONTENTS_SUCCESS,
        contents,
    };
};

export const getContentsFail = (error) => {
    return {
        type: actionTypes.GET_CONTENTS_FAIL,
        error: error,
    };
};

export const getContents = (page, languageId) => {
    return (dispatch) => {
        dispatch(getContentsStart());

        return AdminContentAPI.getContents(page, languageId)
            .then((res) => {
                dispatch(getContentsSuccess(res));
            })
            .catch((err) => {
                dispatch(getContentsFail(err));
            });
    };
};

export const getContentTranslationsStart = () => {
    return {
        type: actionTypes.GET_CONTENT_TRANSLATIONS_START,
    };
};

export const getContentTranslationsSuccess = (translations) => {
    return {
        type: actionTypes.GET_CONTENT_TRANSLATIONS_SUCCESS,
        translations,
    };
};

export const getContentTranslationsFail = (error) => {
    return {
        type: actionTypes.GET_CONTENT_TRANSLATIONS_FAIL,
        error: error,
    };
};

export const getContentTranslations = (contentId) => {
    return (dispatch) => {
        dispatch(getContentTranslationsStart());

        return AdminContentAPI.getContentTranslations(contentId)
            .then((res) => {
                dispatch(getContentTranslationsSuccess(res));
            })
            .catch((err) => {
                dispatch(getContentTranslationsSuccess(err));
            });
    };
};

export const createContentTranslationStart = () => {
    return {
        type: actionTypes.CREATE_CONTENT_TRANSLATION_START,
    };
};

export const createContentTranslationSuccess = (addedContent) => {
    return {
        type: actionTypes.CREATE_CONTENT_TRANSLATION_SUCCESS,
        addedContent,
    };
};

export const createContentTranslationFail = (error) => {
    return {
        type: actionTypes.CREATE_CONTENT_TRANSLATION_FAIL,
        error: error,
    };
};

export const createContentTranslation = (content) => {
    return (dispatch) => {
        dispatch(createContentTranslationStart());

        return AdminContentAPI.createContent(content)
            .then((res) => {
                dispatch(createContentTranslationSuccess(res));
            })
            .catch((err) => {
                dispatch(createContentTranslationFail(err));
            });
    };
};

export const updateContentTranslationStart = () => {
    return {
        type: actionTypes.UPDATE_CONTENT_TRANSLATION_START,
    };
};

export const updateContentTranslationSuccess = (updatedContent) => {
    return {
        type: actionTypes.UPDATE_CONTENT_TRANSLATION_SUCCESS,
        updatedContent,
    };
};

export const updateContentTranslationFail = (error) => {
    return {
        type: actionTypes.UPDATE_CONTENT_TRANSLATION_FAIL,
        error: error,
    };
};

export const updateContentTranslation = (content) => {
    return (dispatch) => {
        dispatch(updateContentTranslationStart());

        return AdminContentAPI.updateContent(content)
            .then((res) => {
                dispatch(updateContentTranslationSuccess(res));
            })
            .catch((err) => {
                dispatch(updateContentTranslationFail(err));
            });
    };
};

export const exportContentTranslationsExcelStart = () => {
    return {
        type: actionTypes.EXPORT_CONTENT_TRANSLATIONS_START,
    };
};

export const exportContentTranslationsExcelSuccess = (printData) => {
    return {
        type: actionTypes.EXPORT_CONTENT_TRANSLATIONS_SUCCESS,
        printData,
    };
};

export const exportContentTranslationsExcelFail = (error) => {
    return {
        type: actionTypes.EXPORT_CONTENT_TRANSLATIONS_FAIL,
        error: error,
    };
};

export const exportContentTranslationsExcel = (languageId) => {
    return (dispatch) => {
        dispatch(exportContentTranslationsExcelStart());

        return AdminContentAPI.exportContentTranslationsExcel(languageId)
            .then((res) => {
                dispatch(exportContentTranslationsExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportContentTranslationsExcelFail(err));
            });
    };
};

export const exportGroupValueTranslationsExcel = (languageId) => {
    return (dispatch) => {
        dispatch(exportContentTranslationsExcelStart());

        return AdminContentAPI.exportGroupValueTranslationsExcel(languageId)
            .then((res) => {
                dispatch(exportContentTranslationsExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportContentTranslationsExcelFail(err));
            });
    };
};

export const importContentTranslationsExcelStart = () => {
    return {
        type: actionTypes.IMPORT_CONTENT_TRANSLATIONS_START,
    };
};

export const importContentTranslationsExcelSuccess = () => {
    return {
        type: actionTypes.IMPORT_CONTENT_TRANSLATIONS_SUCCESS,
    };
};

export const importContentTranslationsExcelFail = (error) => {
    return {
        type: actionTypes.IMPORT_CONTENT_TRANSLATIONS_FAIL,
        error: error,
    };
};

export const importContentTranslationsExcel = (languageId, translationsDataFiles) => {
    return (dispatch) => {
        dispatch(importContentTranslationsExcelStart());

        return AdminContentAPI.importContentTranslationsExcel(languageId, translationsDataFiles)
            .then((res) => {
                dispatch(importContentTranslationsExcelSuccess());
            })
            .catch((err) => {
                dispatch(importContentTranslationsExcelFail(err));
            });
    };
};

export const importGroupValueTranslationsExcel = (languageId, translationsDataFiles) => {
    return (dispatch) => {
        dispatch(importContentTranslationsExcelStart());

        return AdminContentAPI.importGroupValueTranslationsExcel(languageId, translationsDataFiles)
            .then((res) => {
                dispatch(importContentTranslationsExcelSuccess());
            })
            .catch((err) => {
                dispatch(importContentTranslationsExcelFail(err));
            });
    };
};
