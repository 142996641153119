import { updateState } from "../../utility";
import * as actionTypes from "../actions/adminActionTypes";

const initialState = {
    error: null,
    loading: false,
    isUpdating: false,
    actionLogs: [],
    printData: null,
    loadingPrint: false,
};

const getActionLogsStart = (state) => {
    return updateState(state, { error: null, loading: true, actionLogs: [] });
};

const getActionLogsSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        loading: false,
        actionLogs: action.actionLogs,
    });
};

const getActionLogsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const exportActionLogsExcelStart = (state) => {
    return updateState(state, { error: null, loadingPrint: true });
};

const exportActionLogsExcelSuccess = (state, action) => {
    return updateState(state, {
        printData: action.printData,
        error: null,
        loadingPrint: false,
    });
};

const exportActionLogsExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingPrint: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ACTION_LOGS_START:
            return getActionLogsStart(state);
        case actionTypes.GET_ACTION_LOGS_SUCCESS:
            return getActionLogsSuccess(state, action);
        case actionTypes.GET_ACTION_LOGS_FAIL:
            return getActionLogsFail(state, action);

        case actionTypes.EXPORT_ACTION_LOGS_START:
            return exportActionLogsExcelStart(state);
        case actionTypes.EXPORT_ACTION_LOGS_SUCCESS:
            return exportActionLogsExcelSuccess(state, action);
        case actionTypes.EXPORT_ACTION_LOGS_FAIL:
            return exportActionLogsExcelFail(state, action);
        default:
            return state;
    }
};

export default reducer;
