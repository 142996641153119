import ErrorHandler from "./../errorResponseHandler";
import Instance from "./../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminActionLogAPI {
    static getActionLogs(startDate, endDate) {
        return axios
            .get(`/actionLogs?startDate=${startDate}&endDate=${endDate}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static exportActionLogsExcel(request) {
        return axios
            .post(`/actionLogs/exportlogs`, request, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }
}

export default AdminActionLogAPI;
