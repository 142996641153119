import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class JobAPI {
    static getJobs(isSample) {
        return axios
            .get(`/jobs${isSample ? `?isSample=${isSample}` : ""}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getRoleBasedSurveyJobs() {
        return axios
            .get(`/jobs/rolebasedsurveyjobs`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getJobsWithAction() {
        return axios
            .get(`/jobs/withaction`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getOwnedJobs() {
        return axios
            .get(`/jobs/ownedjobs`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getJob(id) {
        return axios
            .get(`/jobs/${id}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static printJobProfile(id) {
        return axios
            .get(`/jobs/${id}/printprofiles`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static printJobProfiles(jobIds) {
        return axios
            .post(
                `/jobs/printjobreports`,
                { jobIds },
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static createJob(job) {
        return axios
            .post(`/jobs`, job)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateJob(id, job) {
        return axios
            .put(`/jobs/${id}`, job)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateJobNote(id, note) {
        return axios
            .put(`/jobs/${id}/updatenote`, { note })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteJob(id) {
        return axios
            .delete(`/jobs/${id}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static copyJob(request) {
        return axios
            .post(`/jobs/copyjob`, request)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static copyJobs(request) {
        return axios
            .post(`/jobs/copyjobs`, { copiedJobs: [...request] })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getJobProfileVersions(id) {
        return axios
            .get(`/jobs/jobprofileversions?jobId=${id}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getMyJobMatchTypes() {
        return axios
            .get(`/jobs/myjobmatchtypes`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getMyJobMatches() {
        return axios
            .get(`/jobs/myjobmatches`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getManagedPeopleJobMatches(email) {
        return axios
            .get(`/jobs/managedPeopleJobmatches?email=${email}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateMyJobMatches(data) {
        return axios
            .post(`/jobs/myjobmatches/update`, data)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateManagedPeopleJobMatches(data) {
        return axios
            .post(`/jobs/managedPeopleJobmatches/update`, data)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getJobLorProfiles(jobId, sfiaVersion, version) {
        return axios
            .get(`/jobs/joblorprofiles?jobId=${jobId}${sfiaVersion ? `&sfiaVersion=${sfiaVersion}` : ""}${version ? `&version=${version}` : ""}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getManagedPeopleJobLorProfiles(email) {
        return axios
            .get(`/jobs/managedPeopleJoblorprofiles?email=${email}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getJobSkillProfiles(jobId, sfiaVersion, version) {
        return axios
            .get(`/jobs/jobskillprofiles?jobId=${jobId}${sfiaVersion ? `&sfiaVersion=${sfiaVersion}` : ""}${version ? `&version=${version}` : ""}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getManagedPeopleJobSkillProfiles(email) {
        return axios
            .get(`/jobs/managedPeopleJobskillprofiles?email=${email}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getJobSkillAttributes(jobId) {
        return axios
            .get(`/jobs/jobskillattributes?jobId=${jobId}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateJobProfiles(jobId, updatedProfiles) {
        const request = {
            lorProfileRequests: updatedProfiles.filter((it) => it.source == "lorProfiles"),
            skillProfileRequests: updatedProfiles.filter((it) => it.source == "skillProfiles"),
        };

        return axios
            .put(`/jobs/${jobId}/jobprofiles`, request)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateJobSkillAttributes(jobId, attributes) {
        const request = {
            attributes,
        };

        return axios
            .put(`/jobs/${jobId}/updateattributes`, request)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static exportJobsDataExcel() {
        return axios
            .get(`/jobs/exportjobs`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static exportJobsDataExcelTemplate() {
        return axios
            .get(`/jobs/exportjobstemplate`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static getJobImportQueue() {
        return axios
            .get(`/jobs/jobimportqueue`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static downloadJobImportExcel(id) {
        return axios
            .get(`/jobs/jobimport/${id}/download`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static importJobsDataExcel(sessionId, operation, jobsDataFiles) {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        const body = new FormData();
        body.append("ContentDisposition", "attachment");
        body.append("ContentType", "multipart/form-data");

        jobsDataFiles.forEach((file) => {
            body.append("jobsData", file);
        });

        body.append("sessionId", sessionId);
        body.append("operation", operation);

        return axios
            .post(`/jobs/requestimportjobs`, body, config)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static processJobImportQueue(jobImportIds) {
        return axios
            .post(`/jobs/processimportjobs`, { jobImportIds })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getJobMatchPersons() {
        return axios
            .get(`/jobs/jobmatchpersons`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static assignjobmatchperson(personId, jobId, jobMatchType) {
        return axios
            .post(`/jobs/assignjobmatchperson`, {
                personId,
                jobId,
                jobMatchType,
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static exportJobMatchesDataExcel() {
        return axios
            .get(`/jobs/exportjobMatches`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static exportJobMatchesDataExcelTemplate() {
        return axios
            .get(`/jobs/exportjobMatchestemplate`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static importJobmatchessDataExcel(operation, jobMatchesData) {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        const body = new FormData();
        body.append("ContentDisposition", "attachment");
        body.append("ContentType", "multipart/form-data");

        jobMatchesData.forEach((file) => {
            body.append("jobMatchesData", file);
        });

        body.append("operation", operation);

        return axios
            .post(`/jobs/importjobmatches`, body, config)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getJobFamilies() {
        return axios
            .get(`/jobs/jobFamilies`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createJobFamily(jobFamily) {
        return axios
            .post(`/jobs/jobFamilies`, jobFamily)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateJobFamily(id, jobFamily) {
        return axios
            .put(`/jobs/jobFamilies/${id}`, jobFamily)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteJobFamily(id) {
        return axios
            .delete(`/jobs/jobFamilies/${id}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateJobFamilyAddEntries(id, addedJobIds) {
        return axios
            .post(`/jobs/jobFamilies/${id}/addEntries`, { addedJobIds })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateJobFamilyDeleteEntries(id, deletedJobIds) {
        return axios
            .post(`/jobs/jobFamilies/${id}/deleteEntries`, { deletedJobIds })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default JobAPI;
