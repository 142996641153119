import changeInput from "../../../utils/changeInput";
import React, { Component } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

class ManageProviderDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            provider: this.props.provider,
            form: null,
            isFormValid: this.props.provider ? true : false,
            isUpdating: false,
            optionSelected: [
                {
                    label: "No",
                    value: "No",
                },
                {
                    label: "Yes",
                    value: "Yes",
                },
                {
                    label: "Preferred",
                    value: "Preferred",
                },
            ],
            updateSuccess: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.provider !== this.props.provider) {
            this.setState({ provider: this.props.provider });
        }

        if (prevProps.isUpdating !== this.props.isUpdating) {
            this.setState({ isUpdating: this.props.isUpdating });
        }

        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
            this.prepareFormState();
        }

        if (prevProps.updateSuccess !== this.props.updateSuccess) {
            if (this.props.updateSuccess) {
                this.setState({ showDialog: false });
            }
        }
    }

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({
            showDialog: !showDialog,
        });
    };

    prepareFormState = () => {
        this.setState({
            form: {
                name: {
                    validation: {
                        required: true,
                    },
                    value: this.props.provider && this.props.provider.name ? this.props.provider.name : "",
                    valid: this.props.provider ? true : false,
                    isValidFormat: this.props.provider ? true : false,
                    touched: false,
                },
                website: {
                    validation: {
                        required: false,
                    },
                    value: this.props.provider && this.props.provider.website ? this.props.provider.website : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                country: {
                    validation: {
                        required: false,
                    },
                    value: this.props.provider && this.props.provider.country ? this.props.provider.country : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                isSelected: {
                    validation: {
                        required: false,
                        isBool: true,
                    },
                    value: this.props.provider && this.props.provider.isSelected ? this.props.provider.isSelected : false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                isPreferred: {
                    validation: {
                        required: false,
                        isBool: true,
                    },
                    value: this.props.provider && this.props.provider.isPreferred ? this.props.provider.isPreferred : false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: this.props.provider && this.props.provider.id ? true : false,
        });
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        if (name == "selected") {
            let isSelected = false;
            let isPreferred = false;

            if (value == "Preferred") {
                isSelected = true;
                isPreferred = true;
            } else if (value == "Yes") {
                isSelected = true;
            }

            let updateState = changeInput(this.state.form, "isSelected", isSelected);
            updateState = changeInput(updateState.form, "isPreferred", isPreferred);

            this.setState({
                form: updateState.form,
                isFormValid: updateState.valid,
            });
        } else {
            let updateState = changeInput(this.state.form, name, value);

            this.setState({
                form: updateState.form,
                isFormValid: updateState.valid,
            });
        }
    };

    handleSave = () => {
        const { form, provider } = this.state;

        provider.name = form.name.value;
        provider.website = form.website.value;
        provider.country = form.country.value;
        provider.isSelected = form.isSelected.value;
        provider.isPreferred = form.isPreferred.value;

        if (provider.id) {
            this.props.handleSaveUpdateProvider(provider);
        } else {
            this.props.handleSaveAddProvider(provider);
        }
    };

    render() {
        const { form, optionSelected } = this.state;

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={!this.state.isUpdating ? this.togleDialogModal : null} size="lg">
                    <ModalHeader toggle={!this.state.isUpdating ? this.togleDialogModal : null}>Training Provider Detail</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Name</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="name" id="name" value={form && form.name.value ? form.name.value : ""} invalid={form && form.name.touched && !form.name.valid} onChange={this.handleChange} disabled={this.state.isUpdating} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Website</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="website" id="website" value={form && form.website.value ? form.website.value : ""} invalid={form && form.website.touched && !form.website.valid} onChange={this.handleChange} disabled={this.state.isUpdating} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Country</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="country" id="country" value={form && form.country.value ? form.country.value : ""} invalid={form && form.country.touched && !form.country.valid} onChange={this.handleChange} disabled={this.state.isUpdating} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Selected</Label>
                                        <Col sm={8}>
                                            <Input type="select" name="selected" id="selected" value={form ? (form.isPreferred.value ? "Preferred" : form.isSelected.value ? "Yes" : "No") : "No"} invalid={false} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                {optionSelected.map((it, key) => (
                                                    <option key={key} value={it.value}>
                                                        {it.label}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <React.Fragment>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.handleSave();
                                }}
                                disabled={!this.state.isFormValid || this.state.isUpdating}
                            >
                                Save
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.togleDialogModal();
                                }}
                                disabled={this.state.isUpdating}
                            >
                                Cancel
                            </Button>
                        </React.Fragment>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ManageProviderDetail;
