import * as authActions from "../../../store/actions/authAction";
import * as contentActions from "../../../store/admin/actions/adminContentActions";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { generateRandomString } from "../../../utils/stringUtils";
import { contentTranslator } from "../../../utils/translatorUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import ContentDetail from "./ContentDetail";
import ContentTranslationBulkUpload from "./ContentTranslationBulkUpload";
import GroupValueTranslationBulkUpload from "./GroupValueTranslationBulkUpload";
import { faFileExcel, faPenToSquare, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Input, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

var _ = require("lodash");
const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const rowStyle = {
    fontSize: "12px",
};

class ManageContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            pages: [],
            selectedLanguage: null,
            selectedPage: null,
            contents: [],
            showDialogContentDetailTs: null,
            selectedContent: null,
            searchProperties: ["name", "contentText", "contentTextTranslation"],
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
        this.generateRandomString = generateRandomString.bind(this);
    }

    componentDidMount() {
        const languages = this.props.languages;
        const language = languages ? languages.find((it) => it.name == "English") : null;

        this.setState({ selectedLanguage: language ? language.id : null });

        this.props.onAuth.authCheckState().then(() => {
            this.initData(null, language ? language.id : null);
        });
    }

    handleRefresh = () => {
        this.initData(null, this.state.selectedLanguage ? this.state.selectedLanguage : null);
    };

    initData = (page, languageId) => {
        this.props.onContent.getContents(page, languageId).then(() => {
            const contents = this.props.contents;

            const pages = [];

            pages.push({
                value: null,
                label: "All Pages",
            });

            const groupedPages = _.groupBy(
                contents.map((it) => {
                    return it.page;
                }),
                function (it) {
                    return it;
                }
            );

            Object.keys(groupedPages).forEach((key) => {
                pages.push({
                    value: groupedPages[key][0],
                    label: groupedPages[key][0],
                });
            });

            const selectedPage = this.state.selectedPage ? this.state.selectedPage : pages.length > 0 ? pages.sort((a, b) => (`${a.value ? "1" : "0"}${a.label}` > `${b.value ? "1" : "0"}${b.label}` ? 1 : -1))[0].value : null;

            this.setState({
                pages: pages.sort((a, b) => (`${a.value ? "1" : "0"}${a.label}` > `${b.value ? "1" : "0"}${b.label}` ? 1 : -1)),
                selectedPage,
                contents: selectedPage ? this.props.contents.filter((it) => it.page == selectedPage).sort((a, b) => (`${a.page}${a.name}` > `${b.page}${b.name}` ? 1 : -1)) : this.props.contents.sort((a, b) => (`${a.page}${a.name}` > `${b.page}${b.name}` ? 1 : -1)),
            });

            this.setState({ loading: this.props.loading || this.props.contentsLoading });
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.contents !== this.props.contents) {
            const contents = this.props.contents;

            const pages = [
                {
                    value: null,
                    label: "All Pages",
                },
            ];

            const groupedPages = _.groupBy(
                contents.map((it) => {
                    return it.page;
                }),
                function (it) {
                    return it;
                }
            );

            Object.keys(groupedPages).forEach((key) => {
                pages.push({
                    value: groupedPages[key][0],
                    label: groupedPages[key][0],
                });
            });

            this.setState({
                contents: this.props.contents
                    ? this.state.selectedPage
                        ? this.props.contents.filter((it) => it.page == this.state.selectedPage).sort((a, b) => (`${a.page}${a.name}` > `${b.page}${b.name}` ? 1 : -1))
                        : this.props.contents.sort((a, b) => (`${a.page}${a.name}` > `${b.page}${b.name}` ? 1 : -1))
                    : [],
                pages: pages.sort((a, b) => (`${a.value ? "1" : "0"}${a.label}` > `${b.value ? "1" : "0"}${b.label}` ? 1 : -1)),
            });
        }
    }

    handleChangeLanguage = (event) => {
        const { value } = event.target;
        this.setState({ selectedLanguage: value });

        this.initData(null, value);
    };

    handleChangePage = (event) => {
        const { value } = event.target;
        const contents = this.props.contents;

        this.setState({ selectedPage: value != "All Pages" ? value : null, contents: contents.filter((it) => it.page == value || value == null || value == "All Pages").sort((a, b) => (`${a.page}${a.name}` > `${b.page}${b.name}` ? 1 : -1)) });
    };

    openContentDetail = (content) => {
        this.setState({ showDialogContentDetailTs: new Date(), selectedContent: content });
    };

    handleAddNew = () => {
        const { pages, selectedPage, selectedLanguage } = this.state;
        const { languages } = this.props;

        this.setState({
            showDialogContentDetailTs: new Date(),
            selectedContent: {
                page: selectedPage && selectedPage != "All Pages" ? selectedPage : pages && pages.length > 1 ? pages[1].value : null,
                languageId: selectedLanguage,
                language: languages.find((it) => it.id == selectedLanguage) ? languages.find((it) => it.id == selectedLanguage).name : null,
            },
        });
    };

    handleExportTranslations = () => {
        const { languages } = this.props;
        const { selectedLanguage } = this.state;

        const language = languages.find((it) => it.id == selectedLanguage);

        if (language) {
            this.props.onContent.exportContentTranslationsExcel(language.id).then(() => {
                if (!this.props.loadingPrint && !this.props.contentsError) {
                    if (this.props.printData) {
                        var today = new Date();
                        var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                        FileDownload(this.props.printData, `ExportTranslations_${strToday}_${language.name}.xlsx`);
                    }
                }
            });
        }
    };

    pad2 = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    handleImportTranslations = () => {
        this.setState({ showDialogImportTranslationTs: new Date() });
    };

    handleExportValueTranslations = () => {
        const { languages } = this.props;
        const { selectedLanguage } = this.state;

        const language = languages.find((it) => it.id == selectedLanguage);

        if (language) {
            this.props.onContent.exportGroupValueTranslationsExcel(language.id).then(() => {
                if (!this.props.loadingPrint && !this.props.contentsError) {
                    if (this.props.printData) {
                        var today = new Date();
                        var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                        FileDownload(this.props.printData, `ExportGroupValueTranslations_${strToday}_${language.name}.xlsx`);
                    }
                }
            });
        }
    };

    handleImportValueTranslations = () => {
        this.setState({ showDialogImportGroupValueTranslationTs: new Date() });
    };

    onTableFilterChange = (e, searchType) => {
        let columnSearchKeywords = this.state.columnSearchKeywords;
        if (!columnSearchKeywords) {
            columnSearchKeywords = [];
        }

        if (columnSearchKeywords.find((it) => it.name == e.target.name)) {
            let columnSearchKeyword = columnSearchKeywords.find((it) => it.name == e.target.name);
            columnSearchKeyword.value = e.target.value;
        } else {
            columnSearchKeywords.push({
                name: e.target.name,
                value: e.target.value,
                searchType,
            });
        }

        this.setState({ columnSearchKeywords });
    };

    render() {
        const { pages, searchKeyword, searchProperties, columnSearchKeywords } = this.state;

        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Manage Page Labels");

        const openContentDetail = this.openContentDetail;
        const onTableFilterChange = this.onTableFilterChange;

        const columns = [
            {
                id: "contentId",
                name: <div>Action</div>,
                selector: (row) => row.contentId,
                sortable: false,
                width: "8%",
                center: true,
                format: function (row) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                            {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) && (
                                <React.Fragment>
                                    <FontAwesomeIcon id={`iconContentDetail${row.id}`} icon={faPenToSquare} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => openContentDetail(row)} />
                                    <UncontrolledTooltip target={`iconContentDetail${row.id}`} placement="bottom">
                                        Update Content
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            )}
                        </div>
                    );
                },
            },
            // {
            //     dataField: "page",
            //     text: "Page",
            //     sort: true,
            //     headerStyle: () => {
            //         return { width: "15%" };
            //     },
            //     formatter: function (value) {
            //         return <span style={{ wordBreak: "break-word" }}>{value}</span>;
            //     },
            //     clickToSelect: false,
            // },
            {
                id: "name",
                name: (
                    <div style={{ width: "90%" }}>
                        Name
                        <br />
                        <input type="text" name="name" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "name") ? columnSearchKeywords.find((it) => it.name == "name").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.name,
                sortable: true,
                width: "22%",
                format: function (row, index) {
                    return <span style={{ wordBreak: "break-word" }}>{row.name}</span>;
                },
            },
            {
                id: "contentText",
                name: (
                    <div style={{ width: "90%" }}>
                        Content
                        <br />
                        <input type="text" name="contentText" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "contentText") ? columnSearchKeywords.find((it) => it.name == "contentText").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.contentText,
                sortable: true,
                width: "35%",
                wrap: true,
            },
            {
                id: "contentTextTranslation",
                name: (
                    <div style={{ width: "90%" }}>
                        Translation
                        <br />
                        <input
                            type="text"
                            name="contentTextTranslation"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "contentTextTranslation") ? columnSearchKeywords.find((it) => it.name == "contentTextTranslation").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.contentTextTranslation,
                sortable: true,
                width: "35%",
                wrap: true,
            },
        ];

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                <ContentTranslationBulkUpload selectedLanguage={this.state.selectedLanguage} showDialog={this.state.showDialogImportTranslationTs} generateAlert={this.generateAlert}></ContentTranslationBulkUpload>
                <GroupValueTranslationBulkUpload selectedLanguage={this.state.selectedLanguage} showDialog={this.state.showDialogImportGroupValueTranslationTs} generateAlert={this.generateAlert}></GroupValueTranslationBulkUpload>
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <ContentDetail
                            content={this.state.selectedContent}
                            showDialog={this.state.showDialogContentDetailTs}
                            language={this.props.languages.find((it) => it.id == this.state.selectedLanguage)}
                            pages={this.state.pages}
                            languages={this.props.languages}
                            generateAlert={this.generateAlert}
                        ></ContentDetail>
                        <div>
                            <Row>
                                <Col md="4" sm="4">
                                    <Input type="select" name="language" id="language" value={this.state.selectedLanguage ? this.state.selectedLanguage : ""} onChange={this.handleChangeLanguage}>
                                        {this.props.languages.map((language, key) => (
                                            <option key={key} value={language.id}>
                                                {language.name}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>
                                <Col md="2" sm="2" style={{ display: "none" }}>
                                    <Input type="select" name="page" id="page" value={this.state.selectedPage ? this.state.selectedPage : ""} onChange={this.handleChangePage}>
                                        {pages.map((page, key) => (
                                            <option key={key} value={page.value}>
                                                {page.label}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>
                                <Col md="4" sm="4" style={{ display: "none" }}>
                                    <SkillsTxTableSearchBar
                                        searchKeyword={this.state.searchKeyword}
                                        onChange={(searchKeyword) => {
                                            this.setState({ searchKeyword });
                                        }}
                                    ></SkillsTxTableSearchBar>
                                </Col>
                                <Col md="8" sm="8">
                                    {/* <SkillsTxTableClearSearch
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        color="primary"
                                        disabled={this.props.contentsLoading || this.state.isUpdating}
                                        onClear={() => {
                                            this.setState({ searchKeyword: "" });
                                        }}
                                    ></SkillsTxTableClearSearch>
                                    &nbsp;&nbsp; */}
                                    <Button
                                        color="primary"
                                        onClick={this.handleRefresh}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        disabled={this.props.contentsLoading || this.state.isUpdating}
                                    >
                                        <FontAwesomeIcon icon={faSync} /> Refresh
                                    </Button>
                                    &nbsp;&nbsp;
                                    {pageAssignment && pageAssignment.operations.find((it) => it.operation == "Export" && it.allow == true) && (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={this.handleExportTranslations}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.contentsLoading || this.props.loadingPrint}
                                            >
                                                <FontAwesomeIcon icon={faFileExcel} /> Export All Translations
                                            </Button>
                                            &nbsp;&nbsp;
                                        </React.Fragment>
                                    )}
                                    {pageAssignment && pageAssignment.operations.find((it) => it.operation == "Import" && it.allow == true) && (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={this.handleImportTranslations}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.contentsLoading}
                                            >
                                                <FontAwesomeIcon icon={faFileExcel} /> Import All Translations
                                            </Button>
                                            &nbsp;&nbsp;
                                        </React.Fragment>
                                    )}
                                    {pageAssignment && (pageAssignment.operations.find((it) => it.operation == "Export" && it.allow == true) || pageAssignment.operations.find((it) => it.operation == "Import" && it.allow == true)) && (
                                        <React.Fragment>
                                            <br />
                                            {pageAssignment && pageAssignment.operations.find((it) => it.operation == "Export" && it.allow == true) && (
                                                <React.Fragment>
                                                    <Button
                                                        color="primary"
                                                        onClick={this.handleExportValueTranslations}
                                                        style={{
                                                            fontSize: "16px",
                                                            height: "40px",
                                                            marginBottom: "10px",
                                                        }}
                                                        disabled={this.props.contentsLoading || this.props.loadingPrint}
                                                    >
                                                        <FontAwesomeIcon icon={faFileExcel} /> Export All Value Translations
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                </React.Fragment>
                                            )}
                                            {pageAssignment && pageAssignment.operations.find((it) => it.operation == "Import" && it.allow == true) && (
                                                <React.Fragment>
                                                    <Button
                                                        color="primary"
                                                        onClick={this.handleImportValueTranslations}
                                                        style={{
                                                            fontSize: "16px",
                                                            height: "40px",
                                                            marginBottom: "10px",
                                                        }}
                                                        disabled={this.props.contentsLoading}
                                                    >
                                                        <FontAwesomeIcon icon={faFileExcel} /> Import All Value Translations
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                            <SkillsTxTable columns={columns} data={this.state.contents} pagination={true} searchKeyword={searchKeyword} columnSearchKeywords={columnSearchKeywords} searchProperties={searchProperties} selectableRows={false} progressPending={this.props.contentsLoading}></SkillsTxTable>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        authPersonId: state.auth.personId,

        contentsLoading: state.adminContent.loading,
        contentsError: state.adminContent.error,
        pages: state.adminContent.pages,
        contents: state.adminContent.contents,
        translations: state.adminContent.translations,

        loadingPrint: state.adminContent.loadingPrint,
        printData: state.adminContent.printData,

        pageAssignments: state.adminPerson.pageAssignments,

        languages: state.language.languages,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onContent: bindActionCreators(contentActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageContent));
