import * as skillProfileActions from "../../store/actions/skillProfileAction";
import { contentTranslator } from "../../utils/translatorUtils";
import ContentTranslator from "../translator/ContentTranslator";
import React from "react";
import { connect } from "react-redux";
import { Button, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { bindActionCreators } from "redux";

class LorProfilesEditComment extends React.Component {
    static displayName = LorProfilesEditComment.name;

    constructor(props) {
        super(props);
        this.state = {
            comment: null,
            updating: false,
        };
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        this.setState({ comment: this.props.editedLorProfile && this.props.editedLorProfile.comment });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.editedLorProfile !== this.props.editedLorProfile) {
            this.setState({ comment: this.props.editedLorProfile && this.props.editedLorProfile.comment });
        }
    }

    handleSaveEditCommentClick = () => {
        const { editedLorProfile, isEndorser } = this.props;
        const { comment } = this.state;
        let processSave = false;

        if (isEndorser) {
            if (window.confirm("These comments are visible to the User. Are you sure?")) {
                processSave = true;
            }
        } else {
            processSave = true;
        }

        if (processSave) {
            this.setState({ updating: true });

            Promise.resolve(this.props.managedPeople ? this.props.onProfile.updateManagedPeopleLorProfileComment(editedLorProfile.id, comment, this.props.managedPeople.personB.email) : this.props.onProfile.updateMyLorProfileComment(editedLorProfile.id, comment)).then(() => {
                if (!this.props.updating) {
                    if (this.props.error) {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    } else {
                        editedLorProfile.comment = comment;
                        this.props.generateAlert("success", this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "EditLorCommentEnterSuccessMessage", "Lor profile comment updated"));
                        this.props.updateLorComment(editedLorProfile);
                    }
                }

                this.setState({ updating: this.props.updating });
            });
        }
    };

    handleChangeComment = (event) => {
        const { value } = event.target;
        this.setState({ comment: value });
    };

    render() {
        const { comment } = this.state;

        if (this.props.editComment) {
            return (
                <Modal isOpen={this.props.editComment} toggle={this.state.updating ? null : this.props.togleEditLorComment}>
                    <ModalHeader toggle={this.props.togleEditLorComment}>
                        <ContentTranslator page="SfiaProfile" name="EditLorCommentTitle" contentText="Comment" />
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "500px" }}>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr valign="top">
                                            <td style={{ width: "100%" }} className="td-edit-skill-header col-respon-fully">
                                                {this.props.editedLor} {this.props.editedLorLevel}
                                            </td>
                                        </tr>
                                        <tr valign="top">
                                            <td style={{ width: "100%" }} className="td-edit-skill-comment">
                                                {this.props.editedLorDescription}
                                            </td>
                                        </tr>
                                        <tr valign="top">
                                            <td colSpan="2" style={{ width: "100%" }} className="td-edit-skill">
                                                <Input
                                                    type="textarea"
                                                    name="comment"
                                                    id="comment"
                                                    placeholder={this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "EditLorCommentEnterCommentPlaceholder", "Enter Comment")}
                                                    style={{ fontSize: "12px" }}
                                                    value={comment ? comment : ""}
                                                    onChange={this.handleChangeComment}
                                                    readOnly={!this.props.allowEditComment}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" disabled={this.state.updating} onClick={this.props.togleEditLorComment}>
                            <ContentTranslator page="SfiaProfile" name="EditLorCommentButtonClose" contentText="Close" />
                        </Button>
                        {this.props.allowEditComment && (
                            <React.Fragment>
                                {" "}
                                <Button color="primary" disabled={this.state.updating} onClick={() => this.handleSaveEditCommentClick()}>
                                    <ContentTranslator page="SfiaProfile" name="EditLorCommentButtonSave" contentText="Save changes" />
                                </Button>
                            </React.Fragment>
                        )}
                    </ModalFooter>
                </Modal>
            );
        } else {
            return "";
        }
    }
}

const mapStateToProps = (state) => {
    return {
        updating: state.skillProfile.updating,
        error: state.skillProfile.error,

        managedPeople: state.auth.managedPeople,
        contentTranslations: state.content.contentTranslations,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onProfile: bindActionCreators(skillProfileActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LorProfilesEditComment);
