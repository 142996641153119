import { updateState } from "../../utility";
import * as actionTypes from "../actions/adminActionTypes";

const initialState = {
    exportData: null,
    loadingExport: false,
    isImporting: false,
};

const exportRelationshipsDataExcelStart = (state) => {
    return updateState(state, { error: null, loadingExport: true });
};

const exportRelationshipsDataExcelSuccess = (state, action) => {
    return updateState(state, {
        exportData: action.exportData,
        error: null,
        loadingExport: false,
    });
};

const exportRelationshipsDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingExport: false,
    });
};

const importRelationshipsDataExcelStart = (state) => {
    return updateState(state, {
        error: null,
        isImporting: true,
    });
};

const importRelationshipsDataExcelSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        isImporting: false,
    });
};

const importRelationshipsDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isImporting: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.EXPORT_RELATIONSHIPS_DATA_START:
            return exportRelationshipsDataExcelStart(state);
        case actionTypes.EXPORT_RELATIONSHIPS_DATA_SUCCESS:
            return exportRelationshipsDataExcelSuccess(state, action);
        case actionTypes.EXPORT_RELATIONSHIPS_DATA_FAIL:
            return exportRelationshipsDataExcelFail(state, action);

        case actionTypes.IMPORT_RELATIONSHIPS_DATA_START:
            return importRelationshipsDataExcelStart(state);
        case actionTypes.IMPORT_RELATIONSHIPS_DATA_SUCCESS:
            return importRelationshipsDataExcelSuccess(state, action);
        case actionTypes.IMPORT_RELATIONSHIPS_DATA_FAIL:
            return importRelationshipsDataExcelFail(state, action);

        default:
            return state;
    }
};

export default reducer;
