import * as evidenceTypeActions from "../../../store/admin/actions/adminEvidenceTypeActions";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import { faPenToSquare, faMinusCircle, faPlus, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const rowStyle = {
    fontSize: "12px",
};

class ManageEvidenceTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            evidenceTypes: [],
            isUpdating: false,
            showDialog: false,
            showUpdateDialog: false,
            updateMode: null,
            touched: false,
            editedEvidenceTypeId: null,
            evidenceTypeName: "",
            searchProperties: ["name"],
        };
    }

    componentDidMount() {
        // this.initData();
    }

    handleRefresh = () => {
        this.initData();
    };

    initData = () => {
        this.setState({ loading: true });
        this.props.onEvidenceType.getEvidenceTypes().then(() => {
            if (!this.props.loading) {
                this.setState({
                    evidenceTypes: this.props.evidenceTypes.sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1)),
                });
            }

            this.setState({ loading: this.props.loading });
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
            this.initData();
        }
    }

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({ showDialog: !showDialog });
    };

    handleAddNew = () => {
        this.setState({ updateMode: "Add", showUpdateDialog: true, touched: false, evidenceTypeName: "" });
    };

    openUpdate = (evidenceType) => {
        this.setState({ updateMode: "Update", showUpdateDialog: true, touched: false, evidenceTypeName: evidenceType.name, editedEvidenceTypeId: evidenceType.id });
    };

    handleChange = (event) => {
        const form = this.state.form;
        const { name, value } = event.target;

        this.setState({ touched: true, evidenceTypeName: value });
    };

    handleSubmit = () => {
        const { updateMode, evidenceTypeName, editedEvidenceTypeId } = this.state;
        this.setState({ isUpdating: true });

        if (updateMode == "Add") {
            this.props.onEvidenceType
                .addEvidenceType({
                    name: evidenceTypeName.trim(),
                })
                .then(() => {
                    if (!this.props.isUpdating) {
                        if (this.props.error) {
                            this.props.generateAlert("danger", this.props.error.errData.Message);
                        } else {
                            this.setState({
                                evidenceTypes: this.props.evidenceTypes.sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1)),
                            });
                            this.togleUpdateDialog();
                            this.props.generateAlert("success", "Evidence type added");
                        }
                    }

                    this.setState({ isUpdating: this.props.isUpdating });
                });
        } else {
            this.props.onEvidenceType
                .updateEvidenceType(editedEvidenceTypeId, {
                    name: evidenceTypeName.trim(),
                })
                .then(() => {
                    if (!this.props.isUpdating) {
                        if (this.props.error) {
                            this.props.generateAlert("danger", this.props.error.errData.Message);
                        } else {
                            this.setState({
                                evidenceTypes: this.props.evidenceTypes.sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1)),
                            });
                            this.togleUpdateDialog();
                            this.props.generateAlert("success", "Evidence type updated");
                        }
                    }

                    this.setState({ isUpdating: this.props.isUpdating });
                });
        }
    };

    togleUpdateDialog = () => {
        const showUpdateDialog = this.state.showUpdateDialog;
        this.setState({ showUpdateDialog: !showUpdateDialog });
    };

    renderUpdate = () => {
        return (
            <Modal isOpen={this.state.showUpdateDialog} size="md" toggle={!this.state.isUpdating ? this.togleUpdateDialog : null}>
                <ModalHeader toggle={!this.state.isUpdating ? this.togleUpdateDialog : null}>{this.state.updateMode ? this.state.updateMode : ""} Evidence Types</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row style={{ marginBottom: "5px" }}>
                                    <Col sm={12}>
                                        <Input
                                            id="name"
                                            name="nameassessmentDescription"
                                            type="text"
                                            value={this.state.evidenceTypeName}
                                            invalid={this.state.touched && this.state.evidenceTypeName == ""}
                                            onChange={this.handleChange}
                                            disabled={this.state.isUpdating}
                                            placeholder="Evidence Type Name"
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color={this.state.isUpdating ? "secondary" : "primary"}
                        onClick={() => {
                            this.handleSubmit();
                        }}
                        disabled={this.state.isUpdating || this.state.evidenceTypeName == ""}
                    >
                        Save
                    </Button>
                    <Button color="secondary" onClick={this.togleUpdateDialog} disabled={this.state.isUpdating}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleDelete = (row) => {
        this.setState({ isUpdating: true });

        this.props.onEvidenceType.deleteEvidenceType(row.id).then(() => {
            if (!this.props.isUpdating) {
                if (this.props.error) {
                    this.props.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    const evidenceTypes = this.props.evidenceTypes
                        .sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1))
                        .map((it) => {
                            return {
                                id: it.id,
                                name: it.name,
                                isUsed: it.isUsed,
                                lastUpdated: new Date(),
                            };
                        });
                    this.setState({ evidenceTypes: [...evidenceTypes.filter((it) => it.id != row.id)] });
                    this.props.generateAlert("success", "Evidence type deleted");
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    render() {
        const { evidenceTypes, searchKeyword, searchProperties } = this.state;

        const openUpdate = this.openUpdate;
        const handleDelete = this.handleDelete;
        const columns = [
            {
                id: "id",
                name: "Action",
                selector: (row) => row.id,
                sortable: false,
                width: "15%",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <FontAwesomeIcon id={`iconUpdate${row.id}`} icon={faPenToSquare} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => openUpdate(row)} />
                            <UncontrolledTooltip target={`iconUpdate${row.id}`} placement="bottom">
                                Update
                            </UncontrolledTooltip>
                            {!row.isUsed && (
                                <React.Fragment>
                                    &nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon id={`iconDelete${row.id}`} icon={faMinusCircle} size="lg" style={{ cursor: "pointer", color: "#FF0000" }} onClick={() => handleDelete(row)} />
                                    <UncontrolledTooltip target={`iconDelete${row.id}`} placement="bottom">
                                        Delete
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            )}
                        </div>
                    );
                },
                omit: !this.props.allowEdit,
            },
            {
                id: "name",
                name: "Name",
                selector: (row) => row.name,
                sortable: true,
                width: this.props.allowEdit ? "85%" : "100%",
            },
        ];

        return (
            <React.Fragment>
                {this.renderUpdate()}
                <Modal isOpen={this.state.showDialog} size="lg">
                    <ModalHeader toggle={!this.state.isUpdating ? this.togleDialogModal : null}>Evidence Types</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col md={12} sm={12}>
                                    <div style={{ marginBottom: "10px" }}>
                                        <Row>
                                            <Col md="6" sm="8">
                                                <SkillsTxTableSearchBar
                                                    searchKeyword={searchKeyword}
                                                    onChange={(searchKeyword) => {
                                                        this.setState({ searchKeyword });
                                                    }}
                                                ></SkillsTxTableSearchBar>
                                            </Col>
                                            <Col md="6" sm="4">
                                                <SkillsTxTableClearSearch
                                                    style={{
                                                        fontSize: "16px",
                                                        height: "40px",
                                                    }}
                                                    color="primary"
                                                    disabled={this.props.loading || this.state.isUpdating}
                                                    onClear={() => {
                                                        this.setState({ searchKeyword: "" });
                                                    }}
                                                ></SkillsTxTableClearSearch>
                                                &nbsp;&nbsp;
                                                <Button
                                                    color="primary"
                                                    onClick={this.handleRefresh}
                                                    style={{
                                                        fontSize: "16px",
                                                        height: "40px",
                                                    }}
                                                    disabled={this.props.loading || this.state.isUpdating}
                                                >
                                                    <FontAwesomeIcon icon={faSync} /> Refresh
                                                </Button>
                                                &nbsp;&nbsp;
                                                {this.props.allowEdit && (
                                                    <React.Fragment>
                                                        <Button
                                                            color="primary"
                                                            onClick={this.handleAddNew}
                                                            style={{
                                                                fontSize: "16px",
                                                                height: "40px",
                                                            }}
                                                            disabled={this.props.loading}
                                                        >
                                                            <FontAwesomeIcon icon={faPlus} /> Add New
                                                        </Button>
                                                        &nbsp;&nbsp;
                                                    </React.Fragment>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                    <SkillsTxTable columns={columns} data={evidenceTypes} pagination={true} searchKeyword={searchKeyword} searchProperties={searchProperties} progressPending={this.props.loading}></SkillsTxTable>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.adminEvidenceType.loading,
        error: state.adminEvidenceType.error,
        evidenceTypes: state.adminEvidenceType.evidenceTypes,
        isUpdating: state.adminEvidenceType.isUpdating,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onEvidenceType: bindActionCreators(evidenceTypeActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageEvidenceTypes);
