import React from "react";
import { Redirect, Route } from "react-router-dom";

const LoggedInRoute = ({ component: Component, isAuthenticated, authMode, connectSignalR, language, setCurrentLanguage, boolLoginFail, boolLoginMembee, containerConfig, redirectUrl, isLogout, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            let authModes = authMode.split(",").map((item) => item.trim().toLowerCase());

            if (!isAuthenticated) {
                if (props.location.pathname == "/") {
                    if (containerConfig && containerConfig.allowSelfRegistration && !redirectUrl) {
                        return <Component connectSignalR={connectSignalR} language={language} setCurrentLanguage={setCurrentLanguage} boolLoginFail={boolLoginFail} boolLoginMembee={boolLoginMembee} {...props} />;
                    } else {
                        return <Redirect to={{ pathname: "/login", state: { redirectUrl: redirectUrl, isLogout: isLogout } }} />;
                    }
                } else {
                    return <Component connectSignalR={connectSignalR} language={language} setCurrentLanguage={setCurrentLanguage} boolLoginFail={boolLoginFail} boolLoginMembee={boolLoginMembee} {...props} />;
                }
            } else {
                if (redirectUrl && !isLogout) {
                    localStorage.setItem("redirectUrl", "");
                    localStorage.setItem("isLogout", "FALSE");
                    return <Redirect to={redirectUrl} />;
                } else {
                    if (authModes && authModes.includes("survey")) {
                        return <Redirect to="/survey" />;
                    } else if (authModes.includes("planner")) {
                        return <Redirect to="/SfiaProfile" />;
                    } else {
                        return <Redirect to="/admin" />;
                    }
                }
            }
        }}
    />
);

export default LoggedInRoute;
