import * as actionTypes from "./actionTypes";

import LanguageAPI from "./../../common/api/service/LanguageService";

export const getLanguagesStart = () => {
    return {
        type: actionTypes.GET_LANGUAGES_START,
    };
};

export const getLanguagesSuccess = (languages) => {
    return {
        type: actionTypes.GET_LANGUAGES_SUCCESS,
        languages
    };
};

export const getLanguagesFail = error => {
    return {
        type: actionTypes.GET_LANGUAGES_FAIL,
        error: error,
    };
};

export const getLanguages = () => {
    return dispatch => {
        dispatch(getLanguagesStart());

        return LanguageAPI.getLanguages()
            .then(res => {
                if (res) {
                    dispatch(getLanguagesSuccess(res));
                } else {
                    dispatch(getLanguagesFail(res));
                }
            })
            .catch(err => {
                dispatch(getLanguagesFail(err));
            });
    };
};

export const setLanguage = (language) => {
    return {
        type: actionTypes.SET_LANGUAGE,
        language,
    };
};