import Badge from "./Badge";
import React from "react";

const styles = {
  listContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "30px",
  },
  sectionTitle: {
    fontFamily: "IBMPlexSans",
    marginBottom: "20px"
  }
};

const BadgesList = ({
  sectionTitle,
  sectionDarkColor = "#000000",
  sectionLightColor = "#000000",
  badgesData,
  onClick
}) => {
  return (
    <div>
      <h5
        style={{
          ...styles.sectionTitle,
          color: sectionDarkColor,
        }}
      >
        {sectionTitle}
      </h5>
      <div style={styles.listContainer}>
        {badgesData.map((badge, index) => (
          <Badge
            key={`${badge?.id}-${index}`}
            bgColor={badge?.color ?? sectionDarkColor}
            title={badge?.skillCode}
            lightBgColor={ badge?.lightColor ?? sectionLightColor}
            imgSource={badge?.imgSource}
            onClick={onClick}
            data={badge}
          />
        ))}
      </div>
    </div>
  );
};

export default BadgesList;
