import * as actionTypes from "./adminActionTypes";

import AdminCompanyAPI from "./../../../common/api/adminservice/AdminCompanyService";

export const getCompanyBrandingLogoStart = () => {
    return {
        type: actionTypes.GET_ADMIN_COMPANY_BRANDING_LOGO_START
    };
};

export const getCompanyBrandingLogoSuccess = () => {
    return {
        type: actionTypes.GET_ADMIN_COMPANY_BRANDING_LOGO_SUCCESS,
    };
};

export const getCompanyBrandingLogoFail = error => {
    return {
        type: actionTypes.GET_ADMIN_COMPANY_BRANDING_LOGO_FAIL,
        error: error,
    };
};

export const getCompanyBrandingLogo = () => {
    return dispatch => {
        dispatch(getCompanyBrandingLogoStart());

        return AdminCompanyAPI.getBrandingLogo()
            .then(res => {
                dispatch(getCompanyBrandingLogoSuccess(res));
            })
            .catch(err => {
                dispatch(getCompanyBrandingLogoFail(err));
            });
    };
};

export const updateCompanyBrandingLogoStart = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_BRANDING_LOGO_START
    };
};

export const updateCompanyBrandingLogoSuccess = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_BRANDING_LOGO_SUCCESS,
    };
};

export const updateCompanyBrandingLogoFail = error => {
    return {
        type: actionTypes.UPDATE_COMPANY_BRANDING_LOGO_FAIL,
        error: error,
    };
};

export const updateCompanyBrandingLogo = (brandingLogo) => {
    return dispatch => {
        dispatch(updateCompanyBrandingLogoStart());

        return AdminCompanyAPI.updateBrandingLogo(brandingLogo)
            .then(res => {
                dispatch(updateCompanyBrandingLogoSuccess(res));
            })
            .catch(err => {
                dispatch(updateCompanyBrandingLogoFail(err));
            });
    };
};

export const deleteCompanyBrandingLogo = () => {
    return dispatch => {
        dispatch(updateCompanyBrandingLogoStart());

        return AdminCompanyAPI.deleteBrandingLogo()
            .then(res => {
                dispatch(updateCompanyBrandingLogoSuccess(res));
            })
            .catch(err => {
                dispatch(updateCompanyBrandingLogoFail(err));
            });
    };
};