import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Table, Tag, Tooltip, Typography, Empty } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";

const constantData = {
  titleLevel: 5,
  pageSize: 10,
  colors: {
    type1: "#69849c",
    type2: "#a5b8c8",
  },
};

const DivCenter = ({ Component, title }) => {
  return (
    <div
      className="p-grid p-align-center vertical-container"
      style={{ marginBottom: "40px" }}
    >
      <div className="layout-wrapper">
        <Title level={constantData.titleLevel}>{title}</Title>
        <Component />
      </div>
    </div>
  );
};

const { Title } = Typography;

const WrapperTable = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;

  .ant-table-container table > thead > tr:first-child th {
    text-align: center;
  }

  .ant-table-container table tr .ant-table-cell-row-hover {
    background: rgb(116, 226, 177, 0.2) !important;
  }

  .mr-10 {
    margin-right: 10px;
  }
  .grid {
    display: grid;
  }
  .anticon {
    vertical-align: 1px !important;
  }

  @media (max-width: 1680px) {
    .cell-cm {
      width: 150px;
    }
  }

  @media (max-width: 1580px) {
    .cell-cm {
      width: 100px;
    }
  }

  @media (max-width: 1480px) {
    .cell-cm {
      width: 60px;
    }
  }
  @media (max-width: 1280px) {
    .cell-cm {
      width: auto;
    }
  }
`;

const WrapperSubCell = styled.div`
  width: auto;
  text-wrap: wrap;
`;

const ContainerTable = styled.div`
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
`;

// Helper function to generate a table column that centers its text content.
// This avoids repetition for multiple columns that require similar formatting.
const createCenteredTextColumn = (
  title,
  dataIndex,
  sorterFunction,
  isResponsive = false
) => ({
  title, // Column header
  dataIndex, // Data key
  key: dataIndex, // Key for React's list rendering
  sorter: (a, b) => sorterFunction(a[dataIndex], b[dataIndex]), // Sorting function for the column
  render: (text) => (
    <WrapperSubCell className={`text-center ${isResponsive && "cell-cm"}`}>
      {text ?? "-"}
    </WrapperSubCell>
  ), // Centering the text
});

// Utility function to compare text values
const sortByText = (a, b) => (a ?? "").localeCompare(b ?? "");

// Utility function to transform data source for tables
// This maps over the data and transforms it to have keys and all the item properties.
const transformDataSource = (data) =>
  data.map((item, index) => ({
    key: `${index + 1}`,
    ...item,
  }));

// Utility function to generate column definitions based on type and need status
// This will return the appropriate columns based on whether it's a professional or generic skill and if it's a needed skill.
const getColumnDefinitions = (isResponsive) => {
  const getColorByActionStatus = (record) => {
    switch (record.actionStatus.toLowerCase()) {
      case "to do":
        return "default";
      case "in progress":
        return "processing";
      case "pending":
        return "warning";
      case "done":
        return "success";
      default:
        return "default";
    }
  };
  const getIconByActionStatus = (record) => {
    switch (record.actionStatus.toLowerCase()) {
      case "to do":
        return <MinusCircleOutlined />;
      case "pending":
        return <ClockCircleOutlined />;
      case "in progress":
        return <SyncOutlined spin />;
      case "done":
        return <CheckCircleOutlined />;
      default:
        return <></>;
    }
  };
  const tempColum = [
    createCenteredTextColumn("Full Name", "fullName", sortByText),
    createCenteredTextColumn(
      "Completion Date",
      "targetCompletionOn",
      sortByText,
      isResponsive
    ),
    createCenteredTextColumn(
      "Skill Name",
      "skillName",
      sortByText,
      isResponsive
    ),
    {
      title: "Skill Code",
      dataIndex: "skillCode",
      key: "skillCode",
      sorter: (a, b) => sortByText(a.skillCode, b.skillCode),
      render: (text, record) => (
        <div className="text-center text-nowrap">
          <Tooltip title={record.skillName}>
            <Tag style={{ color: "black" }}>{text}</Tag>
          </Tooltip>
        </div>
      ),
    },
    createCenteredTextColumn("Action Type", "actionType", sortByText),
    {
      title: "Action Status",
      dataIndex: "actionStatus",
      key: "actionStatus",
      sorter: (a, b) => sortByText(a.status, b.status),
      render: (text, record) => (
        <div className="text-center text-nowrap">
          <Tag
            icon={getIconByActionStatus(record)}
            color={getColorByActionStatus(record)}
          >
            {text}
          </Tag>
        </div>
      ),
    },
  ];
  return tempColum;
};

const ActionPlanTable = ({
  data = [],
  pageSize = constantData.pageSize,
  isResponsive = false,
}) => {
  // Memoized calculation for professional table data
  const dataSource = useMemo(() => {
    // If not showing professional or no data, return empty data.
    if (data?.length === 0) return [];

    // Return the transformed data source and the appropriate columns
    return transformDataSource(data);
  }, [data]);

  // Memoized calculation for professional table data
  const columns = useMemo(() => {
    // If not showing professional or no data, return empty data.
    if (data?.length === 0) return [];

    // Return the transformed data source and the appropriate columns
    return getColumnDefinitions(isResponsive);
  }, [data]);

  return (
    <ContainerTable>
      {dataSource.length > 0 ? (
        // Render the professional table
        <>
          <Title level={constantData.titleLevel}>Professional Skills</Title>
          <WrapperTable>
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={{ pageSize }}
              scroll={{ x: true }}
            />
          </WrapperTable>
        </>
      ) : (
        <DivCenter
          title="Professional Skills"
          Component={() => <Empty description={"No Data"} />}
        />
      )}
    </ContainerTable>
  );
};

export default ActionPlanTable;
