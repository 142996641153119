import BadgeRequestAPI from "../common/api/service/BadgeRequestService";
import BadgesList from "../components/digitalwallet/BadgesList";
import SkillDetailsModal from "../components/digitalwallet/Modal/SkillDetailsModal";
import { lightenColor } from "../utils/functions";
import { HourglassOutlined, MessageOutlined } from "@ant-design/icons";
import { Typography, Empty, Switch as SwitchComponent, message, Avatar } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import { Route, Switch, useParams } from "react-router";
import dayjs from "dayjs";

const styles = {
  container: {
    width: "60vw",
    margin: "0 auto",
    padding: "0 0 50px 0",
  },
  HeaderContainer: {
    borderRadius: 20,
    backgroundColor: "#ecf0f1",
    padding: "30px 40px",
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
    gap: 20,
    marginBottom: 25,
  },
  ContentContainer: {},
  ownerInfoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
};

const createSkillBadgeImage = (skill, skill_code, skill_type, skill_level, badgeProvider) => {
  if (badgeProvider && badgeProvider == "DSA" ){
    return `${process.env.REACT_APP_AZURE_STORAGE_BADGE_DSA_IMAGE_BASE_URL}${skill_type} - ${skill.replaceAll("/", " ")} - Level ${skill_level}.png`;
  }else{
    return `${process.env.REACT_APP_AZURE_STORAGE_BADGE_IMAGE_BASE_URL}${skill_code}${skill_level}${skill_type}.png`;
  }
};

const findSkillsCode = (objArr, code) => {
  const found = _.find(objArr, (obj) => {
    return _.some(obj.subCategories, (sub) => {
      return _.some(sub.skills, (skill) => {
        return skill.skillsCode === code;
      });
    });
  });

  return found;
};

function findSkills(objects, skill, payload) {
  let result = null;
  _.forEach(objects, (obj) => {
    _.forEach(obj.subCategories, (sub) => {
      const child = _.find(sub.skills, { skillsCode: skill.skillCode });
      if (child) {
        const { id, colour, name, sfiaVersion } = obj;
        const { description, level } = child.levels.find(
          (filteredSkill) => filteredSkill.level === skill.level
        );
        result = {
          ...payload,
          id,
          categoryName: name,
          color: colour,
          skillCode: child.skillsCode,
          description,
          level,
          sfiaVersion,
          skillName: child.name,
          skillType: skill.skillType,
        };
        return false;
      }
    });
    if (result) {
      return false;
    }
  });
  return result;
}
const { Text } = Typography;
const WalletComponent = () => {
  const [badges, setBadges] = useState();
  const [modalData, setModalData] = useState();
  const [showByCategory, setShowByCategory] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [walletInfo, setWalletInfo] = useState();
  const [personInfo, setPersonInfo] = useState();

  const { id } = useParams();

  const showModal = (payload) => {
    setModalData(payload);
  };

  const handleOk = () => {
    setModalOpen(false);
  };

  const handleCancel = () => {
    setModalOpen(false);
    setModalData(undefined);
  };

  const fussionBadgesData = useMemo(() => {
    if (badges) {
      return Object.keys(badges).reduce(
        (accumulator, currentValue) =>
          accumulator.concat(badges?.[currentValue]?.data),
        []
      );
    }
    return [];
  }, [badges]);

  useEffect(() => {
    if (modalData) {
      setModalOpen(true);
    }
  }, [modalData]);

  useEffect(() => {
    const fetchBadges = async () => {

      try {
        const badgeWalletData = await BadgeRequestAPI.getBadgeWallet(id);
        const listOfCategories = badgeWalletData.categoryResponse;
        setPersonInfo(badgeWalletData.person)
        const mappedFetchedData = badgeWalletData.evidenceFileResponse.reduce(
          (acc, item) => {
            const accumulator = acc;
            const { label, createdOn, expiryOn, link, badgeProvider, skillLevels } = item;
            accumulator.push({
              label,
              createdOn,
              expiryOn,
              link,
              badgeProvider,
              ...skillLevels[0],
            });
            return accumulator;
          },
          []
        );


        if (dayjs().isAfter(dayjs(badgeWalletData.walletShare.expireOn))) {
          message.error("This's Link Expired", 5000)
          setTimeout(() => {
            window.location.pathname = '/'
          }, 3000)

        } else {
          setWalletInfo(badgeWalletData.walletShare)

          if (Array.isArray(mappedFetchedData) && Array.isArray(listOfCategories)) {
            const MappedData = {};
            mappedFetchedData.forEach((element) => {
              const results = findSkillsCode(listOfCategories, element?.skillCode);
              const parChildRes = findSkills(listOfCategories, element, {
                imgSource: createSkillBadgeImage(
                  element?.skill,
                  element?.skillCode,
                  element?.skillType,
                  element?.level,
                  element?.badgeProvider
                ),
                lightColor: lightenColor(results.colour, 95),
              });

              if (results) {
                if (MappedData?.[results?.name]) {
                  MappedData[results.name].data = [
                    ...MappedData[results.name].data,
                    parChildRes,
                  ];
                } else {
                  MappedData[results?.name] = {
                    color: results.colour,
                    lightColor: lightenColor(results.colour, 95),
                    categoryName: results.name,
                    data: [parChildRes],
                  };
                }
              }
              setBadges(MappedData);
            });
          }


        }

      } catch (error) {
        console.log(error)
        message.error("Link Not Found", 5000)
        setTimeout(() => {
          window.location.pathname = '/'
        }, 3000)
      }





    };
    if (id) {
      fetchBadges();
    }
  }, [id]);
  return !!walletInfo && (
    <div style={styles.container}>
      <div style={styles.HeaderContainer}>
        <div style={styles.ownerInfoContainer}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text
              strong
              style={{
                fontSize: 36,
                color: "#2c3e50",
                margin: 0,
                lineHeight: "initial",
              }}
            >
              Digital Wallet
            </Text>

            <Text style={{ color: "#2c3e50", fontSize: 24 }}>
              {!!personInfo.profileUrl && <Avatar size={"large"} src={personInfo.profile_url}></Avatar>} {personInfo?.fullName ?? ""}
            </Text>
            <Text style={{ color: "#2c3e50" }}>
              Shared to {walletInfo?.email ?? ""}
            </Text>
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <HourglassOutlined style={{ marginRight: 10 }} />
            <Text style={{ color: "#7f8c8d" }}>
              <Text strong style={{ color: "#34495e" }}>
                Expiration date:
              </Text>
              {` ${walletInfo?.expireOn?.substring(0, 10) ?? ''}`}
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <MessageOutlined style={{ marginRight: 10, marginTop: 5 }} />
            <Text style={{ maxWidth: "50ch", color: "#7f8c8d" }}>
              <Text strong style={{ color: "#34495e" }}>
                Note:
              </Text>
              {` ${walletInfo?.remark ?? 'N/A'}`}
            </Text>
          </div>
        </div>
      </div>
      <div style={styles.ContentContainer}>
        {badges && (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <SwitchComponent
                defaultChecked={showByCategory}
                onChange={setShowByCategory}
              />
              <p style={{ margin: "0 0 0 10px" }}>Show By Category</p>
            </div>
            {showByCategory && <div style={{ marginBottom: 20 }} />}
          </>
        )}
        {badges ? (
          showByCategory ? (
            Object.keys(badges).map((key, index) => (
              <div key={`${badges[key].categoryName}-${index}`}>
                <BadgesList
                  sectionTitle={badges[key].categoryName}
                  sectionDarkColor={badges[key].color}
                  sectionLightColor={badges[key].lightColor}
                  badgesData={badges[key].data}
                  onClick={showModal}
                />
                {Object.keys(badges).length !== index + 1 && (
                  <div style={{ marginTop: 70 }} />
                )}
              </div>
            ))
          ) : (
            <BadgesList
              sectionTitle=""
              badgesData={fussionBadgesData}
              onClick={showModal}
            />
          )
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No badges found!</span>}
            style={styles.emptyContainer}
          />
        )}
      </div>
      {modalData && (
        <>
          <SkillDetailsModal
            isModalOpen={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Close"
            data={modalData}
          />
        </>
      )}
    </div>
  );
};

const Wallet = () => {
  return (
    <Switch>
      <Route exact path="/wallet/:id" component={WalletComponent} />
    </Switch>
  );
};

export default Wallet;
