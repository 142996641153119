import ErrorHandler from "./../errorResponseHandler";
import Instance from "./../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminRegistrationReasonAPI {
    static getRegistrationReasons() {
        return axios
            .get(`/registrationReasons`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createRegistrationReason(registrationReason) {
        return axios
            .post(`/registrationReasons`, registrationReason)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateRegistrationReason(id, registrationReason) {
        return axios
            .put(`/registrationReasons/${id}`, registrationReason)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteRegistrationReason(id) {
        return axios
            .delete(`/registrationReasons/${id}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static moveRegistrationReasonUp(id) {
        return axios
            .post(`/registrationReasons/${id}/moveup`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static moveRegistrationReasonDown(id) {
        return axios
            .post(`/registrationReasons/${id}/movedown`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default AdminRegistrationReasonAPI;
