import * as CONSTANTS from "../common/constant";
import * as authActions from "../store/actions/authAction";
import * as countryActions from "../store/actions/countryAction";
import * as jobActions from "../store/actions/jobAction";
import * as registerReasonActions from "../store/actions/registerReasonAction";
import { dismisAlert, generateAlert } from "../utils/alertUtils";
import changeInput from "../utils/changeInput";
import { contentTranslator } from "../utils/translatorUtils";
import { ContentWrapper } from "./common/ContentWrapper";
import Captcha from "./common/captcha/captcha";
import ContentTranslator from "./translator/ContentTranslator";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Alert, Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalHeader, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const titleStyle = {
    fontFamily: "IBMPlexSans-Bold",
    fontSize: "38px",
    align: "left",
};

const descStyle = {
    fontFamily: "IBMPlexSans-Light",
    fontSize: "14px",
    align: "left",
};

const noteStyle = {
    fontSize: 10,
    lineHeight: 1.5,
};

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
const reasonAssessmentOptions = CONSTANTS.REASON_ASSESSMENT_OPTIONS;

class FormRegister extends React.Component {
    static displayName = FormRegister.name;

    constructor(props) {
        super(props);

        this.state = {
            countries: [],
            registerReasons: [],
            error: null,
            alerts: [],
            loading: false,
            reasonAssessmentOptions: reasonAssessmentOptions,
            roleBasedSurveyJobs: [],
            form: {
                firstName: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                lastName: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                email: {
                    validation: {
                        required: true,
                        isEmail: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                phoneNumber: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                country: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                city: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                jobTitle: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                reasonAssessment: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                roleBasedSurvey: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                roleBasedSurveyId: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                subscribe: {
                    validation: {
                        required: true,
                        isBool: true,
                    },
                    value: false,
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                captcha: {
                    validation: {
                        required: true,
                        isBool: true,
                    },
                    value: false,
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
            },
            isFormValid: false,
            emailChecking: false,
            emailCheckingExist: null,
        };

        this.alertTimer = null;
        this.initData = this.initData.bind(this);

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        this.initData();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    initData() {
        this.props.onCountry.getCountries().then(() => {
            let countries = [
                {
                    label: "Select Country",
                    value: "",
                },
            ];
            if (this.props.countries) {
                countries.push(...this.props.countries.map((country) => ({ label: country.name, value: country.name })));
            }
            this.setState({ countries });
        });

        this.props.onRegisterReason.getRegisterReasons().then(() => {
            let registerReasons = [
                {
                    label: "Select Reason",
                    value: "",
                },
            ];
            if (this.props.registerReasons) {
                registerReasons.push(...this.props.registerReasons.map((registerReason) => ({ label: registerReason.label, value: registerReason.value })));
            }
            this.setState({ registerReasons });
        });

        if (this.props.containerConfig && this.props.containerConfig.showRoleBasedSurveyInSelfRegistration) {
            this.props.onJob.getRoleBasedSurveyJobs().then(() => {
                if (!this.props.jobLoading) {
                    let roleBasedSurveyJobs = [
                        {
                            label: "Select Role",
                            value: "",
                        },
                    ];

                    roleBasedSurveyJobs.push(...this.props.jobs.map((job) => ({ label: job.name, value: job.id })));
                    this.setState({ roleBasedSurveyJobs });
                }
            });
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        let updateState = changeInput(this.state.form, name, value);

        if (name == "roleBasedSurveyId") {
            const job = this.state.roleBasedSurveyJobs.find((it) => it.value == value);
            if (job) {
                updateState = changeInput(updateState.form, "roleBasedSurvey", job.label);
            } else {
                updateState = changeInput(updateState.form, "roleBasedSurvey", "");
            }
        }

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    timer = () =>
        setTimeout(() => {
            this.checkEmailExist();
        }, 1000);

    handleEmailKeyUp = (event) => {
        clearTimeout(this.timeoutID);
        this.timeoutID = this.timer();
    };

    handleEmailLostFocus = (event) => {
        clearTimeout(this.timeoutID);
        this.checkEmailExist();
    };

    checkEmailExist = () => {
        const { form } = this.state;

        if (form && form.email && form.email.valid && form.email.isValidFormat) {
            this.setState({ emailChecking: true, emailCheckingExist: null });

            this.props.onAuth.authRegisterEmailChecking(this.state.form.email.value).then(() => {
                if (!this.props.loadingCheckEmail) {
                    if (this.props.errorCheckEmail) {
                        this.setState({ emailCheckingExist: true });
                    } else {
                        this.setState({ emailCheckingExist: false });
                    }
                }
                this.setState({ emailChecking: this.props.loadingCheckEmail });
            });
        }
    };

    handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;

        const updateState = changeInput(this.state.form, name, checked);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleCaptchaRefresh() {}

    handleCaptchaChange = (value) => {
        const updateState = changeInput(this.state.form, "captcha", value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    submitForm = (event) => {
        event.preventDefault();
        this.setState({ error: null, loading: true });

        const person = {
            firstName: this.state.form.firstName.value,
            lastName: this.state.form.lastName.value,
            email: this.state.form.email.value,
            phoneNumber: this.state.form.phoneNumber.value,
            salutation: this.state.form.firstName.value + " " + this.state.form.lastName.value,
            position: this.state.form.jobTitle.value,
            personType: "Participant",
            countryCode: this.state.form.country.value,
            country: this.state.form.country.value,
            location: this.state.form.city.value,
            selfAssessmentReason: this.state.form.reasonAssessment.value,
            roleBasedSurveyId: this.state.form.roleBasedSurveyId.value && this.state.form.roleBasedSurveyId.value != "" ? this.state.form.roleBasedSurveyId.value : null,
            roleBasedSurvey: this.state.form.roleBasedSurvey.value && this.state.form.roleBasedSurvey.value != "" ? this.state.form.roleBasedSurvey.value : null,
        };

        this.props.onAuth.authRegister(person).then(() => {
            if (!this.props.loading) {
                if (this.props.error) {
                    this.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    //console.log('this.props.registerToken', this.props.registerToken);
                    this.props.history.push("/thankyou");
                }
            }
            this.setState({ loading: this.props.loading });
        });
    };

    modalLoading() {
        return (
            <Modal isOpen={this.state.loading} backdrop="static" keyboard={false} size="sm">
                <ModalHeader>
                    Please wait...
                    <Spinner animation="border" role="status" style={{ marginLeft: "20px" }}></Spinner>
                </ModalHeader>
            </Modal>
        );
    }

    render() {
        const { countries, reasonAssessmentOptions, form, registerReasons, roleBasedSurveyJobs } = this.state;
        const containerConfig = this.props.containerConfig;

        return (
            <ContentWrapper>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.modalLoading()}
                {this.state.error && (
                    <Alert color="danger" isOpen={this.state.error} fade={true} id="divAlert" tabIndex="0">
                        {this.state.error.errData.Message}
                    </Alert>
                )}
                <Row xs="1" md="2">
                    <Col>
                        {containerConfig && containerConfig.selfRegistrationWording ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: this.props.defaultCompany ? containerConfig.selfRegistrationWording.replace("[companyname]", this.props.defaultCompany.name) : containerConfig.selfRegistrationWording,
                                }}
                            ></div>
                        ) : (
                            <React.Fragment>
                                <p className="color-dark" style={titleStyle}>
                                    Apply for employment
                                </p>
                                <p className="color-dark" style={descStyle}>
                                    If you are interested in working for {this.props.defaultCompany && this.props.defaultCompany.name} as a Digital professional, we invite you register for a self-assessment.
                                    <br />
                                    <br />
                                    The assessment uses the Skills Framework for the Information Age (SFIA).{" "}
                                    <a href="https://skillstx.com/about-sfia/" target="_blank" rel="noreferrer">
                                        Learn More
                                    </a>
                                    .<br />
                                    <br />
                                    After completing the self-assessment we will provide you access to a career planner to review and maintain your skill profile.
                                    <br />
                                    <br />
                                    IMPORTANT: The self-assessment is NOT a test but it is very important that you are honest and take your time to study the options carefully.
                                </p>
                            </React.Fragment>
                        )}
                    </Col>
                    <Col>
                        <Form onSubmit={this.submitForm}>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="font-weight-bold" for="lblFirstName">
                                            <ContentTranslator page="Registration" name="RegistrationFirstName" contentText="First Name" />
                                        </Label>
                                        <Input type="text" name="firstName" id="inputFirstName" value={form.firstName.value} invalid={form.firstName.touched && !form.firstName.valid} onChange={this.handleChange} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="font-weight-bold" for="lblLastName">
                                            <ContentTranslator page="Registration" name="RegistrationLastName" contentText="Last Name" />
                                        </Label>
                                        <Input type="text" name="lastName" id="inputLastName" value={form.lastName.value} invalid={form.lastName.touched && !form.lastName.valid} onChange={this.handleChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label className="font-weight-bold" for="lblEmail">
                                    <ContentTranslator page="Registration" name="RegistrationEmail" contentText="Email" />
                                </Label>
                                <Input
                                    type="email"
                                    name="email"
                                    id="inputEmail"
                                    value={form.email.value}
                                    invalid={form.email.touched && (!form.email.valid || !form.email.isValidFormat)}
                                    disabled={this.state.emailChecking}
                                    onChange={(e) => {
                                        this.handleChange(e);
                                        this.handleEmailKeyUp(e);
                                    }}
                                    onKeyUp={this.handleEmailKeyUp}
                                    onBlur={this.handleEmailLostFocus}
                                />
                                {form.email.value != "" && !form.email.isValidFormat && (
                                    <FormFeedback>
                                        <ContentTranslator page="Registration" name="RegistrationIncorrectEmail" contentText="Incorrect email format" />
                                    </FormFeedback>
                                )}
                                {this.state.emailChecking && (
                                    <React.Fragment>
                                        <Spinner size="sm" animation="grow" style={{ marginRight: "10px" }}></Spinner>Email checking
                                    </React.Fragment>
                                )}
                                {!this.state.emailChecking &&
                                    this.state.emailCheckingExist != null &&
                                    (this.state.emailCheckingExist == true && this.props.errorCheckEmail ? <span style={{ color: "#ff0000", fontSize: "14px" }}>{this.props.errorCheckEmail.errData.Message}</span> : <span style={{ color: "green", fontSize: "14px" }}>Email Address Available</span>)}
                            </FormGroup>
                            <FormGroup>
                                <Label className="font-weight-bold" for="lblPhoneNumber">
                                    <ContentTranslator page="Registration" name="RegistrationPhoneNumber" contentText="Phone Number" />
                                </Label>
                                <Input type="text" name="phoneNumber" id="inputPhoneNumber" value={form.phoneNumber.value} onChange={this.handleChange} />
                            </FormGroup>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="font-weight-bold" for="lblCountry">
                                            <ContentTranslator page="Registration" name="RegistrationCountry" contentText="Country" />
                                        </Label>
                                        <Input type="select" name="country" id="inputCountry" value={form.country.value} invalid={form.country.touched && !form.country.valid} onChange={this.handleChange}>
                                            {countries.map((country, key) => (
                                                <option key={key} value={country.value}>
                                                    {country.label}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="font-weight-bold" for="lblCity">
                                            <ContentTranslator page="Registration" name="RegistrationNearestCity" contentText="Nearest Major City" />
                                        </Label>
                                        <Input type="text" name="city" id="inputCity" value={form.city.value} invalid={form.city.touched && !form.city.valid} onChange={this.handleChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label className="font-weight-bold" for="lblJob">
                                    <ContentTranslator page="Registration" name="RegistrationRecentJob" contentText="Current or most recent job title" />
                                </Label>
                                <Input type="text" name="jobTitle" id="inputJob" value={form.jobTitle.value} invalid={form.jobTitle.touched && !form.jobTitle.valid} onChange={this.handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label className="font-weight-bold" for="lblReason">
                                    <ContentTranslator page="Registration" name="RegistrationReason" contentText="Main reason for the self-assessment" />
                                </Label>
                                <Input type="select" name="reasonAssessment" id="inputReason" value={form.reasonAssessment.value} invalid={form.reasonAssessment.touched && !form.reasonAssessment.valid} onChange={this.handleChange}>
                                    {registerReasons.map((it, key) => (
                                        <option key={key} value={it.value}>
                                            {it.label}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            {this.props.containerConfig && this.props.containerConfig.showRoleBasedSurveyInSelfRegistration && roleBasedSurveyJobs && roleBasedSurveyJobs.length > 1 && (
                                <FormGroup>
                                    <Label className="font-weight-bold" for="lblReason">
                                        Role based survey
                                    </Label>
                                    <Input type="select" name="roleBasedSurveyId" id="roleBasedSurveyId" value={form.roleBasedSurveyId.value} invalid={form.roleBasedSurveyId.touched && !form.roleBasedSurveyId.valid} onChange={this.handleChange}>
                                        {roleBasedSurveyJobs.map((it, key) => (
                                            <option key={key} value={it.value}>
                                                {it.label}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            )}
                            <FormGroup>
                                <Captcha onChange={this.handleCaptchaChange} placeholder={this.contentTranslator(this.props.contentTranslations, "Registration", "RegistrationCaptchaPlaceholder", "Enter captcha")} onRefresh={this.handleCaptchaRefresh} />
                                {/* {form.captcha.touched && !form.captcha.valid && <div class="invalid-feedback" style={{ display: "block" }}>Incorrect captcha</div>} */}
                            </FormGroup>
                            <FormGroup check style={{ display: "flex" }}>
                                <Input type="checkbox" name="subscribe" id="formSubscribe" checked={form.subscribe.value} invalid={form.subscribe.touched && !form.subscribe.valid} style={{ marginRight: "10px" }} onChange={this.handleChangeCheckBox} />
                                <Label for="formSubscribe" check style={noteStyle}>
                                    <span>
                                        <ContentTranslator page="Registration" name="RegistrationContactPermission" contentText="Allow us to contact you via email. You can unsubscribe at any time and we do NOT provide your details to 3rd parties." />
                                    </span>
                                </Label>
                            </FormGroup>
                            <br />
                            <Button disabled={!this.state.isFormValid || this.state.emailChecking || this.state.emailCheckingExist} block color="primary" style={{ height: "55px", fontFamily: "IBMPlexSans-Bold" }}>
                                {!this.state.loading ? <ContentTranslator page="Registration" name="RegistrationButtonContinue" contentText="Continue" /> : <Spinner type="grow" size="md" color="light" />}
                            </Button>
                            <Row className="text-center">
                                <Col>
                                    <a href="https://skillstx.com/wp-content/uploads/2018/08/STX_PrivacyPolicy_v4.pdf" target="_blank" rel="noreferrer">
                                        <span style={noteStyle}>
                                            <ContentTranslator page="Registration" name="RegistrationPrivacyPolicyLink" contentText="View our privacy policy relating to the self-assessment." />
                                        </span>
                                    </a>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                {/* </LoadingOverlay> */}
            </ContentWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loadingRegister,
        error: state.auth.errorRegister,
        registerToken: state.auth.registerToken,

        loadingCheckEmail: state.auth.loadingCheckEmail,
        errorCheckEmail: state.auth.errorCheckEmail,
        emailCheckingExist: state.auth.emailCheckingExist,

        countries: state.country.countries,
        contentTranslations: state.content.contentTranslations,
        registerReasons: state.registerReason.registerReasons,

        jobs: state.job.jobs,
        jobLoading: state.job.loading,

        defaultCompany: state.company.defaultCompany,
        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //onRegister: bindActionCreators(userActions, dispatch),
        onAuth: bindActionCreators(authActions, dispatch),
        onCountry: bindActionCreators(countryActions, dispatch),
        onRegisterReason: bindActionCreators(registerReasonActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormRegister));
