import ErrorHandler from "./../errorResponseHandler";
import Instance from "./../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminCategoryAPI {
    static getExtraFrameworkCapabilities() {
        return axios
            .get(`/categories/extraFrameworkCapabilities`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getExtraFrameworkLevels() {
        return axios
            .get(`/categories/extraFrameworkLevels`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createExtraFrameworkLevel(level) {
        return axios
            .post(`/categories/extraFrameworkLevels`, level)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateExtraFrameworkLevel(id, level) {
        return axios
            .put(`/categories/extraFrameworkLevels/${id}`, level)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteExtraFrameworkLevel(id) {
        return axios
            .delete(`/categories/extraFrameworkLevels/${id}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static moveExtraFrameworkLevelUp(id) {
        return axios
            .post(`/categories/extraFrameworkLevels/${id}/moveup`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static moveExtraFrameworkLevelDown(id) {
        return axios
            .post(`/categories/extraFrameworkLevels/${id}/movedown`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getExtraFrameworkCategories() {
        return axios
            .get(`/categories/extraFrameworks`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createExtraFrameworkCategory(category) {
        return axios
            .post(`/categories/extraFrameworks`, category)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateExtraFrameworkCategory(categoryId, category) {
        return axios
            .put(`/categories/extraFrameworks/${categoryId}`, category)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteExtraFrameworkCategory(categoryId) {
        return axios
            .delete(`/categories/extraFrameworks/${categoryId}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createExtraFrameworkSubCategory(categoryId, subCategory) {
        return axios
            .post(`/categories/extraFrameworks/${categoryId}/subCategories`, subCategory)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateExtraFrameworkSubCategory(categoryId, subCategoryId, subCategory) {
        return axios
            .put(`/categories/extraFrameworks/${categoryId}/subCategories/${subCategoryId}`, subCategory)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteExtraFrameworkSubCategory(categoryId, subCategoryId) {
        return axios
            .delete(`/categories/extraFrameworks/${categoryId}/subCategories/${subCategoryId}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createExtraFrameworkSkill(categoryId, subCategoryId, skill) {
        return axios
            .post(`/categories/extraFrameworks/${categoryId}/subCategories/${subCategoryId}/skills`, skill)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateExtraFrameworkSkill(categoryId, subCategoryId, skillId, skill) {
        return axios
            .put(`/categories/extraFrameworks/${categoryId}/subCategories/${subCategoryId}/skills/${skillId}`, skill)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteExtraFrameworkSkill(categoryId, subCategoryId, skillId) {
        return axios
            .delete(`/categories/extraFrameworks/${categoryId}/subCategories/${subCategoryId}/skills/${skillId}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static exportExtraFrameworksDataExcel() {
        return axios
            .get(`/categories/extraFrameworks/export`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static exportExtraFrameworksDataExcelTemplate() {
        return axios
            .get(`/categories/extraFrameworks/exporttemplate`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static importExtraFrameworksDataExcel(extraFrameworksData) {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        const body = new FormData();
        body.append("ContentDisposition", "attachment");
        body.append("ContentType", "multipart/form-data");

        extraFrameworksData.forEach((file) => {
            body.append("extraFrameworksData", file);
        });

        return axios
            .post(`/categories/extraFrameworks/import`, body, config)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default AdminCategoryAPI;
