import {
  InfoCircleOutlined,
  UpOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Table, Tag, Tooltip, Row, Typography, Empty } from "antd";
import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";

const { Title } = Typography;

const WrapperTable = styled.div`
  white-space: nowrap;
  border-top: none;
  margin-top: 20px;

  .ant-table.ant-table-middle
    .ant-table-tbody
    .ant-table-wrapper:only-child
    .ant-table {
    margin: 0px;
  }

  .table-s .ant-table-container table tr .ant-table-cell-row-hover {
    background: rgb(116, 226, 177, 0.2) !important;
  }

  .anticon {
    vertical-align: 1px !important;
  }

  .ant-table-cell:has(.p-zero) {
    padding: 0px !important;
  }

  .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed .ant-table,
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: 0px;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table {
    border-top-left-radius: 0x;
    border-top-right-radius: 0px;
  }

  .ant-table {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  .font-sm {
    font-size: small;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table {
    table-layout: auto;
    border-color: gainsboro;
    border-style: solid;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-width: 0px;
  }

  .ant-table-content > table {
    table-layout: auto;
    border-color: gainsboro;
    border-style: solid;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    border-width: 0.5px;
  }
  .op-0 {
    opacity: 0;
  }
  .ant-table-cell:has(.b-none) {
    border-color: white !important;
    transition: background 0.3s;
  }

  .table-s .ant-table-body {
    max-height: 300px;
  }
  .ant-table-cell:has(.c-lv) {
    text-align: center;
  }

  .ant-table-cell:has(.c-w) {
    width: 50px !important;
  }

  .ant-table-cell:has(.e-w) {
    width: 80px !important;
  }

  .ant-table-container table > thead > tr:first-child th {
    background: ${(props) =>
      props?.customStyle?.tableHeaderBackgroundColor || ""};
    color: ${(props) => props?.customStyle?.tableHeaderColor || ""};
    text-align: center;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    table
    > thead
    > tr:first-child
    th {
    background: #fafafa;
    color: black;
    text-align: center;
    cursor: pointer;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    table
    > thead
    > tr:first-child
    th:first-child {
    border-top-left-radius: 0px;
    text-align: start;
    width: auto !important;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 14px;
    text-align: start;
    width: 280px;
  }

  .ant-table-cell {
    padding: 10px;
  }
  .ant-table-row-expand-icon {
    display: none;
  }

  .ant-table-row-indent {
    padding-left: 0 !important;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    table
    > thead
    > tr:first-child
    td:first-child {
    border-top-left-radius: 0px;
    width: auto !important;
  }

  .table-s .ant-table-column-sorters {
    text-align: center;
  }

  .item-center {
    align-items: center;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    table
    > thead
    > tr:first-child
    th:last-child {
    border-top-right-radius: 0px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 14px;
  }

  .rotate-180 {
    transform: rotate(180deg);
    transition: transform 0.5s;
  }
  .rotate-0 {
    transform: rotate(0deg);
    transition: transform 0.5s;
  }

  /*== start of code for tooltips ==*/
  .tool {
    cursor: help;
    position: relative;
  }

  /*== common styles for both parts of tool tip ==*/
  .tool::before,
  .tool::after {
    left: 50%;
    opacity: 0;
    position: absolute;
    z-index: -100;
  }
  .flex {
    display: flex;
  }
  .mt-15 {
    margin-top: 15px;
  }
  .ml-10 {
    margin-left: 10px;
  }

  .tool:hover::before,
  .tool:focus::before,
  .tool:hover::after,
  .tool:focus::after {
    opacity: 1;
    transform: scale(1) translateY(0);
    z-index: 100;
  }

  /*== pointer tip ==*/
  .tool::before {
    border-style: solid;
    border-width: 1em 0.75em 0 0.75em;
    border-color: #3e474f transparent transparent transparent;
    bottom: 100%;

    content: "";
    margin-left: -0.5em;
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26),
      opacity 0.65s 0.5s;
    transform: scale(0.6) translateY(-90%);
  }

  .tool:hover::before,
  .tool:focus::before {
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
  }

  /*== speech bubble ==*/
  .tool::after {
    background: #3e474f;
    border-radius: 0.25em;
    bottom: 135%;
    text-wrap: balance;
    height: auto;
    color: #edeff0;
    content: attr(data-tip);
    margin-left: -16em;
    padding: 1em;
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
    transform: scale(0.6) translateY(50%);
    width: 18em;
  }

  .tool:hover::after,
  .tool:focus::after {
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26);
  }

  @media (max-width: 760px) {
    .tool::after {
      font-size: 0.75em;
      margin-left: -5em;
      width: 10em;
    }
  }

  @media (max-width: 1080px) {
    .sm-grid {
      display: grid;
    }
  }
  .circle {
    border-radius: 10px;
    width: 10px;
    height: 10px;
  }
  .pd-10 {
    padding: 10px;
  }
  .grid {
    display: grid;
  }
  .mr-10 {
    margin-right: 10px;
  }
`;

const WrapperTitleCustom = styled.div`
  text-align: center;
  margin-top: -7px;
  cursor: pointer;
`;

const WrapperTooltipCustom = styled(Tooltip)`
  cursor: help;
  margin-top: 7px;
`;

const WrapperLevelCustom = styled.div`
  padding: 5px;
`;

const WrapperDivToolCustom = styled.div`
  padding: 10px;
`;

const WrapperDivToolMTCustom = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
`;

const WrapperDivCustom = styled.div`
  padding-top: 5px;
  display: flex;
  align-items: center;
`;

const WrapperCircleCustom = styled.div`
  border-radius: 10px;
  width: 10px;
  margin-left: 10px;
  height: 10px;
`;

const WrapperCellCustom = styled.div`
  text-align: center;
  margin-top: -7px;
  cursor: pointer;
`;

const WrapperSlide = styled.div`
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  animation: slide 0.2s;
  @keyframes slide {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;

const WrapperSubCell = styled.div`
  width: auto !important;
  text-wrap: wrap;
`;

const DivCenter = ({ Component }) => {
  return (
    <div className="p-grid p-align-center vertical-container">
      <div className="layout-wrapper">
        <Component />
      </div>
    </div>
  );
};

const constantData = {
  titleLevel: 5,
  tableSize: "default",
  expandedTableSize: "default",
  skillLevelCount: 7,
  categoryName: "category",
  expandName: "expand",
  bottomName: "bottom",
  levelName: "Level",
  categoryColors: [
    {
      name: "Strategy and architecture",
      bold: "#EE2A53",
      tint: "#f8a9ba",
    },
    {
      name: "Change and transformation",
      bold: "#CE559F",
      tint: "#ebbbd8",
    },
    {
      name: "Delivery and operation",
      bold: "#B26720",
      tint: "#e0c2a5",
    },
    {
      name: "Development and implementation",
      bold: "#f3c716",
      tint: "#fae8a1",
    },
    {
      name: "People and skills",
      bold: "#00a5e4",
      tint: "#99dbf4",
    },
    {
      name: "Relationship and engagement",
      bold: "#00a752",
      tint: "#99dbb9",
    },
  ],
};

const createCenteredTextColumnHeader = (title) => (
  <span style={{ textWrap: "wrap" }}>{title}</span>
);

// Helper function to generate a table column that centers its text content.
// This avoids repetition for multiple columns that require similar formatting.
const createCenteredTextColumn = (title, dataIndex, sorterFunction) => ({
  title: createCenteredTextColumnHeader(title), // Column header
  dataIndex, // Data key
  key: dataIndex, // Key for React's list rendering
  sorter: sorterFunction, // Sorting function for the column
  render: (text) => (
    <WrapperSubCell className="text-center">{text ?? "-"}</WrapperSubCell>
  ), // Centering the text
});

// Utility function to compare text values
const sortByText = (a, b) => (a ?? "").localeCompare(b ?? "");

const SkillsWeHaveNeedTable = ({
  data = null,
  isNeedSkill = false,
  isShowDetail = true,
  isSpilt = false,
}) => {
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [listExpand, setListExpand] = useState([]);
  const [expendedRowKeysRef, setExpendedRowKeysRef] = useState({
    name: null,
    keys: [],
  });
  const [expendedRowKeysCurrent, setExpendedRowKeysCurrent] = useState({
    name: null,
    keys: [],
  });

  const onExpandedRowRendered = (record) => {
    // Transform the record's people data into the format suitable for the table.
    const dataSource = record.people.map((item, index) => ({
      // Map over the filtered items
      key: `${index + 1}`,
      ...item, // Spread the properties of each item
    }));

    // Define columns for the table. These determine how data is displayed in the table.
    let columns;
    if (isNeedSkill) {
      columns = [
        createCenteredTextColumn("Requirement Name", "jobName", (a, b) =>
          sortByText(a.jobName, b.jobName)
        ),
        createCenteredTextColumn("Sub-Category", "subCategory", (a, b) =>
          sortByText(a.subCategory, b.subCategory)
        ),
        createCenteredTextColumn("Skill Name", "skill", (a, b) =>
          sortByText(a.skill, b.skill)
        ),
        {
          title: createCenteredTextColumnHeader("Skill Level"),
          dataIndex: "skillLevel",
          key: "skillLevel",
          sorter: (a, b) =>
            sortByText(
              `${a.skillCode} ${a.level}`,
              `${b.skillCode} ${b.level}`
            ),
          render: (text, record) => (
            <div className="text-center">
              <Tooltip title={`${record.skill} Level ${record.level}`}>
                <Tag
                  color={record.subCategoryDetail.colour}
                  style={{ color: "black" }}
                >
                  {`${record.skillCode} ${record.level}`}
                </Tag>
              </Tooltip>
            </div>
          ),
        },

        createCenteredTextColumn("Status", "need", (a, b) =>
          sortByText(a.need, b.need)
        ),
      ];
    } else {
      columns = [
        createCenteredTextColumn("Full Name", "fullName", (a, b) =>
          sortByText(a.fullName, b.fullName)
        ),
        createCenteredTextColumn("Sub-Category", "subCategory", (a, b) =>
          sortByText(a.subCategory, b.subCategory)
        ),
        createCenteredTextColumn("Skill Name", "skill", (a, b) =>
          sortByText(a.skill, b.skill)
        ),
        {
          title: createCenteredTextColumnHeader("Skill Level"),
          dataIndex: "skillLevel",
          key: "skillLevel",
          sorter: (a, b) =>
            sortByText(
              `${a.skillCode} ${a.level}`,
              `${b.skillCode} ${b.level}`
            ),
          render: (text, record) => (
            <div className="text-center">
              <Tooltip title={`${record.skill} Level ${record.level}`}>
                <Tag
                  color={record.subCategoryDetail.colour}
                  style={{ color: "black" }}
                >
                  {`${record.skillCode} ${record.level}`}
                </Tag>
              </Tooltip>
            </div>
          ),
        },
        {
          title: createCenteredTextColumnHeader("Status"),
          dataIndex: "profileStatus",
          key: "profileStatus",
          sorter: (a, b) => sortByText(a.profileStatus, b.profileStatus),
          render: (text, record) => (
            <div className={`text-center ${isSpilt ? "grid" : "sm-grid"}`}>
              <span className="mr-10">{record.profileType}</span>
              <Tag
                icon={
                  record.isApproved ? (
                    <CheckCircleOutlined />
                  ) : (
                    <CloseCircleOutlined />
                  )
                }
                color={record.isApproved ? "success" : "error"}
              >
                {record.isApproved ? "Approved " : "Not Approved "}
              </Tag>
            </div>
          ),
        },
      ];
    }

    // Return the formatted table contained within a styled wrapper.
    return (
      <WrapperSlide className="p-zero">
        <div className="table-s">
          <Table
            scroll={{ x: true, y: true }}
            size={constantData.expandedTableSize} // Set table size from constants
            pagination={false} // Disable pagination
            columns={columns} // Use the defined columns
            dataSource={dataSource} // Use the transformed data source
          />
        </div>
      </WrapperSlide>
    );
  };

  // This function determines if a row is expandable based on the 'isSkill' attribute.
  const onRowExpandable = (record) => {
    // Directly return the boolean value of 'levels' attribute of the record.
    // If the attribute isn't present, it defaults to false.
    return record.levels.length > 0;
  };

  const onExpandCellRendered = (text, record, index) => {
    // Find the corresponding expandable item from the listExpand array using the record's category.
    const findExpand = listExpand.find((_) => _.name === record.category);

    return (
      <div className="b-none e-w">
        {/* 
        Conditional rendering: 
        If 'isShowExpand' attribute of the record is true, then render the custom cell wrapper. 
        Otherwise, nothing will be rendered inside the div. 
      */}
        {record.isShowExpand && (
          <WrapperCellCustom
            // Apply rotation CSS class based on the 'isExpand' attribute of the found item.
            className={findExpand?.isExpand ? "rotate-0" : "rotate-180"}
            // Set the onClick handler to call 'onExpendClicked' with the found item.
            onClick={() => onExpendClicked(findExpand, record)}
          >
            <UpOutlined />
          </WrapperCellCustom>
        )}
      </div>
    );
  };

  const onCategoryCellRendered = (text, record, index) => {
    // Render the chosen component inside a div with the given class.
    // The title and placement properties are consistent for both components.
    return (
      <div className="b-none">
        <Tooltip
          title={record.meta.description}
          placement={constantData.bottomName}
        >
          {text}
        </Tooltip>
      </div>
    );
  };

  const onExpandCellTitleRendered = (meta) => {
    // Define the custom title for the tooltip:
    // It contains the name of the level responsibility and a list of levels beneath it.
    const customTitle = () => (
      <div>
        {/* Display the name of the level responsibility */}
        <WrapperLevelCustom>{meta.levelResponsibility.name}</WrapperLevelCustom>

        {/* Map through the levels and display each one */}
        {meta.levelResponsibility.levels.map((item, index) => (
          <WrapperLevelCustom key={index}>
            {`${constantData.levelName} ${item.level} : ${item.name}`}
          </WrapperLevelCustom>
        ))}
      </div>
    );

    // Render the custom title wrapped in a 'WrapperTitleCustom' component.
    // It contains an expandable icon and an informational icon within a tooltip.
    return (
      <WrapperTitleCustom className="h-bg-c e-w">
        {/* Information Icon wrapped in a tooltip */}
        <WrapperTooltipCustom
          title={customTitle}
          placement={constantData.bottomName}
        >
          <InfoCircleOutlined />
        </WrapperTooltipCustom>
      </WrapperTitleCustom>
    );
  };

  const onExpendClicked = (obj, record) => {
    if (!obj) return; // Early exit if obj is falsy

    const tempExpandStatus = !obj.isExpand;

    listExpand.forEach((_) => (_.isExpand = false));
    const updatedListExpand = [...listExpand]; // Create a copy of the listExpand array

    if (tempExpandStatus === true) {
      // Toggle the expand status of the clicked item
      obj.isExpand = tempExpandStatus;
    }

    setListExpand(updatedListExpand); // Update state with the new list
    setExpendedRowKeysRef((prevState) => ({
      ...prevState, // Keep other properties of the state unchanged
      name: tempExpandStatus ? record.category : "",
      keys: tempExpandStatus ? [record.key] : [],
    }));
  };

  // Renders cell content based on the level value
  const onCellRendered = (text, record, index, lv) => {
    // Filter out the levels based on the provided `lv`.
    const findLevels = record.levels.filter((item) => item.lv === lv);

    // Create a map to keep track of the count of each code.
    // This helps in avoiding multiple filters and loops on the same array.
    const codeCounts = {};
    for (let item of findLevels) {
      if (!codeCounts[item.code]) {
        codeCounts[item.code] = 1; // Initialize count if code is seen for the first time.
      } else {
        codeCounts[item.code]++; // Increment count for the code.
      }
    }

    // Extract unique skill codes.
    const uniqueCodes = Object.keys(codeCounts);

    // Render the tooltip content.
    const dataTip = () => (
      <WrapperDivToolCustom>
        <div>Skill Level: {lv}</div>
        <WrapperDivToolMTCustom>
          Count Of Skills Code: {findLevels.length}
        </WrapperDivToolMTCustom>
        {uniqueCodes.map((code, idx) => (
          <WrapperDivCustom key={idx}>
            <span>
              {code}
              {/* If a code appears more than once, show its count. */}
              {` (${codeCounts[code]})`}
            </span>
            {/* Display a circle with background color from record meta. */}
            <WrapperCircleCustom
              style={{ backgroundColor: record.meta.bold }}
            ></WrapperCircleCustom>
          </WrapperDivCustom>
        ))}
      </WrapperDivToolCustom>
    );

    // Render the main cell content. If there are matching levels, display a tooltip.
    return (
      <div className="b-none text-center c-w">
        {findLevels.length > 0 && (
          <Tooltip title={dataTip}>
            <div style={{ color: "#545350" }}>{findLevels.length}</div>
          </Tooltip>
        )}
      </div>
    );
  };

  /**
   * Determines the styling and interactivity of cells based on the given record and level.
   * This function primarily checks the level and adjusts the cell style based on the record's details.
   */
  const onLevelColorCellRendered = (record, index, lv) => {
    // This is a default style for a cell. Used for skill-based records when no specific level styling is found.
    const defaultStyle = {
      backgroundColor: record.meta.tint,
      borderStyle: "solid",
      borderWidth: "0.5px",
    };

    // If the record has no levels defined, just return the default styling.
    const findLevels = record.levels.filter((_) => _.lv === lv);
    if (findLevels.length === 0) return { style: defaultStyle };

    return {
      // Set up the cell's styling based on the found level's value.
      style: {
        cursor: "help",
        // Determine the cell's background color based on the value.
        backgroundColor: record.meta.bold,
        // Adjust text color depending on the value.
        //color: val < constantData.colorLevel ? "black" : "white",
        borderStyle: "solid",
        borderWidth: "0.5px",
      },
    };
  };

  // A simple tooltip renderer for cell titles
  const onCellTitleRendered = (text, description) => (
    <Tooltip placement={constantData.bottomName} title={description}>
      {text}
    </Tooltip>
  );

  const getDataSource = () => {
    if (data?.subCategories) {
      // If the root is expanded, handle both subcategories and their skills
      return data.subCategories.flatMap((item, index) => {
        const { name, description, people } = item;
        const findCategoryColor = constantData.categoryColors.find(
          (_) => _.name === name
        );
        // Construct base data for the sub-category
        const baseData = {
          key: `${index + 1}`,
          category: name,
          isShowExpand: true,
          meta: {
            description,
            tint: findCategoryColor ? findCategoryColor.tint : item.tint ? item.tint : item.styles.skillColour,
            bold: findCategoryColor ? findCategoryColor.bold : item.bold ? item.bold : item.styles.cateColour,
          },
          levels: people.map((_) => ({
            lv: _.level,
            code: _.skillCode,
          })),
          people,
        };

        // Check if the current sub-category is in the list of expanded items
        const findExpand = listExpand.find(
          (expandItem) => expandItem.name === name
        );

        // If found but it's not expanded, only return the base data
        if (findExpand && !findExpand.isExpand) {
          return [baseData];
        }
        // Return a combined array of the base sub-category data and all its skills data
        return [baseData];
      });
    }
    return [];
  };

  const getColumns = () => {
    if (data?.subCategories) {
      // Initial columns for category and expand cells
      const initialColumns = [
        {
          title: () => onCellTitleRendered(data.name, data.description),
          dataIndex: constantData.categoryName,
          key: constantData.categoryName,
          render: onCategoryCellRendered,
        },
        {
          title: () => onExpandCellTitleRendered(data.meta),
          key: constantData.expandName,
          dataIndex: constantData.expandName,
          render: onExpandCellRendered,
        },
      ];

      // Generate columns for each skill level
      const skillLevelColumns = Array.from(
        { length: constantData.skillLevelCount },
        (_, lv) => {
          const level = lv + 1;
          return {
            title: `${level}`,
            dataIndex: `${level}`,
            key: `${level}`,
            onCell: (record, index) =>
              onLevelColorCellRendered(record, index, level),
            render: (text, record, index) =>
              onCellRendered(text, record, index, level),
          };
        }
      );

      // Combine and return all columns
      return [...initialColumns, ...skillLevelColumns];
    }
    return [];
  };

  // useMemo calculates and memoizes the table data based on its dependencies.
  // It will only re-compute the table data when either data, listExpand, or isContentLoaded changes.
  const tableData = useMemo(() => {
    if (isContentLoaded === false || data === undefined || data === null)
      return { dataSource: [], columns: [] };
    // Get the data source using the provided getDataSource function
    const dataSource = getDataSource();

    // Get the columns using the provided getColumns function
    const columns = getColumns();

    // Return the computed values in an object
    return { dataSource, columns };
  }, [data, listExpand, isContentLoaded]); // Memoization depends on these three values.

  // This useEffect is responsible for handling the side-effect when the content is not yet loaded.
  // It updates the state by marking the content as loaded and sets the initial expanded subcategories.
  useEffect(() => {
    // Check if the content has not been loaded yet
    if (!isContentLoaded) {
      // Mark content as loaded
      setIsContentLoaded(true);

      // Generate an array of expanded subcategories from the data
      let tempListExpand = [];
      if (data?.subCategories) {
        tempListExpand =
          data?.subCategories.map((subCategory) => ({
            name: subCategory.name,
            isExpand: false,
          })) || [];
      }

      // Update the listExpand state by appending the new expanded subcategories.
      // We use a functional update to safely access and update the previous state.
      setListExpand((prevListExpand) => [...prevListExpand, ...tempListExpand]);
    }
  }, [data, isContentLoaded]); // The effect depends on the data and the isContentLoaded flag.

  // useEffect for handling the side-effect related to listExpand updates
  useEffect(() => {
    const find = listExpand.find(
      (item) => item.isExpand === false && item.name === expendedRowKeysRef.name
    );

    if (find) {
      // Reset expendedRowKeysCurrent to its default values
      setExpendedRowKeysCurrent((prevState) => ({
        ...prevState,
        name: null,
        keys: [],
      }));
    }
  }, [listExpand, expendedRowKeysRef.name]);

  // useEffect for handling the side-effect related to expendedRowKeysRef updates
  useEffect(() => {
    // Use a functional state update for expendedRowKeysCurrent since its new value depends on the previous state
    setExpendedRowKeysCurrent((prevState) => {
      const temp = { ...prevState };

      if (expendedRowKeysRef.keys.length < 1) {
        temp.name = null;
        temp.keys = [];
      } else if (
        temp.keys.length > 0 &&
        temp.keys[0] === expendedRowKeysRef.keys[0]
      ) {
        temp.name = null;
        temp.keys = [];
      } else {
        temp.name = expendedRowKeysRef.name;
        temp.keys = [...expendedRowKeysRef.keys];
      }
      return temp;
    });
  }, [expendedRowKeysRef]);

  return (
    <>
      <Title level={constantData.titleLevel}>
        Skills by Category{" "}
        {isSpilt && (
          <span className="vs-h-sm-h">{`(${
            isNeedSkill ? "Skill We Need" : "Skill We Have"
          })`}</span>
        )}
      </Title>
      {tableData.dataSource.length > 0 ? (
        <WrapperTable customStyle={data?.styles}>
          <div className="table-m">
            <Table
              scroll={{ x: true }}
              size={constantData.tableSize}
              columns={tableData.columns}
              pagination={false}
              expandable={{
                showExpandColumn: false,
                expandedRowRender: onExpandedRowRendered,
                rowExpandable: onRowExpandable,
                expandedRowKeys: expendedRowKeysCurrent.keys,
              }}
              dataSource={tableData.dataSource}
            />
          </div>
          {isShowDetail && (
            <Row className="mt-15">
              {data?.subCategories.map((item, index) => {
                const find = constantData.categoryColors.find(
                  (_) => _.name === item.name
                );
                return (
                  <div
                    className="flex text-center item-center pd-10"
                    key={index}
                  >
                    <span className="font-sm ">{item.name}</span>
                    <div
                      className="ml-10 circle"
                      style={{ backgroundColor: find ? find.bold : item.styles.cateColour }}
                    ></div>
                  </div>
                );
              })}
            </Row>
          )}
        </WrapperTable>
      ) : (
        <DivCenter Component={() => <Empty description={"No Data"} />} />
      )}
    </>
  );
};

export { SkillsWeHaveNeedTable };
