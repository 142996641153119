import * as actionLogActions from "../../../store/admin/actions/adminActionLogActions";
import { formatDate_localeString } from "../../../utils/stringUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import { faFileExcel, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Multiselect from "multiselect-react-dropdown";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");

const rowStyle = {
    fontSize: "12px",
};

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "18px",
    fontFamily: "IBMPlexSans-Bold",
};

class ManageActionLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actionLogs: [],
            isUpdating: false,
            showDialog: false,
            showUpdateDialog: false,
            updateMode: null,
            touched: false,
            searchProperties: ["type", "firstName", "lastName", "action", "email"],
            startDate: null,
            endDate: null,
            actionTypeOptions: [
                { value: "Query", label: "Query" },
                { value: "Command", label: "Command" },
            ],
            selectedActionTypeOptions: [{ value: "Command", label: "Command" }],
            actionOptions: [],
            selectedActionOptions: [],
        };

        this.formatDate_localeString = formatDate_localeString.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 100);

        var today = new Date();
        var weekAgo = this.addDays(new Date(), -7);

        this.setState(
            {
                startDate: weekAgo,
                endDate: today,
            },
            () => {
                this.searchData();
            }
        );
    }

    addDays = (date, days) => {
        date.setDate(date.getDate() + days);
        return date;
    };

    pad2 = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    handleRefresh = () => {
        this.searchData();
    };

    searchData = () => {
        var { startDate, endDate } = this.state;
        const selectedTypes = this.state.selectedActionTypeOptions.map((it) => it.value);

        this.setState({ loading: true });
        this.props.onActionLog.getActionLogs(startDate.getFullYear() + "-" + this.pad2(startDate.getMonth() + 1) + "-" + this.pad2(startDate.getDate()), endDate.getFullYear() + "-" + this.pad2(endDate.getMonth() + 1) + "-" + this.pad2(endDate.getDate())).then(() => {
            if (!this.props.loading) {
                const actions = [
                    "SignIn",
                    "SignOut",
                    ...new Set(
                        this.props.actionLogs
                            .filter((it) => it.action != "SignIn" && it.action != "SignOut")
                            .sort((a, b) => (a.action > b.action ? 1 : -1))
                            .map((it) => it.action)
                    ),
                ];

                this.setState({
                    actionLogs: this.props.actionLogs.filter((it) => selectedTypes.includes(it.type)),

                    actionOptions: actions.map((it) => {
                        return {
                            value: it,
                            label: it,
                        };
                    }),
                    selectedActionOptions: actions.map((it) => {
                        return {
                            value: it,
                            label: it,
                        };
                    }),
                });
            }

            this.setState({ loading: this.props.loading });
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
        }
    }

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({ showDialog: !showDialog });
    };

    handleDateChange = (name, value) => {
        if (name == "startDate") {
            this.setState({ startDate: new Date(value) });
        } else {
            this.setState({ endDate: new Date(value) });
        }
    };

    handleSelectType = (selectedList, selectedItem) => {
        const selectedTypes = selectedList.map((it) => it.value);
        const selectedActions = this.state.selectedActionOptions.map((it) => it.value);
        this.setState({ selectedActionTypeOptions: [...selectedList], actionLogs: [...this.props.actionLogs.filter((it) => selectedTypes.includes(it.type) && selectedActions.includes(it.action))] });
    };

    handleRemoveType = (selectedList, selectedItem) => {
        const selectedTypes = selectedList.map((it) => it.value);
        const selectedActions = this.state.selectedActionOptions.map((it) => it.value);
        this.setState({ selectedActionTypeOptions: [...selectedList], actionLogs: [...this.props.actionLogs.filter((it) => selectedTypes.includes(it.type) && selectedActions.includes(it.action))] });
    };

    handleSelectAction = (selectedList, selectedItem) => {
        const selectedTypes = this.state.selectedActionTypeOptions.map((it) => it.value);
        const selectedActions = selectedList.map((it) => it.value);
        this.setState({ selectedActionOptions: [...selectedList], actionLogs: [...this.props.actionLogs.filter((it) => selectedTypes.includes(it.type) && selectedActions.includes(it.action))] });
    };

    handleRemoveAction = (selectedList, selectedItem) => {
        const selectedTypes = this.state.selectedActionTypeOptions.map((it) => it.value);
        const selectedActions = selectedList.map((it) => it.value);
        this.setState({ selectedActionOptions: [...selectedList], actionLogs: [...this.props.actionLogs.filter((it) => selectedTypes.includes(it.type) && selectedActions.includes(it.action))] });
    };

    handleExport = () => {
        var { startDate, endDate } = this.state;
        const timezoneOffset = (new Date().getTimezoneOffset() * -1) / 60;
        const selectedTypes = this.state.selectedActionTypeOptions.map((it) => it.value);
        const selectedActions = this.state.selectedActionOptions.map((it) => it.value);

        this.props.onActionLog
            .exportActionLogsExcel({
                timezoneOffset,
                startDate: startDate.getFullYear() + "-" + this.pad2(startDate.getMonth() + 1) + "-" + this.pad2(startDate.getDate()),
                endDate: endDate.getFullYear() + "-" + this.pad2(endDate.getMonth() + 1) + "-" + this.pad2(endDate.getDate()),
                selectedTypes,
                selectedActions,
            })
            .then(() => {
                if (!this.props.loadingPrint && !this.props.error) {
                    if (this.props.printData) {
                        var today = new Date();
                        var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                        FileDownload(this.props.printData, `ExportAuditLogs_${strToday}.xlsx`);
                    }
                }
            });
    };

    render() {
        const { actionLogs, searchKeyword, searchProperties, actionTypeOptions, selectedActionTypeOptions, actionOptions, selectedActionOptions } = this.state;
        const startDate = this.state.startDate ? this.formatDate_localeString(new Date(this.state.startDate)) : null;
        const endDate = this.state.endDate ? this.formatDate_localeString(new Date(this.state.endDate)) : null;

        const columns = [
            {
                id: "personId",
                name: "User",
                selector: (row) => row.personId,
                sortable: true,
                width: "18%",
                format: function (row, index) {
                    return `${row.firstName} ${row.lastName}`;
                },
                wrap: true,
            },
            {
                id: "email",
                name: "Email",
                selector: (row) => row.email,
                sortable: true,
                width: "20%",
                wrap: true,
            },
            {
                id: "actionDate",
                name: "Date",
                selector: (row) => row.actionDate,
                sortable: true,
                width: "12%",
                format: function (row, index) {
                    return new Intl.DateTimeFormat("en-GB", {
                        year: "2-digit",
                        month: "short",
                        day: "numeric",
                        hourCycle: "h23",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                    }).format(new Date(row.actionDate));
                },
            },
            {
                id: "type",
                name: "Type",
                selector: (row) => row.type,
                sortable: true,
                width: "10%",
                wrap: true,
            },
            {
                id: "action",
                name: "Action",
                selector: (row) => row.action,
                sortable: true,
                width: "40%",
                wrap: true,
            },
        ];

        return (
            <React.Fragment>
                <p className={"page-title"} style={headerStyle}>
                    Audit Logs
                </p>
                <div style={{ marginBottom: "10px" }}>
                    <Row style={{ paddingBottom: "10px" }}>
                        <Col md="5" style={{ display: "flex" }}>
                            <div>
                                <DatePicker selected={startDate ? new Date(startDate) : null} dateFormat="dd/MM/yyyy" onChange={(value) => this.handleDateChange("startDate", value)} disabled={this.state.loading} className="form-control" />
                            </div>
                            &nbsp;&nbsp;to&nbsp;&nbsp;
                            <div>
                                <DatePicker selected={endDate ? new Date(endDate) : null} dateFormat="dd/MM/yyyy" onChange={(value) => this.handleDateChange("endDate", value)} disabled={this.state.loading} className="form-control" />
                            </div>
                        </Col>
                        <Col md="2">
                            <Button
                                color="primary"
                                onClick={this.handleRefresh}
                                style={{
                                    fontSize: "16px",
                                    height: "40px",
                                }}
                                disabled={this.props.loading || this.state.isUpdating}
                            >
                                <FontAwesomeIcon icon={faMagnifyingGlass} /> Search
                            </Button>
                        </Col>
                        <Col md="4">
                            <SkillsTxTableSearchBar
                                searchKeyword={searchKeyword}
                                onChange={(searchKeyword) => {
                                    this.setState({ searchKeyword });
                                }}
                            ></SkillsTxTableSearchBar>
                        </Col>
                        <Col md="1">
                            <SkillsTxTableClearSearch
                                style={{
                                    fontSize: "16px",
                                    height: "40px",
                                }}
                                color="primary"
                                disabled={this.props.loading || this.state.isUpdating}
                                onClear={() => {
                                    this.setState({ searchKeyword: "" });
                                }}
                            ></SkillsTxTableClearSearch>
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: "10px" }}>
                        <Col md="5">
                            <Multiselect onSelect={this.handleSelectType} onRemove={this.handleRemoveType} options={actionTypeOptions} selectedValues={selectedActionTypeOptions} displayValue="label" showCheckbox placeholder="Action Type" />
                        </Col>
                        {/* <Col md="5">
                            <Multiselect onSelect={this.handleSelectAction} onRemove={this.handleRemoveAction} options={actionOptions} selectedValues={selectedActionOptions} displayValue="label" showCheckbox placeholder="Action" />
                        </Col> */}
                        <Col md="2">
                            <Button
                                color="primary"
                                onClick={this.handleExport}
                                style={{
                                    fontSize: "16px",
                                    height: "40px",
                                }}
                                disabled={this.props.loading || this.state.loadingPrint}
                            >
                                <FontAwesomeIcon icon={faFileExcel} /> Export
                            </Button>
                        </Col>
                    </Row>
                </div>
                <SkillsTxTable columns={columns} data={actionLogs} pagination={true} searchKeyword={searchKeyword} searchProperties={searchProperties} progressPending={this.props.loading}></SkillsTxTable>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.adminActionLog.loading,
        error: state.adminActionLog.error,
        actionLogs: state.adminActionLog.actionLogs,
        isUpdating: state.adminActionLog.isUpdating,

        loadingPrint: state.adminActionLog.loadingPrint,
        printData: state.adminActionLog.printData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onActionLog: bindActionCreators(actionLogActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageActionLogs);
