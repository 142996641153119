import * as adminBadgeRequestActions from "../../../store/admin/actions/adminBadgeRequestActions";
import { faCircleCheck, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Input, Popover, PopoverBody, PopoverHeader, Row, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

class BadgeRequestAssessorCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popOverUpdateAssessorOpen: false,
            persons: [],
            assessorName: this.props.assessorName,
            assessorId: this.props.assessorId,
            newAssessorId: this.props.assessorId,
            dirty: false,
            isUpdating: false,
        };
    }

    componentDidMount() {
        const persons =
            this.props.persons &&
            this.props.persons
                .filter((it) => it.roles.some((it) => it.role == "Admin") && ((it.id != this.props.authPersonId && this.props.authPersonId == this.props.personId) || this.props.authPersonId != this.props.personId))
                .map((it) => {
                    return { value: it.id, label: `${it.firstName} ${it.lastName}` };
                })
                .sort((a, b) => (`${a.label}` > `${b.label}` ? 1 : -1));

        this.setState({ persons: [{ label: "None", value: null }, ...persons] });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.persons !== this.props.persons && this.props.persons) {
            const persons =
                this.props.persons &&
                this.props.persons
                    .filter((it) => it.roles.some((it) => it.role == "Admin") && ((it.id != this.props.authPersonId && this.props.authPersonId == this.props.personId) || this.props.authPersonId != this.props.personId))
                    .map((it) => {
                        return { value: it.id, label: `${it.firstName} ${it.lastName}` };
                    })
                    .sort((a, b) => (`${a.label}` > `${b.label}` ? 1 : -1));

            this.setState({ persons: [{ label: "None", value: null }, ...persons] });
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    togglePopover = () => {
        const popOverUpdateAssessorOpen = this.state.popOverUpdateAssessorOpen;
        const assessorId = this.state.assessorId;

        this.setState({ popOverUpdateAssessorOpen: !popOverUpdateAssessorOpen, dirty: false, isUpdating: false, newAssessorId: assessorId });
    };

    openUpdateAssignment = () => {
        const popOverUpdateAssessorOpen = this.state.popOverUpdateAssessorOpen;
        this.setState({ popOverUpdateAssessorOpen: !popOverUpdateAssessorOpen });
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({ newAssessorId: value && value != "None" ? value : null, dirty: true });
    };

    handleUpdateAssessor = () => {
        const { newAssessorId, badgeRequest, persons } = this.state;

        let assessor = null;

        if (newAssessorId) {
            assessor = persons.find((it) => it.value == newAssessorId);
        }

        this.setState({ isUpdating: true });
        this.props.onBadgeRequest.updateBadgeRequestAssessor(this.props.badgeRequest.id, newAssessorId, assessor ? assessor.label : null).then(() => {
            if (!this.props.isUpdating && !this.props.error) {
                if (!newAssessorId) {
                    this.setState({ assessorName: null, assessorId: null });
                } else if (assessor) {
                    this.setState({ assessorName: assessor.label, assessorId: assessor.value });
                }

                this.setState({ popOverUpdateAssessorOpen: false });
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    render() {
        const badgeRequest = this.props.badgeRequest;
        const { assessorName, assessorId, persons, newAssessorId } = this.state;

        return (
            <React.Fragment>
                <div style={{ padding: "5px 0px" }}>
                    {this.props.allowEdit ? (
                        <Link to="#" id={`LKEndorser${badgeRequest.id}`} onClick={() => this.openUpdateAssignment()}>
                            {assessorId ? assessorName : "None"}
                        </Link>
                    ) : (
                        <span>{assessorId ? assessorName : ""}</span>
                    )}
                    {this.props.allowEdit && (
                        <React.Fragment>
                            <UncontrolledTooltip target={`LKEndorser${badgeRequest.id}`} placement="bottom">
                                Update Assessor
                            </UncontrolledTooltip>
                            <Popover placement="bottom" isOpen={this.state.popOverUpdateAssessorOpen} target={`LKEndorser${badgeRequest.id}`} toggle={this.togglePopover}>
                                <PopoverHeader>
                                    <Row>
                                        <Col sm="8">Update Assessor</Col>
                                        <Col sm="4" style={{ display: "flex" }}>
                                            <button className={this.state.dirty ? "btn-primary" : "btn-secondary"} disabled={!this.state.dirty || this.state.isUpdating} onClick={this.handleUpdateAssessor}>
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                            </button>
                                            &nbsp;
                                            <button className={`btn-danger`} onClick={this.togglePopover} disabled={this.state.isUpdating}>
                                                <FontAwesomeIcon icon={faCancel} />
                                            </button>
                                        </Col>
                                    </Row>
                                </PopoverHeader>
                                <PopoverBody>
                                    <Row>
                                        <Col sm="12">
                                            <Input type="select" name="assessorId" id="inputassessor" value={newAssessorId} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                {persons.map((person, key) => (
                                                    <option key={key} value={person.value}>
                                                        {person.label}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                    </Row>
                                </PopoverBody>
                            </Popover>
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUpdating: state.adminPerson.isUpdating,
        error: state.adminPerson.error,
        authPersonId: state.auth.personId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onBadgeRequest: bindActionCreators(adminBadgeRequestActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BadgeRequestAssessorCell);
