import * as adminCategoryActions from "../../../store/admin/actions/adminCategoryActions";
import changeInput from "../../../utils/changeInput";
import React from "react";
import { SketchPicker, ChromePicker } from "react-color";
import { connect } from "react-redux";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";

class ExtraFrameworkSkillDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDialog: false,
            isUpdating: false,
            form: {
                name: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                description: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                skillsCode: {
                    validation: {
                        required: true,
                        minLength: 4,
                        maxLength: 4,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
            },
            isFormValid: false,
            levels: [],
        };
    }

    componentDidMount() {
        let levels = [...this.props.extraFrameworkLevels];
        this.setState({ levels });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
        }

        if (prevProps.skill !== this.props.skill || prevProps.extraFrameworkLevels !== this.props.extraFrameworkLevels) {
            this.setState({
                category: this.props.category,
                subCategory: this.props.subCategory,
                skill: this.props.skill,
            });

            this.prepareForm(this.props.skill);
        }
    }

    prepareForm = (skill) => {
        this.setState({
            form: {
                name: {
                    validation: {
                        required: true,
                    },
                    value: skill ? skill.name : "",
                    valid: skill ? true : false,
                    isValidFormat: skill ? true : false,
                    touched: false,
                },
                description: {
                    validation: {
                        required: true,
                    },
                    value: skill ? skill.description : "",
                    valid: skill ? true : false,
                    isValidFormat: skill ? true : false,
                    touched: false,
                },
                skillsCode: {
                    validation: {
                        required: true,
                        minLength: 4,
                        maxLength: 4,
                    },
                    value: skill ? skill.skillsCode : "",
                    valid: skill ? true : false,
                    isValidFormat: skill ? true : false,
                    touched: false,
                },
            },
            isFormValid: skill ? true : false,
        });

        let levels = [...this.props.extraFrameworkLevels];
        levels.forEach((level) => {
            if (skill && skill.levels) {
                var skillLevel = skill.levels.find((it) => it.extraFrameworkLevelId == level.id);
                if (skillLevel) {
                    level.selected = true;
                    level.skillLevelId = skillLevel.id;
                    level.description = skillLevel.description;
                } else {
                    level.selected = false;
                    level.skillLevelId = null;
                    level.description = null;
                }
            } else {
                level.selected = false;
                level.skillLevelId = null;
                level.description = null;
            }
        });

        this.setState({ levels });
    };

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({
            showDialog: !showDialog,
        });

        this.prepareForm(this.props.skill);
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleChangeSelectLevel = (levelId) => {
        const { levels } = this.state;
        const level = levels.find((it) => it.id == levelId);
        if (level) {
            level.selected = !level.selected;
        }

        this.setState({ levels: [...levels] });
    };

    handleChangeLevelDescription = (levelId, event) => {
        const { name, value } = event.target;

        const { levels } = this.state;
        const level = levels.find((it) => it.id == levelId);
        if (level) {
            level.description = value;
        }

        this.setState({ levels: [...levels] });
    };

    handlePerformSave = () => {
        const { form, levels } = this.state;

        const skill = {
            name: form.name.value,
            description: form.description.value,
            skillsCode: form.skillsCode.value,
            levels: levels
                .filter((it) => it.selected)
                .map((level) => {
                    return {
                        id: level.skillLevelId ? level.skillLevelId : null,
                        extraFrameworkLevelId: level.id,
                        description: level.description,
                    };
                }),
        };

        this.setState({ isUpdating: true });
        if (this.props.skill && this.props.skill.id) {
            this.props.onAdminCategory.updateExtraFrameworkSkill(this.props.category.id, this.props.subCategory.id, this.props.skill.id, skill).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.error) {
                        this.props.generateAlert("success", "Update skill success.");
                        this.togleDialogModal();
                    } else {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    }
                }
                this.setState({ isUpdating: this.props.isUpdating });
            });
        } else {
            this.props.onAdminCategory.createExtraFrameworkSkill(this.props.category.id, this.props.subCategory.id, skill).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.error) {
                        this.props.generateAlert("success", "Add skill success.");
                        this.togleDialogModal();
                    } else {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    }
                }
                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    render() {
        const { form, levels } = this.state;
        const { category, subCategory, skill, extraFrameworkLevels } = this.props;

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={this.togleDialogModal} size="xl">
                    <ModalHeader toggle={this.togleDialogModal}>{skill && skill.id ? "Update Skill" : "Add Skill"}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup row>
                                        <Label sm={4}>Name</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="name" id="name" value={form.name.value} invalid={form.name.touched && !form.name.isValidFormat} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Skill Code</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="skillsCode" maxLength={4} minLength={4} id="skillsCode" value={form.skillsCode.value} invalid={form.skillsCode.touched && !form.skillsCode.isValidFormat} onChange={this.handleChange} />
                                            {form.skillsCode.value != "" && !form.skillsCode.isValidFormat && <FormFeedback>Skills code length must be 4 characters</FormFeedback>}
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup row>
                                        <Label sm={4}>Description</Label>
                                        <Col sm={8}>
                                            <Input type="textarea" name="description" id="description" value={form.description.value} invalid={form.description.touched && !form.description.isValidFormat} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">&nbsp;</Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup row>
                                        <Label sm={4} style={{ fontWeight: "bold" }}>
                                            Levels
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                {levels.map((level, index) => {
                                    return (
                                        <Col sm="6" key={index}>
                                            <FormGroup row>
                                                <Col sm={4}>
                                                    <Input
                                                        type="checkbox"
                                                        name={`chkLevel${level.id}`}
                                                        id={`chkLevel${level.id}`}
                                                        style={{ marginRight: "5px" }}
                                                        checked={level.selected ? level.selected : false}
                                                        disabled={this.state.isUpdating}
                                                        onChange={() => this.handleChangeSelectLevel(level.id)}
                                                    />
                                                    <Label check for={`chkLevel${level.id}`}>
                                                        {`${index + 1}). ${level.level}`}
                                                    </Label>
                                                </Col>
                                                <Col sm={8}>
                                                    <Input
                                                        type="textarea"
                                                        name={`leveldescription${levels.id}`}
                                                        id={`leveldescription${levels.id}`}
                                                        value={level.description ? level.description : ""}
                                                        disabled={!level.selected}
                                                        onChange={(event) => this.handleChangeLevelDescription(level.id, event)}
                                                        placeholder="Level description"
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.handlePerformSave();
                            }}
                            disabled={!(this.state.isFormValid && this.state.levels.filter((it) => it.selected == true).length > 0 && this.state.levels.filter((it) => it.selected == true && (it.description == "" || it.description == undefined)).length == 0)}
                        >
                            Save
                        </Button>
                        <Button color="secondary" onClick={this.togleDialogModal} disabled={this.state.isImporting}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.adminCategory.error,
        isUpdating: state.adminCategory.isUpdating,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAdminCategory: bindActionCreators(adminCategoryActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraFrameworkSkillDetail);
