import { filterArray } from "../../utils/filterUtils";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Spinner } from "reactstrap";

const customStyles = {
    rows: {
        style: {
            verticalAlign: "top",
        },
    },
    headCells: {
        style: {
            fontWeight: "bold",
            fontSize: "14px",
            border: "1px solid #dee2e6",
            whiteSpace: "normal",
        },
    },
    cells: {
        style: {
            padding: "5px 10px",
            fontSize: "12px",
            border: "1px solid #dee2e6",
        },
    },
};

const rowSelectCritera = (row) => row.toggleSelected && row.toggleSelected == true;

const caseInsensitiveSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
        const aField = selector(rowA);
        const bField = selector(rowB);

        const fieldType = typeof aField;

        let comparison = 0;

        if (fieldType == "string") {
            const a = aField ? aField.toLowerCase() : "";
            const b = bField ? bField.toLowerCase() : "";

            if (a > b) {
                comparison = 1;
            }

            if (b > a) {
                comparison = -1;
            }
        } else {
            if (aField > bField) {
                comparison = 1;
            } else if (aField < bField) {
                comparison = -1;
            }
        }

        return direction === "desc" ? comparison * -1 : comparison;
    });
};

export class SkillsTxTable extends Component {
    static displayName = SkillsTxTable.name;

    constructor(props) {
        super(props);

        this.state = {
            clearSelectedRow: null,
            data: [...this.props.data],
        };
        this.filterArray = filterArray.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectableRows !== this.props.selectableRows) {
            this.setState({ toggledClearRows: !this.state.toggledClearRows });
            this.setState({ data: [...this.props.data] });
        }

        if (prevProps.data !== this.props.data) {
            this.setState({ data: [...this.props.data] });
        }
    }

    handleRowClick = (row, event) => {
        if (this.props.selectableRows) {
            this.props.handleRowClick(row);
        }
    };

    render() {
        const { title, columns, defaultSortFieldId, pagination, searchKeyword, searchProperties, headCellsStyle, cellsStyle, columnSearchKeywords } = this.props;

        let filtered = this.filterArray(this.state.data, searchKeyword, searchProperties, columnSearchKeywords);
        
        return (
            <DataTable
                title={title}
                columns={columns}
                sortFunction={caseInsensitiveSort}
                data={filtered}
                customStyles={{ rows: customStyles.rows, headCells: headCellsStyle ? headCellsStyle : customStyles.headCells, cells: cellsStyle ? cellsStyle : customStyles.cells }}
                defaultSortFieldId={defaultSortFieldId}
                pagination={pagination}
                selectableRowsHighlight={this.props.selectableRowsHighlight}
                clearSelectedRows={this.state.toggledClearRows}
                onRowClicked={this.handleRowClick}
                selectableRowSelected={rowSelectCritera}
                fixedHeader={this.props.fixedHeader ? this.props.fixedHeader : false}
                fixedHeaderScrollHeight={this.props.fixedHeaderScrollHeight ? this.props.fixedHeaderScrollHeight : "300px"}
                progressPending={this.props.progressPending}
                noTableHead={this.props.noTableHead ? this.props.noTableHead : false}
                persistTableHead={true}
                progressComponent={
                    <div style={{ width: "100%", textAlign: "center", padding: "10px", border: "1px solid #dee2e6" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                }
                paginationRowsPerPageOptions={[10, 20, 50, 100, 300]}
            />
        );
    }
}
