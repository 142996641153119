import React from 'react'

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 10px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
  }
};

const Button = ({children, icon, onClick, primary}) => {
  return (
    <div style={{...styles.container, backgroundColor: primary ? '#6599FF' : '', border: primary ? '' : "1px solid #000000"}} onClick={() => {
      onClick?.()}}>
      {icon && <div style={styles.iconContainer}>{icon}</div>}
      {children}
    </div>
  )
}

export default Button;
