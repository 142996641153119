export const generateAlert = function generateAlert(type, message) {
    const alerts = this.state.alerts;

    this.setState({
        alerts: [...alerts,
        {
            id: new Date().getTime(),
            type: type,
            message: message
        }
        ]
    })
}

export const dismisAlert = function dismisAlert(alert) {
    const alerts = this.state.alerts;
    const idx = alerts.indexOf(alert);
    if (idx >= 0) {
        this.setState({ alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)] })
    }
}
