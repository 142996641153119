import MentorPreferenceModal from "../../components/trienpont/components/modals/mentorpreferences";
import PossibleMentorModal from "../../components/trienpont/components/modals/possiblementor";
import * as actionPlanActions from "../../store/actions/actionPlanAction";
import * as personAction from "../../store/actions/personAction";
import { contentTranslator } from "../../utils/translatorUtils";
import ChatbotOpenAi from "../ChatbotOpenAi";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import ContentTranslator from "../translator/ContentTranslator";
import FormAddAction from "./FormAddAction";
import { TableActionPlan } from "./TableActionPlan";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const headerStyle = {
    color: "#2B2B2B",
    fontSize: "32px",
    fontFamily: "IBMPlexSans-Bold",
};

const ddHeaderStyle = {
    fontsize: "14px",
    fontFamily: "IBMPlexSans-Bold",
};

const buttonStyle = {
    marginLeft: "10px",
};

class ManageActionPlan extends React.Component {
    static displayNama = ManageActionPlan.name;

    constructor(props) {
        super(props);

        this.state = {
            showActionForm: false,
            showAll: false,
            showHiddenSkills: false,
            allowUpdate: true,
            returnedOptionsActionSkills: this.props.optionsActionSkills,
            showMentorPreferenceDialog: false,
            isBtnMentorPreference: false,
            showPossibleMentorDialog: false,
        };
        this.handleAddActionClick = this.handleAddActionClick.bind(this);
        this.handleActionfinish = this.handleActionfinish.bind(this);
        this.handleActionPlanAdded = this.handleActionPlanAdded.bind(this);
        this.myManageActionWrapper = React.createRef();
        this.contentTranslator = contentTranslator.bind(this);
        this.updatePersonMentorPreference = this.updatePersonMentorPreference.bind(this);
        this.updatePersonMentorPreference();
    }

    componentDidMount() {
        //  relationship for allow
        const listRelationshipType = ["Line Manager", "Mentor"];

        if (!this.props.managedPeople || (this.props.managedPeople && listRelationshipType.some((item) => item.toLocaleLowerCase().trim() === this.props.managedPeople.relationshipType?.toLocaleLowerCase().trim()))) {
            this.setState({ allowUpdate: true });
        } else {
            this.setState({ allowUpdate: false });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.stepActionClicked !== this.props.stepActionClicked) {
            if (this.props.stepActionClicked) {
                this.scroll(this.myManageActionWrapper);
            }
        }

        if (prevProps.proposeActionStarted !== this.props.proposeActionStarted && this.props.proposeActionStarted == true) {
            this.setState({ showHiddenSkills: false });
        }
    }

    updatePersonMentorPreference() {
        this.props.onPerson.getPersonMentorPreference().then(() => {
            this.setState({
                isBtnMentorPreference: this.props.mentorPreference.some((item) => item.selected),
            });
        });
    }

    handleChageShowAll = () => {
        this.setState({
            showAll: !this.state.showAll,
        });
    };

    handleChageShowHiddenSkills = () => {
        const showHiddenSkills = this.state.showHiddenSkills;
        this.setState({
            showHiddenSkills: !showHiddenSkills,
            showActionForm: false,
        });

        this.props.handleChageShowHiddenSkills(!showHiddenSkills);
    };

    handleChangeProfile = (event) => {
        this.props.handleChangeProfileType(event.value);
    };

    handleChangeJob = (event) => {
        this.props.handleChangeJob(event);
    };

    handleAddActionClick = () => {
        this.setState({
            showActionForm: !this.state.showActionForm,
        });
    };

    handleActionfinish = () => {
        this.setState({
            showActionForm: false,
        });
    };

    handleActionPlanAdded(addedAction) {
        this.setState({
            showActionForm: false,
        });

        this.props.handleActionPlanAdded(addedAction);
    }

    handleOpenMentorPreference = () => {
        this.setState({ showMentorPreferenceDialog: new Date() });
    };

    handleOpenPossibleMentor = () => {
        this.setState({ showPossibleMentorDialog: new Date() });
    };

    handlePrintReport = () => {
        Promise.resolve(this.props.managedPeople ? this.props.onActionPlan.printManagedPeopleActionReport(this.props.managedPeople.personB.email) : this.props.onActionPlan.printMyActionReport()).then(() => {
            if (!this.props.loadingPrint && !this.props.error) {
                if (this.props.printData) {
                    const firstName = this.props.managedPeople ? this.props.managedPeople.personB.firstName : localStorage.getItem("firstName");
                    const lastName = this.props.managedPeople ? this.props.managedPeople.personB.lastName : localStorage.getItem("lastName");

                    let reportFormat = "PDF";
                    if (this.props.selfAssessedProfileReportFormat) {
                        reportFormat = this.props.selfAssessedProfileReportFormat;
                    }

                    FileDownload(this.props.printData, firstName && lastName ? `${firstName}${lastName}_SkillsTx_ActionPlan.${reportFormat == "PDF" ? "pdf" : "docx"}` : 'SkillsTx_ActionPlan.${reportFormat == "PDF" ? "pdf" : "docx"}');
                }
            }
        });
    };

    scroll(ref) {
        ref.current.scrollIntoView({ behavior: "smooth" });
    }

    returnOptionActionSkills = (optionsActionSkills) => {
        this.setState({ returnedOptionsActionSkills: optionsActionSkills });
    };

    renderMentorPreference = () => {
        return (
            <MentorPreferenceModal
                data={this.props.mentorPreference}
                updatePersonMentorPreference={this.updatePersonMentorPreference}
                showDialog={this.state.showMentorPreferenceDialog}
                contentTranslations={this.props.contentTranslations}
                contentTranslator={this.contentTranslator}
            ></MentorPreferenceModal>
        );
    };

    renderPossibleMentor = () => {
        return (
            <PossibleMentorModal
                showDialog={this.state.showPossibleMentorDialog}
                profileType={this.props.selectedOptionProfile ? this.props.selectedOptionProfile.replace("SelfAssessed", "Self Assessed") : "Self Assessed"}
                jobType={this.props.selectedJob ? this.props.selectedJob.type : "Current"}
                contentTranslations={this.props.contentTranslations}
                contentTranslator={this.contentTranslator}
            ></PossibleMentorModal>
        );
    };

    render() {
        let isAdmin = false;

        const authMode = this.props.authMode;
        let authModes = authMode.split(",").map((item) => item.trim());
        if (authModes.filter((it) => it != "Admin").length == 0) {
            isAdmin = true;
        }

        const { optionsJob, optionsProfile, selectedOptionProfile, selectedJob, currAssessment, containerConfig } = this.props;
        const isFindMentor = currAssessment?.enableFindMentor ?? false;
        return (
            <ContentWrapperLg>
                <p ref={this.myManageActionWrapper} className="mb-0" style={headerStyle}>
                    {this.props.managedPeople
                        ? `${this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanManageAccountForTitle", "Manage Action Plan for")} ${this.props.managedPeople.personB.firstName} ${this.props.managedPeople.personB.lastName}`
                        : this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanManageAccountTitle", "Manage Your Action Plan")}
                </p>
                {this.renderMentorPreference()}
                {this.renderPossibleMentor()}
                {containerConfig && containerConfig.enableAIChatBot && <ChatbotOpenAi />}
                <Container className="p-0">
                    <Row className="justify-content-between">
                        <Col xs="8" className="p-0">
                            <Container>
                                <Row xs="1" sm="1" md="1" lg="3">
                                    <Col xs="12" md="6" className="mt-2">
                                        <p className="mb-1" style={ddHeaderStyle}>
                                            <ContentTranslator page="SfiaPlan" name="SfiaPlanChooseProfileType" contentText="Choose Profile Type" />
                                        </p>
                                        <Select value={optionsProfile.filter((option) => option.label === selectedOptionProfile)} onChange={this.handleChangeProfile} options={optionsProfile} />
                                    </Col>
                                    <Col xs="12" md="6" lg="7" className="mt-2">
                                        <p className="mb-1" style={ddHeaderStyle}>
                                            <ContentTranslator page="SfiaPlan" name="SfiaPlanChooseRequirementType" contentText="Choose Requirement Type" />
                                        </p>
                                        <Select value={optionsJob.filter((option) => option.label === selectedJob.label)} onChange={this.handleChangeJob} options={optionsJob} />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col className="align-self-end mt-2">
                            {isFindMentor && !isAdmin && (
                                <>
                                    <button id="btnMentorPreference" className="btn btn-light btmMentor-Style mb-2" style={buttonStyle} onClick={this.handleOpenMentorPreference}>
                                        <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonMenteePreferences" contentText="Mentee Preferences" />
                                    </button>
                                    <UncontrolledTooltip target={`btnMentorPreference`} placement="bottom">
                                        <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanButtonMenteePreferencesTooltip" contentText="Mentee Preferences" />
                                    </UncontrolledTooltip>
                                    <button id="btnPossibleMentor" className="btn btn-light btmMentor-Style mb-2" style={buttonStyle} disabled={!this.state.isBtnMentorPreference} onClick={this.handleOpenPossibleMentor}>
                                        <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonPossibleMentor" contentText="Find a Mentor" />
                                    </button>
                                    <UncontrolledTooltip target={`btnPossibleMentor`} placement="bottom">
                                        <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanButtonPossibleMentorTooltip" contentText="Find a Mentor" />
                                    </UncontrolledTooltip>
                                </>
                            )}
                            {this.props.containerConfig && this.props.containerConfig.allowShowHiddenSkills && (
                                <button id="btnShowHiddenSkills" className="btn btn-light btnSecondary-Style mb-2" style={buttonStyle} onClick={this.handleChageShowHiddenSkills} disabled={this.props.proposeActionStarted}>
                                    {this.state.showHiddenSkills
                                        ? this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanButtonHideHiddenSkills", "Hide Hidden Skills")
                                        : this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanButtonShowHiddenSkills", "Show Hidden Skills")}
                                </button>
                            )}
                            <button id="btnShowAll" className="btn btn-light btnSecondary-Style mb-2" style={buttonStyle} onClick={this.handleChageShowAll}>
                                {this.state.showAll ? this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanButtonHideDetail", "Hide Detail") : this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanButtonShowAll", "Show All")}
                            </button>
                            <UncontrolledTooltip target={`btnShowAll`} placement="bottom">
                                {this.state.showAll ? (
                                    <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanButtonHideDetailTooltip" contentText="Show only your skill gaps" />
                                ) : (
                                    <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanButtonShowAllTooltip" contentText="Show the complete skills framework" />
                                )}
                            </UncontrolledTooltip>
                            {this.state.allowUpdate &&
                                (!this.state.showActionForm ? (
                                    <React.Fragment>
                                        <button id="btnNewAction" className="btn btn-light btnSecondary-Style mb-2" style={{ marginLeft: "10px", width: "100px" }} onClick={this.handleAddActionClick} disabled={this.props.loadingAdd}>
                                            <FontAwesomeIcon icon={faPlus} style={{ fontSize: "13px" }} />
                                            <span className="ml-2">
                                                <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonNewAction" contentText="New Action" />
                                            </span>
                                        </button>
                                        <UncontrolledTooltip target={`btnNewAction`} placement="bottom">
                                            <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanButtonNewActionTooltip" contentText="Open the pop-up to create a new Action for any of your skill gaps" />
                                        </UncontrolledTooltip>
                                    </React.Fragment>
                                ) : (
                                    <button className="btn btn-light btnSecondary-Style mb-2" style={{ marginLeft: "10px", width: "100px" }} onClick={this.handleAddActionClick}>
                                        <FontAwesomeIcon icon={faMinus} style={{ fontSize: "13px" }} />
                                    </button>
                                ))}
                            {this.props.actions && this.props.actions.length > 0 && (
                                <React.Fragment>
                                    <button id="btnPrintActionReport" className="btn btn-light btnSecondary-Style mb-2" style={buttonStyle} onClick={this.handlePrintReport}>
                                        <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonPrint" contentText="Print" />
                                    </button>
                                    <UncontrolledTooltip target={`btnPrintActionReport`} placement="bottom">
                                        <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanButtonPrintTooltip" contentText="Generate a printed report of your Action List" />
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            )}
                        </Col>
                    </Row>
                </Container>
                {this.state.showActionForm && (
                    <div style={{ float: "right" }}>
                        <br />
                        <table
                            style={{
                                fontSize: 11,
                                fontFamily: "IBMPlexSans",
                            }}
                        >
                            <tr>
                                <td className="border-0">
                                    <FormAddAction
                                        showHiddenSkills={this.state.showHiddenSkills}
                                        optionsActionTypes={this.props.optionsActionTypes}
                                        optionsActionSkills={this.state.returnedOptionsActionSkills}
                                        categories={this.props.categories}
                                        jobLorProfiles={this.props.jobLorProfiles}
                                        jobSkillProfiles={this.props.jobSkillProfiles}
                                        lorProfiles={this.props.lorProfiles}
                                        skillProfiles={this.props.skillProfiles}
                                        handleClose={this.handleActionfinish}
                                        handleActionPlanAdded={this.handleActionPlanAdded}
                                        providers={this.props.providers}
                                        optionsJob={this.props.optionsJob}
                                        generateAlert={this.props.generateAlert}
                                    />
                                </td>
                            </tr>
                        </table>
                        <br />
                    </div>
                )}
                <TableActionPlan
                    showHiddenSkills={this.state.showHiddenSkills}
                    showAll={this.state.showAll}
                    selectedJob={this.props.selectedJob}
                    currentJob={this.props.currentJob}
                    futureJob={this.props.futureJob}
                    responsibilityData={this.props.responsibilityData}
                    categories={this.props.categories}
                    extraFrameworkCapabilities={this.props.extraFrameworkCapabilities}
                    lorProfiles={this.props.lorProfiles}
                    skillProfiles={this.props.skillProfiles}
                    actions={this.props.actions}
                    selectedOptionProfile={this.props.selectedOptionProfile}
                    jobLorProfiles={this.props.jobLorProfiles}
                    jobSkillProfiles={this.props.jobSkillProfiles}
                    optionsActionTypes={this.props.optionsActionTypes}
                    optionsActionSkills={this.props.optionsActionSkills}
                    handleActionPlanAdded={this.props.handleActionPlanAdded}
                    providers={this.props.providers}
                    allowUpdate={this.state.allowUpdate}
                    loadingAction={this.props.loadingAction}
                    loadingProfile={this.props.loadingProfile}
                    returnOptionActionSkills={this.returnOptionActionSkills}
                    optionsJob={this.props.optionsJob}
                    generateAlert={this.props.generateAlert}
                />
            </ContentWrapperLg>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loadingPrint: state.actionPlan.loadingPrint,
        printData: state.actionPlan.printData,
        error: state.actionPlan.error,

        managedPeople: state.auth.managedPeople,
        authMode: state.auth.authMode,

        mentorPreference: state.person.mentorPreference,

        contentTranslations: state.content.contentTranslations,

        currAssessment: state.config.currAssessment,
        containerConfig: state.config.containerConfig,

        jobProfileReportFormat: state.auth.jobProfileReportFormat,
        selfAssessedProfileReportFormat: state.auth.selfAssessedProfileReportFormat,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onActionPlan: bindActionCreators(actionPlanActions, dispatch),
        onPerson: bindActionCreators(personAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageActionPlan);
