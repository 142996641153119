import { updateState } from "../../utility";
import * as actionTypes from "../actions/adminActionTypes";

const initialState = {
    error: null,
    loading: false,
    isUpdating: false,
    registrationReasons: [],
};

const getRegistrationReasonsStart = (state) => {
    return updateState(state, { error: null, loading: true, registrationReasons: [] });
};

const getRegistrationReasonsSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        loading: false,
        registrationReasons: action.registrationReasons,
    });
};

const getRegistrationReasonsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const addRegistrationReasonStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const addRegistrationReasonSuccess = (state, action) => {
    const registrationReasons = state.registrationReasons;
    registrationReasons.push(action.addedRegistrationReason);

    return updateState(state, {
        error: null,
        isUpdating: false,
        registrationReasons: [...registrationReasons],
    });
};

const addRegistrationReasonFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateRegistrationReasonStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateRegistrationReasonSuccess = (state, action) => {
    const updatedRegistrationReason = action.updatedRegistrationReason;
    const registrationReasons = state.registrationReasons;

    const registrationReason = registrationReasons.find((it) => it.id == updatedRegistrationReason.id);
    if (registrationReason) {
        registrationReason.value = updatedRegistrationReason.value;
        registrationReason.label = updatedRegistrationReason.label;
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        registrationReasons: [...registrationReasons],
    });
};

const updateRegistrationReasonFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteRegistrationReasonStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteRegistrationReasonSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        isUpdating: false,
        registrationReasons: action.registrationReasons,
    });
};

const deleteRegistrationReasonFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const moveRegistrationReasonStart = (state) => {
    return updateState(state, { error: null });
};

const moveRegistrationReasonSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        registrationReasons: action.registrationReasons,
    });
};

const moveRegistrationReasonFail = (state, action) => {
    return updateState(state, {
        error: action.error,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_REGISTRATION_REASONS_START:
            return getRegistrationReasonsStart(state);
        case actionTypes.GET_REGISTRATION_REASONS_SUCCESS:
            return getRegistrationReasonsSuccess(state, action);
        case actionTypes.GET_REGISTRATION_REASONS_FAIL:
            return getRegistrationReasonsFail(state, action);

        case actionTypes.ADD_REGISTRATION_REASON_START:
            return addRegistrationReasonStart(state);
        case actionTypes.ADD_REGISTRATION_REASON_SUCCESS:
            return addRegistrationReasonSuccess(state, action);
        case actionTypes.ADD_REGISTRATION_REASON_FAIL:
            return addRegistrationReasonFail(state, action);

        case actionTypes.UPDATE_REGISTRATION_REASON_START:
            return updateRegistrationReasonStart(state);
        case actionTypes.UPDATE_REGISTRATION_REASON_SUCCESS:
            return updateRegistrationReasonSuccess(state, action);
        case actionTypes.UPDATE_REGISTRATION_REASON_FAIL:
            return updateRegistrationReasonFail(state, action);

        case actionTypes.DELETE_REGISTRATION_REASON_START:
            return deleteRegistrationReasonStart(state);
        case actionTypes.DELETE_REGISTRATION_REASON_SUCCESS:
            return deleteRegistrationReasonSuccess(state, action);
        case actionTypes.DELETE_REGISTRATION_REASON_FAIL:
            return deleteRegistrationReasonFail(state, action);

        case actionTypes.MOVE_REGISTRATION_REASON_START:
            return moveRegistrationReasonStart(state);
        case actionTypes.MOVE_REGISTRATION_REASON_SUCCESS:
            return moveRegistrationReasonSuccess(state, action);
        case actionTypes.MOVE_REGISTRATION_REASON_FAIL:
            return moveRegistrationReasonFail(state, action);

        default:
            return state;
    }
};

export default reducer;
