import * as jobActions from "../../../store/actions/jobAction";
import * as adminPersonActions from "../../../store/admin/actions/adminPersonActions";
import changeInput from "../../../utils/changeInput";
import React from "react";
import { connect } from "react-redux";
import { Combobox } from "react-widgets/cjs";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";

class JobDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDialog: false,
            isUpdating: false,
            persons: [],
            form: {},
            isFormValid: false,
            selectedJob: this.props.selectedJob,
            editMode: false,
            optionActive: [
                {
                    label: "Active",
                    value: false,
                },
                {
                    label: "Inactive",
                    value: true,
                },
            ],
            optionJobRole: [
                {
                    label: "Job",
                    value: "Job",
                },
                {
                    label: "Role",
                    value: "Role",
                },
            ],
            optionWhoCanSee: [
                {
                    label: "Nobody",
                    value: "Nobody",
                },
                {
                    label: "Employee Only",
                    value: "Employee Only",
                },
                {
                    label: "Candidate Only",
                    value: "Candidate Only",
                },
                {
                    label: "Candidates and Employees",
                    value: "Candidates and Employees",
                },
            ],
            optionSeniority: [
                { label: "Select Seniority", value: "" },
                { label: "Senior or Executive Manager", value: "Senior or Executive Manager" },
                { label: "Senior Advisor", value: "Senior Advisor" },
                { label: "Line Manager", value: "Line Manager" },
                { label: "Team Leader", value: "Team Leader" },
                { label: "Senior Employee", value: "Senior Employee" },
                { label: "Experienced Employee", value: "Experienced Employee" },
                { label: "Junior Employee", value: "Junior Employee" },
                { label: "None of the above (please comment)", value: "None of the above (please comment)" },
                { label: "Not Applicable", value: "Not Applicable" },
            ],
            optionEmploymentStatus: [
                { label: "Select Status", value: "" },
                { label: "Permanent Full-time", value: "Permanent Full-time" },
                { label: "Permanent Part-time", value: "Permanent Part-time" },
                { label: "1-2 year contract", value: "1-2 year contract" },
                { label: "2-5 year contract", value: "2-5 year contract" },
                { label: "Consultant/Contractor", value: "Consultant/Contractor" },
                { label: "Casual", value: "Casual" },
                { label: "Other (Please comment)", value: "Other (Please comment)" },
                { label: "Not Applicable", value: "Not Applicable" },
            ],
            optionLanguages: [],
            optionYesOrNo: [
                {
                    label: "Yes",
                    value: true,
                },
                {
                    label: "No",
                    value: false,
                },
            ],
        };
    }

    componentDidMount() {
        this.prepareFormState();
        let optionLanguages = [];

        if (this.props.languages) {
            optionLanguages = [
                ...this.props.languages.map((language) => {
                    return {
                        value: language.name,
                        label: language.name,
                    };
                }),
            ];

            this.setState({ optionLanguages });
        }

        this.props.onPerson.getPersons().then(() => {
            let persons = [
                {
                    value: "Select owner",
                    label: "Select owner",
                },
            ];
            persons.push(
                ...this.props.persons
                    .filter((it) => !it.inactive)
                    .map((person) => {
                        return {
                            value: person.id,
                            label: `${person.firstName} ${person.lastName}`,
                        };
                    })
                    .sort((a, b) => {
                        return a.label > b.label ? 1 : -1;
                    })
            );
            this.setState({
                persons,
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
            this.prepareFormState();
        }

        if (prevProps.selectedJob !== this.props.selectedJob) {
            this.setState({ selectedJob: this.props.selectedJob });
        }

        if (prevProps.selectedJobUpdated !== this.props.selectedJobUpdated && this.props.selectedJobUpdated == true) {
            this.props.generateAlert("success", "Update job success.");
            this.togleDialogModal();
        }
    }

    prepareFormState = () => {
        const job = this.props.selectedJob;
        const languages = this.props.languages;

        let integrityValid = false;

        if (job) {
            integrityValid = true;

            const inActive = job && job.inActive ? job.inActive : false;
            const whoCanSee = job && (job.isHidden && !job.isCandidateJob ? "Nobody" : !job.isHidden && !job.isCandidateJob ? "Employee Only" : job.isHidden && job.isCandidateJob ? "Candidate Only" : "Candidates and Employees");

            if ((inActive == true && whoCanSee != "Nobody") || (inActive == true && job.jobMatches && job.jobMatches.length > 0)) {
                integrityValid = false;
            }
        }

        this.setState({
            form: {
                sequence: {
                    validation: {
                        required: true,
                    },
                    value: job && job.sequence ? job.sequence : 0,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                name: {
                    validation: {
                        required: true,
                    },
                    value: job && job.name ? job.name : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                reference: {
                    validation: {
                        required: true,
                    },
                    value: job && job.reference ? job.reference : "",
                    valid: job && job.reference && job.reference != "" ? true : false,
                    isValidFormat: job && job.reference && job.reference != "" ? true : false,
                    touched: false,
                },
                customerReference: {
                    validation: {
                        required: false,
                    },
                    value: job && job.customerReference ? job.customerReference : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                jobRole: {
                    validation: {
                        required: true,
                    },
                    value: job && job.isRole ? "Role" : "Job",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                definedBy: {
                    validation: {
                        required: false,
                    },
                    value: job && job.definedBy ? job.definedBy : null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                definedById: {
                    validation: {
                        required: false,
                    },
                    value: job && job.definedById ? job.definedById : null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                owner: {
                    validation: {
                        required: false,
                    },
                    value: job && job.owner ? job.owner : "Select owner",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                ownerId: {
                    validation: {
                        required: false,
                    },
                    value: job && job.ownerId ? job.ownerId : "Select owner",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                whoCanSee: {
                    validation: {
                        required: false,
                    },
                    value: job && (job.isHidden && !job.isCandidateJob ? "Nobody" : !job.isHidden && !job.isCandidateJob ? "Employee Only" : job.isHidden && job.isCandidateJob ? "Candidate Only" : "Candidates and Employees"),
                    valid: integrityValid,
                    isValidFormat: integrityValid,
                    touched: integrityValid == false ? true : false,
                },
                reportingTo: {
                    validation: {
                        required: false,
                    },
                    value: job && job.reportingTo ? job.reportingTo : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                seniority: {
                    validation: {
                        required: false,
                    },
                    value: job && job.seniority ? job.seniority : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                employmentStatus: {
                    validation: {
                        required: false,
                    },
                    value: job && job.employmentStatus ? job.employmentStatus : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                department: {
                    validation: {
                        required: false,
                    },
                    value: job && job.department ? job.department : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                location: {
                    validation: {
                        required: false,
                    },
                    value: job && job.location ? job.location : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                inActive: {
                    validation: {
                        required: false,
                    },
                    value: job && job.inActive ? job.inActive : false,
                    valid: integrityValid,
                    isValidFormat: integrityValid,
                    touched: integrityValid == false ? true : false,
                },
                fte: {
                    validation: {
                        isFte: true,
                        required: true,
                    },
                    value: job ? job.fte.toFixed(1) : "1.0",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                language: {
                    validation: {
                        required: true,
                    },
                    value: job && job.language ? job.language : "English",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                languageId: {
                    validation: {
                        required: true,
                    },
                    value: job && job.languageId ? job.languageId : languages && languages.find((it) => it.name == "English") ? languages.find((it) => it.name == "English").id : null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                showAsRoleBasedSurveyInSelfRegistration: {
                    validation: {
                        required: false,
                    },
                    value: job && job.showAsRoleBasedSurveyInSelfRegistration != null ? (job.showAsRoleBasedSurveyInSelfRegistration == true ? true : false) : false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: job && job.reference && job.reference != "" && integrityValid ? true : false,
        });
    };

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({
            showDialog: !showDialog,
            editMode: false,
        });
    };

    handleChange = (event) => {
        const persons = this.state.persons;
        const languages = this.props.languages;
        let { name, value } = event.target;

        let updateState = changeInput(this.state.form, name, value);

        if (name == "ownerId") {
            if (value && value != "Select owner") {
                const person = persons.find((it) => it.value == value);
                if (person) {
                    updateState = changeInput(updateState.form, "owner", person.label);
                }
            } else {
                updateState = changeInput(updateState.form, "owner", "Select owner");
            }
        }

        if (name == "language" && languages) {
            const language = languages.find((it) => it.name == value);
            updateState = changeInput(updateState.form, "languageId", language ? language.id : null);
        }

        if (name == "inActive" || name == "whoCanSee") {
            if (name == "inActive") {
                updateState = changeInput(updateState.form, "whoCanSee", updateState.form.whoCanSee.value);
            } else {
                updateState = changeInput(updateState.form, "inActive", updateState.form.inActive.value);
            }

            if ((updateState.form.inActive.value == true && this.props.selectedJob.jobMatches && this.props.selectedJob.jobMatches.length > 0) || (updateState.form.inActive.value == true && updateState.form.whoCanSee.value != "Nobody")) {
                updateState.form.inActive.valid = false;
                //updateState.form.inActive.isValidFormat = false;
                updateState.form.inActive.touched = true;

                updateState.form.whoCanSee.valid = false;
                //updateState.form.whoCanSee.isValidFormat = false;
                updateState.form.whoCanSee.touched = true;

                updateState.valid = false;
            }
        }

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    togleShowEdit = () => {
        const editMode = this.state.editMode;

        this.setState({ editMode: !editMode });

        if (editMode) {
            this.prepareFormState();
        }
    };

    handlePerformSave = () => {
        const { form } = this.state;

        let job = Object.assign({}, this.props.selectedJob);
        job.sequence = form.sequence.value;
        job.name = form.name.value;
        job.description = form.name.value;
        job.reference = form.reference.value;
        job.customerReference = form.customerReference.value;
        job.name = form.name.value;
        job.isRole = form.jobRole.value == "Role" ? true : false;
        job.definedBy = form.definedBy.value;
        job.definedById = form.definedById.value;
        job.owner = form.ownerId.value ? (form.owner.value != "Select owner" ? form.owner.value : null) : null;
        job.ownerId = form.ownerId.value ? (form.ownerId.value != "Select owner" ? form.ownerId.value : null) : null;

        job.isHidden = form.whoCanSee.value == "Nobody" || form.whoCanSee.value == "Candidate Only" ? true : false;
        job.isCandidateJob = form.whoCanSee.value == "Candidates and Employees" || form.whoCanSee.value == "Candidate Only" ? true : false;

        job.inActive = form.inActive.value;
        job.reportingTo = form.reportingTo.value;
        job.seniority = form.seniority.value;
        job.employmentStatus = form.employmentStatus.value;
        job.department = form.department.value;
        job.location = form.location.value;
        job.fte = form.fte.value;

        job.language = form.language.value;
        job.languageId = form.languageId.value;

        job.showAsRoleBasedSurveyInSelfRegistration = form.showAsRoleBasedSurveyInSelfRegistration.value;

        this.setState({ isUpdating: true });

        this.props.onJob.updateJob(job.id, job).then(() => {
            if (!this.props.isUpdating) {
                if (this.props.error) {
                    this.props.generateAlert("danger", this.props.error.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    render() {
        const { form, editMode, persons, optionWhoCanSee, optionActive, optionEmploymentStatus, optionSeniority, optionLanguages, optionYesOrNo } = this.state;

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={!this.state.isUpdating ? this.togleDialogModal : null} size="xl">
                    <ModalHeader toggle={!this.state.isUpdating ? this.togleDialogModal : null}>Requirement Detail</ModalHeader>
                    <ModalBody>
                        {this.props.selectedJob && (
                            <Form>
                                <Row>
                                    <Col md={6} sm={12}>
                                        {/* <FormGroup row>
                                            <Label sm={4}>Sequence</Label>
                                            <Col sm={8}>
                                                <Input type="text" name="sequence" id="inputSequence" value={form.sequence.value ? form.sequence.value : 0} invalid={form.sequence.touched && !form.sequence.valid} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating} />
                                            </Col>
                                        </FormGroup> */}
                                        <FormGroup row>
                                            <Label sm={4}>Name</Label>
                                            <Col sm={8}>
                                                <Input type="textarea" id="name" name="name" value={form.name.value ? form.name.value : ""} invalid={form.name.touched && !form.name.valid} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating} style={{ height: "92px" }} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Unique reference</Label>
                                            <Col sm={8}>
                                                <Input type="text" name="reference" id="reference" value={form.reference.value ? form.reference.value : ""} invalid={form.reference.touched && !form.reference.valid} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Customer reference</Label>
                                            <Col sm={8}>
                                                <Input type="text" name="customerReference" id="customerReference" value={form.customerReference.value ? form.customerReference.value : ""} invalid={form.customerReference.touched && !form.customerReference.valid} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Owner</Label>
                                            <Col sm={8}>
                                                <Combobox
                                                    data={persons}
                                                    onChange={(value) => {
                                                        this.handleChange({
                                                            target: {
                                                                name: "ownerId",
                                                                value: value.value,
                                                            },
                                                        });
                                                    }}
                                                    textField="label"
                                                    dataKey="value"
                                                    filter="contains"
                                                    placeholder="Select owner"
                                                    autoSelectMatches={true}
                                                    value={persons.find((it) => (it.value == form.ownerId.value ? form.ownerId.value : null))}
                                                    disabled={!editMode || this.state.isUpdating}
                                                />
                                            </Col>
                                        </FormGroup>
                                        {/* <FormGroup row>
                                            <Label sm={4}>Job/Role</Label>
                                            <Col sm={8}>
                                                <Input type="select" name="jobRole" id="jobRole" value={form.jobRole.value} invalid={form.jobRole.touched && !form.jobRole.valid} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating}>
                                                    {optionJobRole.map((it, key) => (
                                                        <option key={key} value={it.value}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup> */}
                                        <FormGroup row>
                                            <Label sm={4}>Who can see</Label>
                                            <Col sm={8}>
                                                <Combobox
                                                    data={optionWhoCanSee}
                                                    onChange={(value) => {
                                                        this.handleChange({
                                                            target: {
                                                                name: "whoCanSee",
                                                                value: value.value,
                                                            },
                                                        });
                                                    }}
                                                    style={{ border: editMode && form.whoCanSee.touched && !form.whoCanSee.valid ? "1px solid #FF0000" : "none", borderRadius: "0.25rem" }}
                                                    filter="contains"
                                                    textField="label"
                                                    dataKey="value"
                                                    autoSelectMatches={true}
                                                    value={optionWhoCanSee.find((it) => (it.value == form.whoCanSee.value ? form.whoCanSee.value : null))}
                                                    disabled={!editMode || this.state.isUpdating}
                                                    invalid={form.whoCanSee.touched && !form.whoCanSee.valid}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Inactive</Label>
                                            <Col sm={8}>
                                                <Combobox
                                                    data={optionActive}
                                                    onChange={(value) => {
                                                        this.handleChange({
                                                            target: {
                                                                name: "inActive",
                                                                value: value.value,
                                                            },
                                                        });
                                                    }}
                                                    style={{ border: editMode && form.inActive.touched && !form.inActive.valid ? "1px solid #FF0000" : "none", borderRadius: "0.25rem" }}
                                                    filter="contains"
                                                    textField="label"
                                                    dataKey="value"
                                                    autoSelectMatches={true}
                                                    value={optionActive.find((it) => it.value == form.inActive.value)}
                                                    disabled={!editMode || this.state.isUpdating || (this.props.selectedJob && this.props.selectedJob.jobMatches && this.props.selectedJob.jobMatches.filter((it) => it.personInactive == false).length > 0 && this.props.selectedJob.inActive == false)}
                                                    invalid={form.inActive.touched && !form.inActive.valid}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Default language</Label>
                                            <Col sm={8}>
                                                <Combobox
                                                    data={optionLanguages}
                                                    onChange={(value) => {
                                                        this.handleChange({
                                                            target: {
                                                                name: "language",
                                                                value: value.value,
                                                            },
                                                        });
                                                    }}
                                                    filter="contains"
                                                    textField="label"
                                                    dataKey="value"
                                                    autoSelectMatches={true}
                                                    value={optionLanguages.find((it) => (it.value == form.language.value ? form.language.value : null))}
                                                    disabled={!editMode || this.state.isUpdating}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <FormGroup row>
                                            <Label sm={4}>Reporting To</Label>
                                            <Col sm={8}>
                                                <Input
                                                    type="text"
                                                    name="reportingTo"
                                                    id="reportingTo"
                                                    value={form.reportingTo.value ? form.reportingTo.value : ""}
                                                    invalid={form.reportingTo.touched && !form.reportingTo.valid}
                                                    onChange={this.handleChange}
                                                    disabled={!editMode || this.state.isUpdating}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Seniority</Label>
                                            <Col sm={8}>
                                                <Combobox
                                                    data={optionSeniority}
                                                    onChange={(value) => {
                                                        this.handleChange({
                                                            target: {
                                                                name: "seniority",
                                                                value: value.value,
                                                            },
                                                        });
                                                    }}
                                                    filter="contains"
                                                    textField="label"
                                                    dataKey="value"
                                                    autoSelectMatches={true}
                                                    value={optionSeniority.find((it) => (it.value == form.seniority.value ? form.seniority.value : null))}
                                                    disabled={!editMode || this.state.isUpdating}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Employement Status</Label>
                                            <Col sm={8}>
                                                <Combobox
                                                    data={optionEmploymentStatus}
                                                    onChange={(value) => {
                                                        this.handleChange({
                                                            target: {
                                                                name: "employmentStatus",
                                                                value: value.value,
                                                            },
                                                        });
                                                    }}
                                                    filter="contains"
                                                    textField="label"
                                                    dataKey="value"
                                                    autoSelectMatches={true}
                                                    value={optionEmploymentStatus.find((it) => (it.value == form.employmentStatus.value ? form.employmentStatus.value : null))}
                                                    disabled={!editMode || this.state.isUpdating}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Department</Label>
                                            <Col sm={8}>
                                                <Input type="text" name="department" id="department" value={form.department.value ? form.department.value : ""} invalid={form.department.touched && !form.department.valid} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Location</Label>
                                            <Col sm={8}>
                                                <Input type="text" name="location" id="location" value={form.location.value ? form.location.value : ""} invalid={form.location.touched && !form.location.valid} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>FTE Count</Label>
                                            <Col sm={8}>
                                                <Input
                                                    type="text"
                                                    name="fte"
                                                    id="fte"
                                                    value={form.fte.value ? form.fte.value : ""}
                                                    invalid={(form.fte.touched && !(form.fte.valid && form.fte.isValidFormat)) || (form.fte.touched && form.fte && (form.fte.value == "0" || form.fte.value == "0.0" || form.fte.value == 0))}
                                                    onChange={this.handleChange}
                                                    disabled={!editMode || this.state.isUpdating}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Include in Self Registration Role Based Survey</Label>
                                            <Col sm={8}>
                                                <Combobox
                                                    data={optionYesOrNo}
                                                    onChange={(value) => {
                                                        this.handleChange({
                                                            target: {
                                                                name: "showAsRoleBasedSurveyInSelfRegistration",
                                                                value: value.value,
                                                            },
                                                        });
                                                    }}
                                                    style={{ border: editMode && form.showAsRoleBasedSurveyInSelfRegistration.touched && !form.showAsRoleBasedSurveyInSelfRegistration.valid ? "1px solid #FF0000" : "none", borderRadius: "0.25rem" }}
                                                    filter="contains"
                                                    textField="label"
                                                    dataKey="value"
                                                    autoSelectMatches={true}
                                                    value={optionYesOrNo.find((it) => it.value == form.showAsRoleBasedSurveyInSelfRegistration.value)}
                                                    disabled={!editMode || this.state.isUpdating}
                                                    invalid={form.showAsRoleBasedSurveyInSelfRegistration.touched && !form.showAsRoleBasedSurveyInSelfRegistration.valid}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        {!editMode && (
                            <React.Fragment>
                                {this.props.selectedJob && this.props.allowEdit && (
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            this.togleShowEdit();
                                        }}
                                    >
                                        Edit
                                    </Button>
                                )}
                                <Button color="secondary" onClick={this.togleDialogModal} disabled={this.state.isImporting}>
                                    Close
                                </Button>
                            </React.Fragment>
                        )}
                        {editMode && (
                            <React.Fragment>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.handlePerformSave();
                                    }}
                                    disabled={!this.state.isFormValid || this.state.isUpdating || (form && form.fte && (form.fte.value == "0" || form.fte.value == "0.0" || form.fte.value == 0))}
                                >
                                    Save
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.togleShowEdit();
                                    }}
                                    disabled={this.state.isUpdating}
                                >
                                    Cancel
                                </Button>
                            </React.Fragment>
                        )}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authPersonId: state.auth.personId,
        personsLoading: state.adminPerson.loading,
        persons: state.adminPerson.persons,
        personsError: state.adminPerson.error,

        error: state.job.error,
        isUpdating: state.job.isUpdating,

        languages: state.language.languages,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPerson: bindActionCreators(adminPersonActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobDetail);
