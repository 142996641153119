import App from "./App";
import AppScrollToTop from "./AppScrollToTop";
import { unregister } from "./registerServiceWorker";
import store from "./store/store";
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-widgets/styles.css";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const root = createRoot(rootElement);
root.render(
    <Provider store={store}>
        <BrowserRouter basename={baseUrl}>
            <AppScrollToTop>
                <App />
            </AppScrollToTop>
        </BrowserRouter>
    </Provider>
);

//registerServiceWorker();
unregister();
