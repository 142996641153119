import * as authActions from "../../../store/actions/authAction";
import * as jobActions from "../../../store/actions/jobAction";
import * as interventionActions from "../../../store/admin/actions/adminInterventionActions";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { filterArray } from "../../../utils/filterUtils";
import { contentTranslator } from "../../../utils/translatorUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import { faPenToSquare, faTrash, faCheck, faArrowRight, faEnvelope, faFileExcel, faInfoCircle, faMinusCircle, faPlus, faSync, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const rowStyle = {
    fontSize: "12px",
};

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "18px",
    fontFamily: "IBMPlexSans-Bold",
};

class ManageTrainingJobs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            interventions: [],
            isUpdating: false,
            selectedIntervention: null,
            selectedInterventions: [],
            jobs: [],
            optionJobs: [],
            assignedJobs: [],
            assignedJobsSelected: [],
            selectedJobId: null,
            searchProperties: ["name", "companyName", "lorLevelsString", "levelsString"],
        };

        this.filterArray = filterArray.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        this.props.onAuth.authCheckState().then(() => {
            this.props.onJob.getJobs().then(() => {
                if (!this.props.jobsLoading) {
                    this.setState({
                        jobs: this.props.jobs,
                    });

                    this.initData();
                }

                this.setState({
                    loading: this.props.loading || this.props.jobsLoading,
                });
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.interventions !== this.props.interventions) {
            this.setState({ interventions: this.prepareInterventions(this.props.interventions).sort((a, b) => (`${a.company.name} ${a.name}` > `${b.company.name} ${b.name}` ? 1 : -1)) });
        }
    }

    initData = () => {
        const selectedInterventions = this.state.selectedInterventions;
        let selectedInterventionId = null;

        if (selectedInterventions && selectedInterventions.length > 0) {
            selectedInterventionId = selectedInterventions[0];
        }

        this.props.onIntervention.getInterventions(true, true, false).then(() => {
            if (!this.props.interventionLoading) {
                const interventions = this.prepareInterventions(this.props.interventions).sort((a, b) => (`${a.company.name} ${a.name}` > `${b.company.name} ${b.name}` ? 1 : -1));
                if (selectedInterventionId) {
                    if (!interventions.find((it) => it.id == selectedInterventionId)) {
                        if (interventions[0]) {
                            selectedInterventionId = interventions[0].id;
                            interventions[0].toggleSelected = true;
                        }
                    } else {
                        const intervention = interventions.find((it) => it.id == selectedInterventionId);
                        intervention.toggleSelected = true;
                    }
                } else {
                    if (interventions[0]) {
                        selectedInterventionId = interventions[0].id;
                        interventions[0].toggleSelected = true;
                    }
                }

                this.setState(
                    {
                        interventions,
                        selectedInterventions: [selectedInterventionId],
                    },
                    () => {
                        this.handleSelectRow(selectedInterventionId);
                    }
                );
            }

            this.setState({ loading: this.props.loading || this.props.interventionLoading });
        });
    };

    handleRefresh = () => {
        this.initData();
    };

    prepareInterventions = (interventions) => {
        return interventions.map((intervention) => {
            return {
                id: intervention.id,
                companyId: intervention.companyId,
                company: intervention.company,
                companyName: intervention.company.name,
                actionType: intervention.actionType,
                name: intervention.name,
                link: intervention.link,
                duration: intervention.duration,
                description: intervention.description,
                sfiaVersion: intervention.sfiaVersion,
                versionNumber: intervention.versionNumber,
                levelsString: intervention.levels
                    .sort((a, b) => `${a.skillsCode}${a.level}` > `${b.skillsCode}${b.level}`)
                    .map((level) => {
                        return `${level.skillsCode}${level.level}`;
                    })
                    .join(", "),
                levels: intervention.levels,
                lorLevelsString: intervention.lorLevels
                    .sort((a, b) => `${a.lorCode}${a.level}` > `${b.lorCode}${b.level}`)
                    .map((level) => {
                        return `${level.lorCode}${level.level}`;
                    })
                    .join(", "),
                lorLevels: intervention.lorLevels,
            };
        });
    };

    handleAfterSearch = (searchKeyword) => {
        const { interventions, searchProperties } = this.state;
        const filtered = this.filterArray(interventions, searchKeyword, searchProperties);

        interventions.forEach((it) => {
            it.toggleSelected = false;
        });

        if (filtered.length > 0) {
            const row = filtered[0];
            const intervention = interventions.find((it) => it.id == row.id);
            intervention.toggleSelected = true;
            this.handleSelectRow(intervention.id);
            this.setState({ interventions: [...interventions], selectedInterventions: [intervention.id] });
        } else {
            this.handleSelectRow();
            this.setState({ interventions: [...interventions], selectedInterventions: [] });
        }
    };

    handleOnSelect = (row) => {
        if (this.state.isUpdating) {
            return false;
        }

        const interventions = this.state.interventions;
        const updatedData = interventions.find((it) => it.id == row.id);

        if (!updatedData.toggleSelected) {
            interventions.forEach((it) => {
                it.toggleSelected = false;
            });

            updatedData.toggleSelected = true;

            this.handleSelectRow(row.id);
            this.setState({ interventions: [...interventions], selectedInterventions: [row.id] });
        }
    };

    handleSelectRow = (interventionId) => {
        const interventions = this.props.interventions;
        const jobs = this.props.jobs;
        const { selectedInterventions, assignedJobsSelected } = this.state;

        let selectedIntervention = null;

        if (selectedInterventions.length > 0) {
            selectedIntervention = selectedInterventions[0];
        }

        selectedIntervention = interventions.find((it) => it.id == interventionId);

        if (selectedIntervention) {
            let assignedJobIds = [];
            if (selectedIntervention.jobs && selectedIntervention.jobs.length > 0) {
                assignedJobIds = [
                    ...selectedIntervention.jobs.map((job) => {
                        return job.jobId;
                    }),
                ];
            }
            const optionJobs = [
                {
                    value: null,
                    label: "Select Requirement",
                },
                ...jobs
                    .filter((it) => !assignedJobIds.includes(it.id))
                    .map((job) => {
                        return {
                            value: job.id,
                            label: job.name,
                        };
                    })
                    .sort((a, b) => (`${a.label}` > `${b.label}` ? 1 : -1)),
            ];

            this.setState({
                selectedInterventions: interventionId ? [interventionId] : [],
                selectedInterventionId: interventionId,
                selectedIntervention,
                optionJobs,
                assignedJobs: selectedIntervention ? selectedIntervention.jobs : [],
                assignedJobsSelected: selectedIntervention != interventionId ? [] : [...assignedJobsSelected],
            });
        } else {
            const optionJobs = [
                {
                    value: null,
                    label: "Select Requirement",
                },
                ...jobs
                    .map((job) => {
                        return {
                            value: job.id,
                            label: job.name,
                        };
                    })
                    .sort((a, b) => (`${a.label}` > `${b.label}` ? 1 : -1)),
            ];

            this.setState({
                selectedInterventions: interventionId ? [interventionId] : [],
                selectedInterventionId: interventionId,
                selectedIntervention,
                optionJobs,
                assignedJobs: selectedIntervention ? selectedIntervention.jobs : [],
                assignedJobsSelected: selectedIntervention != interventionId ? [] : [...assignedJobsSelected],
            });
        }
    };

    handleOnSelectAssignedJob = (row) => {
        if (this.state.isUpdating) {
            return false;
        }

        const { assignedJobs } = this.state;
        let assignedJobsSelected = this.state.assignedJobsSelected;

        if (assignedJobsSelected) {
            const assignedJob = assignedJobs.find((it) => it.id == row.id);
            if (assignedJobsSelected.find((it) => it == row.id)) {
                assignedJob.toggleSelected = false;

                const assignedJobSelected = assignedJobsSelected.find((it) => it == row.id);
                var index = assignedJobsSelected.indexOf(assignedJobSelected);
                if (index !== -1) {
                    assignedJobsSelected.splice(index, 1);
                }
            } else {
                assignedJob.toggleSelected = true;
                assignedJobsSelected = [...assignedJobsSelected, row.id];
            }
        } else {
            const assignedJob = assignedJobs.find((it) => it.id == row.id);
            assignedJob.toggleSelected = true;
            assignedJobsSelected = [row.id];
        }

        this.setState({ assignedJobs: [...assignedJobs], assignedJobsSelected: [...assignedJobsSelected] });
    };

    handleChangeAssignJob = (event) => {
        let { name, value } = event.target;

        if (value == "Select Requirement") {
            this.setState({ selectedJobId: null });
        } else {
            this.setState({ selectedJobId: value });
        }
    };

    handleRemoveInterventionJobsClick = () => {
        const { selectedIntervention, assignedJobsSelected, selectedInterventionId } = this.state;
        this.setState({ isUpdating: true });

        this.props.onIntervention.deleteInterventionJobs(assignedJobsSelected).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.interventionError) {
                    const interventions = this.prepareInterventions(this.props.interventions).sort((a, b) => (`${a.company.name} ${a.name}` > `${b.company.name} ${b.name}` ? 1 : -1));
                    if (selectedInterventionId) {
                        if (!interventions.find((it) => it.id == selectedInterventionId)) {
                            if (interventions[0]) {
                                selectedInterventionId = interventions[0].id;
                                interventions[0].toggleSelected = true;
                            }
                        } else {
                            const intervention = interventions.find((it) => it.id == selectedInterventionId);
                            intervention.toggleSelected = true;
                        }
                    }

                    this.setState(
                        {
                            selectedJobId: null,
                            interventions,
                        },
                        () => {
                            this.handleSelectRow(null);
                            setTimeout(() => {
                                this.handleSelectRow(selectedIntervention.id);
                            }, 200);
                        }
                    );
                } else {
                    this.generateAlert("danger", this.props.interventionError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleAssignJobClick = () => {
        const { selectedIntervention, optionJobs, selectedInterventionId } = this.state;
        let selectedJobId = this.state.selectedJobId;
        this.setState({ isUpdating: true });

        this.props.onIntervention.assignInterventionJob(selectedIntervention.id, selectedJobId).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.interventionError) {
                    const optionJob = optionJobs.find((it) => it.value == selectedJobId);
                    if (optionJob) {
                        const index = optionJobs.indexOf(optionJob);
                        if (index !== -1) {
                            const nextOption = optionJobs[index + 1];
                            if (nextOption) {
                                selectedJobId = nextOption.value;
                            } else {
                                selectedJobId = null;
                            }
                        } else {
                            selectedJobId = null;
                        }
                    } else {
                        selectedJobId = null;
                    }

                    const interventions = this.prepareInterventions(this.props.interventions).sort((a, b) => (`${a.company.name} ${a.name}` > `${b.company.name} ${b.name}` ? 1 : -1));
                    if (selectedInterventionId) {
                        if (!interventions.find((it) => it.id == selectedInterventionId)) {
                            if (interventions[0]) {
                                selectedInterventionId = interventions[0].id;
                                interventions[0].toggleSelected = true;
                            }
                        } else {
                            const intervention = interventions.find((it) => it.id == selectedInterventionId);
                            intervention.toggleSelected = true;
                        }
                    }

                    this.setState(
                        {
                            selectedJobId,
                            interventions,
                        },
                        () => {
                            this.handleSelectRow(null);
                            setTimeout(() => {
                                this.handleSelectRow(selectedIntervention.id);
                            }, 200);
                        }
                    );
                } else {
                    this.generateAlert("danger", this.props.interventionError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    render() {
        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Assign Action Requirements");

        const handleOnSelect = this.handleOnSelect;
        const handleOnSelectAssignedJob = this.handleOnSelectAssignedJob;

        const columns = [
            {
                id: "name",
                name: "Name",
                selector: (row) => row.name,
                sortable: false,
                width: "100%",
                style: { cursor: "pointer" },
                wrap: true,
                format: function (row, index) {
                    return (
                        <div key={index} style={{ padding: "10px 5px", cursor: "pointer" }} onClick={(e) => handleOnSelect(row)}>
                            <span style={{ fontSize: "14px", textDecoration: "underline" }}>{row.company.name}</span>
                            <br />
                            <span style={{ fontSize: "14px", fontWeight: "bold" }}>{row.name}</span>
                            {row.lorLevelsString || row.levelsString ? <br /> : ""}
                            {row.lorLevelsString}
                            {row.lorLevelsString && row.levelsString ? ", " : ""}
                            {row.levelsString}
                        </div>
                    );
                },
            },
        ];

        const columnsJobs = [
            {
                id: "jobName",
                name: "Job Name",
                selector: (row) => row.jobName,
                sortable: false,
                wrap: true,
                width: "100%",
                style: { cursor: "pointer" },
                format: function (row, index) {
                    return (
                        <div key={index} style={{ padding: "10px 5px", cursor: "pointer" }} onClick={(e) => handleOnSelectAssignedJob(row)}>
                            <span style={{ fontSize: "14px" }}>{row.jobName}</span>
                        </div>
                    );
                },
            },
        ];

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <Row>
                            <Col md="6">
                                <p className={"page-title"} style={headerStyle}>
                                    Action List
                                </p>
                                <div>
                                    <Row style={{ marginBottom: "10px" }}>
                                        <Col md="12">
                                            <SkillsTxTableSearchBar
                                                searchKeyword={this.state.searchKeywordLevel1}
                                                onChange={(searchKeywordLevel1) => {
                                                    this.setState({ searchKeywordLevel1 });
                                                    this.handleAfterSearch(searchKeywordLevel1);
                                                }}
                                            ></SkillsTxTableSearchBar>
                                        </Col>
                                    </Row>
                                    <div style={{ maxHeight: "500px", minHeight: "500px", overflowY: "scroll" }}>
                                        <SkillsTxTable
                                            columns={columns}
                                            data={this.state.interventions}
                                            pagination={false}
                                            noTableHead={true}
                                            searchKeyword={this.state.searchKeywordLevel1}
                                            searchProperties={this.state.searchProperties}
                                            selectableRows={true}
                                            selectableRowsHighlight={true}
                                            handleRowClick={this.handleOnSelect}
                                            progressPending={this.props.loading}
                                        ></SkillsTxTable>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6">
                                <p className={"page-title"} style={headerStyle}>
                                    Assigned Requirements
                                </p>
                                <div>
                                    <div style={{ maxHeight: "300px", minHeight: "500px", overflowY: "scroll" }}>
                                        <SkillsTxTable columns={columnsJobs} data={this.state.assignedJobs} pagination={false} noTableHead={true} selectableRows={true} selectableRowsHighlight={true} handleRowClick={this.handleOnSelectAssignedJob} progressPending={this.props.loading}></SkillsTxTable>
                                    </div>
                                </div>
                                <br />
                                {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Delete" && op.allow == true) && (
                                    <React.Fragment>
                                        <Button
                                            color="danger"
                                            onClick={this.handleRemoveInterventionJobsClick}
                                            style={{
                                                width: "100%",
                                                marginBottom: "10px",
                                            }}
                                            disabled={!(this.state.assignedJobsSelected && this.state.assignedJobsSelected.length > 0) || this.state.isUpdating}
                                        >
                                            <Row>
                                                <Col md="2">
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Col>
                                                <Col md="10">Remove</Col>
                                            </Row>
                                        </Button>
                                        <Row>
                                            <Col md="12" style={{ borderTop: "1px solid" }}>
                                                &nbsp;
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )}
                                {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Add" && op.allow == true) && (
                                    <React.Fragment>
                                        <Row>
                                            <Col md="12" style={{ paddingBottom: "5px" }}>
                                                <Input type="select" name="assignJob" id="assignJob" value={this.state.selectedJobId} onChange={this.handleChangeAssignJob} disabled={this.state.isUpdating}>
                                                    {this.state.optionJobs.map((it, key) => (
                                                        <option key={key} value={it.value}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </Row>
                                        <Button
                                            color="success"
                                            onClick={this.handleAssignJobClick}
                                            style={{
                                                width: "100%",
                                                marginBottom: "10px",
                                            }}
                                            disabled={!this.state.selectedJobId || !this.state.selectedInterventionId || this.state.isUpdating}
                                        >
                                            <Row>
                                                <Col md="10">Add</Col>
                                                <Col md="2">
                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                </Col>
                                            </Row>
                                        </Button>
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,

        interventionLoading: state.adminIntervention.loading,
        interventionError: state.adminIntervention.error,
        interventions: state.adminIntervention.interventions,
        isUpdating: state.adminIntervention.isUpdating,

        jobsLoading: state.job.loading,
        jobsError: state.job.error,
        jobs: state.job.jobs,
        pageAssignments: state.adminPerson.pageAssignments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onIntervention: bindActionCreators(interventionActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageTrainingJobs);
