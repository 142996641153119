import CategoryService from "../../../../common/api/service/CategoryService";
import PersonService from "../../../../common/api/service/PersonService";
import ProfileService from "../../../../common/api/service/ProfileService";
import OrganizationalChart from "../../components/charts/organizational";
import TeamBuilderFilters from "../../components/filters/teambuilder";
import TeamBuilderTable from "../../components/table/teambuilder";
import { groupBy } from "lodash";
import React, { useEffect, useState, useMemo } from "react";
import { Spinner } from "reactstrap";
import styled from "styled-components";

const Container = styled.div`
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const DivCenter = ({ Component }) => {
  return (
    <div className="p-grid p-align-center vertical-container">
      <div className="layout-wrapper">
        <div className="p-grid">
          <div
            className="p-col-12"
            style={{ position: "fixed", top: "50%", left: "50%" }}
          >
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
};

const ContainerBorder = styled.div`
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #dddddd;
  border-radius: 14px;
`;

// Constant Data
const constantData = {
  noAttribute: "(No Attribute)",
  empty: "",
  pageSize: 5,
  fullName: "fullName",
  level: "level",
  location: "location",
  skillCode: "skillCode",
  country: "country",
  profileStatus: "profileStatus",
  endorsed: "Endorsed",
  capability: "capability",
  competency: "Competency",
  proficiency: "Proficiency",
  knowledge: "Knowledge",
  characters: {
    n: "N",
    m: "M",
    p: "P",
  },
};

// Returns a new array containing only the unique elements from the provided `dataArray` based on a custom comparison function.
const distinctArray = (dataArray, func) => {
  // `seen` will store a unique "hash" for each item we've already encountered.
  const seen = new Set();

  // The array to accumulate unique items from `dataArray`.
  const uniqueArray = [];

  dataArray.forEach((item) => {
    // Create a unique "hash" for the current item based on the comparator function.
    // This "hash" is simply a string representation of all items considered equal to the current item.
    const hash = uniqueArray.some((itemIn) => func(item, itemIn));

    // If we haven't seen this unique "hash" before, add the item to our unique array.
    if (!hash) {
      seen.add(hash);
      uniqueArray.push(item);
    }
  });

  return uniqueArray;
};

const TeamBuilder = () => {
  // data
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [data, setData] = useState([]);

  // Selection
  const [selectedAttribute, setSelectedAttribute] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [selectedProfileStatus, setSelectedProfileStatus] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedSkillLevel, setSelectedSkillLevel] = useState([]);

  /**
   * Fetch data from services.
   */
  const fetchData = async () => {
    const services = [
      CategoryService.getCategories(true),
      PersonService.getPersonMyPeoples(),
      PersonService.getPersonMyProfile(),
      ProfileService.getMyProfileVersions(),
      ProfileService.getMySkillPofiles(null, null, "Self Assessed"),
      ProfileService.getMySkillPofiles(null, null, "Endorsed"),
    ];

    return await Promise.all(services);
  };

  /**
   * Flatten categories to get skills.
   */
  const flattenDataCategories = (dataCategories) => {
    return dataCategories
      .flatMap((item) => item.subCategories)
      .flatMap((item) => item.skills);
  };

  /**
   * Get capability based on string value.
   */
  const getCapability = (stringVal) => {
    switch (stringVal) {
      case constantData.characters.m:
        return constantData.competency;
      case constantData.characters.p:
        return constantData.proficiency;
      default:
        return constantData.knowledge;
    }
  };

  // Derive sub-category details for a given value
  const getSubCategory = (value, dataCategories) => {
    // Logic to extract and return sub-category details
    let findCategory = dataCategories?.find(
      (item) => item.name === value?.category
    );
    if (!findCategory) {
      findCategory = {
        colour: constantData.empty,
        description: constantData.empty,
      };
    }
    let findSubCategory = findCategory?.subCategories?.find(
      (item) => item.name === value?.subCategory
    );
    if (!findSubCategory) {
      findSubCategory = {
        colour: constantData.empty,
        description: constantData.empty,
      };
    }
    return {
      skillColour: findSubCategory?.skillColour,
      colour: findSubCategory?.colour,
      description: findSubCategory?.description,
    };
  };

  // Map a single skill with its associated metadata
  const mapSkillWithDetails = (itemSkill, item, approver) => {
    // Logic to transform the given skill with additional details
    return {
      ...itemSkill,
      country: item.personB.country,
      location: item.personB.location,
      fullName: item.personB.firstName + " " + item.personB.lastName,
      position: item.personB.position,
      email: item.personB.email,
      ...approver,
      profileStatus: approver ? `${itemSkill.profileType} - Approved` : `${itemSkill.profileType} - Not Approved`,
      isApproved: approver ? true : false,
      approvedText: approver ? "Approved" : "Not Approved",
      capability: getCapability(itemSkill.stringVal),
    };
  };

  // Function to derive skill data from an item, its versions, and an approver
  const getDataSkill = async (item, versions, approver) => {
    let baseSkills = [];

    if (item.skillProfile) {
      baseSkills.push(...item.skillProfile);
    } else {
      if (versions.length > 0){
        if (versions.filter(it => it.profileType == "Self Assessed").length > 0){
          const selfAssessedSkills = await ProfileService.getManagedPeopleSkillPofiles(
            item.personB.email,
            versions.filter(it => it.profileType == "Self Assessed")[0].sfiaVersion,
            versions.filter(it => it.profileType == "Self Assessed")[0].version,
            versions.filter(it => it.profileType == "Self Assessed")[0].profileType
          )

          if (selfAssessedSkills.length > 0){
            baseSkills.push(...selfAssessedSkills);
          }
        }

        if (versions.filter(it => it.profileType == "Endorsed").length > 0){
          const endorsedSkills = await ProfileService.getManagedPeopleSkillPofiles(
            item.personB.email,
            versions.filter(it => it.profileType == "Endorsed")[0].sfiaVersion,
            versions.filter(it => it.profileType == "Endorsed")[0].version,
            versions.filter(it => it.profileType == "Endorsed")[0].profileType
          )

          if (endorsedSkills.length > 0){
            baseSkills.push(...endorsedSkills);
          }
        }
      }
    }

    const result = baseSkills
    .map((itemSkill) => mapSkillWithDetails(itemSkill, item, approver))
    .filter((itemSkill) => itemSkill.stringVal !== constantData.characters.n)

    return result;
  };

  // Helper function to process individual datasets
  const processItem = async (item) => {
    // Fetch versions if not present in the item
    const versions = item.versions
      ? item.versions
      : await ProfileService.getManagedPeopleProfileVersions(
          item.personB.email
        );

    // Find the earliest account creation and its approvals
    const earliestAccount = item.personB.accounts.sort(
      (a, b) => new Date(a.createdOn) - new Date(b.createdOn)
    )[0];
    const approvals = earliestAccount.approvals.sort(
      (a, b) => new Date(a.approvedOn) - new Date(b.approvedOn)
    );
    const approver = approvals.length > 0 ? approvals[0] : null;

    // Compute the skill data based on various conditions and associated metadata
    const dataSkill = await getDataSkill(item, versions, approver);
    return {
      personId: item.personB.id,
      dataSkill,
      accountAttributes: earliestAccount ? earliestAccount.attributes .map((attr) => attr.skillAttributeId) : [],
      getAttributes: dataSkill.map((item) => item.skillCode),
    };
  };

  /**
   * Main function to process all data.
   */
  const processAllData = async () => {
    try {
      // Fetch all required initial datasets
      let dataSkill = [];
      const [dataCategories, dataPeoples, dataProfile, dataVersion, selfAssessedDataSkill, endorsedDataSkill] =
        await fetchData();

      if (selfAssessedDataSkill && selfAssessedDataSkill.length > 0){
        dataSkill.push(...selfAssessedDataSkill);
      }

      if (endorsedDataSkill && endorsedDataSkill.length > 0){
        dataSkill.push(...endorsedDataSkill);
      }

      // Convert nested dataCategories into a flat structure
      const flatDataCategories = flattenDataCategories(dataCategories);

      // Create a combined dataset to facilitate further processing
      const initialData = [
        ...dataPeoples,
        {
          personB: dataProfile,
          versions: dataVersion,
          skillProfile: dataSkill,
        },
      ];

      // Process each individual dataset to structure them uniformly
      const peoplesData = await Promise.all(
        initialData.map(async (item) => processItem(item))
      );

      // Create a set of unique attributes
      const attributesSet = new Set(
        peoplesData.flatMap((item) => item.getAttributes)
      );

      // Filter to get matching attributes
      const allAttribute = flatDataCategories.filter((item) =>
        attributesSet.has(item.skillsCode)
      );

      // Map over datasets, enriching them with associated sub-category details and attributes
      const processedData = peoplesData
        .map((item) => item.dataSkill)
        .flat(2)
        .map((item) => {
          const accountAttributes = peoplesData.find(it => it.personId == item.personId).accountAttributes;
          const attributes = allAttribute.find((attr) => attr.skillsCode == item.skillCode)?.attributes?.filter(attr => accountAttributes && accountAttributes.includes(attr.id)).map((attr) => attr.attribute) || [];
          
          return {
            ...item,
            subCategoryDetail: getSubCategory(item, dataCategories),
            attributes: [...attributes]
          }
        })
        ;

      //Set the resulting data for further usage (for instance, updating the component's state)
      setData(processedData);
    } catch (error) {
      // Handle exceptions and log them for debugging purposes
      console.error("Failed to fetch and process data:", error);
    }
  };

  /**
   * Main function to generate the tree select based on input value.
   */
  const generateTreeSelect = (val, name) =>
    // Using Object.entries to get both key and value of each entry in the data object
    Object.entries(val)
      .map(([category, itemsArray], categoryIdx) => {
        // Generating a Set to deduplicate the values based on the 'name' property
        // Then spread the Set back into an array for easy mapping
        const uniqueValues = [...new Set(itemsArray.map((item) => item[name]))];
        const rootData = JSON.stringify({
          isRoot: true,
          value: category,
        });
        return {
          title: category,
          value: rootData,
          key: rootData,

          // For each unique value, we map it to generate the 'children' array
          children: uniqueValues
            .map((uniqueValue, valueIdx) => {
              const childData = JSON.stringify({
                isRoot: false,
                value: uniqueValue,
              });
              return {
                // Fetching the 'text' property directly from the original itemsArray for each child
                title: itemsArray[valueIdx].text,
                value: childData,
                key: childData,
              };
            })
            .sort((a, b) => a.title.localeCompare(b.title)),
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));

  /**
   * Main function to generate the tree node based on input value.
   */
  const generateTreeNode = (val) => {
    /**
     * Helper function to create a new tree node.
     */
    const createNode = (label, isPerson = false) => ({
      label,
      isRoot: false, // By default, a node is not a root node
      isExpand: false, // By default, a node is not expanded
      meta: {
        isPerson,
      },
      children: [],
    });
    // Create the root node with pre-defined properties
    const rootNode = {
      label: "Persons",
      isRoot: true,
      isExpand: true,
      meta: {
        isPerson: false,
      },
      // Map over each item in the input array to construct the children nodes for the root
      children: val.map((item) => {
        // Use the helper function to create a new node for the item
        const node = createNode(item.label, true); // Set isPerson to true for the item node
        // Map over each child of the item to construct its children nodes
        node.children = item.children.map((childItem) =>
          createNode(childItem.skillCode)
        );
        return node; // Return the constructed node for the item
      }),
    };

    return rootNode;
  };

  // Filter Attribute
  const optionsAttribute = useMemo(() => {
    // If there's no data, return an empty array immediately.
    if (data.length === 0) return [];
    const attributesSet = new Set(data.flatMap((item) => item.attributes));
    attributesSet.add(constantData.noAttribute);
    // Filter out non-distinct items based on their 'attributes' field.
    // This uses the distinctArray function, which presumably removes duplicates based on a comparator.
    return (
      Array.from(attributesSet)
        .map((item) => ({
          // Convert each item into a label-value pair for use in a dropdown or similar UI element.
          label: item,
          value: item,
        }))
        // Sort the options alphabetically based on the label.
        .sort((a, b) => a.label.localeCompare(b.label))
    );
  }, [data]); // Recompute only when 'data' changes.

  // Filter Skill Name
  const optionsSkill = useMemo(() => {
    // If there's no data, return an empty array immediately.
    if (data.length === 0) return [];

    // Filter out non-distinct items based on their 'skill' field.
    // This uses the distinctArray function, which presumably removes duplicates based on a comparator.
    return (
      distinctArray(data, (itemA, itemB) => itemA.skill === itemB.skill)
        .map(({ skill, skillCode }) => ({
          label: `${skill} (${skillCode})`,
          value: skill,
        }))
        // Sort the options alphabetically based on the label.
        .sort((a, b) => a.label.localeCompare(b.label))
    );
  }, [data]); // Recompute only when 'data' changes.

  // Filter Position
  const optionsPosition = useMemo(() => {
    // If there's no data, return an empty array immediately.
    if (data.length === 0) return [];

    // Filter out non-distinct items based on their 'position' field.
    // This uses the distinctArray function, which presumably removes duplicates based on a comparator.
    return (
      distinctArray(data, (itemA, itemB) => itemA.position === itemB.position)
        .map(({ position }) => ({
          label: position,
          value: position,
        }))
        // Sort the options alphabetically based on the label.
        .sort((a, b) => a.label.localeCompare(b.label))
    );
  }, [data]); // Recompute only when 'data' changes.

  // Filter Profile Status
  const optionsProfileStatus = useMemo(() => {
    if (data.length === 0) return [];

    const temp = data.map((item) => ({
      capability: `${item.profileType} - ${item.capability} (${item.approvedText})`,      
      profileType: item.profileType,
      profileStatus: item.profileStatus,
      text: `${item.profileType} - ${item.capability} (${item.approvedText})`,
    }));

    // Deduplicate the combined array based on the capability.
    const uniqueArray = distinctArray(
      temp,
      (itemA, itemB) => itemA.capability === itemB.capability
    );

    // Generate the tree select structure.
    return generateTreeSelect(
      groupBy(uniqueArray, constantData.profileStatus),
      constantData.capability
    );
  }, [data]);

  // Filter Country Location
  const optionsCountry = useMemo(() => {
    // Exit early if there's no data to process.
    if (data.length === 0) return [];

    // Transform
    const tempData = data.map((item) => ({
      location: `${item.country} - ${item.location}`,
      country: item.country,
      text: `${item.country} - ${item.location}`,
    }));

    // Deduplicate the combined array based on the skillCode.
    const uniqueSkills = distinctArray(
      tempData,
      (itemA, itemB) => itemA.country === itemB.country
    );

    // Generate the tree select structure.
    return generateTreeSelect(
      groupBy(uniqueSkills, constantData.country),
      constantData.location
    );
  }, [data]);

  // Filter Level of Skill
  const optionsSkillLevel = useMemo(() => {
    // Exit early if there's no data to process.
    if (data.length === 0) return [];

    // Transform
    const tempData = data.map((item) => ({
      skillCode: `${item.skillCode} - ${item.level}`,
      level: `Level ${item.level}`,
      text: `${item.skillCode} - ${item.level}`,
    }));

    // Deduplicate the combined array based on the skillCode.
    const uniqueSkills = distinctArray(
      tempData,
      (itemA, itemB) => itemA.skillCode === itemB.skillCode
    );

    // Generate the tree select structure.
    return generateTreeSelect(
      groupBy(uniqueSkills, constantData.level),
      constantData.skillCode
    );
  }, [data]);

  // Data Filter  => Ref By Data
  const dataFilter = useMemo(() => {
    let resultData = [];

    if (data.length) {
      resultData = data.filter((item) => {
        // If selectedAttribute is populated and the condition is not met, filter out
        if (
          selectedAttribute.length > 0 &&
          !selectedAttribute.some((attr) => {
            if (attr === constantData.noAttribute) {
              return item.attributes.length === 0;
            }
            return item.attributes.includes(attr);
          })
        ) {
          return false;
        }

        // If selectedPosition is populated and the condition is not met, filter out
        if (
          selectedPosition.length > 0 &&
          !selectedPosition.includes(item.position)
        ) {
          return false;
        }

        // If selectedSkill is populated and the condition is not met, filter out
        if (selectedSkill.length > 0 && !selectedSkill.includes(item.skill)) {
          return false;
        }

        // If selectedProfileStatus is populated and the condition is not met, filter out
        if (selectedProfileStatus.length > 0) {
          const isFound = selectedProfileStatus
            .map((_) => JSON.parse(_))
            .some((_) => {
              if (_.isRoot) {
                return item.profileStatus === _.value;
              } else {
                return `${item.profileType} - ${item.capability} (${item.approvedText})` === _.value;
              }
            });
          if (!isFound) {
            return false;
          }
        }

        // If selectedCountry is populated and the condition is not met, filter out
        if (selectedCountry.length > 0) {
          const isFound = selectedCountry
            .map((_) => JSON.parse(_))
            .some((_) => {
              if (_.isRoot) {
                return item.country === _.value;
              } else {
                return `${item.country} - ${item.location}` === _.value;
              }
            });
          if (!isFound) {
            return false;
          }
        }

        // If selectedSkillLevel is populated and the condition is not met, filter out
        if (selectedSkillLevel.length > 0) {
          const isFound = selectedSkillLevel
            .map((_) => JSON.parse(_))
            .some((_) => {
              if (_.isRoot) {
                return `Level ${item.level}` === _.value;
              } else {
                return `${item.skillCode} - ${item.level}` === _.value;
              }
            });
          if (!isFound) {
            return false;
          }
        }

        // If all conditions are met or not applicable, include the item
        return true;
      });
    }

    return resultData;
  }, [
    data,
    selectedSkillLevel,
    selectedCountry,
    selectedProfileStatus,
    selectedSkill,
    selectedPosition,
    selectedAttribute,
  ]);

  const dashboardTable = useMemo(() => dataFilter ?? [], [dataFilter]); // Recompute only if dataFilter changes

  const dashboardOrganizationalChart = useMemo(() => {
    // Early return if dataFilter is empty
    if (dataFilter.length === 0) return null;

    // Group the data based on fullName
    const dataGroup = groupBy(dataFilter, constantData.fullName);

    // Construct the tempData array using the grouped data
    const tempData = Object.keys(dataGroup).map((key) => ({
      label: key,
      children: distinctArray(
        dataGroup[key],
        (a, b) => a.skillCode === b.skillCode
      ),
    }));

    // Generate and return the tree node using the constructed tempData
    return generateTreeNode(tempData);
  }, [dataFilter]); // Recompute only if dataFilter changes

  // This effect runs when the component mounts.
  useEffect(() => {
    // This variable helps us ensure that we don't call `setIsContentLoaded` if the component has unmounted before our asynchronous operation completes.
    let isMounted = true;

    // Fetch and process all data
    processAllData().then(() => {
      if (isMounted) {
        // Only set the loading state to false if the component is still mounted
        setIsContentLoaded(true);
      }
    });

    // The cleanup function for the effect
    return () => {
      // If the component unmounts, we set isMounted to false to prevent any state updates after unmounting.
      isMounted = false;
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts and never again.

  // If content is still loading, display a spinner.
  if (!isContentLoaded) {
    return <DivCenter Component={() => <Spinner />} />;
  }

  return (
    <Container>
      {/* Filter */}
      <TeamBuilderFilters
        objAttribute={{
          options: optionsAttribute,
          value: selectedAttribute,
          setValue: setSelectedAttribute,
        }}
        objCountry={{
          options: optionsCountry,
          value: selectedCountry,
          setValue: setSelectedCountry,
        }}
        objSkillLevel={{
          options: optionsSkillLevel,
          value: selectedSkillLevel,
          setValue: setSelectedSkillLevel,
        }}
        objProfileStatus={{
          options: optionsProfileStatus,
          value: selectedProfileStatus,
          setValue: setSelectedProfileStatus,
        }}
        objPosition={{
          options: optionsPosition,
          value: selectedPosition,
          setValue: setSelectedPosition,
        }}
        objSkill={{
          options: optionsSkill,
          value: selectedSkill,
          setValue: setSelectedSkill,
        }}
      ></TeamBuilderFilters>
      {/* Organizational Chart */}
      <ContainerBorder className="mt-4">
        <OrganizationalChart
          data={dashboardOrganizationalChart}
        ></OrganizationalChart>
      </ContainerBorder>
      {/* TeamBuilder Table */}
      <ContainerBorder className="mt-4">
        <TeamBuilderTable
          pageSize={constantData.pageSize}
          data={dashboardTable}
        ></TeamBuilderTable>
      </ContainerBorder>
    </Container>
  );
};

export { TeamBuilder };
