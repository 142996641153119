import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const apiHostname = process.env.REACT_APP_API_ENDPOINT;

class Analytics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            height: 0,
        };
    }

    componentDidMount() {
        const element = document.getElementById("DivPowerBI");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ height: window.innerHeight });
    }

    render() {
        const containerConfig = this.props.containerConfig;

        return (
            <React.Fragment>
                {containerConfig && containerConfig.powerBiReportId && containerConfig.powerBiWorkspaceId && containerConfig.powerBiEmbeddedToken && containerConfig.powerBiEmbeddedUrl && (
                    <React.Fragment>
                        <div id="DivPowerBI" style={{ height: `${this.state.height}px` }}>
                            <PowerBIEmbed
                                embedConfig={{
                                    type: "report", // Supported types: report, dashboard, tile, visual and qna
                                    id: containerConfig.powerBiReportId,
                                    embedUrl: containerConfig.powerBiRepowerBiEmbeddedUrlportId,
                                    accessToken: containerConfig.powerBiEmbeddedToken,
                                    tokenType: models.TokenType.Embed,
                                    settings: {
                                        panes: {
                                            filters: {
                                                expanded: false,
                                                visible: false,
                                            },
                                        },
                                        background: models.BackgroundType.Transparent,
                                    },
                                }}
                                eventHandlers={
                                    new Map([
                                        [
                                            "loaded",
                                            function () {
                                                console.log("Report loaded");
                                            },
                                        ],
                                        [
                                            "rendered",
                                            function () {
                                                console.log("Report rendered");
                                            },
                                        ],
                                        [
                                            "error",
                                            function (event) {
                                                console.log(event.detail);
                                            },
                                        ],
                                    ])
                                }
                                cssClassName={"powerbi-report-style-class"}
                                getEmbeddedComponent={(embeddedReport) => {
                                    this.report = embeddedReport;
                                }}
                            />
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        token: state.auth.token,
        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
