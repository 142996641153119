import ErrorHandler from "./../errorResponseHandler";
import Instance from "./../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminInterventionAPI {
    static getInterventions(selectedProviderOnly, includeInterventionJobs, showWorkspaceOwnerActionOnly) {
        return axios
            .get(`/actionplans/interventions?selectedProviderOnly=${selectedProviderOnly ? "true" : "false"}${includeInterventionJobs ? `&includeInterventionJobs=true` : ""}&showWorkspaceOwnerActionOnly=${showWorkspaceOwnerActionOnly ? `true` : `false`}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createIntervention(intervention) {
        return axios
            .post(`/actionplans/interventions`, intervention)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateIntervention(id, intervention) {
        return axios
            .put(`/actionplans/interventions/${id}`, intervention)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteIntervention(id) {
        return axios
            .delete(`/actionplans/interventions/${id}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteInterventions(interventionIds) {
        return axios
            .post(`/actionplans/interventions/delete`, { interventionIds })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static exportInterventionsDataExcel(timezoneOffset) {
        return axios
            .get(`/actionplans/interventions/export?selectedProviderOnly=false&timezoneOffset=${timezoneOffset}`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static exportInterventionsDataExcelTemplate() {
        return axios
            .get(`/actionplans/interventions/exporttemplate`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static importInterventionsDataExcel(interventionsData) {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        const body = new FormData();
        body.append("ContentDisposition", "attachment");
        body.append("ContentType", "multipart/form-data");

        interventionsData.forEach((file) => {
            body.append("interventionsData", file);
        });

        return axios
            .post(`/actionplans/interventions/import`, body, config)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static assignInterventionJob(interventionId, jobId) {
        return axios
            .post(`/actionplans/interventionJobs`, {
                interventionId,
                jobId,
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteInterventionJob(id) {
        return axios
            .delete(`/actionplans/interventionJobs/${id}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteInterventionJobs(interventionJobIds) {
        return axios
            .post(`/actionplans/interventionJobs/delete`, {
                interventionJobIds,
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getNoOfActiveActionPromotion() {
        return axios
            .get(`/actionplans/actionpromotions/activenumber`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getActiveActionPromotions() {
        return axios
            .get(`/actionplans/actionpromotions`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateActionPromotion(id, actionPromotion) {
        return axios
            .put(`/actionplans/actionpromotions/${id}`, actionPromotion)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static acceptActionPromotion(id) {
        return axios
            .post(`/actionplans/actionpromotions/${id}/accept`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static declineActionPromotion(id, declineReason) {
        return axios
            .post(`/actionplans/actionpromotions/${id}/decline`, { declineReason })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default AdminInterventionAPI;
