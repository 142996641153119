import * as actionTypes from "../actions/actionTypes";

import { updateState } from "../utility";

const initialState = {
    countries: [],
    error: null,
    loading: false,
};

const getCountriesStart = (state) => {
    return updateState(state, { error: null, countries: [], loading: true });
};

const getCountriesSuccess = (state, action) => {
    return updateState(state, {
        countries: action.countries,
        error: null,
        loading: false,
    });
};

const getCountriesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COUNTRIES_START:
            return getCountriesStart(state);
        case actionTypes.GET_COUNTRIES_SUCCESS:
            return getCountriesSuccess(state, action);
        case actionTypes.GET_COUNTRIES_FAIL:
            return getCountriesFail(state, action);
        default:
            return state;
    }
};

export default reducer;
