import { Button } from "reactstrap";
import { Layout } from "./Layout";
import { ContentWrapper } from "./common/ContentWrapper";
import React from "react";
import { CSVLink } from "react-csv";

class TeamTabs extends React.Component {
    static displayName = TeamTabs.name;

    constructor(props) {
        super(props);
        this.state = {
            emailAddress: null,
            tableHeader: ["Name", "Email", "User Principal Name"],
            csvData: [],
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const emailAddress = query.get("emailAddress");
        this.setState({ emailAddress });
    }

    handleDownloadExcel = () => {
        this.setState({
            csvData: [this.state.tableHeader, ["Test 1", "test1@skillstx.com", "test1@skillstx.com"], ["Test 2", "test2.collins@skillstx.com", "test2.collins@skillstx.com"]],
        });
        return true;
    };

    render() {
        return (
            <Layout>
                <ContentWrapper>
                    <div>Redirect from Teams; {this.state.emailAddress ? `Email Address ${this.state.emailAddress}` : "No Email"}</div>
                    <div>
                        <CSVLink data={this.state.csvData} onClick={this.handleDownloadExcel} filename="contact.csv">
                            <Button appearance="primary">Export Contact Table to Excel</Button>
                        </CSVLink>
                    </div>
                </ContentWrapper>
            </Layout>
        );
    }
}

export default TeamTabs;
