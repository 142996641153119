import { updateState } from "../../utility";
import * as actionTypes from "../actions/adminActionTypes";

const initialState = {
    error: null,
    loading: false,
    isUpdating: false,
    allowedRegistrationEmailDomains: [],
};

const getAllowedRegistrationEmailDomainsStart = (state) => {
    return updateState(state, { error: null, loading: true, allowedRegistrationEmailDomains: [] });
};

const getAllowedRegistrationEmailDomainsSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        loading: false,
        allowedRegistrationEmailDomains: action.allowedRegistrationEmailDomains,
    });
};

const getAllowedRegistrationEmailDomainsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const addAllowedRegistrationEmailDomainStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const addAllowedRegistrationEmailDomainSuccess = (state, action) => {
    const allowedRegistrationEmailDomains = state.allowedRegistrationEmailDomains;
    allowedRegistrationEmailDomains.push(action.addedAllowedRegistrationEmailDomain);

    return updateState(state, {
        error: null,
        isUpdating: false,
        allowedRegistrationEmailDomains: [...allowedRegistrationEmailDomains],
    });
};

const addAllowedRegistrationEmailDomainFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateAllowedRegistrationEmailDomainStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateAllowedRegistrationEmailDomainSuccess = (state, action) => {
    const updatedAllowedRegistrationEmailDomain = action.updatedAllowedRegistrationEmailDomain;
    const allowedRegistrationEmailDomains = state.allowedRegistrationEmailDomains;

    const allowedRegistrationEmailDomain = allowedRegistrationEmailDomains.find((it) => it.id == updatedAllowedRegistrationEmailDomain.id);
    if (allowedRegistrationEmailDomain) {
        allowedRegistrationEmailDomain.emailDomain = updatedAllowedRegistrationEmailDomain.emailDomain;
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        allowedRegistrationEmailDomains: [...allowedRegistrationEmailDomains],
    });
};

const updateAllowedRegistrationEmailDomainFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteAllowedRegistrationEmailDomainStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteAllowedRegistrationEmailDomainSuccess = (state, action) => {
    const deletedAllowedRegistrationEmailDomainId = action.deletedAllowedRegistrationEmailDomainId;
    console.log("deletedAllowedRegistrationEmailDomainId", deletedAllowedRegistrationEmailDomainId);
    const allowedRegistrationEmailDomains = state.allowedRegistrationEmailDomains;

    const registrationEmailDomain = allowedRegistrationEmailDomains.find((it) => it.id == deletedAllowedRegistrationEmailDomainId);
    if (registrationEmailDomain) {
        const index = allowedRegistrationEmailDomains.indexOf(registrationEmailDomain);
        if (index !== -1) {
            allowedRegistrationEmailDomains.splice(index, 1);
        }
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        allowedRegistrationEmailDomains: [...allowedRegistrationEmailDomains],
    });
};

const deleteAllowedRegistrationEmailDomainFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALLOWED_REGISTRATION_EMAIL_DOMAINS_START:
            return getAllowedRegistrationEmailDomainsStart(state);
        case actionTypes.GET_ALLOWED_REGISTRATION_EMAIL_DOMAINS_SUCCESS:
            return getAllowedRegistrationEmailDomainsSuccess(state, action);
        case actionTypes.GET_ALLOWED_REGISTRATION_EMAIL_DOMAINS_FAIL:
            return getAllowedRegistrationEmailDomainsFail(state, action);

        case actionTypes.ADD_ALLOWED_REGISTRATION_EMAIL_DOMAIN_START:
            return addAllowedRegistrationEmailDomainStart(state);
        case actionTypes.ADD_ALLOWED_REGISTRATION_EMAIL_DOMAIN_SUCCESS:
            return addAllowedRegistrationEmailDomainSuccess(state, action);
        case actionTypes.ADD_ALLOWED_REGISTRATION_EMAIL_DOMAIN_FAIL:
            return addAllowedRegistrationEmailDomainFail(state, action);

        case actionTypes.UPDATE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_START:
            return updateAllowedRegistrationEmailDomainStart(state);
        case actionTypes.UPDATE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_SUCCESS:
            return updateAllowedRegistrationEmailDomainSuccess(state, action);
        case actionTypes.UPDATE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_FAIL:
            return updateAllowedRegistrationEmailDomainFail(state, action);

        case actionTypes.DELETE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_START:
            return deleteAllowedRegistrationEmailDomainStart(state);
        case actionTypes.DELETE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_SUCCESS:
            return deleteAllowedRegistrationEmailDomainSuccess(state, action);
        case actionTypes.DELETE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_FAIL:
            return deleteAllowedRegistrationEmailDomainFail(state, action);

        default:
            return state;
    }
};

export default reducer;
