import * as adminPersonActions from "../../../store/admin/actions/adminPersonActions";
import { faCircleCheck, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, CustomInput, Popover, PopoverBody, PopoverHeader, Row, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

class UserRoleCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popOverUpdateRoleOpen: false,
            roleSelection: [],
            roles: this.props.roles,
            dirty: false,
            inProgress: false,
        };
    }

    componentDidMount() {
        const roles = this.props.roles;
        let roleSelection = [];

        if (roles && roles.length > 0) {
            roleSelection = [...roles];
        } else {
            roleSelection.push({ role: "User" });
        }
        this.setState({ roleSelection });
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.roles !== this.props.roles) {
            this.setState({ roles: this.props.roles });
        }

        if (prevProps.resetPasswordSent !== this.props.resetPasswordSent && this.props.resetPasswordSent) {
            this.props.generateAlert("success", "Reset password request sent");
        }
    }

    togglePopover = () => {
        const popOverUpdateRoleOpen = this.state.popOverUpdateRoleOpen;

        this.setState({ popOverUpdateRoleOpen: !popOverUpdateRoleOpen, dirty: false, inProgress: false });
    };

    openUpdateRoles = () => {
        const popOverUpdateRoleOpen = this.state.popOverUpdateRoleOpen;

        const roles = this.props.roles;
        let roleSelection = [];

        if (roles && roles.length > 0) {
            roleSelection = [...roles];
        } else {
            roleSelection.push({ role: "User" });
        }

        this.setState({ popOverUpdateRoleOpen: !popOverUpdateRoleOpen, roleSelection });
    };

    handleRoleCheckedChange = (role) => {
        let roleSelection = this.state.roleSelection;

        if (roleSelection.find((it) => it.role == role)) {
            var objRole = roleSelection.find((it) => it.role == role);
            var index = roleSelection.indexOf(objRole);
            roleSelection.splice(index, 1);
        } else {
            roleSelection.push({
                role,
            });
        }

        this.setState({ roleSelection, dirty: true });
    };

    handleUpdateRoles = () => {
        const roleSelection = this.state.roleSelection;
        this.setState({ inProgress: true });
        this.props.onPerson
            .updatePersonRoles(
                this.props.person.id,
                roleSelection.map((it) => {
                    return it.role;
                })
            )
            .then(() => {
                if (!this.props.isUpdating && !this.props.error) {
                    this.props.personRolesUpdated(this.props.person.id, this.props.updatedRoles);
                    this.setState({ popOverUpdateRoleOpen: false });
                }

                if (roleSelection.find((it) => it.role == "Admin") && (!this.props.person.havePassword || this.props.person.havePassword == false) && (!this.props.person.resetPasswordRequested || this.props.person.resetPasswordRequested == false)) {
                    if (window.confirm("Do you want to send reset password email to this new admin user?")) {
                        this.props.onPerson.resetPersonPassword(this.props.person.id).then(() => {});
                    }
                }

                this.setState({ inProgress: this.props.isUpdating });
            });
    };

    render() {
        const person = this.props.person;
        const roles = this.state.roles;
        const roleSelection = this.state.roleSelection;

        const strRoles = roles
            .sort((a, b) => ((a.role == "Admin" ? 2 : 1) > (b.role == "Admin" ? 2 : 1) ? 1 : -1))
            .map((it) => {
                return it.role;
            });

        return (
            <React.Fragment>
                {this.props.allowEdit ? (
                    <React.Fragment>
                        <Link to="#" id={`LKRole${person.id}`} onClick={() => this.openUpdateRoles()}>
                            {strRoles.length > 0 ? strRoles.join(", ") : "User"}
                        </Link>
                        <UncontrolledTooltip target={`LKRole${person.id}`} placement="bottom">
                            Update Person Roles
                        </UncontrolledTooltip>
                        <Popover placement="bottom" isOpen={this.state.popOverUpdateRoleOpen} target={`LKRole${person.id}`} toggle={this.togglePopover}>
                            <PopoverHeader>
                                <Row>
                                    <Col sm="7">Update roles</Col>
                                    <Col sm="5">
                                        <button
                                            className={this.state.roleSelection && this.state.roleSelection.length > 0 && this.state.dirty ? "btn-primary" : "btn-secondary"}
                                            disabled={!this.state.dirty || this.state.inProgress || this.state.roleSelection.length == 0}
                                            onClick={this.handleUpdateRoles}
                                        >
                                            <FontAwesomeIcon icon={faCircleCheck} />
                                        </button>
                                        &nbsp;
                                        <button className={`btn-danger`} onClick={this.togglePopover} disabled={this.state.inProgress}>
                                            <FontAwesomeIcon icon={faCancel} />
                                        </button>
                                    </Col>
                                </Row>
                            </PopoverHeader>
                            <PopoverBody>
                                <Row>
                                    <Col sm="6">
                                        <CustomInput
                                            key={`chkRoleUser${person.id}`}
                                            type="checkbox"
                                            className="custom-100"
                                            name={`chkRoleUser${person.id}`}
                                            id={`chkRoleUser${person.id}`}
                                            checked={roleSelection.find((it) => it.role == "User") ? true : false}
                                            label="User"
                                            onChange={() => this.handleRoleCheckedChange("User")}
                                            disabled={this.state.inProgress}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <CustomInput
                                            key={`chkRoleAdmin${person.id}`}
                                            type="checkbox"
                                            className="custom-100"
                                            name={`chkRoleAdmin${person.id}`}
                                            id={`chkRoleAdmin${person.id}`}
                                            checked={roleSelection.find((it) => it.role == "Admin") ? true : false}
                                            label="Admin"
                                            onChange={() => this.handleRoleCheckedChange("Admin")}
                                            disabled={this.state.inProgress}
                                        />
                                    </Col>
                                </Row>
                            </PopoverBody>
                        </Popover>
                    </React.Fragment>
                ) : (
                    <div>{strRoles.length > 0 ? strRoles.join(", ") : "User"}</div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUpdating: state.adminPerson.roleUpdating,
        error: state.adminPerson.error,
        updatedRoles: state.adminPerson.updatedRoles,
        resetPasswordSent: state.adminPerson.resetPasswordSent,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPerson: bindActionCreators(adminPersonActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRoleCell);
