import * as personActions from "../../store/actions/personAction";
import ContentTranslator from "../translator/ContentTranslator";
import "./../../custom-card-profile.css";
import axios from "axios";
import React, { Fragment } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { connect } from "react-redux";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

class UpdateProfilePicture extends React.Component {
    static displayName = UpdateProfilePicture.name;

    constructor(props) {
        super(props);

        this.state = {
            src: null,
            crop: {
                unit: "%",
                x: 5,
                y: 5,
                width: 90,
                aspect: 16 / 16,
            },
            updating: false,
            alerts: [],
        };

        this.imageRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showModal !== this.props.showModal) {
            this.setState({ showModal: true });
        }

        if (prevProps.src !== this.props.src) {
            this.setState({ src: this.props.src });
        }
    }

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };

    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => this.setState({ src: reader.result }));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, "newFile.jpeg");
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width * scaleX, crop.height * scaleY);

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        //reject(new Error('Canvas is empty'));
                        console.error("Canvas is empty");
                        return;
                    }
                    blob.name = fileName;
                    window.URL.revokeObjectURL(this.fileUrl);
                    this.fileUrl = window.URL.createObjectURL(blob);
                    resolve(this.fileUrl);
                },
                "image/jpeg",
                1
            );
        });
    }

    uploadImage = () => {
        const blobUrl = this.state.croppedImageUrl;
        const config = { responseType: "blob" };

        Promise.resolve(axios.get(blobUrl, config)).then((response) => {
            const file = new File([response.data], "filename.jpg");
            this.setState({ updating: true });
            this.props.onPerson.uploadPersonProfilePicture(file).then(() => {
                if (!this.props.updating) {
                    if (this.props.error) {
                    } else {
                        this.props.pictureUpdated();
                        this.setState({ showModal: false });
                    }
                }
                this.setState({ updating: this.props.updating });
            });
        });
    };

    selectPicture = () => {
        this.imageRef.current.click();
    };

    handleChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({ src: reader.result });
            });
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    render() {
        const { crop, croppedImageUrl, src } = this.state;

        return (
            <Fragment>
                <Modal isOpen={this.state.showModal} toggle={this.toggleModal} size="md" centered={true}>
                    <ModalBody>
                        <input ref={this.imageRef} type="file" style={{ display: "none" }} accept="image/*" onChange={this.handleChange} />
                        {src && (
                            <div>
                                <div style={{ textAlign: "center" }}>
                                    <ReactCrop width="400px" height="400px" src={src} crop={crop} ruleOfThirds onImageLoaded={this.onImageLoaded} onComplete={this.onCropComplete} onChange={this.onCropChange} />
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    {this.state.updating ? (
                                        <Spinner type="grow" size="md" color="dark" />
                                    ) : (
                                        <Fragment>
                                            <button className="btn btn-light btnSecondary-Style" onClick={this.selectPicture} disabled={this.state.updating}>
                                                <ContentTranslator page="SfiaProfile" name="ProfilePictureButtonReplaceImage" contentText="Replace Image" />
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button className="btn btn-light btnSecondary-Style" onClick={this.uploadImage} disabled={this.state.updating}>
                                                <ContentTranslator page="SfiaProfile" name="ProfilePictureButtonUpload" contentText="Upload" />
                                            </button>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        )}
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.person.loading,
        error: state.person.error,
        person: state.person.person,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPerson: bindActionCreators(personActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfilePicture);
