import * as authActions from "../../../store/actions/authAction";
import * as configActions from "../../../store/actions/configAction";
import * as adminPersonActions from "../../../store/admin/actions/adminPersonActions";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { filterArray } from "../../../utils/filterUtils";
import { contentTranslator } from "../../../utils/translatorUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import { HubConnectionBuilder } from "@aspnet/signalr";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCopy, faPenToSquare, faSquareCheck, faSquareMinus, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Input, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
const messageHubHostname = process.env.NODE_ENV === "development" ? process.env.REACT_APP_MESSAGE_HUB_ENDPOINT : "/messagehub";

class ManageProfiles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            persons: [],
            selectedPerson: null,
            isUpdating: false,
            personsSelected: [],
            modeCopyProfiles: false,
        };

        this.filterArray = filterArray.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    connectSignalR = () => {
        this.connection = new HubConnectionBuilder().withUrl(messageHubHostname).build();

        this.connection.on(`CopySelfAssessedProfileNotification`, (response) => {
            if (response.action == "CopySelfAssessedProfile") {
                if (response.status == "Success") {
                    let copyProfileRequestResponse = response.data;
                    this.props.onPerson.updateCopySelfProfilesAsEndorsedStatus(copyProfileRequestResponse);
                    this.generateAlert("success", "Some copy profile request status updated.");
                }
            }
        });

        this.connection.start().catch((err) => console.error(err.toString()));
    };

    disconnectSignalR = () => {
        if (this.connection) this.connection.stop();
    };

    componentDidMount() {
        localStorage.setItem("routeAdminFrom", "/admin/selfprofiles");
        localStorage.setItem("manageProfileByAdminPopup", "FALSE");

        this.props.onAuth.authCheckState().then(() => {
            this.initData();
            this.setState({ loading: this.props.loading });
            this.connectSignalR();
        });
    }

    componentWillUnmount() {
        this.disconnectSignalR();
        localStorage.setItem("showApprovedProfileOnly", "FALSE");
    }

    searchProperties = () => {
        return ["email", "firstName", "lastName", "lastApprovedBy", "copyProfileRequestStatus"];
    };

    componentDidUpdate(prevProps) {
        if (prevProps.persons !== this.props.persons && this.props.persons) {
            this.setState({
                persons: this.reformPersons(
                    this.props.persons
                        .filter((it) => !it.inactive && it.accounts && it.accounts.filter((acc) => acc.assessmentId == this.props.assessmentId && acc.approvals && acc.approvals.length > 0).length > 0)
                        .sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))
                ),
            });
        }

        if (prevProps.mode !== this.props.mode && prevProps.mode) {
            this.handleRefresh();
        }
    }

    initData = () => {
        this.props.onPerson.getPersonsIncludeCopySelfProfileStatus().then(() => {
            this.setState({
                persons: this.reformPersons(
                    this.props.persons
                        .filter((it) => !it.inactive && it.accounts && it.accounts.filter((acc) => acc.assessmentId == this.props.assessmentId && acc.approvals && acc.approvals.length > 0).length > 0)
                        .sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))
                ),
            });
        });
    };

    reformPersons = (persons) => {
        return persons.map((it) => {
            return {
                id: it.id,
                companyId: it.companyId,
                firstName: it.firstName,
                lastName: it.lastName,
                email: it.email,
                completeName: `${it.firstName}${it.lastName ? ` ${it.lastName}` : ""}${it.email ? ` ${it.email}` : ""}`,
                salutation: it.salutation ? it.salutation : "",
                position: it.position ? it.position : "",
                inactive: it.inactive,
                personType: it.personType ? it.personType : "",
                accessGroup: it.accessGroup,
                allowNotifications: it.allowNotifications,
                lastActivityOn: it.lastActivityOn,
                lastActivitySystem: it.lastActivitySystem,
                createdOn: it.createdOn,
                countryCode: it.countryCode ? it.countryCode : "",
                country: it.country ? it.country : "",
                location: it.location ? it.location : "",
                selfAssessmentReason: it.selfAssessmentReason ? it.selfAssessmentReason : "",
                encryptedId: it.encryptedId,
                team: it.team ? it.team : "",
                usePreferredName: it.usePreferredName,
                other: it.other ? it.other : "",
                department: it.department ? it.department : "",
                businessUnit: it.businessUnit ? it.businessUnit : "",
                division: it.division ? it.division : "",
                territory: it.territory ? it.territory : "",
                costCentre: it.costCentre ? it.costCentre : "",
                supervisor: it.supervisor ? it.supervisor : "",
                validatedDate: it.validatedDate,
                isCandidate: it.isCandidate,
                isAnalyticsUser: it.isAnalyticsUser,
                leaveOn: it.leaveOn,
                isAssigned: it.isAssigned,
                isInvited: it.isInvited,
                invitedOn: it.invitedOn,
                teams: it.teams ? [...it.teams] : null,
                profilePictureUrl: it.profilePictureUrl,
                activityStatementInterval: it.activityStatementInterval,
                lastUpdatedOn: it.lastUpdatedOn,
                lastUpdatedVersion: it.lastUpdatedVersion,
                latestAssessmentId: it.latestAssessmentId,
                cartridgeId: it.cartridgeId,
                account: it.accounts ? it.accounts.sort((b, a) => new Date(a.createdOn) - new Date(b.createdOn))[0] : null,
                lastApprovedVersion: it.selfAssessedLastApprovedVersion,
                lastApprovedOn: it.selfAssessedLastApprovedOn,
                lastApprovedBy: it.selfAssessedLastApprovedBy,
                copyProfileRequested: it.selfAssessedCopyProfileRequested,
                copyProfileRequestedOn: it.selfAssessedCopyProfileRequestedOn,
                copyProfileRequestStatus: it.selfAssessedCopyProfileRequestStatus ? it.selfAssessedCopyProfileRequestStatus : "Not Requested",
            };
        });
    };

    handleRefresh = () => {
        this.setState({ personsSelected: [] });
        this.initData();
    };

    pad2 = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    toFixedIfNecessary = (value, dp) => {
        return +parseFloat(value).toFixed(dp);
    };

    toggleCopyProfiles = () => {
        this.handleClearSelection();
        const modeCopyProfiles = this.state.modeCopyProfiles;
        this.setState({ modeCopyProfiles: !modeCopyProfiles });
    };

    handleRowClick = (row) => {
        if (!row.copyProfileRequested) {
            const persons = this.state.persons;
            const updatedData = persons.find((it) => it.id == row.id);
            updatedData.toggleSelected = !updatedData.toggleSelected;

            this.setState({ persons: [...persons], personsSelected: persons.filter((it) => it.toggleSelected).map((row) => row.id) });
        }
    };

    handleSelectAll = () => {
        const { persons, searchKeyword, columnSearchKeywords } = this.state;
        let selectAll = false;

        const filtered = this.filterArray(persons, searchKeyword, this.searchProperties(), columnSearchKeywords);

        if (filtered.filter((it) => it.toggleSelected && !it.copyProfileRequested).length == 0) {
            selectAll = true;
        } else if (filtered.filter((it) => !it.toggleSelected && !it.copyProfileRequested).length == 0) {
            selectAll = false;
        } else {
            selectAll = true;
        }

        persons.forEach((it) => {
            it.toggleSelected = false;
        });

        if (selectAll) {
            persons.forEach((it) => {
                if (filtered.find((f) => f.id == it.id)) {
                    if (!it.copyProfileRequested) {
                        it.toggleSelected = true;
                    }
                }
            });
        }

        this.setState({ persons: [...persons], personsSelected: persons.filter((it) => it.toggleSelected).map((row) => row.id) });
    };

    handleClearSelection = () => {
        const persons = this.state.persons;

        persons.forEach((it) => {
            it.toggleSelected = false;
        });

        this.setState({ persons: [...persons] });
    };

    showProfile = (person) => {
        localStorage.setItem("showApprovedProfileOnly", "TRUE");
        localStorage.setItem("profileType", "Self Assessed");
        localStorage.setItem("manageProfileByAdminPopup", "TRUE");

        this.props.history.push(`/SfiaProfile/Manage?email=${person.email}`);
    };

    handleConfirmRequestCopyProfile = () => {
        const { persons, searchKeyword, modeCopyProfiles, personsSelected } = this.state;

        const copiedProfiles = persons
            .filter((it) => personsSelected.includes(it.id))
            .map((it) => {
                return {
                    personId: it.id,
                    profileType: "Self Assessed",
                    version: it.lastApprovedVersion,
                };
            });

        this.setState({ isUpdating: true });

        this.props.onPerson.requestCopySelfProfilesAsEndorsed(copiedProfiles).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.personsError) {
                    this.generateAlert("success", "Copy profiles requested.");
                    this.toggleCopyProfiles();

                    const copyProfileRequestIds = this.props.copyProfileRequests.map((it) => {
                        return it.id;
                    });

                    Promise.resolve(this.props.onPerson.processCopySelfProfilesAsEndorsed(copyProfileRequestIds)).then(() => {});
                } else {
                    this.generateAlert("danger", this.props.personsError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    onTableFilterChange = (e, searchType) => {
        let columnSearchKeywords = this.state.columnSearchKeywords;
        if (!columnSearchKeywords) {
            columnSearchKeywords = [];
        }

        if (columnSearchKeywords.find((it) => it.name == e.target.name)) {
            let columnSearchKeyword = columnSearchKeywords.find((it) => it.name == e.target.name);
            columnSearchKeyword.value = e.target.value;
        } else {
            columnSearchKeywords.push({
                name: e.target.name,
                value: e.target.value,
                searchType,
            });
        }

        this.setState({ columnSearchKeywords });
    };

    render() {
        const { persons, searchKeyword, modeCopyProfiles, personsSelected, columnSearchKeywords } = this.state;
        const pageAssignments = this.props.pageAssignments;

        let pageAssignment = null;

        if (pageAssignments.find((it) => it.pageName == "Create Endorsed from Approved")) {
            pageAssignment = pageAssignments.find((it) => it.pageName == "Create Endorsed from Approved");
        }

        const handleRowClick = this.handleRowClick;
        const handleSelectAll = this.handleSelectAll;
        const allowCopy = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Copy Profile as Endorsed" && op.allow == true) ? true : false;
        const filtered = this.filterArray(persons, searchKeyword, this.searchProperties(), columnSearchKeywords);
        const showProfile = this.showProfile;
        const onTableFilterChange = this.onTableFilterChange;

        const columns = [
            {
                id: "id",
                name: (
                    <div style={{ textAlign: "center" }}>
                        <FontAwesomeIcon
                            id={`headerChkSelectAll`}
                            icon={filtered.filter((it) => it.toggleSelected && !it.copyProfileRequested).length == 0 ? faSquare : filtered.filter((it) => !it.toggleSelected && !it.copyProfileRequested).length == 0 ? faSquareCheck : faSquareMinus}
                            style={{ cursor: "pointer", color: filtered.filter((it) => it.toggleSelected && !it.copyProfileRequested).length == 0 ? "#8f8f9d" : "#007bff" }}
                            size="lg"
                            onClick={() => handleSelectAll()}
                        />
                    </div>
                ),
                selector: (row) => row.id,
                sortable: false,
                width: "6%",
                center: true,
                format: function (row, index) {
                    return !row.copyProfileRequested ? (
                        <div style={{ textAlign: "center" }}>
                            <Input type="checkbox" id={`chkRow${row.id}`} checked={row.toggleSelected} onClick={(e) => handleRowClick(row)} />
                        </div>
                    ) : (
                        ""
                    );
                },
                omit: !modeCopyProfiles,
            },
            {
                id: "lastUpdatedOn",
                name: "Action",
                selector: (row) => row.id,
                sortable: true,
                width: "6%",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <React.Fragment>
                                <FontAwesomeIcon id={`iconEditProfile${row.id}`} icon={faPenToSquare} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => showProfile(row)} />
                                <UncontrolledTooltip target={`iconEditProfile${row.id}`} placement="bottom">
                                    Show Profile
                                </UncontrolledTooltip>
                            </React.Fragment>
                        </div>
                    );
                },
                omit: !allowCopy,
            },
            {
                id: "email",
                name: (
                    <div style={{ width: "90%" }}>
                        Person Name (email)
                        <br />
                        <input type="text" name="completeName" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "completeName") ? columnSearchKeywords.find((it) => it.name == "completeName").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.email,
                sortable: true,
                width: modeCopyProfiles ? "30%" : allowCopy ? "36%" : "41%",
                wrap: true,
                format: function (row, index) {
                    return modeCopyProfiles && !row.copyProfileRequested ? <span onClick={(e) => handleRowClick(row)}>{`${row.firstName} ${row.lastName} (${row.email})`}</span> : <span>{`${row.firstName} ${row.lastName} (${row.email})`}</span>;
                },
            },
            {
                id: "lastApprovedOn",
                name: "Last Approved On",
                selector: (row) => row.lastApprovedOn,
                sortable: true,
                width: "13%",
                wrap: true,
                format: function (row, index) {
                    return modeCopyProfiles && !row.copyProfileRequested ? (
                        <span onClick={(e) => handleRowClick(row)}>
                            {new Intl.DateTimeFormat("en-GB", {
                                year: "2-digit",
                                month: "long",
                                day: "numeric",
                            }).format(new Date(row.lastApprovedOn))}
                        </span>
                    ) : (
                        new Intl.DateTimeFormat("en-GB", {
                            year: "2-digit",
                            month: "long",
                            day: "numeric",
                        }).format(new Date(row.lastApprovedOn))
                    );
                },
            },
            {
                id: "lastApprovedVersion",
                name: (
                    <div style={{ width: "90%" }}>
                        Last Approved Version
                        <br />
                        <input
                            type="text"
                            name="lastApprovedVersion"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "lastApprovedVersion") ? columnSearchKeywords.find((it) => it.name == "lastApprovedVersion").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.lastApprovedVersion,
                sortable: true,
                width: "10%",
                wrap: true,
                format: function (row, index) {
                    return modeCopyProfiles && !row.copyProfileRequested ? <span onClick={(e) => handleRowClick(row)}>{`v${row.lastApprovedVersion}`}</span> : `v${row.lastApprovedVersion}`;
                },
                center: true,
            },
            {
                id: "lastApprovedBy",
                name: (
                    <div style={{ width: "90%" }}>
                        Last Approved By
                        <br />
                        <input
                            type="text"
                            name="lastApprovedBy"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "lastApprovedBy") ? columnSearchKeywords.find((it) => it.name == "lastApprovedBy").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.lastApprovedBy,
                sortable: true,
                width: "20%",
                wrap: true,
                format: function (row, index) {
                    return modeCopyProfiles && !row.copyProfileRequested ? <span onClick={(e) => handleRowClick(row)}>{row.lastApprovedBy}</span> : row.lastApprovedBy;
                },
            },
            {
                id: "copyProfileRequestStatus",
                name: (
                    <div style={{ width: "90%" }}>
                        Copy Profile Status
                        <br />
                        <input
                            type="text"
                            name="copyProfileRequestStatus"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "copyProfileRequestStatus") ? columnSearchKeywords.find((it) => it.name == "copyProfileRequestStatus").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.copyProfileRequestStatus,
                sortable: true,
                width: "15%",
                wrap: true,
                format: function (row, index) {
                    return modeCopyProfiles && !row.copyProfileRequested ? <span onClick={(e) => handleRowClick(row)}>{row.copyProfileRequestStatus}</span> : row.copyProfileRequestStatus;
                },
            },
        ];

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <div style={{ marginBottom: "10px" }}>
                            <Row>
                                <Col md="5" sm="6">
                                    <SkillsTxTableSearchBar
                                        searchKeyword={this.state.searchKeyword}
                                        onChange={(searchKeyword) => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword, personsSkillProfileSelected: [] });
                                        }}
                                    ></SkillsTxTableSearchBar>
                                </Col>
                                <Col md="7" sm="6">
                                    <SkillsTxTableClearSearch
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        color="primary"
                                        disabled={this.props.personsLoading || this.state.isUpdating}
                                        onClear={() => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword: "", personsSkillProfileSelected: [] });
                                        }}
                                    ></SkillsTxTableClearSearch>
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        onClick={this.handleRefresh}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        disabled={this.props.personsLoading || this.state.isUpdating}
                                    >
                                        <FontAwesomeIcon icon={faSync} /> Refresh
                                    </Button>
                                    {allowCopy && (
                                        <React.Fragment>
                                            &nbsp;&nbsp;
                                            <Button
                                                color="primary"
                                                onClick={this.toggleCopyProfiles}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.personsLoading || modeCopyProfiles}
                                            >
                                                <FontAwesomeIcon icon={faCopy} /> Copy Profiles
                                            </Button>
                                        </React.Fragment>
                                    )}
                                    {allowCopy && modeCopyProfiles && (
                                        <React.Fragment>
                                            &nbsp;&nbsp;
                                            <Button
                                                color="primary"
                                                onClick={this.handleConfirmRequestCopyProfile}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.personsLoading || this.props.isUpdating || personsSelected.length == 0}
                                            >
                                                OK
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                color="primary"
                                                onClick={this.toggleCopyProfiles}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.personsLoading}
                                            >
                                                Cancel
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </div>
                        <SkillsTxTable
                            columns={columns}
                            data={this.state.persons}
                            pagination={true}
                            searchKeyword={searchKeyword}
                            searchProperties={this.searchProperties()}
                            columnSearchKeywords={columnSearchKeywords}
                            selectableRows={modeCopyProfiles}
                            selectableRowsHighlight={true}
                            handleRowClick={this.handleRowClick}
                            progressPending={this.props.personsLoading}
                            headCellsStyle={{
                                style: {
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    border: "1px solid #dee2e6",
                                    whiteSpace: "normal",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                    paddingRight: "8px",
                                    paddingLeft: "8px",
                                },
                            }}
                            cellsStyle={{
                                style: {
                                    padding: "5px 10px",
                                    fontSize: "11px",
                                    border: "1px solid #dee2e6",
                                },
                            }}
                        ></SkillsTxTable>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        assessmentId: state.auth.assessmentId,
        error: state.auth.error,
        loginWithTeams: state.auth.loginWithTeams,

        personsLoading: state.adminPerson.loading,
        personsError: state.adminPerson.error,
        persons: state.adminPerson.persons,
        isUpdating: state.adminPerson.isUpdating,
        copyProfileRequests: state.adminPerson.copyProfileRequests,

        loadingPrint: state.adminPerson.loadingPrint,
        printData: state.adminPerson.printData,

        pageAssignments: state.adminPerson.pageAssignments,
        config: state.config.config,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onPerson: bindActionCreators(adminPersonActions, dispatch),
        onConfig: bindActionCreators(configActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageProfiles));
