import * as jobActions from "../../../store/actions/jobAction";
import changeInput from "../../../utils/changeInput";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";

class JobFamilyDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDialog: false,
            isUpdating: false,
            form: {},
            isFormValid: false,
            selectedJobFamily: this.props.selectedJobFamily,
        };
    }

    componentDidMount() {
        this.prepareFormState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
            this.prepareFormState();
        }

        if (prevProps.selectedJobFamily !== this.props.selectedJobFamily) {
            this.setState({ selectedJobFamily: this.props.selectedJobFamily });
        }
    }

    prepareFormState = () => {
        const jobFamily = this.props.selectedJobFamily;

        this.setState({
            form: {
                name: {
                    validation: {
                        required: true,
                    },
                    value: jobFamily && jobFamily.name ? jobFamily.name : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: jobFamily ? true : false,
        });
    };

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({
            showDialog: !showDialog,
        });
    };

    handleChange = (event) => {
        let { name, value } = event.target;

        let updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handlePerformSave = () => {
        const { form } = this.state;

        let jobFamily = Object.assign({}, this.props.selectedJobFamily);
        jobFamily.name = form.name.value;

        this.setState({ isUpdating: true });

        if (!this.props.selectedJobFamily) {
            this.props.onJob.createJobFamily(jobFamily).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.error) {
                        this.togleDialogModal();
                    } else {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        } else {
            this.props.onJob.updateJobFamily(jobFamily.id, jobFamily).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.error) {
                        this.togleDialogModal();
                    } else {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    render() {
        const { form } = this.state;

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={!this.state.isUpdating ? this.togleDialogModal : null}>
                    <ModalHeader toggle={!this.state.isUpdating ? this.togleDialogModal : null}>Requirement Family Detail</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup row>
                                        <Label sm={2}>Name</Label>
                                        <Col sm={10}>
                                            <Input type="text" name="name" id="name" value={form.name && form.name.value ? form.name.value : ""} invalid={form.name && form.name.touched && !form.name.valid} onChange={this.handleChange} disabled={this.state.isUpdating} />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <React.Fragment>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.handlePerformSave();
                                }}
                                disabled={!this.state.isFormValid || this.state.isUpdating}
                            >
                                Save
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.togleDialogModal();
                                }}
                                disabled={this.state.isUpdating}
                            >
                                Cancel
                            </Button>
                        </React.Fragment>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.job.error,
        isUpdating: state.job.isUpdating,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onJob: bindActionCreators(jobActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobFamilyDetail);
