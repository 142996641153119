import * as authActions from "../../../store/actions/authAction";
import * as configActions from "../../../store/actions/configAction";
import changeInput from "../../../utils/changeInput";
import { ContentWrapperLg } from "../../common/ContentWrapperLg";
import { dismisAlert, generateAlert } from "./../../../utils/alertUtils";
import { faRotate, faXmark, faCircleXmark, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Button, ButtonGroup, Col, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
const apiHostname = process.env.REACT_APP_API_ENDPOINT;

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "18px",
    fontFamily: "IBMPlexSans-Bold",
};

const rowStyle = {
    fontSize: "12px",
};

class ManageSSOSetting extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            config: null,
            isUpdating: false,
            formDirty: false,
            ssoIPCertificateKeyVaultFiles: null,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.ipCertFileRef = React.createRef();
    }

    componentDidMount() {
        this.setState({ loading: true });

        this.props.onAuth.authCheckState().then(() => {
            this.props.onConfig.getContainerAppSetting().then(() => {
                const form = this.prepareForm(this.props.containerAppSetting);
                this.setState({ containerAppSetting: this.props.containerAppSetting, form, isFormValid: true, loading: this.props.configLoading });
            });
        });
    }

    prepareForm = (containerAppSetting) => {
        if (containerAppSetting) {
            return {
                ssoSamlEnable: {
                    validation: {
                        required: true,
                    },
                    value: containerAppSetting && containerAppSetting.SSO_SAML_ENABLE ? containerAppSetting.SSO_SAML_ENABLE : "FALSE",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                ssoSamlEntityId: {
                    validation: {
                        required: containerAppSetting && containerAppSetting.SSO_SAML_ENABLE == "TRUE" ? true : false,
                    },
                    value: containerAppSetting && containerAppSetting.SSO_SAML_ENTITY_ID,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                restrictLoginSSOOnly: {
                    validation: {
                        required: true,
                    },
                    value: containerAppSetting && containerAppSetting.RESTRICT_LOGIN_SSO_ONLY ? containerAppSetting.RESTRICT_LOGIN_SSO_ONLY : "FALSE",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                byPassLoginPage: {
                    validation: {
                        required: true,
                    },
                    value: containerAppSetting && containerAppSetting.BY_PASS_LOGIN_PAGE ? containerAppSetting.BY_PASS_LOGIN_PAGE : "FALSE",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                ssoSamlIpConfigurationOption: {
                    validation: {
                        required: containerAppSetting && containerAppSetting.SSO_SAML_ENABLE == "TRUE" ? true : false,
                    },
                    value: containerAppSetting && containerAppSetting.SSO_SAML_IP_CONFIGURATION_OPTION ? containerAppSetting.SSO_SAML_IP_CONFIGURATION_OPTION : "AUTO",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                ssoSamlIpMetadataAddress: {
                    validation: {
                        required: containerAppSetting && containerAppSetting.SSO_SAML_ENABLE == "TRUE" ? (containerAppSetting && containerAppSetting.SSO_SAML_IP_CONFIGURATION_OPTION ? containerAppSetting.SSO_SAML_IP_CONFIGURATION_OPTION : "AUTO" == "AUTO" ? true : false) : false,
                    },
                    value: containerAppSetting && containerAppSetting.SSO_SAML_IP_METADATA_ADDRESS,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                ssoSamlIpEntityId: {
                    validation: {
                        required: containerAppSetting && containerAppSetting.SSO_SAML_ENABLE == "TRUE" ? (containerAppSetting && containerAppSetting.SSO_SAML_IP_CONFIGURATION_OPTION ? containerAppSetting.SSO_SAML_IP_CONFIGURATION_OPTION : "AUTO" == "MANUAL" ? true : false) : false,
                    },
                    value: containerAppSetting && containerAppSetting.SSO_SAML_IP_ENTITY_ID,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                ssoSamlIpSingleSignOnEndpoint: {
                    validation: {
                        required: containerAppSetting && containerAppSetting.SSO_SAML_ENABLE == "TRUE" ? (containerAppSetting && containerAppSetting.SSO_SAML_IP_CONFIGURATION_OPTION ? containerAppSetting.SSO_SAML_IP_CONFIGURATION_OPTION : "AUTO" == "MANUAL" ? true : false) : false,
                    },
                    value: containerAppSetting && containerAppSetting.SSO_SAML_IP_SINGLE_SIGNON_ENDPOINT,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                ssoSamlIpCertificateKeyVaultName: {
                    validation: {
                        required: containerAppSetting && containerAppSetting.SSO_SAML_ENABLE == "TRUE" ? (containerAppSetting && containerAppSetting.SSO_SAML_IP_CONFIGURATION_OPTION ? containerAppSetting.SSO_SAML_IP_CONFIGURATION_OPTION : "AUTO" == "MANUAL" ? true : false) : false,
                    },
                    value: containerAppSetting && containerAppSetting.SSO_SAML_IP_CERTIFICATE_KEY_VAULT_NAME,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                ssoSamlClaimEmailAddressType: {
                    validation: {
                        required: containerAppSetting && containerAppSetting.SSO_SAML_ENABLE == "TRUE" ? true : false,
                    },
                    value: containerAppSetting && containerAppSetting.SSO_SAML_CLAIM_EMAILADDRESS_TYPE,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            };
        } else {
            return null;
        }
    };

    handleIPFileSelected = (event) => {
        const files = Array.from(event.target.files);
        this.setState({ ssoIPCertificateKeyVaultFiles: files });
    };

    ClearIPSelectedFile = () => {
        this.ipCertFileRef.current.value = null;
        this.setState({ ssoIPCertificateKeyVaultFiles: null });
    };

    handleChange = (event) => {
        const { containerConfig, containerAppSetting } = this.props;
        const { name, value } = event.target;

        if (name == "ssoCertificateKeyVaultPassword") {
            this.setState({ ssoCertificateKeyVaultPassword: value });
        } else {
            let form = this.state.form;
            if (name == "ssoSamlEnable") {
                form.ssoSamlEntityId.validation.required = value == "TRUE";
                form.ssoSamlClaimEmailAddressType.validation.required = value == "TRUE";

                if (form.ssoSamlIpConfigurationOption.value == "AUTO") {
                    form.ssoSamlIpMetadataAddress.validation.required = value == "TRUE";

                    form.ssoSamlIpEntityId.validation.required = false;
                    form.ssoSamlIpSingleSignOnEndpoint.validation.required = false;
                    form.ssoSamlIpCertificateKeyVaultName.validation.required = false;
                } else {
                    form.ssoSamlIpMetadataAddress.validation.required = false;

                    form.ssoSamlIpEntityId.validation.required = value == "TRUE";
                    form.ssoSamlIpSingleSignOnEndpoint.validation.required = value == "TRUE";
                    form.ssoSamlIpCertificateKeyVaultName.validation.required = value == "TRUE";
                }
            }

            let updateState = changeInput(form, name, value);

            if (name == "ssoSamlEnable") {
                if (value == "TRUE") {
                    updateState = changeInput(updateState.form, "ssoSamlIpMetadataAddress", form.ssoSamlIpMetadataAddress.value ? form.ssoSamlIpMetadataAddress.value : containerConfig ? containerConfig.defaultSsoSamlIpMetadataAddress : "");
                    updateState = changeInput(updateState.form, "ssoSamlIpEntityId", form.ssoSamlIpEntityId.value ? form.ssoSamlIpEntityId.value : "");
                    updateState = changeInput(updateState.form, "ssoSamlIpSingleSignOnEndpoint", form.ssoSamlIpSingleSignOnEndpoint.value ? form.ssoSamlIpSingleSignOnEndpoint.value : "");
                    updateState = changeInput(updateState.form, "ssoSamlIpCertificateKeyVaultName", form.ssoSamlIpCertificateKeyVaultName.value ? form.ssoSamlIpCertificateKeyVaultName.value : "");

                    updateState = changeInput(updateState.form, "ssoSamlEntityId", form.ssoSamlEntityId.value ? form.ssoSamlEntityId.value : containerConfig ? `${containerConfig.defaultBaseUrl}saml` : "");
                    updateState = changeInput(updateState.form, "ssoSamlClaimEmailAddressType", form.ssoSamlClaimEmailAddressType.value ? form.ssoSamlClaimEmailAddressType.value : "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress");
                } else {
                    updateState = changeInput(updateState.form, "ssoSamlIpMetadataAddress", form.ssoSamlIpMetadataAddress.value ? form.ssoSamlIpMetadataAddress.value : "");
                    updateState = changeInput(updateState.form, "ssoSamlIpEntityId", form.ssoSamlIpEntityId.value ? form.ssoSamlIpEntityId.value : "");
                    updateState = changeInput(updateState.form, "ssoSamlIpSingleSignOnEndpoint", form.ssoSamlIpSingleSignOnEndpoint.value ? form.ssoSamlIpSingleSignOnEndpoint.value : "");
                    updateState = changeInput(updateState.form, "ssoSamlIpCertificateKeyVaultName", form.ssoSamlIpCertificateKeyVaultName.value ? form.ssoSamlIpCertificateKeyVaultName.value : "");

                    updateState = changeInput(updateState.form, "ssoSamlEntityId", form.ssoSamlEntityId.value ? form.ssoSamlEntityId.value : "");
                    updateState = changeInput(updateState.form, "ssoSamlClaimEmailAddressType", form.ssoSamlClaimEmailAddressType.value ? form.ssoSamlClaimEmailAddressType.value : "");
                    updateState = changeInput(updateState.form, "restrictLoginSSOOnly", "FALSE");
                    updateState = changeInput(updateState.form, "byPassLoginPage", "FALSE");
                }
            }

            if (name == "restrictLoginSSOOnly") {
                if (value == "FALSE") {
                    updateState = changeInput(updateState.form, "byPassLoginPage", "FALSE");
                }
            }

            this.setState({
                form: updateState.form,
                isFormValid: updateState.valid,
                formDirty: true,
            });
        }
    };

    changeConfigurationOption = (option) => {
        let form = this.state.form;
        form.ssoSamlIpConfigurationOption.value = option;

        if (form.ssoSamlIpConfigurationOption.value == "AUTO") {
            form.ssoSamlIpMetadataAddress.validation.required = true;
            form.ssoSamlIpEntityId.validation.required = false;
            form.ssoSamlIpSingleSignOnEndpoint.validation.required = false;
            form.ssoSamlIpCertificateKeyVaultName.validation.required = false;
        } else {
            form.ssoSamlIpMetadataAddress.validation.required = false;
            form.ssoSamlIpEntityId.validation.required = true;
            form.ssoSamlIpSingleSignOnEndpoint.validation.required = true;
            form.ssoSamlIpCertificateKeyVaultName.validation.required = true;
        }

        let updateState = changeInput(form, "ssoSamlIpConfigurationOption", option);

        updateState = changeInput(updateState.form, "ssoSamlIpMetadataAddress", form.ssoSamlIpMetadataAddress.value ? form.ssoSamlIpMetadataAddress.value : "");
        updateState = changeInput(updateState.form, "ssoSamlIpEntityId", form.ssoSamlIpEntityId.value ? form.ssoSamlIpEntityId.value : "");
        updateState = changeInput(updateState.form, "ssoSamlIpSingleSignOnEndpoint", form.ssoSamlIpSingleSignOnEndpoint.value ? form.ssoSamlIpSingleSignOnEndpoint.value : "");
        updateState = changeInput(updateState.form, "ssoSamlIpCertificateKeyVaultName", form.ssoSamlIpCertificateKeyVaultName.value ? form.ssoSamlIpCertificateKeyVaultName.value : "");

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleSubmitClick = () => {
        const { containerAppSetting, form, ssoIPCertificateKeyVaultFiles } = this.state;
        let appSetting = null;

        if (form.ssoSamlIpConfigurationOption.value == "AUTO") {
            appSetting = {
                ssoSamlEnable: form.ssoSamlEnable.value,
                ssoSamlEntityId: form.ssoSamlEntityId.value,
                restrictLoginSSOOnly: form.restrictLoginSSOOnly.value,
                byPassLoginPage: form.byPassLoginPage.value,
                ssoSamlIpConfigurationOption: form.ssoSamlIpConfigurationOption.value,
                ssoSamlClaimEmailAddressType: form.ssoSamlClaimEmailAddressType.value,
                ssoSamlIpMetadataAddress: form.ssoSamlIpMetadataAddress.value,
            };
        } else {
            appSetting = {
                ssoSamlEnable: form.ssoSamlEnable.value,
                ssoSamlEntityId: form.ssoSamlEntityId.value,
                restrictLoginSSOOnly: form.restrictLoginSSOOnly.value,
                byPassLoginPage: form.byPassLoginPage.value,
                ssoSamlClaimEmailAddressType: form.ssoSamlClaimEmailAddressType.value,
                ssoSamlIpConfigurationOption: form.ssoSamlIpConfigurationOption.value,

                ssoSamlIpEntityId: form.ssoSamlIpEntityId.value,
                ssoSamlIpSingleSignOnEndpoint: form.ssoSamlIpSingleSignOnEndpoint.value,
                ssoSamlIpCertificateKeyVaultName: form.ssoSamlIpCertificateKeyVaultName.value,
                ssoSamlIpCertificateFile: ssoIPCertificateKeyVaultFiles ? ssoIPCertificateKeyVaultFiles[0] : null,
            };
        }

        if (window.confirm("Update container application SSO Setting will restart the container, proceed?")) {
            this.setState({ isUpdating: true });
            this.props.onConfig.updateContainerAppSetting(appSetting).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.configError) {
                        this.generateAlert("success", "Container app SSO setting updated.");
                    } else {
                        this.generateAlert("danger", this.props.configError.errData.Message);
                    }
                }
                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    handleResetClick = () => {
        const form = this.prepareForm(this.props.containerAppSetting);
        this.setState({ containerAppSetting: this.props.containerAppSetting, form, isFormValid: true, formDirty: false });
    };

    render() {
        const { containerAppSetting } = this.props;
        const { form, ssoIPCertificateKeyVaultFiles } = this.state;

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <ContentWrapperLg style={{ width: "100%", maxWidth: "100%" }} className="contentWrapperLgAdmin-Style">
                            <p className={"page-title"} style={headerStyle}>
                                Manage SSO Settings
                            </p>
                            <Form>
                                <Row>
                                    <Col md={12} sm={12}>
                                        {this.props.containerAppSetting && (
                                            <React.Fragment>
                                                <FormGroup row>
                                                    <Label sm={4} className="small-label">
                                                        SSO SAML Enabled
                                                    </Label>
                                                    <Col sm={8}>
                                                        <Input
                                                            type="select"
                                                            name="ssoSamlEnable"
                                                            id="ssoSamlEnable"
                                                            value={form && form.ssoSamlEnable ? form.ssoSamlEnable.value : "FALSE"}
                                                            invalid={form && form.ssoSamlEnable && form.ssoSamlEnable.touched && !form.ssoSamlEnable.valid}
                                                            onChange={this.handleChange}
                                                            disabled={this.state.isUpdating}
                                                        >
                                                            <option key={1} value={"TRUE"}>
                                                                {"TRUE"}
                                                            </option>
                                                            <option key={2} value={"FALSE"}>
                                                                {"FALSE"}
                                                            </option>
                                                        </Input>
                                                    </Col>
                                                </FormGroup>
                                                {form && form.ssoSamlEnable && form.ssoSamlEnable.value == "TRUE" && (
                                                    <React.Fragment>
                                                        <FormGroup row>
                                                            <Label sm={4} className="small-label">
                                                                Do you want to restrict logins to SSO ONLY?
                                                            </Label>
                                                            <Col sm={8}>
                                                                <Input
                                                                    type="select"
                                                                    name="restrictLoginSSOOnly"
                                                                    id="restrictLoginSSOOnly"
                                                                    value={form && form.restrictLoginSSOOnly ? form.restrictLoginSSOOnly.value : "FALSE"}
                                                                    invalid={form && form.restrictLoginSSOOnly && form.restrictLoginSSOOnly.touched && !form.restrictLoginSSOOnly.valid}
                                                                    onChange={this.handleChange}
                                                                    disabled={this.state.isUpdating}
                                                                >
                                                                    <option key={1} value={"TRUE"}>
                                                                        {"YES"}
                                                                    </option>
                                                                    <option key={2} value={"FALSE"}>
                                                                        {"NO"}
                                                                    </option>
                                                                </Input>
                                                                <Label sm={12} className="small-label">
                                                                    (NOTE: this will prevent access by external parties, who may be providing support, to access your SkillsTX environment)
                                                                </Label>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label sm={4} className="small-label">
                                                                Do you want to bypass login page?
                                                            </Label>
                                                            <Col sm={8}>
                                                                <Input
                                                                    type="select"
                                                                    name="byPassLoginPage"
                                                                    id="byPassLoginPage"
                                                                    value={form && form.byPassLoginPage ? form.byPassLoginPage.value : "FALSE"}
                                                                    invalid={form && form.byPassLoginPage && form.byPassLoginPage.touched && !form.byPassLoginPage.valid}
                                                                    onChange={this.handleChange}
                                                                    disabled={this.state.isUpdating || !(form && form.restrictLoginSSOOnly && form.restrictLoginSSOOnly.value == "TRUE")}
                                                                >
                                                                    <option key={1} value={"TRUE"}>
                                                                        {"YES"}
                                                                    </option>
                                                                    <option key={2} value={"FALSE"}>
                                                                        {"NO"}
                                                                    </option>
                                                                </Input>
                                                            </Col>
                                                        </FormGroup>
                                                    </React.Fragment>
                                                )}
                                                <FormGroup row>
                                                    <Label sm={4} className="small-label">
                                                        SSO SAML EntityId
                                                    </Label>
                                                    <Col sm={8}>
                                                        <Input
                                                            type="text"
                                                            name="ssoSamlEntityId"
                                                            id="ssoSamlEntityId"
                                                            value={form && form.ssoSamlEntityId && form.ssoSamlEntityId.value ? form.ssoSamlEntityId.value : ""}
                                                            invalid={form && form.ssoSamlEntityId && form.ssoSamlEntityId.touched && !form.ssoSamlEntityId.valid}
                                                            disabled={this.state.isUpdating || (form && form.ssoSamlEnable && form.ssoSamlEnable.value == "FALSE")}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label sm={4} className="small-label">
                                                        SSO SAML Claim Email Address
                                                    </Label>
                                                    <Col sm={8}>
                                                        <Input
                                                            type="text"
                                                            name="ssoSamlClaimEmailAddressType"
                                                            id="ssoSamlClaimEmailAddressType"
                                                            value={form && form.ssoSamlClaimEmailAddressType && form.ssoSamlClaimEmailAddressType.value ? form.ssoSamlClaimEmailAddressType.value : ""}
                                                            invalid={form && form.ssoSamlClaimEmailAddressType && form.ssoSamlClaimEmailAddressType.touched && !form.ssoSamlClaimEmailAddressType.valid}
                                                            disabled={this.state.isUpdating || (form && form.ssoSamlEnable && form.ssoSamlEnable.value == "FALSE")}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col sm={12}>
                                                        <ButtonGroup>
                                                            <Button
                                                                color="primary"
                                                                outline={form && form.ssoSamlIpConfigurationOption && form.ssoSamlIpConfigurationOption.value == "AUTO" ? false : true}
                                                                onClick={() => this.changeConfigurationOption("AUTO")}
                                                                disabled={this.state.isUpdating || (form && form.ssoSamlEnable && form.ssoSamlEnable.value == "FALSE")}
                                                            >
                                                                AUTO
                                                            </Button>
                                                            <Button
                                                                color="primary"
                                                                outline={form && form.ssoSamlIpConfigurationOption && form.ssoSamlIpConfigurationOption.value == "MANUAL" ? false : true}
                                                                onClick={() => this.changeConfigurationOption("MANUAL")}
                                                                disabled={this.state.isUpdating || (form && form.ssoSamlEnable && form.ssoSamlEnable.value == "FALSE")}
                                                            >
                                                                MANUAL
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                </FormGroup>
                                                {form && form.ssoSamlIpConfigurationOption && form.ssoSamlIpConfigurationOption.value == "AUTO" && (
                                                    <React.Fragment>
                                                        <FormGroup row>
                                                            <Label sm={4} className="small-label">
                                                                SSO SAML IP Metadata Address
                                                            </Label>
                                                            <Col sm={8}>
                                                                <Input
                                                                    type="textarea"
                                                                    name="ssoSamlIpMetadataAddress"
                                                                    id="ssoSamlIpMetadataAddress"
                                                                    value={form && form.ssoSamlIpMetadataAddress && form.ssoSamlIpMetadataAddress.value ? form.ssoSamlIpMetadataAddress.value : ""}
                                                                    invalid={form && form.ssoSamlIpMetadataAddress && form.ssoSamlIpMetadataAddress.touched && !form.ssoSamlIpMetadataAddress.valid}
                                                                    disabled={this.state.isUpdating || (form && form.ssoSamlEnable && form.ssoSamlEnable.value == "FALSE")}
                                                                    onChange={this.handleChange}
                                                                    rows="5"
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </React.Fragment>
                                                )}
                                                {form && form.ssoSamlIpConfigurationOption && form.ssoSamlIpConfigurationOption.value == "MANUAL" && (
                                                    <React.Fragment>
                                                        <React.Fragment>
                                                            <FormGroup row>
                                                                <Label sm={4} className="small-label">
                                                                    SSO SAML IP Entity Id
                                                                </Label>
                                                                <Col sm={8}>
                                                                    <Input
                                                                        type="text"
                                                                        name="ssoSamlIpEntityId"
                                                                        id="ssoSamlIpEntityId"
                                                                        value={form && form.ssoSamlIpEntityId && form.ssoSamlIpEntityId.value ? form.ssoSamlIpEntityId.value : ""}
                                                                        invalid={form && form.ssoSamlIpEntityId && form.ssoSamlIpEntityId.touched && !form.ssoSamlIpEntityId.valid}
                                                                        disabled={this.state.isUpdating || (form && form.ssoSamlEnable && form.ssoSamlEnable.value == "FALSE")}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label sm={4} className="small-label">
                                                                    SSO SAML IP Single SignOn Endpoint
                                                                </Label>
                                                                <Col sm={8}>
                                                                    <Input
                                                                        type="textarea"
                                                                        name="ssoSamlIpSingleSignOnEndpoint"
                                                                        id="ssoSamlIpSingleSignOnEndpoint"
                                                                        value={form && form.ssoSamlIpSingleSignOnEndpoint && form.ssoSamlIpSingleSignOnEndpoint.value ? form.ssoSamlIpSingleSignOnEndpoint.value : ""}
                                                                        invalid={form && form.ssoSamlIpSingleSignOnEndpoint && form.ssoSamlIpSingleSignOnEndpoint.touched && !form.ssoSamlIpSingleSignOnEndpoint.valid}
                                                                        disabled={this.state.isUpdating || (form && form.ssoSamlEnable && form.ssoSamlEnable.value == "FALSE")}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label sm={4} className="small-label">
                                                                    {form && "SSO SAML IP Certificate Key Vault Name"}
                                                                </Label>
                                                                <Col sm={8}>
                                                                    <Input
                                                                        type="text"
                                                                        name="ssoSamlIpCertificateKeyVaultName"
                                                                        id="ssoSamlIpCertificateKeyVaultName"
                                                                        value={form && form.ssoSamlIpCertificateKeyVaultName && form.ssoSamlIpCertificateKeyVaultName.value ? form.ssoSamlIpCertificateKeyVaultName.value : ""}
                                                                        invalid={form && form.ssoSamlIpCertificateKeyVaultName && form.ssoSamlIpCertificateKeyVaultName.touched && !form.ssoSamlIpCertificateKeyVaultName.valid}
                                                                        disabled={this.state.isUpdating || (form && form.ssoSamlEnable && form.ssoSamlEnable.value == "FALSE")}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label sm={4} className="small-label">
                                                                    SSO SAML IP Public Key Certificate File (.cer)
                                                                </Label>
                                                                <Col
                                                                    sm={7}
                                                                    style={{
                                                                        color:
                                                                            form &&
                                                                            !ssoIPCertificateKeyVaultFiles &&
                                                                            (!this.props.containerAppSetting.SSO_SAML_IP_CERTIFICATE_KEY_VAULT_NAME ||
                                                                                (this.props.containerAppSetting.SSO_SAML_IP_CERTIFICATE_KEY_VAULT_NAME && this.props.containerAppSetting.SSO_SAML_IP_CERTIFICATE_KEY_VAULT_NAME != form.ssoSamlIpCertificateKeyVaultName.value))
                                                                                ? "#FF0000"
                                                                                : "#000000",
                                                                    }}
                                                                >
                                                                    <input ref={this.ipCertFileRef} type="file" accept=".cer" multiple={false} onChange={this.handleIPFileSelected} />
                                                                </Col>
                                                                <Col sm={1}>
                                                                    <FontAwesomeIcon icon={faCircleXmark} size="1x" style={{ color: "#0069d9", cursor: "pointer" }} onClick={this.ClearIPSelectedFile} />
                                                                </Col>
                                                            </FormGroup>
                                                        </React.Fragment>
                                                    </React.Fragment>
                                                )}
                                                <FormGroup row>
                                                    <Label sm={4} className="small-label">
                                                        SSO SAML Metadata Address
                                                    </Label>
                                                    <Col sm={8}>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="text" value={form && form.ssoSamlEnable && form.ssoSamlEnable.value == "TRUE" ? (containerAppSetting ? `${containerAppSetting.BASE_URL_LINK}saml/metadata` : "") : ""} readOnly={true} />
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row style={{ marginBottom: "5px" }}>
                                                    <Col sm={12}>
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                this.handleSubmitClick();
                                                            }}
                                                            disabled={
                                                                !this.state.formDirty ||
                                                                !this.state.isFormValid ||
                                                                this.state.isUpdating ||
                                                                (form &&
                                                                    form.ssoSamlEnable &&
                                                                    form.ssoSamlEnable.value == "TRUE" &&
                                                                    form.ssoSamlIpConfigurationOption.value == "MANUAL" &&
                                                                    (ssoIPCertificateKeyVaultFiles ||
                                                                        (containerAppSetting.SSO_SAML_IP_CERTIFICATE_KEY_VAULT_NAME && containerAppSetting.SSO_SAML_IP_CERTIFICATE_KEY_VAULT_NAME != form.ssoSamlIpCertificateKeyVaultName.value) ||
                                                                        (!containerAppSetting.SSO_SAML_IP_CERTIFICATE_KEY_VAULT_NAME && form.ssoSamlIpCertificateKeyVaultName.value)) &&
                                                                    !ssoIPCertificateKeyVaultFiles)
                                                            }
                                                        >
                                                            Update Settings
                                                        </Button>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <Button color="primary" onClick={this.handleResetClick} disabled={this.state.isUpdating || !this.state.formDirty}>
                                                            Reset
                                                        </Button>
                                                    </Col>
                                                </FormGroup>
                                            </React.Fragment>
                                        )}
                                    </Col>
                                </Row>
                            </Form>
                        </ContentWrapperLg>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,

        pageAssignments: state.adminPerson.pageAssignments,

        configLoading: state.config.loading,
        isUpdating: state.config.isUpdating,
        configError: state.config.error,
        containerConfig: state.config.containerConfig,
        containerAppSetting: state.config.containerAppSetting,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onConfig: bindActionCreators(configActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSSOSetting);
