import * as authActions from "../../store/actions/authAction";
import * as badgeRequestActions from "../../store/actions/badgeRequestAction";
import * as categoryActions from "../../store/actions/categoryAction";
import * as contentActions from "../../store/actions/contentAction";
import * as evidenceActions from "../../store/actions/evidenceAction";
import * as lorActions from "../../store/actions/lorAction";
import * as personActions from "../../store/actions/personAction";
import * as skillProfileActions from "../../store/actions/skillProfileAction";
import { dismisAlert, generateAlert } from "../../utils/alertUtils";
import { contentTranslator } from "../../utils/translatorUtils";
import EvidenceList from "./EvidenceList";
import ManageEvidence from "./ManageEvidence";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Container, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
class Evidence extends React.Component {
    static displayName = Evidence.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            contentLoading: true,
            alerts: [],
            optionsProfile: [],
            evidences: [],
            lorProfiles: [],
            skillProfiles: [],
            loadingEvidence: true,
            loadingEdit: true,
            loadingAdd: true,
            showHiddenSkills: false,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);

        this.initData = this.initData.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        if (this.props.language) {
            this.props.onContent.getContentTranslations(this.props.language.id, "SfiaAll-Menu-SfiaProfileMenu-SfiaEvidence-Lors-Skills").then(() => {
                this.setState({ contentLoading: this.props.contentLoading });

                this.props.onAuth.authCheckState().then(() => {
                    if (window.location.pathname.includes("/Manage")) {
                        const query = new URLSearchParams(this.props.location.search);
                        const email = query.get("email");

                        if (!this.props.managedPeople || !(this.props.managedPeople && this.props.managedPeople.personB.email.toLowerCase() == email.toLowerCase())) {
                            this.setState({ loading: true });

                            this.props.onPerson.getPersonMyPeopleByEmail(email).then(() => {
                                if (!this.props.personLoading && this.props.personManagedPeople) {
                                    this.props.onAuth.myPeopleSetManagedRelationship(this.props.personManagedPeople).then(() => {
                                        if (!this.props.personLoading && this.props.personManagedPeople) {
                                            this.initData();
                                        }

                                        this.setState({ loading: this.props.personLoading });
                                    });
                                } else {
                                    this.setState({ loading: this.props.personLoading });
                                }
                            });
                        } else {
                            this.initData();
                        }
                    } else {
                        this.initData();
                    }
                });
            });
        } else {
            this.props.onAuth.authCheckState().then(() => {
                if (window.location.pathname.includes("/Manage")) {
                    const query = new URLSearchParams(this.props.location.search);
                    const email = query.get("email");

                    if (!this.props.managedPeople || !(this.props.managedPeople && this.props.managedPeople.personB.email.toLowerCase() == email.toLowerCase())) {
                        this.setState({ loading: true });

                        this.props.onPerson.getPersonMyPeopleByEmail(email).then(() => {
                            if (!this.props.personLoading && this.props.personManagedPeople) {
                                this.props.onAuth.myPeopleSetManagedRelationship(this.props.personManagedPeople).then(() => {
                                    if (!this.props.personLoading && this.props.personManagedPeople) {
                                        this.initData();
                                    }

                                    this.setState({ loading: this.props.personLoading });
                                });
                            } else {
                                this.setState({ loading: this.props.personLoading });
                            }
                        });
                    } else {
                        this.initData();
                    }
                } else {
                    this.initData();
                }
            });
        }
    }

    initData() {
        this.setState({ loading: true });

        Promise.resolve(this.props.managedPeople ? this.props.onSkillProfile.getManagedPeopleProfileTypes(this.props.managedPeople.personB.email) : this.props.onSkillProfile.getMyProfileTypes()).then(() => {
            if (this.props.errorProfile) {
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });

                let profileType = null;

                if (this.props.profileTypes.length > 0) {
                    profileType = this.props.profileTypes[0];
                    this.setState({
                        optionsProfile: this.props.profileTypes.map((it) => {
                            return {
                                value: it,
                                label: this.contentTranslator(this.props.contentTranslations, "SfiaAll", it.replace(" ", ""), it),
                            };
                        }),
                        selectedprofileType: profileType,
                    });
                }

                Promise.all([
                    this.props.managedPeople ? this.props.onEvidence.getEvidencesByManagedPeopleAccountId(this.props.managedPeople.personB.email) : this.props.onEvidence.getEvidencesByAccountId(),
                    this.props.containerConfig && this.props.containerConfig.showSfiaCertificationAdmin && this.props.containerConfig.allowCertification && this.props.onBadgeRequest.getMyLatestBadgeRequest(),
                ]).then(() => {
                    if (!this.props.loadingEvidence && !this.props.errorEvidence) {
                        const evidences = this.props.evidences;

                        this.setState({
                            evidences,
                        });
                    }

                    this.setState({
                        loadingEvidence: this.props.loadingEvidence || (this.props.containerConfig && this.props.containerConfig.showSfiaCertificationAdmin && this.props.containerConfig.allowCertification && this.props.badgeRequestLoading),
                    });
                });

                Promise.all([
                    this.props.onCategory.getCategories(),
                    this.props.onLor.getLors(),
                    this.props.onEvidence.getEvidenceTypes(),
                    this.props.managedPeople ? this.props.onSkillProfile.getManagedPeopleLorProfile(this.props.managedPeople.personB.email, null, null, profileType) : this.props.onSkillProfile.getMyLorProfile(null, null, profileType),
                    this.props.managedPeople ? this.props.onSkillProfile.getManagedPeopleSkillProfile(this.props.managedPeople.personB.email, null, null, profileType) : this.props.onSkillProfile.getMySkillProfile(null, null, profileType),
                ])
                    .then(() => {
                        if (!this.props.lorLoading && !this.props.lorError && !this.props.categoryLoading && !this.props.categoryError && !this.props.loadingProfile && !this.props.errorProfile) {
                            const lorProfiles = this.props.lorProfiles;
                            const skillProfiles = this.props.skillProfiles.filter((it) => it.numericVal != 0);

                            this.populateCategories();

                            this.setState({
                                lorProfiles,
                                skillProfiles,
                            });
                        }

                        this.setState({
                            loadingAdd: this.props.lorLoading || this.props.categoryLoading || this.props.loadingProfile,
                        });
                    })
                    .catch((err) => {
                        this.setState({
                            loadingAdd: false,
                        });
                    });
            }
        });
    }

    handleChangeProfileType = (value) => {
        this.setState({ selectedprofileType: value });

        Promise.all([
            this.props.managedPeople ? this.props.onSkillProfile.getManagedPeopleLorProfile(this.props.managedPeople.personB.email, null, null, value) : this.props.onSkillProfile.getMyLorProfile(null, null, value),
            this.props.managedPeople ? this.props.onSkillProfile.getManagedPeopleSkillProfile(this.props.managedPeople.personB.email, null, null, value) : this.props.onSkillProfile.getMySkillProfile(null, null, value),
        ]).then(() => {
            const lorProfiles = this.props.lorProfiles;
            const skillProfiles = this.props.skillProfiles.filter((it) => it.numericVal != 0);
            this.setState({ lorProfiles, skillProfiles });

            this.populateCategories();
        });
    };

    populateCategories = () => {
        const skillProfiles = this.props.skillProfiles.filter((it) => it.numericVal != 0);

        let categories = this.props.categories;

        categories = categories.map((category) => {
            return {
                id: category.id,
                name: category.name,
                description: category.description,
                colour: category.colour,
                sfiaVersion: category.sfiaVersion,
                subCategories: category.subCategories.map((subCategory) => {
                    return {
                        id: subCategory.id,
                        name: subCategory.name,
                        description: subCategory.description,
                        colour: subCategory.colour,
                        skillColour: subCategory.skillColour,
                        skills: subCategory.skills.map((skill) => {
                            let skillNameShown = false;

                            return {
                                id: skill.id,
                                name: skill.name,
                                skillsCode: skill.skillsCode,
                                description: skill.description,
                                isHidden: skill.isHidden,
                                levels: skill.levels.map((level) => {
                                    const skillProfile = skillProfiles.find((x) => x.skillCode == skill.skillsCode && x.level == level.level);

                                    level = {
                                        skillNameShown: false,
                                        show: false,
                                        id: level.id,
                                        level: level.level,
                                        description: level.description,
                                        skillProfile: skillProfile,
                                    };
                                    if (!skillNameShown) {
                                        if (level.skillProfile || this.state.showAllSkill) {
                                            level.skillNameShown = true;
                                            skillNameShown = true;
                                        }
                                    }

                                    if (level.skillProfile) {
                                        level.show = true;
                                    }

                                    return level;
                                }),
                            };
                        }),
                    };
                }),
            };
        });

        this.setState({
            categories,
            loadingAdd: false,
            loadingEdit: false,
        });
    };

    handleEvidenceAdded = (addedEvidence) => {
        const evidences = this.state.evidences;
        this.setState({ evidences: [...evidences, addedEvidence] });
    };

    handleEvidenceDeleted = (deletedId) => {
        const evidences = this.state.evidences;
        const deletedEvidence = evidences.find((it) => it.id == deletedId);

        if (deletedEvidence) {
            this.setState({
                evidences: [...evidences.filter((it) => it.id != deletedId)],
            });
        }
    };

    handleEvidenceUpdated = (updatedEvidence) => {
        const evidences = this.state.evidences;
        const editedEvidence = evidences.find((it) => it.id == updatedEvidence.id);

        if (editedEvidence) {
            const updatedEvidenceIndex = evidences.indexOf(editedEvidence);
            evidences[updatedEvidenceIndex] = updatedEvidence;
        }
        this.setState({ evidences: [...evidences] });
    };

    handleChageShowHiddenSkills = (showHiddenSkills) => {
        this.setState({ showHiddenSkills: showHiddenSkills });
    };

    render() {
        return (
            <Container className="contentMain">
                {this.state.loading || this.state.contentLoading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                        <EvidenceList
                            evidences={this.state.evidences}
                            lorProfiles={this.state.lorProfiles}
                            skillProfiles={this.state.skillProfiles}
                            categories={this.state.categories}
                            loadingEvidence={this.state.loadingEvidence}
                            loadingEdit={this.state.loadingEdit}
                            handleEvidenceUpdated={this.handleEvidenceUpdated}
                            handleEvidenceDeleted={this.handleEvidenceDeleted}
                            generateAlert={this.generateAlert}
                            showHiddenSkills={this.state.showHiddenSkills}
                        ></EvidenceList>
                        {this.state.optionsProfile && this.state.optionsProfile.length > 0 && (
                            <ManageEvidence
                                evidences={this.state.evidences}
                                optionsProfile={this.state.optionsProfile}
                                selectedOptionProfile={this.state.selectedprofileType ? this.state.selectedprofileType : "Self Assessed"}
                                lorProfiles={this.state.lorProfiles}
                                skillProfiles={this.state.skillProfiles}
                                categories={this.state.categories}
                                loadingEvidence={this.state.loadingEvidence}
                                loadingAdd={this.state.loadingAdd}
                                generateAlert={this.generateAlert}
                                handleChangeProfileType={this.handleChangeProfileType}
                                handleEvidenceAdded={this.handleEvidenceAdded}
                                handleChageShowHiddenSkills={this.handleChageShowHiddenSkills}
                            ></ManageEvidence>
                        )}
                    </React.Fragment>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loadingProfile: state.skillProfile.loading,
        errorProfile: state.skillProfile.error,
        profileTypes: state.skillProfile.profileTypes,
        skillProfiles: state.skillProfile.skillProfiles,

        categories: state.category.categories,
        categoryLoading: state.category.loading,
        categoryError: state.category.error,

        loadingEvidence: state.evidence.loading,
        errorEvidence: state.evidence.error,
        evidences: state.evidence.evidences,

        lorLoading: state.lor.loading,
        lorError: state.lor.error,

        loadingLor: state.skillProfile.lorLoading,
        errorLor: state.skillProfile.lorError,
        lorProfiles: state.skillProfile.lorProfiles,

        personLoading: state.person.loading,
        personManagedPeople: state.person.managedPeople,
        managedPeople: state.auth.managedPeople,

        language: state.language.language,
        contentTranslations: state.content.contentTranslations,
        contentLoading: state.content.loading,
        contentError: state.content.error,
        containerConfig: state.config.containerConfig,
        myBadgeRequest: state.badgeRequest.myBadgeRequest,
        badgeRequestLoading: state.badgeRequest.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onSkillProfile: bindActionCreators(skillProfileActions, dispatch),
        onEvidence: bindActionCreators(evidenceActions, dispatch),
        onLor: bindActionCreators(lorActions, dispatch),
        onCategory: bindActionCreators(categoryActions, dispatch),
        onPerson: bindActionCreators(personActions, dispatch),
        onContent: bindActionCreators(contentActions, dispatch),
        onBadgeRequest: bindActionCreators(badgeRequestActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Evidence);
