import * as authActions from "../../../store/actions/authAction";
import * as jobActions from "../../../store/actions/jobAction";
import { dismisAlert, generateAlert } from "./../../../utils/alertUtils";
import ManageJobDetail from "./ManageJobDetail";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

class ManageJobCreate extends React.Component {
    constructor(props) {
        super(props);
        this.connection = null;
        this.state = {
            error: null,
            loading: true,
            alerts: [],
            job: null,
            jobs: [],
            jobIsUpdating: false,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
    }

    componentDidMount() {
        this.props.onAuth.authCheckState().then(() => {
            this.props.onJob.getJobs().then(() => {
                this.setState({
                    jobs: this.props.jobs,
                    loading: this.props.loading || this.props.jobsLoading,
                });
            });
        });
    }

    handleSaveNewJobDetail = (job) => {
        this.setState({ jobIsUpdating: true });

        this.props.onJob.createJob(job).then(() => {
            if (!this.props.jobIsUpdating) {
                if (!this.props.jobsError) {
                    this.generateAlert("success", "Create job success.");
                    this.props.history.push(`${this.props.job.id}/profile`);
                } else {
                    this.setState({ jobIsUpdating: false });
                    this.generateAlert("danger", this.props.jobsError.errData.Message);
                }
            }
        });
    };

    render() {
        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <ManageJobDetail languages={this.props.languages} jobs={this.props.jobs} createNew={true} isUpdating={this.state.jobIsUpdating} handleSaveNewJobDetail={this.handleSaveNewJobDetail}></ManageJobDetail>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,

        jobsLoading: state.job.loading,
        jobsError: state.job.error,
        job: state.job.job,
        jobs: state.job.jobs,
        jobIsUpdating: state.job.isUpdating,

        languages: state.language.languages,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageJobCreate));
