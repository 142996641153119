const LevelResponsibility = {
  name: "7 Levels Of Responsibility",
  levels: [
    {
      level: 1,
      name: "Follow",
    },
    {
      level: 2,
      name: "Assist",
    },
    {
      level: 3,
      name: "Apply",
    },
    {
      level: 4,
      name: "Enable",
    },
    {
      level: 5,
      name: "Ensure, Advise",
    },
    {
      level: 6,
      name: "Initiate, Influence",
    },
    {
      level: 7,
      name: "Set Strategy, Inspire, Mobilise",
    },
  ],
};

export { LevelResponsibility };
