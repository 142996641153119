import CategoryAPI from "../../../../common/api/service/CategoryService";
import LorAPI from "../../../../common/api/service/LorService";
import PersonAPI from "../../../../common/api/service/PersonService";
import ProfileAPI from "../../../../common/api/service/ProfileService";
import { LevelResponsibility } from "../../components/config/mockdata/levels";
import {
  OrderCategory,
  OrderGeneric,
} from "../../components/config/mockdata/order";
import SkillChangeFilters from "../../components/filters/skillchange";
import SkillChangeTable from "../../components/table/skillchange";
import { Row, Col, Empty, Typography } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import { Spinner } from "reactstrap";
import styled from "styled-components";

const DivCenter = ({ Component }) => {
  return (
    <div className="p-grid p-align-center vertical-container">
      <div className="layout-wrapper">
        <div className="p-grid">
          <div
            className="p-col-12"
            style={{ position: "fixed", top: "50%", left: "50%" }}
          >
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
};

const { Title } = Typography;

const WrapperDiv = styled.div`
  .vs-h-sm-h {
    display: none;
  }
  @media (max-width: 1080px) {
    .col-sm-cm-m {
      max-width: 100% !important;
    }

    .col-sm-cm {
      flex: 0 0 100%;
    }

    .vs-sm-h {
      display: none;
    }

    .vs-h-sm-h {
      display: contents;
    }
  }
`;

const constantData = {
  empty: "",
  versionName: "Version",
  approvedText: " (Approved)",
  selfAssessedName: "Self Assessed",
  generic: {
    id: "genericObj12345",
    name: "Generic Attributes",
    description: null,
  },
  genericSub: {
    id: "genericSubObj12345",
    name: "Generic Sub Attributes",
    description: null,
  },
  colors: {
    type1: "#3C65C2",
    type2: "#EEF2FF",
    type3: "#69849c",
    type4: "#a5b8c8",
  },
};

/**
 * Utility function to extract default values from an item. If the item doesn't have
 * a particular key, it uses the default value provided.
 */
const extractDefaults = (item, defaults) => {
  return Object.keys(defaults).reduce((obj, key) => {
    obj[key] = item[key] ?? defaults[key];
    return obj;
  }, {});
};

const SkillChange = (props) => {
  const [dataPerson, setDataPerson] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  // Selection
  const [selectPerson, setSelectPerson] = useState();
  const [selectStartVersion, setSelectStartVersion] = useState();
  const [selectEndVersion, setSelectEndVersion] = useState();

  const [isCompactView, setIsCompactView] = useState(true);

  // Helper function to fetch and enrich profile versions for a given email
  const fetchProfileVersions = async (categories, email) => {
    // Fetching profile versions based on email
    const versions = await ProfileAPI.getManagedPeopleProfileVersions(email);

    // Mapping through each version to enrich with skill profiles
    const enrichedVersions = await Promise.all(
      versions.map(async (version) => {
        // Fetching skill profiles and self assessed skills in parallel for efficiency
        const [skills, selfAssessedSkills] = await Promise.all([
          ProfileAPI.getManagedPeopleSkillPofiles(
            email,
            version.sfiaVersion,
            version.version
          ),
          ProfileAPI.getManagedPeopleLorPofiles(
            email,
            version.sfiaVersion,
            version.version,
            constantData.selfAssessedName
          ),
        ]);

        const availableSkillCodes = categories
          ? categories.flatMap((category, index) => {
                return category.subCategories.flatMap((subCategory, index) => {
                    return subCategory.skills
                        .filter((it) => !it.isHidden)
                        .flatMap((skill, index) => {
                            return skill.skillsCode;
                        });
                });
            })
          : [];

        // Combining the fetched data and returning enriched version
        return {
          ...version,
          skillProfile: [...skills.filter(it => availableSkillCodes.includes(it.skillCode)), ...selfAssessedSkills],
        };
      })
    );

    return enrichedVersions;
  };

  const processPersonData = async (categories, people) => {
    // For each person, fetch and enrich their profile versions.
    people = await Promise.all(
      people.map(async (person) => {
        // Fetch enriched profile versions for the person.
        const profileVersions = await fetchProfileVersions(
          categories,
          person.personB.email
        );

        // Add an `approveVersion` flag based on approvals and return the enriched person data.
        return {
          ...person,
          profileVersions: profileVersions.map((version) => ({
            ...version,
            // Check if any account has an approval for this version.
            approveVersion: person.personB.accounts.some((account) =>
              account.approvals.some(
                (approval) => approval.approvedVersion == version.version
              )
            ),
          })),
        };
      })
    );
    setDataPerson(people);
  };

  const processCategoryData = (category, lor) => {
    // Reordering category based on the predefined order.
    let orderedCats = OrderCategory.map((key) =>
      category.find((e) => e.name === key)
    );

    if (orderedCats.filter(it => it == undefined).length > 0){
      orderedCats = [...category]
    }

    let orderedGeneric = [];
    let lorNameOrder = [];

    lor.forEach((it) => {
      let idx = 99;
      if (OrderGeneric.find(x => x == it.name)){
         idx = OrderGeneric.indexOf(it.name);
      }

      lorNameOrder.push({
        idx,
        name:it.name
      })
    })

    lorNameOrder.sort((a, b) => (`${a.idx} ${a.name}` > `${b.idx} ${b.name}` ? 1 : -1)).forEach((lname) => {
      const l = lor.find(it => it.name == lname.name);
      orderedGeneric.push(l);
    })

    // Defining the genericObj structure.
    const genericObj = [
      {
        id: constantData.generic.id,
        name: constantData.generic.name,
        description: constantData.generic.description,
        styles: {
          color: constantData.colors.type1,
          backgroundColor: constantData.colors.type2,
        },
        hideHeader: true,
        sfiaVersion: 8,
        subCategories: [
          {
            id: constantData.genericSub.id,
            name: constantData.genericSub.name,
            description: constantData.genericSub.description,
            colour: constantData.colors.type3,
            skillColour: constantData.colors.type4,
            skills: orderedGeneric,
          },
        ],
      },
    ];
    // Combining the reordered category list with the generic object.
    setDataCategory([...genericObj, ...orderedCats]);
  };

  const processAllData = async () => {
    try {
      if (!props.manageProfileByAdminPopup){
        // Fetching both datasets in parallel.
        const [peoples, category, lor] = await Promise.all([
          PersonAPI.getPersonMyPeoples(),
          CategoryAPI.getCategories(),
          LorAPI.getLors(),
        ]);

        processCategoryData(category, lor);
        await processPersonData(category, peoples);
      } else {
        let peoples = [];

        if (props.managedPeople){
          peoples.push(props.managedPeople);          
        }

        const [category, lor] = await Promise.all([
          CategoryAPI.getCategories(),
          LorAPI.getLors(),
        ]);

        processCategoryData(category, lor);
        await processPersonData(category, peoples);
      }
    } catch (error) {
      // Log the error if fetching fails
      console.error("Failed to fetch action plan data:", error);

      // Optionally, you can set an error state here if you'd like to display an error message to users.
    }
  };

  // Use the useMemo hook to optimize the transformation of dataPerson into the desired format.
  // This ensures that the transformation only happens when dataPerson changes.
  const optionPerson = useMemo(() => {
    // Check if dataPerson is empty; if so, return an empty array.
    if (!dataPerson.length) return [];

    // Map over the dataPerson array to transform each item.
    // This combines the task of accessing item.personB and constructing the new format
    // into a single iteration over the dataPerson array.
    return dataPerson.map((item) => ({
      // Set the value as the ID of personB.
      value: item.personB.id,

      // Construct the label by combining the firstName and lastName of personB.
      label: `${item.personB.firstName} ${item.personB.lastName}`,
    }));
  }, [dataPerson]); // Only recompute optionPerson when dataPerson changes.

  // Use the useMemo hook to transform optionPerson into the desired option format.
  // This ensures the transformation only occurs if optionPerson changes.
  const optionStartVersion = useMemo(() => {
    // If dataPerson is empty, return an empty array.
    if (!dataPerson.length) return [];

    // Find the correct personB item based on the person ID and retrieve profileVersions.
    // If not found, default to an empty array.
    const profileVersions =
      dataPerson.find((item) => item.personB.id === selectPerson)
        ?.profileVersions || [];

    // Map over the profileVersions to create the desired option format.
    return profileVersions.map((item) => ({
      value: item.version,
      label: `${constantData.versionName} ${item.version} - ${new Intl.DateTimeFormat("en-GB", {
        year: "2-digit",
        month: "short",
        day: "numeric",
    }).format(new Date(item.createdOn))}${
        item.approveVersion ? constantData.approvedText : constantData.empty
      }`,
      refData: item,
    }));
  }, [optionPerson, selectPerson]); // Dependencies for useMemo: Only recompute if either of these values change.

  // Use the useMemo hook to optimize the generation of optionEndVersion based on optionStartVersion.
  // This ensures that the filtered options only get recalculated if optionStartVersion or selectStartVersion changes.
  const optionEndVersion = useMemo(() => {
    // If optionStartVersion doesn't have any items, return an empty array.
    if (!optionStartVersion.length) return [];

    // Filter the options from optionStartVersion. Only include items where the version
    // is greater than the selected start version, ensuring that the end version is always after the start version.
    return optionStartVersion.filter((item) => item.value > selectStartVersion);
  }, [optionStartVersion, selectStartVersion]); // Dependencies for useMemo: Only recompute if either of these values change.

  // Function to generate a table by profile level.
  const generateTableByProfileLevel = (profile) => {
    return dataCategory.map((item) => {
      // Extract default values for the main category item
      const itemDefaults = {
        hideHeader: false,
        colour: "",
        styles: null,
      };
      const extractedItem = extractDefaults(item, itemDefaults);

      return {
        name: item.name,
        meta: {
          hideHeader: extractedItem.hideHeader,
          levelResponsibility: LevelResponsibility,
        },
        description: item.description,
        styles: {
          tableHeaderColor: extractedItem?.styles?.color || "white",
          tableHeaderBackgroundColor:
            extractedItem?.styles?.backgroundColor || extractedItem.colour,
        },
        subCategories: item.subCategories.map((subCategoryKey) => {
          // Extract default values for sub-category items
          const subCategoryDefaults = {
            colour: "",
            skillColour: "",
          };
          const extractedSubCategory = extractDefaults(
            subCategoryKey,
            subCategoryDefaults
          );

          return {
            name: subCategoryKey.name,
            description: subCategoryKey.description,
            colour: extractedSubCategory.colour,
            skillColour: extractedSubCategory.skillColour,
            skills: subCategoryKey.skills
              .filter(it => !it.isHidden)
              .map((skillKey) => {
              // Extract default values for skills within sub-category
              const skillDefaults = {
                skillsCode: skillKey.lorCode,
              };
              const extractedSkill = extractDefaults(skillKey, skillDefaults);

              return {
                name: skillKey.name,
                description: skillKey.description,
                code: extractedSkill.skillsCode,
                levels: skillKey.levels.map((levelKey) => {
                  // Extract default values for levels within skills
                  const levelDefaults = {
                    level: levelKey.levelNumber,
                  };
                  const extractedLevel = extractDefaults(
                    levelKey,
                    levelDefaults
                  );

                  // Find a matching skill profile
                  const findSkillProfile = profile.skillProfile.filter(it => it.profileType == "Self Assessed").find(
                    (_) =>
                      // filter category
                      (_.category ?? constantData.generic.name) === item.name &&
                      // filter sub category
                      (_.subCategory ?? constantData.genericSub.name) ===
                        subCategoryKey.name &&
                      // filter code
                      (_.skillCode ?? _.lorCode) ===
                        extractedSkill.skillsCode &&
                      // filter level
                      _.level === extractedLevel.level
                  );

                  return {
                    level: extractedLevel.level,
                    description: levelKey.description,
                    skillProfile: findSkillProfile,
                  };
                }),
              };
            }),
          };
        }).filter(it => it.skills.length > 0),
      };
    }).filter(it => it.subCategories.length > 0);
  };

  // Compares skills between two categories and returns the differences.
  const compareSkills = (itemSub, secondSkillsList) => {
    const listDiffSkills = [];

    itemSub.skills.forEach((skill, index) => {
      const firstSkills = skill.levels.filter((_) => _.skillProfile);
      const secondSkills = secondSkillsList[index].levels.filter(
        (_) => _.skillProfile
      );

      let isDiff = false;

      // Check if the number of skills differs
      if (firstSkills.length !== secondSkills.length) {
        isDiff = true;
      } else {
        // Check if there's any skill level mismatch between the tables
        isDiff = firstSkills.some((firstSkill) => {
          const findMatchLevel = secondSkills.find(
            (secondSkill) => secondSkill.level === firstSkill.level
          );

          return (
            !findMatchLevel ||
            findMatchLevel.skillProfile.stringVal !==
              firstSkill.skillProfile.stringVal
          );
        });
      }

      // If a difference was found, add the skill to the list of differences
      if (isDiff) {
        listDiffSkills.push({
          name: skill.name,
          code: skill.code,
        });
      }
    });

    return listDiffSkills;
  };

  // Compares two tables and filters out their data based on the differences.
  const compareTables = (firstTable, secondTable) => {
    // Copy tables to avoid mutations
    let tempFirst = [...firstTable];
    let tempSecond = [...secondTable];
    const listDiffCategory = [];

    tempFirst.forEach((item, index) => {
      const listDiffSubCategories = [];

      item.subCategories.forEach((itemSub, indexSub) => {
        const listDiffSkills = compareSkills(
          itemSub,
          secondTable[index].subCategories[indexSub].skills
        );

        // If differences in skills were found, filter the skills for that sub-category
        if (listDiffSkills.length > 0) {
          itemSub.skills = itemSub.skills.filter((skill) =>
            listDiffSkills.some((diff) => diff.name === skill.name)
          );
          tempSecond[index].subCategories[indexSub].skills = tempSecond[
            index
          ].subCategories[indexSub].skills.filter((skill) =>
            listDiffSkills.some((diff) => diff.name === skill.name)
          );
          listDiffSubCategories.push({ name: itemSub.name });
        } else {
          // Reset skills if no differences were found
          itemSub.skills = [];
          tempSecond[index].subCategories[indexSub].skills = [];
        }
      });

      // If differences in subcategories were found, filter the sub-categories for that category
      if (listDiffSubCategories.length > 0) {
        item.subCategories = item.subCategories.filter((subCat) =>
          listDiffSubCategories.some((diff) => diff.name === subCat.name)
        );
        tempSecond[index].subCategories = tempSecond[
          index
        ].subCategories.filter((subCat) =>
          listDiffSubCategories.some((diff) => diff.name === subCat.name)
        );
        listDiffCategory.push({ name: item.name });
      } else {
        // Reset subcategories if no differences were found
        item.subCategories = [];
        secondTable[index].subCategories = [];
      }
    });

    // If differences in categories were found, filter the main category list
    if (listDiffCategory.length > 0) {
      tempFirst = tempFirst.filter((cat) =>
        listDiffCategory.some((diff) => diff.name === cat.name)
      );
      tempSecond = tempSecond.filter((cat) =>
        listDiffCategory.some((diff) => diff.name === cat.name)
      );
    } else {
      // Reset tables if no differences were found
      tempFirst = [];
      tempSecond = [];
    }

    return {
      first: tempFirst,
      second: tempSecond,
    };
  };

  const compareTableSkills = (firstTable, secondTable) => {
    let updatedSkills = [];
    
    if (firstTable && firstTable.length > 0){
      firstTable.filter(it => it.stringVal != "N" && it.profileType == "Self Assessed" && !it.isExtraFramework).forEach((firstSkill) => {
        if (firstSkill.skillCode){
          const category = dataCategory.find(it => it.name == firstSkill.category);
          const categoryIndex = category ? dataCategory.findIndex(it => it.name == firstSkill.category) : 0;
          const subCategory = category ? category.subCategories.find(it => it.name == firstSkill.subCategory) : null;
          const subCategoryIndex = subCategory ? category.subCategories.findIndex(it => it.name == firstSkill.subCategory) : 0;
          const skill = subCategory ? subCategory.skills.find(it => it.name == firstSkill.skill) : null;
          const skillIndex = skill ? subCategory.skills.findIndex(it => it.name == firstSkill.skill) : 0;

          const checkSecond = secondTable ? secondTable.find(it => it.stringVal != "N" && it.profileType == "Self Assessed" && it.category == firstSkill.category && it.subCategory == firstSkill.subCategory && it.skillCode == firstSkill.skillCode && it.level == firstSkill.level) : null;
          if (checkSecond) {
            if (firstSkill.stringVal != checkSecond.stringVal){
              updatedSkills.push({
                category : firstSkill.category,
                categoryIndex,
                subCategory : firstSkill.subCategory,
                subCategoryIndex,
                skill : firstSkill.skill,
                skillIndex,
                skillCode : firstSkill.skillCode,
                level : firstSkill.level,
                stringVal : checkSecond.stringVal,
                originalSkillType :  firstSkill.stringVal == "M" ? "Competent" : (firstSkill.stringVal == "P" ? "Proficient" : firstSkill.stringVal == "NA" ? "Knowledge" : ""),
                skillType :  checkSecond.stringVal == "M" ? "Competent" : (checkSecond.stringVal == "P" ? "Proficient" : checkSecond.stringVal == "NA" ? "Knowledge" : ""),
                action : "Updated"
              })
            }
          } else {
            updatedSkills.push({
              category : firstSkill.category,
              categoryIndex,
              subCategory : firstSkill.subCategory,
              subCategoryIndex,
              skill : firstSkill.skill,
              skillIndex,
              skillCode : firstSkill.skillCode,
              level : firstSkill.level,
              stringVal : firstSkill.stringVal,
              skillType :  firstSkill.stringVal == "M" ? "Competent" : (firstSkill.stringVal == "P" ? "Proficient" : firstSkill.stringVal == "NA" ? "Knowledge" : ""),
              action : "Removed"
            })
          }
        } else if (firstSkill.lorCode){
          const category = dataCategory.find(it => it.subCategories.some(it => it.skills.some(it => it.name == firstSkill.lor && it.lorCode == firstSkill.lorCode)));
          const categoryIndex = category ? dataCategory.findIndex(it => it.subCategories.some(it => it.skills.some(it => it.name == firstSkill.lor && it.lorCode == firstSkill.lorCode))) : 0;
          const subCategory = category ? category.subCategories.find(it => it.skills.some(it => it.name == firstSkill.lor && it.lorCode == firstSkill.lorCode)) : null;
          const subCategoryIndex = subCategory ? category.subCategories.findIndex(it => it.skills.some(it => it.name == firstSkill.lor && it.lorCode == firstSkill.lorCode)) : 0;
          const skill = subCategory ? subCategory.skills.find(it => it.name == firstSkill.lor && it.lorCode == firstSkill.lorCode) : null;
          const skillIndex = skill ? subCategory.skills.findIndex(it => it.name == firstSkill.lor && it.lorCode == firstSkill.lorCode) : 0;
          
          const checkSecond = secondTable ? secondTable.find(it => it.stringVal != "N" && it.profileType == "Self Assessed" && !it.isExtraFramework && it.lor == firstSkill.lor && it.lorCode == firstSkill.lorCode && it.level == firstSkill.level) : null;
          if (!checkSecond) {
            updatedSkills.push({
              category : category.name,
              categoryIndex,
              subCategory : subCategory.name,
              subCategoryIndex,
              skill : firstSkill.lor,
              skillIndex,
              skillCode : firstSkill.lorCode,
              level : firstSkill.level,
              action : "Removed"
            })
          }
        }
      });
    }

    if (secondTable && secondTable.length > 0){
      secondTable.filter(it => it.stringVal != "N" && it.profileType == "Self Assessed" && !it.isExtraFramework).forEach((secondSkill) => {
        if (secondSkill.skillCode){
          const category = dataCategory.find(it => it.name == secondSkill.category);
          const categoryIndex = category ? dataCategory.findIndex(it => it.name == secondSkill.category) : 0;
          const subCategory = category ? category.subCategories.find(it => it.name == secondSkill.subCategory) : null;
          const subCategoryIndex = subCategory ? category.subCategories.findIndex(it => it.name == secondSkill.subCategory) : 0;
          const skill = subCategory ? subCategory.skills.find(it => it.name == secondSkill.skill) : null;
          const skillIndex = skill ? subCategory.skills.findIndex(it => it.name == secondSkill.skill) : 0;

          const checkFirst = firstTable ? firstTable.find(it => it.stringVal != "N" && it.profileType == "Self Assessed" && !it.isExtraFramework && it.category == secondSkill.category && it.subCategory == secondSkill.subCategory && it.skillCode == secondSkill.skillCode && it.level == secondSkill.level) : null;
          if (!checkFirst){
            updatedSkills.push({
              category : secondSkill.category,
              categoryIndex,
              subCategory : secondSkill.subCategory,
              subCategoryIndex,
              skill : secondSkill.skill,
              skillIndex,
              skillCode : secondSkill.skillCode,
              level : secondSkill.level,
              stringVal : secondSkill.stringVal,
              skillType :  secondSkill.stringVal == "M" ? "Competent" : (secondSkill.stringVal == "P" ? "Proficient" : secondSkill.stringVal == "NA" ? "Knowledge" : ""),
              action : "Added"
            })
          }
        }else if (secondSkill.lorCode){
          const category = dataCategory.find(it => it.subCategories.some(it => it.skills.some(it => it.name == secondSkill.lor && it.lorCode == secondSkill.lorCode)));
          const categoryIndex = category ? dataCategory.findIndex(it => it.subCategories.some(it => it.skills.some(it => it.name == secondSkill.lor && it.lorCode == secondSkill.lorCode))) : 0;
          const subCategory = category ? category.subCategories.find(it => it.skills.some(it => it.name == secondSkill.lor && it.lorCode == secondSkill.lorCode)) : null;
          const subCategoryIndex = subCategory ? category.subCategories.findIndex(it => it.skills.some(it => it.name == secondSkill.lor && it.lorCode == secondSkill.lorCode)) : 0;
          const skill = subCategory ? subCategory.skills.find(it => it.name == secondSkill.lor && it.lorCode == secondSkill.lorCode) : null;
          const skillIndex = skill ? subCategory.skills.findIndex(it => it.name == secondSkill.lor && it.lorCode == secondSkill.lorCode) : 0;

          const checkFirst = firstTable ? firstTable.find(it => it.stringVal != "N" && it.profileType == "Self Assessed" && it.lor == secondSkill.lor && it.lorCode == secondSkill.lorCode && it.level == secondSkill.level) : null;
          if (!checkFirst){
            updatedSkills.push({
              category : category.name,
              categoryIndex,
              subCategory : subCategory.name,
              subCategoryIndex,
              skill : secondSkill.lor,
              skillIndex,
              skillCode : secondSkill.lorCode,
              level : secondSkill.level,
              stringVal : secondSkill.stringVal,
              action : "Added"
            })
          }
        }
      });
    }

    return updatedSkills;
  }

  /**
   * Memoized computation of dashboard tables based on selected start and end versions.
   * It also takes into account the `isCompactView` flag to determine if tables should be compared or not.
   */
  const dashboardTable = useMemo(() => {
    let firstTable = [];
    let secondTable = [];

    let firstSkillProfiles = [];
    let secondSkillProfiles = [];
    let updatedSkillProfiles = [];

    // If a start version is selected, generate the first table
    if (selectStartVersion) {
      const foundStartVersion = optionStartVersion.find(
        (item) => item.value === selectStartVersion
      );
      firstTable = foundStartVersion
        ? generateTableByProfileLevel(foundStartVersion.refData)
        : [];

      firstSkillProfiles = foundStartVersion ? foundStartVersion.refData.skillProfile : [];
    }

    // If an end version is selected, generate the second table
    if (selectEndVersion) {
      const foundEndVersion = optionEndVersion.find(
        (item) => item.value === selectEndVersion
      );
      secondTable = foundEndVersion
        ? generateTableByProfileLevel(foundEndVersion.refData)
        : [];

      secondSkillProfiles = foundEndVersion ? foundEndVersion.refData.skillProfile : [];

      // If compact view is enabled and both tables exist, compare and filter them
      if (isCompactView && firstTable.length && secondTable.length) {
        const changedSkills = compareTableSkills(firstSkillProfiles, secondSkillProfiles);
        updatedSkillProfiles = changedSkills;
        const { first, second } = compareTables(firstTable, secondTable);
        firstTable = first;
        secondTable = second;
      }
    }

    console.log("firstTable", firstTable);

    return {
      firstTable,
      secondTable,
      updatedSkillProfiles
    };
  }, [selectStartVersion, selectEndVersion, isCompactView]);

  useEffect(() => {
    // Reset selectPerson if dataPerson is empty
    if (dataPerson.length > 0 && props.manageProfileByAdminPopup && !selectPerson){
      setSelectPerson(dataPerson[0].personB.id);
    }

    if (!dataPerson.length) {
      setSelectPerson(undefined);
    } else if (selectPerson) {
      // When selectPerson exists, find the minimum from optionStartVersion
      const find = optionStartVersion.reduce((p, v) => (p < v ? p : v));

      if (find) {
        setSelectStartVersion(find.value);
      }
    } else {
      // Reset selectStartVersion if selectPerson doesn't exist
      setSelectStartVersion(undefined);
    }
  }, [dataPerson, selectPerson, optionStartVersion]);

  // Reset selectEndVersion when selectStartVersion changes
  useEffect(() => {
    setSelectEndVersion(undefined);
  }, [selectStartVersion]);

  // This effect runs when the component mounts.
  useEffect(() => {
    // This variable helps us ensure that we don't call `setIsContentLoaded` if the component has unmounted before our asynchronous operation completes.
    let isMounted = true;

    // Fetch and process all data
    processAllData().then(() => {
      if (isMounted) {
        // Only set the loading state to false if the component is still mounted
        setIsContentLoaded(true);
      }
    });

    // The cleanup function for the effect
    return () => {
      // If the component unmounts, we set isMounted to false to prevent any state updates after unmounting.
      isMounted = false;
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts and never again.

  // If content is still loading, display a spinner.
  if (!isContentLoaded) {
    return <DivCenter Component={() => <Spinner />} />;
  }

  // If content is no data, display a empty.
  if (dataPerson.length < 1) {
    return (
      <DivCenter Component={() => <Empty description={"No Person Data"} />} />
    );
  }

  return (
    <>
      {/* Render SkillChangeFilters with necessary props */}
      <SkillChangeFilters
        objPerson={{
          options: optionPerson,
          value: selectPerson,
          setValue: setSelectPerson,
        }}
        objStartVersion={{
          options: optionStartVersion,
          value: selectStartVersion,
          setValue: setSelectStartVersion,
        }}
        objEndVersion={{
          options: optionEndVersion,
          value: selectEndVersion,
          setValue: setSelectEndVersion,
        }}
        objCompact={{
          value: isCompactView,
          setValue: setIsCompactView,
        }}
      />

      {/* Check if there are any items in firstTable */}
      {dashboardTable.firstTable.length > 0 ? (
        <WrapperDiv>
          {dashboardTable.secondTable.length > 0 ? (
            <React.Fragment>
            {
              dashboardTable.secondTable.map((item, index) => (
                <Row                
                  key={index}
                  gutter={22}
                  style={{ fontSize: "11px", padding: "10px" }}
                  className="justify-content-center"
                >
                  {/* Render the first table */}
                  <Col className="col-sm-cm col-sm-cm-m" span={12}>
                    {index === 0 && (
                      <Title className="vs-sm-h" level={5}>
                        Version {selectStartVersion}
                      </Title>
                    )}
                    {/* Render each SkillChangeTable. Use unique identifier if present, or fall back to the index. */}
                    <SkillChangeTable
                      version={selectStartVersion}
                      data={dashboardTable.firstTable[index]}
                    />
                  </Col>
                  {/* Render the second table */}
                  <Col className="col-sm-cm col-sm-cm-m" span={12}>
                    {index === 0 && (
                      <Title className="vs-sm-h" level={5}>
                        Version {selectEndVersion}
                      </Title>
                    )}
                    {/* Render each SkillChangeTable. Use unique identifier if present, or fall back to the index. */}
                    <SkillChangeTable
                      version={selectEndVersion}
                      data={dashboardTable.secondTable[index]}
                    />
                  </Col>
                </Row>
              ))
            }
            {/* Render skill changed summary */}
            {
              dashboardTable.updatedSkillProfiles.length > 0 && 
              <Row                
                  className="layout-container justify-content-center m-1"
                  style={{ fontSize: "14px", padding: "10px", display:"block" }}
                >
                  <Col offset={6} span={12}>
                    {
                      dashboardTable.updatedSkillProfiles
                      .sort((a, b ) => 
                      {
                        if (a.categoryIndex < b.categoryIndex) return -1;
                        if (a.categoryIndex > b.categoryIndex) return 1;
                        if (a.subCategoryIndex < b.subCategoryIndex) return -1;
                        if (a.subCategoryIndex > b.subCategoryIndex) return 1;
                        if (a.skillIndex < b.skillIndex) return -1;
                        if (a.skillIndex > b.skillIndex) return 1;
                        if (a.level < b.level) return -1;
                        if (a.level > b.level) return 1;
                        return 0;
                      }
                      )
                      .map((item, index) => (
                        <div key={index} style={{clear:"both"}}>
                          {`${item.skillCode}${item.level} - ${item.skill} : ${item.skillType ? (item.skillType + " ") : ""}${item.action}`}
                        </div>
                      ))
                    }
                  </Col>
                </Row>
            }
            </React.Fragment>            
          ) : (
            <Row gutter={22} style={{ fontSize: "11px", padding: "10px" }}>
              {/* Render the table */}
              <Col className="col-sm-cm " offset={4} span={16}>
                {dashboardTable.firstTable.map((item, index) => (
                  // Render each SkillChangeTable. Use unique identifier if present, or fall back to the index.
                  <SkillChangeTable data={item} key={item.id || index} />
                ))}
              </Col>
            </Row>
          )}
        </WrapperDiv>
      ) : (
        selectStartVersion && (
          <DivCenter
            Component={() => (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={"No Data Change"}
              />
            )}
          />
        )
      )}
    </>
  );
};
export { SkillChange };
