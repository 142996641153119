import { Table, Typography, Empty, Tag, Row } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";

const constantData = {
  titleLevel: 5,
  pageSize: 10,
  colors: {
    type1: "#69849c",
    type2: "#a5b8c8",
  },
  rangeColors: [
    {
      color: "green",
      label: "80 - 100",
      isMatch: (val) => val >= 80,
    },
    {
      color: "cyan",
      label: "60 - 79",
      isMatch: (val) => val >= 60,
    },
    {
      color: "gold",
      label: "40 - 59",
      isMatch: (val) => val >= 40,
    },
    {
      color: "orange",
      label: "20 - 39",
      isMatch: (val) => val >= 20,
    },
    {
      color: "volcano",
      label: "1 - 19",
      isMatch: (val) => val >= 0,
    },
    {
      color: "red",
      label: "0",
      isMatch: (val) => val === 0,
    },
  ],
};

const DivCenter = ({ Component, title }) => {
  return (
    <div
      className="p-grid p-align-center vertical-container"
      style={{ marginBottom: "40px" }}
    >
      <div className="layout-wrapper">
        <Title level={constantData.titleLevel}>{title}</Title>
        <Component />
      </div>
    </div>
  );
};

const { Title } = Typography;

const WrapperTable = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;

  .ant-table-container table > thead > tr:first-child th {
    text-align: center;
  }

  .ant-table-container table tr .ant-table-cell-row-hover {
    background: rgb(116, 226, 177, 0.2) !important;
  }

  .mr-10 {
    margin-right: 10px;
  }
  .grid {
    display: grid;
  }
  .anticon {
    vertical-align: 1px !important;
  }

  @media (max-width: 1680px) {
    .cell-cm {
      width: 150px;
    }
  }

  @media (max-width: 1580px) {
    .cell-cm {
      width: 100px;
    }
  }

  @media (max-width: 1480px) {
    .cell-cm {
      width: 60px;
    }
  }
  @media (max-width: 1280px) {
    .cell-cm {
      width: auto;
    }
  }
`;

const WrapperSubCell = styled.div`
  width: auto;
  text-wrap: wrap;
`;

const ContainerTable = styled.div`
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;

  .circle {
    border-radius: 10px;
    width: 10px;
    height: 10px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .pd-10 {
    padding: 10px;
  }

  .flex {
    display: flex;
  }

  .font-sm {
    font-size: small;
  }

  .item-center {
    align-items: center;
  }
`;

const createCenteredTextColumnHeader = (title) => (
  <span style={{ textWrap: "wrap" }}>{title}</span>
);

// Helper function to generate a table column that centers its text content.
// This avoids repetition for multiple columns that require similar formatting.
const createCenteredTextColumn = (
  title,
  dataIndex,
  sorterFunction,
  isResponsive = false,
  isCenter = true
) => ({
  title: createCenteredTextColumnHeader(title), // Column header
  dataIndex, // Data key
  key: dataIndex, // Key for React's list rendering
  sorter: (a, b) => sorterFunction(a[dataIndex], b[dataIndex]), // Sorting function for the column
  render: (text) => (
    <WrapperSubCell
      className={`${isCenter && "text-center"} ${isResponsive && "cell-cm"}`}
    >
      {text ?? "-"}
    </WrapperSubCell>
  ), // Centering the text
});

const createCenteredNumberColumn = (
  title,
  dataIndex,
  sorterFunction,
  isResponsive = false,
  isCenter = true
) => {
  const getColor = (val) =>
    constantData.rangeColors.find((item) => item.isMatch(val))?.color;

  return {
    title: createCenteredTextColumnHeader(title), // Column header
    dataIndex, // Data key
    key: dataIndex, // Key for React's list rendering
    sorter: (a, b) => sorterFunction(a[dataIndex], b[dataIndex]), // Sorting function for the column
    render: (text) => (
      <WrapperSubCell
        className={`${isCenter && "text-center"} ${isResponsive && "cell-cm"}`}
      >
        <Tag color={getColor(text)}>{text}%</Tag>
      </WrapperSubCell>
    ), // Centering the text
  };
};

// Utility function to compare text values
const sortByText = (a, b) => (a ?? "").localeCompare(b ?? "");
// Utility function to compare number values
const sortByNumber = (a, b) => (a < b ? -1 : a > b ? 1 : 0);

// Utility function to transform data source for tables
// This maps over the data and transforms it to have keys and all the item properties.
const transformDataSource = (data) =>
  data.map((item, index) => ({
    key: `${index + 1}`,
    ...item,
  }));

// Utility function to generate column definitions based on type and need status
// This will return the appropriate columns based on whether it's a professional or generic skill and if it's a needed skill.
const getColumnDefinitions = (isResponsive) => {
  const tempColum = [
    createCenteredTextColumn(
      "Full Name",
      "fullName",
      sortByText,
      isResponsive,
      false
    ),
    createCenteredTextColumn("Email", "email", sortByText, isResponsive, false),
    createCenteredTextColumn(
      "Requirement Name",
      "jobTitle",
      sortByText,
      isResponsive,
      false
    ),
    createCenteredTextColumn(
      "Current Position as Entered",
      "position",
      sortByText,
      isResponsive,
      false
    ),
    createCenteredNumberColumn(
      "Match % (SA)",
      "matchPercentSelf",
      sortByNumber
    ),
    createCenteredNumberColumn(
      "Match % (Endorsed)",
      "matchPercentValidated",
      sortByNumber
    ),
  ];
  return tempColum;
};

const JobMatchTable = ({
  data = [],
  pageSize = constantData.pageSize,
  isResponsive = false,
}) => {
  // Memoized calculation for professional table data
  const dataSource = useMemo(() => {
    // If not showing professional or no data, return empty data.
    if (data?.length === 0) return [];

    // Return the transformed data source and the appropriate columns
    return transformDataSource(data);
  }, [data]);

  // Memoized calculation for professional table data
  const columns = useMemo(() => {
    // If not showing professional or no data, return empty data.
    if (data?.length === 0) return [];

    // Return the transformed data source and the appropriate columns
    return getColumnDefinitions(isResponsive);
  }, [data]);

  return (
    <ContainerTable>
      {dataSource.length > 0 ? (
        // Render the professional table
        <>
          <Title level={constantData.titleLevel}>Match Range For Person</Title>
          <WrapperTable>
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={{ pageSize }}
              scroll={{ x: true }}
            />
            <Row>
              {constantData.rangeColors.map((item, index) => {
                return (
                  <div
                    className="flex text-center item-center pd-10"
                    key={index}
                  >
                    <span className="font-sm ">Range:</span>
                    <Tag className="ml-10" color={item.color}>
                      {item.label}
                    </Tag>
                  </div>
                );
              })}
            </Row>
          </WrapperTable>
        </>
      ) : (
        <DivCenter
          title="Match Range For Person"
          Component={() => <Empty description={"No Data"} />}
        />
      )}
    </ContainerTable>
  );
};

export default JobMatchTable;
