import JobAPI from "../../../../common/api/service/JobService";
import PersonAPI from "../../../../common/api/service/PersonService";
import JobMatchFilters from "../../components/filters/jobmatch";
import MatchRangeFilters from "../../components/filters/jobmatch/matchrange";
import JobMatchTable from "../../components/table/jobmatch";
import { groupBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Spinner } from "reactstrap";
import styled from "styled-components";
import { useImmer } from "use-immer";

const DivCenter = ({ Component }) => {
  return (
    <div className="p-grid p-align-center vertical-container">
      <div className="layout-wrapper">
        <div className="p-grid">
          <div
            className="p-col-12"
            style={{ position: "fixed", top: "50%", left: "50%" }}
          >
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
};

const Container = styled.div`
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ContainerBorder = styled.div`
  padding: 10px;
  margin-top: 30px;
  border-width: 1px;
  border-style: solid;
  border-color: #dddddd;
  border-radius: 14px;
`;

// Constant Data
const constantData = {
  fullName: "fullName",
  jobTitle: "jobTitle",
  pageSize: 10,
};

const JobMatch = () => {
  // Data
  const [data, setData] = useState([]);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  // Selection
  const [selectedName, setSelectedName] = useState([]);
  const [selectedRequirement, setSelectedRequirement] = useState([]);
  const [selectedMatchRangeSelfAssessed, setSelectedMatchRangeSelfAssessed] =
    useImmer({
      min: 0,
      max: 100,
    });
  const [selectedMatchRangeEndorsed, setSelectedMatchRangeEndorsed] = useImmer({
    min: 0,
    max: 100,
  });

  const processAllData = async () => {
    try {
      // 1. Fetch all person details
      const dataMyPeoples = await PersonAPI.getPersonMyPeoples();

      // 2. For each person, fetch their associated job matches
      const dataJobMatches = await Promise.all(
        dataMyPeoples.map((person) =>
          JobAPI.getManagedPeopleJobMatches(person.personB.email).then(
            (jobMatches) => ({
              jobMatches,
              personB: person.personB, // Store only what's needed
            })
          )
        )
      );

      // 3. Combine person details with their respective job matches
      const dataMixJob = dataJobMatches.flatMap(({ jobMatches, personB }) =>
        jobMatches.map((jobItem) => ({
          ...jobItem,
          fullName: `${personB.firstName} ${personB.lastName}`,
          email: personB.email,
          position: personB.position,
        }))
      );

      // Update state or context with the merged data
      setData(dataMixJob);
    } catch (error) {
      // Log the error if fetching fails
      console.error("Failed to fetch action plan data:", error);
      // Optionally, you can set an error state to display an error message to users.
    }
  };

  // Filter by Person Name
  const optionsName = useMemo(() => {
    if (data?.length === 0) return [];

    const dataGroup = groupBy(data, constantData.fullName);

    return Object.keys(dataGroup)
      .map((item) => ({
        label: item,
        value: item,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [data]);

  // Filter by Requirement
  const optionsRequirement = useMemo(() => {
    if (data?.length === 0) return [];

    const dataGroup = groupBy(data, constantData.jobTitle);

    return Object.keys(dataGroup)
      .map((item) => ({
        label: item,
        value: item,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [data]);

  // Data Filter => Ref By Data
  const dataFilter = useMemo(() => {
    let resultData = [];

    if (data.length) {
      resultData = data.filter((item) => {
        // If selectedName is populated and the condition is not met, filter out
        if (selectedName.length > 0 && !selectedName.includes(item.fullName)) {
          return false;
        }

        // If selectedRequirement is populated and the condition is not met, filter out
        if (
          selectedRequirement.length > 0 &&
          !selectedRequirement.includes(item.jobTitle)
        ) {
          return false;
        }

        // If selectedMatchRangeSelfAssessed is populated and the condition is not met, filter out
        if (
          !(
            item.matchPercentSelf >= selectedMatchRangeSelfAssessed.min &&
            item.matchPercentSelf <= selectedMatchRangeSelfAssessed.max
          )
        ) {
          return false;
        }

        // If selectedMatchRangeEndorsed is populated and the condition is not met, filter out
        if (
          !(
            item.matchPercentValidated >= selectedMatchRangeEndorsed.min &&
            item.matchPercentValidated <= selectedMatchRangeEndorsed.max
          )
        ) {
          return false;
        }

        // If all conditions are met or not applicable, include the item
        return true;
      });
    }
    return resultData;
  }, [
    data,
    selectedName,
    selectedRequirement,
    selectedMatchRangeSelfAssessed,
    selectedMatchRangeEndorsed,
  ]);

  const dashboardTable = useMemo(() => dataFilter ?? [], [dataFilter]); // Recompute only if dataFilter changes

  const dashboardSelfAssessedCount = useMemo(() => {
    // Checking for emptiness directly
    if (!dataFilter.length) return 0;

    // Count unique fullNames without grouping the entire dataset
    const uniqueNames = new Set(
      dataFilter.map((item) => item[constantData.fullName])
    );
    return uniqueNames.size;
  }, [dataFilter]); // Recompute only if dataFilter changes

  const dashboardRequirementMatchCount = useMemo(() => {
    // Checking for emptiness directly
    if (!dataFilter.length) return 0;

    // Count unique fullNames without grouping the entire dataset
    const uniqueNames = new Set(
      dataFilter.map((item) => item[constantData.jobTitle])
    );
    return uniqueNames.size;
  }, [dataFilter]); // Recompute only if dataFilter changes

  // This effect runs when the component mounts.
  useEffect(() => {
    // This variable helps us ensure that we don't call `setIsContentLoaded` if the component has unmounted before our asynchronous operation completes.
    let isMounted = true;

    // Fetch and process all data
    processAllData().then(() => {
      if (isMounted) {
        // Only set the loading state to false if the component is still mounted
        setIsContentLoaded(true);
      }
    });

    // The cleanup function for the effect
    return () => {
      // If the component unmounts, we set isMounted to false to prevent any state updates after unmounting.
      isMounted = false;
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts and never again.

  // If content is still loading, display a spinner.
  if (!isContentLoaded) {
    return <DivCenter Component={() => <Spinner />} />;
  }

  return (
    <Container>
      {/* JobMatches filters */}
      <JobMatchFilters
        objName={{
          options: optionsName,
          value: selectedName,
          setValue: setSelectedName,
        }}
        objRequirement={{
          options: optionsRequirement,
          value: selectedRequirement,
          setValue: setSelectedRequirement,
        }}
      />
      {/* JobMatch Match Range */}
      <ContainerBorder>
        <MatchRangeFilters
          objSelfAssessed={{
            value: selectedMatchRangeSelfAssessed,
            setValue: setSelectedMatchRangeSelfAssessed,
          }}
          objEndorsed={{
            value: selectedMatchRangeEndorsed,
            setValue: setSelectedMatchRangeEndorsed,
          }}
          objPerson={{
            count: dashboardSelfAssessedCount,
          }}
          objRequirementMatch={{
            count: dashboardRequirementMatchCount,
          }}
        ></MatchRangeFilters>
      </ContainerBorder>
      {/* JobMatch table */}
      <ContainerBorder>
        <JobMatchTable
          pageSize={constantData.pageSize}
          data={dashboardTable}
        ></JobMatchTable>
      </ContainerBorder>
    </Container>
  );
};

export { JobMatch };
