import * as companyActions from "../../../store/actions/companyAction";
import { faCircleCheck, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Input, Popover, PopoverBody, PopoverHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";

class ProviderSelectCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popOverUpdateOpen: false,
            provider: this.props.provider,
            newIsSelected: this.props.provider.isSelected,
            newIsPreferred: this.props.provider.isPreferred,
            dirty: false,
            inProgress: false,
            optionSelected: [
                {
                    label: "No",
                    value: "No",
                },
                {
                    label: "Yes",
                    value: "Yes",
                },
                {
                    label: "Preferred",
                    value: "Preferred",
                },
            ],
        };
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    togglePopover = () => {
        const { provider, popOverUpdateOpen } = this.state;

        this.setState({ popOverUpdateOpen: !popOverUpdateOpen, dirty: false, inProgress: false, newIsSelected: provider.isSelected, newIsPreferred: provider.isPreferred });
    };

    openUpdate = () => {
        const popOverUpdateOpen = this.state.popOverUpdateOpen;
        this.setState({ popOverUpdateOpen: !popOverUpdateOpen });
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        let isSelected = false;
        let isPreferred = false;

        if (value == "Preferred") {
            isSelected = true;
            isPreferred = true;
        } else if (value == "Yes") {
            isSelected = true;
        }

        this.setState({
            newIsSelected: isSelected,
            newIsPreferred: isPreferred,
            dirty: true,
        });
    };

    handleUpdateSelected = () => {
        const { provider, newIsSelected, newIsPreferred } = this.state;

        provider.isSelected = newIsSelected;
        provider.isPreferred = newIsPreferred;

        this.setState({ inProgress: true });
        this.props.onCompany.updateCompanyProviderSelection(provider.id, provider).then(() => {
            if (!this.props.isUpdating && !this.props.error) {
                this.setState({ popOverUpdateOpen: false });
            }

            this.setState({ inProgress: this.props.isUpdating });
        });
    };

    render() {
        const { provider, newIsSelected, newIsPreferred, optionSelected } = this.state;

        return (
            <React.Fragment>
                {this.props.allowEdit ? (
                    <React.Fragment>
                        <Link to="#" id={`LKProvider${provider.id}`} onClick={() => this.openUpdate()}>
                            {provider && (provider.isPreferred ? "Preferred" : provider.isSelected ? "Yes" : "No")}
                        </Link>
                        <Popover placement="bottom" isOpen={this.state.popOverUpdateOpen} target={`LKProvider${provider.id}`} toggle={this.togglePopover}>
                            <PopoverHeader>
                                <Row>
                                    <Col sm="8">Is Selected Training Provider</Col>
                                    <Col sm="4">
                                        <button className={this.state.dirty ? "btn-primary" : "btn-secondary"} disabled={!this.state.dirty || this.state.inProgress} onClick={this.handleUpdateSelected}>
                                            <FontAwesomeIcon icon={faCircleCheck} />
                                        </button>
                                        &nbsp;
                                        <button className={`btn-danger`} onClick={this.togglePopover} disabled={this.state.inProgress}>
                                            <FontAwesomeIcon icon={faCancel} />
                                        </button>
                                    </Col>
                                </Row>
                            </PopoverHeader>
                            <PopoverBody>
                                <Row>
                                    <Col sm="12">
                                        <Input type="select" name="selected" id="selected" value={newIsPreferred ? "Preferred" : newIsSelected ? "Yes" : "No"} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                            {optionSelected.map((it, key) => (
                                                <option key={key} value={it.value}>
                                                    {it.label}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>
                                </Row>
                            </PopoverBody>
                        </Popover>
                    </React.Fragment>
                ) : (
                    <span>{provider && (provider.isPreferred ? "Preferred" : provider.isSelected ? "Yes" : "No")}</span>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUpdating: state.company.isUpdating,
        error: state.company.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCompany: bindActionCreators(companyActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSelectCell);
