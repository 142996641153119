import React from 'react';

export default class SkillProfileGapHighlight extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            animationClass: "fadeIn"
        }
    }

    componentDidMount() {
        // if (!this.props.isFully) {
        //     this.interval = setInterval(() => {
        //         this.setState({ animationClass: this.state.animationClass == "" || this.state.animationClass == "fadeIn" ? "fadeOut" : "fadeIn" })
        //     }, this.state.animationClass == "fadeIn" ? 2000 : 5000);
        // }
    }
    componentWillUnmount() {
        // if (!this.props.isFully) {
        //     clearInterval(this.interval);
        // }
    }

    render() {
        const stopFlashAnimation = true;

        if (this.props.isShow) {
            const fullyColorCode = this.props.jobMatchType == "Current" ? "#488ac7" : "#6cbb3c";
            const gapClassName =
                this.props.reqDesValue == "Desirable" && this.props.jobMatchType == "Current" ? "col-legend-desirable-current" : "col-legend-desirable-future";

            return (
                <td id={this.props.id}
                    className={`pt-1 pb-1 td-value ${this.props.fixWidthClass ? this.props.fixWidthClass : "td-fixwidth"} ${gapClassName} ${!this.props.isFully && !stopFlashAnimation && this.state.animationClass}`}
                    style={{
                        background: (this.props.isShow ? (this.props.reqDesValue == "Required" && fullyColorCode) : this.props.colUnmatched),
                        borderRight: `2px solid ${this.props.rightBorderColor ? this.props.rightBorderColor : "#FFFFFF"}`
                    }}
                    ref={(node) => {
                        if (node) {
                            //node.style.setProperty("padding", "0px", "important");
                        }
                    }}
                >{this.props.children}</td>
            )
        } else {
            return (
                <td id={this.props.id}
                    className={`pt-1 pb-1 td-value ${this.props.fixWidthClass ? this.props.fixWidthClass : "td-fixwidth"}`}
                    style={{
                        background: this.props.colUnmatched,
                        borderRight: `2px solid ${this.props.rightBorderColor ? this.props.rightBorderColor : "#FFFFFF"}`
                    }}
                >{this.props.children}</td>
            )
        }

    }
}

