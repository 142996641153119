/***
 * @example parameterizedString("my name is {s1} and surname is {s2}", "John", "Doe");
 * @return "my name is John and surname is Doe"
 *
 * @firstArgument {String} like "my name is {s1} and surname is {s2}"
 * @otherArguments {String | Number}
 * @returns {String}
 */
export const parameterizedString = (...args) => {
    let str = args[0];
    const params = args.filter((arg, index) => index !== 0);

    if (!str) return "";

    const len = params ? params.length : 0;
    for (let i = 0; i < len; i++) {
        if (!str.includes(`{s${i + 1}}`)) {
            str = str + ` ${params[i]}`;
        }
    }

    return str.replace(/{s[0-9]}+/g, (matchedStr) => {
        const variableIndex = matchedStr.replace("{s", "").replace("}", "") - 1;
        return params[variableIndex];
    });
};

export const generateRandomString = (length) => {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const pad2 = (n) => {
    return (n < 10 ? "0" : "") + n;
};

export const formatDate_yyyyMMdd_HHmmss = (date) => {
    var result = date ? date.getFullYear() + pad2(date.getMonth() + 1) + pad2(date.getDate()) + "_" + pad2(date.getHours()) + pad2(date.getMinutes()) + pad2(date.getSeconds()) : "";
    return result;
};

export const formatDate_localeString = (date) => {
    var result = date ? date.getFullYear() + "-" + pad2(date.getMonth() + 1) + "-" + pad2(date.getDate()) + "T" + pad2(date.getHours()) + ":" + pad2(date.getMinutes()) + ":" + pad2(date.getSeconds()) + "Z" : "";
    return result;
};
