import BadgeAvatar from "../BadgeAvatar";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Modal, Space, Tag } from "antd";
import React from "react";

const skillstxCapabilitiesPdf = `${process.env.PUBLIC_URL}/assets/documents/skillstx_capabilities.pdf`;

const styles = {
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "10px",
  },
  textContentSection: {
    padding: "0 20px 0 40px",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontFamily: "IBMPlexSans-Bold",
    fontSize: "18px",
    marginBottom: "15px",
  },
  description: {},
  tagsTitle: {
    marginTop: "20px",
    fontFamily: "IBMPlexSans-Bold",
  },
  downloadLinkRef: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: 0,
    width: "fit-content",
    gap: "5px",
    cursor: "pointer",
    marginTop: "20px",
  },
};

const SkillDetailsModal = ({
  isModalOpen,
  onOk,
  onCancel,
  okText = "Close",
  footer = null,
  data,
}) => {
  return (
    <Modal
      open={isModalOpen}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText}
      footer={footer}
      width={700}
    >
      <div style={styles.contentContainer}>
        <BadgeAvatar
          bgColor={data.lightColor}
          imgSource={data.imgSource}
          size={130}
        />
        <div>
          <section style={styles.textContentSection}>
            <p style={{ ...styles.title, color: data.color }}>{`${
              data.skillName
            } - Level ${data.level}${
              data?.skillType ? ` - ${data?.skillType}` : ""
            }`}</p>
            <p style={styles.description}>{data.description}</p>
            <p style={styles.tagsTitle}>Tags</p>
            <Space size={[0, 8]} wrap>
              <Tag color="default">SFIA V{data.sfiaVersion}</Tag>
              <Tag color="default">{data.skillCode}</Tag>
              <Tag color="default">Level {data.level}</Tag>
            </Space>
            <a
              href={skillstxCapabilitiesPdf}
              style={{ ...styles.downloadLinkRef, color: data.color }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more <ArrowRightOutlined />
            </a>
            <a
              href={data.link}
              style={{ ...styles.downloadLinkRef, color: data.color }}
              rel="noopener noreferrer"
              target="_blank"
            >
              View Badge <ArrowRightOutlined />
            </a>
          </section>
        </div>
      </div>
    </Modal>
  );
};

export default SkillDetailsModal;
