import { Empty } from "antd";
import { Chart } from "chart.js";
import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
import React, { useRef, useEffect } from "react";

Chart.register(TreemapController, TreemapElement);

const DivCenter = ({ Component }) => {
  return (
    <div className="p-grid p-align-center vertical-container">
      <div className="layout-wrapper">
        <Component />
      </div>
    </div>
  );
};

const constantData = {
  chartType: "treemap",
  topName: "top",
  leftName: "left",
  dataName: "data",
  boldName: "bold",
  valueName: "value",
  colors: {
    transparent: "transparent",
    font: ["white", "whiteSmoke"],
    type1: "#545350",
  },
};

const TreeMapChart = ({ data = [] }) => {
  const canvas = useRef(null);

  // Helper function to retrieve the appropriate color based on the context
  const getColorByContext = (ctx, defaultColor, colorKey) => {
    // Check if the context type matches our expected data type
    return ctx.type !== constantData.dataName
      ? defaultColor
      : ctx.raw._data[colorKey];
  };

  const dataChart = {
    datasets: [{ tree: data, key: constantData.valueName }],
  };

  const configChart = {
    type: constantData.chartType,
    data: dataChart,
    options: {
      labels: {
        position: constantData.topName,
        align: constantData.leftName,
        display: true,
        overflow: "fit",
        // Format the label based on the context data
        formatter(ctx) {
          if (ctx.type !== constantData.dataName) return;
          return [ctx.raw._data.label, `Count : ${ctx.raw.v}`];
        },
        color: constantData.colors.font,
        font: [{ size: 15, weight: constantData.boldName }, { size: 13 }],
      },
      // Utilize helper function for retrieving background color
      backgroundColor: (ctx) =>
        getColorByContext(ctx, constantData.colors.transparent, "colour"),
      // Utilize helper function for retrieving hover background color
      hoverBackgroundColor: (ctx) =>
        getColorByContext(ctx, constantData.colors.transparent, "hoverColour"),
      hoverColor: constantData.colors.type1,
      hoverFont: [{ size: 16, weight: constantData.boldName }, { size: 14 }],
      borderWidth: 0,
      borderRadius: 3,
      spacing: 1,
      plugins: {
        tooltip: {
          callbacks: {
            title: (ctx) => {
              const item = ctx[0];
              return (
                item.dataset.tree[item.datasetIndex]?.description ||
                item.dataset.value
              );
            },
          },
        },
        datalabels: {
          display: false,
        },
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
      },
    },
  };

  // Effect hook ensures chart gets rendered (or re-rendered) when data changes
  useEffect(() => {
    let chartInstance = null;
    if (data?.length > 0) {
      chartInstance = new Chart(canvas.current, configChart);
    }

    // Cleanup function to prevent memory leaks by destroying the chart instance when the component unmounts
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [data]); // Dependency array ensures the effect runs only when data changes

  // Conditionally render the canvas if data is present, otherwise display a centered 'No Data' message
  if (data?.length < 1) {
    return <DivCenter Component={() => <Empty description={"No Data"} />} />;
  }

  return <canvas ref={canvas} />;
};

export { TreeMapChart };
