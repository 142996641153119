import * as actionTypes from "../actions/actionTypes";
import { updateState } from "../utility";

const initialState = {
    email: null,
    error: null,
    loading: false,
    isLogout: false,
    redirectUrl: null,
    token: null,
    hasPeoples: false,
    hasRelationships: false,
    isEndorser: false,
    manageEndorsements: false,
    manageCertifications: false,
    manageSelfAssessed: false,
    manageSelfProfiles: false,
    loginWithMembee: false,
    loginWithTeams: false,
    assessmentId: null,
    sfiaVersion: null,
    jobProfileReportFormat: null,
    selfAssessedProfileReportFormat: null,
    authMode: null,
    loginResult: null,
    personId: null,
    ownJobs: false,

    errorRegister: null,
    loadingRegister: false,
    registerToken: null,
    resetPassword: null,
    errorResetPassword: null,
    loadingResetPassword: false,

    errorCheckEmail: null,
    loadingCheckEmail: false,
    emailCheckingExist: null,

    managedPeople: null,
};

const authRegisterEmailCheckingStart = (state) => {
    return updateState(state, {
        errorCheckEmail: null,
        loadingCheckEmail: true,
        emailCheckingExist: null,
    });
};

const authRegisterEmailCheckingSuccess = (state, action) => {
    return updateState(state, {
        errorCheckEmail: null,
        loadingCheckEmail: false,
        emailCheckingExist: action.emailCheckingExist,
    });
};

const authRegisterEmailCheckingFail = (state, action) => {
    return updateState(state, {
        errorCheckEmail: action.error,
        loadingCheckEmail: false,
        emailCheckingExist: null,
    });
};

const signInStart = (state) => {
    return updateState(state, { error: null, loading: true });
};

const signInSuccess = (state, action) => {
    return updateState(state, {
        email: action.email,
        error: null,
        loading: false,
        token: action.token,
        assessmentId: action.assessmentId,
        sfiaVersion: action.sfiaVersion,
        jobProfileReportFormat: action.jobProfileReportFormat,
        selfAssessedProfileReportFormat: action.selfAssessedProfileReportFormat,
        hasPeoples: action.hasPeoples && action.hasPeoples == "True" ? true : action.hasPeoples == "False" ? false : action.hasPeoples,
        isEndorser: action.isEndorser && action.isEndorser == "True" ? true : action.isEndorser == "False" ? false : action.isEndorser,
        ownJobs: action.ownJobs && action.ownJobs == "True" ? true : action.ownJobs == "False" ? false : action.ownJobs,
        authMode: action.authMode,
        personId: action.personId,
        manageEndorsements: action.manageEndorsements && action.manageEndorsements == "True" ? true : action.manageEndorsements == "False" ? false : action.manageEndorsements,
        manageCertifications: action.manageCertifications && action.manageCertifications == "True" ? true : action.manageCertifications == "False" ? false : action.manageCertifications,
        loginWithMembee: action.loginWithMembee && action.loginWithMembee == "True" ? true : action.loginWithMembee == "False" ? false : action.loginWithMembee,
        loginWithTeams: action.loginWithTeams && action.loginWithTeams == "True" ? true : action.loginWithTeams == "False" ? false : action.loginWithTeams,
        receiveLicenseNotification: action.receiveLicenseNotification && action.receiveLicenseNotification == "True" ? true : action.receiveLicenseNotification == "False" ? false : action.receiveLicenseNotification,
        lastActiveUserNotification: action.lastActiveUserNotification && action.lastActiveUserNotification != "NULL" ? new Date(action.lastActiveUserNotification) : null,
        allowSkipSurvey: action.allowSkipSurvey && action.allowSkipSurvey == "True" ? true : action.allowSkipSurvey == "False" ? false : action.allowSkipSurvey,
        manageSelfAssessed: action.manageSelfAssessed && action.manageSelfAssessed == "True" ? true : action.manageSelfAssessed == "False" ? false : action.manageSelfAssessed,
        manageSelfProfiles: action.manageSelfProfiles && action.manageSelfProfiles == "True" ? true : action.manageSelfProfiles == "False" ? false : action.manageSelfProfiles,
        hasRelationships: action.hasRelationships && action.hasRelationships == "True" ? true : action.hasRelationships == "False" ? false : action.hasRelationships,
    });
};

const signInSuccessWithWait = (state, action) => {
    return updateState(state, {
        email: action.email,
        error: null,
        loading: false,
        token: action.token.token,
        loginResult: action.token,
        assessmentId: action.assessmentId,
        sfiaVersion: action.sfiaVersion,
        jobProfileReportFormat: action.jobProfileReportFormat,
        selfAssessedProfileReportFormat: action.selfAssessedProfileReportFormat,
        hasPeoples: action.hasPeoples && action.hasPeoples == "True" ? true : action.hasPeoples == "False" ? false : action.hasPeoples,
        isEndorser: action.isEndorser && action.isEndorser == "True" ? true : action.isEndorser == "False" ? false : action.isEndorser,
        ownJobs: action.ownJobs && action.ownJobs == "True" ? true : action.ownJobs == "False" ? false : action.ownJobs,
        authMode: action.authMode,
        personId: action.personId,
        manageEndorsements: action.manageEndorsements && action.manageEndorsements == "True" ? true : action.manageEndorsements == "False" ? false : action.manageEndorsements,
        manageCertifications: action.manageCertifications && action.manageCertifications == "True" ? true : action.manageCertifications == "False" ? false : action.manageCertifications,
        loginWithMembee: action.loginWithMembee && action.loginWithMembee == "True" ? true : action.loginWithMembee == "False" ? false : action.loginWithMembee,
        loginWithTeams: action.loginWithTeams && action.loginWithTeams == "True" ? true : action.loginWithTeams == "False" ? false : action.loginWithTeams,
        receiveLicenseNotification: action.receiveLicenseNotification && action.receiveLicenseNotification == "True" ? true : action.receiveLicenseNotification == "False" ? false : action.receiveLicenseNotification,
        lastActiveUserNotification: action.lastActiveUserNotification && action.lastActiveUserNotification != "NULL" ? new Date(action.lastActiveUserNotification) : null,
        managedPeople: action.managedPeople,
        allowSkipSurvey: action.allowSkipSurvey && action.allowSkipSurvey == "True" ? true : action.allowSkipSurvey == "False" ? false : action.allowSkipSurvey,
        manageSelfAssessed: action.manageSelfAssessed && action.manageSelfAssessed == "True" ? true : action.manageSelfAssessed == "False" ? false : action.manageSelfAssessed,
        manageSelfProfiles: action.manageSelfProfiles && action.manageSelfProfiles == "True" ? true : action.manageSelfProfiles == "False" ? false : action.manageSelfProfiles,
        hasRelationships: action.hasRelationships && action.hasRelationships == "True" ? true : action.hasRelationships == "False" ? false : action.hasRelationships,
    });
};

const authCheckStateSuccess = (state) => {
    return updateState(state, {
        error: null,
        loading: false,
    });
};

const signInFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const authLogoutStart = (state) => {
    return updateState(state, { error: null, loading: true });
};

const authLogoutSuccess = (state) => {
    return updateState(state, {
        token: null,
        email: null,
        loading: false,
        hasPeoples: false,
        isEndorser: false,
        hasRelationships: false,
        redirectUrl: null,
    });
};

const authLogoutIntendedSuccess = (state) => {
    return updateState(state, {
        token: null,
        email: null,
        loading: false,
        isLogout: true,
        hasPeoples: false,
        isEndorser: false,
        hasRelationships: false,
        redirectUrl: null,
    });
};

const authLogoutFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const authRegisterStart = (state) => {
    return updateState(state, {
        errorRegister: null,
        loadingRegister: true,
        registerToken: null,
    });
};

const authRegisterSuccess = (state, action) => {
    return updateState(state, {
        errorRegister: null,
        loadingRegister: false,
        registerToken: action.token,
    });
};

const authRegisterFail = (state, action) => {
    return updateState(state, {
        errorRegister: action.error,
        loadingRegister: false,
        registerToken: null,
    });
};

const forgotPasswordStart = (state) => {
    return updateState(state, { error: null, loading: true });
};

const forgotPasswordSuccess = (state) => {
    return updateState(state, {
        error: null,
        loading: false,
    });
};

const forgotPasswordFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const createAccountStart = (state) => {
    return updateState(state, { error: null, loading: true });
};

const createAccountSuccess = (state) => {
    return updateState(state, {
        error: null,
        loading: false,
    });
};

const createAccountFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getResetPasswordStart = (state) => {
    return updateState(state, { errorResetPassword: null, loadingResetPassword: true });
};

const getResetPasswordSuccess = (state, action) => {
    return updateState(state, {
        errorResetPassword: null,
        loadingResetPassword: false,
        resetPassword: action.resetPassword,
    });
};

const getResetPasswordFail = (state, action) => {
    return updateState(state, {
        errorResetPassword: action.error,
        loadingResetPassword: false,
    });
};

const processResetPasswordStart = (state) => {
    return updateState(state, { errorResetPassword: null, loadingResetPassword: true });
};

const processResetPasswordSuccess = (state) => {
    return updateState(state, {
        errorResetPassword: null,
        loadingResetPassword: false,
    });
};

const processResetPasswordFail = (state, action) => {
    return updateState(state, {
        errorResetPassword: action.error,
        loadingResetPassword: false,
    });
};

const myPeopleSetManagedRelationship = (state, action) => {
    return updateState(state, {
        managedPeople: action.managedPeople,
    });
};

const myPeopleClearManagedRelationship = (state) => {
    return updateState(state, {
        managedPeople: null,
    });
};

const setRedirectUrl = (state, action) => {
    return updateState(state, {
        // isLogout: action.isLogout,
        // redirectUrl: action.redirectUrl,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SIGN_IN_START:
            return signInStart(state);
        case actionTypes.SIGN_IN_SUCCESS:
            return signInSuccess(state, action);
        case actionTypes.SIGN_IN_SUCCESS_WITH_WAIT:
            return signInSuccessWithWait(state, action);
        case actionTypes.SIGN_IN_FAIL:
            return signInFail(state, action);
        case actionTypes.AUTH_CHECK_SUCCESS:
            return authCheckStateSuccess(state);
        case actionTypes.AUTH_LOGOUT_START:
            return authLogoutStart(state);
        case actionTypes.AUTH_LOGOUT_SUCCESS:
            return authLogoutSuccess(state);
        case actionTypes.AUTH_LOGOUT_INTENDED_SUCCESS:
            return authLogoutIntendedSuccess(state);
        case actionTypes.AUTH_LOGOUT_FAIL:
            return authLogoutFail(state, action);
        case actionTypes.AUTH_REGISTER_START:
            return authRegisterStart(state);
        case actionTypes.AUTH_REGISTER_SUCCESS:
            return authRegisterSuccess(state, action);
        case actionTypes.AUTH_REGISTER_FAIL:
            return authRegisterFail(state, action);
        case actionTypes.FORGOT_PASSWORD_START:
            return forgotPasswordStart(state);
        case actionTypes.FORGOT_PASSWORD_SUCCESS:
            return forgotPasswordSuccess(state);
        case actionTypes.FORGOT_PASSWORD_FAIL:
            return forgotPasswordFail(state, action);
        case actionTypes.CREATE_ACCOUNT_START:
            return createAccountStart(state);
        case actionTypes.CREATE_ACCOUNT_SUCCESS:
            return createAccountSuccess(state);
        case actionTypes.CREATE_ACCOUNT_FAIL:
            return createAccountFail(state, action);
        case actionTypes.GET_RESET_PASSWORD_START:
            return getResetPasswordStart(state);
        case actionTypes.GET_RESET_PASSWORD_SUCCESS:
            return getResetPasswordSuccess(state, action);
        case actionTypes.GET_RESET_PASSWORD_FAIL:
            return getResetPasswordFail(state, action);
        case actionTypes.PROCESS_RESET_PASSWORD_START:
            return processResetPasswordStart(state);
        case actionTypes.PROCESS_RESET_PASSWORD_SUCCESS:
            return processResetPasswordSuccess(state);
        case actionTypes.PROCESS_RESET_PASSWORD_FAIL:
            return processResetPasswordFail(state, action);

        case actionTypes.AUTH_REGISTER_EMAIL_CHECKING_START:
            return authRegisterEmailCheckingStart(state);
        case actionTypes.AUTH_REGISTER_EMAIL_CHECKING_SUCCESS:
            return authRegisterEmailCheckingSuccess(state, action);
        case actionTypes.AUTH_REGISTER_EMAIL_CHECKING_FAIL:
            return authRegisterEmailCheckingFail(state, action);

        case actionTypes.MYPEOPLES_SET_MANAGED_RELATIONSHIP:
            return myPeopleSetManagedRelationship(state, action);
        case actionTypes.MYPEOPLES_CLEAR_MANAGED_RELATIONSHIP:
            return myPeopleClearManagedRelationship(state);

        case actionTypes.AUTH_SET_REDIRECT_URL:
            return setRedirectUrl(state, action);

        default:
            return state;
    }
};

export default reducer;
