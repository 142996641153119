import PersonService from "../../../../../common/api/service/PersonService";
import PossibleMentorTable from "../../table/possiblementor";
import ConfirmModal from "../confirm";
import { ApiOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  Row,
  Spin,
  notification,
  Switch,
  Alert,
  Col,
  Tooltip,
} from "antd";
import React, { useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import { useImmer } from "use-immer";

const ContainerCenter = styled.div`
  text-align: -webkit-center;
`;

const PossibleMentorModal = ({ showDialog, profileType, jobType, contentTranslations, contentTranslator }) => {
  const [state, setState] = useImmer({
    showDialog: false,
    dataValue: [],
    rawData: [],
    isShowConfirm: false,
    isLoading: false,
    isIgnore: false,
    profileType,
    jobType,
    contentTranslations: contentTranslations
  });

  const setDefaultData = () => {
    setState((draft) => {
      draft.showDialog = true;
      draft.dataValue = [];
      draft.isShowConfirm = false;
      draft.isLoading = true;
      draft.rawData = [];
      draft.isIgnore = false;
    });
  };

  const toFixedIfNecessary = (value, dp) => {
    return +parseFloat(value).toFixed(dp);
  };

  const processDataAsync = async () => {
    try {
      const dataSearchMentors = await PersonService.getSearchMentors(
        state.isIgnore,
        jobType,
        profileType
      );

      dataSearchMentors.map((item) => {
        item.matchedSkillPercent = item.matchedSkillPercent ? toFixedIfNecessary(item.matchedSkillPercent * 100, 2) : item.matchedSkillPercent
      })

      setState((draft) => {
        draft.dataValue = [];
        draft.rawData = dataSearchMentors.map((item) => {
          return {
            ...item,
            name: `${item.firstName} ${item.lastName}`,
          };
        });
      });
    } catch (error) {
      // Log the error if fetching fails
      console.error("Failed to fetch action plan data:", error);
      // Optionally, you can set an error state to display an error message to users.
    }
  };

  const onToggleDialogModal = () => {
    const showDialog = state.showDialog;
    setState((draft) => {
      draft.showDialog = !showDialog;
      draft.isLoading = false;
    });
  };

  const onConnectClicked = useCallback((e) => {
    setState((draft) => {
      draft.isShowConfirm = true;
    });
  });

  const onOkClicked = useCallback(async (e) => {
    setState((draft) => {
      draft.isLoading = true;
      draft.isShowConfirm = false;
    });
    try {
      const listUpdate = {
        mentorIds: state.dataValue
          .filter((item) => item.isChecked === true)
          .map((item) => item.personId),
      };
      const results = await PersonService.updateConnectMentors(jobType, profileType, listUpdate);
      if (results.length > 0) {
        await processDataAsync();
        notification["success"]({
          message: contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorConnectSuccessTitle", "Success"),
          description: contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorConnectSuccessDescription", "Connect mentor is updated"),
        });
      } else {
        notification["error"]({
          message: contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorConnectFailTitle", "Not connected properly"),
          description: contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorConnectFailDescription", "The mentor connection was not found."),
        });
      }
    } catch (error) {
      // Optionally, you can set an error state to display an error message to users.
      notification["error"]({
        message: "error",
        description: error?.errData?.title,
      });
    } finally {
      setState((draft) => {
        draft.isLoading = false;
      });
    }
  });

  const onCancelClicked = useCallback((e) => {
    setState((draft) => {
      draft.isShowConfirm = false;
    });
  });

  const onValueChanged = useCallback((val) => {
    setState((draft) => {
      draft.dataValue = val ?? [];
    });
  });

  const onIgnoreChanged = useCallback((val) => {
    setState((draft) => {
      draft.isIgnore = val;
    });
  });

  const connectValid = useMemo(() => {
    if (state.dataValue.length > 0) {
      const filter = state.dataValue.filter((item) => item.isChecked === true);
      return {
        isEmpty: filter.length < 1,
        isLimit: filter.length > 3,
      };
    }
    return {
      isEmpty: true,
      isLimit: false,
    };
  }, [state.dataValue]);

  useEffect(() => {
    if (showDialog) {
      setState((draft) => {
        draft.isLoading = true;
      });

      processDataAsync().then(() => {
        setState((draft) => {
          draft.isLoading = false;
        });
      });
    }
  }, [state.isIgnore]);

  useEffect(() => {
    if (showDialog) {
      setDefaultData();
      // This variable helps us ensure that we don't call `setIsContentLoaded` if the component has unmounted before our asynchronous operation completes.
      let isMounted = true;
      processDataAsync().then(() => {
        if (isMounted) {
          // Only set the loading state to false if the component is still mounted
          setState((draft) => {
            draft.isLoading = false;
          });
        }
      });
      // The cleanup function for the effect
      return () => {
        // If the component unmounts, we set isMounted to false to prevent any state updates after unmounting.
        isMounted = false;
      };
    }
  }, [showDialog]);

  if (state.showDialog === false) return null;

  // ... rest of the logic and JSX
  return (
    <Modal
      title={state.isShowConfirm ? null : contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorDialogTitle", "Matched Mentor(s)")}
      closable={!state.isLoading && !state.isShowConfirm}
      open={state.showDialog}
      onCancel={() => {
        setDefaultData();
        onToggleDialogModal();
      }}
      width={state.isShowConfirm ? "fit-content" : "60%"}
      style={{ borderRadius: "20px" }}
      footer={null}
    >
      <Spin
        spinning={state.isLoading}
        size="large"
        tip="Waiting..."
        delay={200}
      >
        {state.isShowConfirm == false && (
          <>
            <Row className="ml-3 mr-3">
              <Col span={12}>
                {state.rawData.length > 0 && (
                  <Button
                    disabled={connectValid.isEmpty || connectValid.isLimit}
                    onClick={onConnectClicked}
                  >
                    {contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorDialogButtonConnect", "Connect")}
                  </Button>
                )}
              </Col>
              <Col span={12} className="pl-2  text-right">
                <Tooltip title={contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorDialogTogleIgnoreSkillsTooltip", "This button will include mentors who have a 0% skill match.")}>
                  <label>{contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorDialogTogleIgnoreSkills", "Ignore Skills")}</label>
                  <Switch
                    className="ml-2"
                    checked={state.isIgnore}
                    onChange={onIgnoreChanged}
                  />
                </Tooltip>
              </Col>
            </Row>         
            <Row className="ml-3 mr-3 mt-3 ">
              <Col span={12}>
                {state.rawData.length > 0 && 
                <Alert
                  banner
                  showIcon
                  description={
                    connectValid.isEmpty
                      ? contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorDialogMinOneMentorNotes", "Please select at least one mentor before Connecting")
                      : `${!connectValid.isLimit ? "** " : ""}${contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorDialogMaxThreeMentorNotes", "You can only select a maximum of 3 items per connect.")}`
                  }
                  type={connectValid.isLimit ? "error" : "warning"}
                />
                }
              </Col>
              {!state.isIgnore && 
              <Col span={12} className="pl-2">
                <Alert
                  banner
                  showIcon={false}
                  message={
                  <code>
                    <span style={{color:"#000000", fontSize:"14px", fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"}}
                      dangerouslySetInnerHTML={{
                        __html: contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorDialogMatchMessage", "<b>IMPORTANT</b> : Mentors skills will be matched against the Skill Gaps you currently have displayed. For example, self-assessed profile and current requirement."),
                      }}
                    ></span>
                  </code>
                  }
                  type={"warning"}
                />
              </Col>
              }              
            </Row>
          </>
        )}
        <ContainerCenter>
          <ConfirmModal
            open={state.isShowConfirm}
            onOk={onOkClicked}
            onCancel={onCancelClicked}
            titleText={contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorConnectPopupMessage", "You are about to send connection request to the mentors you have selected.")}
            msg={contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorConnectPopupNotes", "When they accept, they can view your profile data.")}
            okText={contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorConnectPopupButtonOk", "Ok")}
            cancelText={contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorConnectPopupButtonCancel", "Cancel")}
          ></ConfirmModal>
        </ContainerCenter>

        {state.isShowConfirm == false && (
          <PossibleMentorTable
            refData={state.rawData}
            values={state.dataValue}
            nameHeader="Preferences"
            nameSelect="selected"
            onChange={onValueChanged}
            contentTranslations={contentTranslations}
            contentTranslator={contentTranslator}
          />
        )}
      </Spin>
    </Modal>
  );
};
export default PossibleMentorModal;
