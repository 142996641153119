export const lightenColor = (color, amount) => {
  // Parse the input color string into RGB components
  let r = parseInt(color.substr(1,2), 16);
  let g = parseInt(color.substr(3,2), 16);
  let b = parseInt(color.substr(5,2), 16);

  // Calculate the lightened RGB values
  r = Math.round(Math.min(r + amount, 255));
  g = Math.round(Math.min(g + amount, 255));
  b = Math.round(Math.min(b + amount, 255));

  // Convert the RGB values back to a hex color string
  let hex = ((r << 16) | (g << 8) | b).toString(16);
  return '#' + ('000000' + hex).slice(-6);
}