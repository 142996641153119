export const RESPONSIBILITY_SKILL_DESCRIPTION = [
    { name: "Autonomy", description: "Autonomy is described as freedom to determine one's own actions, behaviour, etc." },
    { name: "Influence", description: "Influence is described as ability to sway resulting from ability, position, etc." },
    { name: "Complexity", description: "Complexity is described as Involved, intricate or complicated etc." },
    { name: "Knowledge", description: "Knowledge is described as; facts, information, and skills acquired by a person through experience or education; the theoretical or practical understanding of a subject." },
    { name: "Business Skills", description: "Business Skills is described as understanding the business environment." },
];

export const REASON_ASSESSMENT_OPTIONS = [
    {
        label: "Select Reason",
        value: "",
    },
    {
        value: "Apply for job",
        label: "I am or will apply for a job that includes SFIA skills"
    },
    {
        value: "Looking for work",
        label: "I am or maybe looking for work and wanted to include SFIA skills in my CV/Resume"
    },
    {
        value: "Curious",
        label: "I curious and want to learn more about SFIA"
    },
    {
        value: "Recommendation",
        label: "Someone recommended/suggested it to me"
    },
    {
        value: "Assignment",
        label: "As a university or college assignment"
    },
    {
        value: "Career Planning",
        label: "I want to use a SFIA profile to help plan my career development"
    },
    {
        value: "Organisational use",
        label: "I want to assess SFIA/SkillsTx for use in my organisation"
    },
    {
        value: "None of the above",
        label: "None of the above"
    },
];

export const OPTIONS_SENIORITY_GROUP_NAME = "SFIA_Status";
export const OPTIONS_CONTRACT_TYPE_GROUP_NAME = "Contract_Type";
export const OPTIONS_YEAR_RANGE_JOB_GROUP_NAME = "SFIA_YearRangeJob";
export const OPTIONS_YEAR_RANGE_ORG_GROUP_NAME = "SFIA_YearRangeOrg";

// export const OPTIONS_SENIORITY = [
//     { label: "Senior or Executive Manager", value: "Senior or Executive Manager" },
//     { label: "Senior Advisor", value: "Senior Advisor" },
//     { label: "Line Manager", value: "Line Manager" },
//     { label: "Team Leader", value: "Team Leader" },
//     { label: "Senior Employee", value: "Senior Employee" },
//     { label: "Experienced Employee", value: "Experienced Employee" },
//     { label: "Junior Employee", value: "Junior Employee" },
//     { label: "None of the above (please comment)", value: "None of the above (please comment)" },
//     { label: "Not Applicable", value: "NA" },
// ];

// export const OPTIONS_EMPLOYMENT_CONTRACT = [
//     { label: "Permanent Full-time", value: "Permanent Full-time" },
//     { label: "Permanent Part-time", value: "Permanent Part-time" },
//     { label: "1-2 year contract", value: "1-2 year contract" },
//     { label: "2-5 year contract", value: "2-5 year contract" },
//     { label: "Consultant/Contractor", value: "Consultant/Contractor" },
//     { label: "Casual", value: "Casual" },
//     { label: "Other (Please comment)", value: "Other (Please comment)" },
//     { label: "NA", value: "Not Applicable" },
// ];

// export const OPTIONS_TIME_IN_CURRENT_ROLE = [
//     { label: "New to role or not yet started", value: "New to role or not yet started" },
//     { label: "Less than 1 Year", value: "Less than 1 Year" },
//     { label: "1 to 3 Years", value: "1 to 3 Years" },
//     { label: "3 to 7 Years", value: "3 to 7 Years" },
//     { label: "More than 7 Years", value: "More than 7 Years" },
// ];

// export const OPTIONS_TIME_IN_ORGANISATION = [
//     { label: "Less than 2 Years", value: "Less than 2 Years" },
//     { label: "2 to 5 Years", value: "2 to 5 Years" },
//     { label: "5 to 10 Years", value: "5 to 10 Years" },
//     { label: "10 to 20 Years", value: "10 to 20 Years" },
//     { label: "More than 20 Years", value: "More than 20 Years" },
// ];

export const OPTIONS_COMPANY_TYPE_CUSTOMER = "Customer";
export const OPTIONS_COMPANY_TYPE_PROVIDER = "Provider";