import CategoryAPI from "../../../../common/api/service/CategoryService";
import JobService from "../../../../common/api/service/JobService";
import PersonService from "../../../../common/api/service/PersonService";
import ProfileService from "../../../../common/api/service/ProfileService";
import { Bar, TreeMapChart } from "../../components/charts";
import { LevelResponsibility } from "../../components/config/mockdata/levels";
import { OrderCategory } from "../../components/config/mockdata/order";
import SkillsComparisonFilters from "../../components/filters/skillscomparison";
import {
  SkillsWeHaveNeedTable,
  SkillsWeHaveNeedTableNormal,
} from "../../components/table";
import { Row, Col, Typography } from "antd";
import { groupBy } from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import { Spinner } from "reactstrap";
import styled from "styled-components";

const { Title } = Typography;

const DivCenter = ({ Component }) => {
  return (
    <div className="p-grid p-align-center vertical-container">
      <div className="layout-wrapper">
        <div className="p-grid">
          <div
            className="p-col-12"
            style={{ position: "fixed", top: "50%", left: "50%" }}
          >
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
};

// styled components
const Container = styled.div`
  padding: 10px;

  .vs-h-sm-h {
    display: none;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  @media (max-width: 1280px) {
    .col-sm-c {
      max-width: 100% !important;
      flex: 0 0 100%;
    }

    .vs-h-sm-h {
      display: contents;
    }

    .sm-mt-20 {
      margin-top: 20px;
    }
  }
`;

const ContainerContent = styled.div`
  padding: 20px 30px;
  margin-bottom: 50px;
`;

const ContainerDefault = styled.div`
  padding: 0px 20px 00px 30px;
`;

// Constant Data
const constantData = {
  pageSize: 3,
  jobLorProfileLevel: 8,
  empty: "",
  spaceBar: " ",
  levelName: "level",
  profileStatusName: "profileStatus",
  skillCodeLevelName: "skillCodeLevel",
  desirableName: "Desirable",
  requiredName: "Required",
  jobTypeList: ["Current", "Future"],
  fullName: "fullName",
  categoryName: "category",
  skillName: "skill",
  skillCode: "skillCode",
  categoryTitleName: "Category",
  subCategoryName: "subCategory",
  endorsedName: "Endorsed",
  competencyName: "Competency",
  capabilityName: "capability",
  lorCodeName: "lorCode",
  proficiencyName: "Proficiency",
  knowledgeName: "Knowledge",
  genericAttributeName: "GenericAttribute",
  personName: "Person",
  keyResult: ["haveSkill", "needSkill"],
  styles: {
    maxWidth: "auto",
  },
  colors: {
    type1: "#3C65C2",
    type2: "#EEF2FF",
    type3: "#69849c",
    type4: "#a5b8c8",
  },
  characters: {
    m: "M",
    n: "N",
    p: "P",
  },
};

// Helper function to deep clone data using JSON methods
// This works for simple data structures without circular references or functions
const deepClone = (data) => JSON.parse(JSON.stringify(data));

// Returns a new array containing only the unique elements from the provided `dataArray` based on a custom comparison function.
const distinctArray = (dataArray, func) => {
  // `seen` will store a unique "hash" for each item we've already encountered.
  const seen = new Set();

  // The array to accumulate unique items from `dataArray`.
  const uniqueArray = [];

  dataArray.forEach((item) => {
    // Create a unique "hash" for the current item based on the comparator function.
    // This "hash" is simply a string representation of all items considered equal to the current item.
    const hash = uniqueArray.some((itemIn) => func(item, itemIn));

    // If we haven't seen this unique "hash" before, add the item to our unique array.
    if (!hash) {
      seen.add(hash);
      uniqueArray.push(item);
    }
  });

  return uniqueArray;
};

const SkillsComparison = () => {
  // Data
  const [dataHaveSkill, setDataHaveSkill] = useState([]);
  const [dataNeedSkill, setDataNeedSkill] = useState([]);
  const [genericAttributeHaveSkillData, setGenericAttributeHaveSkillData] =
    useState([]);
  const [genericAttributeNeedSkillData, setGenericAttributeNeedSkillData] =
    useState([]);
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  // Selection
  const [selectedLorSkill, setSelectedLorSkill] = useState([]);
  const [selectedGeneric, setSelectedGeneric] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState([]);
  const [selectedRequirement, setSelectedRequirement] = useState([]);
  const [selectedType, setSelectedType] = useState([]);

  const [categories, setCategories] = useState([]);

  // Processes the data of a person for a given item and its associated versions.
  const processPersonData = async (item, versions, categories) => {
    const availableSkillCodes = categories
      ? categories.flatMap((category, index) => {
            return category.subCategories.flatMap((subCategory, index) => {
                return subCategory.skills
                    .filter((it) => !it.isHidden)
                    .flatMap((skill, index) => {
                        return skill.skillsCode;
                    });
            });
        })
      : [];

    // Creates a copy of personB's accounts and sorts them in descending order based on the creation date.
    // Then, selects the most recent account.
    const account = item.personB.accounts
      .slice()
      .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))[0];

    // If an account exists, creates a copy of its approvals, sorts them in descending order based on the approval date,
    // and then selects the most recent approval.
    const approver =
      account &&
      account.approvals
        .slice()
        .sort((a, b) => new Date(b.approvedOn) - new Date(a.approvedOn))[0];

    // Retrieves skillProfiles from the item. If they're not available, and a version exists,
    // fetches them using the ProfileService. If none of these conditions are met, defaults to an empty array.
    const skillProfiles =
      item.skillProfile ||
      (versions.length > 0 &&
        (await ProfileService.getManagedPeopleSkillPofiles(
          item.personB.email,
          versions[0].sfiaVersion,
          versions[0].version
        ))) ||
      [];

    // Returns the processed data.
    return {
      approver,
      profiles: processDetail(skillProfiles.filter(it => availableSkillCodes.includes(it.skillCode)), categories),
      name: constantData.personName,
    };
  };

  //Processes generic attribute data for a given item and its versions.z`
  const processGenericAttributeHaveSkillData = async (
    item,
    versions,
    categories
  ) => {
    // Sorts the accounts of personB based on the creation date.
    const account = item.personB.accounts.sort(
      (a, b) => new Date(a.createdOn) - new Date(b.createdOn)
    );

    // Sorts the approvals of the oldest account based on the approval date.
    const sortedApprovals = account[0].approvals.sort(
      (a, b) => new Date(a.approvedOn) - new Date(b.approvedOn)
    );

    // Gets the first (oldest) approver if available, otherwise null.
    const approver = sortedApprovals.length > 0 ? sortedApprovals[0] : null;

    // Retrieves lorProfiles from the item or fetches them if not available and a version exists.
    const lorProfiles =
      item.lorProfile ||
      (versions.length > 0
        ? await ProfileService.getManagedPeopleLorPofiles(
            item.personB.email,
            versions[0].sfiaVersion,
            versions[0].version
          )
        : []);

    // Returns the processed data.
    return {
      approver,
      profiles: processDetail(lorProfiles, categories),
      name: constantData.genericAttributeName,
    };
  };

  const processGenericAttributeNeedSkillData = (data) => {
    // Generic
    // For the generic category of needSkill, we transform the data
    // by mapping over the jobLor property and appending jobName and jobRef to each item
    let tempMapJob = data
      .map((item) =>
        item.jobLor.map((jobLor) => ({
          ...jobLor,
          jobName: item.jobName,
          jobRef: item.jobRef,
        }))
      )
      .flat(2)
      .map((item) => ({
        jobName: item.jobName,
        jobRef: item.jobRef,
        lor: item.lor,
        lorCode: item.lorCode,
        level: item.level,
      }));

    return distinctArray(
      tempMapJob,
      (itemA, itemB) => JSON.stringify(itemA) === JSON.stringify(itemB)
    );
  };

  // Processes the job details for a given list of items.
  // For each item, it fetches the associated job, its skill profiles, and its lor profiles.
  const processJobDetailData = async (categories, items) => {
    const availableSkillCodes = categories
      ? categories.flatMap((category, index) => {
            return category.subCategories.flatMap((subCategory, index) => {
                return subCategory.skills
                    .filter((it) => !it.isHidden)
                    .flatMap((skill, index) => {
                        return skill.skillsCode;
                    });
            });
        })
      : [];

    // For each item, create a set of promises to fetch the relevant job details.
    const jobDetailsPromises = items.map(async (item) => {
      // Use Promise.all to fetch the job, its skill profiles, and lor profiles concurrently for the given jobId.
      const [job, jobSkillProfiles, jobLorProfiles] = await Promise.all([
        JobService.getJob(item.jobId),
        JobService.getJobSkillProfiles(item.jobId),
        JobService.getJobLorProfiles(
          item.jobId
        ),
      ]);

      // Enrich each jobSkillProfile with additional job details (name, reference, lor profiles).
      return jobSkillProfiles
      .filter(it => availableSkillCodes.includes(it.skillCode))
      .map((profile) => {
        profile.jobName = job.name;
        profile.jobRef = job.reference;
        profile.jobLor = jobLorProfiles;
        return profile;
      });
    });

    // Resolve all the promises and flatten the resulting arrays to a single array.
    return (await Promise.all(jobDetailsPromises)).flat(2);
  };

  // Processes data related to people who possess certain skills.
  const processHaveSkillData = async (personPeople, categories) => {
    // Initialize arrays to store processed data.
    const personData = [];
    const genericAttributeData = [];

    // Map over each person item and process their data concurrently.
    await Promise.all(
      personPeople.map(async (item) => {
        // Get the profile versions of the current person.
        // If not provided, fetch them using the ProfileService.
        const versions =
          item.profileVersion ||
          (await ProfileService.getManagedPeopleProfileVersions(
            item.personB.email
          ));

        // Concurrently fetch and process person data and generic attribute data.
        const listData = await Promise.all([
          processPersonData(item, versions, categories),
          processGenericAttributeHaveSkillData(item, versions, categories),
        ]);

        // For each processed data value, transform and filter the profiles.
        for (const value of listData) {
          const mapData = value.profiles
            .map((_) => ({
              ..._,
              // Append full name and email details to each profile.
              fullName: `${item.personB.firstName} ${item.personB.lastName}`,
              email: item.personB.email,
              ...value.approver,
              // Set the profile status based on its type and approver details.
              profileStatus: !_.profileType.includes(constantData.endorsedName)
                ? value.approver
                  ? `${_.profileType} (Approved)`
                  : `${_.profileType} (Not Approved)`
                : _.profileType,
              isApproved: !_.profileType.includes(constantData.endorsedName)
                ? value.approver
                  ? true
                  : false
                : null,
              approvedText: !_.profileType.includes(constantData.endorsedName)
                ? value.approver
                  ? "Approved"
                  : "Not Approved"
                : null,
              // Determine the capability based on stringVal.
              capability:
                _.stringVal === constantData.characters.m
                  ? constantData.competencyName
                  : _.stringVal === constantData.characters.p
                  ? constantData.proficiencyName
                  : constantData.knowledgeName,
            }))
            .filter((_) => _.stringVal !== constantData.characters.n);

          // If the processed data name matches "personName", push to personData, else to genericAttributeHaveSkillData.
          if (value.name === constantData.personName) {
            personData.push(mapData);
          } else {
            genericAttributeData.push(mapData);
          }
        }
      })
    );

    // Flatten and set the processed data to their respective states or storage locations.
    setDataHaveSkill(personData.flat(2));
    setGenericAttributeHaveSkillData(genericAttributeData.flat(2));
  };

  // Processes data related to the skills needed by people.
  const processNeedSkillData = async (personPeople, categories) => {
    // For each person, fetch their job matches concurrently.
    // Flatten the resulting array three levels deep.
    const managedPeopleJobMatches = (
      await Promise.all(
        personPeople.map((item) =>
          JobService.getManagedPeopleJobMatches(item.personB.email)
        )
      )
    ).flat(3);

    // Filter out jobs that are not part of the predefined job type list.
    const filteredJobs = managedPeopleJobMatches.filter((item) =>
      constantData.jobTypeList.includes(item.jobType)
    );

    // Process the job details for the filtered jobs.
    const jobDetails = await processJobDetailData(categories, filteredJobs);

    // Reduce the job details to create a list of processed jobs, mapping each job's stringVal
    // to its respective 'need' value.
    const processedJobs = jobDetails.reduce((a, b) => {
      if (b.stringVal === constantData.characters.m) {
        b.need = constantData.requiredName;
        // Add the processed job to the accumulator.
        a.push(b);
      } else if (b.stringVal === constantData.characters.p) {
        b.need = constantData.desirableName;
        // Add the processed job to the accumulator.
        a.push(b);
      } else {
        b.need = constantData.empty;
        // Add the processed job to the accumulator.
        a.push(b);
      }
      return a;
    }, []);

    const processedData = processDetail(processedJobs, categories);
    const genericAttributeData =
      processGenericAttributeNeedSkillData(processedData);
    // Update the state or storage with the processed jobs.
    setDataNeedSkill(processedData);
    setGenericAttributeNeedSkillData(genericAttributeData);
  };

  const processDetail = (data, categories) =>
    data.map((element) => {
      let findCategory = categories?.find(
        (item) => item.name === element?.category
      );
      if (!findCategory) {
        findCategory = {
          colour: constantData.colors.type3,
          description: constantData.empty,
        };
      }
      let findSubCategory = findCategory?.subCategories?.find(
        (item) => item.name === element?.subCategory
      );
      if (!findSubCategory) {
        findSubCategory = {
          colour: constantData.colors.type4,
          description: constantData.empty,
        };
      }
      const findSkill = findSubCategory?.skills?.find(
        (item) => item.name === element?.skill
      );
      const findLevel = findSkill?.levels?.find(
        (item) => item.level === element?.level
      );

      return {
        categoryDetail: {
          colour: findCategory?.colour,
          description: findCategory?.description,
        },
        subCategoryDetail: {
          skillColour: findSubCategory?.skillColour,
          colour: findSubCategory?.colour,
          description: findSubCategory?.description,
        },
        skillDetail: {
          description: findSkill?.description,
        },
        levelDetail: {
          description: findLevel?.description,
        },
        ...element,
      };
    });

  // Processes all relevant data for skills.
  // This function fetches a set of initial data, and then prepares and processes
  // two types of skill-related data: haveSkill and needSkill.
  const processAllData = async () => {
    try {
      // Concurrently fetch multiple sets of data using Promise.all for improved performance.
      const [
        personPeople,
        personProfile,
        profileVersion,
        skillProfile,
        lorProfile,
        categories,
      ] = await Promise.all([
        PersonService.getPersonMyPeoples(),
        PersonService.getPersonMyProfile(),
        ProfileService.getMyProfileVersions(),
        ProfileService.getMySkillPofiles(),
        ProfileService.getMyLorPofiles(),
        CategoryAPI.getCategories(),
      ]);

      // Construct a new data entry based on the fetched personal profile and related data.
      const newDataEntry = {
        personB: personProfile,
        profileVersion,
        skillProfile,
        lorProfile,
      };

      setCategories(categories);
      // Append the new data entry to the existing list of personPeople for processing "haveSkill" data.
      const prepareDataHaveSkill = [...personPeople, newDataEntry];

      // Use the original personPeople data for processing "needSkill" data since no modifications are needed.
      const prepareDataNeedSkill = personPeople;

      // Concurrently process "haveSkill" and "needSkill" data for better performance.
      await Promise.all([
        processHaveSkillData(prepareDataHaveSkill, categories),
        processNeedSkillData(prepareDataNeedSkill, categories),
      ]);
    } catch (error) {
      // Log the error if fetching fails
      console.error("Failed to fetch action plan data:", error);

      // Optionally, you can set an error state here if you'd like to display an error message to users.
    }
  };

  const generateTableSkillCategory = (dataFilterSkill) => {
    const categoryColors = dataFilterSkill.categoryColors;
    const data = dataFilterSkill.data;
    // Destructure for easier access.
    const { categoryName } = constantData;

    // Use a single reduce function to categorize and structure the data.
    const groupedData = data.reduce((acc, item) => {
      // Extract keys for categorization.
      const categoryKey = item[categoryName];      

      // If the category does not exist, initialize it.
      if (!acc[categoryKey]) {
        acc[categoryKey] = {
          values: [],
        };
      }

      // Push the item into the category values.
      acc[categoryKey].values.push(item);
      return acc;
    }, {});

    // Extract keys from groupedData
    const groupedKeys = Object.keys(groupedData);

    let orderedCats = OrderCategory.map((key) =>
      groupedKeys.find((e) => e.name === key)
    );

    let orderedKeys = [];
    if (orderedCats.filter(it => it == undefined).length > 0){
      orderedKeys = [...groupedKeys];
    }else{
      //Filter and reorder the keys based on OrderCategory
      orderedKeys = OrderCategory.filter((key) =>
        groupedKeys.includes(key)
      );
    }

    // Construct the ordered grouped data
    const orderedGroupedData = orderedKeys.map((key) => [
      key,
      groupedData[key],
    ]);

    const resultData = {
      name: constantData.categoryTitleName,
      meta: { levelResponsibility: LevelResponsibility },
      description: constantData.empty,
      styles: {
        tableHeaderColor: constantData.colors.type1,
        tableHeaderBackgroundColor: constantData.colors.type2,
      },
      subCategories: orderedGroupedData.map(
        ([categoryKey, { values: categoryValues }]) => {
          const { categoryDetail, subCategoryDetail } = categoryValues[0];
          const categoryColor = categoryColors.find(it => it.category == categoryKey);
          return {
            name: categoryKey,
            meta: { levelResponsibility: LevelResponsibility },
            description: categoryDetail.description,
            styles: {
              cateColour: categoryDetail.colour,
              skillColour: subCategoryDetail.skillColour,
              colour: subCategoryDetail.colour || "",
            },
            people: categoryValues,
            bold: categoryColor ? categoryColor.bold : "#69849c",
            tint: categoryColor ? categoryColor.tint : "#ecf0f5"
          };
        }
      ),
    };

    return resultData;
  };

  const generateTreeMap = (skills) => {
    // Group the skills by the given category name
    const groupByCate = groupBy(skills, constantData.categoryName);

    // Map each group to the desired object structure
    return Object.keys(groupByCate).map((item) => ({
      label: item,
      value: groupByCate[item].length,
      description: groupByCate[item][0]?.categoryDetail?.description,
      colour: groupByCate[item][0]?.categoryDetail?.colour,
      hoverColour: groupByCate[item][0]?.subCategoryDetail?.colour,
    }));
  };

  // Generates bar data for given input.
  const generateBar = (data) => {
    // Use reduce to create an object where the keys are the skillCodeLevel
    // and the values are their respective counts.
    const groupedData = data.reduce((acc, item) => {
      const skillCodeLevel = `${item.lorCode} - ${item.level}`;

      // If the skillCodeLevel doesn't exist in the accumulator, initialize it.
      if (!acc[skillCodeLevel]) {
        acc[skillCodeLevel] = 0;
      }

      // Increment the count for the skillCodeLevel.
      acc[skillCodeLevel]++;

      return acc;
    }, {});

    // Convert the grouped data object to an array of {label, value} objects.
    const aggregatedData = Object.entries(groupedData).map(
      ([label, value]) => ({
        label,
        value,
      })
    );

    // Sort the data in descending order based on their values (counts).
    aggregatedData.sort((a, b) => b.value - a.value);

    return {
      maxCount: Math.max(...aggregatedData.map((item) => item.value)) + 1, // Calculate the maximum count
      source: aggregatedData,
    };
  };

  const generateTreeSelect = (data, name) =>
    // Using Object.entries to get both key and value of each entry in the data object
    Object.entries(data)
      .map(([category, itemsArray], categoryIdx) => {
        // Generating a Set to deduplicate the values based on the 'name' property
        // Then spread the Set back into an array for easy mapping
        const uniqueValues = [...new Set(itemsArray.map((item) => item[name]))];
        const rootData = JSON.stringify({
          isRoot: true,
          value: category,
        });
        return {
          title: category,
          value: rootData,
          key: rootData,

          // For each unique value, we map it to generate the 'children' array
          children: uniqueValues
            .map((uniqueValue, valueIdx) => {
              const childData = JSON.stringify({
                isRoot: false,
                value: uniqueValue,
              });
              return {
                // Fetching the 'text' property directly from the original itemsArray for each child
                title: itemsArray[valueIdx].text,
                value: childData,
                key: childData,
              };
            })
            .sort((a, b) => a.title.localeCompare(b.title)),
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));

  // Filter by Requirement Name
  const optionRequirement = useMemo(() => {
    if (dataNeedSkill.length === 0) return [];

    // Deduplicate the combined array based on the fullName.
    const uniqueSkills = distinctArray(
      dataNeedSkill,
      (itemA, itemB) => itemA.jobName === itemB.jobName
    );

    return [
      ...new Set(
        uniqueSkills.map((item) => ({
          label: item.jobName,
          value: item.jobName,
        }))
      ),
    ].sort((a, b) => a.label.localeCompare(b.label));
  }, [dataNeedSkill]);

  // Filter by Requirement Type
  const optionType = useMemo(() => {
    if (dataNeedSkill.length === 0) return [];

    // Deduplicate the combined array based on the fullName.
    const uniqueSkills = distinctArray(
      dataNeedSkill,
      (itemA, itemB) => itemA.need === itemB.need
    );

    return [
      ...new Set(
        uniqueSkills.map((item) => ({
          label: item.need,
          value: item.need,
        }))
      ),
    ].sort((a, b) => a.label.localeCompare(b.label));
  }, [dataNeedSkill]);

  // Filter by Person Name
  const optionsName = useMemo(() => {
    if (dataHaveSkill.length === 0) return [];

    // Deduplicate the combined array based on the fullName.
    const uniqueSkills = distinctArray(
      dataHaveSkill,
      (itemA, itemB) => itemA.fullName === itemB.fullName
    );

    const groupedData = groupBy(uniqueSkills, constantData.fullName);

    return Object.keys(groupedData)
      .map((item) => ({
        label: item,
        value: item,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [dataHaveSkill]);

  // Filter Profile Status
  const optionProfileStatus = useMemo(() => {
    if (dataHaveSkill.length === 0) return [];

    const temp = dataHaveSkill.map((item) => ({
      capability: `${item.capability} (${item.approvedText})`,
      profileStatus: item.profileStatus,
      text: `${item.capability} (${item.approvedText})`,
    }));
    // Deduplicate the combined array based on the capability.
    const uniqueArray = distinctArray(
      temp,
      (itemA, itemB) => itemA.capability === itemB.capability
    );

    // Generate the tree select structure.
    return generateTreeSelect(
      groupBy(uniqueArray, constantData.profileStatusName),
      constantData.capabilityName
    );
  }, [dataHaveSkill]);

  // Filter Level of Skill Have
  const optionLorSkillLevel = useMemo(() => {
    // Exit early if there's no data to process.
    if (dataHaveSkill.length === 0 && dataNeedSkill.length === 0) return [];

    // Combine and transform both dataHaveSkill and dataNeedSkill arrays.
    const combinedSkills = [...dataHaveSkill, ...dataNeedSkill].map((item) => ({
      skillCode: `${item.skillCode} - ${item.level}`,
      level: `Level ${item.level}`,
      text: `${item.skillCode} - ${item.level}`,
    }));

    // Deduplicate the combined array based on the skillCode.
    const uniqueSkills = distinctArray(
      combinedSkills,
      (itemA, itemB) => itemA.skillCode === itemB.skillCode
    );

    // Generate the tree select structure.
    return generateTreeSelect(
      groupBy(uniqueSkills, constantData.levelName),
      constantData.skillCode
    );
  }, [dataHaveSkill, dataNeedSkill]);

  // Filter Level of Generic Attribute Have
  const optionGenericAttribute = useMemo(() => {
    // Exit early if there's no data to process.
    if (
      dataNeedSkill.length === 0 &&
      genericAttributeHaveSkillData.length === 0
    )
      return [];

    const tempUniq = _.uniq(dataNeedSkill.map((item) => item.jobLor).flat(2));
    // Combine and transform both dataHaveSkill and dataNeedSkill arrays.
    const combinedSkills = [...tempUniq, ...genericAttributeHaveSkillData].map(
      (item) => ({
        lorCode: `${item.lorCode} - ${item.level}`,
        level: `Level ${item.level}`,
        text: `${item.lorCode} - ${item.level}`,
      })
    );

    // Deduplicate the combined array based on the skillCode.
    const uniqueSkills = distinctArray(
      combinedSkills,
      (itemA, itemB) => itemA.lorCode === itemB.lorCode
    );

    // Generate the tree select structure.
    return generateTreeSelect(
      groupBy(uniqueSkills, constantData.levelName),
      constantData.lorCodeName
    );
  }, [genericAttributeHaveSkillData, dataNeedSkill]);

  // Data Filter  => Ref By Data
  const dataFilterHaveSkill = useMemo(() => {
    const resultData = {
      data: [],
      categoryColors: [],
      genericAttribute: [],
    };

    if (dataHaveSkill.length) {
      resultData.data = dataHaveSkill.filter((item) => {
        // If selectedName is populated and the condition is not met, filter out
        if (selectedName.length > 0 && !selectedName.includes(item.fullName)) {
          return false;
        }

        // If selectedProfile is populated and the condition is not met, filter out
        if (selectedProfile.length > 0) {
          const isFound = selectedProfile
            .map((_) => JSON.parse(_))
            .some((_) => {
              if (_.isRoot) {
                return item.profileStatus === _.value;
              } else {
                return `${item.capability} (${item.approvedText})` === _.value;
              }
            });
          if (!isFound) {
            return false;
          }
        }

        // If selectedLorSkill is populated and the condition is not met, filter out
        if (selectedLorSkill.length > 0) {
          const isFound = selectedLorSkill
            .map((_) => JSON.parse(_))
            .some((_) => {
              if (_.isRoot) {
                return `Level ${item.level}` === _.value;
              } else {
                return `${item.skillCode} - ${item.level}` === _.value;
              }
            });
          if (!isFound) {
            return false;
          }
        }

        // If all conditions are met or not applicable, include the item
        return true;
      });

      dataHaveSkill.forEach((item) => {
        if (resultData.categoryColors.find(it => it.category == item.category)) {
          return false;
        }

        const category = categories.find(it => it.name == item.category);
        if (category == null){
          return false;
        }

        if (category.subCategories.length == 0){
          return false;
        }

        const subCategory = category.subCategories[0];

        resultData.categoryColors.push({
          category : category.name,
          bold : category.colour,
          tint: subCategory.skillColour
        })
      });
    }

    if (genericAttributeHaveSkillData.length) {
      resultData.genericAttribute = genericAttributeHaveSkillData.filter(
        (item) => {
          // If selectedName is populated and the condition is not met, filter out
          if (
            selectedName.length > 0 &&
            !selectedName.includes(item.fullName)
          ) {
            return false;
          }

          // If selectedGeneric is populated and the condition is not met, filter out
          if (selectedGeneric.length > 0) {
            const isFound = selectedGeneric
              .map((_) => JSON.parse(_))
              .some((_) => {
                if (_.isRoot) {
                  return `Level ${item.level}` === _.value;
                } else {
                  return `${item.lorCode} - ${item.level}` === _.value;
                }
              });
            if (!isFound) {
              return false;
            }
          }

          // If all conditions are met or not applicable, include the item
          return true;
        }
      );
    }
    return resultData;
  }, [
    dataHaveSkill,
    genericAttributeNeedSkillData,
    selectedLorSkill,
    selectedGeneric,
    selectedName,
    selectedProfile,
  ]);

  // useMemo hook to memoize the computation of the dashboard tree map data
  // This ensures that the tree map data is only recalculated if dataFilter changes
  const dashboardTreeMapHaveSkill = useMemo(
    () => generateTreeMap(dataFilterHaveSkill.data),
    [dataFilterHaveSkill.data]
  );

  const dashboardTableSkillCategoryHaveSkill = useMemo(
    () => generateTableSkillCategory(dataFilterHaveSkill) ?? {},
    [dataFilterHaveSkill.data]
  ); // Recompute only if dataFilter changes

  const dashboardTableHaveSkill = useMemo(() => {
    const resultData = {
      generic: [],
      professional: [],
    };

    if (dataFilterHaveSkill.data.length > 0) {
      // Deep clone
      resultData.professional = deepClone(dataFilterHaveSkill.data);
    }

    return resultData;
  }, [dataFilterHaveSkill.data]);

  // Use useMemo to ensure we only recompute the data when `dataFilter` changes.
  const dashboardBarHaveSkill = useMemo(
    () =>
      dataFilterHaveSkill.genericAttribute.length > 0
        ? generateBar(dataFilterHaveSkill.genericAttribute)
        : {},
    [dataFilterHaveSkill.genericAttribute]
  );

  // Data Filter  => Ref By Data
  const dataFilterSkillWeNeed = useMemo(() => {
    const resultData = {
      data: [],
      categoryColors: [],
      genericAttribute: [],
    };
    if (dataNeedSkill.length) {
      resultData.data = dataNeedSkill.filter((item) => {
        // If selectedRequirement is populated and the condition is not met, filter out
        if (
          selectedRequirement.length > 0 &&
          !selectedRequirement.includes(item.jobName)
        ) {
          return false;
        }

        // If selectedType is populated and the condition is not met, filter out
        if (selectedType.length > 0 && !selectedType.includes(item.need)) {
          return false;
        }

        //If selectedLorSkill is populated and the condition is not met, filter out
        if (selectedLorSkill.length > 0) {
          const isFound = selectedLorSkill
            .map((_) => JSON.parse(_))
            .some((_) => {
              if (_.isRoot) {
                return `Level ${item.level}` === _.value;
              } else {
                return `${item.skillCode} - ${item.level}` === _.value;
              }
            });
          if (!isFound) {
            return false;
          }
        }

        // If all conditions are met or not applicable, include the item
        return true;
      });

      dataNeedSkill.forEach((item) => {
        if (resultData.categoryColors.find(it => it.category == item.category)) {
          return false;
        }

        const category = categories.find(it => it.name == item.category);
        if (category == null){
          return false;
        }

        if (category.subCategories.length == 0){
          return false;
        }

        const subCategory = category.subCategories[0];

        resultData.categoryColors.push({
          category : category.name,
          bold : category.colour,
          tint: subCategory.skillColour
        })
      });
    }

    if (genericAttributeNeedSkillData.length) {
      resultData.genericAttribute = genericAttributeNeedSkillData.filter(
        (item) => {
          //If selectedGeneric is populated and the condition is not met, filter out
          if (selectedGeneric.length > 0) {
            const isFound = selectedGeneric
              .map((_) => JSON.parse(_))
              .some((_) => {
                if (_.isRoot) {
                  return `Level ${item.level}` === _.value;
                } else {
                  return `${item.lorCode} - ${item.level}` === _.value;
                }
              });

            if (!isFound) {
              return false;
            }
          }

          // If all conditions are met or not applicable, include the item
          return true;
        }
      );
    }

    return resultData;
  }, [
    dataNeedSkill,
    genericAttributeNeedSkillData,
    selectedLorSkill,
    selectedGeneric,
    selectedRequirement,
    selectedType,
  ]);

  const dashboardTableSkillCategoryNeedSkill = useMemo(
    () => generateTableSkillCategory(dataFilterSkillWeNeed) ?? {},
    [dataFilterSkillWeNeed.data]
  ); // Recompute only if dataFilter changes

  // useMemo hook to memoize the computation of the dashboard tree map data
  // This ensures that the tree map data is only recalculated if dataFilter changes
  const dashboardTreeMapNeedSkill = useMemo(
    () => generateTreeMap(dataFilterSkillWeNeed.data),
    [dataFilterSkillWeNeed.data]
  );

  const dashboardTableNeedSkill = useMemo(() => {
    const resultData = {
      generic: [],
      professional: [],
    };

    if (dataFilterSkillWeNeed.data.length > 0) {
      // Deep clone
      resultData.professional = deepClone(dataFilterSkillWeNeed.data);
    }

    return resultData;
  }, [dataFilterSkillWeNeed.data]);

  // Use useMemo to ensure we only recompute the data when `dataFilter` changes.
  const dashboardBarNeedSkill = useMemo(
    () =>
      dataFilterSkillWeNeed.genericAttribute.length > 0
        ? generateBar(dataFilterSkillWeNeed.genericAttribute)
        : {},
    [dataFilterSkillWeNeed.genericAttribute]
  );

  useEffect(() => {
    // This variable helps us ensure that we don't call `setIsContentLoaded` if the component has unmounted before our asynchronous operation completes.
    let isMounted = true;

    processAllData().then(() => {
      if (isMounted) {
        // Only set the loading state to false if the component is still mounted
        setIsContentLoaded(true);
      }
    });

    // The cleanup function for the effect
    return () => {
      // If the component unmounts, we set isMounted to false to prevent any state updates after unmounting.
      isMounted = false;
    };
  }, []);

  // If content is still loading, display a spinner.
  if (!isContentLoaded) {
    return <DivCenter Component={() => <Spinner />} />;
  }

  return (
    <Container>
      <ContainerDefault>
        <SkillsComparisonFilters
          objName={{
            options: optionsName,
            value: selectedName,
            setValue: setSelectedName,
          }}
          objProfile={{
            options: optionProfileStatus,
            value: selectedProfile,
            setValue: setSelectedProfile,
          }}
          objRequirement={{
            options: optionRequirement,
            value: selectedRequirement,
            setValue: setSelectedRequirement,
          }}
          objType={{
            options: optionType,
            value: selectedType,
            setValue: setSelectedType,
          }}
          objLorSkill={{
            options: optionLorSkillLevel,
            value: selectedLorSkill,
            setValue: setSelectedLorSkill,
          }}
          objGeneric={{
            options: optionGenericAttribute,
            value: selectedGeneric,
            setValue: setSelectedGeneric,
          }}
        />
      </ContainerDefault>
      <ContainerContent>
        <Row gutter={[22]}>
          <Col
            className="col-sm-c sm-mt-20"
            span={12}
            style={{ ...constantData.styles }}
          >
            <Title level={5} className="text-center mb-30 ">
              Skills We Have
            </Title>
            <TreeMapChart data={dashboardTreeMapHaveSkill} />
          </Col>
          <Col
            className="col-sm-c sm-mt-20"
            span={12}
            style={{ ...constantData.styles }}
          >
            <Title level={5} className="text-center mb-30">
              Skills We Need
            </Title>
            <TreeMapChart data={dashboardTreeMapNeedSkill} />
          </Col>
        </Row>
      </ContainerContent>
      <ContainerContent>
        <Row gutter={[22]}>
          <Col
            className="col-sm-c sm-mt-20"
            span={12}
            style={{ ...constantData.styles }}
          >
            <SkillsWeHaveNeedTable
              isShowDetail={false}
              isSpilt={true}
              data={dashboardTableSkillCategoryHaveSkill}
            />
          </Col>
          <Col
            className="col-sm-c sm-mt-20"
            span={12}
            style={{ ...constantData.styles }}
          >
            <SkillsWeHaveNeedTable
              isShowDetail={false}
              data={dashboardTableSkillCategoryNeedSkill}
              isNeedSkill={true}
              isSpilt={true}
            />
          </Col>
        </Row>
      </ContainerContent>
      <ContainerContent>
        <Row gutter={[22]}>
          <Col
            className="col-sm-c sm-mt-20"
            span={12}
            style={{ ...constantData.styles }}
          >
            <Title level={5} className="text-left">
              Generic Attributes{" "}
              <span className="vs-h-sm-h">(Skill We Have)</span>
            </Title>
            <Bar data={dashboardBarHaveSkill} />
          </Col>
          <Col
            className="col-sm-c sm-mt-20"
            span={12}
            style={{ ...constantData.styles }}
          >
            <Title level={5} className="text-left">
              Generic Attributes{" "}
              <span className="vs-h-sm-h">(Skill We Need)</span>
            </Title>
            <Bar data={dashboardBarNeedSkill} />
          </Col>
        </Row>
      </ContainerContent>
      <ContainerContent>
        <Row gutter={[10, 10]}>
          <Col className="col-sm-c sm-mt-20 gut" span={12}>
            <SkillsWeHaveNeedTableNormal
              data={dashboardTableHaveSkill}
              isShowProfessional={true}
              isResponsive={true}
              isSpilt={true}
              pageSize={constantData.pageSize}
            />
          </Col>
          <Col className="col-sm-c sm-mt-20" span={12}>
            <SkillsWeHaveNeedTableNormal
              data={dashboardTableNeedSkill}
              isNeedSkill={true}
              isResponsive={true}
              isSpilt={true}
              isShowProfessional={true}
              pageSize={constantData.pageSize}
            />
          </Col>
        </Row>
      </ContainerContent>
    </Container>
  );
};

export { SkillsComparison };
