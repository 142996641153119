import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class OpenAIAPI {
    static createThread() {
        return axios
            .post("/openai/threads")
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createThreadMessage(threadId, message) {
        return axios
            .post(`/openai/threads/${threadId}/messages`, { message })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createThreadRun(threadId) {
        return axios
            .post(`/openai/threads/${threadId}/runs`, { })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getThreadRun(threadId, runId) {
        return axios
            .get(`/openai/threads/${threadId}/runs/${runId}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getThreadRunMessages(threadId, runId) {
        return axios
            .get(`/openai/threads/${threadId}/runs/${runId}/messages`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default OpenAIAPI;
