import { authLogoutFail, authLogoutSuccess, signInSuccess } from "../../store/actions/authAction";
import store from "../../store/store";
// import { sumologicLog } from "./../logging/LogConfiguration";
import AuthAPI from "./service/AuthService";
import axiosInstance from "axios";

const isHandlerEnabled = (config = {}) => {
    return Object.prototype.hasOwnProperty.call(config, "handlerEnabled") && !config.handlerEnabled ? false : true;
};

const CancelToken = axiosInstance.CancelToken;
let cancel;

const numbers = /[0-9]/g;

/** Error interceptor handler */
const errorHandler = (error, instance) => {
    if (isHandlerEnabled(error.config)) {
        // Eliminate property 'data' and 'headers' for privacy concern
        // count execution time from request meta
        const endTimeResponse = new Date().getTime() - error.config.meta.requestStartedAt;
        const stackDetail = error.response ? error.stack : error.request ? error.request._response : error.stack;
        const statusCode = error.response ? error.response.status : error.request ? error.request.status : 500;
        const newEndpointText = getEndpoints(error.config.url);

        var sumoLogicUrl = process.env.REACT_APP_LOG_SUMOLOGIC_URL;

        if (sumoLogicUrl && sumoLogicUrl != "") {
            // sumologicLog.error(
            //     "Failed to executed action {@Details} in {ElapsedMilliseconds}ms",
            //     {
            //         Message: error.message,
            //         Stack: stackDetail,
            //         StatusCode: statusCode,
            //         RequestPath: error.config.url,
            //         BaseUrl: error.config.baseURL,
            //         Endpoints: newEndpointText,
            //         Method: error.config.method.toUpperCase(),
            //     },
            //     endTimeResponse
            // );
        }

        const originalRequest = error.config;
        if (error.response) {
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const token = store.getState().auth.token;
                if (!token) {
                    localStorage.clear();

                    cancel(); // cancel request
                    return AuthAPI.logOut()
                        .then(() => {
                            return store.dispatch(authLogoutSuccess());
                        })
                        .catch((err) => {
                            return store.dispatch(authLogoutFail(err));
                        });
                } else {
                    return AuthAPI.refreshToken()
                        .then((res) => {
                            if (res.token) {
                                // Fullfill new token payload
                                localStorage.setItem("expiresIn", res.expiresIn);
                                localStorage.setItem("email", res.email);

                                console.log("res.personId", res.personId);

                                //store token
                                store.dispatch(
                                    signInSuccess(
                                        res.token,
                                        res.email,
                                        res.assessmentId,
                                        res.sfiaVersion,
                                        res.jobProfileReportFormat,
                                        res.selfAssessedProfileReportFormat,
                                        res.hasPeoples,
                                        res.isEndorser,
                                        res.authMode,
                                        res.personId,
                                        res.ownJobs,
                                        res.manageEndorsements,
                                        res.manageCertifications,
                                        res.loginWithMembee,
                                        res.loginWithTeams,
                                        res.receiveLicenseNotification,
                                        res.lastActiveUserNotification,
                                        res.allowSkipSurvey,
                                        res.manageSelfAssessed,
                                        res.manageSelfProfiles,
                                        res.hasRelationships
                                    )
                                );

                                // Change Authorization header
                                const token = res.token;
                                instance.defaults.headers.common["Authorization"] = "Bearer " + token;

                                //return previous request
                                return instance(originalRequest);
                            } else {
                                localStorage.clear();
                                return AuthAPI.logOut()
                                    .then(() => {
                                        return store.dispatch(authLogoutSuccess());
                                    })
                                    .catch((err) => {
                                        return store.dispatch(authLogoutFail(err));
                                    });
                            }
                        })
                        .catch((err) => {
                            return Promise.reject({ ...err });
                        });
                }
            }
        } else {
            return Promise.reject({ ...error });
        }
    }
    return Promise.reject({ ...error });
};

/** Success interceptor handler */
const successHandler = (response) => {
    if (isHandlerEnabled(response.config)) {
        // count execution time from request meta
        const endTimeResponse = new Date().getTime() - response.config.meta.requestStartedAt;
        const newEndpointText = getEndpoints(response.config.url);

        var sumoLogicUrl = process.env.REACT_APP_LOG_SUMOLOGIC_URL;

        if (sumoLogicUrl && sumoLogicUrl != "") {
            // sumologicLog.info(
            //     "Executed action {@Details} in {ElapsedMilliseconds}ms",
            //     {
            //         StatusCode: response.status,
            //         RequestPath: response.config.url,
            //         BaseUrl: response.config.baseURL,
            //         Endpoints: newEndpointText,
            //         Method: response.config.method.toUpperCase(),
            //     },
            //     endTimeResponse
            // );
        }
    }
    return response;
};

/** Request Handler to all axios connection
 *  Could put header authorization
 */
const requestHandler = (request) => {
    // start initial execution time on meta
    request.meta = request.meta || {};
    request.meta.requestStartedAt = new Date().getTime();
    // set cancel token request
    request.cancelToken = new CancelToken(function executor(c) {
        cancel = c;
    });
    const token = store.getState().auth.token;

    if (token) {
        request.headers["Authorization"] = `Bearer ${token}`;
        request.headers["Content-Type"] = "application/json";
        // request.headers["Access-Control-Allow-Headers"] =
        //   "Origin, X-Requested-With, Content-Type, Accept";
        return request;
    } else {
        request.headers["Content-Type"] = "application/json";
        return request;
    }
};

const instanceInterceptor = {
    responseInterceptor: (instance) => {
        /** Enabling response Interceptors handler */
        instance.interceptors.response.use(
            (response) => successHandler(response),
            (error) => errorHandler(error, instance)
        );
    },
    requestInterceptor: (instance) => {
        instance.interceptors.request.use((request) => requestHandler(request));
    },
};

const getEndpoints = (url) => {
    const endpointText = url.split("?")[0];
    const splitEndpoint = endpointText.split("/");
    const newEndpointText = splitEndpoint
        .filter((txt) => txt !== ",")
        .map((param) => {
            if (param.match(numbers)) {
                return "{id}";
            } else {
                return param;
            }
        })
        .join("/");
    return newEndpointText;
};

export default instanceInterceptor;
