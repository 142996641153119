import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class LorAPI {
    static getLors() {
        return axios
            .get(`/lors`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateLor(id, lor) {
        return axios
            .put(`/lors/${id}`, lor)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateLorIsHidden(id, isHidden) {
        return axios
            .post(`/lors/${id}/updateIsHidden?isHidden=${isHidden ? true : false}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default LorAPI;
