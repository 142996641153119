import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class CategoryAPI {
    static getExtraFrameworkLevels() {
        return axios
            .get(`/categories/extraFrameworkLevels`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getExtraFrameworkCapabilities() {
        return axios
            .get(`/categories/extraFrameworkCapabilities`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getCategories(includeAccountAttributes, languageId) {
        return axios
            .get(`/categories?includeAccountAttributes=${includeAccountAttributes ? "true" : "false"}${languageId ? `&languageId=${languageId}` : ""}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static addSkillAttribute(categoryId, subCategoryId, skillId, attribute) {
        return axios
            .post(`/categories/${categoryId}/${subCategoryId}/${skillId}/attributes`, attribute)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateSkillAttribute(categoryId, subCategoryId, skillId, attributeId, attribute) {
        return axios
            .put(`/categories/${categoryId}/${subCategoryId}/${skillId}/attributes/${attributeId}`, attribute)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteSkillAttribute(categoryId, subCategoryId, skillId, attributeId) {
        return axios
            .delete(`/categories/${categoryId}/${subCategoryId}/${skillId}/attributes/${attributeId}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateSkillIsHidden(categoryId, subCategoryId, skillId, isHidden) {
        return axios
            .post(`/categories/${categoryId}/${subCategoryId}/${skillId}/updateIsHidden?isHidden=${isHidden ? true : false}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static exportSkillsHiddenDataExcel() {
        return axios
            .get(`/categories/exportskillshidden`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static importSkillsHiddenDataExcel(skillsHiddenData) {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        const body = new FormData();
        body.append("ContentDisposition", "attachment");
        body.append("ContentType", "multipart/form-data");

        body.append("skillsHiddenData", skillsHiddenData);

        return axios
            .post(`/categories/importskillsHidden`, body, config)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static exportSkillAttributesDataExcel() {
        return axios
            .get(`/categories/exportskillattributes`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static exportSkillAttributesDataExcelTemplate() {
        return axios
            .get(`/categories/exportskillattributestemplate`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static importAttributesDataExcel(attributesData) {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        const body = new FormData();
        body.append("ContentDisposition", "attachment");
        body.append("ContentType", "multipart/form-data");

        attributesData.forEach((file) => {
            body.append("attributesData", file);
        });

        return axios
            .post(`/categories/importskillattributes`, body, config)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default CategoryAPI;
