import * as actionTypes from "../actions/actionTypes";
import { updateState } from "../utility";

const initialState = {
    myBadgeRequest: null,
    error: null,
    loading: false,
    isUpdating: false,
};

const getBadgeRequestStart = (state) => {
    return updateState(state, { error: null, myBadgeRequest: null, loading: true });
};

const getBadgeRequestSuccess = (state, action) => {
    return updateState(state, {
        myBadgeRequest: action.myBadgeRequest,
        error: null,
        loading: false,
    });
};

const getBadgeRequestFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const submitBadgeRequestStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const submitBadgeRequestSuccess = (state, action) => {
    return updateState(state, {
        myBadgeRequest: action.badgeRequest,
        error: null,
        isUpdating: false,
    });
};

const submitBadgeRequestFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MY_LATEST_BADGE_REQUEST_START:
            return getBadgeRequestStart(state);
        case actionTypes.GET_MY_LATEST_BADGE_REQUEST_SUCCESS:
            return getBadgeRequestSuccess(state, action);
        case actionTypes.GET_MY_LATEST_BADGE_REQUEST_FAIL:
            return getBadgeRequestFail(state, action);

        case actionTypes.SUBMIT_BADGE_REQUEST_START:
            return submitBadgeRequestStart(state);
        case actionTypes.SUBMIT_BADGE_REQUEST_SUCCESS:
            return submitBadgeRequestSuccess(state, action);
        case actionTypes.SUBMIT_BADGE_REQUEST_FAIL:
            return submitBadgeRequestFail(state, action);

        default:
            return state;
    }
};

export default reducer;
