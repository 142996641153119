import React, { Component } from 'react';
import { Container } from 'reactstrap';


export class ContentWrapper extends Component {
    static displayName = ContentWrapper.name;

    render() {
        return (
            <Container className="contentWrapper-Style" style={this.props.wrapperStyle}>
                {this.props.children}
            </Container>
        );
    }
}