import ErrorHandler from "./../errorResponseHandler";
import Instance from "./../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminPersonAPI {
    static getPersons(includeEndorsements, includeCopySelfProfileStatus, showAdminOnly, includeSelfAssessedProfile) {
        return axios
            .get(`/persons?includeEndorsements=${includeEndorsements ? "true" : "false"}&includeCopySelfProfileStatus=${includeCopySelfProfileStatus ? "true" : "false"}&showAdminOnly=${showAdminOnly ? "true" : "false"}&includeSelfAssessedProfile=${includeSelfAssessedProfile ? "true" : "false"}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getPersonsIncRelationships() {
        return axios
            .get(`/persons/relationships`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getPersonsSfiaCredentials() {
        return axios
            .get(`/persons/sfiacredentials`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updatePersonRoles(personId, roles) {
        return axios
            .post(`/persons/${personId}/updateroles`, {
                roles,
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static addPerson(person) {
        return axios
            .post(`/persons`, person)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log(err);
                throw ErrorHandler(err);
            });
    }

    static updatePerson(id, person) {
        return axios
            .put(`/persons/${id}`, person)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updatePersonEndorser(id, endorsedById, endorsedBy) {
        return axios
            .post(`/persons/${id}/updateendorser`, {
                endorsedById,
                endorsedBy,
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updatePersonEndorsementAssessor(id, assessorId, assessorName) {
        return axios
            .post(`/persons/${id}/updateEndorsementAssessor`, {
                assessorId,
                assessorName,
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deletePerson(id) {
        return axios
            .delete(`/persons/${id}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static resetPersonPassword(id) {
        return axios
            .post(`/persons/${id}/resetpassword`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static exportPersonsDataExcel(timezoneOffset) {
        return axios
            .get(`/persons/exportindividuals?timezoneOffset=${timezoneOffset}`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static exportPersonsDataExcelTemplate() {
        return axios
            .get(`/persons/exportindividualstemplate`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static exportPersonsAssessmentProgressDataExcel(timezoneOffset) {
        return axios
            .get(`/persons/exportprogress?timezoneOffset=${timezoneOffset}`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static importPersonsDataExcel(operation, personsDataFiles) {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        const body = new FormData();
        body.append("ContentDisposition", "attachment");
        body.append("ContentType", "multipart/form-data");

        personsDataFiles.forEach((file) => {
            body.append("personsData", file);
        });

        body.append("operation", operation);

        return axios
            .post(`/persons/importindividuals`, body, config)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static generatePersonProfileReports(personId, skillProfile, actionPlan, endorsedProfile) {
        return axios
            .post(
                `/persons/generatepersonprofilereports`,
                { personId, skillProfile, actionPlan, endorsedProfile },
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static generateProfileReports(request) {
        return axios
            .post(`/persons/generateprofilereports`, request, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static generateProfileReportsToEmail(request) {
        return axios
            .post(`/persons/generateprofilereportstoemail`, request)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static downloadProfileReports(token) {
        return axios
            .get(`/persons/downloadprofilereports/${token}`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static assignPersons(personIds) {
        return axios
            .post(`/persons/assignpersons`, { personIds })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log(err);
                throw ErrorHandler(err);
            });
    }

    static unAssignPersons(personIds) {
        return axios
            .post(`/persons/unassignpersons`, { personIds })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log(err);
                throw ErrorHandler(err);
            });
    }

    static invitePersons(personIds) {
        return axios
            .post(`/persons/invitepersons`, { personIds })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log(err);
                throw ErrorHandler(err);
            });
    }

    static getPageAssignments() {
        return axios
            .get(`/persons/pageassignments`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getPersonPageAssignments(personId) {
        return axios
            .get(`/persons/${personId}/pageassignments`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updatePersonPageAssignments(personId, pageAssignments) {
        return axios
            .post(`/persons/${personId}/pageassignments`, pageAssignments)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static exportEndorsementsDataExcel(timezoneOffset) {
        return axios
            .get(`/persons/exportendorsements?timezoneOffset=${timezoneOffset}`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static getColumnDisplaySettings() {
        return axios
            .get(`/persons/columnDisplaySettings`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateColumnDisplaySetting(columnDisplaySetting) {
        return axios
            .post(`/persons/columnDisplaySettings`, columnDisplaySetting)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static exportPersonsProfiles(personIds) {
        return axios
            .post(
                `/persons/exportprofiles`,
                { personIds },
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static importPersonsProfiles(personProfilesJsons) {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        const body = new FormData();
        body.append("ContentDisposition", "attachment");
        body.append("ContentType", "multipart/form-data");

        personProfilesJsons.forEach((file) => {
            body.append("personProfilesJson", file);
        });

        return axios
            .post(`/persons/importprofiles`, body, config)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static approveProfileRequests(data) {
        return axios
            .post(`/persons/approveprofilerequests`, data)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static exportLatestSelfAssessedProfilesDataExcel(timezoneOffset) {
        return axios
            .get(`/persons/exportselfassessedprofiles?timezoneOffset=${timezoneOffset}`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }
}

export default AdminPersonAPI;
