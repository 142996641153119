import * as adminCategoryActions from "../../../store/admin/actions/adminCategoryActions";
import changeInput from "../../../utils/changeInput";
import React from "react";
import { SketchPicker, ChromePicker } from "react-color";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";

class ExtraFrameworkSubCategoryDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDialog: false,
            isUpdating: false,
            form: {
                name: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                description: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                colour: {
                    validation: {
                        required: true,
                    },
                    value: "#dad5d5",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                skillColour: {
                    validation: {
                        required: true,
                    },
                    value: "#dad5d5",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: false,
            displayColorPicker: false,
            displaySkillColorPicker: false,
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
        }

        if (prevProps.subCategory !== this.props.subCategory) {
            this.setState({
                category: this.props.category,
                subCategory: this.props.subCategory,
            });

            this.prepareForm(this.props.subCategory);
        }
    }

    prepareForm = (subCategory) => {
        this.setState({
            form: {
                name: {
                    validation: {
                        required: true,
                    },
                    value: subCategory ? subCategory.name : "",
                    valid: subCategory ? true : false,
                    isValidFormat: subCategory ? true : false,
                    touched: false,
                },
                description: {
                    validation: {
                        required: true,
                    },
                    value: subCategory ? subCategory.description : "",
                    valid: subCategory ? true : false,
                    isValidFormat: subCategory ? true : false,
                    touched: false,
                },
                colour: {
                    validation: {
                        required: true,
                    },
                    value: subCategory ? subCategory.colour : "#dad5d5",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                skillColour: {
                    validation: {
                        required: true,
                    },
                    value: subCategory ? subCategory.skillColour : "#dad5d5",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: subCategory ? true : false,
        });
    };

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({
            showDialog: !showDialog,
        });

        this.prepareForm(this.props.subCategory);
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handlePerformSave = () => {
        const form = this.state.form;
        const subCategory = {
            name: form.name.value,
            description: form.description.value,
            colour: form.colour.value,
            skillColour: form.skillColour.value,
        };
        this.setState({ isUpdating: true });
        if (this.props.subCategory && this.props.subCategory.id) {
            this.props.onAdminCategory.updateExtraFrameworkSubCategory(this.props.category.id, this.props.subCategory.id, subCategory).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.error) {
                        this.props.generateAlert("success", "Update sub category success.");
                        this.togleDialogModal();
                    } else {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    }
                }
                this.setState({ isUpdating: this.props.isUpdating });
            });
        } else {
            this.props.onAdminCategory.createExtraFrameworkSubCategory(this.props.category.id, subCategory).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.error) {
                        this.props.generateAlert("success", "Add sub category success.");
                        this.togleDialogModal();
                    } else {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    }
                }
                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    handleChangeComplete = (name, color) => {
        const form = this.state.form;

        if (name == "colour") {
            form.colour.value = color.hex;
        } else if (name == "skillColour") {
            form.skillColour.value = color.hex;
        }

        this.setState({ form });
    };

    handleOpenColorPicker = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleCloseColorPicker = () => {
        this.setState({ displayColorPicker: false });
    };

    handleOpenSkillColorPicker = () => {
        this.setState({ displaySkillColorPicker: !this.state.displaySkillColorPicker });
    };

    handleCloseSkillColorPicker = () => {
        this.setState({ displaySkillColorPicker: false });
    };

    render() {
        const { form } = this.state;
        const { category, subCategory } = this.props;

        const popover = {
            position: "absolute",
            zIndex: "2",
        };
        const cover = {
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
        };

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={this.togleDialogModal}>
                    <ModalHeader toggle={this.togleDialogModal}>{subCategory && subCategory.id ? "Update Sub Category" : "Add Sub Category"}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Name</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="name" id="name" value={form.name.value} invalid={form.name.touched && !form.name.isValidFormat} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Description</Label>
                                        <Col sm={8}>
                                            <Input type="textarea" name="description" id="description" value={form.description.value} invalid={form.description.touched && !form.description.isValidFormat} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Colour</Label>
                                        <Col sm={8}>
                                            <div style={{ backgroundColor: form.colour.value, width: "60px", height: "30px", cursor: "pointer" }} onClick={this.handleOpenColorPicker}>
                                                &nbsp;
                                            </div>
                                            {this.state.displayColorPicker ? (
                                                <div style={popover}>
                                                    <div style={cover} onClick={this.handleCloseColorPicker} />
                                                    <ChromePicker disableAlpha={true} color={form.colour.value} onChangeComplete={(color) => this.handleChangeComplete("colour", color)} />
                                                </div>
                                            ) : null}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Skill Colour</Label>
                                        <Col sm={8}>
                                            <div style={{ backgroundColor: form.skillColour.value, width: "60px", height: "30px", cursor: "pointer" }} onClick={this.handleOpenSkillColorPicker}>
                                                &nbsp;
                                            </div>
                                            {this.state.displaySkillColorPicker ? (
                                                <div style={popover}>
                                                    <div style={cover} onClick={this.handleCloseSkillColorPicker} />
                                                    <ChromePicker disableAlpha={true} color={form.skillColour.value} onChangeComplete={(color) => this.handleChangeComplete("skillColour", color)} />
                                                </div>
                                            ) : null}
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.handlePerformSave();
                            }}
                            disabled={!this.state.isFormValid}
                        >
                            Save
                        </Button>
                        <Button color="secondary" onClick={this.togleDialogModal} disabled={this.state.isImporting}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.adminCategory.error,
        isUpdating: state.adminCategory.isUpdating,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAdminCategory: bindActionCreators(adminCategoryActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraFrameworkSubCategoryDetail);
