import React, { Component } from "react";
import { Container } from "reactstrap";

export class ContentWrapperLg extends Component {
    static displayName = ContentWrapperLg.name;

    render() {
        return (
            <Container className={this.props.className ? this.props.className : "contentWrapperLg-Style"} style={this.props.style} hidden={this.props.hidden}>
                {this.props.children}
            </Container>
        );
    }
}
