import * as actionPlanActions from "../../../store/actions/actionPlanAction";
import * as authActions from "../../../store/actions/authAction";
import * as categoryActions from "../../../store/actions/categoryAction";
import * as companyActions from "../../../store/actions/companyAction";
import * as lorActions from "../../../store/actions/lorAction";
import * as interventionActions from "../../../store/admin/actions/adminInterventionActions";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { filterArray } from "../../../utils/filterUtils";
import { contentTranslator } from "../../../utils/translatorUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import ManageActionPromotionDetail from "./ManageActionPromotionDetail";
import ManageTrainingDetail from "./ManageTrainingDetail";
import TrainingBulkUpload from "./TrainingBulkUpload";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faPenToSquare, faTrash, faFileExcel, faMinusCircle, faPlus, faSync, faSquareMinus, faSquareCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Button, Col, Input, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const rowStyle = {
    fontSize: "12px",
};

class ManageActionPromotions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            actionPromotions: [],
            isUpdating: false,
            showDialogInterventionDetailTs: null,
            providers: [],
            actionTypes: [],
            searchProperties: ["promoterName", "promoterEmail", "name", "description", "actionType", "lorLevelsString", "levelsString", "link"],
        };

        this.filterArray = filterArray.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        this.props.onAuth.authCheckState().then(() => {
            this.props.onActionPlan.getActionTypes().then(() => {
                this.setState({
                    actionTypes: this.props.actionTypes,
                });
            });

            Promise.all([this.props.onCompany.getProviderCompanies(), this.props.onCategory.getCategories(), this.props.onLor.getLors()]).then(() => {
                if (!this.props.loading && !this.props.categoryLoading && !this.props.companyLoading && !this.props.lorLoading) {
                    this.setState({ providers: this.props.providers, lors: this.props.lors, categories: this.props.categories });
                    this.initData();
                }

                this.setState({
                    loading: this.props.loading || this.props.categoryLoading || this.props.companyLoading || this.props.lorLoading,
                });
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.actionPromotions !== this.props.actionPromotions) {
            this.setState({
                actionPromotions: this.prepareDatas(this.props.actionPromotions.filter((it) => it.status == "Active")), //.sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1))
            });
        }
    }

    initData = () => {
        this.props.onIntervention.getActionPromotions().then(() => {
            if (!this.props.interventionLoading) {
                this.setState({
                    actionPromotions: this.prepareDatas(this.props.actionPromotions.filter((it) => it.status == "Active")), //.sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1))
                });
            }

            this.setState({ loading: this.props.loading || this.props.interventionLoading });
        });
    };

    handleRefresh = () => {
        this.initData();
    };

    prepareDatas = (actionPromotions) => {
        return actionPromotions.map((actionPromotion) => {
            return {
                id: actionPromotion.id,
                promoterId: actionPromotion.promoterId,
                promoterName: actionPromotion.promoterName,
                promoterEmail: actionPromotion.promoterEmail,
                actionType: actionPromotion.actionType,
                name: actionPromotion.name,
                link: actionPromotion.link,
                duration: actionPromotion.duration,
                durationHours: actionPromotion.durationHours,
                description: actionPromotion.description,
                status: actionPromotion.status,
                versionNumber: actionPromotion.versionNumber,
                levelsString: actionPromotion.levels
                    .sort((a, b) => `${a.skillsCode}${a.level}` > `${b.skillsCode}${b.level}`)
                    .map((level) => {
                        return `${level.skillsCode}${level.level}`;
                    })
                    .join(", "),
                levels: actionPromotion.levels,
                lorLevelsString: actionPromotion.lorLevels
                    .sort((a, b) => `${a.lorCode}${a.level}` > `${b.lorCode}${b.level}`)
                    .map((level) => {
                        return `${level.lorCode}${level.level}`;
                    })
                    .join(", "),
                lorLevels: actionPromotion.lorLevels,
            };
        });
    };

    openActionPromotionDetail = (actionPromotion) => {
        this.setState({ selectedActionPromotion: actionPromotion, showDialogInterventionDetailTs: new Date() });
    };

    handleAddNew = () => {
        this.setState({
            selectedIntervention: {
                id: null,
            },
            showDialogInterventionDetailTs: new Date(),
        });
    };

    handleSaveUpdateActionPromotion = (actionPromotion) => {
        this.setState({ isUpdating: true, updateSuccess: false });
        this.props.onIntervention.updateActionPromotion(actionPromotion.id, actionPromotion).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.interventionError) {
                    this.generateAlert("success", "Update action promotion success.");
                    this.setState({ updateSuccess: true });
                } else {
                    this.generateAlert("danger", this.props.interventionError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleAcceptActionPromotion = (actionPromotionId) => {
        this.setState({ isUpdating: true, acceptSuccess: false });
        this.props.onIntervention.acceptActionPromotion(actionPromotionId).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.interventionError) {
                    this.generateAlert("success", "Action promotion accepted.");
                    this.setState({ acceptSuccess: true });
                } else {
                    this.generateAlert("danger", this.props.interventionError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleDeclineActionPromotion = (actionPromotionId, declineReason) => {
        this.setState({ isUpdating: true, declineSuccess: false });
        this.props.onIntervention.declineActionPromotion(actionPromotionId, declineReason).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.interventionError) {
                    this.generateAlert("success", "Action promotion declined.");
                    this.setState({ declineSuccess: true });
                } else {
                    this.generateAlert("danger", this.props.interventionError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleSaveAddIntervention = (intervention) => {
        this.setState({ isUpdating: true, updateSuccess: false });
        this.props.onIntervention.addIntervention(intervention).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.interventionError) {
                    this.generateAlert("success", "Create training success.");
                    this.setState({ updateSuccess: true });
                    this.handleRefresh();
                } else {
                    this.generateAlert("danger", this.props.interventionError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleAfterSearch = () => {
        this.setState({ selectedInterventions: [] });
    };

    pad2 = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    handleReturnToList = () => {
        this.props.history.push("/admin/trainings");
    };

    render() {
        const { actionPromotions, searchKeyword, searchProperties } = this.state;
        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Manage Action List");

        const openInterventionDetail = this.openActionPromotionDetail;
        const handleDeleteIntervention = this.handleDeleteIntervention;

        const filtered = this.filterArray(actionPromotions, searchKeyword, searchProperties);

        const columns = [
            {
                id: "id",
                name: "Action",
                selector: (row) => row.id,
                sortable: false,
                width: "7%",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                            {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) && (
                                <React.Fragment>
                                    <FontAwesomeIcon id={`iconProviderDetail${row.id}`} icon={faInfoCircle} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => openInterventionDetail(row)} />
                                    <UncontrolledTooltip target={`iconProviderDetail${row.id}`} placement="bottom">
                                        Review Action
                                    </UncontrolledTooltip>
                                    &nbsp;&nbsp;&nbsp;
                                </React.Fragment>
                            )}
                        </div>
                    );
                },
            },
            {
                id: "promoterName",
                name: "Promoter Name",
                selector: (row) => row.promoterName,
                sortable: true,
                wrap: true,
                width: "10%",
            },
            {
                id: "promoterEmail",
                name: "Promoter Email",
                selector: (row) => row.promoterEmail,
                sortable: true,
                width: "14%",
                wrap: true,
                format: function (row, index) {
                    return <a href={`mailto:${row.promoterEmail}`}>{row.promoterEmail}</a>;
                },
            },
            {
                id: "actionType",
                name: "Action Type",
                selector: (row) => row.actionType,
                sortable: true,
                width: "8%",
                wrap: true,
            },
            {
                id: "name",
                name: "Action Name",
                selector: (row) => row.name,
                sortable: true,
                width: "10%",
                wrap: true,
            },
            {
                id: "description",
                name: "Action Description",
                selector: (row) => row.description,
                sortable: true,
                width: "13%",
                wrap: true,
            },
            {
                id: "lorLevelsString",
                name: "Lor(s) Addressed",
                selector: (row) => row.lorLevelsString,
                sortable: true,
                width: "9%",
                format: function (row, index) {
                    return row.lorLevelsString;
                },
                wrap: true,
            },
            {
                id: "levelsString",
                name: "Skill(s) Addressed",
                selector: (row) => row.levelsString,
                sortable: true,
                width: "9%",
                format: function (row, index) {
                    return row.levelsString;
                },
                wrap: true,
            },
            {
                id: "link",
                name: "Website",
                selector: (row) => row.link,
                sortable: true,
                width: "8%",
                wrap: true,
            },
            {
                id: "duration",
                name: "Duration (days)",
                selector: (row) => row.duration,
                sortable: false,
                center: true,
                width: "6%",
            },
            {
                id: "durationHours",
                name: "Duration (hours)",
                selector: (row) => row.durationHours,
                sortable: false,
                center: true,
                width: "6%",
            },
        ];

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <ManageActionPromotionDetail
                            actionTypes={this.state.actionTypes}
                            providers={this.state.providers}
                            lors={this.state.lors}
                            categories={this.state.categories}
                            actionPromotion={this.state.selectedActionPromotion}
                            showDialog={this.state.showDialogInterventionDetailTs}
                            generateAlert={this.generateAlert}
                            isUpdating={this.state.isUpdating}
                            handleSaveUpdateActionPromotion={this.handleSaveUpdateActionPromotion}
                            handleAcceptActionPromotion={this.handleAcceptActionPromotion}
                            handleDeclineActionPromotion={this.handleDeclineActionPromotion}
                            updateSuccess={this.state.updateSuccess}
                            acceptSuccess={this.state.acceptSuccess}
                            declineSuccess={this.state.declineSuccess}
                        ></ManageActionPromotionDetail>
                        <div style={{ marginBottom: "10px" }}>
                            <Row>
                                <Col md="4" sm="6">
                                    <SkillsTxTableSearchBar
                                        searchKeyword={this.state.searchKeyword}
                                        onChange={(searchKeyword) => {
                                            this.setState({ searchKeyword });
                                        }}
                                    ></SkillsTxTableSearchBar>
                                </Col>
                                <Col md="8" sm="6">
                                    <SkillsTxTableClearSearch
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                        }}
                                        color="primary"
                                        disabled={this.props.interventionLoading || this.state.isUpdating}
                                        onClear={() => {
                                            this.setState({ searchKeyword: "" });
                                        }}
                                    ></SkillsTxTableClearSearch>
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        onClick={this.handleRefresh}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                        }}
                                        disabled={this.props.interventionLoading || this.state.isUpdating}
                                    >
                                        <FontAwesomeIcon icon={faSync} /> Refresh
                                    </Button>
                                    &nbsp;&nbsp;
                                    <React.Fragment>
                                        <Button
                                            color="primary"
                                            onClick={this.handleReturnToList}
                                            style={{
                                                fontSize: "16px",
                                                height: "40px",
                                            }}
                                            disabled={this.props.interventionLoading}
                                        >
                                            Return to list
                                        </Button>
                                    </React.Fragment>
                                </Col>
                            </Row>
                        </div>
                        <SkillsTxTable
                            columns={columns}
                            data={actionPromotions}
                            pagination={true}
                            searchKeyword={searchKeyword}
                            searchProperties={searchProperties}
                            selectableRows={true}
                            selectableRowsHighlight={true}
                            handleRowClick={this.handleRowClick}
                            progressPending={this.props.interventionLoading}
                            headCellsStyle={{
                                style: {
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    border: "1px solid #dee2e6",
                                    whiteSpace: "wrap",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                    paddingRight: "8px",
                                    paddingLeft: "8px",
                                },
                            }}
                        ></SkillsTxTable>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,

        interventionLoading: state.adminIntervention.loading,
        interventionError: state.adminIntervention.error,
        actionPromotions: state.adminIntervention.actionPromotions,
        isUpdating: state.adminIntervention.isUpdating,
        actionTypes: state.actionPlan.actionTypes,

        companyLoading: state.company.loading,
        companyError: state.company.error,
        providers: state.company.providers,

        categories: state.category.categories,
        categoryLoading: state.category.loading,

        lorLoading: state.lor.loading,
        lors: state.lor.lors,

        exportData: state.adminIntervention.exportData,
        loadingExport: state.adminIntervention.loadingExport,
        pageAssignments: state.adminPerson.pageAssignments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onIntervention: bindActionCreators(interventionActions, dispatch),
        onActionPlan: bindActionCreators(actionPlanActions, dispatch),
        onCategory: bindActionCreators(categoryActions, dispatch),
        onCompany: bindActionCreators(companyActions, dispatch),
        onLor: bindActionCreators(lorActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageActionPromotions));
