import * as actionTypes from "../actions/actionTypes";
import { updateState } from "../utility";

const initialState = {
    contentTranslations: [],
    error: null,
    loading: false,
};

const getContentTranslationsStart = (state) => {
    return updateState(state, { error: null, loading: true });
};

const getContentTranslationsSuccess = (state, action) => {
    let currContentTranslations = state.contentTranslations ? [...state.contentTranslations] : [];
    const actionContentTranslations = action.contentTranslations;
    const updatedContentTranslations = currContentTranslations.map((currContentTranslation) => {
        try {
            if (actionContentTranslations.find((x) => x.page == currContentTranslation.page && x.name == currContentTranslation.name)) {
                var actionContentTranslation = actionContentTranslations.find((x) => x.page == currContentTranslation.page && x.name == currContentTranslation.name);                

                return {
                    id: currContentTranslation.id,
                    page: currContentTranslation.page,
                    name: currContentTranslation.name,
                    contentId: currContentTranslation.contentId,
                    contentTranslationId: currContentTranslation.contentTranslationId,
                    languageId: currContentTranslation.languageId,
                    language: currContentTranslation.language,
                    contentText: actionContentTranslation.contentText,
                    contentTextTranslation: actionContentTranslation.contentTextTranslation,
                };
            } else {
                return {
                    id: currContentTranslation.id,
                    page: currContentTranslation.page,
                    name: currContentTranslation.name,
                    contentId: currContentTranslation.contentId,
                    contentTranslationId: currContentTranslation.contentTranslationId,
                    languageId: currContentTranslation.languageId,
                    language: currContentTranslation.language,
                    contentText: currContentTranslation.contentText,
                    contentTextTranslation: currContentTranslation.contentText,
                };
            }
        } catch (err) {
            console.log(err.message);
        }
    });

    let addedContentTranslations = actionContentTranslations.filter((it) => !currContentTranslations.some((x) => x.page == it.page && x.name == it.name));
    const latestContentTranslations = [...updatedContentTranslations, ...addedContentTranslations];

    return updateState(state, {
        contentTranslations: [...latestContentTranslations],
        error: null,
        loading: false,
    });
};

const getContentTranslationsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CONTENT_TRANSLATIONS_START:
            return getContentTranslationsStart(state);
        case actionTypes.GET_CONTENT_TRANSLATIONS_SUCCESS:
            return getContentTranslationsSuccess(state, action);
        case actionTypes.GET_CONTENT_TRANSLATIONS_FAIL:
            return getContentTranslationsFail(state, action);
        default:
            return state;
    }
};

export default reducer;
