import ErrorHandler from "./../errorResponseHandler";
import Instance from "./../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminRelationshipTypeAPI {
    static getRelationshipTypes() {
        return axios
            .get(`/relationshipTypes`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }
}

export default AdminRelationshipTypeAPI;