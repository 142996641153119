import ContentAPI from "./../../common/api/service/ContentService";
import * as actionTypes from "./actionTypes";

export const getContentTranslationsStart = () => {
    return {
        type: actionTypes.GET_CONTENT_TRANSLATIONS_START,
    };
};

export const getContentTranslationsSuccess = (contentTranslations) => {
    return {
        type: actionTypes.GET_CONTENT_TRANSLATIONS_SUCCESS,
        contentTranslations,
    };
};

export const getContentTranslationsFail = (error) => {
    return {
        type: actionTypes.GET_CONTENT_TRANSLATIONS_FAIL,
        error: error,
    };
};

export const getContentTranslations = (languageId, pages) => {
    return (dispatch) => {
        dispatch(getContentTranslationsStart());

        return ContentAPI.getContentTranslations(languageId, pages)
            .then((res) => {
                if (res) {
                    dispatch(getContentTranslationsSuccess(res));
                } else {
                    dispatch(getContentTranslationsFail(res));
                }
            })
            .catch((err) => {
                dispatch(getContentTranslationsFail(err));
            });
    };
};
