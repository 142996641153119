import * as adminContentActions from "../../../store/admin/actions/adminContentActions";
import changeInput from "../../../utils/changeInput";
import React from "react";
import { connect } from "react-redux";
import ReactTextareaAutosize from "react-textarea-autosize";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";

const textareaStyle = {
    width: "100%",
    padding: "10px",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
};

const textareaStyleDisable = {
    width: "100%",
    padding: "10px",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    background: "#e9ecef",
};

class ContentDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDialog: false,
            isUpdating: false,
            pages: this.props.pages,
            languages: this.props.languages,
            form: {},
            isFormValid: false,
            content: this.props.content,
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
            this.prepareFormState();
        }

        if (prevProps.content !== this.props.content) {
            this.setState({ content: this.props.content });
        }
    }

    prepareFormState = () => {
        const content = this.props.content;
        if (content) {
            const form = {
                contentId: {
                    validation: {
                        required: false,
                    },
                    value: content.contentId,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                contentTranslationId: {
                    validation: {
                        required: false,
                    },
                    value: content.contentTranslationId,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                page: {
                    validation: {
                        required: true,
                    },
                    value: content.page,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                name: {
                    validation: {
                        required: true,
                    },
                    value: content.name ? content.name : null,
                    valid: content.name ? true : false,
                    isValidFormat: content.name ? true : false,
                    touched: false,
                },
                contentText: {
                    validation: {
                        required: true,
                    },
                    value: content.contentText ? content.contentText : "",
                    valid: content.contentText ? true : false,
                    isValidFormat: content.contentText ? true : false,
                    touched: false,
                },
                contentTextTranslation: {
                    validation: {
                        required: false,
                    },
                    value: content.contentTextTranslation,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                language: {
                    validation: {
                        required: true,
                    },
                    value: content.language ? content.language : this.props.language ? this.props.language.name : null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                languageId: {
                    validation: {
                        required: true,
                    },
                    value: content.languageId ? content.languageId : this.props.language ? this.props.language.id : null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            };

            const updateState = changeInput(form, "page", form.page.value);

            this.setState({
                form: updateState.form,
                isFormValid: updateState.valid,
            });
        }
    };

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({
            showDialog: !showDialog,
        });
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handlePerformSave = () => {
        const form = this.state.form;
        const content = {
            contentId: form.contentId.value,
            contentTranslationId: form.contentTranslationId.value,
            page: form.page.value,
            name: form.name.value,
            contentText: form.contentText.value,
            contentTextTranslation: form.contentTextTranslation.value,
            language: form.language.value,
            languageId: form.languageId.value,
        };

        this.setState({ isUpdating: true });

        if (content.contentId) {
            this.props.onContent.updateContentTranslation(content).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.personsError) {
                        this.props.generateAlert("success", "Update content success.");
                        this.togleDialogModal();
                    } else {
                        this.props.generateAlert("danger", this.props.personsError.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        } else {
            this.props.onContent.createContentTranslation(content).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.personsError) {
                        this.props.generateAlert("success", "Create content success.");
                        this.togleDialogModal();
                    } else {
                        this.props.generateAlert("danger", this.props.personsError.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    render() {
        const { form, showDialog, languages, pages, content } = this.state;

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={!this.state.isUpdating ? this.togleDialogModal : null} size="xl">
                    <ModalHeader toggle={!this.state.isUpdating ? this.togleDialogModal : null}>Content Detail</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col md={6} sm={12}>
                                    {/* <FormGroup row>
                                        <Label sm={4}>Page</Label>
                                        <Col sm={8}>
                                            <Input type="select" name="page" id="page" value={form.page && form.page.value} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                {pages
                                                    .filter((it) => it.value)
                                                    .map((page, key) => (
                                                        <option key={key} value={page.value}>
                                                            {page.label}
                                                        </option>
                                                    ))}
                                            </Input>
                                        </Col>
                                    </FormGroup> */}
                                    <FormGroup row>
                                        <Label sm={4}>Name</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="name" id="name" value={form.name && form.name.value} invalid={form.name && form.name.touched && !form.name.isValidFormat} onChange={this.handleChange} disabled={true} />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={6} sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Language</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="language" id="language" value={form.language && form.language.value} invalid={form.language && form.language.touched && !form.language.isValidFormat} readOnly={true} />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Content</Label>
                                        <Col sm={8}>
                                            <ReactTextareaAutosize
                                                minRows={5}
                                                style={!this.state.isUpdating ? textareaStyle : textareaStyleDisable}
                                                aria-label="maximum height"
                                                value={form.contentText && form.contentText.value ? form.contentText.value : ""}
                                                onChange={this.handleChange}
                                                disabled={this.state.isUpdating}
                                                name="contentText"
                                                id="contentText"
                                                placeholder="Content"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={6} sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Translation</Label>
                                        <Col sm={8}>
                                            <ReactTextareaAutosize
                                                minRows={5}
                                                style={!this.state.isUpdating ? textareaStyle : textareaStyleDisable}
                                                aria-label="maximum height"
                                                value={form.contentTextTranslation && form.contentTextTranslation.value ? form.contentTextTranslation.value : ""}
                                                onChange={this.handleChange}
                                                disabled={this.state.isUpdating}
                                                name="contentTextTranslation"
                                                id="contentTextTranslation"
                                                placeholder="Translation"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <React.Fragment>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.handlePerformSave();
                                }}
                                disabled={!this.state.isFormValid || this.state.isUpdating}
                            >
                                Save
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.togleDialogModal();
                                }}
                                disabled={this.state.isUpdating}
                            >
                                Cancel
                            </Button>
                        </React.Fragment>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.adminContent.error,
        isUpdating: state.adminContent.isUpdating,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onContent: bindActionCreators(adminContentActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentDetail);
