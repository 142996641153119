import * as authActions from "../../../store/actions/authAction";
import * as configActions from "../../../store/actions/configAction";
import * as personActions from "../../../store/actions/personAction";
import * as skillProfileActions from "../../../store/actions/skillProfileAction";
import * as adminBadgeRequestActions from "../../../store/admin/actions/adminBadgeRequestActions";
import * as adminPersonActions from "../../../store/admin/actions/adminPersonActions";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { filterArray } from "../../../utils/filterUtils";
import { contentTranslator } from "../../../utils/translatorUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import BadgeRequestAssessorCell from "./BadgeRequestAssessorCell";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCertificate, faCircleXmark, faClipboardCheck, faDownload, faSquareCheck, faSquareMinus, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

class ManageSfiaCredentials extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            persons: [],
            badgeRequests: [],
            isUpdating: false,
            searchProperties: ["email", "firstName", "lastName", "assessorName", "status"],
            modeGenerateReports: false,
            modeSubmitCertificate: false,
            personsSkillProfileSelected: [],
            personsEndorsedSelected: [],
            personsActionSelected: [],
            personsSubmitCertificateSelected: [],
            loadingPrint: false,
            filterAssignedToMe: false,
            showLoadingPanel: false,
            selectedRequestToReject: null,
            showRejectBadgeConfirmation: false,
        };

        this.filterArray = filterArray.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        const routeGoBack = localStorage.getItem("routeGoBack");
        localStorage.setItem("routeAdminFrom", "/admin/sfiacredentials");
        localStorage.setItem("manageProfileByAdminPopup", "FALSE");

        let repopulate = true;
        if (routeGoBack == "TRUE") {
            repopulate = false;
        }

        this.props.onAuth.authCheckState().then(() => {
            const searchProperties = this.state.searchProperties;
            if (repopulate || !this.props.badgeRequests || (this.props.badgeRequests && this.props.badgeRequests.length == 0)) {
                this.initData();
                this.props.onAdminPerson.getPersons().then(() => {
                    let persons = this.props.persons.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1));

                    this.setState({
                        persons,
                    });
                });
                this.setState({ loading: this.props.loading });
            } else {
                const filterAssignedToMe = this.state.filterAssignedToMe;

                let badgeRequests = this.reformBadgeRequests(this.props.badgeRequests.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1)));
                if (filterAssignedToMe) {
                    badgeRequests = badgeRequests.filter((it) => it.assessorId == this.props.personId);
                }

                if (this.state.modeGenerateReports) {
                    badgeRequests.forEach((it) => {
                        it.toggleSkillProfileSelected = !it.selfAssessedReportGenerated && it.percentage >= 1;
                        it.toggleEndorsedSelected = !it.endorsedReportGenerated && it.hasEndorseProfile;
                        it.toggleActionSelected = !it.actionReportGenerated && it.hasAction;
                    });
                }

                let persons = this.props.persons.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1));

                this.setState({
                    badgeRequests: badgeRequests,
                    personsSkillProfileSelected: badgeRequests.filter((it) => it.toggleSkillProfileSelected).map((row) => row.id),
                    personsEndorsedSelected: badgeRequests.filter((it) => it.toggleEndorsedSelected).map((row) => row.id),
                    personsActionSelected: badgeRequests.filter((it) => it.toggleActionSelected).map((row) => row.id),
                    personsSubmitCertificateSelected: [],
                    persons,
                    loading: false,
                });
            }
        });
    }

    componentWillUnmount() {
        localStorage.setItem("routeGoBack", "FALSE");
    }

    componentDidUpdate(prevProps) {
        if (prevProps.persons !== this.props.persons && this.props.persons) {
            this.setState({ persons: this.props.persons.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1)) });
        }

        if (prevProps.badgeRequests !== this.props.badgeRequests && this.props.badgeRequests) {
            this.setState({ badgeRequests: this.reformBadgeRequests(this.props.badgeRequests.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))) });
        }
    }

    reformBadgeRequests = (badgeRequests) => {
        return badgeRequests.map((it) => {
            return {
                id: it.id,
                personId: it.personId,
                idSkillProfile: it.id,
                idActionPlan: it.id,
                idEndorsedProfile: it.id,
                firstName: it.firstName,
                lastName: it.lastName,
                certificationSubmitted: it.certificationSubmitted,
                email: it.email,
                completeName: `${it.firstName}${it.lastName ? ` ${it.lastName}` : ""}${it.email ? ` ${it.email}` : ""}`,

                totalQuestions: it.totalQuestions,
                answeredQuestions: it.answeredQuestions,
                totalTimeTaken: it.totalTimeTaken,
                percentage: it.percentage,
                hasEndorseProfile: it.hasEndorseProfile,
                hasAction: it.hasAction,

                selfAssessedReportGenerated: it.selfAssessedReportGenerated,
                lastSelfAssessedReportGeneratedVersion: it.lastSelfAssessedReportGeneratedVersion,
                lastSelfAssessedReportGeneratedOn: it.lastSelfAssessedReportGeneratedOn,
                actionReportGenerated: it.actionReportGenerated,
                lastActionReportGeneratedVersion: it.lastActionReportGeneratedVersion,
                lastActionReportGeneratedOn: it.lastActionReportGeneratedOn,
                endorsedReportGenerated: it.endorsedReportGenerated,
                lastEndorsedReportGeneratedVersion: it.lastEndorsedReportGeneratedVersion,
                lastEndorsedReportGeneratedOn: it.lastEndorsedReportGeneratedOn,

                submitDate: it.submitDate,
                status: it.status ? it.status : "",
                assessorId: it.assessorId,
                assessorName: it.assessorName ? it.assessorName : "",
                assessedDate: it.assessedDate,
            };
        });
    };

    initData = () => {
        const filterAssignedToMe = this.state.filterAssignedToMe;
        this.props.onAdminBadgeRequest.getBadgeRequests().then(() => {
            let badgeRequests = this.reformBadgeRequests(this.props.badgeRequests.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1)));
            if (filterAssignedToMe) {
                badgeRequests = badgeRequests.filter((it) => it.assessorId == this.props.personId);
            }

            if (this.state.modeGenerateReports) {
                badgeRequests.forEach((it) => {
                    it.toggleSkillProfileSelected = !it.selfAssessedReportGenerated && it.percentage >= 1;
                    it.toggleEndorsedSelected = !it.endorsedReportGenerated && it.hasEndorseProfile;
                    it.toggleActionSelected = !it.actionReportGenerated && it.hasAction;
                });
            }

            this.setState({
                badgeRequests: badgeRequests,
                personsSkillProfileSelected: badgeRequests.filter((it) => it.toggleSkillProfileSelected).map((row) => row.id),
                personsEndorsedSelected: badgeRequests.filter((it) => it.toggleEndorsedSelected).map((row) => row.id),
                personsActionSelected: badgeRequests.filter((it) => it.toggleActionSelected).map((row) => row.id),
                personsSubmitCertificateSelected: [],
            });
        });
    };

    handleRefresh = () => {
        this.initData();
    };

    handleRowClickDummy = () => {};

    handleRowSkillProfileClick = (row) => {
        if (!this.state.loadingPrint) {
            const badgeRequests = this.state.badgeRequests;
            const updatedData = badgeRequests.find((it) => it.id == row.id);
            updatedData.toggleSkillProfileSelected = !updatedData.toggleSkillProfileSelected;

            this.setState({ badgeRequests: [...badgeRequests], personsSkillProfileSelected: badgeRequests.filter((it) => it.toggleSkillProfileSelected).map((row) => row.personId) });
        }
    };

    handleSelectSkillProfileAll = () => {
        const { badgeRequests, searchKeyword, searchProperties, columnSearchKeywords } = this.state;
        let selectAll = false;

        const filtered = this.filterArray(badgeRequests, searchKeyword, searchProperties, columnSearchKeywords);

        if (filtered.filter((it) => it.toggleSkillProfileSelected && it.percentage >= 1).length == 0) {
            selectAll = true;
        } else if (filtered.filter((it) => !it.toggleSkillProfileSelected && it.percentage >= 1).length == 0) {
            selectAll = false;
        } else {
            selectAll = true;
        }

        badgeRequests.forEach((it) => {
            it.toggleSkillProfileSelected = false;
        });

        if (selectAll) {
            badgeRequests
                .filter((it) => it.percentage >= 1)
                .forEach((it) => {
                    if (filtered.find((f) => f.id == it.id)) {
                        it.toggleSkillProfileSelected = true;
                    }
                });
        }

        this.setState({ badgeRequests: [...badgeRequests], personsSkillProfileSelected: badgeRequests.filter((it) => it.toggleSkillProfileSelected).map((row) => row.personId) });
    };

    handleRowEndorsedClick = (row) => {
        if (!this.state.loadingPrint) {
            const badgeRequests = this.state.badgeRequests;
            const updatedData = badgeRequests.find((it) => it.id == row.id);
            updatedData.toggleEndorsedSelected = !updatedData.toggleEndorsedSelected;

            this.setState({ badgeRequests: [...badgeRequests], personsEndorsedSelected: badgeRequests.filter((it) => it.toggleEndorsedSelected).map((row) => row.personId) });
        }
    };

    handleSelectEndorsedAll = () => {
        const { badgeRequests, searchKeyword, searchProperties, columnSearchKeywords } = this.state;
        let selectAll = false;

        const filtered = this.filterArray(badgeRequests, searchKeyword, searchProperties, columnSearchKeywords);

        if (filtered.filter((it) => it.toggleEndorsedSelected && it.hasEndorseProfile).length == 0) {
            selectAll = true;
        } else if (filtered.filter((it) => !it.toggleEndorsedSelected && it.hasEndorseProfile).length == 0) {
            selectAll = false;
        } else {
            selectAll = true;
        }

        badgeRequests.forEach((it) => {
            it.toggleEndorsedSelected = false;
        });

        if (selectAll) {
            badgeRequests
                .filter((it) => it.hasEndorseProfile)
                .forEach((it) => {
                    if (filtered.find((f) => f.id == it.id)) {
                        it.toggleEndorsedSelected = true;
                    }
                });
        }

        this.setState({ badgeRequests: [...badgeRequests], personsEndorsedSelected: badgeRequests.filter((it) => it.toggleEndorsedSelected).map((row) => row.personId) });
    };

    handleRowActionClick = (row) => {
        if (!this.state.loadingPrint) {
            const badgeRequests = this.state.badgeRequests;
            const updatedData = badgeRequests.find((it) => it.id == row.id);
            updatedData.toggleActionSelected = !updatedData.toggleActionSelected;

            this.setState({ badgeRequests: [...badgeRequests], personsActionSelected: badgeRequests.filter((it) => it.toggleActionSelected).map((row) => row.personId) });
        }
    };

    handleSelectActionAll = () => {
        const { badgeRequests, searchKeyword, searchProperties, columnSearchKeywords } = this.state;
        let selectAll = false;

        const filtered = this.filterArray(badgeRequests, searchKeyword, searchProperties, columnSearchKeywords);

        if (filtered.filter((it) => it.toggleActionSelected && it.hasAction).length == 0) {
            selectAll = true;
        } else if (filtered.filter((it) => !it.toggleActionSelected && it.hasAction).length == 0) {
            selectAll = false;
        } else {
            selectAll = true;
        }

        badgeRequests.forEach((it) => {
            it.toggleActionSelected = false;
        });

        if (selectAll) {
            badgeRequests
                .filter((it) => it.hasAction)
                .forEach((it) => {
                    if (filtered.find((f) => f.id == it.id)) {
                        it.toggleActionSelected = true;
                    }
                });
        }

        this.setState({ badgeRequests: [...badgeRequests], personsActionSelected: badgeRequests.filter((it) => it.toggleActionSelected).map((row) => row.personId) });
    };

    handleClearSelection = () => {
        const badgeRequests = this.state.badgeRequests;

        badgeRequests.forEach((it) => {
            it.toggleSkillProfileSelected = false;
            it.toggleEndorsedSelected = false;
            it.toggleActionSelected = false;
            it.toggleCertificateSelected = false;
        });

        this.setState({ badgeRequests: [...badgeRequests], personsSkillProfileSelected: [], personsEndorsedSelected: [], personsActionSelected: [], personsSubmitCertificateSelected: [] });
    };

    certifyProfile = (person) => {
        localStorage.setItem("showApprovedProfileOnly", "FALSE");
        localStorage.setItem("profileType", "SelfAssessed");
        localStorage.setItem("manageProfileByAdminPopup", "TRUE");

        this.props.history.push(`/SfiaProfile/Certify?email=${person.email}`);
    };

    handleGenerateReport = () => {
        const badgeRequests = this.state.badgeRequests;

        badgeRequests.forEach((it) => {
            it.toggleSkillProfileSelected = !it.selfAssessedReportGenerated && it.percentage >= 1;
            it.toggleEndorsedSelected = !it.endorsedReportGenerated && it.hasEndorseProfile;
            it.toggleActionSelected = !it.actionReportGenerated && it.hasAction;
        });

        this.setState({
            modeGenerateReports: true,
            badgeRequests: [...badgeRequests],
            personsSkillProfileSelected: badgeRequests.filter((it) => it.toggleSkillProfileSelected).map((row) => row.personId),
            personsEndorsedSelected: badgeRequests.filter((it) => it.toggleEndorsedSelected).map((row) => row.personId),
            personsActionSelected: badgeRequests.filter((it) => it.toggleActionSelected).map((row) => row.personId),
        });
    };

    handleConfirmGenerateReport = () => {
        const { personsSkillProfileSelected, personsEndorsedSelected, personsActionSelected } = this.state;
        let personIds = [];

        personIds.push(...personsSkillProfileSelected);

        personsEndorsedSelected.forEach((it) => {
            if (!personIds.find((x) => x == it)) {
                personIds.push(it);
            }
        });

        personsActionSelected.forEach((it) => {
            if (!personIds.find((x) => x == it)) {
                personIds.push(it);
            }
        });

        this.setState({ loadingPrint: true });
        this.props.onAdminPerson
            .generateProfileReports({
                reportRequests: personIds.map((personId) => {
                    return {
                        personId,
                        selfAssessedReport: personsSkillProfileSelected.find((it) => it == personId) ? true : false,
                        endorsedReport: personsEndorsedSelected.find((it) => it == personId) ? true : false,
                        actionReport: personsActionSelected.find((it) => it == personId) ? true : false,
                    };
                }),
            })
            .then(() => {
                if (!this.props.loadingPrint) {
                    if (this.props.printData) {
                        FileDownload(this.props.printData, `SkillsTx_Persons_Profiles.zip`);
                        this.handleClearSelection();
                        this.handleRefresh();
                    }
                }

                this.setState({ loadingPrint: this.props.loadingPrint, modeGenerateReports: false });
            });
    };

    handleCancelGenerateReport = () => {
        this.handleClearSelection();
        this.setState({ modeGenerateReports: false });
    };

    handleToggleAssignedToMe = () => {
        const filterAssignedToMe = this.state.filterAssignedToMe;
        let badgeRequests = this.reformBadgeRequests(this.props.badgeRequests);

        if (!filterAssignedToMe == true) {
            badgeRequests = badgeRequests.filter((it) => it.assessorId == this.props.personId);
        }

        badgeRequests.forEach((it) => {
            it.toggleSkillProfileSelected = !it.selfAssessedReportGenerated && it.percentage >= 1;
            it.toggleEndorsedSelected = !it.endorsedReportGenerated && it.hasEndorseProfile;
            it.toggleActionSelected = !it.actionReportGenerated && it.hasAction;
        });

        this.setState({
            filterAssignedToMe: !filterAssignedToMe,
            badgeRequests: [...badgeRequests],
            personsSkillProfileSelected: badgeRequests.filter((it) => it.toggleSkillProfileSelected).map((row) => row.personId),
            personsEndorsedSelected: badgeRequests.filter((it) => it.toggleEndorsedSelected).map((row) => row.personId),
            personsActionSelected: badgeRequests.filter((it) => it.toggleActionSelected).map((row) => row.personId),
        });
    };

    onTableFilterChange = (e, searchType) => {
        let columnSearchKeywords = this.state.columnSearchKeywords;
        if (!columnSearchKeywords) {
            columnSearchKeywords = [];
        }

        if (columnSearchKeywords.find((it) => it.name == e.target.name)) {
            let columnSearchKeyword = columnSearchKeywords.find((it) => it.name == e.target.name);
            columnSearchKeyword.value = e.target.value;
        } else {
            columnSearchKeywords.push({
                name: e.target.name,
                value: e.target.value,
                searchType,
            });
        }

        this.setState({ columnSearchKeywords });
    };

    submitCertificateProfile = (badgeRequest) => {
        if (window.confirm(`Are you sure?`)) {
            this.setState({ isUpdating: true });
            this.setState({ showLoadingPanel: true, loadingPanelHeader: "Submit certificate", loadingPanelBody: "Please wait ..." });
            this.props.onAdminBadgeRequest.submitBadgeRequestCertification(badgeRequest.id).then(() => {
                if (!this.props.badgeRequestUpdating) {
                    if (this.props.badgeRequestError) {
                        this.generateAlert("danger", this.props.badgeRequestError.errData.Message);
                    } else {
                        this.generateAlert("success", "Certification badges submitted.");
                    }
                }

                this.setState({ isUpdating: this.props.badgeRequestUpdating, showLoadingPanel: this.props.badgeRequestUpdating });
            });
        }
    };

    handleTogleSubmitCertificate = () => {
        const modeSubmitCertificate = this.state.modeSubmitCertificate;

        if (modeSubmitCertificate) {
            this.handleClearSelection();
        }

        this.setState({
            modeSubmitCertificate: !modeSubmitCertificate,
        });
    };

    handleRowSubmitCertificateClick = (row) => {
        if (!this.state.loadingPrint) {
            const badgeRequests = this.state.badgeRequests;
            const updatedData = badgeRequests.find((it) => it.id == row.id);
            updatedData.toggleCertificateSelected = !updatedData.toggleCertificateSelected;

            this.setState({ badgeRequests: [...badgeRequests], personsSubmitCertificateSelected: badgeRequests.filter((it) => it.toggleCertificateSelected).map((row) => row.id) });
        }
    };

    handleSelectSubmitCertificateAll = () => {
        const { personId } = this.props;
        const { badgeRequests, searchKeyword, searchProperties, columnSearchKeywords } = this.state;
        let selectAll = false;

        const filtered = this.filterArray(badgeRequests, searchKeyword, searchProperties, columnSearchKeywords);

        if (filtered.filter((it) => it.toggleCertificateSelected && it.status == "Approved" && it.certificationSubmitted != true && it.assessorId && personId == it.assessorId).length == 0) {
            selectAll = true;
        } else if (filtered.filter((it) => !it.toggleCertificateSelected && it.status == "Approved" && it.certificationSubmitted != true && it.assessorId && personId == it.assessorId).length == 0) {
            selectAll = false;
        } else {
            selectAll = true;
        }

        badgeRequests.forEach((it) => {
            it.toggleCertificateSelected = false;
        });

        if (selectAll) {
            badgeRequests
                .filter((it) => it.status == "Approved" && it.certificationSubmitted != true && it.assessorId && personId == it.assessorId)
                .forEach((it) => {
                    if (filtered.find((f) => f.id == it.id)) {
                        it.toggleCertificateSelected = true;
                    }
                });
        }

        this.setState({ badgeRequests: [...badgeRequests], personsSubmitCertificateSelected: badgeRequests.filter((it) => it.toggleCertificateSelected).map((row) => row.id) });
    };

    handleConfirmSubmitCertificates = () => {
        const { personsSubmitCertificateSelected } = this.state;

        this.setState({ isUpdating: true });
        this.setState({ showLoadingPanel: true, loadingPanelHeader: "Submit certificates", loadingPanelBody: "Please wait ..." });
        this.props.onAdminBadgeRequest.submitBadgeRequestCertifications(personsSubmitCertificateSelected).then(() => {
            if (!this.props.badgeRequestUpdating) {
                if (this.props.badgeRequestError) {
                    this.generateAlert("danger", this.props.badgeRequestError.errData.Message);
                } else {
                    this.generateAlert("success", "Certification badges submitted.");
                    this.handleTogleSubmitCertificate();
                }
            }

            this.setState({ isUpdating: this.props.badgeRequestUpdating, showLoadingPanel: this.props.badgeRequestUpdating });
        });
    };

    renderLoadingPanel() {
        if (this.state.showLoadingPanel) {
            return (
                <Modal isOpen={this.state.showLoadingPanel}>
                    <ModalHeader>{this.state.loadingPanelHeader}</ModalHeader>
                    <ModalBody>
                        <Spinner size="md" color="primary" /> {this.state.loadingPanelBody}
                    </ModalBody>
                </Modal>
            );
        } else {
            return "";
        }
    }

    rejectBadgeRequest = (request) => {
        this.setState({
            selectedRequestToReject: request,
            updateBadgeRequest: false,
            showRejectBadgeConfirmation: true,
        });
    };

    togleRejectBadgesConfirmation = () => {
        const showRejectBadgeConfirmation = this.state.showRejectBadgeConfirmation;
        this.setState({ showRejectBadgeConfirmation: !showRejectBadgeConfirmation });
    };

    handleRejectBadgeRequest = () => {
        const selectedRequestToReject = this.state.selectedRequestToReject;
        this.setState({ isUpdating: true });
        this.props.onAdminBadgeRequest.rejectBadgeRequestCertification(selectedRequestToReject.id).then(() => {
            if (!this.props.badgeRequestUpdating) {
                if (this.props.badgeRequestError) {
                    this.generateAlert("danger", this.props.badgeRequestError.errData.Message);
                } else {
                    this.generateAlert("success", "Badges request rejected.");
                    this.togleRejectBadgesConfirmation();
                    this.props.onConfig.getContainerConfigWithoutLoading().then(() => {});
                }
            }

            this.setState({ isUpdating: this.props.badgeRequestUpdating });
        });
    };

    renderRejectBadgesConfirmation = () => {
        return (
            <Modal isOpen={this.state.showRejectBadgeConfirmation} toggle={!this.state.isUpdating && this.togleRejectBadgesConfirmation}>
                <ModalHeader toggle={!this.state.isUpdating && this.togleRejectBadgesConfirmation}>Reject badge request confirmation</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        Are you sure you want to Reject this request?
                                        <br />
                                        If YES, then you must notify the requester directly with the reason.
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleRejectBadgeRequest();
                        }}
                        disabled={this.state.isUpdating}
                    >
                        Ok
                    </Button>
                    <Button color="secondary" onClick={this.togleRejectBadgesConfirmation} disabled={this.state.isUpdating}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    render() {
        const { personId, containerConfig } = this.props;
        const { badgeRequests, persons, searchKeyword, searchProperties, modeGenerateReports, modeSubmitCertificate, filterAssignedToMe, columnSearchKeywords } = this.state;
        const pageAssignments = this.props.pageAssignments;
        let pageAssignment = null;

        if (pageAssignments.find((it) => it.pageName == "Manage SFIA Credentials and Reports")) {
            pageAssignment = pageAssignments.find((it) => it.pageName == "Manage SFIA Credentials and Reports");
        }

        // const editProfile = this.editProfile;
        const certifyProfile = this.certifyProfile;
        const rejectBadgeRequest = this.rejectBadgeRequest;
        const submitCertificateProfile = this.submitCertificateProfile;
        const generateAlert = this.generateAlert;
        const handleSelectSkillProfileAll = this.handleSelectSkillProfileAll;
        const handleRowSkillProfileClick = this.handleRowSkillProfileClick;

        const handleSelectEndorsedAll = this.handleSelectEndorsedAll;
        const handleRowEndorsedClick = this.handleRowEndorsedClick;

        const handleSelectActionAll = this.handleSelectActionAll;
        const handleRowActionClick = this.handleRowActionClick;

        const handleSelectSubmitCertificateAll = this.handleSelectSubmitCertificateAll;
        const handleRowSubmitCertificateClick = this.handleRowSubmitCertificateClick;

        const onTableFilterChange = this.onTableFilterChange;
        const loadingPrint = this.state.loadingPrint;

        const selectableRows = false;
        const filtered = this.filterArray(badgeRequests, searchKeyword, searchProperties, columnSearchKeywords);

        // const allowEdit = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? true : false;
        const allowEdit = false;
        const allowApprove = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Approve" && op.allow == true) ? true : false;
        const allowSubmitCertificate = containerConfig && containerConfig.badgeProvider == "DSA" && pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Approve" && op.allow == true) ? true : false;
        const allowReject = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Reject" && op.allow == true) ? true : false;
        const allowAssignAssessor = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Assign Assessor" && op.allow == true) ? true : false;
        const allowGenerateReports = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Generate Reports" && op.allow == true) ? true : false;

        const lastSubmittedSort = (rowA, rowB) => {
            let lastUpdatedA = rowA.submitDate ? rowA.submitDate.toString().replaceAll("-", "").replaceAll(":", "").replace("T", "") : "";
            let lastUpdatedB = rowB.submitDate ? rowB.submitDate.toString().replaceAll("-", "").replaceAll(":", "").replace("T", "") : "";

            if (lastUpdatedA > lastUpdatedB) {
                return 1;
            }

            if (lastUpdatedB > lastUpdatedA) {
                return -1;
            }

            return 0;
        };

        const lastAssessedSort = (rowA, rowB) => {
            let lastReviewedA = rowA.assessedDate ? rowA.assessedDate.toString().replaceAll("-", "").replaceAll(":", "").replace("T", "") : "";
            let lastReviewedB = rowB.assessedDate ? rowB.assessedDate.toString().replaceAll("-", "").replaceAll(":", "").replace("T", "") : "";

            if (lastReviewedA > lastReviewedB) {
                return 1;
            }

            if (lastReviewedB > lastReviewedA) {
                return -1;
            }

            return 0;
        };

        const columns = [
            {
                id: "idSkillProfile",
                name: (
                    <div style={{ textAlign: "center" }}>
                        <span id="headerSkillProfiles" style={{ fontWeight: "bold" }}>
                            S
                        </span>
                        <UncontrolledTooltip target={"headerSkillProfiles"} placement="bottom">
                            Self-assessed
                        </UncontrolledTooltip>
                        <br />
                        <FontAwesomeIcon
                            id={`headerChkSelectAllSkillProfile`}
                            icon={filtered.filter((it) => it.toggleSkillProfileSelected && it.percentage >= 1).length == 0 ? faSquare : filtered.filter((it) => !it.toggleSkillProfileSelected && it.percentage >= 1).length == 0 ? faSquareCheck : faSquareMinus}
                            style={{ cursor: "pointer", color: filtered.filter((it) => it.toggleSkillProfileSelected && it.percentage >= 1).length == 0 ? "#8f8f9d" : "#007bff" }}
                            size="lg"
                            onClick={() => !loadingPrint && handleSelectSkillProfileAll()}
                        />
                    </div>
                ),
                selector: (row) => row.id,
                sortable: false,
                width: "3%",
                center: true,
                format: function (row, index) {
                    return <div>{row.percentage >= 1 && <Input type="checkbox" id={`chkRowSkillProfile${row.id}`} checked={row.toggleSkillProfileSelected} disabled={loadingPrint} onClick={(e) => handleRowSkillProfileClick(row)} />}</div>;
                },
                omit: !modeGenerateReports,
            },
            {
                id: "idEndorsedProfile",
                name: (
                    <div style={{ textAlign: "center" }}>
                        <span id="headerEndorsedProfiles" style={{ fontWeight: "bold" }}>
                            E
                        </span>
                        <UncontrolledTooltip target={"headerEndorsedProfiles"} placement="bottom">
                            Endorsed
                        </UncontrolledTooltip>
                        <br />
                        <FontAwesomeIcon
                            id={`headerChkSelectAllEndorsedProfile`}
                            icon={filtered.filter((it) => it.toggleEndorsedSelected && it.hasEndorseProfile).length == 0 ? faSquare : filtered.filter((it) => !it.toggleEndorsedSelected && it.hasEndorseProfile).length == 0 ? faSquareCheck : faSquareMinus}
                            style={{ cursor: "pointer", color: filtered.filter((it) => it.toggleEndorsedSelected && it.hasEndorseProfile).length == 0 ? "#8f8f9d" : "#007bff" }}
                            size="lg"
                            onClick={() => !loadingPrint && handleSelectEndorsedAll()}
                        />
                    </div>
                ),
                selector: (row) => row.id,
                sortable: false,
                width: "3%",
                center: true,
                format: function (row, index) {
                    return <div>{row.hasEndorseProfile && <Input type="checkbox" id={`chkRowEndorsed${row.id}`} checked={row.toggleEndorsedSelected} disabled={loadingPrint} onClick={(e) => handleRowEndorsedClick(row)} />}</div>;
                },
                omit: !modeGenerateReports,
            },
            {
                id: "idActionPlan",
                name: (
                    <div style={{ textAlign: "center" }}>
                        <span id="headerActionPlans" style={{ fontWeight: "bold" }}>
                            A
                        </span>
                        <UncontrolledTooltip target={"headerActionPlans"} placement="bottom">
                            Action Plan
                        </UncontrolledTooltip>
                        <br />
                        <FontAwesomeIcon
                            id={`headerChkSelectAllAction`}
                            icon={filtered.filter((it) => it.toggleActionSelected && it.hasAction).length == 0 ? faSquare : filtered.filter((it) => !it.toggleActionSelected && it.hasAction).length == 0 ? faSquareCheck : faSquareMinus}
                            style={{ cursor: "pointer", color: filtered.filter((it) => it.toggleActionSelected && it.hasAction).length == 0 ? "#8f8f9d" : "#007bff" }}
                            size="lg"
                            onClick={() => !loadingPrint && handleSelectActionAll()}
                        />
                    </div>
                ),
                selector: (row) => row.id,
                sortable: false,
                width: "3%",
                center: true,
                format: function (row, index) {
                    return <div>{row.hasAction && <Input type="checkbox" id={`chkRowAction${row.id}`} checked={row.toggleActionSelected} disabled={loadingPrint} onClick={(e) => handleRowActionClick(row)} />}</div>;
                },
                omit: !modeGenerateReports,
            },
            {
                id: "certificationSubmitted",
                name: (
                    <div style={{ textAlign: "center" }}>
                        <FontAwesomeIcon
                            id={`headerChkSelectAllSubmitCertificate`}
                            icon={
                                filtered.filter((it) => it.status == "Approved" && it.certificationSubmitted != true && it.assessorId && personId == it.assessorId && it.toggleCertificateSelected).length == 0
                                    ? faSquare
                                    : filtered.filter((it) => it.status == "Approved" && it.certificationSubmitted != true && it.assessorId && personId == it.assessorId && !it.toggleCertificateSelected).length == 0
                                    ? faSquareCheck
                                    : faSquareMinus
                            }
                            style={{ cursor: "pointer", color: filtered.filter((it) => it.status == "Approved" && it.certificationSubmitted != true && it.assessorId && personId == it.assessorId && it.toggleCertificateSelected).length == 0 ? "#8f8f9d" : "#007bff" }}
                            size="lg"
                            onClick={() => handleSelectSubmitCertificateAll()}
                        />
                    </div>
                ),
                selector: (row) => row.id,
                sortable: false,
                width: "5%",
                center: true,
                format: function (row, index) {
                    return (
                        <div>
                            {row.status == "Approved" && row.certificationSubmitted != true && row.assessorId && personId == row.assessorId && (
                                <Input type="checkbox" id={`chkRowSubmitCertificate${row.id}`} checked={row.toggleCertificateSelected} disabled={loadingPrint} onClick={(e) => handleRowSubmitCertificateClick(row)} />
                            )}
                        </div>
                    );
                },
                omit: !modeSubmitCertificate,
            },
            {
                id: "id",
                name: "Action",
                selector: (row) => row.id,
                sortable: true,
                width: "6%",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            {/* {allowEdit && (
                                <React.Fragment>
                                    <FontAwesomeIcon id={`iconEditProfile${row.id}`} icon={faPenToSquare} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => editProfile(row)} />
                                    <UncontrolledTooltip target={`iconEditProfile${row.id}`} placement="bottom">
                                        Edit
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            )} */}
                            {allowApprove && (row.status == "Not Assessed" || (row.status == "Assessed" && !row.certificationSubmitted)) && row.assessorId && personId == row.assessorId && (
                                <React.Fragment>
                                    <FontAwesomeIcon id={`iconApproveProfile${row.id}`} icon={faClipboardCheck} size="lg" style={{ cursor: "pointer", color: "#0056b3", paddingLeft: "5px", paddingRight: "5px" }} onClick={() => certifyProfile(row)} />
                                    <UncontrolledTooltip target={`iconApproveProfile${row.id}`} placement="bottom">
                                        Certify
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            )}
                            {allowReject && row.status != "Approved" && (
                                <React.Fragment>
                                    <FontAwesomeIcon id={`iconRejectProfile${row.id}`} icon={faCircleXmark} size="lg" style={{ cursor: "pointer", color: "#FF0000", paddingLeft: "5px", paddingRight: "5px" }} onClick={() => rejectBadgeRequest(row)} />
                                    <UncontrolledTooltip target={`iconRejectProfile${row.id}`} placement="bottom">
                                        Reject
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            )}
                            {allowSubmitCertificate && row.status == "Approved" && !row.certificationSubmitted && row.assessorId && personId == row.assessorId && (
                                <React.Fragment>
                                    <FontAwesomeIcon id={`iconCertifyProfile${row.id}`} icon={faCertificate} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => submitCertificateProfile(row)} />
                                    <UncontrolledTooltip target={`iconCertifyProfile${row.id}`} placement="bottom">
                                        Submit certificate
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            )}
                        </div>
                    );
                },
                omit: !(allowEdit || allowApprove),
            },
            {
                id: "email",
                name: (
                    <div style={{ width: "90%" }}>
                        Person Name (email)
                        <br />
                        <input type="text" name="completeName" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "completeName") ? columnSearchKeywords.find((it) => it.name == "completeName").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.email,
                sortable: true,
                width: allowEdit || allowApprove ? (modeGenerateReports ? "27%" : modeSubmitCertificate ? "31%" : "36%") : modeGenerateReports ? "33%" : modeSubmitCertificate ? "37%" : "42%",
                center: false,
                format: function (row, index) {
                    return <span>{`${row.firstName} ${row.lastName} (${row.email})`}</span>;
                },
            },
            {
                id: "submitDate",
                name: "Request submitted",
                selector: (row) => row.submitDate,
                sortable: true,
                sortFunction: lastSubmittedSort,
                width: "15%",
                center: false,
                format: function (row, index) {
                    return (
                        <span>
                            {`${new Intl.DateTimeFormat("en-GB", {
                                year: "2-digit",
                                month: "short",
                                day: "numeric",
                            }).format(new Date(row.submitDate))}`}
                        </span>
                    );
                },
            },
            {
                id: "status",
                name: (
                    <div style={{ width: "90%" }}>
                        Status
                        <br />
                        <input type="text" name="status" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "status") ? columnSearchKeywords.find((it) => it.name == "status").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.status,
                sortable: true,
                width: "13%",
                center: false,
                wrap: true,
                format: function (row, index) {
                    return row.status;
                },
            },
            {
                id: "assessorName",
                name: (
                    <div style={{ width: "90%" }}>
                        Assessor Name
                        <br />
                        <input type="text" name="assessorName" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "assessorName") ? columnSearchKeywords.find((it) => it.name == "assessorName").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.assessorName,
                sortable: true,
                width: "15%",
                center: false,
                wrap: true,
                format: function (row, index) {
                    let adminPersons = [...persons.filter((it) => it.roles.some((it) => it.role == "Admin"))];

                    return allowAssignAssessor && row.status != "Assessed" && row.status != "Approved" ? (
                        <BadgeRequestAssessorCell key={`LKEndorserCurrent${row.personId} `} personId={row.personId} persons={adminPersons} badgeRequest={row} assessorId={row.assessorId} assessorName={row.assessorName} allowEdit={true}></BadgeRequestAssessorCell>
                    ) : (
                        row.assessorName
                    );
                    //return row.badgeRequestLastAssessedBy;
                },
            },
            {
                id: "assessedDate",
                name: "Last Assessed date",
                selector: (row) => row.assessedDate,
                sortable: true,
                sortFunction: lastAssessedSort,
                width: "15%",
                center: false,
                wrap: true,
                format: function (row, index) {
                    if (row.assessedDate) {
                        return (
                            <span>
                                {`${new Intl.DateTimeFormat("en-GB", {
                                    year: "2-digit",
                                    month: "short",
                                    day: "numeric",
                                }).format(new Date(row.assessedDate))}`}
                            </span>
                        );
                    } else {
                        return "";
                    }
                },
            },
        ];

        return (
            <React.Fragment>
                {this.renderLoadingPanel()}
                {this.renderRejectBadgesConfirmation()}
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <div style={{ marginBottom: "10px" }}>
                            {this.props.containerConfig && (
                                <Row>
                                    <Col md="12" style={{ paddingBottom: "10px" }}>
                                        {this.props.containerConfig.certificationCredit && this.props.containerConfig.certificationCredit >= 10 ? (
                                            <span>{`You have ${this.props.containerConfig.certificationCredit} certify credits available`}</span>
                                        ) : this.props.containerConfig.certificationCredit && this.props.containerConfig.certificationCredit > 0 ? (
                                            <span style={{ color: "#FF0000" }}>
                                                {`You have ONLY ${this.props.containerConfig.certificationCredit} certify credits available.`}
                                                <br />
                                                To purchase more credits contact certify@skillstx.com, your SkillsTX relationship manager or SkillsTX Partner
                                            </span>
                                        ) : (
                                            <span style={{ color: "#FF0000" }}>
                                                Your credits have been exhausted, no more Certify requests can be accepted but you can process the requests that are outstanding.
                                                <br />
                                                To purchase more credits contact certify@skillstx.com, your SkillsTX relationship manager or Skills TX Partner
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col md="5" sm="6">
                                    <SkillsTxTableSearchBar
                                        searchKeyword={this.state.searchKeyword}
                                        onChange={(searchKeyword) => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword, personsSkillProfileSelected: [] });
                                        }}
                                    ></SkillsTxTableSearchBar>
                                </Col>
                                <Col md="7" sm="6">
                                    <SkillsTxTableClearSearch
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        color="primary"
                                        disabled={this.props.personsLoading || this.state.isUpdating}
                                        onClear={() => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword: "", personsSkillProfileSelected: [] });
                                        }}
                                    ></SkillsTxTableClearSearch>
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        onClick={this.handleRefresh}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        disabled={this.props.personsLoading || this.state.isUpdating}
                                    >
                                        <FontAwesomeIcon icon={faSync} /> Refresh
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        onClick={this.handleToggleAssignedToMe}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        disabled={this.props.personsLoading || this.state.isUpdating}
                                    >
                                        {filterAssignedToMe ? "Show all" : "Assigned to me"}
                                    </Button>
                                    {allowGenerateReports && (
                                        <React.Fragment>
                                            &nbsp;&nbsp;
                                            <Button
                                                color="primary"
                                                onClick={this.handleGenerateReport}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.personsLoading || this.state.isUpdating || modeGenerateReports || modeSubmitCertificate || this.state.loadingPrint}
                                            >
                                                <FontAwesomeIcon icon={faDownload} /> Generate reports
                                            </Button>
                                        </React.Fragment>
                                    )}
                                    {modeGenerateReports && (
                                        <React.Fragment>
                                            &nbsp;&nbsp;
                                            <Button
                                                id="btnOKGenerateReport"
                                                color="primary"
                                                onClick={this.handleConfirmGenerateReport}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.state.loadingPrint || (this.state.personsSkillProfileSelected.length == 0 && this.state.personsEndorsedSelected.length == 0 && this.state.personsActionSelected.length == 0)}
                                            >
                                                {!this.state.loadingPrint ? "OK" : <Spinner type="grow" size="md" color="light" />}
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                id="btnCancelGenerateReport"
                                                color="primary"
                                                onClick={this.handleCancelGenerateReport}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.state.loadingPrint}
                                            >
                                                Cancel
                                            </Button>
                                        </React.Fragment>
                                    )}
                                    {allowSubmitCertificate && (
                                        <React.Fragment>
                                            &nbsp;&nbsp;
                                            <Button
                                                color="primary"
                                                onClick={this.handleTogleSubmitCertificate}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.personsLoading || this.state.isUpdating || modeGenerateReports || modeSubmitCertificate || this.state.loadingPrint}
                                            >
                                                <FontAwesomeIcon icon={faCertificate} /> Submit Certificate
                                            </Button>
                                        </React.Fragment>
                                    )}
                                    {modeSubmitCertificate && (
                                        <React.Fragment>
                                            &nbsp;&nbsp;
                                            <Button
                                                id="btnOKSubmitCertificate"
                                                color="primary"
                                                onClick={this.handleConfirmSubmitCertificates}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.state.isUpdating || this.state.personsSubmitCertificateSelected.length == 0}
                                            >
                                                {!this.state.loadingPrint ? "OK" : <Spinner type="grow" size="md" color="light" />}
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                id="btnCancelSubmitCertificate"
                                                color="primary"
                                                onClick={this.handleTogleSubmitCertificate}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.state.isUpdating}
                                            >
                                                Cancel
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </div>
                        <SkillsTxTable
                            columns={columns}
                            data={this.state.badgeRequests}
                            pagination={true}
                            searchKeyword={searchKeyword}
                            searchProperties={searchProperties}
                            columnSearchKeywords={columnSearchKeywords}
                            selectableRows
                            selectableRowsHighlight={true}
                            handleRowClick={this.handleRowClickDummy}
                            progressPending={this.props.badgeRequestLoading}
                            headCellsStyle={{
                                style: {
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    border: "1px solid #dee2e6",
                                    whiteSpace: "normal",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                    paddingRight: "8px",
                                    paddingLeft: "8px",
                                },
                            }}
                            cellsStyle={{
                                style: {
                                    padding: "5px 10px",
                                    fontSize: "11px",
                                    border: "1px solid #dee2e6",
                                },
                            }}
                        ></SkillsTxTable>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        personId: state.auth.personId,
        email: state.auth.email,
        loginWithTeams: state.auth.loginWithTeams,

        personsLoading: state.adminPerson.loading,
        personsError: state.adminPerson.error,
        persons: state.adminPerson.persons,

        badgeRequestLoading: state.adminBadgeRequest.loading,
        badgeRequestError: state.adminBadgeRequest.error,
        badgeRequests: state.adminBadgeRequest.badgeRequests,
        isUpdating: state.adminBadgeRequest.isUpdating,

        badgeRequestUpdating: state.adminBadgeRequest.isUpdating,

        loadingPrint: state.adminPerson.loadingPrint,
        printData: state.adminPerson.printData,

        pageAssignments: state.adminPerson.pageAssignments,
        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onAdminPerson: bindActionCreators(adminPersonActions, dispatch),
        onAdminBadgeRequest: bindActionCreators(adminBadgeRequestActions, dispatch),
        onPerson: bindActionCreators(personActions, dispatch),
        onSkillProfile: bindActionCreators(skillProfileActions, dispatch),
        onConfig: bindActionCreators(configActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageSfiaCredentials));
