import ValueGroupAPI from "./../../common/api/service/ValueGroupService";
import * as CONSTANTS from "./../../common/constant";
import * as actionTypes from "./actionTypes";

export const getSeniorityOptionsStart = () => {
    return {
        type: actionTypes.GET_SENIORITY_VALUES_START,
    };
};

export const getSeniorityOptionsSuccess = (values) => {
    return {
        type: actionTypes.GET_SENIORITY_VALUES_SUCCESS,
        values,
    };
};

export const getSeniorityOptionsFail = () => {
    return {
        type: actionTypes.GET_SENIORITY_VALUES_FAIL,
    };
};

export const getSeniorityOptions = (languageId) => {
    return (dispatch) => {
        dispatch(getSeniorityOptionsStart());

        return ValueGroupAPI.getValuesByGroupName(CONSTANTS.OPTIONS_SENIORITY_GROUP_NAME, languageId)
            .then((res) => {
                dispatch(getSeniorityOptionsSuccess(res));
            })
            .catch((err) => {
                dispatch(getSeniorityOptionsFail(err));
            });
    };
};

export const getContractTypeOptionsStart = () => {
    return {
        type: actionTypes.GET_CONTRACT_TYPE_VALUES_START,
    };
};

export const getContractTypeOptionsSuccess = (values) => {
    return {
        type: actionTypes.GET_CONTRACT_TYPE_VALUES_SUCCESS,
        values,
    };
};

export const getContractTypeOptionsFail = () => {
    return {
        type: actionTypes.GET_CONTRACT_TYPE_VALUES_FAIL,
    };
};

export const getContractTypeOptions = (languageId) => {
    return (dispatch) => {
        dispatch(getContractTypeOptionsStart());

        return ValueGroupAPI.getValuesByGroupName(CONSTANTS.OPTIONS_CONTRACT_TYPE_GROUP_NAME, languageId)
            .then((res) => {
                dispatch(getContractTypeOptionsSuccess(res));
            })
            .catch((err) => {
                dispatch(getContractTypeOptionsFail(err));
            });
    };
};

export const getYearRangeJobOptionsStart = () => {
    return {
        type: actionTypes.GET_YEAR_RANGE_JOB_VALUES_START,
    };
};

export const getYearRangeJobOptionsSuccess = (values) => {
    return {
        type: actionTypes.GET_YEAR_RANGE_JOB_VALUES_SUCCESS,
        values,
    };
};

export const getYearRangeJobOptionsFail = () => {
    return {
        type: actionTypes.GET_YEAR_RANGE_JOB_VALUES_FAIL,
    };
};

export const getYearRangeJobOptions = (languageId) => {
    return (dispatch) => {
        dispatch(getYearRangeJobOptionsStart());

        return ValueGroupAPI.getValuesByGroupName(CONSTANTS.OPTIONS_YEAR_RANGE_JOB_GROUP_NAME, languageId)
            .then((res) => {
                dispatch(getYearRangeJobOptionsSuccess(res));
            })
            .catch((err) => {
                dispatch(getYearRangeJobOptionsFail(err));
            });
    };
};

export const getYearRangeOrgOptionsStart = () => {
    return {
        type: actionTypes.GET_YEAR_RANGE_ORG_VALUES_START,
    };
};

export const getYearRangeOrgOptionsSuccess = (values) => {
    return {
        type: actionTypes.GET_YEAR_RANGE_ORG_VALUES_SUCCESS,
        values,
    };
};

export const getYearRangeOrgOptionsFail = () => {
    return {
        type: actionTypes.GET_YEAR_RANGE_ORG_VALUES_FAIL,
    };
};

export const getYearRangeOrgOptions = (languageId) => {
    return (dispatch) => {
        dispatch(getYearRangeOrgOptionsStart());

        return ValueGroupAPI.getValuesByGroupName(CONSTANTS.OPTIONS_YEAR_RANGE_ORG_GROUP_NAME, languageId)
            .then((res) => {
                dispatch(getYearRangeOrgOptionsSuccess(res));
            })
            .catch((err) => {
                dispatch(getYearRangeOrgOptionsFail(err));
            });
    };
};
