import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Button, Container, Input } from "reactstrap";

export class SkillsTxTableClearSearch extends Component {
    static displayName = SkillsTxTableClearSearch.name;

    constructor(props) {
        super(props);

        this.state = {};
    }

    handleClear = () => {
        this.props.onClear();
    };

    render() {
        return (
            <Button color={this.props.color} onClick={this.handleClear} style={this.props.style} disabled={this.props.disabled}>
                Clear
            </Button>
        );
    }
}
