import AuthAPI from "./../../common/api/service/AuthService";
import getTimeIn from "./../../utils/getTimeIn";
import * as actionTypes from "./actionTypes";

export const authRegisterEmailCheckingStart = () => {
    return {
        type: actionTypes.AUTH_REGISTER_EMAIL_CHECKING_START,
    };
};

export const authRegisterEmailCheckingSuccess = (emailCheckingExist) => {
    return {
        type: actionTypes.AUTH_REGISTER_EMAIL_CHECKING_SUCCESS,
        emailCheckingExist,
    };
};

export const authRegisterEmailCheckingFail = (error) => {
    return {
        type: actionTypes.AUTH_REGISTER_EMAIL_CHECKING_FAIL,
        error: error,
    };
};

export const authRegisterEmailChecking = (email) => {
    return (dispatch) => {
        dispatch(authRegisterEmailCheckingStart());

        return AuthAPI.registerEmailChecking(email)
            .then((res) => {
                dispatch(authRegisterEmailCheckingSuccess(res.emailCheckingExist));
            })
            .catch((err) => {
                dispatch(authRegisterEmailCheckingFail(err));
            });
    };
};

export const authRegisterStart = () => {
    return {
        type: actionTypes.AUTH_REGISTER_START,
    };
};

export const authRegisterSuccess = (token) => {
    return {
        type: actionTypes.AUTH_REGISTER_SUCCESS,
        token,
    };
};

export const authRegisterFail = (error) => {
    return {
        type: actionTypes.AUTH_REGISTER_FAIL,
        error: error,
    };
};

export const authRegister = (person) => {
    return (dispatch) => {
        dispatch(authRegisterStart());

        return AuthAPI.register(person)
            .then((res) => {
                if (res.token) {
                    dispatch(authRegisterSuccess(res.token));
                }
            })
            .catch((err) => {
                dispatch(authRegisterFail(err));
            });
    };
};

export const signInStart = () => {
    return {
        type: actionTypes.SIGN_IN_START,
    };
};

export const signInSuccess = (
    token,
    email,
    assessmentId,
    sfiaVersion,
    jobProfileReportFormat,
    selfAssessedProfileReportFormat,
    hasPeoples,
    isEndorser,
    authMode,
    personId,
    ownJobs,
    manageEndorsements,
    manageCertifications,
    loginWithMembee,
    loginWithTeams,
    receiveLicenseNotification,
    lastActiveUserNotification,
    allowSkipSurvey,
    manageSelfAssessed,
    manageSelfProfiles,
    hasRelationships
) => {
    return {
        type: actionTypes.SIGN_IN_SUCCESS,
        token,
        email,
        assessmentId,
        sfiaVersion,
        jobProfileReportFormat,
        selfAssessedProfileReportFormat,
        hasPeoples,
        isEndorser,
        authMode,
        personId,
        ownJobs,
        manageEndorsements,
        manageCertifications,
        loginWithMembee,
        loginWithTeams,
        receiveLicenseNotification,
        lastActiveUserNotification,
        allowSkipSurvey,
        manageSelfAssessed,
        manageSelfProfiles,
        hasRelationships,
    };
};

export const signInSuccessWithWait = (
    token,
    email,
    assessmentId,
    sfiaVersion,
    jobProfileReportFormat,
    selfAssessedProfileReportFormat,
    hasPeoples,
    isEndorser,
    authMode,
    personId,
    ownJobs,
    manageEndorsements,
    manageCertifications,
    loginWithMembee,
    loginWithTeams,
    receiveLicenseNotification,
    lastActiveUserNotification,
    managedPeople,
    allowSkipSurvey,
    manageSelfAssessed,
    manageSelfProfiles,
    hasRelationships
) => {
    return {
        type: actionTypes.SIGN_IN_SUCCESS_WITH_WAIT,
        token,
        email,
        assessmentId,
        sfiaVersion,
        jobProfileReportFormat,
        selfAssessedProfileReportFormat,
        hasPeoples,
        isEndorser,
        authMode,
        personId,
        ownJobs,
        manageEndorsements,
        manageCertifications,
        loginWithMembee,
        loginWithTeams,
        receiveLicenseNotification,
        lastActiveUserNotification,
        managedPeople,
        allowSkipSurvey,
        manageSelfAssessed,
        manageSelfProfiles,
        hasRelationships,
    };
};

export const signInFail = (error) => {
    return {
        type: actionTypes.SIGN_IN_FAIL,
        error: error,
    };
};

export const signIn = (email, password) => {
    return (dispatch) => {
        dispatch(signInStart());

        return AuthAPI.signin(email, password)
            .then((res) => {
                if (res.token) {
                    localStorage.setItem("expiresIn", res.expiresIn * 1000);
                    localStorage.setItem("email", res.email);
                    localStorage.setItem("personId", res.personId);
                    localStorage.setItem("firstName", res.firstName);
                    localStorage.setItem("lastName", res.lastName);
                    localStorage.setItem("authMode", res.authMode);

                    dispatch(
                        signInSuccessWithWait(
                            res,
                            res.email,
                            res.assessmentId,
                            res.sfiaVersion,
                            res.jobProfileReportFormat,
                            res.selfAssessedProfileReportFormat,
                            res.hasPeoples,
                            res.isEndorser,
                            res.authMode,
                            res.personId,
                            res.ownJobs,
                            res.manageEndorsements,
                            res.manageCertifications,
                            res.loginWithMembee,
                            res.loginWithTeams,
                            res.receiveLicenseNotification,
                            res.lastActiveUserNotification,
                            null,
                            res.allowSkipSurvey,
                            res.manageSelfAssessed,
                            res.manageSelfProfiles,
                            res.hasRelationships
                        )
                    );
                } else {
                    dispatch(signInFail(res));
                }
            })
            .catch((err) => {
                dispatch(signInFail(err));
            });
    };
};

export const signInUseToken = (token) => {
    return (dispatch) => {
        dispatch(signInStart());

        return AuthAPI.signinUseToken(token)
            .then((res) => {
                if (res.token) {
                    localStorage.setItem("expiresIn", res.expiresIn * 1000);
                    localStorage.setItem("email", res.email);
                    localStorage.setItem("personId", res.personId);
                    localStorage.setItem("firstName", res.firstName);
                    localStorage.setItem("lastName", res.lastName);
                    localStorage.setItem("authMode", res.authMode);

                    dispatch(
                        signInSuccessWithWait(
                            res,
                            res.email,
                            res.assessmentId,
                            res.sfiaVersion,
                            res.jobProfileReportFormat,
                            res.selfAssessedProfileReportFormat,
                            res.hasPeoples,
                            res.isEndorser,
                            res.authMode,
                            res.personId,
                            res.ownJobs,
                            res.manageEndorsements,
                            res.manageCertifications,
                            res.loginWithMembee,
                            res.loginWithTeams,
                            res.receiveLicenseNotification,
                            res.lastActiveUserNotification,
                            null,
                            res.allowSkipSurvey,
                            res.manageSelfAssessed,
                            res.manageSelfProfiles,
                            res.hasRelationships
                        )
                    );
                } else {
                    dispatch(signInFail(res));
                }
            })
            .catch((err) => {
                dispatch(signInFail(err));
            });
    };
};

export const signInUseOneTimeLoginToken = (token) => {
    return (dispatch) => {
        dispatch(signInStart());

        return AuthAPI.signinUseOneTimeLoginToken(token)
            .then((res) => {
                if (res.token) {
                    localStorage.setItem("expiresIn", res.expiresIn * 1000);
                    localStorage.setItem("email", res.email);
                    localStorage.setItem("personId", res.personId);
                    localStorage.setItem("firstName", res.firstName);
                    localStorage.setItem("lastName", res.lastName);
                    localStorage.setItem("authMode", res.authMode);

                    dispatch(
                        signInSuccessWithWait(
                            res,
                            res.email,
                            res.assessmentId,
                            res.sfiaVersion,
                            res.jobProfileReportFormat,
                            res.selfAssessedProfileReportFormat,
                            res.hasPeoples,
                            res.isEndorser,
                            res.authMode,
                            res.personId,
                            res.ownJobs,
                            res.manageEndorsements,
                            res.manageCertifications,
                            res.loginWithMembee,
                            res.loginWithTeams,
                            res.receiveLicenseNotification,
                            res.lastActiveUserNotification,
                            null,
                            res.allowSkipSurvey,
                            res.manageSelfAssessed,
                            res.manageSelfProfiles,
                            res.hasRelationships
                        )
                    );
                } else {
                    dispatch(signInFail(res));
                }
            })
            .catch((err) => {
                dispatch(signInFail(err));
            });
    };
};

export const authLogoutStart = () => {
    return {
        type: actionTypes.AUTH_LOGOUT_START,
    };
};

export const authLogoutSuccess = () => {
    return {
        type: actionTypes.AUTH_LOGOUT_SUCCESS,
    };
};

export const authLogoutIntendedSuccess = () => {
    return {
        type: actionTypes.AUTH_LOGOUT_INTENDED_SUCCESS,
    };
};

export const authLogoutFail = (error) => {
    return {
        type: actionTypes.AUTH_LOGOUT_FAIL,
        error: error,
    };
};

export const authLogout = () => {
    return (dispatch) => {
        dispatch(authLogoutStart());
        return AuthAPI.logOut()
            .then((res) => {
                dispatch(authLogoutSuccess());
            })
            .catch((err) => {
                dispatch(authLogoutFail(err));
            });
    };
};

export const authLogoutIntended = () => {
    localStorage.setItem("redirectUrl", "");
    localStorage.setItem("isLogout", "TRUE");

    return (dispatch) => {
        dispatch(authLogoutStart());
        return AuthAPI.logOutIntended()
            .then((res) => {
                dispatch(authLogoutIntendedSuccess());
            })
            .catch((err) => {
                dispatch(authLogoutFail(err));
            });
    };
};

export const checkLoginCookiesState = () => {
    return async (dispatch) => {
        dispatch(signInStart());
        try {
            const res = await AuthAPI.refreshToken();
            if (res.token) {
                // Fullfill new token payload
                localStorage.setItem("expiresIn", res.expiresIn * 1000);
                localStorage.setItem("email", res.email);
                localStorage.setItem("personId", res.personId);
                localStorage.setItem("firstName", res.firstName);
                localStorage.setItem("lastName", res.lastName);
                localStorage.setItem("authMode", res.authMode);

                dispatch(
                    signInSuccess(
                        res.token,
                        res.email,
                        res.assessmentId,
                        res.sfiaVersion,
                        res.jobProfileReportFormat,
                        res.selfAssessedProfileReportFormat,
                        res.hasPeoples,
                        res.isEndorser,
                        res.authMode,
                        res.personId,
                        res.ownJobs,
                        res.manageEndorsements,
                        res.manageCertifications,
                        res.loginWithMembee,
                        res.loginWithTeams,
                        res.receiveLicenseNotification,
                        res.lastActiveUserNotification,
                        res.allowSkipSurvey,
                        res.manageSelfAssessed,
                        res.manageSelfProfiles,
                        res.hasRelationships
                    )
                );
            } else {
                dispatch(authLogout());
            }
        } catch (err) {
            dispatch(authLogout());
        }
    };
};

export const authCheckState = () => {
    return async (dispatch) => {
        dispatch(signInStart());
        const expiresIn = localStorage.getItem("expiresIn");
        if (expiresIn < getTimeIn()) {
            try {
                const res = await AuthAPI.refreshToken();
                if (res.token) {
                    // Fullfill new token payload
                    localStorage.setItem("expiresIn", res.expiresIn * 1000);
                    localStorage.setItem("email", res.email);
                    localStorage.setItem("personId", res.personId);
                    localStorage.setItem("firstName", res.firstName);
                    localStorage.setItem("lastName", res.lastName);
                    localStorage.setItem("authMode", res.authMode);

                    dispatch(
                        signInSuccess(
                            res.token,
                            res.email,
                            res.assessmentId,
                            res.sfiaVersion,
                            res.jobProfileReportFormat,
                            res.selfAssessedProfileReportFormat,
                            res.hasPeoples,
                            res.isEndorser,
                            res.authMode,
                            res.personId,
                            res.ownJobs,
                            res.manageEndorsements,
                            res.manageCertifications,
                            res.loginWithMembee,
                            res.loginWithTeams,
                            res.receiveLicenseNotification,
                            res.lastActiveUserNotification,
                            res.allowSkipSurvey,
                            res.manageSelfAssessed,
                            res.manageSelfProfiles,
                            res.hasRelationships
                        )
                    );
                } else {
                    dispatch(authLogout());
                }
            } catch (err) {
                dispatch(authLogout());
            }
        } else {
            dispatch(authCheckStateSuccess());
        }
    };
};

export const forceRefreshToken = () => {
    return async (dispatch) => {
        dispatch(signInStart());
        const res = await AuthAPI.refreshToken();
        if (res.token) {
            // Fullfill new token payload
            localStorage.setItem("expiresIn", res.expiresIn * 1000);
            localStorage.setItem("email", res.email);
            localStorage.setItem("personId", res.personId);
            localStorage.setItem("firstName", res.firstName);
            localStorage.setItem("lastName", res.lastName);
            localStorage.setItem("authMode", res.authMode);

            dispatch(
                signInSuccess(
                    res.token,
                    res.email,
                    res.assessmentId,
                    res.sfiaVersion,
                    res.jobProfileReportFormat,
                    res.selfAssessedProfileReportFormat,
                    res.hasPeoples,
                    res.isEndorser,
                    res.authMode,
                    res.personId,
                    res.ownJobs,
                    res.manageEndorsements,
                    res.manageCertifications,
                    res.loginWithMembee,
                    res.loginWithTeams,
                    res.receiveLicenseNotification,
                    res.lastActiveUserNotification,
                    res.allowSkipSurvey,
                    res.manageSelfAssessed,
                    res.manageSelfProfiles,
                    res.hasRelationships
                )
            );
        } else {
            dispatch(authLogout());
        }
    };
};

export const authCheckStateSuccess = () => {
    return {
        type: actionTypes.AUTH_CHECK_SUCCESS,
    };
};

export const forgotPasswordStart = () => {
    return {
        type: actionTypes.FORGOT_PASSWORD_START,
    };
};

export const forgotPasswordSuccess = () => {
    return {
        type: actionTypes.FORGOT_PASSWORD_SUCCESS,
    };
};

export const forgotPasswordFail = (error) => {
    return {
        type: actionTypes.FORGOT_PASSWORD_FAIL,
        error: error,
    };
};

export const forgotPassword = (email) => {
    return (dispatch) => {
        dispatch(forgotPasswordStart());

        return AuthAPI.forgotPassword(email)
            .then((res) => {
                dispatch(forgotPasswordSuccess());
            })
            .catch((err) => {
                dispatch(forgotPasswordFail(err));
            });
    };
};

export const createAccountStart = () => {
    return {
        type: actionTypes.CREATE_ACCOUNT_START,
    };
};

export const createAccountSuccess = () => {
    return {
        type: actionTypes.CREATE_ACCOUNT_SUCCESS,
    };
};

export const createAccountFail = (error) => {
    return {
        type: actionTypes.CREATE_ACCOUNT_FAIL,
        error: error,
    };
};

export const createAccount = (surveyRating) => {
    return (dispatch) => {
        dispatch(createAccountStart());

        return AuthAPI.createAccount(surveyRating)
            .then((res) => {
                dispatch(createAccountSuccess());
            })
            .catch((err) => {
                dispatch(createAccountFail(err));
            });
    };
};

export const submitRating = (surveyRating) => {
    return (dispatch) => {
        dispatch(createAccountStart());

        return AuthAPI.submitRating(surveyRating)
            .then((res) => {
                dispatch(createAccountSuccess());
            })
            .catch((err) => {
                dispatch(createAccountFail(err));
            });
    };
};

export const submitRatingTeams = (surveyRating) => {
    return (dispatch) => {
        dispatch(createAccountStart());

        return AuthAPI.submitRatingTeams(surveyRating)
            .then((res) => {
                dispatch(createAccountSuccess());
            })
            .catch((err) => {
                dispatch(createAccountFail(err));
            });
    };
};

export const getResetPasswordStart = () => {
    return {
        type: actionTypes.GET_RESET_PASSWORD_START,
    };
};

export const getResetPasswordSuccess = (resetPassword) => {
    return {
        type: actionTypes.GET_RESET_PASSWORD_SUCCESS,
        resetPassword,
    };
};

export const getResetPasswordFail = (error) => {
    return {
        type: actionTypes.GET_RESET_PASSWORD_FAIL,
        error: error,
    };
};

export const getResetPassword = (token) => {
    return (dispatch) => {
        dispatch(getResetPasswordStart());

        return AuthAPI.getResetPassword(token)
            .then((res) => {
                dispatch(getResetPasswordSuccess(res));
            })
            .catch((err) => {
                dispatch(getResetPasswordFail(err));
            });
    };
};

export const processResetPasswordStart = () => {
    return {
        type: actionTypes.PROCESS_RESET_PASSWORD_START,
    };
};

export const processResetPasswordSuccess = () => {
    return {
        type: actionTypes.PROCESS_RESET_PASSWORD_SUCCESS,
    };
};

export const processResetPasswordFail = (error) => {
    return {
        type: actionTypes.PROCESS_RESET_PASSWORD_FAIL,
        error: error,
    };
};

export const processResetPassword = (body) => {
    return (dispatch) => {
        dispatch(processResetPasswordStart());

        return AuthAPI.processResetPassword(body)
            .then((res) => {
                dispatch(processResetPasswordSuccess(res));
            })
            .catch((err) => {
                dispatch(processResetPasswordFail(err));
            });
    };
};

export const myPeopleSetManagedRelationship = (managedPeople) => {
    return (dispatch) => {
        dispatch(signInStart());

        return AuthAPI.signinwithdownline(managedPeople.personB.email)
            .then((res) => {
                if (res.token) {
                    localStorage.setItem("expiresIn", res.expiresIn * 1000);
                    localStorage.setItem("email", res.email);
                    localStorage.setItem("personId", res.personId);
                    localStorage.setItem("firstName", res.firstName);
                    localStorage.setItem("lastName", res.lastName);
                    localStorage.setItem("authMode", res.authMode);

                    dispatch(
                        signInSuccessWithWait(
                            res,
                            res.email,
                            res.assessmentId,
                            res.sfiaVersion,
                            res.jobProfileReportFormat,
                            res.selfAssessedProfileReportFormat,
                            res.hasPeoples,
                            res.isEndorser,
                            res.authMode,
                            res.personId,
                            res.ownJobs,
                            res.manageEndorsements,
                            res.manageCertifications,
                            res.loginWithMembee,
                            res.loginWithTeams,
                            res.receiveLicenseNotification,
                            res.lastActiveUserNotification,
                            managedPeople,
                            res.allowSkipSurvey,
                            res.manageSelfAssessed,
                            res.manageSelfProfiles,
                            res.hasRelationships
                        )
                    );
                } else {
                    dispatch(signInFail(res));
                }
            })
            .catch((err) => {
                dispatch(signInFail(err));
            });
    };
};

export const signinwithdownline = (downline) => {
    return (dispatch) => {
        dispatch(signInStart());

        return AuthAPI.signinwithdownline(downline)
            .then((res) => {
                if (res.token) {
                    localStorage.setItem("expiresIn", res.expiresIn * 1000);
                    localStorage.setItem("email", res.email);
                    localStorage.setItem("personId", res.personId);
                    localStorage.setItem("firstName", res.firstName);
                    localStorage.setItem("lastName", res.lastName);
                    localStorage.setItem("authMode", res.authMode);

                    dispatch(
                        signInSuccessWithWait(
                            res,
                            res.email,
                            res.assessmentId,
                            res.sfiaVersion,
                            res.jobProfileReportFormat,
                            res.selfAssessedProfileReportFormat,
                            res.hasPeoples,
                            res.isEndorser,
                            res.authMode,
                            res.personId,
                            res.ownJobs,
                            res.manageEndorsements,
                            res.manageCertifications,
                            res.loginWithMembee,
                            res.loginWithTeams,
                            res.receiveLicenseNotification,
                            res.lastActiveUserNotification,
                            managedPeople,
                            res.allowSkipSurvey,
                            res.manageSelfAssessed,
                            res.manageSelfProfiles,
                            res.hasRelationships
                        )
                    );
                } else {
                    dispatch(signInFail(res));
                }
            })
            .catch((err) => {
                dispatch(signInFail(err));
            });
    };
};

export const myPeopleClearManagedRelationship = () => {
    return {
        type: actionTypes.MYPEOPLES_CLEAR_MANAGED_RELATIONSHIP,
    };
};

export const setRedirectUrl = (redirectUrl, isLogout) => {
    localStorage.setItem("redirectUrl", redirectUrl);
    localStorage.setItem("isLogout", isLogout ? "TRUE" : "FALSE");

    return {
        type: actionTypes.AUTH_SET_REDIRECT_URL,
        redirectUrl,
        isLogout,
    };
};
