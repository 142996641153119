import * as actionTypes from "./adminActionTypes";

import AdminRelationshipAPI from "./../../../common/api/adminservice/AdminRelationshipService";

export const addRelationshipsStart = () => {
    return {
        type: actionTypes.ADD_RELATIONSHIPS_START
    };
};

export const addRelationshipsSuccess = (addedRelationships) => {
    return {
        type: actionTypes.ADD_RELATIONSHIPS_SUCCESS,
        addedRelationships
    };
};

export const addRelationshipsFail = error => {
    return {
        type: actionTypes.ADD_RELATIONSHIPS_FAIL,
        error: error,
    };
};

export const addRelationships = (relationships) => {
    return dispatch => {
        dispatch(addRelationshipsStart());

        return AdminRelationshipAPI.addRelationships(relationships)
            .then(res => {
                dispatch(addRelationshipsSuccess(res));
            })
            .catch(err => {
                dispatch(addRelationshipsFail(err));
            });
    };
};

export const deleteRelationshipsStart = () => {
    return {
        type: actionTypes.DELETE_RELATIONSHIPS_START
    };
};

export const deleteRelationshipsSuccess = (deletedRelationshipIds) => {
    return {
        type: actionTypes.DELETE_RELATIONSHIPS_SUCCESS,
        deletedRelationshipIds
    };
};

export const deleteRelationshipsFail = error => {
    return {
        type: actionTypes.DELETE_RELATIONSHIPS_FAIL,
        error: error,
    };
};

export const deleteRelationships = (relationshipIds) => {
    return dispatch => {
        dispatch(deleteRelationshipsStart());

        return AdminRelationshipAPI.deleteRelationships(relationshipIds)
            .then(res => {
                dispatch(deleteRelationshipsSuccess(relationshipIds));
            })
            .catch(err => {
                dispatch(deleteRelationshipsFail(err));
            });
    };
};

export const exportRelationshipsDataExcelStart = () => {
    return {
        type: actionTypes.EXPORT_RELATIONSHIPS_DATA_START,
    };
};

export const exportRelationshipsDataExcelSuccess = (exportData) => {
    return {
        type: actionTypes.EXPORT_RELATIONSHIPS_DATA_SUCCESS,
        exportData,
    };
};

export const exportRelationshipsDataExcelFail = (error) => {
    return {
        type: actionTypes.EXPORT_RELATIONSHIPS_DATA_FAIL,
        error: error,
    };
};

export const exportRelationshipsDataExcel = () => {
    return (dispatch) => {
        dispatch(exportRelationshipsDataExcelStart());

        return AdminRelationshipAPI.exportRelationshipsDataExcel()
            .then((res) => {
                dispatch(exportRelationshipsDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportRelationshipsDataExcelFail(err));
            });
    };
};

export const exportRelationshipsDataExcelTemplate = () => {
    return (dispatch) => {
        dispatch(exportRelationshipsDataExcelStart());

        return AdminRelationshipAPI.exportRelationshipsDataExcelTemplate()
            .then((res) => {
                dispatch(exportRelationshipsDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportRelationshipsDataExcelFail(err));
            });
    };
};

export const importSkillAttributesDataExcelStart = () => {
    return {
        type: actionTypes.IMPORT_RELATIONSHIPS_DATA_START,
    };
};

export const importSkillAttributesDataExcelSuccess = () => {
    return {
        type: actionTypes.IMPORT_RELATIONSHIPS_DATA_SUCCESS,
    };
};

export const importSkillAttributesDataExcelFail = (error) => {
    return {
        type: actionTypes.IMPORT_RELATIONSHIPS_DATA_FAIL,
        error: error,
    };
};

export const importSkillAttributesDataExcel = (relationshipsData) => {
    return (dispatch) => {
        dispatch(importSkillAttributesDataExcelStart());

        return AdminRelationshipAPI.importRelationshipsDataExcel(relationshipsData)
            .then((res) => {
                dispatch(importSkillAttributesDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(importSkillAttributesDataExcelFail(err));
            });
    };
};