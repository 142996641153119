import AdminEvidenceTypeAPI from "./../../../common/api/adminservice/AdminEvidenceTypeService";
import * as actionTypes from "./adminActionTypes";

export const getEvidenceTypesStart = () => {
    return {
        type: actionTypes.GET_EVIDENCETYPES_START,
    };
};

export const getEvidenceTypesSuccess = (evidenceTypes) => {
    return {
        type: actionTypes.GET_EVIDENCETYPES_SUCCESS,
        evidenceTypes,
    };
};

export const getEvidenceTypesFail = (error) => {
    return {
        type: actionTypes.GET_EVIDENCETYPES_FAIL,
        error: error,
    };
};

export const getEvidenceTypes = () => {
    return (dispatch) => {
        dispatch(getEvidenceTypesStart());

        return AdminEvidenceTypeAPI.getEvidenceTypes()
            .then((res) => {
                dispatch(getEvidenceTypesSuccess(res));
            })
            .catch((err) => {
                dispatch(getEvidenceTypesFail(err));
            });
    };
};

export const addEvidenceTypeStart = () => {
    return {
        type: actionTypes.ADD_EVIDENCETYPE_START,
    };
};

export const addEvidenceTypeSuccess = (addedEvidenceType) => {
    return {
        type: actionTypes.ADD_EVIDENCETYPE_SUCCESS,
        addedEvidenceType,
    };
};

export const addEvidenceTypeFail = (error) => {
    return {
        type: actionTypes.ADD_EVIDENCETYPE_FAIL,
        error: error,
    };
};

export const addEvidenceType = (evidenceType) => {
    return (dispatch) => {
        dispatch(addEvidenceTypeStart());

        return AdminEvidenceTypeAPI.createEvidenceType(evidenceType)
            .then((res) => {
                dispatch(addEvidenceTypeSuccess(res));
            })
            .catch((err) => {
                dispatch(addEvidenceTypeFail(err));
            });
    };
};

export const updateEvidenceTypeStart = () => {
    return {
        type: actionTypes.UPDATE_EVIDENCETYPE_START,
    };
};

export const updateEvidenceTypeSuccess = (updatedEvidenceType) => {
    return {
        type: actionTypes.UPDATE_EVIDENCETYPE_SUCCESS,
        updatedEvidenceType,
    };
};

export const updateEvidenceTypeFail = (error) => {
    return {
        type: actionTypes.UPDATE_EVIDENCETYPE_FAIL,
        error: error,
    };
};

export const updateEvidenceType = (id, evidenceType) => {
    return (dispatch) => {
        dispatch(updateEvidenceTypeStart());

        return AdminEvidenceTypeAPI.updateEvidenceType(id, evidenceType)
            .then((res) => {
                dispatch(updateEvidenceTypeSuccess(res));
            })
            .catch((err) => {
                dispatch(updateEvidenceTypeFail(err));
            });
    };
};

export const deleteEvidenceTypeStart = () => {
    return {
        type: actionTypes.DELETE_EVIDENCETYPE_START,
    };
};

export const deleteEvidenceTypeSuccess = (deletedEvidenceTypeId) => {
    return {
        type: actionTypes.DELETE_EVIDENCETYPE_SUCCESS,
        deletedEvidenceTypeId,
    };
};

export const deleteEvidenceTypeFail = (error) => {
    return {
        type: actionTypes.DELETE_EVIDENCETYPE_FAIL,
        error: error,
    };
};

export const deleteEvidenceType = (id) => {
    return (dispatch) => {
        dispatch(deleteEvidenceTypeStart());

        return AdminEvidenceTypeAPI.deleteEvidenceType(id)
            .then((res) => {
                dispatch(deleteEvidenceTypeSuccess(id));
            })
            .catch((err) => {
                dispatch(deleteEvidenceTypeFail(err));
            });
    };
};
