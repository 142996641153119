import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class ProfileAPI {
    static getMyProfileTypes() {
        return axios
            .get(`/profiles/myprofiletypes`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getManagedPeopleProfileTypes(email) {
        return axios
            .get(`/profiles/managedPeopleProfiletypes?email=${email}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getMyProfileVersions(profileType) {
        return axios
            .get(`/profiles/myprofileversions${profileType ? `?profileType=${profileType}` : ""}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getManagedPeopleProfileVersions(email, profileType) {
        return axios
            .get(`/profiles/managePeopleProfileversions?email=${email}${profileType ? `&profileType=${profileType}` : ""}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getMyLorPofiles(sfiaVersion, version, profileType) {
        let url = `/profiles/mylorprofiles`;
        let count = 0;

        if (sfiaVersion) {
            url += `?sfiaVersion=${sfiaVersion}`;
            count += 1;
        }

        if (version) {
            url += `${count == 0 ? "?" : "&"}version=${version}`;
            count += 1;
        }

        if (profileType) {
            url += `${count == 0 ? "?" : "&"}profileType=${profileType}`;
            count += 1;
        }

        return axios
            .get(url)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getManagedPeopleLorPofiles(email, sfiaVersion, version, profileType) {
        let url = `/profiles/managedPeopleLorprofiles?email=${email}`;

        if (sfiaVersion) {
            url += `&sfiaVersion=${sfiaVersion}`;
        }

        if (version) {
            url += `&version=${version}`;
        }

        if (profileType) {
            url += `&profileType=${profileType}`;
        }

        return axios
            .get(url)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getMySkillPofiles(sfiaVersion, version, profileType) {
        let url = `/profiles/myskillprofiles`;
        let count = 0;

        if (sfiaVersion) {
            url += `?sfiaVersion=${sfiaVersion}`;
            count += 1;
        }

        if (version) {
            url += `${count == 0 ? "?" : "&"}version=${version}`;
            count += 1;
        }

        if (profileType) {
            url += `${count == 0 ? "?" : "&"}profileType=${profileType}`;
            count += 1;
        }

        return axios
            .get(url)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getManagedPeopleSkillPofiles(email, sfiaVersion, version, profileType) {
        let url = `/profiles/managedPeopleSkillprofiles?email=${email}`;

        if (sfiaVersion) {
            url += `&sfiaVersion=${sfiaVersion}`;
        }

        if (version) {
            url += `&version=${version}`;
        }

        if (profileType) {
            url += `&profileType=${profileType}`;
        }

        return axios
            .get(url)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static printMyPofiles(endorsed) {
        return axios
            .get(`/profiles/printprofiles${endorsed ? "?endorsed=true" : ""}`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static printManagedPeoplePofiles(email, endorsed) {
        return axios
            .get(`/profiles/printManagedPeopleProfiles?email=${email}${endorsed ? "&endorsed=true" : ""}`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static emailMyPofilesReport() {
        return axios
            .get(`/profiles/emailprofilesreport`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static updateMyProfile(updatedProfiles) {
        const request = {
            lorProfileRequests: updatedProfiles.filter((it) => it.source == "lorProfiles"),
            skillProfileRequests: updatedProfiles.filter((it) => it.source == "skillProfiles"),
        };

        return axios
            .put("/profiles/myprofiles", request)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateManagedPeopleProfile(email, updatedProfiles) {
        const request = {
            lorProfileRequests: updatedProfiles.filter((it) => it.source == "lorProfiles"),
            skillProfileRequests: updatedProfiles.filter((it) => it.source == "skillProfiles"),
        };

        return axios
            .put(`/profiles/managedPeopleProfiles/${email}`, request)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateManagedPeopleEndorsedStatus(email, status) {
        return axios
            .put(`/profiles/managedPeopleProfiles/${email}/updateEndorsedStatus`, { status })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static copyEndorsedProfile() {
        return axios
            .post("/profiles/copyendorsed")
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static copyProfile(version, profileType) {
        return axios
            .post("/profiles/copyprofile", {
                version,
                profileType,
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateMyLorProfileComment(id, comment) {
        return axios
            .post(`/profiles/mylorprofiles/${id}/updatecomment`, {
                comment,
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateManagedPeopleLorProfileComment(id, comment, email) {
        return axios
            .post(`/profiles/managedPeopleLorProfiles/${id}/updatecomment`, {
                comment,
                email,
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateMySkillProfileComment(id, comment) {
        return axios
            .post(`/profiles/myskillprofiles/${id}/updatecomment`, {
                comment,
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateManagedPeopleSkillProfileComment(id, comment, email) {
        return axios
            .post(`/profiles/managedPeopleSkillProfiles/${id}/updatecomment`, {
                comment,
                email,
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static generateMyPersonalDataExcel() {
        return axios
            .get(`/profiles/generatepersonaldataexcel`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static submitUpdateProfileSuggestion(email, comment, suggestedSkills) {
        return axios
            .post(`/profiles/managedPeopleProfiles/${email}/suggestions`, {
                comment,
                skills: suggestedSkills,
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static acceptUpdateProfileSuggestion(id, comment, timezoneOffset) {
        return axios
            .post(`/profiles/suggestions/${id}/accept`, { comment, timezoneOffset })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static rejectUpdateProfileSuggestion(id, comment, timezoneOffset) {
        return axios
            .post(`/profiles/suggestions/${id}/reject`, { comment, timezoneOffset })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default ProfileAPI;
