import ErrorHandler from "./../errorResponseHandler";
import Instance from "./../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminAllowedRegistrationEmailDomainAPI {
    static getAllowedRegistrationEmailDomains() {
        return axios
            .get(`/allowedRegistrationEmailDomains`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createAllowedRegistrationEmailDomain(emailDomain) {
        return axios
            .post(`/allowedRegistrationEmailDomains`, { emailDomain })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateAllowedRegistrationEmailDomain(id, emailDomain) {
        return axios
            .put(`/allowedRegistrationEmailDomains/${id}`, { emailDomain })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteAllowedRegistrationEmailDomain(id) {
        return axios
            .delete(`/allowedRegistrationEmailDomains/${id}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default AdminAllowedRegistrationEmailDomainAPI;
