import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class TeamAnalyticsService {
    static getSkillWeHave() {
        return axios
            .get(`datavisualization/skillwehave`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }
    static getSkillWeHaveGa() {
        return axios
            .get(`dataVisualization/skillwehavega`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }
    static getSkillWeNeed() {
        return axios
            .get(`dataVisualization/skillweneed`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static getActionPlan() {
        return axios
            .get(`dataVisualization/action`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }
    static getBuilders() {
        return axios
            .get(`dataVisualization/builders`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }
    static getSkillGaps() {
        return axios
            .get(`dataVisualization/skillgap`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }
}

export default TeamAnalyticsService;
