import AdminActionTypeAPI from "./../../../common/api/adminservice/AdminActionTypService";
import * as adminActionTypes from "./adminActionTypes";

export const getActionTypesStart = () => {
    return {
        type: adminActionTypes.GET_ACTIONTYPES_START,
    };
};

export const getActionTypesSuccess = (actionTypes) => {
    return {
        type: adminActionTypes.GET_ACTIONTYPES_SUCCESS,
        actionTypes,
    };
};

export const getActionTypesFail = (error) => {
    return {
        type: adminActionTypes.GET_ACTIONTYPES_FAIL,
        error: error,
    };
};

export const getActionTypes = () => {
    return (dispatch) => {
        dispatch(getActionTypesStart());

        return AdminActionTypeAPI.getActionTypes()
            .then((res) => {
                dispatch(getActionTypesSuccess(res));
            })
            .catch((err) => {
                dispatch(getActionTypesFail(err));
            });
    };
};

export const addActionTypeStart = () => {
    return {
        type: adminActionTypes.ADD_ACTIONTYPE_START,
    };
};

export const addActionTypeSuccess = (addedActionType) => {
    return {
        type: adminActionTypes.ADD_ACTIONTYPE_SUCCESS,
        addedActionType,
    };
};

export const addActionTypeFail = (error) => {
    return {
        type: adminActionTypes.ADD_ACTIONTYPE_FAIL,
        error: error,
    };
};

export const addActionType = (actionType) => {
    return (dispatch) => {
        dispatch(addActionTypeStart());

        return AdminActionTypeAPI.createActionType(actionType)
            .then((res) => {
                dispatch(addActionTypeSuccess(res));
            })
            .catch((err) => {
                dispatch(addActionTypeFail(err));
            });
    };
};

export const updateActionTypeStart = () => {
    return {
        type: adminActionTypes.UPDATE_ACTIONTYPE_START,
    };
};

export const updateActionTypeSuccess = (updatedActionType) => {
    return {
        type: adminActionTypes.UPDATE_ACTIONTYPE_SUCCESS,
        updatedActionType,
    };
};

export const updateActionTypeFail = (error) => {
    return {
        type: adminActionTypes.UPDATE_ACTIONTYPE_FAIL,
        error: error,
    };
};

export const updateActionType = (id, actionType) => {
    return (dispatch) => {
        dispatch(updateActionTypeStart());

        return AdminActionTypeAPI.updateActionType(id, actionType)
            .then((res) => {
                dispatch(updateActionTypeSuccess(res));
            })
            .catch((err) => {
                dispatch(updateActionTypeFail(err));
            });
    };
};

export const deleteActionTypeStart = () => {
    return {
        type: adminActionTypes.DELETE_ACTIONTYPE_START,
    };
};

export const deleteActionTypeSuccess = (deletedActionTypeId) => {
    return {
        type: adminActionTypes.DELETE_ACTIONTYPE_SUCCESS,
        deletedActionTypeId,
    };
};

export const deleteActionTypeFail = (error) => {
    return {
        type: adminActionTypes.DELETE_ACTIONTYPE_FAIL,
        error: error,
    };
};

export const deleteActionType = (id) => {
    return (dispatch) => {
        dispatch(deleteActionTypeStart());

        return AdminActionTypeAPI.deleteActionType(id)
            .then((res) => {
                dispatch(deleteActionTypeSuccess(id));
            })
            .catch((err) => {
                dispatch(deleteActionTypeFail(err));
            });
    };
};
