import ErrorHandler from "./../errorResponseHandler";
import Instance from "./../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminCompanyAPI {
    static getBrandingLogo() {
        return axios
            .get(`/companies/brandinglogo`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static updateBrandingLogo(brandingLogo) {
        return axios
            .post(`/companies/brandinglogo`, {
                brandingLogo
            })
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                console.log(err);
                throw ErrorHandler(err);
            });
    }

    static deleteBrandingLogo() {
        return axios
            .post(`/companies/deletebrandinglogo`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                console.log(err);
                throw ErrorHandler(err);
            });
    }
}

export default AdminCompanyAPI;