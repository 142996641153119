import { dismisAlert, generateAlert } from "../../../src/utils/alertUtils";
import * as authActions from "../../store/actions/authAction";
import * as configActions from "../../store/actions/configAction";
import * as contentActions from "../../store/actions/contentAction";
import * as personActions from "../../store/actions/personAction";
import * as skillProfileActions from "../../store/actions/skillProfileAction";
import changeInput from "../../utils/changeInput";
import { contentTranslator } from "../../utils/translatorUtils";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import ContentTranslator from "../translator/ContentTranslator";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import Select from "react-select";
import { Button, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
const headerStyle = {
    color: "#2B2B2B",
    fontSize: "22px",
    fontFamily: "IBMPlexSans-Bold",
};

const subHeaderStyle = {
    fontFamily: "IBMPlexSans-Light",
    fontSize: "14px",
};

class MySetting extends React.Component {
    static displayName = MySetting.name;

    constructor(props) {
        super(props);
        this.connection = null;
        this.state = {
            loading: false,
            contentLoading: true,
            updateProfile: false,
            alerts: [],
            notificationEnabledOptions: [
                {
                    value: true,
                    label: "Yes",
                },
                {
                    value: false,
                    label: "No",
                },
            ],
            form: {
                firstName: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                lastName: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                preferredName: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                position: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                allowNotifications: {
                    validation: {
                        required: false,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                activityStatementInterval: {
                    validation: {
                        required: false,
                    },
                    value: 30,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: false,
            forgetMeProgress: false,
            downloadPersonalDataProgress: false,
            downloadPersonProfileProgress: false,
            activityStatementIntervalOptions: [
                {
                    value: 14,
                    label: 14,
                },
                {
                    value: 30,
                    label: 30,
                },
                {
                    value: 60,
                    label: 60,
                },
                {
                    value: 90,
                    label: 90,
                },
                {
                    value: 365,
                    label: 365,
                },
            ],
            updateActivityStatementInterval: false,
        };

        this.initData = this.initData.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true });

        if (this.props.language) {
            this.props.onContent.getContentTranslations(this.props.language.id, "SfiaAll-Menu-SfiaProfileMenu-SfiaProfile-Lors-Skills").then(() => {
                this.setState({ contentLoading: this.props.contentLoading });

                this.props.onAuth.authCheckState().then(() => {
                    this.setState({ loading: false });
                    this.initData();
                });
            });
        } else {
            this.props.onAuth.authCheckState().then(() => {
                this.setState({ loading: false });
                this.initData();
            });
        }
    }

    initData() {
        if (!this.props.currAssessment) {
            this.props.onConfig.getCurrAssessment().then(() => {});
        }

        this.setState({ loading: true });
        const { form } = this.state;

        this.props.onPerson.getPersonMyProfile().then(() => {
            if (!this.props.loading && this.props.person) {
                const person = this.props.person;

                form.firstName.value = person.firstName;
                form.firstName.valid = true;
                form.firstName.isValidFormat = true;

                form.lastName.value = person.lastName;
                form.lastName.valid = true;
                form.lastName.isValidFormat = true;

                form.preferredName.value = person.salutation;
                form.preferredName.valid = true;
                form.preferredName.isValidFormat = true;

                form.allowNotifications.value = person.allowNotifications;
                form.allowNotifications.valid = true;
                form.allowNotifications.isValidFormat = true;

                form.position.value = person.position;
                form.position.valid = true;
                form.position.isValidFormat = true;

                if (person.activityStatementInterval) {
                    form.activityStatementInterval.value = person.activityStatementInterval;
                }

                this.setState({ form, isFormValid: true });
            }
            this.setState({ loading: this.props.loading });
        });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleAllowNotificationChange = (value) => {
        const { form } = this.state;
        form.allowNotifications.value = value;

        this.setState({
            form,
        });
    };

    handleActivityStatementIntervalChange = (value) => {
        console.log("value", value);
        const { form } = this.state;
        form.activityStatementInterval.value = value;

        this.setState({
            form,
        });
    };

    handleSubmitUpdate = () => {
        const { form } = this.state;

        const person = {
            firstName: this.state.form.firstName.value,
            lastName: this.state.form.lastName.value,
            salutation: this.state.form.preferredName.value,
            allowNotifications: this.state.form.allowNotifications.value,
            position: this.state.form.position.value,
            accountId: null,
        };

        this.setState({ updateProfile: true, showLoadingPanel: true, loadingPanelHeader: "Update profile", loadingPanelBody: "Please wait..." });

        this.props.onPerson.updatePersonMyProfile(person).then(() => {
            if (!this.props.updating) {
                if (this.props.error) {
                    this.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    this.generateAlert("success", "Profile updated");
                }
            }
            this.setState({ updateProfile: this.props.updating, showLoadingPanel: this.props.updating });
        });
    };

    handleSubmitUpdateActivityStatementInterval = () => {
        const { form } = this.state;

        this.setState({ updateProfile: true, showLoadingPanel: true, loadingPanelHeader: "Update activity statement interval", loadingPanelBody: "Please wait..." });

        this.props.onPerson.updatePersonActivityStatementInterval(form.activityStatementInterval.value).then(() => {
            if (!this.props.updating) {
                if (this.props.error) {
                    this.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    this.generateAlert("success", "Activity statement interval updated");
                }
            }
            this.setState({ updateProfile: this.props.updating, showLoadingPanel: this.props.updating });
        });
    };

    handleForgetMe = () => {
        if (window.confirm(this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SettingsGDPRConfirmation", "This action will completely ERASE your identity within the system. It CANNOT be recovered once erased. Are you SURE?"))) {
            this.setState({ forgetMeProgress: true, showLoadingPanel: true, loadingPanelHeader: "Forget Me", loadingPanelBody: "Please wait..." });

            this.props.onPerson.forgetMe().then(() => {
                if (!this.props.updating) {
                    if (this.props.error) {
                        this.generateAlert("danger", this.props.error.errData.Message);
                    } else {
                        this.props.onAuth.authLogoutIntended().then(() => {
                            this.props.history.push("/");
                        });
                    }
                }
            });
        }
    };

    handleDownloadPersonalData = () => {
        this.setState({ downloadPersonalDataProgress: true, showLoadingPanel: true, loadingPanelHeader: "Download Personal Data", loadingPanelBody: "Please wait..." });

        this.props.onSkillProfile.generatePersonalDataExcel().then(() => {
            if (!this.props.loadingPrint && !this.props.skillProfileError) {
                if (this.props.printData) {
                    let firstName = localStorage.getItem("firstName");
                    let lastName = localStorage.getItem("lastName");

                    FileDownload(this.props.printData, firstName && lastName ? `${firstName}${lastName}.xlsx` : "PersonalData.xlsx");
                }
            }

            this.setState({ downloadPersonalDataProgress: this.props.loadingPrint, showLoadingPanel: this.props.loadingPrint });
        });
    };

    renderLoadingPanel() {
        if (this.state.showLoadingPanel) {
            return (
                <Modal isOpen={this.state.showLoadingPanel}>
                    <ModalHeader>{this.state.loadingPanelHeader}</ModalHeader>
                    <ModalBody>
                        <Spinner size="md" color="primary" /> {this.state.loadingPanelBody}
                    </ModalBody>
                </Modal>
            );
        } else {
            return "";
        }
    }

    render() {
        const { form } = this.state;

        return (
            <Container className="contentMain">
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.renderLoadingPanel()}
                <ContentWrapperLg>
                    <p className={"page-title"} style={headerStyle}>
                        <ContentTranslator page="SfiaProfile" name="SettingsGeneralTitle" contentText="Personal Settings" />
                    </p>
                    <p style={subHeaderStyle}>
                        <ContentTranslator page="SfiaProfile" name="SettingsGeneralInfo" contentText="You can update your personal details below" />
                    </p>
                    <Form>
                        <FormGroup row>
                            <Label sm={3} style={{ fontFamily: "IBMPlexSans-Light", fontSize: "14px" }}>
                                <ContentTranslator page="SfiaProfile" name="SettingsFirstNameTitle" contentText="First Name" />
                            </Label>
                            <Col sm={6}>
                                <Input type="text" name="firstName" id={"firstName"} value={form.firstName.value} invalid={form.firstName.touched && !form.firstName.valid} onChange={this.handleChange} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ fontFamily: "IBMPlexSans-Light", fontSize: "14px" }}>
                                <ContentTranslator page="SfiaProfile" name="SettingsLastNameTitle" contentText="Last Name" />
                            </Label>
                            <Col sm={6}>
                                <Input type="text" name="lastName" id={"lastName"} value={form.lastName.value} invalid={form.lastName.touched && !form.lastName.valid} onChange={this.handleChange} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ fontFamily: "IBMPlexSans-Light", fontSize: "14px" }}>
                                <ContentTranslator page="SfiaProfile" name="SettingsPreferredOrNickNameTitle" contentText="Preferred or Nickname" />
                            </Label>
                            <Col sm={6}>
                                <Input type="text" name="preferredName" id={"preferredName"} value={form.preferredName.value} invalid={form.preferredName.touched && !form.preferredName.valid} onChange={this.handleChange} />
                            </Col>
                        </FormGroup>
                        {this.props.currAssessment && this.props.currAssessment.suppressActivityNotification == false && this.props.currAssessment.allowUserSuppressActivityNotification == true && (
                            <FormGroup row>
                                <Label sm={3} style={{ fontFamily: "IBMPlexSans-Light", fontSize: "14px" }}>
                                    <ContentTranslator page="SfiaProfile" name="SettingsNotificationEnabled" contentText="Activity Notifications enabled?" />
                                </Label>
                                <Col sm={2}>
                                    <Select value={this.state.notificationEnabledOptions.find((it) => it.value == form.allowNotifications.value)} onChange={(value) => this.handleAllowNotificationChange(value.value)} options={this.state.notificationEnabledOptions} />
                                </Col>
                            </FormGroup>
                        )}
                        <FormGroup row>
                            <Col sm={12} style={{ textAlign: "center" }}>
                                <Button color="primary" onClick={this.handleSubmitUpdate} disabled={!this.state.isFormValid || this.state.updateProfile}>
                                    <ContentTranslator page="SfiaAll" name="Save" contentText="Save" />
                                </Button>
                            </Col>
                        </FormGroup>
                    </Form>
                </ContentWrapperLg>
                {this.props.currAssessment && this.props.currAssessment.allowUsersToBeForgotten ? (
                    <ContentWrapperLg>
                        <p className={"page-title"} style={headerStyle}>
                            <ContentTranslator page="SfiaProfile" name="SettingsGDPRTitle" contentText="General Data Protection Regulation settings" />
                        </p>
                        <p style={subHeaderStyle}>
                            <ContentTranslator
                                page="SfiaProfile"
                                name="SettingsGDPRMessage"
                                contentText={`If you would like to be forgotten by SkillsTx please click the "Forget Me" button below. Please note that by answering OK to the confirmation that the process is <b>NOT</b> reversible and any existing data relating to you will not be retrievable.`}
                            />
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <Button color="primary" onClick={this.handleForgetMe} disabled={this.state.forgetMeProgress}>
                                <ContentTranslator page="SfiaProfile" name="SettingsGDPRButton" contentText="Forget Me" />
                            </Button>
                        </p>
                        <br />
                        <p style={subHeaderStyle}>
                            <ContentTranslator page="SfiaProfile" name="SettingsDownloadDataMessage" contentText="You can also download a list of data held within SkillsTx for review. Just click the link below :" />
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <Button color="primary" onClick={this.handleDownloadPersonalData} disabled={this.state.downloadPersonalDataProgress}>
                                <ContentTranslator page="SfiaAll" name="DownloadDataButton" contentText="Download Personal Data" />
                            </Button>
                        </p>
                    </ContentWrapperLg>
                ) : (
                    <ContentWrapperLg>
                        <p style={subHeaderStyle}>
                            <ContentTranslator page="SfiaProfile" name="SettingsDownloadDataMessage" contentText="You can also download a list of data held within SkillsTx for review. Just click the link below :" />
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <Button color="primary" onClick={this.handleDownloadPersonalData} disabled={this.state.downloadPersonalDataProgress}>
                                <ContentTranslator page="SfiaAll" name="DownloadDataButton" contentText="Download Personal Data" />
                            </Button>
                        </p>
                    </ContentWrapperLg>
                )}
                {this.props.hasPeoples && (
                    <ContentWrapperLg>
                        <p className={"page-title"} style={headerStyle}>
                            <ContentTranslator page="SfiaProfile" name="SettingsASFTitle" contentText="Activity Statement Frequency" />
                        </p>
                        <p style={subHeaderStyle}>
                            <ContentTranslator
                                page="SfiaProfile"
                                name="SettingsASFMessage"
                                contentText={`As a manager and/or team leader you will receive an activity statement relating to your assigned people via your registered email address.<br />This activity statement will include details of changes to skill profiles, updated action plans and missed deadlines.<br />You can set the frequency below:`}
                            />
                        </p>
                        <Form>
                            <FormGroup row>
                                <Label sm={5} style={{ fontFamily: "IBMPlexSans-Light", fontSize: "14px" }}>
                                    <ContentTranslator page="SfiaProfile" name="SettingsASFLabel" contentText="<b>I would like to receive an activity statement every:</b>" />
                                </Label>
                                <Col sm={2}>
                                    <Select value={this.state.activityStatementIntervalOptions.find((it) => it.value == form.activityStatementInterval.value)} onChange={(value) => this.handleActivityStatementIntervalChange(value.value)} options={this.state.activityStatementIntervalOptions} />
                                </Col>
                                <Label sm={1} style={{ fontFamily: "IBMPlexSans-Light", fontSize: "14px" }}>
                                    <ContentTranslator page="SfiaProfile" name="SettingsASFDays" contentText="<b>days</b>" />
                                </Label>
                                <Col sm={2} style={{ textAlign: "center" }}>
                                    <Button color="primary" onClick={this.handleSubmitUpdateActivityStatementInterval} disabled={this.state.updateActivityStatementInterval}>
                                        <ContentTranslator page="SfiaAll" name="Save" contentText="Save" />
                                    </Button>
                                </Col>
                            </FormGroup>
                        </Form>
                    </ContentWrapperLg>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.person.loading,
        error: state.person.error,
        updating: state.person.updating,
        person: state.person.person,

        loadingExport: state.person.loadingExport,
        exportData: state.person.exportData,

        hasPeoples: state.auth.hasPeoples,
        isEndorser: state.auth.isEndorser,

        loadingPrint: state.skillProfile.loadingPrint,
        printData: state.skillProfile.printData,

        language: state.language.language,
        contentTranslations: state.content.contentTranslations,
        contentLoading: state.content.loading,
        contentError: state.content.error,

        currAssessment: state.config.currAssessment,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onSkillProfile: bindActionCreators(skillProfileActions, dispatch),
        onPerson: bindActionCreators(personActions, dispatch),
        onContent: bindActionCreators(contentActions, dispatch),
        onConfig: bindActionCreators(configActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MySetting);
