import RegisterReasonAPI from "./../../common/api/service/RegisterReasonService";
import * as actionTypes from "./actionTypes";

export const getRegisterReasonsStart = () => {
    return {
        type: actionTypes.GET_REGISTER_REASONS_START,
    };
};

export const getRegisterReasonsSuccess = (registerReasons) => {
    return {
        type: actionTypes.GET_REGISTER_REASONS_SUCCESS,
        registerReasons,
    };
};

export const getRegisterReasonsFail = (error) => {
    return {
        type: actionTypes.GET_REGISTER_REASONS_FAIL,
        error: error,
    };
};

export const getRegisterReasons = () => {
    return (dispatch) => {
        dispatch(getRegisterReasonsStart());

        return RegisterReasonAPI.getRegisterReasons()
            .then((res) => {
                if (res) {
                    dispatch(getRegisterReasonsSuccess(res));
                } else {
                    dispatch(getRegisterReasonsFail(res));
                }
            })
            .catch((err) => {
                dispatch(getRegisterReasonsFail(err));
            });
    };
};
