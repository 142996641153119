import { updateState } from "../../utility";
import * as actionTypes from "../actions/adminActionTypes";

const initialState = {
    badgeRequests: [],
    updatedBadgeRequest: null,
    error: null,
    loading: false,
    isUpdating: false,
};

const getBadgeRequestsStart = (state) => {
    return updateState(state, { error: null, badgeRequests: [], loading: true });
};

const getBadgeRequestsSuccess = (state, action) => {
    return updateState(state, {
        badgeRequests: action.badgeRequests,
        error: null,
        loading: false,
    });
};

const getBadgeRequestsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateBadgeRequestStart = (state) => {
    return updateState(state, { error: null, isUpdating: true, updatedBadgeRequest: null });
};

const updateBadgeRequestFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateBadgeRequestAssessorSuccess = (state, action) => {
    let badgeRequests = state.badgeRequests;

    const updatedBadgeRequest = badgeRequests.find((x) => x.id == action.badgeRequest.id);
    if (updatedBadgeRequest) {
        updatedBadgeRequest.status = action.badgeRequest.status;
        updatedBadgeRequest.assessorId = action.badgeRequest.assessorId;
        updatedBadgeRequest.assessorName = action.badgeRequest.assessorName;
    }

    return updateState(state, {
        badgeRequests: [...badgeRequests],
        error: null,
        isUpdating: false,
    });
};

const updateBadgeRequestCertificationEntriesSuccess = (state, action) => {
    let badgeRequests = state.badgeRequests;

    const updatedBadgeRequest = badgeRequests.find((x) => x.id == action.badgeRequest.id);
    if (updatedBadgeRequest) {
        updatedBadgeRequest.status = action.badgeRequest.status;
        updatedBadgeRequest.assessedDate = action.badgeRequest.assessedDate;
        updatedBadgeRequest.requestCertificationEntries = [...action.badgeRequest.requestCertificationEntries];
    }

    return updateState(state, {
        badgeRequests: [...badgeRequests],
        updatedBadgeRequest: action.badgeRequest,
        error: null,
        isUpdating: false,
    });
};

const submitBadgeRequestCertificationSuccess = (state, action) => {
    let badgeRequests = state.badgeRequests;

    const updatedBadgeRequest = badgeRequests.find((x) => x.id == action.badgeRequest.id);
    if (updatedBadgeRequest) {
        updatedBadgeRequest.status = action.badgeRequest.status;
        updatedBadgeRequest.assessedDate = action.badgeRequest.assessedDate;
        updatedBadgeRequest.certificationSubmitted = action.badgeRequest.certificationSubmitted;
    }

    return updateState(state, {
        badgeRequests: [...badgeRequests],
        updatedBadgeRequest: action.badgeRequest,
        error: null,
        isUpdating: false,
    });
};

const submitBadgeRequestCertificationsSuccess = (state, action) => {
    let badgeRequests = state.badgeRequests;

    action.badgeRequests.forEach((badgeRequest) => {
        const updatedBadgeRequest = badgeRequests.find((x) => x.id == badgeRequest.id);
        if (updatedBadgeRequest) {
            updatedBadgeRequest.status = badgeRequest.status;
            updatedBadgeRequest.assessedDate = badgeRequest.assessedDate;
            updatedBadgeRequest.certificationSubmitted = badgeRequest.certificationSubmitted;
        }
    });

    return updateState(state, {
        badgeRequests: [...badgeRequests],
        error: null,
        isUpdating: false,
    });
};

const updateBadgeRequest = (state, action) => {
    const badgeRequests = state.badgeRequests;
    const updateBadgeRequest = action.badgeRequest;
    const badgeRequest = badgeRequests.find((it) => it.id == updateBadgeRequest.id);
    if (badgeRequest) {
        badgeRequest.status = updateBadgeRequest.status;
        badgeRequest.assessorId = updateBadgeRequest.assessorId;
        badgeRequest.assessorName = updateBadgeRequest.assessorName;
        badgeRequest.assessedDate = updateBadgeRequest.assessedDate;
        badgeRequest.certificationSubmitted = updateBadgeRequest.certificationSubmitted;
        badgeRequest.requestCertificationEntries = [...updateBadgeRequest.requestCertificationEntries];
    }

    return updateState(state, {
        badgeRequests: [...badgeRequests],
    });
};

const rejectBadgeRequestStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const rejectBadgeRequestFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const rejectBadgeRequestSuccess = (state, action) => {
    let badgeRequests = state.badgeRequests;

    const rejectedBadgeRequest = badgeRequests.find((x) => x.id == action.badgeRequestId);
    if (rejectedBadgeRequest) {
        const index = badgeRequests.indexOf(rejectedBadgeRequest);
        if (index !== -1) {
            badgeRequests.splice(index, 1);
        }
    }

    return updateState(state, {
        badgeRequests: [...badgeRequests],
        error: null,
        isUpdating: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_BADGE_REQUESTS_START:
            return getBadgeRequestsStart(state);
        case actionTypes.GET_BADGE_REQUESTS_SUCCESS:
            return getBadgeRequestsSuccess(state, action);
        case actionTypes.GET_BADGE_REQUESTS_FAIL:
            return getBadgeRequestsFail(state, action);

        case actionTypes.UPDATE_BADGE_REQUEST_ASSESSOR_START:
        case actionTypes.UPDATE_BADGE_REQUEST_CERTIFICATION_ENTRIES_START:
        case actionTypes.SUBMIT_BADGE_REQUEST_CERTIFICATION_START:
        case actionTypes.SUBMIT_BADGE_REQUEST_CERTIFICATIONS_START:
            return updateBadgeRequestStart(state);
        case actionTypes.UPDATE_BADGE_REQUEST_ASSESSOR_FAIL:
        case actionTypes.UPDATE_BADGE_REQUEST_CERTIFICATION_ENTRIES_FAIL:
        case actionTypes.SUBMIT_BADGE_REQUEST_CERTIFICATION_FAIL:
        case actionTypes.SUBMIT_BADGE_REQUEST_CERTIFICATIONS_FAIL:
            return updateBadgeRequestFail(state, action);

        case actionTypes.UPDATE_BADGE_REQUEST_ASSESSOR_SUCCESS:
            return updateBadgeRequestAssessorSuccess(state, action);
        case actionTypes.UPDATE_BADGE_REQUEST_CERTIFICATION_ENTRIES_SUCCESS:
            return updateBadgeRequestCertificationEntriesSuccess(state, action);
        case actionTypes.SUBMIT_BADGE_REQUEST_CERTIFICATION_SUCCESS:
            return submitBadgeRequestCertificationSuccess(state, action);
        case actionTypes.SUBMIT_BADGE_REQUEST_CERTIFICATIONS_SUCCESS:
            return submitBadgeRequestCertificationsSuccess(state, action);

        case actionTypes.UPDATE_BADGE_REQUEST:
            return updateBadgeRequest(state, action);

        case actionTypes.REJECT_BADGE_REQUEST_CERTIFICATION_START:
            return rejectBadgeRequestStart(state);
        case actionTypes.REJECT_BADGE_REQUEST_CERTIFICATION_FAIL:
            return rejectBadgeRequestFail(state);
        case actionTypes.REJECT_BADGE_REQUEST_CERTIFICATION_SUCCESS:
            return rejectBadgeRequestSuccess(state, action);

        default:
            return state;
    }
};

export default reducer;
