import { updateState } from "../../utility";
import * as actionTypes from "../actions/adminActionTypes";

const initialState = {
    error: null,
    loading: false,
    isUpdating: false,
    evidenceTypes: [],
};

const getEvidenceTypesStart = (state) => {
    return updateState(state, { error: null, loading: true, evidenceTypes: [] });
};

const getEvidenceTypesSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        loading: false,
        evidenceTypes: action.evidenceTypes,
    });
};

const getEvidenceTypesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const addEvidenceTypeStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const addEvidenceTypeSuccess = (state, action) => {
    const evidenceTypes = state.evidenceTypes;
    evidenceTypes.push(action.addedEvidenceType);

    return updateState(state, {
        error: null,
        isUpdating: false,
        evidenceTypes: [...evidenceTypes],
    });
};

const addEvidenceTypeFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateEvidenceTypeStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateEvidenceTypeSuccess = (state, action) => {
    const updatedEvidenceType = action.updatedEvidenceType;
    const evidenceTypes = state.evidenceTypes;

    const evidenceType = evidenceTypes.find((it) => it.id == updatedEvidenceType.id);
    if (evidenceType) {
        evidenceType.name = updatedEvidenceType.name;
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        evidenceTypes: [...evidenceTypes],
    });
};

const updateEvidenceTypeFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteEvidenceTypeStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteEvidenceTypeSuccess = (state, action) => {
    const deletedEvidenceTypeId = action.deletedEvidenceTypeId;
    const evidenceTypes = state.evidenceTypes;

    const evidenceType = evidenceTypes.find((it) => it.id == deletedEvidenceTypeId);
    if (evidenceType) {
        const index = evidenceTypes.indexOf(evidenceType);
        if (index !== -1) {
            evidenceTypes.splice(index, 1);
        }
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        evidenceTypes: [...evidenceTypes],
    });
};

const deleteEvidenceTypeFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EVIDENCETYPES_START:
            return getEvidenceTypesStart(state);
        case actionTypes.GET_EVIDENCETYPES_SUCCESS:
            return getEvidenceTypesSuccess(state, action);
        case actionTypes.GET_EVIDENCETYPES_FAIL:
            return getEvidenceTypesFail(state, action);

        case actionTypes.ADD_EVIDENCETYPE_START:
            return addEvidenceTypeStart(state);
        case actionTypes.ADD_EVIDENCETYPE_SUCCESS:
            return addEvidenceTypeSuccess(state, action);
        case actionTypes.ADD_EVIDENCETYPE_FAIL:
            return addEvidenceTypeFail(state, action);

        case actionTypes.UPDATE_EVIDENCETYPE_START:
            return updateEvidenceTypeStart(state);
        case actionTypes.UPDATE_EVIDENCETYPE_SUCCESS:
            return updateEvidenceTypeSuccess(state, action);
        case actionTypes.UPDATE_EVIDENCETYPE_FAIL:
            return updateEvidenceTypeFail(state, action);

        case actionTypes.DELETE_EVIDENCETYPE_START:
            return deleteEvidenceTypeStart(state);
        case actionTypes.DELETE_EVIDENCETYPE_SUCCESS:
            return deleteEvidenceTypeSuccess(state, action);
        case actionTypes.DELETE_EVIDENCETYPE_FAIL:
            return deleteEvidenceTypeFail(state, action);        

        default:
            return state;
    }
};

export default reducer;
