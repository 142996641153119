import { InfoCircleOutlined } from "@ant-design/icons";
import { Table, Tooltip } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";

const WrapperTable = styled.div`
  margin-top: 20px;

  .ant-table.ant-table-middle
    .ant-table-tbody
    .ant-table-wrapper:only-child
    .ant-table {
    margin: 0px;
  }

  .ant-table-cell:has(.p-zero) {
    padding: 0px !important;
  }

  .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed .ant-table,
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: 0px;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table {
    border-top-left-radius: 0x;
    border-top-right-radius: 0px;
  }

  .ant-table {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table {
    table-layout: auto;
    border-color: gainsboro;
    border-style: solid;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-width: 0px;
  }

  .ant-table-content > table {
    table-layout: auto;
    border-color: gainsboro;
    border-style: solid;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    border-width: 0.5px;
  }

  .ant-table-cell:has(.b-none) {
    border-color: white !important;
    transition: background 0.3s;
  }

  .ant-table-row:has(.r-hide) {
    display: none;
  }

  .ant-table-cell:has(.c-w) {
    width: 50px !important;
  }

  .ant-table-cell:has(.e-w) {
    width: 30px;
  }

  .ant-table-container table > thead > tr:first-child th {
    background: ${(props) =>
      props?.customStyle?.tableHeaderBackgroundColor || ""};
    color: ${(props) => props?.customStyle?.tableHeaderColor || ""};
    text-align: center;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    table
    > thead
    > tr:first-child
    th {
    background: #fafafa;
    color: black;
    text-align: center;
    cursor: pointer;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    table
    > thead
    > tr:first-child
    th:first-child {
    border-top-left-radius: 0px;
    text-align: start;
    width: auto !important;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 14px;
    text-align: start;
    width: 280px;
  }
  .ant-table-cell {
    padding: 10px;
  }
  .ant-table-row-expand-icon {
    display: none;
  }

  .ant-table-row-indent {
    padding-left: 0 !important;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    table
    > thead
    > tr:first-child
    td:first-child {
    border-top-left-radius: 0px;
    width: auto !important;
  }

  .table-s .ant-table-column-sorters {
    text-align: center;
  }

  .ant-table-container table > thead > tr:first-child td:first-child {
    border-top-left-radius: 14px;
    text-align: start;
    width: 240px;
  }

  @media (max-width: 1080px) {
    .ant-table-container table > thead > tr:first-child td:first-child {
      border-top-left-radius: 14px;
      text-align: start;
      width: auto !important;
    }

    .ant-table-container table > thead > tr:first-child th:first-child {
      border-top-left-radius: 14px;
      text-align: start;
      width: auto !important;
    }
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 14px;
  }

  .op-0 {
    opacity: 0;
  }
`;
const WrapperLevelCustom = styled.div`
  padding: 5px;
`;

const WrapperCellSkillCategoryCustom = styled(Tooltip)`
  padding-left: 20px;
`;
const WrapperCellSkillCategory = styled(Tooltip)``;

const WrapperCellSubCategoryCustom = styled(Tooltip)`
  font-weight: 700;
`;

const WrapperTitleCustom = styled.div`
  text-align: center;
  margin-top: -7px;
  cursor: pointer;
`;

const WrapperTooltipCustom = styled(Tooltip)`
  cursor: help;
`;

// Renders cell content based on the level value
const getCellEmpty = () => <div className="b-none c-w"></div>;

const constantData = {
  lvBackgroundColorDefault: "#F0F0F0",
  tableSize: "default",
  expandedTableSize: "default",
  skillLevelCount: 7,
  categoryName: "category",
  infoName: "info",
  topName: "top",
  levelName: "Level",
  characters: {
    p: "P",
    na: "NA",
    m: "M",
  },
};

const SkillChangeTable = ({ data = null, version = null }) => {
  const onCategoryCellRendered = (text, record, index) => {
    // Depending on the 'isSkill' property of the record, determine which component to render.
    // If 'isSkill' is true, use 'WrapperCellSkillCategoryCustom'.
    // If 'isSkill' is false, use 'WrapperCellSubCategoryCustom'.
    const ComponentToRender = record.isSkill
      ? record.hideHeader
        ? WrapperCellSkillCategory
        : WrapperCellSkillCategoryCustom
      : WrapperCellSubCategoryCustom;

    // Render the chosen component inside a div with the given class.
    // The title and placement properties are consistent for both components.
    return (
      <div className="b-none">
        <ComponentToRender
          title={record.meta.description}
          placement={constantData.topName}
        >
          {`${text}${record.isSkill ? ` (${record.meta.code})` : ""}`}
        </ComponentToRender>
      </div>
    );
  };

  const onExpandCellTitleRendered = (meta) => {
    // Define the custom title for the tooltip:
    // It contains the name of the level responsibility and a list of levels beneath it.
    const customTitle = () => (
      <div>
        {/* Display the name of the level responsibility */}
        <WrapperLevelCustom>
          {meta?.levelResponsibility?.name}
        </WrapperLevelCustom>

        {/* Map through the levels and display each one */}
        {meta?.levelResponsibility?.levels?.map((item, index) => (
          <WrapperLevelCustom key={index}>
            {`${constantData.levelName} ${item.level} : ${item.name}`}
          </WrapperLevelCustom>
        ))}
      </div>
    );

    // Render the custom title wrapped in a 'WrapperTitleCustom' component.
    // It contains an expandable icon and an informational icon within a tooltip.
    return (
      <WrapperTitleCustom className="h-bg-c e-w">
        {/* Information Icon wrapped in a tooltip */}
        <WrapperTooltipCustom
          title={customTitle}
          placement={constantData.topName}
        >
          <InfoCircleOutlined />
        </WrapperTooltipCustom>
      </WrapperTitleCustom>
    );
  };
  // Renders get content based on the level value
  const onCellRendered = (text, record, lv) => {
    // When record isn't a skill, just return an empty style object.
    if (!record.isSkill || !record.levels) return getCellEmpty();
    // Attempt to find the corresponding level from the record's levels.
    const tempLevel = record.levels.find(
      (level) =>
        level.lv === lv && (record.hideHeader ? level.skillProfile : true)
    );
    // If no such level is found, return with the default style.
    if (!tempLevel) return getCellEmpty();

    return (
      <Tooltip
        placement="topRight"
        title={tempLevel.description}
        className="b-none c-w"
      >
        <div className="text-center op-0">{tempLevel.lv}</div>
      </Tooltip>
    );
  };

  /**
   * Determines the styling and interactivity of cells based on the given record and level.
   * This function primarily checks the level and adjusts the cell style based on the record's details.
   */
  const onLevelColorCellRendered = (record, index, lv) => {
    // When record isn't a skill, just return an empty style object.
    if (!record.isSkill) return {};

    // This is a default style for a cell. Used for skill-based records when no specific level styling is found.
    const defaultStyle = {
      backgroundColor: constantData.lvBackgroundColorDefault,
      borderStyle: "solid",
      borderWidth: "0.5px",
    };

    // If the record has no levels defined, just return the default styling.
    if (!record.levels) return { style: defaultStyle };

    // Attempt to find the corresponding level from the record's levels.
    const tempLevel = record.levels.find(
      (level) =>
        level.lv === lv && (record.hideHeader ? level.skillProfile : true)
    );

    // If no such level is found, return with the default style.
    if (!tempLevel) return { style: defaultStyle };
    let tempBackgroundColor = record.meta.skillColour;
    if (record.hideHeader === false && tempLevel.skillProfile) {
      switch (tempLevel.skillProfile.stringVal) {
        case constantData.characters.m:
          tempBackgroundColor = record.meta.colour;
          break;
        case constantData.characters.na:
          tempBackgroundColor = `repeating-linear-gradient(${record.meta.skillColour}, ${record.meta.colour} 10px, ${record.meta.skillColour} 10px, ${record.meta.colour} 20px)`;
          break;
        case constantData.characters.p:
          tempBackgroundColor = `repeating-linear-gradient(45deg, ${record.meta.skillColour}, ${record.meta.colour} 10px, ${record.meta.skillColour} 10px, ${record.meta.colour} 20px)`;
          break;
      }
    }
    return {
      // Set up the cell's styling based on the found level's value.
      style: {
        cursor: "help",
        // Determine the cell's background color based on the value.
        background: tempBackgroundColor,
        // Adjust text color depending on the value.
        color: "black",
        borderStyle: "solid",
        borderWidth: "0.5px",
      },
    };
  };

  // A simple tooltip renderer for cell titles
  const onCellTitleRendered = (text, description) => (
    <Tooltip placement={constantData.topName} title={description}>
      {text} {version && <span className="vs-h-sm-h">(v. {version})</span>}
    </Tooltip>
  );

  const onCellTitle = (record, index) => {
    if (record.hideHeader && !record.isSkill) {
      return {
        className: "r-hide",
      };
    }
    return {};
  };

  const getDataSource = () => {
    const hideHeader = data.meta.hideHeader;
    // If the root is expanded, handle both subcategories and their skills
    return data.subCategories.flatMap((item, index) => {
      const { name, description, skillColour, colour, skills } = item;

      // Construct base data for the sub-category
      const baseData = {
        key: `${index + 1}`,
        category: name,
        hideHeader: hideHeader,
        isSkill: false,
        meta: { description, skillColour, colour },
      };

      // Construct data for each skill under the current sub-category
      const skillsData = skills.map((itemSkill, indexSkill) => {
        const { name, description, levels, code } = itemSkill;

        return {
          key: `${index + 1}.${indexSkill + 1}`,
          category: name,
          parent: item.name,
          hideHeader: hideHeader,
          isSkill: true,
          meta: { skillColour, colour, description, code },
          // Map each level of the skill to its data structure
          levels: levels.map((level) => ({
            lv: level.level,
            description: level.description,
            skillProfile: level.skillProfile,
          })),
        };
      });

      // Return a combined array of the base sub-category data and all its skills data
      return [baseData, ...skillsData];
    });
  };

  const getColumns = () => {
    // Initial columns for category and expand cells
    const initialColumns = [
      {
        title: () =>
          onCellTitleRendered(data.name, data.description, data.meta),
        dataIndex: constantData.categoryName,
        key: constantData.categoryName,
        render: onCategoryCellRendered,
        onCell: onCellTitle,
      },
      {
        title: () => onExpandCellTitleRendered(data.meta),
        key: constantData.infoName,
        dataIndex: constantData.infoName,
        render: () => getCellEmpty(),
      },
    ];

    // Generate columns for each skill level
    const skillLevelColumns = Array.from(
      { length: constantData.skillLevelCount },
      (_, lv) => {
        const level = lv + 1;
        return {
          title: `${level}`,
          dataIndex: `${level}`,
          key: `${level}`,
          onCell: (record, index) =>
            onLevelColorCellRendered(record, index, level),
          render: (text, record) => onCellRendered(text, record, level),
        };
      }
    );

    // Combine and return all columns
    return [...initialColumns, ...skillLevelColumns];
  };

  // useMemo calculates and memoizes the table data based on its dependencies.
  const tableData = useMemo(() => {
    if (data === undefined) return { dataSource: [], columns: [] };
    // Get the data source using the provided getDataSource function
    const dataSource = getDataSource();

    // Get the columns using the provided getColumns function
    const columns = getColumns();

    // Return the computed values in an object
    return { dataSource, columns };
  }, [data]); // Memoization depends on these three values.

  return (
    <WrapperTable customStyle={data?.styles}>
      <div className="table-m">
        <Table
          size={constantData.tableSize}
          columns={tableData.columns}
          pagination={false}
          dataSource={tableData.dataSource}
        />
      </div>
    </WrapperTable>
  );
};

export default SkillChangeTable;
