import * as authActions from "../../../store/actions/authAction";
import * as configActions from "../../../store/actions/configAction";
import * as jobActions from "../../../store/actions/jobAction";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { generateRandomString } from "../../../utils/stringUtils";
import { contentTranslator } from "../../../utils/translatorUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import UserDetail from "../users/UserDetail";
import JobAssignmentBulkUpload from "./JobAssignmentBulkUpload";
import JobAssignmentCell from "./JobAssignmentCell";
import { faInfoCircle, faSync, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Input, Label, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
const messageHubHostname = process.env.NODE_ENV === "development" ? process.env.REACT_APP_MESSAGE_HUB_ENDPOINT : "/messagehub";

const rowStyle = {
    fontSize: "12px",
};

class ManageJobAssignment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            config: null,
            alerts: [],
            jobMatchPersons: [],
            showDialogUserDetailTs: null,
            selectedPerson: null,
            searchProperties: [],
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
        this.generateRandomString = generateRandomString.bind(this);
    }

    componentDidMount() {
        this.props.onAuth.authCheckState().then(() => {
            this.props.onConfig.getAdminConfig().then(() => {
                if (this.props.config && !this.props.configLoading) {
                    this.setState({ config: this.props.config });
                    Promise.all([this.props.onJob.getJobs(), this.props.onJob.getJobMatchPersons()]).then(() => {
                        this.setState({
                            jobs: this.props.jobs.filter((it) => it.inActive == false || !it.inActive),
                            jobMatchPersons: this.reformJobMatchPersons(this.props.jobMatchPersons.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))),
                        });

                        this.setState({ loading: this.props.loading || this.props.jobsLoading });
                    });
                }
            });
        });
    }

    handleRefresh = () => {
        this.props.onJob.getJobMatchPersons().then(() => {
            this.setState({
                jobMatchPersons: this.reformJobMatchPersons(this.props.jobMatchPersons.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))),
            });
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.jobMatchPersons !== this.props.jobMatchPersons) {
            this.setState({
                jobMatchPersons: this.reformJobMatchPersons(this.props.jobMatchPersons.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))),
            });
        }
    }

    reformJobMatchPersons = (jobMatchPersons) => {
        return jobMatchPersons.map((it) => {
            return {
                personId: it.personId,
                firstName: it.firstName,
                lastName: it.lastName,
                email: it.email,
                salutation: it.salutation,
                position: it.position,
                inactive: it.inactive,
                personType: it.personType,
                accessGroup: it.accessGroup,
                allowNotifications: it.allowNotifications,
                lastActivityOn: it.lastActivityOn,
                lastActivitySystem: it.lastActivitySystem,
                createdOn: it.createdOn,
                countryCode: it.countryCode,
                country: it.country,
                location: it.location,
                selfAssessmentReason: it.selfAssessmentReason,
                encryptedId: it.encryptedId,
                team: it.team,
                usePreferredName: it.usePreferredName,
                other: it.other,
                department: it.department,
                businessUnit: it.businessUnit,
                division: it.division,
                territory: it.territory,
                costCentre: it.costCentre,
                supervisor: it.supervisor,
                validatedDate: it.validatedDate,
                isCandidate: it.isCandidate,
                isAnalyticsUser: it.isAnalyticsUser,
                leaveOn: it.leaveOn,
                roles: it.roles,
                groups: it.groups,
                currentJobMatchId: it.currentJobMatchId,
                currentJobMatch: it.currentJobMatch,
                currentJobReference: it.currentJobReference,
                currentJobMatchReference: it.currentJobMatchId ? `${it.currentJobMatch}${it.currentJobReference ? ` ${it.currentJobReference}` : ""}` : "None",
                currentJobMatchPercentSelf: it.currentJobMatchPercentSelf,
                currentJobMatchPercentValidated: it.currentJobMatchPercentValidated,
                currentJobMatchUpdatedOn: it.currentJobMatchUpdatedOn,
                futureJobMatchId: it.futureJobMatchId,
                futureJobMatch: it.futureJobMatch,
                futureJobReference: it.futureJobReference,
                futureJobMatchReference: it.futureJobMatchId ? `${it.futureJobMatch}${it.futureJobReference ? ` ${it.futureJobReference}` : ""}` : "None",
                futureJobMatchPercentSelf: it.futureJobMatchPercentSelf,
                futureJobMatchPercentValidated: it.futureJobMatchPercentValidated,
                futureJobMatchUpdatedOn: it.futureJobMatchUpdatedOn,
                proposedJobMatchId: it.proposedJobMatchId,
                proposedJobMatch: it.proposedJobMatch,
                proposedJobReference: it.proposedJobReference,
                proposedJobMatchReference: it.proposedJobMatchId ? `${it.proposedJobMatch}${it.proposedJobReference ? ` ${it.proposedJobReference}` : ""}` : "None",
                proposedJobMatchPercentSelf: it.proposedJobMatchPercentSelf,
                proposedJobMatchPercentValidated: it.proposedJobMatchPercentValidated,
                proposedJobMatchUpdatedOn: it.proposedJobMatchUpdatedOn,
                lastUpdatedOn: it.lastUpdatedOn,
                lastUpdatedOn1: it.lastUpdatedOn1,
                lastUpdatedOn2: it.lastUpdatedOn2,
                lastUpdatedOn3: it.lastUpdatedOn3,
            };
        });
    };

    openPersonDetail = (person) => {
        this.setState({ showDialogUserDetailTs: new Date(), selectedPerson: person });
    };

    pad2 = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    handleExportJobMatches = () => {
        this.props.onJob.exportJobMatchesDataExcel().then(() => {
            if (!this.props.loadingExport && !this.props.jobsError) {
                if (this.props.exportData) {
                    var today = new Date();
                    var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                    FileDownload(this.props.exportData, `ExportAssignments_${strToday}.xlsx`);
                }
            }
        });
    };

    handleImportJobMatches = () => {
        this.setState({ showDialogImportTs: new Date() });
    };

    handleImportSuccess = () => {
        this.handleRefresh();
    };

    handleChangeShowEndorsedMatchPercent = () => {
        const config = {
            allowSelfRegistration: this.state.config.allowSelfRegistration,
            selfRegistransMarkedAsCandidate: this.state.config.selfRegistransMarkedAsCandidate,
            restrictRegistransEmailDomain: this.state.config.restrictRegistransEmailDomain,
            selfRegistrationWording: this.state.config.selfRegistrationWording,
            selfRegistrationConfirmationWording: this.state.config.selfRegistrationConfirmationWording,
            assessmentId: this.state.config.assessmentId,
            hasCurrentJobLocked: this.state.config.hasCurrentJobLocked,
            hasFutureJobLocked: this.state.config.hasFutureJobLocked,
            whoAllowApproval: this.state.config.whoAllowApproval,
            enableApprovalRequest: this.state.config.enableApprovalRequest,
            allowUsersToBeForgotten: this.state.config.allowUsersToBeForgotten,
            defaultActivityStatementFrequency: this.state.config.defaultActivityStatementFrequency,
            reminderRepeatInterval: this.state.config.reminderRepeatInterval,
            reminderTotal: this.state.config.reminderTotal,
            assessmentTutorialUrl: this.state.config.assessmentTutorialUrl,
            assessmentDescription: this.state.config.assessmentDescription,
            assessmentSurveyTargetCompletionInterval: this.state.config.assessmentSurveyTargetCompletionInterval,
            jobProfileReportFormat: this.state.config.jobProfileReportFormat,
            selfAssessedProfileReportFormat: this.state.config.selfAssessedProfileReportFormat,
            suppressActivityNotification: this.state.config.suppressActivityNotification,
            allowUserSuppressActivityNotification: this.state.config.allowUserSuppressActivityNotification,
            allowCertification: this.state.config.allowCertification,
            nav1Label: this.state.config.nav1Label,
            nav1Link: this.state.config.nav1Link,
            nav2Label: this.state.config.nav2Label,
            nav2Link: this.state.config.nav2Link,
            nav3Label: this.state.config.nav3Label,
            nav3Link: this.state.config.nav3Link,
            stepPanelBackgroundColor: this.state.config.stepPanelBackgroundColor,
            stepPanelFontColor: this.state.config.stepPanelFontColor,
            companyName: this.state.config.companyName,
            entityName: this.state.config.entityName,
            automaticUserInactivationLoginDays: this.state.config.automaticUserInactivationLoginDays,
            showEndorsedMatchPercentInRequirementAssign: !this.state.config.showEndorsedMatchPercentInRequirementAssign,
        };

        this.setState({ config });

        this.props.onConfig.updateAdminConfig(config).then(() => {});
    };

    onTableFilterChange = (e, searchType) => {
        let columnSearchKeywords = this.state.columnSearchKeywords;
        if (!columnSearchKeywords) {
            columnSearchKeywords = [];
        }

        if (columnSearchKeywords.find((it) => it.name == e.target.name)) {
            let columnSearchKeyword = columnSearchKeywords.find((it) => it.name == e.target.name);
            columnSearchKeyword.value = e.target.value;
        } else {
            columnSearchKeywords.push({
                name: e.target.name,
                value: e.target.value,
                searchType,
            });
        }

        this.setState({ columnSearchKeywords });
    };

    render() {
        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Assign Requirements");

        const { jobs, jobMatchPersons, searchKeyword, searchProperties, columnSearchKeywords } = this.state;
        const openPersonDetail = this.openPersonDetail;
        const onTableFilterChange = this.onTableFilterChange;

        const sortName = (rowA, rowB) => {
            let nameA = ((rowA.firstName ? rowA.firstName.trim().toLowerCase() : "") + " " + (rowA.lastName ? rowA.lastName.trim().toLowerCase() : "")).trim();
            let nameB = ((rowB.firstName ? rowB.firstName.trim().toLowerCase() : "") + " " + (rowB.lastName ? rowB.lastName.trim().toLowerCase() : "")).trim();

            if (nameA > nameB) {
                return 1;
            }

            if (nameB > nameA) {
                return -1;
            }

            return 0;
        };

        const sortCurrentMatch = (rowA, rowB) => {
            let jobMatchA = rowA.currentJobMatchId ? `${rowA.currentJobMatch}${rowA.currentJobReference ? ` ${rowA.currentJobReference}` : ""}` : "";
            let jobMatchB = rowB.currentJobMatchId ? `${rowB.currentJobMatch}${rowB.currentJobReference ? ` ${rowB.currentJobReference}` : ""}` : "";

            if (jobMatchA > jobMatchB) {
                return 1;
            }

            if (jobMatchB > jobMatchA) {
                return -1;
            }

            return 0;
        };

        const sortCurrentMatchPercentSelf = (rowA, rowB) => {
            let jobMatchA = rowA.currentJobMatchId ? rowA.currentJobMatchPercentSelf : -1;
            let jobMatchB = rowB.currentJobMatchId ? rowB.currentJobMatchPercentSelf : -1;

            if (jobMatchA > jobMatchB) {
                return 1;
            }

            if (jobMatchB > jobMatchA) {
                return -1;
            }

            return 0;
        };

        const sortCurrentMatchPercentValidated = (rowA, rowB) => {
            let jobMatchA = rowA.currentJobMatchId ? rowA.currentJobMatchPercentValidated : -1;
            let jobMatchB = rowB.currentJobMatchId ? rowB.currentJobMatchPercentValidated : -1;

            if (jobMatchA > jobMatchB) {
                return 1;
            }

            if (jobMatchB > jobMatchA) {
                return -1;
            }

            return 0;
        };

        const sortFutureMatch = (rowA, rowB) => {
            let jobMatchA = rowA.futureJobMatchId ? `${rowA.futureJobMatch}${rowA.futureJobReference ? ` ${rowA.futureJobReference}` : ""}` : "";
            let jobMatchB = rowB.futureJobMatchId ? `${rowB.futureJobMatch}${rowB.futureJobReference ? ` ${rowB.futureJobReference}` : ""}` : "";

            if (jobMatchA > jobMatchB) {
                return 1;
            }

            if (jobMatchB > jobMatchA) {
                return -1;
            }

            return 0;
        };

        const sortFutureMatchPercentSelf = (rowA, rowB) => {
            let jobMatchA = rowA.futureJobMatchId ? rowA.futureJobMatchPercentSelf : -1;
            let jobMatchB = rowB.futureJobMatchId ? rowB.futureJobMatchPercentSelf : -1;

            if (jobMatchA > jobMatchB) {
                return 1;
            }

            if (jobMatchB > jobMatchA) {
                return -1;
            }

            return 0;
        };

        const sortFutureMatchPercentValidated = (rowA, rowB) => {
            let jobMatchA = rowA.futureJobMatchId ? rowA.futureJobMatchPercentValidated : -1;
            let jobMatchB = rowB.futureJobMatchId ? rowB.futureJobMatchPercentValidated : -1;

            if (jobMatchA > jobMatchB) {
                return 1;
            }

            if (jobMatchB > jobMatchA) {
                return -1;
            }

            return 0;
        };

        const sortProposedMatch = (rowA, rowB) => {
            let jobMatchA = rowA.proposedJobMatchId ? `${rowA.proposedJobMatch}${rowA.proposedJobReference ? ` ${rowA.proposedJobReference}` : ""}` : "";
            let jobMatchB = rowB.proposedJobMatchId ? `${rowB.proposedJobMatch}${rowB.proposedJobReference ? ` ${rowB.proposedJobReference}` : ""}` : "";

            if (jobMatchA > jobMatchB) {
                return 1;
            }

            if (jobMatchB > jobMatchA) {
                return -1;
            }

            return 0;
        };

        const sortProposedMatchPercentSelf = (rowA, rowB) => {
            let jobMatchA = rowA.proposedJobMatchId ? rowA.proposedJobMatchPercentSelf : -1;
            let jobMatchB = rowB.proposedJobMatchId ? rowB.proposedJobMatchPercentSelf : -1;

            if (jobMatchA > jobMatchB) {
                return 1;
            }

            if (jobMatchB > jobMatchA) {
                return -1;
            }

            return 0;
        };

        const sortProposedMatchPercentValidated = (rowA, rowB) => {
            let jobMatchA = rowA.proposedJobMatchId ? rowA.proposedJobMatchPercentValidated : -1;
            let jobMatchB = rowB.proposedJobMatchId ? rowB.proposedJobMatchPercentValidated : -1;

            if (jobMatchA > jobMatchB) {
                return 1;
            }

            if (jobMatchB > jobMatchA) {
                return -1;
            }

            return 0;
        };

        const sortPosition = (rowA, rowB) => {
            let positionA = rowA.position ? rowA.position.toLowerCase() : "";
            let positionB = rowB.position ? rowB.position.toLowerCase() : "";

            if (positionA > positionB) {
                return 1;
            }

            if (positionB > positionA) {
                return -1;
            }

            return 0;
        };

        const sortTeam = (rowA, rowB) => {
            let teamA = rowA.team ? rowA.team.toLowerCase() : "";
            let teamB = rowB.team ? rowB.team.toLowerCase() : "";

            if (teamA > teamB) {
                return 1;
            }

            if (teamB > teamA) {
                return -1;
            }

            return 0;
        };

        const columns = [
            {
                id: "lastUpdatedOn",
                name: "Action",
                selector: (row) => row.lastUpdatedOn,
                sortable: false,
                width: "80px",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <FontAwesomeIcon id={`iconJobDetail${row.personId}`} icon={faInfoCircle} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => openPersonDetail(row)} />
                            <UncontrolledTooltip target={`iconJobDetail${row.personId}`} placement="bottom">
                                Person Info
                            </UncontrolledTooltip>
                        </div>
                    );
                },
            },
            {
                id: "firstName",
                name: (
                    <div style={{ width: "90%" }}>
                        Name
                        <br />
                        <input type="text" name="firstName" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "firstName") ? columnSearchKeywords.find((it) => it.name == "firstName").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.firstName,
                sortFunction: sortName,
                sortable: true,
                width: "150px",
                wrap: true,
                format: function (row, index) {
                    return `${row.firstName} ${row.lastName}`;
                },
            },
            {
                id: "email",
                name: (
                    <div style={{ width: "90%" }}>
                        Email Address
                        <br />
                        <input type="text" name="email" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "email") ? columnSearchKeywords.find((it) => it.name == "email").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.email,
                sortable: true,
                width: "200px",
                format: function (row, index) {
                    return <span style={{ wordBreak: "break-word" }}>{row.email}</span>;
                },
            },
            {
                id: "lastUpdatedOn1",
                name: (
                    <div style={{ width: "90%" }}>
                        Current Requirement
                        <br />
                        <input
                            type="text"
                            name="currentJobMatchReference"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "currentJobMatchReference") ? columnSearchKeywords.find((it) => it.name == "currentJobMatchReference").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.lastUpdatedOn1,
                sortable: true,
                sortFunction: sortCurrentMatch,
                width: "200px",
                wrap: true,
                format: function (row, index) {
                    let jobsSelections = [...jobs];
                    if (row.futureJobMatchId) {
                        const job = jobsSelections.find((it) => it.id == row.futureJobMatchId && (row.currentJobMatchId ? row.currentJobMatchId : null) != it.id);
                        if (job) {
                            const index = jobsSelections.indexOf(job);
                            jobsSelections.splice(index, 1);
                        }
                    }

                    if (row.proposedJobMatchId) {
                        const job = jobsSelections.find((it) => it.id == row.proposedJobMatchId && (row.currentJobMatchId ? row.currentJobMatchId : null) != it.id);
                        if (job) {
                            const index = jobsSelections.indexOf(job);
                            jobsSelections.splice(index, 1);
                        }
                    }

                    return (
                        <JobAssignmentCell
                            key={`LKRequirementAssignedCurrent${row.personId} `}
                            jobs={jobsSelections}
                            person={row}
                            jobMatch={row.currentJobMatch}
                            jobMatchId={row.currentJobMatchId}
                            jobReference={row.currentJobReference}
                            jobMatchType="Current"
                            allowEdit={pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Assign Current Requirement" && op.allow == true) ? true : false}
                        ></JobAssignmentCell>
                    );
                },
            },
            {
                id: "currentJobMatchPercentSelf",
                name: "Current Self Match",
                selector: (row) => row.currentJobMatchPercentSelf,
                sortable: true,
                sortFunction: sortCurrentMatchPercentSelf,
                width: "100px",
                format: function (row, index) {
                    return <div style={{ textAlign: "right" }}>{row.currentJobMatchPercentSelf}</div>;
                },
            },
            {
                id: "currentJobMatchPercentValidated",
                name: "Current Endorsed Match",
                selector: (row) => row.currentJobMatchPercentValidated,
                sortable: true,
                sortFunction: sortCurrentMatchPercentValidated,
                width: "100px",
                format: function (row, index) {
                    return <div style={{ textAlign: "right" }}>{row.currentJobMatchPercentValidated}</div>;
                },
                omit: !(this.state.config && this.state.config.showEndorsedMatchPercentInRequirementAssign),
            },
            {
                id: "lastUpdatedOn2",
                name: (
                    <div style={{ width: "90%" }}>
                        Future Requirement
                        <br />
                        <input
                            type="text"
                            name="futureJobMatchReference"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "futureJobMatchReference") ? columnSearchKeywords.find((it) => it.name == "futureJobMatchReference").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.lastUpdatedOn2,
                sortable: true,
                sortFunction: sortFutureMatch,
                width: "200px",
                wrap: true,
                format: function (row, index) {
                    let jobsSelections = [...jobs];
                    if (row.currentJobMatchId) {
                        const job = jobsSelections.find((it) => it.id == row.currentJobMatchId && (row.futureJobMatchId ? row.futureJobMatchId : null) != it.id);
                        if (job) {
                            const index = jobsSelections.indexOf(job);
                            jobsSelections.splice(index, 1);
                        }
                    }

                    if (row.proposedJobMatchId) {
                        const job = jobsSelections.find((it) => it.id == row.proposedJobMatchId && (row.futureJobMatchId ? row.futureJobMatchId : null) != it.id);
                        if (job) {
                            const index = jobsSelections.indexOf(job);
                            jobsSelections.splice(index, 1);
                        }
                    }

                    return (
                        <JobAssignmentCell
                            key={`LKRequirementAssignedFuture${row.personId} `}
                            jobs={jobsSelections}
                            person={row}
                            jobMatch={row.futureJobMatch}
                            jobMatchId={row.futureJobMatchId}
                            jobReference={row.futureJobReference}
                            jobMatchType="Future"
                            allowEdit={pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Assign Future Requirement" && op.allow == true) ? true : false}
                        ></JobAssignmentCell>
                    );
                },
            },
            {
                id: "futureJobMatchPercentSelf",
                name: "Future Self Match",
                selector: (row) => row.futureJobMatchPercentSelf,
                sortable: true,
                sortFunction: sortFutureMatchPercentSelf,
                width: "100px",
                format: function (row, index) {
                    return <div style={{ textAlign: "right" }}>{row.futureJobMatchPercentSelf}</div>;
                },
            },
            {
                id: "futureJobMatchPercentValidated",
                name: "Future Endorsed Match",
                selector: (row) => row.futureJobMatchPercentValidated,
                sortable: true,
                sortFunction: sortFutureMatchPercentValidated,
                width: "100px",
                format: function (row, index) {
                    return <div style={{ textAlign: "right" }}>{row.futureJobMatchPercentValidated}</div>;
                },
                omit: !(this.state.config && this.state.config.showEndorsedMatchPercentInRequirementAssign),
            },
            {
                id: "lastUpdatedOn3",
                name: (
                    <div style={{ width: "90%" }}>
                        Proposed Requirement
                        <br />
                        <input
                            type="text"
                            name="proposedJobMatchReference"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "proposedJobMatchReference") ? columnSearchKeywords.find((it) => it.name == "proposedJobMatchReference").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.lastUpdatedOn3,
                sortable: true,
                sortFunction: sortProposedMatch,
                width: "200px",
                wrap: true,
                format: function (row, index) {
                    let jobsSelections = [...jobs];
                    if (row.currentJobMatchId) {
                        const job = jobsSelections.find((it) => it.id == row.currentJobMatchId && (row.proposedJobMatchId ? row.proposedJobMatchId : null) != it.id);
                        if (job) {
                            const index = jobsSelections.indexOf(job);
                            jobsSelections.splice(index, 1);
                        }
                    }

                    if (row.futureJobMatchId) {
                        const job = jobsSelections.find((it) => it.id == row.futureJobMatchId && (row.proposedJobMatchId ? row.proposedJobMatchId : null) != it.id);
                        if (job) {
                            const index = jobsSelections.indexOf(job);
                            jobsSelections.splice(index, 1);
                        }
                    }

                    return (
                        <JobAssignmentCell
                            key={`LKRequirementAssignedProposed${row.personId} `}
                            jobs={jobsSelections}
                            person={row}
                            jobMatch={row.proposedJobMatch}
                            jobMatchId={row.proposedJobMatchId}
                            jobReference={row.proposedJobReference}
                            jobMatchType="Proposed"
                            allowEdit={pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Assign Proposed Requirement" && op.allow == true) ? true : false}
                        ></JobAssignmentCell>
                    );
                },
            },
            {
                id: "proposedJobMatchPercentSelf",
                name: "Proposed Self Match",
                selector: (row) => row.proposedJobMatchPercentSelf,
                sortable: true,
                sortFunction: sortProposedMatchPercentSelf,
                width: "100px",
                format: function (row, index) {
                    return <div style={{ textAlign: "right" }}>{row.proposedJobMatchPercentSelf}</div>;
                },
            },
            {
                id: "proposedJobMatchPercentValidated",
                name: "Proposed Endorsed Match",
                selector: (row) => row.proposedJobMatchPercentValidated,
                sortable: true,
                sortFunction: sortProposedMatchPercentValidated,
                width: "100px",
                format: function (row, index) {
                    return <div style={{ textAlign: "right" }}>{row.proposedJobMatchPercentValidated}</div>;
                },
                omit: !(this.state.config && this.state.config.showEndorsedMatchPercentInRequirementAssign),
            },
            {
                id: "position",
                name: (
                    <div style={{ width: "90%" }}>
                        Position
                        <br />
                        <input type="text" name="position" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "position") ? columnSearchKeywords.find((it) => it.name == "position").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.position,
                sortable: true,
                sortFunction: sortPosition,
                width: "100px",
                wrap: true,
                format: function (row, index) {
                    return row.position;
                },
            },
            {
                id: "team",
                name: (
                    <div style={{ width: "90%" }}>
                        Team
                        <br />
                        <input type="text" name="team" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "team") ? columnSearchKeywords.find((it) => it.name == "team").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.team,
                sortable: true,
                sortFunction: sortTeam,
                width: "100px",
                wrap: true,
                format: function (row, index) {
                    return row.team;
                },
            },
        ];

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                <JobAssignmentBulkUpload handleImportSuccess={this.handleImportSuccess} showDialog={this.state.showDialogImportTs} generateAlert={this.generateAlert}></JobAssignmentBulkUpload>
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <UserDetail disableEdit={true} selectedPerson={this.state.selectedPerson} showDialog={this.state.showDialogUserDetailTs} generateAlert={this.generateAlert}></UserDetail>
                        <div>
                            <Row>
                                <Col md="6" sm="8">
                                    <SkillsTxTableSearchBar
                                        searchKeyword={this.state.searchKeyword}
                                        onChange={(searchKeyword) => {
                                            this.setState({ searchKeyword });
                                        }}
                                    ></SkillsTxTableSearchBar>
                                </Col>
                                <Col md="6" sm="4">
                                    <SkillsTxTableClearSearch
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                        }}
                                        color="primary"
                                        disabled={this.props.jobsLoading || this.state.isUpdating}
                                        onClear={() => {
                                            this.setState({ searchKeyword: "" });
                                        }}
                                    ></SkillsTxTableClearSearch>
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        onClick={this.handleRefresh}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                        }}
                                        disabled={this.props.jobsLoading || this.state.isUpdating}
                                    >
                                        <FontAwesomeIcon icon={faSync} /> Refresh
                                    </Button>
                                    &nbsp;&nbsp;
                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Export" && op.allow == true) && (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={this.handleExportJobMatches}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                }}
                                                disabled={this.props.jobsLoading || this.props.loadingExport}
                                            >
                                                <FontAwesomeIcon icon={faFileExcel} /> Export
                                            </Button>
                                            &nbsp;&nbsp;
                                        </React.Fragment>
                                    )}
                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Import" && op.allow == true) && (
                                        <Button
                                            color="primary"
                                            onClick={this.handleImportJobMatches}
                                            style={{
                                                fontSize: "16px",
                                                height: "40px",
                                            }}
                                            disabled={this.props.jobsLoading}
                                        >
                                            <FontAwesomeIcon icon={faFileExcel} /> Import &nbsp;&nbsp;
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: "10px" }}>
                                <Col md="12" style={{ paddingBottom: "10px" }}>
                                    <Input
                                        type="checkbox"
                                        id="chkShowEndorsedMatchPercent"
                                        onClick={() => {
                                            this.handleChangeShowEndorsedMatchPercent();
                                        }}
                                        checked={this.state.config && this.state.config.showEndorsedMatchPercentInRequirementAssign}
                                    />
                                    &nbsp;&nbsp;
                                    <Label check for="chkShowEndorsedMatchPercent">
                                        Show Endorsed Match Percent
                                    </Label>
                                </Col>
                                <Col md="12" style={{ overflowX: "scroll" }}>
                                    <SkillsTxTable
                                        columns={columns}
                                        data={jobMatchPersons}
                                        pagination={true}
                                        searchKeyword={searchKeyword}
                                        searchProperties={searchProperties}
                                        columnSearchKeywords={columnSearchKeywords}
                                        progressPending={this.props.jobsLoading}
                                        headCellsStyle={{
                                            style: {
                                                fontWeight: "bold",
                                                fontSize: "12px",
                                                border: "1px solid #dee2e6",
                                                whiteSpace: "wrap",
                                                paddingTop: "5px",
                                                paddingBottom: "5px",
                                                paddingRight: "8px",
                                                paddingLeft: "8px",
                                            },
                                        }}
                                    ></SkillsTxTable>
                                </Col>
                            </Row>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,

        configLoading: state.config.loading,
        config: state.config.config,

        jobsLoading: state.job.loading,
        jobsError: state.job.error,
        jobs: state.job.jobs,
        jobMatchPersons: state.job.jobMatchPersons,

        exportData: state.job.exportData,
        loadingExport: state.job.loadingExport,

        pageAssignments: state.adminPerson.pageAssignments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
        onConfig: bindActionCreators(configActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageJobAssignment));
