import AdminRegistrationReasonAPI from "./../../../common/api/adminservice/AdminRegistrationReasonService";
import * as adminActionTypes from "./adminActionTypes";

export const getRegistrationReasonsStart = () => {
    return {
        type: adminActionTypes.GET_REGISTRATION_REASONS_START,
    };
};

export const getRegistrationReasonsSuccess = (registrationReasons) => {
    return {
        type: adminActionTypes.GET_REGISTRATION_REASONS_SUCCESS,
        registrationReasons,
    };
};

export const getRegistrationReasonsFail = (error) => {
    return {
        type: adminActionTypes.GET_REGISTRATION_REASONS_FAIL,
        error: error,
    };
};

export const getRegistrationReasons = () => {
    return (dispatch) => {
        dispatch(getRegistrationReasonsStart());

        return AdminRegistrationReasonAPI.getRegistrationReasons()
            .then((res) => {
                dispatch(getRegistrationReasonsSuccess(res));
            })
            .catch((err) => {
                dispatch(getRegistrationReasonsFail(err));
            });
    };
};

export const addRegistrationReasonStart = () => {
    return {
        type: adminActionTypes.ADD_REGISTRATION_REASON_START,
    };
};

export const addRegistrationReasonSuccess = (addedRegistrationReason) => {
    return {
        type: adminActionTypes.ADD_REGISTRATION_REASON_SUCCESS,
        addedRegistrationReason,
    };
};

export const addRegistrationReasonFail = (error) => {
    return {
        type: adminActionTypes.ADD_REGISTRATION_REASON_FAIL,
        error: error,
    };
};

export const addRegistrationReason = (registrationReason) => {
    return (dispatch) => {
        dispatch(addRegistrationReasonStart());

        return AdminRegistrationReasonAPI.createRegistrationReason(registrationReason)
            .then((res) => {
                dispatch(addRegistrationReasonSuccess(res));
            })
            .catch((err) => {
                dispatch(addRegistrationReasonFail(err));
            });
    };
};

export const updateRegistrationReasonStart = () => {
    return {
        type: adminActionTypes.UPDATE_REGISTRATION_REASON_START,
    };
};

export const updateRegistrationReasonSuccess = (updatedRegistrationReason) => {
    return {
        type: adminActionTypes.UPDATE_REGISTRATION_REASON_SUCCESS,
        updatedRegistrationReason,
    };
};

export const updateRegistrationReasonFail = (error) => {
    return {
        type: adminActionTypes.UPDATE_REGISTRATION_REASON_FAIL,
        error: error,
    };
};

export const updateRegistrationReason = (id, registrationReason) => {
    return (dispatch) => {
        dispatch(updateRegistrationReasonStart());

        return AdminRegistrationReasonAPI.updateRegistrationReason(id, registrationReason)
            .then((res) => {
                dispatch(updateRegistrationReasonSuccess(res));
            })
            .catch((err) => {
                dispatch(updateRegistrationReasonFail(err));
            });
    };
};

export const deleteRegistrationReasonStart = () => {
    return {
        type: adminActionTypes.DELETE_REGISTRATION_REASON_START,
    };
};

export const deleteRegistrationReasonSuccess = (registrationReasons) => {
    return {
        type: adminActionTypes.DELETE_REGISTRATION_REASON_SUCCESS,
        registrationReasons,
    };
};

export const deleteRegistrationReasonFail = (error) => {
    return {
        type: adminActionTypes.DELETE_REGISTRATION_REASON_FAIL,
        error: error,
    };
};

export const deleteRegistrationReason = (id) => {
    return (dispatch) => {
        dispatch(deleteRegistrationReasonStart());

        return AdminRegistrationReasonAPI.deleteRegistrationReason(id)
            .then((res) => {
                dispatch(deleteRegistrationReasonSuccess(res));
            })
            .catch((err) => {
                dispatch(deleteRegistrationReasonFail(err));
            });
    };
};

export const moveRegistrationReasonStart = () => {
    return {
        type: adminActionTypes.MOVE_REGISTRATION_REASON_START,
    };
};

export const moveRegistrationReasonSuccess = (registrationReasons) => {
    return {
        type: adminActionTypes.MOVE_REGISTRATION_REASON_SUCCESS,
        registrationReasons,
    };
};

export const moveRegistrationReasonFail = (error) => {
    return {
        type: adminActionTypes.MOVE_REGISTRATION_REASON_FAIL,
        error: error,
    };
};

export const moveRegistrationReasonUp = (id) => {
    return (dispatch) => {
        dispatch(moveRegistrationReasonStart());

        return AdminRegistrationReasonAPI.moveRegistrationReasonUp(id)
            .then((res) => {
                dispatch(moveRegistrationReasonSuccess(res));
            })
            .catch((err) => {
                dispatch(moveRegistrationReasonFail(err));
            });
    };
};

export const moveRegistrationReasonDown = (id) => {
    return (dispatch) => {
        dispatch(moveRegistrationReasonStart());

        return AdminRegistrationReasonAPI.moveRegistrationReasonDown(id)
            .then((res) => {
                dispatch(moveRegistrationReasonSuccess(res));
            })
            .catch((err) => {
                dispatch(moveRegistrationReasonFail(err));
            });
    };
};
