import { CloseCircleTwoTone, CheckCircleTwoTone } from "@ant-design/icons";
import { Button, Row, Input, Collapse, Descriptions } from "antd";
import React, { useCallback, useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useImmer } from "use-immer";

const { Panel } = Collapse;

const ContainerConfirm = styled.div`
  padding: 5px;
  width: 400px;

  .btn-danger {
    background-color: red;
    color: white;
  }

  .btn-success {
    background-color: #52c41a;
    color: white;
  }
`;

const { TextArea } = Input;

// Wrapping the component with React.memo to prevent unnecessary re-renders.
// This is useful when the parent component renders often but props for AcceptDenyModal don't change.
const AcceptDenyModal = ({ open, onCancel, onOk, data, contentTranslations, contentTranslator }) => {
  const [comment, setComment] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [state, setState] = useImmer({
    contentTranslations: contentTranslations
  });

  const handleCancel = (event) => {
    onCancel?.(event); // Optional chaining ensures that onAccept is called only if it's provided.
    // Removed setIsOpen as we are now directly using the 'open' prop for controlling visibility.
    setIsOpen(false);
  };

  const handleOk = (event) => {
    onOk?.(event, comment); // Similar to handleOk, using optional chaining for onOk.
    // Removed setIsOpen as we are now directly using the 'open' prop for controlling visibility.
    setIsOpen(false);
  };

  // Handler for when the comment input field value changes
  const onCommentChanged = useCallback((event) => {
    // Update the comment in the modal's data
    setComment(event.target.value);
  }); // No dependencies since it doesn't rely on external state or props

  // Compute the state for Accept/Deny display based on the modal's data
  const modalValid = useMemo(() => {
    // Default state when data is undefined
    if (data === undefined) {
      return { title: "", text: "", isCommentRequired: false };
    }

    // Determine the title and text based on whether it's an Accept or Deny action
    const title = data.isAccept ? contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestConfirmationAcceptTitle", "<span style='color:#52c41a;'>Accept</span> request") : contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestConfirmationDenyTitle", "<span style='color:green;'>Deny</span> request");
    const okButton = data.isAccept ? contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestConfirmationButtonAccept", "Accept") : contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestConfirmationButtonDeny", "Deny");
    const cancelButton = contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestConfirmationButtonCancel", "Cancel");
    const text = data.isAccept 
      ? contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestConfirmationAcceptMessage", "Before accepting this request you must provide some comments for the requester. This will be included in the confirmation email.") 
      : contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestConfirmationDenyMessage", "Before denying this request you must provide some comments for the requester. This will be included in the denial email. Please fully explain the reasons.");

    // Determine if the comment is required (it is required if it's empty)
    const isCommentRequired = comment.trim() === "";

    return { title, okButton, cancelButton, text, isCommentRequired };
  }, [data, comment]); // Depend on data as it’s the only piece of state used

  // Determine the button class name based on the validation state and modal type (accept/deny)
  const okButtonClassName =
    modalValid.isCommentRequired === true ||
    (data?.isAccept ? "btn-success" : "btn-danger");

  useEffect(() => {
    setIsOpen(open);
    if (open === true) {
      setComment("");
    }
  }, [open]);

  // Return null to render nothing if the modal is not open.
  // This is an early return pattern, a common optimization in React for conditional rendering
  if (!isOpen) return null;

  return (
    // ContainerConfirm is a wrapper component, likely styled, for the modal content.
    <ContainerConfirm>
      {/* Row is a layout component, probably from a UI library, for arranging items in a row.
          The 'gutter' prop likely adds spacing between the columns in the row. */}
      <Row gutter={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
        <div style={{display:"flex"}}>
          {data?.isAccept ? (
            <CheckCircleTwoTone
              className="mr-2"
              twoToneColor="#52c41a"
              style={{ fontSize: "20px" }}
            />
          ) : (
            <CloseCircleTwoTone
              className="mr-2"
              twoToneColor="red"
              style={{ fontSize: "20px" }}
            />
          )}
          <div style={{marginTop:"-2px"}} dangerouslySetInnerHTML={{
            __html: modalValid.title,
          }}></div>
        </div>
      </Row>
      {/* Another Row for action buttons, with increased gutter spacing and
      additional styling for centering and top margin. */}
      <Row className="mt-3" gutter={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
        <div className="text-left">
          {modalValid.text} {/* Display additional text/instructions */}
        </div>
      </Row>
      {/* Another Row for action buttons, with increased gutter spacing and
      additional styling for centering and top margin. */}
      <Row className="mt-3" gutter={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
        <Collapse style={{ width: "100%" }}>
          <Panel header={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestConfirmationPersonDetailHeader", "Person Detail")} key={data?.data ?? "1"}>
            <Descriptions size="small" bordered>
              <Descriptions.Item label={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestConfirmationPersonDetailName", "Name")} span={3}>
                {data?.data.name ?? "-"}
              </Descriptions.Item>
              <Descriptions.Item label={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestConfirmationPersonDetailEmail", "Email")} span={3}>
                {data?.data.email ?? "-"}
              </Descriptions.Item>
              <Descriptions.Item label={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestConfirmationPersonDetailMatch", "Match")} span={3}>
                {data?.data.matchedSkillPercent
                  ? `${data.data.matchedSkillPercent} %`
                  : "-"}
              </Descriptions.Item>
              <Descriptions.Item label={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestConfirmationPersonDetailSkills", "Skills")} span={3}>
                {data?.data.skills.join(", ") ?? "-"}
              </Descriptions.Item>
              <Descriptions.Item label={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestConfirmationPersonDetailProfiles", "Profiles")} span={3}>
                {data?.data.preferences.join(", ") ?? "-"}
              </Descriptions.Item>
            </Descriptions>
          </Panel>
        </Collapse>
      </Row>
      {/* Another Row for action buttons, with increased gutter spacing and
      additional styling for centering and top margin. */}
      <Row gutter={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
        {/* Text area for the user to input a comment. Shows an error status if a comment is required but not provided. */}
        <TextArea
          status={modalValid.isCommentRequired ? "error" : ""}
          value={comment}
          onChange={onCommentChanged}
          className="mt-4"
          rows={4}
          placeholder={
            modalValid.isCommentRequired ? contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestConfirmationCommentPlaceholder", "Comment is required") : "Comment"
          }
        />
      </Row>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        className="mt-4 justify-content-end"
      >
        {/* Button component for the 'OK' action, styled as a primary and success button, with a right margin.
            'handleOk' is a function that will be called when this button is clicked. */}
        <Button
          shape="round"
          disabled={modalValid.isCommentRequired} // Disable the OK button if a comment is required but not provided
          className={okButtonClassName}
          key="ok"
          onClick={handleOk}
        >
          {modalValid.okButton}
        </Button>
        {/* Another Button for the 'Cancel' action, styled as a primary and danger button.
            'handleCancel' is a function that will be called when this button is clicked. */}
        <Button
          className="ml-2"
          shape="round"
          key="cancel"
          onClick={handleCancel}
        >
          {modalValid.cancelButton}
        </Button>
      </Row>
    </ContainerConfirm>
  );
};

export default AcceptDenyModal;
