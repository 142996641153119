import * as groupValueActions from "../../store/actions/groupValueAction";
import * as personActions from "../../store/actions/personAction";
import changeInput from "../../utils/changeInput";
import ContentTranslator from "../translator/ContentTranslator";
import "./../../custom-card-profile.css";
import UpdateProfilePicture from "./UpdateProfilePicture";
import { faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { Col, Container, Input, Modal, ModalBody, Row, Spinner, Table, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

//import sampleProfile from '../../assets/img/lgo/sample-profile.png';

const cardProfileStyle = {
    fontFamily: "IBMPlexSans",
    fontSize: "11px",
    color: "#2B2B2B",
};

class CardProfile extends React.Component {
    static displayName = CardProfile.name;
    constructor(props) {
        super(props);

        this.state = {
            person: null,
            account: null,
            showModal: false,
            modal: false,
            setModal: false,
            loading: false,
            editMode: false,
            seniorityOptions: [],
            //optionSeniority: CONSTANTS.OPTIONS_SENIORITY,
            contractTypeOptions: [],
            //optionEmploymentContract: CONSTANTS.OPTIONS_EMPLOYMENT_CONTRACT,
            yearRangeJobOptions: [],
            //optionTimeInCurrentRole: CONSTANTS.OPTIONS_TIME_IN_CURRENT_ROLE,
            yearRangeOrgOptions: [],
            //optionTimeInOrganisation: CONSTANTS.OPTIONS_TIME_IN_ORGANISATION,
            form: {
                firstName: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                lastName: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                seniority: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                employmentContract: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                timeInCurrentRole: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                timeInOrganisation: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                recentOrCurrentRole: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                salutation: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: false,
                    touched: false,
                },
                allowNotifications: {
                    validation: {
                        required: false,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: false,
                    touched: false,
                },
            },
            isFormValid: false,
            updating: false,
            showProfilePictureModal: null,
            selectedPicture: null,
            profilePicture: null,
        };

        this.initData = this.initData.bind(this);
        this.startEdit = this.startEdit.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.submitEdit = this.submitEdit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.imageRef = React.createRef();
    }

    componentDidMount() {
        this.initData();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    initData() {
        if (this.props.managedPeople) {
            this.setState({ loading: true });
            const person = this.props.managedPeople.personB;

            let account;
            if (person.accounts && person.accounts.length > 0) {
                account = person.accounts.sort((a, b) => b.createdOn > a.createdOn)[0];
            }

            this.setState({ person, account, loading: false });

            this.props.onPerson.downloadManagedPersonProfilePicture(person.email).then(() => {
                if (this.props.profilePicture) {
                    this.setState({ profilePicture: this.props.profilePicture });
                }
            });
        } else {
            this.setState({ loading: true });
            this.props.onPerson.getPersonMyProfile().then(() => {
                if (!this.props.loading && this.props.person) {
                    const person = this.props.person;

                    let account;
                    if (person.accounts && person.accounts.length > 0) {
                        account = person.accounts.sort((a, b) => b.createdOn > a.createdOn)[0];
                    }

                    this.setState({ person, account });
                }
                this.setState({ loading: this.props.loading });
            });

            this.props.onPerson.downloadPersonProfilePicture().then(() => {
                if (this.props.profilePicture) {
                    this.setState({ profilePicture: this.props.profilePicture });
                }
            });
        }

        Promise.all([
            this.props.onGroupValue.getSeniorityOptions(this.props.language ? this.props.language.id : null),
            this.props.onGroupValue.getContractTypeOptions(this.props.language ? this.props.language.id : null),
            this.props.onGroupValue.getYearRangeJobOptions(this.props.language ? this.props.language.id : null),
            this.props.onGroupValue.getYearRangeOrgOptions(this.props.language ? this.props.language.id : null),
        ])
            .then(() => {
                this.setState({
                    seniorityOptions: this.props.seniorityOptions ? this.props.seniorityOptions : [],
                    contractTypeOptions: this.props.contractTypeOptions ? this.props.contractTypeOptions : [],
                    yearRangeJobOptions: this.props.yearRangeJobOptions ? this.props.yearRangeJobOptions : [],
                    yearRangeOrgOptions: this.props.yearRangeOrgOptions ? this.props.yearRangeOrgOptions : [],
                });
            })
            .catch((err) => {});
    }

    pictureUpdated = () => {
        if (this.props.managedPeople) {
            this.props.onPerson.downloadManagedPersonProfilePicture(person.email).then(() => {
                if (this.props.profilePicture) {
                    this.setState({ profilePicture: this.props.profilePicture });
                }
            });
        } else {
            this.props.onPerson.downloadPersonProfilePicture().then(() => {
                if (this.props.profilePicture) {
                    this.setState({ profilePicture: this.props.profilePicture });
                }
            });
        }
    };

    startEdit() {
        if (!this.state.editMode) {
            const { person, account } = this.state;

            let form = {
                firstName: {
                    validation: {
                        required: true,
                    },
                    value: person.firstName,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                lastName: {
                    validation: {
                        required: true,
                    },
                    value: person.lastName,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                accountId: {
                    validation: {
                        required: true,
                    },
                    value: account.id,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                seniority: {
                    validation: {
                        required: true,
                    },
                    value: account.seniority,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                employmentContract: {
                    validation: {
                        required: true,
                    },
                    value: account.employmentContract,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                timeInCurrentRole: {
                    validation: {
                        required: true,
                    },
                    value: account.timeInCurrentRole,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                timeInOrganisation: {
                    validation: {
                        required: true,
                    },
                    value: account.timeInOrganisation,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                recentOrCurrentRole: {
                    validation: {
                        required: true,
                    },
                    value: account.recentOrCurrentRole ? account.recentOrCurrentRole.replace("<Enter StringVal>", "") : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },

                salutation: {
                    validation: {
                        required: false,
                    },
                    value: person.salutation,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                allowNotifications: {
                    validation: {
                        required: false,
                    },
                    value: person.allowNotifications,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            };

            this.setState({ form, editMode: true, isFormValid: true });
        }
    }

    cancelEdit() {
        this.setState({ editMode: false });
    }

    submitEdit() {
        this.setState({ updating: true });
        const person = {
            firstName: this.state.form.firstName.value,
            lastName: this.state.form.lastName.value,
            accountId: this.state.form.accountId.value,
            seniority: this.state.form.seniority.value,

            employmentContract: this.state.form.employmentContract.value,
            timeInCurrentRole: this.state.form.timeInCurrentRole.value,
            timeInOrganisation: this.state.form.timeInOrganisation.value,

            position: this.state.form.recentOrCurrentRole.value,
            recentOrCurrentRole: this.state.form.recentOrCurrentRole.value,

            salutation: this.state.form.salutation.value,
            allowNotifications: this.state.form.allowNotifications.value,
        };

        this.props.onPerson.updatePersonMyProfile(person).then(() => {
            if (!this.props.updating) {
                if (this.props.error) {
                    this.props.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    this.props.generateAlert("success", "Profile updated");

                    const person = this.props.person;

                    let account;
                    if (person.accounts && person.accounts.length > 0) {
                        account = person.accounts.sort((a, b) => b.createdOn > a.createdOn)[0];
                    }

                    this.setState({ person, account, editMode: false });
                }
            }
            this.setState({ updating: this.props.updating });
        });
    }

    eventClick = () => {
        this.setState({
            showModal: null,
        });
    };

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
            editMode: false,
            updating: false,
        });
    };

    showUploadProfilePicture = () => {
        this.imageRef.current.click();
    };

    handlePictureChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({ showProfilePictureModal: new Date(), selectedPicture: reader.result });
            });
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    render() {
        const { person, account, form } = this.state;

        return (
            <div style={cardProfileStyle}>
                <input ref={this.imageRef} type="file" style={{ display: "none" }} accept="image/*" onChange={this.handlePictureChange} />
                <UpdateProfilePicture pictureUpdated={this.pictureUpdated} showModal={this.state.showProfilePictureModal} src={this.state.selectedPicture} person={person} generateAlert={this.props.generateAlert}></UpdateProfilePicture>
                {person && (
                    <Modal isOpen={this.state.showModal} toggle={this.toggleModal} size="lg" centered={true}>
                        <ModalBody>
                            <Container>
                                <Row>
                                    <Col xs="4" className="border-right">
                                        <div style={{ textAlign: "center" }}>
                                            <img style={{ borderRadius: "50%" }} src={person && this.state.profilePicture ? URL.createObjectURL(this.state.profilePicture) : process.env.PUBLIC_URL + "/assets/img/Blank Profile.png"} alt="Profile Avatar" width="159" height="159" />
                                            {!this.props.managedPeople && (
                                                <div style={{ position: "absolute", top: "0px", right: "5px" }}>
                                                    <button id="btnEditProfilePicture2" className="btn btn-light btnSecondary-Style" onClick={this.showUploadProfilePicture}>
                                                        <FontAwesomeIcon icon={faPencilAlt} />
                                                    </button>
                                                    <UncontrolledTooltip target={`btnEditProfilePicture2`} placement="bottom">
                                                        <ContentTranslator page="SfiaProfileTooltip" name="PersonalDetailsButtonEditProfilePictureTooltip" contentText="Edit your profile picture" />
                                                    </UncontrolledTooltip>
                                                </div>
                                            )}
                                        </div>
                                        <p className="text-center mb-0">
                                            <b>
                                                {person.firstName} {person.lastName}
                                            </b>
                                        </p>
                                        <p className="text-center mb-0">{person.position}</p>
                                        <p></p>
                                        <p className="text-center mb-0">
                                            {!this.props.managedPeople &&
                                                (this.state.editMode ? (
                                                    <React.Fragment>
                                                        <button className={`btn ${!this.state.isFormValid || this.state.updating ? "btn-light" : "btn-primary"} btnPrimary-Style`} style={{ width: "75px" }} onClick={this.submitEdit} disabled={!this.state.isFormValid || this.state.updating}>
                                                            <ContentTranslator page="SfiaProfile" name="PersonalDetailsButtonSave" contentText="Save" />
                                                        </button>
                                                        <br />
                                                        <button className="btn btn-light btnSecondary-Style" style={{ width: "75px" }} onClick={this.cancelEdit}>
                                                            <ContentTranslator page="SfiaProfile" name="PersonalDetailsButtonCancel" contentText="Cancel" />
                                                        </button>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <button id="btnEditPersonalDetail" className="btn btn-light btnSecondary-Style" onClick={this.startEdit}>
                                                            <ContentTranslator page="SfiaProfile" name="PersonalDetailsButtonEdit" contentText="Edit Profile" />
                                                        </button>
                                                        <UncontrolledTooltip target={`btnEditPersonalDetail`} placement="bottom">
                                                            <ContentTranslator page="SfiaProfileTooltip" name="PersonalDetailsButtonEditTooltip" contentText="Edit your personal details" />
                                                        </UncontrolledTooltip>
                                                    </React.Fragment>
                                                ))}
                                        </p>
                                    </Col>
                                    <Col xs="8">
                                        <Container className="pr-0">
                                            <Row>
                                                <Col className="p-0 align-self-center" style={{ fontSize: "18px", color: "#3164C9", fontFamily: "IBMPlexSans-Bold" }}>
                                                    <ContentTranslator page="SfiaProfile" name="PersonalDetailsTitle" contentText="Personal Details" />
                                                </Col>
                                                <Col xs="auto" className="align-self-end">
                                                    <button className="btn btn-light btnSecondary-Style" onClick={this.toggleModal}>
                                                        <FontAwesomeIcon icon={faTimes} style={{ fontSize: "13px" }} />
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <Table>
                                            <tbody>
                                                {this.state.updating ? (
                                                    <div style={{ width: "100%", textAlign: "center" }}>
                                                        <Spinner size="lg" animation="border" role="status"></Spinner>
                                                    </div>
                                                ) : (
                                                    <React.Fragment>
                                                        {this.state.editMode && (
                                                            <React.Fragment>
                                                                <tr>
                                                                    <td className="pl-0 border-top-0 border-bottom font-weight-bold" style={{ width: "150px" }}>
                                                                        <ContentTranslator page="SfiaProfile" name="PersonalDetailsFirstName" contentText="First Name" />
                                                                    </td>
                                                                    <td className="border-top-0 border-bottom">
                                                                        <Input type="text" name="firstName" id="inputFirstName" value={form.firstName.value} invalid={form.firstName.touched && !form.firstName.valid} onChange={this.handleChange} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="pl-0 border-top-0 border-bottom font-weight-bold" style={{ width: "150px" }}>
                                                                        <ContentTranslator page="SfiaProfile" name="PersonalDetailsLastName" contentText="Last Name" />
                                                                    </td>
                                                                    <td className="border-top-0 border-bottom">
                                                                        <Input type="text" name="lastName" id="inputlastName" value={form.lastName.value} invalid={form.lastName.touched && !form.lastName.valid} onChange={this.handleChange} />
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )}
                                                        <tr>
                                                            <td className="pl-0 border-top-0 border-bottom font-weight-bold" style={{ width: "150px" }}>
                                                                <ContentTranslator page="SfiaProfile" name="PersonalDetailsSeniority" contentText="Seniority" />
                                                            </td>
                                                            <td className="border-top-0 border-bottom">
                                                                {this.state.editMode ? (
                                                                    <Input type="select" name="seniority" id="inputSeniority" onChange={this.handleChange} value={form.seniority.value}>
                                                                        {this.state.seniorityOptions.map((obj, index) => (
                                                                            <option key={index} value={obj.label}>
                                                                                {obj.label}
                                                                            </option>
                                                                        ))}
                                                                    </Input>
                                                                ) : (
                                                                    account && account.seniority
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pl-0 border-top-0 border-bottom font-weight-bold">
                                                                <ContentTranslator page="SfiaProfile" name="PersonalDetailsContract" contentText="Contract" />
                                                            </td>
                                                            <td className="border-top-0 border-bottom">
                                                                {this.state.editMode ? (
                                                                    <Input type="select" name="employmentContract" id="inputEmploymentContract" onChange={this.handleChange} value={form.employmentContract.value}>
                                                                        {this.state.contractTypeOptions.map((obj, index) => (
                                                                            <option key={index} value={obj.label}>
                                                                                {obj.label}
                                                                            </option>
                                                                        ))}
                                                                    </Input>
                                                                ) : (
                                                                    account && account.employmentContract
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pl-0 border-top-0 border-bottom font-weight-bold">
                                                                <ContentTranslator page="SfiaProfile" name="PersonalDetailsTimeInRole" contentText="In-Role" />
                                                            </td>
                                                            <td className="border-top-0 border-bottom">
                                                                {this.state.editMode ? (
                                                                    <Input type="select" name="timeInCurrentRole" id="inputTimeInCurrentRole" onChange={this.handleChange} value={form.timeInCurrentRole.value}>
                                                                        {this.state.yearRangeJobOptions.map((obj, index) => (
                                                                            <option key={index} value={obj.label}>
                                                                                {obj.label}
                                                                            </option>
                                                                        ))}
                                                                    </Input>
                                                                ) : (
                                                                    account && account.timeInCurrentRole
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pl-0 border-top-0 border-bottom font-weight-bold">
                                                                <ContentTranslator page="SfiaProfile" name="PersonalDetailsTimeInOrganisation" contentText="In-Organisation" />
                                                            </td>
                                                            <td className="border-top-0 border-bottom">
                                                                {this.state.editMode ? (
                                                                    <Input type="select" name="timeInOrganisation" id="inputTmeInOrganisation" onChange={this.handleChange} value={form.timeInOrganisation.value}>
                                                                        {this.state.yearRangeOrgOptions.map((obj, index) => (
                                                                            <option key={index} value={obj.label}>
                                                                                {obj.label}
                                                                            </option>
                                                                        ))}
                                                                    </Input>
                                                                ) : (
                                                                    account && account.timeInOrganisation
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pl-0 border-top-0 border-bottom font-weight-bold">
                                                                <ContentTranslator page="SfiaProfile" name="PersonalDetailsCurrentRole" contentText="Current Role" />
                                                            </td>
                                                            <td className="border-top-0 border-bottom">
                                                                {this.state.editMode ? (
                                                                    <Input type="text" name="recentOrCurrentRole" id="inputrecentOrCurrentRole" value={form.recentOrCurrentRole.value} invalid={form.recentOrCurrentRole.touched && !form.recentOrCurrentRole.valid} onChange={this.handleChange} />
                                                                ) : account && account.recentOrCurrentRole ? (
                                                                    account.recentOrCurrentRole.replace("<Enter StringVal>", "")
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                    </Modal>
                )}
                <div style={{ textAlign: "center" }}>
                    <img style={{ borderRadius: "50%" }} src={person && this.state.profilePicture ? URL.createObjectURL(this.state.profilePicture) : process.env.PUBLIC_URL + "/assets/img/Blank Profile.png"} alt="Profile Avatar" width="90" height="90" />
                    {!this.props.managedPeople && (
                        <div style={{ position: "absolute", top: "0px", right: "5px" }}>
                            <button id="btnEditProfilePicture" className="btn btn-light btnSecondary-Style btn-sm" onClick={this.showUploadProfilePicture} style={{ height: "30px" }}>
                                <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: "8px" }} />
                            </button>
                            <UncontrolledTooltip target={`btnEditProfilePicture`} placement="bottom">
                                <ContentTranslator page="SfiaProfileTooltip" name="PersonalDetailsButtonEditProfilePictureTooltip" contentText="Edit your profile picture" />
                            </UncontrolledTooltip>
                        </div>
                    )}
                </div>
                {this.state.loading ? (
                    <Spinner animation="border" role="status" style={{ marginLeft: "20px" }}></Spinner>
                ) : (
                    person && (
                        <div style={{ maxWidth: "125px" }}>
                            <p className="text-center mb-0">
                                <b>
                                    {person.firstName} {person.lastName}
                                </b>
                            </p>
                            <p className="text-center mb-0">{person.position ? person.position.replace("<Enter StringVal>", "") : ""}</p>
                            <p className="text-center mb-0">
                                <button id="btnViewPersonalDetails" className="btn btn-link" onClick={this.toggleModal} style={{ fontSize: "11px" }}>
                                    <ContentTranslator page="SfiaProfile" name="PersonalDetailsButtonView" contentText="View Details" />
                                </button>
                                <UncontrolledTooltip target={`btnViewPersonalDetails`} placement="bottom">
                                    <ContentTranslator page="SfiaProfileTooltip" name="PersonalDetailsButtonViewTooltip" contentText="View your personal details" />
                                </UncontrolledTooltip>
                            </p>
                        </div>
                    )
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.person.loading,
        error: state.person.error,
        person: state.person.person,
        profilePicture: state.person.profilePicture,
        updating: state.person.updating,
        seniorityOptions: state.groupValue.seniorityOptions,
        contractTypeOptions: state.groupValue.contractTypeOptions,
        yearRangeJobOptions: state.groupValue.yearRangeJobOptions,
        yearRangeOrgOptions: state.groupValue.yearRangeOrgOptions,

        managedPeople: state.auth.managedPeople,
        language: state.language.language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPerson: bindActionCreators(personActions, dispatch),
        onGroupValue: bindActionCreators(groupValueActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardProfile);
