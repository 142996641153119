import CustomSelect from "../global/customselect";
import {
  FilterTwoTone,
  FilterFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Row, Col, Button, Tag } from "antd";
import React, { useMemo, useState, useCallback } from "react";
import styled from "styled-components";

const WrapperFilter = styled.div`
  .anticon {
    vertical-align: 0.125em;
  }
  .card-c {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 35px;

    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);

    backdrop-filter: blur(15px);
  }

  .slide {
    transition: all 0.5s ease-in-out;
    transform-origin: left top;
    animation: slide 0.5s;
    @keyframes slide {
      0% {
        transform: scaleY(0);
      }
      100% {
        transform: scaleY(1);
      }
    }
  }

  .mt-20 {
    margin-top: 20px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .mr-10 {
    margin-right: 10px;
  }
  .font-small {
    font-size: 13px;
  }

  .ml-5-c {
    margin-left: 5px;
  }
  .mb-10 {
    margin-bottom: 5px;
  }

  .rotate-90 {
    transform: rotate(90deg);
    transition: transform 0.5s;
  }
  .rotate-0 {
    transform: rotate(0deg);
    transition: transform 0.5s;
  }

  @media (max-width: 1280px) {
    .col-sm-cm-m {
      max-width: 100% !important;
      flex: 0 0 100%;
    }
    .sm-mt-10 {
      margin-top: 10px;
    }
  }
`;

// Helper function to render tags based on the object and its color.
const renderTags = (obj, color, onCloseClicked, isTree = false) => {
  return obj?.value?.map((item, index) => (
    <Tag className="mb-10 slide" color={color} key={index}>
      {/* Check if item is a string or needs to be parsed */}
      <span>{isTree ? JSON.parse(item).value : item}</span>
      <CloseCircleOutlined
        className="ml-5-c"
        onClick={() => onCloseClicked(obj, item)}
        style={{ color: "darkred" }}
      />
    </Tag>
  ));
};

const renderFilter = (Component, isExpand) => (
  <Component
    style={{ alignSelf: "center" }}
    className={`text-center ${isExpand ? "rotate-90" : "rotate-0"}`}
  />
);

const ActionPlanFilters = ({ objName, objSkillCode, objActions }) => {
  // Local state to manage the expand/collapse feature.
  const [isExpand, setIsExpand] = useState(false);

  // Function to remove a selected filter.
  const onCloseClicked = useCallback((obj, currentValue) => {
    // Filter out the selected value and update the state.
    const updatedValue = obj.value.filter((value) => value !== currentValue);
    obj.setValue(updatedValue);
  });

  // useMemo to determine if any filter is selected.
  const hasFilter = useMemo(() => {
    return [objName, objSkillCode, objActions].some(
      (obj) => obj?.value?.length > 0
    );
  }, [objName?.value, objSkillCode?.value, objActions?.value]);

  return (
    <WrapperFilter>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
        {/* Rest of the JSX structure where you'll use your button */}
        <Col span={4} flex="none">
          <Button
            size="large"
            type="primary"
            icon={
              hasFilter
                ? renderFilter(FilterFilled, isExpand)
                : renderFilter(FilterTwoTone, isExpand)
            }
            ghost
            onClick={() => setIsExpand((state) => !state)}
          >
            Filter
          </Button>
        </Col>
        {/* Rest of the JSX structure where you'll use your renderTags */}
        <Col
          span={20}
          flex="auto"
          style={{ display: (!hasFilter || isExpand) && "none" }}
        >
          {/* Render tags for each filter type */}
          {renderTags(objName, "magenta", onCloseClicked)}
          {renderTags(objSkillCode, "red", onCloseClicked)}
          {renderTags(objActions, "geekblue", onCloseClicked)}
        </Col>
      </Row>
      <div
        style={{ display: !isExpand && "none" }}
        className="mt-20 card-c slide"
      >
        {/* Rest of the JSX structure where you'll use your FilterComponent */}
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col className="gutter-row col-sm-cm-m " span={12}>
            <CustomSelect
              label="Person Name"
              obj={objName}
              placeholder={`Select Person Name${
                (objName?.options ?? []).length < 1 ? " (No Data)" : ""
              }`}
            />
          </Col>
          <Col className="gutter-row col-sm-cm-m sm-mt-10" span={12}>
            <CustomSelect
              label="Skill Code"
              obj={objSkillCode}
              placeholder={`Select Skill Code${
                (objSkillCode?.options ?? []).length < 1 ? " (No Data)" : ""
              }`}
            />
          </Col>
          <Col className="gutter-row col-sm-cm-m sm-mt-10" span={12}>
            <CustomSelect
              label="Action Name"
              obj={objActions}
              placeholder={`Select Action Name${
                (objActions?.options ?? []).length < 1 ? " (No Data)" : ""
              }`}
            />
          </Col>
        </Row>
      </div>
    </WrapperFilter>
  );
};

export default ActionPlanFilters;
