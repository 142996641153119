import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class ContentAPI {
    static getContentTranslations(languageId, pages) {
        return axios
            .get(`/contents/${languageId}/${pages}`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }
}

export default ContentAPI;