import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Table, Typography, Empty, Tooltip, Input, Tag, Row } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";

const constantData = {
  titleLevel: 5,
  pageSize: 10,
  rangeColors: [
    {
      color: "green",
      label: "80 - 100",
      isMatch: (val) => val >= 80,
    },
    {
      color: "cyan",
      label: "60 - 79",
      isMatch: (val) => val >= 60,
    },
    {
      color: "gold",
      label: "40 - 59",
      isMatch: (val) => val >= 40,
    },
    {
      color: "orange",
      label: "20 - 39",
      isMatch: (val) => val >= 20,
    },
    {
      color: "volcano",
      label: "1 - 19",
      isMatch: (val) => val >= 0,
    },
    {
      color: "red",
      label: "0",
      isMatch: (val) => val === 0,
    },
  ],
};

const DivCenter = ({ Component, title }) => {
  return (
    <div
      className="p-grid p-align-center vertical-container"
      style={{ marginBottom: "40px" }}
    >
      <div className="layout-wrapper">
        <Title level={constantData.titleLevel}>{title}</Title>
        <Component />
      </div>
    </div>
  );
};

const { Title } = Typography;

const { TextArea } = Input;

const WrapperTable = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;

  .ant-table-container table > thead > tr:first-child th {
    text-align: center;
  }

  .ant-table-container table tr .ant-table-cell-row-hover {
    background: rgb(116, 226, 177, 0.2) !important;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 14px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 14px;
  }

  .ant-table-content > table {
    table-layout: auto;
    border-color: gainsboro;
    border-style: solid;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    border-width: 0.5px;
  }
  .mr-10 {
    margin-right: 10px;
  }
  .grid {
    display: grid;
  }

  @media (max-width: 1680px) {
    .cell-cm {
      width: 150px;
    }
  }

  @media (max-width: 1580px) {
    .cell-cm {
      width: 100px;
    }
  }

  @media (max-width: 1480px) {
    .cell-cm {
      width: 60px;
    }
  }
  @media (max-width: 1280px) {
    .cell-cm {
      width: auto;
    }
  }
`;

const WrapperSubCell = styled.div`
  width: auto;
  text-wrap: wrap;
`;

const ContainerTable = styled.div`
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;

  .circle {
    border-radius: 10px;
    width: 10px;
    height: 10px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .pd-10 {
    padding: 10px;
  }

  .flex {
    display: flex;
  }

  .font-sm {
    font-size: small;
  }

  .item-center {
    align-items: center;
  }

  .btn-danger {
    background-color: red;
    color: white;
  }

  .btn-success {
    background-color: #52c41a;
    color: white;
  }

  .label-danger {
    color: red;
    text-decoration: underline;
  }

  .label-success {
    color: #52c41a;
    text-decoration: underline;
  }

  .btn-disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
  }
`;

const createCenteredTextColumnHeader = (title) => (
  <span style={{ textWrap: "nowrap" }}>{title}</span>
);

// Helper function to generate a table column that centers its text content.
// This avoids repetition for multiple columns that require similar formatting.
const createCenteredTextColumn = (title, dataIndex, sorterFunction) => ({
  title: createCenteredTextColumnHeader(title), // Column header
  dataIndex, // Data key
  key: dataIndex, // Key for React's list rendering
  sorter: (a, b) => sorterFunction(a[dataIndex], b[dataIndex]), // Sorting function for the column
  render: (text) => (
    <WrapperSubCell className="text-center">{text ?? "-"}</WrapperSubCell>
  ), // Centering the text
});

// Helper function to generate a table column that centers its text content.
// This avoids repetition for multiple columns that require similar formatting.
const createCenteredArrayColumn = (title, dataIndex, sorterFunction) => ({
  title: createCenteredTextColumnHeader(title), // Column header
  dataIndex, // Data key
  key: dataIndex, // Key for React's list rendering
  sorter: (a, b) => sorterFunction(a[dataIndex], b[dataIndex]), // Sorting function for the column
  render: (text) => (
    <WrapperSubCell className="text-center">
      {text?.join(", ") ?? "-"}
    </WrapperSubCell>
  ), // Centering the text
});

// Helper function to generate a table column that centers its text content.
// This avoids repetition for multiple columns that require similar formatting.
const createCenteredArrayTagColumn = (title, dataIndex, sorterFunction) => ({
  title: createCenteredTextColumnHeader(title), // Column header
  dataIndex, // Data key
  key: dataIndex, // Key for React's list rendering
  sorter: (a, b) => sorterFunction(a[dataIndex], b[dataIndex]), // Sorting function for the column
  render: (text) => (
    <WrapperSubCell className="text-center">
      {text?.length > 0
        ? text.map((item, index) => (
            <Tag className="mb-1" key={index}>
              {item}
            </Tag>
          ))
        : "-"}
    </WrapperSubCell>
  ), // Centering the text
});

const createCenteredButtonColumn = (
  title,
  dataIndex,
  onDeny,
  onAccept,
  sorterFunction,
  contentTranslations, contentTranslator
) => {
  const onClicked = (record, func) => {
    if (func != null) {
      func(record);
    }
  };

  const render = (record, text) => {
    switch (text?.toLowerCase()) {
      case "accepted":
        return (
          <Tag color="green">
            <div className="flex align-items-center">
              <CheckOutlined />
              <div className="ml-1">{text}</div>
            </div>
          </Tag>
        );
      case "denied":
        return (
          <Tag color="red">
            <div className="flex align-items-center">
              <CloseOutlined />
              <div className="ml-1">{text}</div>
            </div>
          </Tag>
        );
      case "requested":
        return (
          <div className="flex justify-content-center">
            <Tooltip title={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestTableAcceptButtonTooltip", "Accept")}>
              <CheckCircleTwoTone
                style={{ fontSize: "20px" }}
                onClick={() => onClicked(record, onAccept)}
                twoToneColor="#52c41a"
              />
            </Tooltip>
            <Tooltip title={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestTableDenyButtonTooltip", "Deny")} className="ml-3">
              <CloseCircleTwoTone
                twoToneColor="red"
                style={{ fontSize: "20px" }}
                onClick={() => onClicked(record, onDeny)}
              />
            </Tooltip>
          </div>
        );
      default:
        return;
    }
  };

  return {
    title: createCenteredTextColumnHeader(title), // Column header
    dataIndex, // Data key
    key: dataIndex, // Key for React's list rendering
    //sorter: (a, b) => sorterFunction(a[dataIndex], b[dataIndex]), // Sorting function for the column
    render: (text, record) => (
      <WrapperSubCell className="text-center">
        {text ? render(record, text) : "-"}
      </WrapperSubCell>
    ), // Centering the text
  };
};

const createCenteredNumberColumn = (
  title,
  dataIndex,
  sorterFunction,
  isResponsive = false,
  isCenter = true
) => {
  const getColor = (val) =>
    constantData.rangeColors.find((item) => item.isMatch(val))?.color;

  return {
    title: createCenteredTextColumnHeader(title), // Column header
    dataIndex, // Data key
    key: dataIndex, // Key for React's list rendering
    sorter: (a, b) => sorterFunction(a[dataIndex], b[dataIndex]), // Sorting function for the column
    render: (text) => (
      <WrapperSubCell
        className={`${isCenter && "text-center"} ${isResponsive && "cell-cm"}`}
      >
        {text !== undefined && text !== null ? (
          <Tag color={getColor(text)}>{text}%</Tag>
        ) : (
          "-"
        )}
      </WrapperSubCell>
    ), // Centering the text
  };
};

// Utility function to compare text values array
const sortByTextArray = (a, b) => {
  // Determine the minimum length between the two current arrays
  const minLength = Math.min(a.length, b.length);

  // Iterate over the elements up to the minimum length
  for (let i = 0; i < minLength; i++) {
    // Only proceed to string conversion and comparison if elements are different
    // This saves performance by avoiding unnecessary operations
    if (a[i] !== b[i]) {
      // Convert current elements to strings and make them lowercase
      // for case-insensitive comparison
      const elemA = a[i].toString().toLowerCase();
      const elemB = b[i].toString().toLowerCase();

      // Compare the elements and determine their order
      if (elemA < elemB) return -1; // b comes before a
      if (elemA > elemB) return 1; // b comes after a
    }
  }

  // If all compared elements are equal, then compare array lengths
  // The shorter array comes first
  return a.length - b.length;
};

// Utility function to compare text values
const sortByText = (a, b) => (a ?? "").localeCompare(b ?? "");

// Utility function to compare number values
const sortByNumber = (a, b) => (a < b ? -1 : a > b ? 1 : 0);

// Utility function to transform data source for tables
// This maps over the data and transforms it to have keys and all the item properties.
const transformDataSource = (data) =>
  data.map((item, index) => ({
    key: `${index + 1}`,
    ...item,
  }));

// Utility function to generate column definitions based on type and need status
// This will return the appropriate columns based on whether it's a professional or generic skill and if it's a needed skill.
const getColumnDefinitions = (onDeny, onAccept, contentTranslations, contentTranslator) => {
  const tempColum = [
    createCenteredTextColumn(contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestTableName", "Name"), "name", sortByText),
    createCenteredTextColumn(contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestTableEmail", "Email"), "email", sortByText),
    createCenteredNumberColumn(contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestTableMatchPercent", "Match %"), "matchedSkillPercent", sortByNumber),
    createCenteredArrayTagColumn(contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestTableSkills", "Skills"), "skills", sortByTextArray),
    createCenteredArrayColumn(contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestTableProfiles", "Profiles"), "preferences", sortByTextArray),
    createCenteredButtonColumn(
      contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestTableStatus", "Status"),
      "mentorStatus",
      onDeny,
      onAccept,
      sortByText,
      contentTranslations, contentTranslator
    ),
    createCenteredTextColumn(contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestTableComment", "Comment"), "comment", sortByText),
  ];
  return tempColum;
};

const MenteeTable = ({
  data = [],
  pageSize = constantData.pageSize,
  onDeny,
  onAccept,
  contentTranslations,
  contentTranslator
}) => {
  // useMemo will recompute the memoized value (`tableData`) only when one of the dependencies
  // in the dependency array changes. This optimization helps avoid expensive calculations on
  // every render when the relevant data hasn't changed.

  const tableData = useMemo(() => {
    // Early return when content is not loaded; avoids processing empty or undefined data.
    if (data.length < 1) return { dataSource: [], columns: [] };

    // Call `transformDataSource` to process the raw data into the desired structure/format
    // for use by the table. This might include converting dates to a specific format,
    // reshaping objects, adding additional fields, etc.
    const dataSource = transformDataSource(data);

    // Retrieve column definitions, which could depend on various factors such as user role,
    // specific permissions, feature flags, etc. This function is also responsible for
    // integrating interactive elements like checkboxes into the columns, setting up the
    // appropriate handlers, and defining how they interact with the component's state.
    const columns = getColumnDefinitions(onDeny, onAccept, contentTranslations, contentTranslator);

    // The useMemo hook returns an object containing the processed `dataSource` and `columns`,
    // ready for consumption by a table component (e.g., in a <Table dataSource={...} columns={...} /> setup).
    return { dataSource, columns };

    // Dependency array: the memoized value will only recompute if `data`, `stateCheckbox`, or
    // are computationally expensive or if the data volume is large.
  }, [data]);

  // Create a copy of the listExpand array
  return (
    // Container for the entire table component
    <ContainerTable>
      {data.length > 0 ? (
        // Conditionally render the table or a 'No Data' message based on the length of the 'data' array
        // If 'data' has elements, render the table and associated components
        <div>
          {/* Wrapper for styling and layout of the table */}
          <WrapperTable>
            {/* Ant Design Table component displaying data */}
            <Table
              dataSource={tableData.dataSource} // Data for the table
              columns={tableData.columns} // Column configuration for the table
              pagination={{ pageSize, hideOnSinglePage: true }} // Pagination configuration, setting the number of rows per page
              scroll={{ x: true }} // Enable horizontal scrolling
            />
            {tableData.dataSource.length > 0 && (
              <Row className="mt-4">
                {constantData.rangeColors.map((item, index) => {
                  return (
                    <div className=" text-center item-center pd-10" key={index}>
                      <span className="font-sm ">{contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestRange", "Range")}:</span>
                      <Tag className="ml-10" color={item.color}>
                        {item.label}
                      </Tag>
                    </div>
                  );
                })}
              </Row>
            )}
          </WrapperTable>
        </div>
      ) : (
        // If 'data' is empty, render a centered 'No Data' message
        <DivCenter
          Component={() => <Empty description={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestTableNoData", "No Data")} />} // Ant Design Empty component with a description
        />
      )}
    </ContainerTable>
  );
};

export default MenteeTable;
