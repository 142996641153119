import * as authActions from "../../../store/actions/authAction";
import * as adminCategoryActions from "../../../store/admin/actions/adminCategoryActions";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import ExtraFrameworkLevelDetail from "./ExtraFrameworkLevelDetail";
import { faPenToSquare, faPlus, faSync, faArrowUp, faArrowDown, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const rowStyle = {
    fontSize: "12px",
};

class ManageExtraFrameworkCapabilities extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            extraFrameworkCapabilities: this.props.extraFrameworkCapabilities,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.extraFrameworkCapabilities !== this.props.extraFrameworkCapabilities) {
            this.setState({
                extraFrameworkCapabilities: this.props.extraFrameworkCapabilities,
            });
        }
    }

    componentWillUnmount() {}

    render() {
        const { pageAssignment } = this.props;
        const { extraFrameworkCapabilities } = this.state;

        let allowUpdate = false;
        // allowUpdate = pageAssignment.operations.find((it) => it.operation == "Manage" && it.allow == true) ? true : false;

        const columns = [
            {
                id: "capability",
                name: "Capability",
                selector: (row) => row.capability,
                sortable: false,
                width: "70%",
            },
            {
                id: "weight",
                name: "Weight",
                selector: (row) => row.weight,
                sortable: false,
                width: "30%",
                center: true,
            },
        ];

        return (
            <React.Fragment>
                <p className="mb-0" style={{ color: "#2B2B2B", fontSize: "22px", fontFamily: "IBMPlexSans-Bold", paddingBottom: "5px" }}>
                    Extra Framework Capabilities
                </p>
                <div>
                    <Row>
                        <Col md="6" sm="6">
                            <Button
                                color="primary"
                                onClick={this.props.handleRefreshCapabilities}
                                style={{
                                    fontSize: "12px",
                                    height: "35px",
                                    marginBottom: "10px",
                                }}
                                disabled={this.props.capabilityLoading || this.state.isUpdating}
                            >
                                <FontAwesomeIcon icon={faSync} /> Refresh
                            </Button>
                        </Col>
                    </Row>
                    <SkillsTxTable columns={columns} data={extraFrameworkCapabilities.sort((a, b) => b.weight - a.weight)} pagination={false} selectableRows={true} selectableRowsHighlight={true} progressPending={this.props.capabilityLoading}></SkillsTxTable>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        authPersonId: state.auth.personId,

        isUpdating: state.adminCategory.isUpdating,
        capabilityLoading: state.adminCategory.capabilityLoading,
        categoryError: state.adminCategory.error,
        extraFrameworkLevels: state.adminCategory.extraFrameworkLevels,

        pageAssignments: state.adminPerson.pageAssignments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onAdminCategory: bindActionCreators(adminCategoryActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageExtraFrameworkCapabilities));
