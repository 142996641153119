import { Empty } from "antd";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useRef, useEffect } from "react";

Chart.register(...registerables, ChartDataLabels);

const DivCenter = ({ Component }) => {
  return (
    <div className="p-grid p-align-center vertical-container">
      <div className="layout-wrapper">
        <Component />
      </div>
    </div>
  );
};
// Static configurations moved outside of the component for performance reasons.
// These don't need to be recreated every render.

const constantData = {
  chartType: "bar",
  colors: {
    background: [
      "#b71540",
      "#eb2f06",
      "#f6b93b",
      "#0c2461",
      "#1e3799",
      "#0a3d62",
      "#60a3bc",
      "#079992",
      "#78e08f",
      "#6a89cc",
    ],
    type1: "#EEEEEE",
    type2: "#3C65C2",
  },
  yText: "Generic Attribute Count",
  xText: "GA Code And Level",
  legendText: "Attribute Number",
  endName: "end",
};

// Base font configuration for the X and Y axis to avoid repetitive code.
const baseFontConfig = {
  weight: 500,
};

// Base title configuration for the X and Y axis to maintain consistent styling.
const baseTitleConfig = {
  display: true,
  color: constantData.colors.type2,
  font: {
    size: 14,
    weight: 700,
  },
};

const Bar = ({ data: { source = [], maxCount } = {} }) => {
  const canvas = useRef(null);

  // Refactored data for the chart using destructuring for more concise code.
  const dataChart = {
    labels: source.map((item) => item.label),
    datasets: [
      {
        label: constantData.legendText,
        data: source.map((item) => item.value),
        backgroundColor: constantData.colors.background,
      },
    ],
  };

  // Centralized configuration for the chart. Static values are directly set
  // and values that depend on props are computed inline.
  const configChart = {
    type: constantData.chartType,
    data: dataChart,
    options: {
      scales: {
        y: {
          ticks: {
            ...baseFontConfig,
            precision: 0,
          },
          max: maxCount,
          beginAtZero: true,
          grid: {
            color: constantData.colors.type1,
            borderDash: [8, 4],
          },
          title: {
            ...baseTitleConfig,
            text: constantData.yText,
          },
        },
        x: {
          ticks: {
            ...baseFontConfig,
          },
          grid: {
            display: false,
          },
          title: {
            ...baseTitleConfig,
            text: constantData.xText,
          },
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      barThickness: 30,
      aspectRatio: 5 / 2,
      layout: {
        padding: {
          top: 32,
        },
      },
      elements: {
        line: {
          fill: false,
          tension: 0.4,
        },
      },
      plugins: {
        datalabels: {
          align: constantData.endName,
          anchor: constantData.endName,
          offset: 0,
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (ctx) => ` ${ctx.dataset.label} : ${ctx.formattedValue}`,
            title: (ctx) => ctx[0].label,
          },
        },
      },
    },
  };

  // Ensure the chart is only re-rendered when the data source changes.
  useEffect(() => {
    let chartInstance = null;

    if (source?.length > 0) {
      chartInstance = new Chart(canvas.current, configChart);
    }

    // Cleanup the previous chart instance to prevent memory leaks
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [source]); // Dependency array updated to re-render the chart when data changes

  // Conditionally render the canvas if data is present, otherwise display a centered 'No Data' message
  if (source?.length < 1) {
    return <DivCenter Component={() => <Empty description={"No Data"} />} />;
  }

  return <canvas ref={canvas} />;
};

export { Bar };
