import ErrorHandler from "./../errorResponseHandler";
import Instance from "./../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminActionTypeAPI {
    static getActionTypes() {
        return axios
            .get(`/actiontypes`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createActionType(actiontype) {
        return axios
            .post(`/actiontypes`, actiontype)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateActionType(id, actiontype) {
        return axios
            .put(`/actiontypes/${id}`, actiontype)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteActionType(id) {
        return axios
            .delete(`/actiontypes/${id}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default AdminActionTypeAPI;
