import * as actionTypes from "../actions/actionTypes";

import { updateState } from "../utility";

const initialState = {
    languages: [],
    language: null,
    error: null,
    loading: false,
};

const getLanguagesStart = (state) => {
    return updateState(state, { error: null, languages: [], loading: true });
};

const getLanguagesSuccess = (state, action) => {    
    return updateState(state, {
        languages: action.languages,
        error: null,
        loading: false,
    });
};

const getLanguagesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const setLanguage = (state, action) => {
    return updateState(state, {
        language: action.language
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_LANGUAGES_START:
            return getLanguagesStart(state);
        case actionTypes.GET_LANGUAGES_SUCCESS:
            return getLanguagesSuccess(state, action);
        case actionTypes.GET_LANGUAGES_FAIL:
            return getLanguagesFail(state, action);
        case actionTypes.SET_LANGUAGE:
            return setLanguage(state, action);
        default:
            return state;
    }
};

export default reducer;
