import * as authActions from "../../store/actions/authAction";
import * as companyActions from "../../store/actions/companyAction";
import * as configActions from "../../store/actions/configAction";
import * as languageActions from "../../store/actions/languageAction";
import * as personActions from "../../store/actions/personAction";
import * as adminPersonActions from "../../store/admin/actions/adminPersonActions";
import ContentTranslator from "../translator/ContentTranslator";
import { NavRightLogin } from "./NavRightLogin";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, NavLink as RRNavLink } from "react-router-dom";
import { Collapse, Container, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler } from "reactstrap";
import { bindActionCreators } from "redux";

const navlinkStyle = {
    color: "#2B2B2B",
    fontSize: "18px",
    fontFamily: "IBMPlexSans-Bold",
    marginRight: "10px",
    marginLeft: "10px",
};

const navlinkActiveStyle = {
    color: "#4279E6",
    textDecoration: "none",
};

const headerLeftStyle = {
    position: "absolute",
    left: "50px",
    top: "45px",
};

const navbarStyle = {
    borderRadius: "20px",
};

const headerRightStyle = {
    fontFamily: "IBMPlexSans-Bold",
    position: "absolute",
    right: "50px",
    top: "50px",
    width: "200px",
};

const headerStyle = {
    fontFamily: "IBMPlexSans-Bold",
    marginBottom: "69px",
};

class NavMainLogin extends Component {
    static displayName = NavMainLogin.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            selectedLanguage: null,
            logoTimestamp: new Date(),
            brandingLogoImage: null,
        };
    }

    componentDidMount() {
        this.setState({ brandingLogoImage: this.props.brandingLogoImage });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.logoTimestamp !== this.props.logoTimestamp) {
            this.setState({ logoTimestamp: this.props.logoTimestamp });

            this.props.onCompany.getCompanyBrandingLogo().then(() => {
                this.setState({ brandingLogoImage: this.props.brandingLogoImage });
            });
        }

        if (prevProps.containerConfig !== this.props.containerConfig) {
            this.setState({ containerConfig: this.props.containerConfig });
        }
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    logout = () => {
        const samlEnabled = this.props.samlEnabled && this.props.samlEnabled == "TRUE" ? true : false;

        Promise.resolve(this.props.onAuth.myPeopleClearManagedRelationship()).then(() => {
            this.props.disconnectSignalR();

            this.props.onAuth.authLogoutIntended().then(() => {
                Promise.resolve([
                    this.props.onPerson.clearPersonAccountState(),
                    this.props.onConfig.clearCurrAssessment(),
                    this.props.onConfig.clearActiveUserCountStatusState(),
                    this.props.onConfig.getSamlEnabledStatus(),
                    this.props.onAdminPerson.clearPageAssignmentsState(),
                    this.props.onAdminPerson.clearColumnDisplaySettingsState(),
                ]);

                if (this.props.loginWithMembee) {
                    window.location = "/membeelogout";
                }
            });
        });
    };

    render() {
        const apiHostname = process.env.REACT_APP_API_ENDPOINT;
        const baseURL = process.env.NODE_ENV === "development" ? `${apiHostname}` : `/api`;

        const { containerConfig } = this.props;

        return (
            <Navbar style={headerStyle} className="navbar-expand-md navbar-toggleable-md ng-white" light>
                <Container>
                    <NavbarBrand tag={Link} to="/">
                        {this.props.brandingLogoChecked && this.props.brandingLogoImage ? (
                            <img style={headerLeftStyle} src={"data:image/png;base64," + this.state.brandingLogoImage} alt="Comp Logo" width="120" className="compLogo" />
                        ) : (
                            <img
                                style={headerLeftStyle}
                                src={`${baseURL}/companies/brandinglogo?timestamp=${this.state.logoTimestamp}`}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = process.env.PUBLIC_URL + "/assets/img/lgo/skillstx-logo-top.png";
                                }}
                                alt="Comp Logo"
                                width="120"
                                className="compLogo"
                            />
                        )}
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-md-inline-flex justify-content-center" isOpen={!this.state.collapsed} navbar>
                        <ul style={navbarStyle} className="navbar-nav flex-grow bg-white mt-5">
                            <NavItem>
                                <NavLink tag={RRNavLink} activeStyle={navlinkActiveStyle} style={navlinkStyle} exact to="/SfiaProfile/">
                                    <ContentTranslator page="Menu" name="MenuPlanner" contentText="Planner" />
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                {containerConfig && ((containerConfig.nav1Label && containerConfig.nav1Label != "") || (containerConfig.nav1Link && containerConfig.nav1Link != "")) ? (
                                    <NavLink tag={RRNavLink} activeStyle={navlinkActiveStyle} style={navlinkStyle} to={{ pathname: containerConfig && containerConfig.nav1Link && containerConfig.nav1Link != "" ? containerConfig.nav1Link : "http://licensed-skillstx.talentlms.com/" }} target="_blank">
                                        {containerConfig && containerConfig.nav1Label && containerConfig.nav1Label != "" ? containerConfig.nav1Label : <ContentTranslator page="Menu" name="MenuTrainingPortal" contentText="Training Portal" />}
                                    </NavLink>
                                ) : (
                                    <NavLink tag={RRNavLink} activeStyle={navlinkActiveStyle} style={navlinkStyle} to={{ pathname: "http://licensed-skillstx.talentlms.com/" }} target="_blank">
                                        <ContentTranslator page="Menu" name="MenuTrainingPortal" contentText="Training Portal" />
                                    </NavLink>
                                )}
                            </NavItem>
                            <NavItem>
                                {containerConfig && ((containerConfig.nav2Label && containerConfig.nav2Label != "") || (containerConfig.nav2Link && containerConfig.nav2Link != "")) ? (
                                    <NavLink tag={RRNavLink} activeStyle={navlinkActiveStyle} style={navlinkStyle} to={{ pathname: containerConfig && containerConfig.nav2Link && containerConfig.nav2Link != "" ? containerConfig.nav2Link : "https://skillstx.com/support-center/" }} target="_blank">
                                        {containerConfig && containerConfig.nav2Label && containerConfig.nav2Label != "" ? containerConfig.nav2Label : <ContentTranslator page="Menu" name="MenuSupport" contentText="Support" />}
                                    </NavLink>
                                ) : (
                                    <NavLink tag={RRNavLink} activeStyle={navlinkActiveStyle} style={navlinkStyle} to={{ pathname: "https://skillstx.com/support-center/" }} target="_blank">
                                        <ContentTranslator page="Menu" name="MenuSupport" contentText="Support" />
                                    </NavLink>
                                )}
                            </NavItem>
                            <NavItem>
                                {containerConfig && ((containerConfig.nav3Label && containerConfig.nav3Label != "") || (containerConfig.nav3Link && containerConfig.nav3Link != "")) ? (
                                    <NavLink tag={RRNavLink} activeStyle={navlinkActiveStyle} style={navlinkStyle} to={{ pathname: containerConfig && containerConfig.nav3Link && containerConfig.nav3Link != "" ? containerConfig.nav3Link : "https://skillstx.com/wisdom/" }} target="_blank">
                                        {containerConfig && containerConfig.nav3Label && containerConfig.nav3Label != "" ? containerConfig.nav3Label : <ContentTranslator page="Menu" name="MenuNews" contentText="News" />}
                                    </NavLink>
                                ) : (
                                    <NavLink tag={RRNavLink} activeStyle={navlinkActiveStyle} style={navlinkStyle} to={{ pathname: "https://skillstx.com/wisdom/" }} target="_blank">
                                        <ContentTranslator page="Menu" name="MenuNews" contentText="News" />
                                    </NavLink>
                                )}
                            </NavItem>
                            {!this.props.loginWithTeams && (
                                <NavItem>
                                    <Link to="#" className="nav-link" style={navlinkStyle} onClick={this.logout}>
                                        <ContentTranslator page="Menu" name="MenuLogout" contentText="Logout" />
                                    </Link>
                                </NavItem>
                            )}
                        </ul>
                    </Collapse>
                    <div id="rightMenu" style={headerRightStyle}>
                        <NavRightLogin languages={this.props.languages} selectedLanguage={this.props.language} setCurrentLanguage={this.props.setCurrentLanguage} />
                    </div>
                </Container>
            </Navbar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.language.loading,
        languages: state.language.languages,
        samlEnabled: state.config.samlEnabled,

        brandingLogoImage: state.company.brandingLogoImage,
        brandingLogoChecked: state.company.brandingLogoChecked,

        containerConfig: state.config.containerConfig,
        loginWithMembee: state.auth.loginWithMembee,
        loginWithTeams: state.auth.loginWithTeams,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onLanguage: bindActionCreators(languageActions, dispatch),
        onPerson: bindActionCreators(personActions, dispatch),
        onCompany: bindActionCreators(companyActions, dispatch),
        onConfig: bindActionCreators(configActions, dispatch),
        onAdminPerson: bindActionCreators(adminPersonActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavMainLogin);
