import * as authActions from "../../../store/actions/authAction";
import * as adminCategoryActions from "../../../store/admin/actions/adminCategoryActions";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import ExtraFrameworkLevelDetail from "./ExtraFrameworkLevelDetail";
import { faPenToSquare, faPlus, faSync, faArrowUp, faArrowDown, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const rowStyle = {
    fontSize: "12px",
};

class ManageExtraFrameworkLevels extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            extraFrameworkLevels: this.props.extraFrameworkLevels,
            showDialogLevelDetailTs: null,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.extraFrameworkLevels !== this.props.extraFrameworkLevels) {
            this.setState({
                extraFrameworkLevels: this.props.extraFrameworkLevels,
            });
        }
    }

    componentWillUnmount() {}

    handleAddNewLevel = () => {
        const { extraFrameworkLevels } = this.state;
        if (extraFrameworkLevels && extraFrameworkLevels.length >= 7) {
            this.props.generateAlert("danger", "Max levels number allowed is 7");
        } else {
            this.setState({ showDialogLevelDetailTs: new Date(), selectedExtraFrameworkLevel: null });
        }
    };

    handleOpenLevelDetail = (extraFrameworkLevel) => {
        this.setState({ showDialogLevelDetailTs: new Date(), selectedExtraFrameworkLevel: extraFrameworkLevel });
    };

    handleMoveLevelUp = (extraFrameworkLevel) => {
        this.props.onAdminCategory.moveExtraFrameworkLevelUp(extraFrameworkLevel.id).then(() => {});
    };

    handleMoveLevelDown = (extraFrameworkLevel) => {
        this.props.onAdminCategory.moveExtraFrameworkLevelDown(extraFrameworkLevel.id).then(() => {});
    };

    handleDeleteLevel = (extraFrameworkLevel) => {
        if (window.confirm("Are you sure want to delete this data?")) {
            this.setState({ isUpdating: true });

            this.props.onAdminCategory.deleteExtraFrameworkLevel(extraFrameworkLevel.id).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.error) {
                        this.props.generateAlert("success", "Delete level success.");
                    } else {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    render() {
        const { pageAssignment } = this.props;
        const { extraFrameworkLevels } = this.state;

        let allowUpdate = false;
        // allowUpdate = pageAssignment.operations.find((it) => it.operation == "Manage" && it.allow == true) ? true : false;

        const openLevelDetail = this.handleOpenLevelDetail;
        const moveLevelUp = this.handleMoveLevelUp;
        const moveLevelDown = this.handleMoveLevelDown;
        const deleteLevel = this.handleDeleteLevel;

        const columns = [
            {
                id: "id",
                name: "Action",
                selector: (row) => row.id,
                sortable: false,
                width: "10%",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                            <FontAwesomeIcon id={`iconLevelDetail${row.id}`} icon={faPenToSquare} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => openLevelDetail(row)} />
                            <UncontrolledTooltip target={`iconLevelDetail${row.id}`} placement="bottom">
                                Update Level
                            </UncontrolledTooltip>
                            &nbsp;&nbsp;&nbsp;
                            <FontAwesomeIcon id={`iconDeleteLevel${row.id}`} icon={faMinusCircle} size="lg" style={{ cursor: "pointer", color: "#FF0000" }} onClick={() => deleteLevel(row)} />
                            <UncontrolledTooltip target={`iconDeleteLevel${row.id}`} placement="bottom">
                                Delete Level
                            </UncontrolledTooltip>
                        </div>
                    );
                },
                omit: !allowUpdate,
            },
            {
                id: "level",
                name: "Level",
                selector: (row) => row.level,
                sortable: false,
                width: allowUpdate ? "80%" : "100%",
            },
            {
                id: "levelNumber",
                name: "",
                selector: (row) => row.level,
                center: true,
                width: "10%",
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                            <FontAwesomeIcon id={`iconMoveUp${row.id}`} icon={faArrowUp} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => moveLevelUp(row)} />
                            <UncontrolledTooltip target={`iconMoveUp${row.id}`} placement="bottom">
                                Move Level Up
                            </UncontrolledTooltip>
                            &nbsp;&nbsp;&nbsp;
                            <FontAwesomeIcon id={`iconMoveDown${row.id}`} icon={faArrowDown} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => moveLevelDown(row)} />
                            <UncontrolledTooltip target={`iconMoveDown${row.id}`} placement="bottom">
                                Move Level Down
                            </UncontrolledTooltip>
                        </div>
                    );
                },
                omit: !allowUpdate,
            },
        ];

        return (
            <React.Fragment>
                <ExtraFrameworkLevelDetail extraFrameworkLevel={this.state.selectedExtraFrameworkLevel} showDialog={this.state.showDialogLevelDetailTs} generateAlert={this.props.generateAlert}></ExtraFrameworkLevelDetail>
                <p className="mb-0" style={{ color: "#2B2B2B", fontSize: "22px", fontFamily: "IBMPlexSans-Bold", paddingBottom: "5px" }}>
                    Extra Framework Levels
                </p>
                <div>
                    <Row>
                        <Col md="6" sm="6">
                            <Button
                                color="primary"
                                onClick={this.props.handleRefreshLevels}
                                style={{
                                    fontSize: "12px",
                                    height: "35px",
                                    marginBottom: "10px",
                                }}
                                disabled={this.props.categoryLoading || this.state.isUpdating}
                            >
                                <FontAwesomeIcon icon={faSync} /> Refresh
                            </Button>
                            {allowUpdate && (
                                <React.Fragment>
                                    &nbsp;&nbsp;
                                    {pageAssignment.operations.find((it) => it.operation == "Manage" && it.allow == true) && (
                                        <Button
                                            color="primary"
                                            onClick={this.handleAddNewLevel}
                                            style={{
                                                fontSize: "12px",
                                                height: "35px",
                                                marginBottom: "10px",
                                            }}
                                            disabled={this.props.levelLoading || this.state.isUpdating}
                                        >
                                            <FontAwesomeIcon icon={faPlus} /> Add Level
                                        </Button>
                                    )}
                                </React.Fragment>
                            )}
                        </Col>
                    </Row>
                    <SkillsTxTable columns={columns} data={extraFrameworkLevels} pagination={false} selectableRows={true} selectableRowsHighlight={true} progressPending={this.props.levelLoading}></SkillsTxTable>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        authPersonId: state.auth.personId,

        isUpdating: state.adminCategory.isUpdating,
        levelLoading: state.adminCategory.levelLoading,
        categoryError: state.adminCategory.error,
        extraFrameworkLevels: state.adminCategory.extraFrameworkLevels,

        pageAssignments: state.adminPerson.pageAssignments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onAdminCategory: bindActionCreators(adminCategoryActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageExtraFrameworkLevels));
