import * as evidenceActions from "../../store/actions/evidenceAction";
import { contentTranslator } from "../../utils/translatorUtils";
import ContentTranslator from "../translator/ContentTranslator";
import AddNewEvidenceForm from "./AddNewEvidenceForm";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const buttonActionPrimaryStyle = {
    height: "32px",
    width: "80px",
};

class EvidenceResponsibilityBadgeBody extends React.Component {
    static displayName = EvidenceResponsibilityBadgeBody.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showAddEvidenceForm: false,
        };
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {}

    handleAddEvidenceClick = () => {
        this.setState({
            showAddEvidenceForm: !this.state.showAddEvidenceForm,
        });
    };

    handleCloseAddEvidence = () => {
        this.setState({
            showAddEvidenceForm: false,
        });
    };

    handleEvidenceAdded = (addedEvidence) => {
        this.handleCloseAddEvidence();
        this.props.handleEvidenceAdded(addedEvidence);
    };

    render() {
        const { skill, submitBadgeRequestSkills, maxNumberOfBadgeRequestSkill } = this.props;

        const skillSelected = submitBadgeRequestSkills && submitBadgeRequestSkills.find((it) => it.skillsCode == skill.skillsCode);

        if (skill) {
            return (
                <React.Fragment>
                    <tr style={{ display: !this.props.skillHidden ? "" : "none" }}>
                        <td className={"pt-1 pb-1 border-0"}></td>
                        <td className={"pt-1 border-0"}>{`${this.contentTranslator(this.props.contentTranslations, "Skills", skill.skill, skill.skill)} (${skill.skillsCode})`}</td>
                        <td colSpan="2" className={"pt-1 pb-1 td-item "}>
                            <label
                                style={{ cursor: "pointer", color: "#212529", textDecoration: "underline" }}
                                onClick={() =>
                                    this.props.openSkillDescriptionModal({
                                        skillsCode: skill.skillsCode,
                                        description: this.contentTranslator(this.props.contentTranslations, "Skills", `${skill.name} Description`, skill.description),
                                    })
                                }
                            >
                                <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceViewDescription" contentText="View Description" />
                            </label>
                        </td>
                        <td className={"pt-1 pb-1 td-item "}>
                            {skillSelected ? (
                                <button className={`btn btn-primary btnAmber-Style`} style={buttonActionPrimaryStyle} onClick={() => this.props.handleEvidenceBadgeRequestUnSelect(skill)}>
                                    <FontAwesomeIcon icon={faMinus} style={{ fontSize: "13px" }} />
                                </button>
                            ) : submitBadgeRequestSkills.length >= maxNumberOfBadgeRequestSkill ? (
                                <button className={`btn btn-secondary`} style={buttonActionPrimaryStyle} disabled={true}>
                                    &nbsp;&nbsp;
                                </button>
                            ) : (
                                <button className={`btn btn-primary btnPrimary-Style`} style={buttonActionPrimaryStyle} onClick={() => this.props.handleEvidenceBadgeRequestSelect(skill)}>
                                    <FontAwesomeIcon icon={faPlus} style={{ fontSize: "13px" }} />
                                </button>
                            )}
                        </td>
                    </tr>
                </React.Fragment>
            );
        } else {
            return <React.Fragment></React.Fragment>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        managedPeople: state.auth.managedPeople,
        contentTranslations: state.content.contentTranslations,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onEvidence: bindActionCreators(evidenceActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EvidenceResponsibilityBadgeBody);
