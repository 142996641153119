import * as jobActions from "../../store/actions/jobAction";
import { parameterizedString } from "../../utils/stringUtils";
import { contentTranslator } from "../../utils/translatorUtils";
import { SkillsTxTable } from "../common/SkillsTxTable";
import ContentTranslator from "../translator/ContentTranslator";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const containerStyle = {
    fontSize: "11px",
    fontFamily: "IBMPlexSans-Light",
};

const titleStyle = {
    fontSize: "13px",
    fontFamily: "IBMPlexSans-Bold",
};

const subTitleStyle = {
    fontFamily: "IBMPlexSans-Bold",
};

const buttonStyle = {
    width: "86px",
    height: "25px",
};

const futureRequirementCheckLink = {
    textDecoration: "underline",
    color: "#007bff",
};

class SkillProgress extends React.Component {
    static displayName = SkillProgress.name;
    constructor(props) {
        super(props);
        this.state = {
            jobLoading: false,
            jobUpdating: false,
            selectedCurrentJob: this.props.currentJob,
            selectedFutureJob: this.props.futureJob,
            editJob: false,
            jobMatches: [],
            searchJobName: "",
            byPassFutureRequirementCheck: false,
            showFutureRequirementCheckDialog: false,
        };
        this.contentTranslator = contentTranslator.bind(this);
        this.parameterizedString = parameterizedString.bind(this);
    }

    componentDidMount() {
        const { selectedCurrentJob, selectedFutureJob } = this.state;

        const jobMatches = [
            {
                id: "None",
                jobTitle: "None",
                currentSelected: false,
                futureSelected: !selectedFutureJob,
            },
            ...this.props.jobMatches,
        ];

        jobMatches
            .filter((it) => it.id != "None")
            .forEach((jobMatch) => {
                jobMatch.currentSelected = false;
                jobMatch.futureSelected = false;
                jobMatch.disabled = false;

                if (selectedCurrentJob && jobMatch.id == selectedCurrentJob.id) {
                    jobMatch.currentSelected = true;
                }

                if (selectedFutureJob && jobMatch.id == selectedFutureJob.id) {
                    jobMatch.futureSelected = true;
                }
            });

        jobMatches.forEach((it) => {
            it.jobTitleSort = it.id == "None" ? "  " + it.jobTitle : it.jobTitle;
        });

        this.setState({ jobMatches: jobMatches.sort((a, b) => a.jobTitleSort > b.jobTitleSort) });
    }

    openEditJob = () => {
        const { jobMatches } = this.state;
        let selectedCurrentJob = this.props.currentJob;
        let selectedFutureJob = this.props.futureJob;

        jobMatches
            //.filter(it => it.id != "None")
            .forEach((jobMatch) => {
                jobMatch.currentSelected = false;

                if (selectedCurrentJob && jobMatch.id == selectedCurrentJob.id) {
                    jobMatch.currentSelected = true;
                } else if (!selectedCurrentJob && jobMatch.id == "None") {
                    jobMatch.currentSelected = true;
                }
            });

        jobMatches.forEach((jobMatch) => {
            jobMatch.futureSelected = false;

            if (selectedFutureJob && jobMatch.id == selectedFutureJob.id) {
                jobMatch.futureSelected = true;
            } else if (!selectedFutureJob && jobMatch.id == "None") {
                jobMatch.futureSelected = true;
            }
        });

        if (!selectedCurrentJob) {
            selectedCurrentJob = jobMatches.find((it) => it.id == "None");
        }

        if (!selectedFutureJob) {
            selectedFutureJob = jobMatches.find((it) => it.id == "None");
        }

        this.setState({
            editJob: true,
            selectedCurrentJob,
            selectedFutureJob,
            jobMatches,
            byPassFutureRequirementCheck: false,
        });
    };

    togleEditJob = () => {
        const { editJob, jobLoading } = this.state;
        if (!jobLoading) {
            this.setState({ editJob: !editJob });
        }
    };

    changeSelectedCurrentJob = (row) => {
        const { jobMatches } = this.state;

        jobMatches
            //.filter(it => it.id != "None")
            .forEach((jobMatch) => {
                jobMatch.currentSelected = false;

                if (jobMatch.id == row.id) {
                    jobMatch.currentSelected = true;
                }
            });

        this.setState({ selectedCurrentJob: row, jobMatches });
    };

    changeSelectedFutureJob = (row) => {
        const { jobMatches } = this.state;

        jobMatches.forEach((jobMatch) => {
            jobMatch.futureSelected = false;

            if (jobMatch.id == row.id) {
                jobMatch.futureSelected = true;
            }
        });

        this.setState({ selectedFutureJob: row, jobMatches });
    };

    componentDidUpdate(prevProps) {
        const { selectedCurrentJob, selectedFutureJob } = this.state;

        if (prevProps.jobMatches !== this.props.jobMatches) {
            const propJobMatches = this.props.jobMatches.filter((it) => it.jobType != "Proposed");

            const jobMatches = [
                {
                    id: "None",
                    jobTitle: "None",
                    currentSelected: false,
                    futureSelected: !selectedFutureJob,
                },
                ...propJobMatches.sort((a, b) => a.jobTitle > b.jobTitle),
            ];

            jobMatches
                .filter((it) => it.id != "None")
                .forEach((jobMatch) => {
                    jobMatch.currentSelected = false;
                    jobMatch.futureSelected = false;
                    jobMatch.disabled = false;

                    if (selectedCurrentJob && jobMatch.id == selectedCurrentJob.id) {
                        jobMatch.currentSelected = true;
                    }

                    if (selectedFutureJob && jobMatch.id == selectedFutureJob.id) {
                        jobMatch.futureSelected = true;
                    }
                });

            jobMatches.forEach((it) => {
                it.jobTitleSort = it.id == "None" ? "  " + it.jobTitle : it.jobTitle;
            });

            this.setState({ jobMatches: jobMatches.sort((a, b) => a.jobTitleSort > b.jobTitleSort) });
        }

        if (prevProps.forceOpenEditJob !== this.props.forceOpenEditJob) {
            if (this.props.forceOpenEditJob) {
                this.openEditJob();
            }
        }
    }

    handleSaveEditJobClick = () => {
        const { currentJob, futureJob } = this.props;
        const { selectedCurrentJob, selectedFutureJob, byPassFutureRequirementCheck } = this.state;

        const data = {
            oldCurrentJobMatchId: currentJob ? currentJob.id : null,
            newCurrentJobMatchId: selectedCurrentJob ? selectedCurrentJob.id : null,
            oldFutureJobMatchId: futureJob ? futureJob.id : null,
            newFutureJobMatchId: selectedFutureJob ? selectedFutureJob.id : null,
            managedPeopleEmail: this.props.managedPeople ? this.props.managedPeople.personB.email : null,
        };

        if (data.oldFutureJobMatchId && data.oldFutureJobMatchId != data.newFutureJobMatchId && byPassFutureRequirementCheck == false) {
            this.setState({ showFutureRequirementCheckDialog: true });
        } else {
            this.setState({ jobUpdating: true });
            Promise.resolve(this.props.managedPeople ? this.props.onJob.updateManagedPeopleJobMatches(data) : this.props.onJob.updateMyJobMatches(data)).then(() => {
                if (!this.props.jobUpdating) {
                    if (this.props.jobError) {
                        this.props.generateAlert("danger", this.props.jobError);
                    }
                }

                if (this.props.jobMatchesUpdated) {
                    this.setState({ editJob: false });
                    this.props.handleJobMatchesUpdated();
                }

                this.setState({ jobUpdating: this.props.jobUpdating });
            });
        }
    };

    handleSearchByJobName = (event) => {
        const { selectedCurrentJob, selectedFutureJob } = this.state;
        const { name, value } = event.target;

        const jobMatches = [
            {
                id: "None",
                jobTitle: "None",
                currentSelected: false,
                futureSelected: !selectedFutureJob,
            },
            ...this.props.jobMatches,
        ];

        jobMatches
            .filter((it) => it.id != "None")
            .forEach((jobMatch) => {
                jobMatch.currentSelected = false;
                jobMatch.futureSelected = false;
                jobMatch.disabled = false;

                if (selectedCurrentJob && jobMatch.id == selectedCurrentJob.id) {
                    jobMatch.currentSelected = true;
                }

                if (selectedFutureJob && jobMatch.id == selectedFutureJob.id) {
                    jobMatch.futureSelected = true;
                }
            });

        jobMatches.forEach((it) => {
            it.jobTitleSort = it.id == "None" ? "  " + it.jobTitle : it.jobTitle;
        });

        this.setState({ searchJobName: value, jobMatches: jobMatches.sort((a, b) => a.jobTitleSort > b.jobTitleSort).filter((it) => it.jobTitle.toLowerCase().includes(value.toLowerCase())) });
    };

    renderModalEditJob = () => {
        const { currentJob, futureJob, currAssessment } = this.props;
        const { selectedCurrentJob, selectedFutureJob, jobMatches } = this.state;

        const changeSelectedCurrentJob = this.changeSelectedCurrentJob;
        const changeSelectedFutureJob = this.changeSelectedFutureJob;

        jobMatches.forEach((it) => {
            it.jobTitleSort = it.id == "None" ? "  " + it.jobTitle : it.jobTitle;
        });

        const columns = [
            {
                id: "jobTitleSort",
                name: this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "AssignSkillRequirementFieldJobName", "Name"),
                selector: (row) => row.jobTitleSort,
                sortable: true,
                width: "40%",
                format: function (row, index) {
                    return row.id != "None" ? (
                        <Link to={`${window.location.pathname.toLowerCase().includes("sfiaprofile/") ? "" : "SfiaProfile/"}JobProfile/${row.jobId}`} target="_blank">
                            {row.jobTitle}
                        </Link>
                    ) : (
                        row.jobTitle
                    );
                },
            },
            {
                id: "matchPercentValidated",
                name: this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "AssignSkillRequirementFieldEndorsed", "Endorsed"),
                selector: (row) => row.matchPercentValidated,
                sortable: true,
                width: "15%",
                center: true,
                format: function (row, index) {
                    return row.matchPercentValidated != undefined ? `${(Math.round(row.matchPercentValidated * 100) / 100).toFixed(2)}%` : "";
                },
            },
            {
                id: "matchPercentSelf",
                name: this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "AssignSkillRequirementFieldSelfAssessed", "Self Assessed"),
                selector: (row) => row.matchPercentSelf,
                sortable: true,
                width: "15%",
                center: true,
                format: function (row, index) {
                    return row.matchPercentSelf != undefined ? `${(Math.round(row.matchPercentSelf * 100) / 100).toFixed(2)}%` : "";
                },
            },
            {
                id: "currentSelected",
                name: this.contentTranslator(this.props.contentTranslations, "SfiaAll", "Current", "Current"),
                selector: (row) => row.currentSelected,
                sortable: true,
                width: "15%",
                center: true,
                format: function (row, index) {
                    if ((currAssessment && currAssessment.hasCurrentJobLocked == false) || row.currentSelected == true) {
                        return (
                            <FormGroup check style={{ paddingLeft: "0px" }}>
                                <Label check>
                                    <Input type="radio" name="radioCurrentJob" checked={row.currentSelected} onChange={() => {}} onClick={() => changeSelectedCurrentJob(row)} />
                                </Label>
                            </FormGroup>
                        );
                    } else {
                        return "";
                    }
                },
            },
            {
                id: "futureSelected",
                name: this.contentTranslator(this.props.contentTranslations, "SfiaAll", "Future", "Future"),
                selector: (row) => row.futureSelected,
                sortable: true,
                width: "15%",
                center: true,
                format: function (row, index) {
                    if ((currAssessment && currAssessment.hasFutureJobLocked == false) || row.futureSelected == true) {
                        return (
                            <FormGroup check style={{ paddingLeft: "0px" }}>
                                <Label check>
                                    <Input type="radio" name="radioFutureJob" checked={row.futureSelected} onChange={() => {}} onClick={() => changeSelectedFutureJob(row)} />
                                </Label>
                            </FormGroup>
                        );
                    } else {
                        return "";
                    }
                },
            },
        ];

        return (
            <Modal isOpen={this.state.editJob} toggle={this.togleEditJob} style={{ maxWidth: "75%" }}>
                <ModalHeader toggle={this.togleEditJob}>
                    <ContentTranslator page="SfiaProfile" name="AssignSkillRequirementTitle" contentText="Assign Skill Requirement(s)" />
                </ModalHeader>
                <ModalBody style={{ maxHeight: "500px", overflowY: "scroll", overflowX: "hidden" }}>
                    <Row>
                        <Col>
                            <span>
                                <ContentTranslator
                                    page="SfiaProfile"
                                    name="AssignSkillRequirementMessage"
                                    contentText="Please assign your current and your future skill requirement from the options below.<br />NOTE: - The list will often include your job or role. But could also include SFIA Views and/or industry profiles. – DO NOT assign the same for both Current & Future."
                                />
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}></Col>
                        <Col sm={4} style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                            <Input type="text" name="name" id={"name"} value={this.state.searchJobName} onChange={this.handleSearchByJobName} placeholder={this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SearchRequirementTooltip", "Search by requirement name")} />
                        </Col>
                    </Row>
                    <SkillsTxTable columns={columns} data={this.state.jobMatches} fixedHeader={true} fixedHeaderScrollHeight="400px" progressPending={this.state.jobLoading}></SkillsTxTable>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.togleEditJob} disabled={this.state.jobLoading}>
                        <ContentTranslator page="SfiaProfile" name="AssignSkillRequirementButtonClose" contentText="Close" />
                    </Button>{" "}
                    <Button
                        color="primary"
                        onClick={() => this.handleSaveEditJobClick()}
                        disabled={
                            this.state.jobLoading ||
                            this.state.jobUpdating ||
                            (currentJob && selectedCurrentJob && currentJob.id == selectedCurrentJob.id && futureJob && selectedFutureJob && futureJob.id == selectedFutureJob.id
                                ? true
                                : selectedFutureJob && selectedCurrentJob.id == selectedFutureJob.id && (selectedCurrentJob.id != "None" || selectedFutureJob.id != "None"))
                        }
                    >
                        {!this.state.jobUpdating ? (
                            this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "AssignSkillRequirementButtonSave", "Save changes")
                        ) : (
                            <React.Fragment>
                                <ContentTranslator page="SfiaProfile" name="AssignSkillRequirementButtonSave" contentText="Save changes" /> <Spinner type="grow" size="sm" color="light" />
                            </React.Fragment>
                        )}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    togleFutureRequirementCheckDialog = () => {
        const showFutureRequirementCheckDialog = this.state.showFutureRequirementCheckDialog;
        this.setState({ showFutureRequirementCheckDialog: !showFutureRequirementCheckDialog });
    };

    handleConfirmFutureRequirementCheck = () => {
        this.setState(
            {
                showFutureRequirementCheckDialog: false,
                byPassFutureRequirementCheck: true,
            },
            () => {
                this.handleSaveEditJobClick();
            }
        );
    };

    renderFutureRequirementCheckDialog = () => {
        const { showFutureRequirementCheckDialog } = this.state;

        return (
            <Modal isOpen={showFutureRequirementCheckDialog}>
                <ModalHeader toggle={this.togleFutureRequirementCheckDialog}>
                    <ContentTranslator page="SfiaProfile" name="AssignSkillFutureRequirementCheckTitle" contentText="Future Requirement Check" />
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label
                                        sm={12}
                                        dangerouslySetInnerHTML={{
                                            __html: this.parameterizedString(
                                                this.contentTranslator(
                                                    this.props.contentTranslations,
                                                    "SfiaProfile",
                                                    "AssignSkillFutureRequirementCheckNote",
                                                    "Please check the details of this requirement for criteria that may be required. For example, security clearances and certifications.<br />You can click <a class='futureRequirementCheckLink' onClick='{s1}'>here</a> for details.<br /><b>Note</b>: You may need to use the <b>Show More Requirement Detail</b> button."
                                                ),
                                                this.state.selectedFutureJob ? `openFutureRequirementDetail("${window.location.pathname.toLowerCase().includes("sfiaprofile/") ? "" : "SfiaProfile/"}JobProfile/${this.state.selectedFutureJob.jobId}");` : "openFutureRequirementDetail(null);"
                                            ),
                                        }}
                                    ></Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmFutureRequirementCheck();
                        }}
                    >
                        <ContentTranslator page="SfiaAll" name="OK" contentText="OK" />
                    </Button>
                    <Button color="secondary" onClick={this.togleFutureRequirementCheckDialog}>
                        <ContentTranslator page="SfiaAll" name="Cancel" contentText="Cancel" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    render() {
        const editJob = this.state.editJob;
        const { isSelfAssessOk, currentJob, futureJob, isEndorseOk } = this.props;

        return (
            <Container style={containerStyle}>
                {editJob && this.renderModalEditJob()}
                {this.renderFutureRequirementCheckDialog()}
                <Row>
                    <Col>
                        <span style={titleStyle}>
                            <ContentTranslator page="SfiaProfile" name="RequirementProgressTitle" contentText="Requirement Progress" />
                        </span>
                    </Col>
                    {(!currentJob || (currentJob && currentJob.isEditable) || (futureJob && futureJob.isEditable)) && (!this.props.managedPeople || (this.props.managedPeople && this.props.managedPeople.relationshipType == "Line Manager" ? true : false)) && (
                        <Col xs="auto" className="pr-0 ml-auto">
                            <button id="btnAssignRequirement" className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={() => this.openEditJob()}>
                                <ContentTranslator page="SfiaProfile" name="RequirementProgressButtonAssign" contentText="Assign" />
                            </button>
                            <UncontrolledTooltip target={`btnAssignRequirement`} placement="bottom">
                                <ContentTranslator page="SfiaProfileTooltip" name="RequirementProgressButtonAssignTooltip" contentText="View and/or Assign yourself to Skill Requirement(s)" />
                            </UncontrolledTooltip>
                        </Col>
                    )}
                </Row>
                <div>
                    <Row>
                        <Col>
                            <span style={subTitleStyle}>
                                <ContentTranslator page="SfiaProfile" name="RequirementProgressSelfAssessedTitle" contentText="Self Assessed" />
                            </span>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col xs="7">
                            <span>{currentJob ? this.contentTranslator(this.props.contentTranslations, "SfiaAll", "Current", "Current") + " - " + currentJob.jobTitle : this.contentTranslator(this.props.contentTranslations, "SfiaAll", "Current", "Current")}</span>
                        </Col>
                        {currentJob ? (
                            <React.Fragment>
                                <Col>
                                    <Progress value={currentJob.matchPercentSelf} />
                                </Col>
                                <Col xs="1" className="p-0">
                                    <p className="text-right m-0">{`${(Math.round(currentJob.matchPercentSelf * 100) / 100).toFixed(2)}%`}</p>
                                </Col>
                            </React.Fragment>
                        ) : (
                            <Col>
                                <ContentTranslator page="SfiaProfile" name="JobProfileNoCurrentJobSelected" contentText="No Current Job Selected" />
                            </Col>
                        )}
                    </Row>
                    <Row className="align-items-center">
                        <Col xs="7">
                            <span>{futureJob ? this.contentTranslator(this.props.contentTranslations, "SfiaAll", "Future", "Future") + " - " + futureJob.jobTitle : this.contentTranslator(this.props.contentTranslations, "SfiaAll", "Future", "Future")}</span>
                        </Col>
                        {futureJob ? (
                            <React.Fragment>
                                <Col>
                                    <Progress value={futureJob.matchPercentSelf} />
                                </Col>
                                <Col xs="1" className="p-0">
                                    <p className="text-right m-0">{`${(Math.round(futureJob.matchPercentSelf * 100) / 100).toFixed(2)}%`}</p>
                                </Col>
                            </React.Fragment>
                        ) : (
                            <Col>
                                <ContentTranslator page="SfiaProfile" name="JobProfileNoFutureJobSelected" contentText="No Future Job Selected" />
                            </Col>
                        )}
                    </Row>
                </div>
                <br />
                <div>
                    <Row>
                        <Col>
                            <span style={subTitleStyle}>
                                <ContentTranslator page="SfiaProfile" name="RequirementProgressEndorsedTitle" contentText="Endorsed" />
                            </span>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col xs="7">
                            <span>{currentJob ? this.contentTranslator(this.props.contentTranslations, "SfiaAll", "Current", "Current") + " - " + currentJob.jobTitle : this.contentTranslator(this.props.contentTranslations, "SfiaAll", "Current", "Current")}</span>
                        </Col>
                        {currentJob ? (
                            <React.Fragment>
                                <Col>
                                    <Progress value={currentJob.matchPercentValidated} />
                                </Col>
                                <Col xs="1" className="p-0">
                                    <p className="text-right m-0">{`${(Math.round(currentJob.matchPercentValidated * 100) / 100).toFixed(2)}%`}</p>
                                </Col>
                            </React.Fragment>
                        ) : (
                            <Col>
                                <ContentTranslator page="SfiaProfile" name="JobProfileNoCurrentJobSelected" contentText="No Current Job Selected" />
                            </Col>
                        )}
                    </Row>
                    <Row className="align-items-center mb-2">
                        <Col xs="7">
                            <span>{futureJob ? this.contentTranslator(this.props.contentTranslations, "SfiaAll", "Future", "Future") + " - " + futureJob.jobTitle : this.contentTranslator(this.props.contentTranslations, "SfiaAll", "Future", "Future")}</span>
                        </Col>
                        {futureJob ? (
                            <React.Fragment>
                                <Col>
                                    <Progress value={futureJob.matchPercentValidated} />
                                </Col>
                                <Col xs="1" className="p-0">
                                    <p className="text-right m-0">{`${(Math.round(futureJob.matchPercentValidated * 100) / 100).toFixed(2)}%`}</p>
                                </Col>
                            </React.Fragment>
                        ) : (
                            <Col>
                                <ContentTranslator page="SfiaProfile" name="JobProfileNoFutureJobSelected" contentText="No Future Job Selected" />
                            </Col>
                        )}
                    </Row>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        jobLoading: state.job.loading,
        jobUpdating: state.job.isUpdating,
        jobError: state.job.error,
        jobMatches: state.job.jobMatches,
        jobMatchesUpdated: state.job.jobMatchesUpdated,

        managedPeople: state.auth.managedPeople,
        contentTranslations: state.content.contentTranslations,

        currAssessment: state.config.currAssessment,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onJob: bindActionCreators(jobActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillProgress);
