export const filterArray = (data, searchKeyword, searchProperties, columnSearchKeywords) => {
    let filtered = [...data];

    if (searchKeyword && searchKeyword != "") {
        if (searchProperties && searchProperties.length > 0) {
            filtered = filtered.filter((item) => {
                return searchProperties.some((field) => (typeof item[field] === "string" && item[field].toLowerCase().includes(searchKeyword.toLowerCase())) || (typeof item[field] === "number" && item[field].toString().toLowerCase().includes(searchKeyword.toLowerCase())));
            });
        } else {
            filtered = filtered.filter((entry) => Object.values(entry).some((val) => (typeof val === "string" && val.toLowerCase().includes(searchKeyword.toLowerCase())) || (typeof val === "number" && val.toString().toLowerCase().includes(searchKeyword.toLowerCase()))));
        }
    }

    if (columnSearchKeywords && columnSearchKeywords.length > 0) {
        columnSearchKeywords
            .filter((it) => it.value && it.value != "")
            .forEach((columnSearchKeyword) => {
                filtered = filtered.filter(
                    (entry) =>
                        (typeof entry[columnSearchKeyword.name] === "string" &&
                            (columnSearchKeyword.searchType == "startsWith" ? entry[columnSearchKeyword.name].toLowerCase().startsWith(columnSearchKeyword.value.toLowerCase()) : entry[columnSearchKeyword.name].toLowerCase().includes(columnSearchKeyword.value.toLowerCase()))) ||
                        (typeof entry[columnSearchKeyword.name] === "number" &&
                            (columnSearchKeyword.searchType == "startsWith"
                                ? entry[columnSearchKeyword.name].toString().toLowerCase().startsWith(columnSearchKeyword.value.toLowerCase())
                                : entry[columnSearchKeyword.name].toString().toLowerCase().includes(columnSearchKeyword.value.toLowerCase())))
                );
            });
    }

    return filtered;
};
