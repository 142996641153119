import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class LanguageAPI {
    static getLanguages() {
        return axios
            .get(`/languages`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }
}

export default LanguageAPI;