import * as configActions from "../../../store/actions/configAction";
import changeInput from "../../../utils/changeInput";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";

class UserUpdatePersonMiscellaneousConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDialog: false,
            isUpdating: false,
            form: {
                personMiscellaneous1Enable: {
                    validation: {
                        required: false,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                personMiscellaneous1Caption: {
                    validation: {
                        required: true,
                        minLength: 1,
                    },
                    value: "Miscellaneous 1",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                personMiscellaneous2Enable: {
                    validation: {
                        required: false,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                personMiscellaneous2Caption: {
                    validation: {
                        required: true,
                        minLength: 1,
                    },
                    value: "Miscellaneous 2",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                personMiscellaneous3Enable: {
                    validation: {
                        required: false,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                personMiscellaneous3Caption: {
                    validation: {
                        required: true,
                        minLength: 1,
                    },
                    value: "Miscellaneous 3",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                personMiscellaneous4Enable: {
                    validation: {
                        required: false,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                personMiscellaneous4Caption: {
                    validation: {
                        required: true,
                        minLength: 1,
                    },
                    value: "Miscellaneous 4",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                personMiscellaneous5Enable: {
                    validation: {
                        required: false,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                personMiscellaneous5Caption: {
                    validation: {
                        required: true,
                        minLength: 1,
                    },
                    value: "Miscellaneous 5",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: true,
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
            this.prepareFormState();
        }
    }

    prepareFormState = () => {
        const config = this.props.config;
        if (config) {
            this.setState({
                form: {
                    personMiscellaneous1Enable: {
                        validation: {
                            required: false,
                        },
                        value: config ? config.personMiscellaneous1Enable : false,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    personMiscellaneous1Caption: {
                        validation: {
                            required: true,
                            minLength: 1,
                        },
                        value: config && config.personMiscellaneous1Caption ? config.personMiscellaneous1Caption : "Miscellaneous 1",
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    personMiscellaneous2Enable: {
                        validation: {
                            required: false,
                        },
                        value: config ? config.personMiscellaneous2Enable : false,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    personMiscellaneous2Caption: {
                        validation: {
                            required: true,
                            minLength: 1,
                        },
                        value: config && config.personMiscellaneous2Caption ? config.personMiscellaneous2Caption : "Miscellaneous 2",
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    personMiscellaneous3Enable: {
                        validation: {
                            required: false,
                        },
                        value: config ? config.personMiscellaneous3Enable : false,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    personMiscellaneous3Caption: {
                        validation: {
                            required: true,
                            minLength: 1,
                        },
                        value: config && config.personMiscellaneous3Caption ? config.personMiscellaneous3Caption : "Miscellaneous 3",
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    personMiscellaneous4Enable: {
                        validation: {
                            required: false,
                        },
                        value: config ? config.personMiscellaneous4Enable : false,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    personMiscellaneous4Caption: {
                        validation: {
                            required: true,
                            minLength: 1,
                        },
                        value: config && config.personMiscellaneous4Caption ? config.personMiscellaneous4Caption : "Miscellaneous 4",
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    personMiscellaneous5Enable: {
                        validation: {
                            required: false,
                        },
                        value: config ? config.personMiscellaneous5Enable : false,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    personMiscellaneous5Caption: {
                        validation: {
                            required: true,
                            minLength: 1,
                        },
                        value: config && config.personMiscellaneous5Caption ? config.personMiscellaneous5Caption : "Miscellaneous 5",
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                },
                isFormValid: true,
            });
        }
    };

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;

        this.setState({
            showDialog: !showDialog,
        });
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;

        const updateState = changeInput(this.state.form, name, checked);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handlePerformSave = () => {
        const form = this.state.form;

        const config = {
            personMiscellaneous1Enable: form.personMiscellaneous1Enable.value,
            personMiscellaneous1Caption: form.personMiscellaneous1Caption.value,
            personMiscellaneous2Enable: form.personMiscellaneous2Enable.value,
            personMiscellaneous2Caption: form.personMiscellaneous2Caption.value,
            personMiscellaneous3Enable: form.personMiscellaneous3Enable.value,
            personMiscellaneous3Caption: form.personMiscellaneous3Caption.value,
            personMiscellaneous4Enable: form.personMiscellaneous4Enable.value,
            personMiscellaneous4Caption: form.personMiscellaneous4Caption.value,
            personMiscellaneous5Enable: form.personMiscellaneous5Enable.value,
            personMiscellaneous5Caption: form.personMiscellaneous5Caption.value,
        };

        this.setState({ isUpdating: true });
        this.props.onConfig.updateAdminPersonMiscellaneousConfig(config).then(() => {
            if (!this.props.isUpdating) {
                if (this.props.error) {
                    this.props.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    this.props.generateAlert("success", "Update additional columns setting success");
                    this.togleDialogModal();
                }
            }
            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    render() {
        const { form } = this.state;
        const defaultColumnNames = [
            "personid",
            "fullname",
            "email",
            "preferredname",
            "position",
            "countrycode",
            "country",
            "location",
            "team",
            "other",
            "department",
            "businessunit",
            "division",
            "territory",
            "costcentre",
            "supervisorname",
            "iscandidate",
            "hasaction",
            "createdon",
            "lastupdatedon",
            "dayssincelastupdate",
            "activitystatus",
            "lastondemandactivitystatementsenton",
            "selfassessmentreason",
            "surveyrating",
            "surveycomment",
            "activitystatementinterval",
            "leaveon",
            "seniority",
            "employmentcontract",
            "timeincurrentrole",
            "timeinorganisation",
            "recentorcurrentrole",
            "experienceselection",
            "isapprovalrequested",
            "approvalrequestsenton",
            "issurveycomplete",
            "hasendorsedprofile",
            "totalquestions",
            "answeredquestions",
            "totaltimetaken",
            "completedsurveypercent",
            "inactive",
            "notes",
            "latestprofileversion",
            "latestprofilestatus",
            "latestversionisapproved",
            "latestversionapproveddate",
            "latestversionapprovalcomment",
            "previousversionisapproved",
            "previousversionapproveddate",
            "previousversionapprovalcomment",
            "currentrequirementassigned",
            "futurerequirementassigned",
            "datamodellastupdated",
            "datamodellastsync",
        ];

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={!this.state.isUpdating ? this.togleDialogModal : null} size="lg">
                    <ModalHeader toggle={!this.state.isUpdating ? this.togleDialogModal : null}>Additional Columns Setting</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup row>
                                        <Label sm={2}>Misc 1</Label>
                                        <Col sm={10}>
                                            <div style={{ display: "flex" }}>
                                                <Input type="checkbox" name="personMiscellaneous1Enable" id="personMiscellaneous1Enable" checked={form.personMiscellaneous1Enable.value} style={{ marginRight: "10px" }} disabled={this.state.isUpdating} onChange={this.handleChangeCheckBox} />
                                                <Input
                                                    type="text"
                                                    name="personMiscellaneous1Caption"
                                                    id="personMiscellaneous1Caption"
                                                    value={form.personMiscellaneous1Caption.value}
                                                    invalid={
                                                        form.personMiscellaneous1Caption.touched &&
                                                        (!form.personMiscellaneous1Caption.isValidFormat || (form.personMiscellaneous1Enable.value == true && form.personMiscellaneous1Caption.value && defaultColumnNames.includes(form.personMiscellaneous1Caption.value.toLowerCase())))
                                                    }
                                                    onChange={this.handleChange}
                                                    disabled={(form && form.personMiscellaneous1Enable && !form.personMiscellaneous1Enable.value) || this.state.isUpdating}
                                                />
                                            </div>
                                            {form.personMiscellaneous1Caption.touched && form.personMiscellaneous1Enable.value == true && form.personMiscellaneous1Caption.value && defaultColumnNames.includes(form.personMiscellaneous1Caption.value.toLowerCase()) && (
                                                <div style={{ paddingLeft: "25px", color: "#FF0000" }}>Column name not available</div>
                                            )}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={2}>Misc 2</Label>
                                        <Col sm={10}>
                                            <div style={{ display: "flex" }}>
                                                <Input type="checkbox" name="personMiscellaneous2Enable" id="personMiscellaneous2Enable" checked={form.personMiscellaneous2Enable.value} style={{ marginRight: "10px" }} disabled={this.state.isUpdating} onChange={this.handleChangeCheckBox} />
                                                <Input
                                                    type="text"
                                                    name="personMiscellaneous2Caption"
                                                    id="personMiscellaneous2Caption"
                                                    value={form.personMiscellaneous2Caption.value}
                                                    invalid={
                                                        form.personMiscellaneous2Caption.touched &&
                                                        (!form.personMiscellaneous2Caption.isValidFormat || (form.personMiscellaneous2Enable.value == true && form.personMiscellaneous2Caption.value && defaultColumnNames.includes(form.personMiscellaneous2Caption.value.toLowerCase())))
                                                    }
                                                    onChange={this.handleChange}
                                                    disabled={(form && form.personMiscellaneous2Enable && !form.personMiscellaneous2Enable.value) || this.state.isUpdating}
                                                />
                                            </div>
                                            {form.personMiscellaneous1Caption.touched && form.personMiscellaneous2Enable.value == true && form.personMiscellaneous2Caption.value && defaultColumnNames.includes(form.personMiscellaneous2Caption.value.toLowerCase()) && (
                                                <div style={{ paddingLeft: "25px", color: "#FF0000" }}>Column name not available</div>
                                            )}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={2}>Misc 3</Label>
                                        <Col sm={10}>
                                            <div style={{ display: "flex" }}>
                                                <Input type="checkbox" name="personMiscellaneous3Enable" id="personMiscellaneous3Enable" checked={form.personMiscellaneous3Enable.value} style={{ marginRight: "10px" }} disabled={this.state.isUpdating} onChange={this.handleChangeCheckBox} />
                                                <Input
                                                    type="text"
                                                    name="personMiscellaneous3Caption"
                                                    id="personMiscellaneous3Caption"
                                                    value={form.personMiscellaneous3Caption.value}
                                                    invalid={
                                                        form.personMiscellaneous3Caption.touched &&
                                                        (!form.personMiscellaneous3Caption.isValidFormat || (form.personMiscellaneous3Enable.value == true && form.personMiscellaneous3Caption.value && defaultColumnNames.includes(form.personMiscellaneous3Caption.value.toLowerCase())))
                                                    }
                                                    onChange={this.handleChange}
                                                    disabled={(form && form.personMiscellaneous3Enable && !form.personMiscellaneous3Enable.value) || this.state.isUpdating}
                                                />
                                            </div>
                                            {form.personMiscellaneous1Caption.touched && form.personMiscellaneous3Enable.value == true && form.personMiscellaneous3Caption.value && defaultColumnNames.includes(form.personMiscellaneous3Caption.value.toLowerCase()) && (
                                                <div style={{ paddingLeft: "25px", color: "#FF0000" }}>Column name not available</div>
                                            )}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={2}>Misc 4</Label>
                                        <Col sm={10}>
                                            <div style={{ display: "flex" }}>
                                                <Input type="checkbox" name="personMiscellaneous4Enable" id="personMiscellaneous4Enable" checked={form.personMiscellaneous4Enable.value} style={{ marginRight: "10px" }} disabled={this.state.isUpdating} onChange={this.handleChangeCheckBox} />
                                                <Input
                                                    type="text"
                                                    name="personMiscellaneous4Caption"
                                                    id="personMiscellaneous4Caption"
                                                    value={form.personMiscellaneous4Caption.value}
                                                    invalid={
                                                        form.personMiscellaneous4Caption.touched &&
                                                        (!form.personMiscellaneous4Caption.isValidFormat || (form.personMiscellaneous4Enable.value == true && form.personMiscellaneous4Caption.value && defaultColumnNames.includes(form.personMiscellaneous4Caption.value.toLowerCase())))
                                                    }
                                                    onChange={this.handleChange}
                                                    disabled={(form && form.personMiscellaneous4Enable && !form.personMiscellaneous4Enable.value) || this.state.isUpdating}
                                                />
                                            </div>
                                            {form.personMiscellaneous4Caption.touched && form.personMiscellaneous4Enable.value == true && form.personMiscellaneous4Caption.value && defaultColumnNames.includes(form.personMiscellaneous4Caption.value.toLowerCase()) && (
                                                <div style={{ paddingLeft: "25px", color: "#FF0000" }}>Column name not available</div>
                                            )}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={2}>Misc 5</Label>
                                        <Col sm={10}>
                                            <div style={{ display: "flex" }}>
                                                <Input type="checkbox" name="personMiscellaneous5Enable" id="personMiscellaneous5Enable" checked={form.personMiscellaneous5Enable.value} style={{ marginRight: "10px" }} disabled={this.state.isUpdating} onChange={this.handleChangeCheckBox} />
                                                <Input
                                                    type="text"
                                                    name="personMiscellaneous5Caption"
                                                    id="personMiscellaneous5Caption"
                                                    value={form.personMiscellaneous5Caption.value}
                                                    invalid={
                                                        form.personMiscellaneous5Caption.touched &&
                                                        (!form.personMiscellaneous5Caption.isValidFormat || (form.personMiscellaneous5Enable.value == true && form.personMiscellaneous5Caption.value && defaultColumnNames.includes(form.personMiscellaneous5Caption.value.toLowerCase())))
                                                    }
                                                    onChange={this.handleChange}
                                                    disabled={(form && form.personMiscellaneous5Enable && !form.personMiscellaneous5Enable.value) || this.state.isUpdating}
                                                />
                                            </div>
                                            {form.personMiscellaneous5Caption.touched && form.personMiscellaneous5Enable.value == true && form.personMiscellaneous5Caption.value && defaultColumnNames.includes(form.personMiscellaneous5Caption.value.toLowerCase()) && (
                                                <div style={{ paddingLeft: "25px", color: "#FF0000" }}>Column name not available</div>
                                            )}
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <React.Fragment>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.handlePerformSave();
                                }}
                                disabled={
                                    !this.state.isFormValid ||
                                    this.state.isUpdating ||
                                    (form && form.personMiscellaneous1Enable.value == true && form.personMiscellaneous1Caption.value && defaultColumnNames.includes(form.personMiscellaneous1Caption.value.toLowerCase())) ||
                                    (form && form.personMiscellaneous2Enable.value == true && form.personMiscellaneous2Caption.value && defaultColumnNames.includes(form.personMiscellaneous2Caption.value.toLowerCase())) ||
                                    (form && form.personMiscellaneous3Enable.value == true && form.personMiscellaneous3Caption.value && defaultColumnNames.includes(form.personMiscellaneous3Caption.value.toLowerCase())) ||
                                    (form && form.personMiscellaneous4Enable.value == true && form.personMiscellaneous4Caption.value && defaultColumnNames.includes(form.personMiscellaneous4Caption.value.toLowerCase())) ||
                                    (form && form.personMiscellaneous5Enable.value == true && form.personMiscellaneous5Caption.value && defaultColumnNames.includes(form.personMiscellaneous5Caption.value.toLowerCase()))
                                }
                            >
                                Save
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.togleDialogModal();
                                }}
                                disabled={this.state.isUpdating}
                            >
                                Cancel
                            </Button>
                        </React.Fragment>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.config.error,
        isUpdating: state.config.isUpdating,
        config: state.config.config,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfig: bindActionCreators(configActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserUpdatePersonMiscellaneousConfig);
