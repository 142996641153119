import {
  ActionPlan,
  TeamBuilder,
  SkillsWeHave,
  SkillsWeNeed,
  SkillGaps,
  SkillChange,
  SkillsComparison,
  JobMatch,
} from "./pages";
import { Col, Row } from "antd";
import "antd/dist/antd.min.css";
import React from "react";
import { Navigation } from "react-minimal-side-navigation/lib";
import { Route, Switch } from "react-router";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Container } from "reactstrap";

/**
 * Functional component `Main` is a wrapper which registers other wrappers.
 * @returns Functional.Component
 */
function TeamAnlytics(props) {
  const { path, url } = useRouteMatch();
  const { push } = useHistory();
  const routes = [
    {
      path: "/skills/comparison",
      itemId: "/SfiaProfile/TeamAnalytics/skills/comparison",
      title: "Skills Comparison",
      element: <SkillsComparison />,
    },
    {
      path: "/skills/have",
      itemId: "/SfiaProfile/TeamAnalytics/skills/have",
      title: "Skills We Have",
      element: <SkillsWeHave />,
    },
    {
      itemId: "/SfiaProfile/TeamAnalytics/skills/need",
      path: "/skills/need",
      title: "Skills We Need",
      element: <SkillsWeNeed />,
    },
    {
      itemId: "/SfiaProfile/TeamAnalytics/skills/gaps",
      path: "/skills/gaps",
      title: "Skill Gaps",
      element: <SkillGaps />,
    },
    {
      itemId: "/SfiaProfile/TeamAnalytics/team-builder",
      path: "/team-builder",
      title: "Team Builder",
      element: <TeamBuilder />,
    },
    {
      itemId: "/SfiaProfile/TeamAnalytics/action-plan",
      path: "/action-plan",
      title: "Action Plan",
      element: <ActionPlan />,
    },
    {
      itemId: "/SfiaProfile/TeamAnalytics/job-match",
      path: "/job-match",
      title: "Search for Candidates",
      element: <JobMatch />,
    },
    {
      itemId: "/SfiaProfile/TeamAnalytics/skill-change",
      path: "/skill-change",
      title: "Skill Changes",
      element: <SkillChange manageProfileByAdminPopup={props.manageProfileByAdminPopup} managedPeople={props.managedPeople} />,
    },
  ];

  return (
    <Row
      style={{
        backgroundColor: "#FFF",
        padding: "1rem",
        margin: "1rem",
        borderRadius: "10px",
      }}
    >
      <Col span={3}>
        <Container className="p-0" style={{ backgroundColor: "#FFFFFF" }}>
          <Navigation
              items={!(props.manageProfileByAdminPopup && props.manageProfileByAdminPopup == true) ? routes : []}
              activeItemId={window.location.pathname}
              onSelect={(e) => {
                push(e.itemId);
              }}
            />
        </Container>
      </Col>
      <Col span={21}>
        <Switch>
          {routes.map((item, index) => (
            <Route
              exact
              path={`${path}${item.path}`}
              key={`${index}-${item.itemId}`}
            >
              {item.element}
            </Route>
          ))}
        </Switch>
      </Col>
    </Row>
  );
}

/**
 * Constant which will act as selector and mount containers to DOM.
 * @returns DOM
 */
export default TeamAnlytics;
