import * as relationshipActions from "../../../store/admin/actions/adminRelationshipActions";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");

class RelationshipBulkUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDialog: false,
            selectedFiles: [],
            isImporting: false,
            importMessage: null,
            showConfirmDialog: false,
        };

        this.excelRef = React.createRef();
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
        }
    }

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.excelRef.current.value = null;
        this.setState({ showDialog: !showDialog, selectedFiles: [] });
    };

    handleDownloadTemplate = () => {
        this.props.onRelationship.exportRelationshipsDataExcelTemplate().then(() => {
            if (!this.props.loadingExport && !this.props.categoryError) {
                if (this.props.exportData) {
                    FileDownload(this.props.exportData, `UploadRelationshipsTemplate.xlsx`);
                }
            }
        });
    };

    handleSelectFiles = () => {
        this.excelRef.current.click();
    };

    handleSelectExcels = (event) => {
        const selectedFiles = this.state.selectedFiles;

        if (event.target.files && event.target.files.length > 0) {
            this.setState({ selectedFiles: [...selectedFiles, ...event.target.files] });
        }
    };

    handlePerformUpload = () => {
        this.setState({ showConfirmDialog: true, showDialog: false });
    };

    togleDialogConfirmModal = () => {
        const showConfirmDialog = this.state.showConfirmDialog;
        this.setState({ showConfirmDialog: !showConfirmDialog, showDialog: showConfirmDialog });
    };

    handleConfirmPerformUpload = () => {
        this.setState({ isImporting: true });
        this.props.onRelationship.importSkillAttributesDataExcel(this.state.selectedFiles).then(() => {
            if (!this.props.isImporting) {
                this.excelRef.current.value = null;

                this.props.generateAlert("success", "Import relationships success.");
                this.setState({ showConfirmDialog: false, showDialog: false, selectedFiles: [] });
                this.props.handleImportSuccess();
            }

            this.setState({ isImporting: this.props.isImporting });
        });
    };

    render() {
        const { selectedFiles } = this.state;

        return (
            <React.Fragment>
                <input ref={this.excelRef} type="file" style={{ display: "none" }} accept=".xlsx, .xls" multiple={true} onChange={this.handleSelectExcels} />
                <Modal isOpen={this.state.showDialog} toggle={this.togleDialogModal}>
                    <ModalHeader toggle={this.togleDialogModal}>Delete and replace relationships</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label sm={12}>
                                            You can bulk upload relationships from an Excel workbook.
                                            <br />
                                            <b>NOTE</b>: ALL existing relationships will be replaced with the contents of the Workbook. USE WITH CAUTION.
                                        </Label>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col sm={12} style={{ textAlign: "center" }}>
                                            <Button
                                                color="success"
                                                onClick={this.handleDownloadTemplate}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                }}
                                                disabled={this.props.loadingPrint || this.state.isImporting}
                                            >
                                                Download Bulk Template
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={12}>
                                            <b>Processing an upload workbook will delete and replace ALL existing relationships</b>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col sm={12} style={{ textAlign: "center" }}>
                                            <Button
                                                color="success"
                                                onClick={this.handleSelectFiles}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                }}
                                                disabled={this.props.isImporting}
                                            >
                                                Select Files
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                    {selectedFiles.length > 0 && (
                                        <FormGroup row>
                                            <Col sm={12}>
                                                <Label>Files to upload</Label>
                                                <br />
                                                <ListGroup>
                                                    {selectedFiles.map((it, key) => {
                                                        return (
                                                            <React.Fragment key={key}>
                                                                <ListGroupItem key={key}>{it.name}</ListGroupItem>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </ListGroup>
                                            </Col>
                                        </FormGroup>
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.handlePerformUpload();
                            }}
                            disabled={selectedFiles.length == 0 || this.state.isImporting}
                        >
                            Process Upload
                        </Button>
                        <Button color="secondary" onClick={this.togleDialogModal} disabled={this.state.isImporting}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.showConfirmDialog} toggle={this.togleDialogConfirmModal}>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label sm={12}>
                                            <b>
                                                WARNING:
                                                <br />
                                                This upload will delete and replace ALL existing relationships!!
                                            </b>
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.handleConfirmPerformUpload();
                            }}
                            disabled={this.state.isImporting}
                        >
                            OK
                        </Button>
                        <Button color="secondary" onClick={this.togleDialogConfirmModal} disabled={this.state.isImporting}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loadingExport: state.adminRelationship.loadingExport,
        exportData: state.adminRelationship.exportData,

        isImporting: state.adminRelationship.isImporting,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRelationship: bindActionCreators(relationshipActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RelationshipBulkUpload);
