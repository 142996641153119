import { updateState } from "../../utility";
import * as actionTypes from "../actions/adminActionTypes";

const initialState = {
    error: null,
    loading: false,
    isUpdating: false,
    mentorPreferences: [],
};

const getMentorPreferencesStart = (state) => {
    return updateState(state, { error: null, loading: true, mentorPreferences: [] });
};

const getMentorPreferencesSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        loading: false,
        mentorPreferences: action.mentorPreferences,
    });
};

const getMentorPreferencesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const addMentorPreferenceStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const addMentorPreferenceSuccess = (state, action) => {
    const mentorPreferences = state.mentorPreferences;
    mentorPreferences.push(action.addedMentorPreference);

    return updateState(state, {
        error: null,
        isUpdating: false,
        mentorPreferences: [...mentorPreferences],
    });
};

const addMentorPreferenceFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateMentorPreferenceStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateMentorPreferenceSuccess = (state, action) => {
    const updatedMentorPreference = action.updatedMentorPreference;
    const mentorPreferences = state.mentorPreferences;

    const mentorPreference = mentorPreferences.find((it) => it.id == updatedMentorPreference.id);
    if (mentorPreference) {
        mentorPreference.preference = updatedMentorPreference.preference;
        mentorPreference.selected = updatedMentorPreference.selected;
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        mentorPreferences: [...mentorPreferences],
    });
};

const updateMentorPreferenceFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteMentorPreferenceStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteMentorPreferenceSuccess = (state, action) => {
    const deletedMentorPreferenceId = action.deletedMentorPreferenceId;
    const mentorPreferences = state.mentorPreferences;

    const mentorPreference = mentorPreferences.find((it) => it.id == deletedMentorPreferenceId);
    if (mentorPreference) {
        const index = mentorPreferences.indexOf(mentorPreference);
        if (index !== -1) {
            mentorPreferences.splice(index, 1);
        }
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        mentorPreferences: [...mentorPreferences],
    });
};

const deleteMentorPreferenceFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MENTOR_PREFERENCES_START:
            return getMentorPreferencesStart(state);
        case actionTypes.GET_MENTOR_PREFERENCES_SUCCESS:
            return getMentorPreferencesSuccess(state, action);
        case actionTypes.GET_MENTOR_PREFERENCES_FAIL:
            return getMentorPreferencesFail(state, action);

        case actionTypes.ADD_MENTOR_PREFERENCE_START:
            return addMentorPreferenceStart(state);
        case actionTypes.ADD_MENTOR_PREFERENCE_SUCCESS:
            return addMentorPreferenceSuccess(state, action);
        case actionTypes.ADD_MENTOR_PREFERENCE_FAIL:
            return addMentorPreferenceFail(state, action);

        case actionTypes.UPDATE_MENTOR_PREFERENCE_START:
            return updateMentorPreferenceStart(state);
        case actionTypes.UPDATE_MENTOR_PREFERENCE_SUCCESS:
            return updateMentorPreferenceSuccess(state, action);
        case actionTypes.UPDATE_MENTOR_PREFERENCE_FAIL:
            return updateMentorPreferenceFail(state, action);

        case actionTypes.DELETE_MENTOR_PREFERENCE_START:
            return deleteMentorPreferenceStart(state);
        case actionTypes.DELETE_MENTOR_PREFERENCE_SUCCESS:
            return deleteMentorPreferenceSuccess(state, action);
        case actionTypes.DELETE_MENTOR_PREFERENCE_FAIL:
            return deleteMentorPreferenceFail(state, action);        

        default:
            return state;
    }
};

export default reducer;
