import ErrorHandler from "./../errorResponseHandler";
import Instance from "./../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminEvidenceTypeAPI {
    static getEvidenceTypes() {
        return axios
            .get(`/evidencetypes`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createEvidenceType(evidencetype) {
        return axios
            .post(`/evidencetypes`, evidencetype)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateEvidenceType(id, evidencetype) {
        return axios
            .put(`/evidencetypes/${id}`, evidencetype)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteEvidenceType(id) {
        return axios
            .delete(`/evidencetypes/${id}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default AdminEvidenceTypeAPI;
