import CategoryAPI from "../../../../common/api/service/CategoryService";
import JobService from "../../../../common/api/service/JobService";
import PersonService from "../../../../common/api/service/PersonService";
import { Bar } from "../../components/charts";
import { LevelResponsibility } from "../../components/config/mockdata/levels";
import { OrderCategory } from "../../components/config/mockdata/order";
import SkillsWeNeedFilters from "../../components/filters/skillsweneed";
import {
  SkillsWeHaveNeedTable,
  SkillsWeHaveNeedTableNormal,
} from "../../components/table";
import { Typography } from "antd";
import { groupBy } from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import { Spinner } from "reactstrap";
import styled from "styled-components";

const { Title } = Typography;

const DivCenter = ({ Component }) => {
  return (
    <div className="p-grid p-align-center vertical-container">
      <div className="layout-wrapper">
        <div className="p-grid">
          <div
            className="p-col-12"
            style={{ position: "fixed", top: "50%", left: "50%" }}
          >
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
};

// styled components
const Container = styled.div`
  padding: 0px 30px 0px 30px;
  .mt-40 {
    margin-top: 40px;
  }
`;

const ContainerBorder = styled.div`
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #dddddd;
  border-radius: 14px;
`;

const ContainerContent = styled.div`
  padding: 20px 30px;
`;

const ContainerDefault = styled.div`
  padding: 0px 0px 20px 0px;
`;

// Constant Data
const constantData = {
  pageSize: 5,
  jobLorProfileLevel: 8,
  empty: "",
  spaceBar: " ",
  levelName: "level",
  profileStatusName: "profileStatus",
  skillCodeLevelName: "skillCodeLevel",
  desirableName: "Desirable",
  requiredName: "Required",
  jobTypeList: ["Current", "Future"],
  fullName: "fullName",
  categoryName: "category",
  skillName: "skill",
  categoryTitleName: "Category",
  subCategoryName: "subCategory",
  endorsedName: "Endorsed",
  competencyName: "Competency",
  capabilityName: "capability",
  lorCodeName: "lorCode",
  proficiencyName: "Proficiency",
  knowledgeName: "Knowledge",
  genericAttributeName: "GenericAttribute",
  personName: "Person",
  keyResult: ["haveSkill", "needSkill"],
  styles: {
    maxWidth: "auto",
  },
  colors: {
    type1: "#3C65C2",
    type2: "#EEF2FF",
    type3: "#69849c",
    type4: "#a5b8c8",
  },
  characters: {
    m: "M",
    n: "N",
    p: "P",
  },
};

// Helper function to deep clone data using JSON methods
// This works for simple data structures without circular references or functions
const deepClone = (data) => JSON.parse(JSON.stringify(data));

// Returns a new array containing only the unique elements from the provided `dataArray` based on a custom comparison function.
const distinctArray = (dataArray, func) => {
  // `seen` will store a unique "hash" for each item we've already encountered.
  const seen = new Set();

  // The array to accumulate unique items from `dataArray`.
  const uniqueArray = [];

  dataArray.forEach((item) => {
    // Create a unique "hash" for the current item based on the comparator function.
    // This "hash" is simply a string representation of all items considered equal to the current item.
    const hash = uniqueArray.some((itemIn) => func(item, itemIn));

    // If we haven't seen this unique "hash" before, add the item to our unique array.
    if (!hash) {
      seen.add(hash);
      uniqueArray.push(item);
    }
  });

  return uniqueArray;
};

const SkillsWeNeed = () => {
  // Data
  const [data, setData] = useState([]);
  const [genericAttributeData, setGenericAttributeData] = useState([]);
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  // Selection
  const [selectedLorSkill, setSelectedLorSkill] = useState([]);
  const [selectedGeneric, setSelectedGeneric] = useState([]);
  const [selectedRequirement, setSelectedRequirement] = useState([]);
  const [selectedType, setSelectedType] = useState([]);

  const processDetail = (data, categories) =>
    data.map((element) => {
      let findCategory = categories?.find(
        (item) => item.name === element?.category
      );
      if (!findCategory) {
        findCategory = {
          colour: constantData.colors.type3,
          description: constantData.empty,
        };
      }
      let findSubCategory = findCategory?.subCategories?.find(
        (item) => item.name === element?.subCategory
      );
      if (!findSubCategory) {
        findSubCategory = {
          colour: constantData.colors.type4,
          description: constantData.empty,
        };
      }
      const findSkill = findSubCategory?.skills?.find(
        (item) => item.name === element?.skill
      );
      const findLevel = findSkill?.levels?.find(
        (item) => item.level === element?.level
      );

      return {
        categoryDetail: {
          colour: findCategory?.colour,
          description: findCategory?.description,
        },
        subCategoryDetail: {
          skillColour: findSubCategory?.skillColour,
          colour: findSubCategory?.colour,
          description: findSubCategory?.description,
        },
        skillDetail: {
          description: findSkill?.description,
        },
        levelDetail: {
          description: findLevel?.description,
        },
        ...element,
      };
    });

  const processGenericAttribute = (data) => {
    // Generic
    // For the generic category of needSkill, we transform the data
    // by mapping over the jobLor property and appending jobName and jobRef to each item
    let tempMapJob = data
      .map((item) =>
        item.jobLor.map((jobLor) => ({
          ...jobLor,
          jobName: item.jobName,
          jobRef: item.jobRef,
          need: item.need,
        }))
      )
      .flat(2)
      .map((item) => ({
        jobName: item.jobName,
        jobRef: item.jobRef,
        lor: item.lor,
        lorCode: item.lorCode,
        level: item.level,
        need: item.need,
      }));

    return distinctArray(
      tempMapJob,
      (itemA, itemB) => JSON.stringify(itemA) === JSON.stringify(itemB)
    );
  };

  // Processes data related to the skills needed by people.
  const processData = async (personPeople, categories) => {
    // For each person, fetch their job matches concurrently.
    // Flatten the resulting array three levels deep.
    const managedPeopleJobMatches = (
      await Promise.all(
        personPeople.map((item) =>
          JobService.getManagedPeopleJobMatches(item.personB.email)
        )
      )
    ).flat(3);

    // Filter out jobs that are not part of the predefined job type list.
    const filteredJobs = managedPeopleJobMatches.filter((item) =>
      constantData.jobTypeList.includes(item.jobType)
    );

    // Process the job details for the filtered jobs.
    const jobDetails = await processJobDetailData(categories, filteredJobs);

    // Reduce the job details to create a list of processed jobs, mapping each job's stringVal
    // to its respective 'need' value.
    const processedJobs = jobDetails.reduce((a, b) => {
      if (b.stringVal === constantData.characters.m) {
        b.need = constantData.requiredName;
        // Add the processed job to the accumulator.
        a.push(b);
      } else if (b.stringVal === constantData.characters.p) {
        b.need = constantData.desirableName;
        // Add the processed job to the accumulator.
        a.push(b);
      } else {
        b.need = constantData.empty;
        // Add the processed job to the accumulator.
        a.push(b);
      }
      return a;
    }, []);

    const processedData = processDetail(processedJobs, categories);
    const genericAttributeData = processGenericAttribute(processedData);
    // Update the state or storage with the processed jobs.
    setData(processedData);
    setGenericAttributeData(genericAttributeData);
  };

  // Processes the job details for a given list of items.
  // For each item, it fetches the associated job, its skill profiles, and its lor profiles.
  const processJobDetailData = async (categories, items) => {
    const availableSkillCodes = categories
      ? categories.flatMap((category, index) => {
            return category.subCategories.flatMap((subCategory, index) => {
                return subCategory.skills
                    .filter((it) => !it.isHidden)
                    .flatMap((skill, index) => {
                        return skill.skillsCode;
                    });
            });
        })
      : [];

    // For each item, create a set of promises to fetch the relevant job details.
    const jobDetailsPromises = items.map(async (item) => {
      // Use Promise.all to fetch the job, its skill profiles, and lor profiles concurrently for the given jobId.
      const [job, jobSkillProfiles, jobLorProfiles] = await Promise.all([
        JobService.getJob(item.jobId),
        JobService.getJobSkillProfiles(item.jobId),
        JobService.getJobLorProfiles(
          item.jobId
        ),
      ]);

      // Enrich each jobSkillProfile with additional job details (name, reference, lor profiles).
      return jobSkillProfiles.filter(it => availableSkillCodes.includes(it.skillCode)).map((profile) => {
        profile.jobName = job.name;
        profile.jobRef = job.reference;
        profile.jobLor = jobLorProfiles;
        return profile;
      });
    });

    // Resolve all the promises and flatten the resulting arrays to a single array.
    return (await Promise.all(jobDetailsPromises)).flat(2);
  };

  // Processes all relevant data for skills.
  // This function fetches a set of initial data, and then prepares and processes
  // two types of skill-related data: haveSkill and needSkill.
  const processAllData = async () => {
    try {
      // Concurrently fetch multiple sets of data using Promise.all for improved performance.
      const [personPeople, categories] = await Promise.all([
        PersonService.getPersonMyPeoples(),

        CategoryAPI.getCategories(),
      ]);

      // Use the original personPeople data for processing "needSkill" data since no modifications are needed.
      const prepareDataNeedSkill = personPeople;

      await processData(prepareDataNeedSkill, categories);
    } catch (error) {
      // Log the error if fetching fails
      console.error("Failed to fetch action plan data:", error);

      // Optionally, you can set an error state here if you'd like to display an error message to users.
    }
  };

  const generateTableSkillCategory = (data) => {
    // Destructure for easier access.
    const { categoryName } = constantData;

    // Use a single reduce function to categorize and structure the data.
    const groupedData = data.reduce((acc, item) => {
      // Extract keys for categorization.
      const categoryKey = item[categoryName];

      // If the category does not exist, initialize it.
      if (!acc[categoryKey]) {
        acc[categoryKey] = {
          values: [],
        };
      }

      // Push the item into the category values.
      acc[categoryKey].values.push(item);
      return acc;
    }, {});

    // Extract keys from groupedData
    const groupedKeys = Object.keys(groupedData);

    let orderedCats = OrderCategory.map((key) =>
      groupedKeys.find((e) => e.name === key)
    );

    let orderedKeys = [];
    if (orderedCats.filter(it => it == undefined).length > 0){
      orderedKeys = [...groupedKeys];
    }else{
      //Filter and reorder the keys based on OrderCategory
      orderedKeys = OrderCategory.filter((key) =>
        groupedKeys.includes(key)
      );
    }    

    // Construct the ordered grouped data
    const orderedGroupedData = orderedKeys.map((key) => [
      key,
      groupedData[key],
    ]);

    const resultData = {
      name: constantData.categoryTitleName,
      meta: { levelResponsibility: LevelResponsibility },
      description: constantData.empty,
      styles: {
        tableHeaderColor: constantData.colors.type1,
        tableHeaderBackgroundColor: constantData.colors.type2,
      },
      subCategories: orderedGroupedData.map(
        ([categoryKey, { values: categoryValues }]) => {
          const { categoryDetail, subCategoryDetail } = categoryValues[0];
          return {
            name: categoryKey,
            meta: { levelResponsibility: LevelResponsibility },
            description: categoryDetail.description,
            styles: {
              cateColour: categoryDetail.colour,
              skillColour: subCategoryDetail.skillColour,
              colour: subCategoryDetail.colour || "",
            },
            people: categoryValues,
          };
        }
      ),
    };

    return resultData;
  };

  // Generates bar data for given input.
  const generateBar = (data) => {
    // Use reduce to create an object where the keys are the skillCodeLevel
    // and the values are their respective counts.
    const groupedData = data.reduce((acc, item) => {
      const skillCodeLevel = `${item.lorCode} - ${item.level}`;

      // If the skillCodeLevel doesn't exist in the accumulator, initialize it.
      if (!acc[skillCodeLevel]) {
        acc[skillCodeLevel] = 0;
      }

      // Increment the count for the skillCodeLevel.
      acc[skillCodeLevel]++;

      return acc;
    }, {});

    // Convert the grouped data object to an array of {label, value} objects.
    const aggregatedData = Object.entries(groupedData).map(
      ([label, value]) => ({
        label,
        value,
      })
    );

    // Sort the data in descending order based on their values (counts).
    aggregatedData.sort((a, b) => b.value - a.value);

    return {
      maxCount: Math.max(...aggregatedData.map((item) => item.value)) + 1, // Calculate the maximum count
      source: aggregatedData,
    };
  };

  const generateTreeSelect = (data, name) =>
    // Using Object.entries to get both key and value of each entry in the data object
    Object.entries(data)
      .map(([category, itemsArray], categoryIdx) => {
        // Generating a Set to deduplicate the values based on the 'name' property
        // Then spread the Set back into an array for easy mapping
        const uniqueValues = [...new Set(itemsArray.map((item) => item[name]))];
        const rootData = JSON.stringify({
          isRoot: true,
          value: category,
        });
        return {
          title: category,
          value: rootData,
          key: rootData,

          // For each unique value, we map it to generate the 'children' array
          children: uniqueValues
            .map((uniqueValue, valueIdx) => {
              const childData = JSON.stringify({
                isRoot: false,
                value: uniqueValue,
              });
              return {
                // Fetching the 'text' property directly from the original itemsArray for each child
                title: itemsArray[valueIdx].text,
                value: childData,
                key: childData,
              };
            })
            .sort((a, b) => a.title.localeCompare(b.title)),
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));

  // Filter Level of Skill Have
  const optionLorSkillLevel = useMemo(() => {
    // Exit early if there's no data to process.
    if (data.length === 0) return [];

    // Transform
    const combinedSkills = [...data].map((item) => ({
      skillCode: `${item.skillCode} - ${item.level}`,
      level: `Level ${item.level}`,
      text: `${item.skillCode} - ${item.level}`,
    }));

    // Deduplicate the combined array based on the skillCode.
    const uniqueSkills = distinctArray(
      combinedSkills,
      (itemA, itemB) => itemA.skillCode === itemB.skillCode
    );

    // Generate the tree select structure.
    return generateTreeSelect(
      groupBy(uniqueSkills, constantData.levelName),
      "skillCode"
    );
  }, [data]);

  // Filter Level of Generic Attribute Have
  const optionGenericAttribute = useMemo(() => {
    // Exit early if there's no data to process.
    if (genericAttributeData.length === 0) return [];

    // Transform
    const combinedSkills = [...genericAttributeData].map((item) => ({
      lorCode: `${item.lorCode} - ${item.level}`,
      level: `Level ${item.level}`,
      text: `${item.lorCode} - ${item.level}`,
    }));

    // Deduplicate the combined array based on the skillCode.
    const uniqueSkills = distinctArray(
      combinedSkills,
      (itemA, itemB) => itemA.lorCode === itemB.lorCode
    );

    // Generate the tree select structure.
    return generateTreeSelect(
      groupBy(uniqueSkills, constantData.levelName),
      constantData.lorCodeName
    );
  }, [genericAttributeData]);

  // Filter by Requirement Name
  const optionRequirement = useMemo(() => {
    if (data.length === 0) return [];

    // Deduplicate the combined array based on the fullName.
    const uniqueSkills = distinctArray(
      data,
      (itemA, itemB) => itemA.jobName === itemB.jobName
    );

    return [
      ...new Set(
        uniqueSkills.map((item) => ({
          label: item.jobName,
          value: item.jobName,
        }))
      ),
    ].sort((a, b) => a.label.localeCompare(b.label));
  }, [data]);

  // Filter by Requirement Type
  const optionType = useMemo(() => {
    if (data.length === 0) return [];

    // Deduplicate the combined array based on the fullName.
    const uniqueSkills = distinctArray(
      data,
      (itemA, itemB) => itemA.need === itemB.need
    );

    return [
      ...new Set(
        uniqueSkills.map((item) => ({
          label: item.need,
          value: item.need,
        }))
      ),
    ].sort((a, b) => a.label.localeCompare(b.label));
  }, [data]);

  // Data Filter  => Ref By Data
  const dataFilter = useMemo(() => {
    let resultData = [];

    if (data.length) {
      resultData = data.filter((item) => {
        // If selectedRequirement is populated and the condition is not met, filter out
        if (
          selectedRequirement.length > 0 &&
          !selectedRequirement.includes(item.jobName)
        ) {
          return false;
        }

        // If selectedType is populated and the condition is not met, filter out
        if (selectedType.length > 0 && !selectedType.includes(item.need)) {
          return false;
        }

        // If selectedLorSkill is populated and the condition is not met, filter out
        if (selectedLorSkill.length > 0) {
          const isFound = selectedLorSkill
            .map((_) => JSON.parse(_))
            .some((_) => {
              if (_.isRoot) {
                return `Level ${item.level}` === _.value;
              } else {
                return `${item.skillCode} - ${item.level}` === _.value;
              }
            });
          if (!isFound) {
            return false;
          }
        }

        // If all conditions are met or not applicable, include the item
        return true;
      });
    }

    return resultData;
  }, [data, selectedLorSkill, selectedRequirement, selectedType]);

  // Data Filter => Ref By Data
  const dataFilterGenericAttribute = useMemo(() => {
    let resultData = [];

    if (genericAttributeData.length) {
      resultData = genericAttributeData.filter((item) => {
        // If selectedRequirement is populated and the condition is not met, filter out
        if (
          selectedRequirement.length > 0 &&
          !selectedRequirement.includes(item.jobName)
        ) {
          return false;
        }

        // If selectedType is populated and the condition is not met, filter out
        if (selectedType.length > 0 && !selectedType.includes(item.need)) {
          return false;
        }

        // If selectedGeneric is populated and the condition is not met, filter out
        if (selectedGeneric.length > 0) {
          const isFound = selectedGeneric
            .map((_) => JSON.parse(_))
            .some((_) => {
              if (_.isRoot) {
                return `Level ${item.level}` === _.value;
              } else {
                return `${item.lorCode} - ${item.level}` === _.value;
              }
            });
          if (!isFound) {
            return false;
          }
        }

        // If all conditions are met or not applicable, include the item
        return true;
      });
    }

    return resultData;
  }, [
    genericAttributeData,
    selectedGeneric,
    selectedRequirement,
    selectedType,
  ]);

  const dashboardTableSkillCategory = useMemo(
    () => generateTableSkillCategory(dataFilter) ?? {},
    [dataFilter]
  ); // Recompute only if dataFilter changes

  const dashboardTableGeneric = useMemo(
    () =>
      dataFilterGenericAttribute.length > 0
        ? { generic: deepClone(dataFilterGenericAttribute) }
        : {},
    [dataFilterGenericAttribute]
  );

  const dashboardTableProfessional = useMemo(
    () =>
      dataFilter.length > 0 ? { professional: deepClone(dataFilter) } : {},
    [dataFilter]
  );

  // Use useMemo to ensure we only recompute the data when `dataFilter` changes.
  const dashboardBar = useMemo(
    () =>
      dataFilterGenericAttribute.length > 0
        ? generateBar(dataFilterGenericAttribute)
        : {},
    [dataFilterGenericAttribute]
  );

  useEffect(() => {
    // This variable helps us ensure that we don't call `setIsContentLoaded` if the component has unmounted before our asynchronous operation completes.
    let isMounted = true;

    processAllData().then(() => {
      if (isMounted) {
        // Only set the loading state to false if the component is still mounted
        setIsContentLoaded(true);
      }
    });

    // The cleanup function for the effect
    return () => {
      // If the component unmounts, we set isMounted to false to prevent any state updates after unmounting.
      isMounted = false;
    };
  }, []);

  // If content is still loading, display a spinner.
  if (!isContentLoaded) {
    return <DivCenter Component={() => <Spinner />} />;
  }

  return (
    <Container>
      <ContainerDefault>
        <SkillsWeNeedFilters
          objRequirement={{
            options: optionRequirement,
            value: selectedRequirement,
            setValue: setSelectedRequirement,
          }}
          objType={{
            options: optionType,
            value: selectedType,
            setValue: setSelectedType,
          }}
          objLorSkill={{
            options: optionLorSkillLevel,
            value: selectedLorSkill,
            setValue: setSelectedLorSkill,
          }}
          objGeneric={{
            options: optionGenericAttribute,
            value: selectedGeneric,
            setValue: setSelectedGeneric,
          }}
        />
      </ContainerDefault>
      <ContainerBorder>
        <ContainerContent>
          <SkillsWeHaveNeedTable
            isNeedSkill={true}
            data={dashboardTableSkillCategory}
          />
        </ContainerContent>
        <ContainerContent style={{ textAlign: "-webkit-center" }}>
          <Title level={5} className="text-left">
            Generic Attributes
          </Title>
          <div style={{ maxWidth: "900px" }}>
            <Bar data={dashboardBar} />
          </div>
        </ContainerContent>
      </ContainerBorder>
      <ContainerBorder className="mt-40">
        <ContainerContent>
          <SkillsWeHaveNeedTableNormal
            data={dashboardTableProfessional}
            isNeedSkill={true}
            isShowProfessional={true}
            pageSize={constantData.pageSize}
          />
          <SkillsWeHaveNeedTableNormal
            data={dashboardTableGeneric}
            isNeedSkill={true}
            isShowGeneric={true}
            pageSize={constantData.pageSize}
          />
        </ContainerContent>
      </ContainerBorder>
    </Container>
  );
};

export { SkillsWeNeed };
