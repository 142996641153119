import AdminCategoryAPI from "./../../../common/api/adminservice/AdminCategoryService";
import * as actionTypes from "./adminActionTypes";

export const getExtraFrameworkCapabilitiesStart = () => {
    return {
        type: actionTypes.GET_EXTRAFRAMEWORK_CAPABILITIES_START,
    };
};

export const getExtraFrameworkCapabilitiesSuccess = (extraFrameworkCapabilities) => {
    return {
        type: actionTypes.GET_EXTRAFRAMEWORK_CAPABILITIES_SUCCESS,
        extraFrameworkCapabilities,
    };
};

export const getExtraFrameworkCapabilitiesFail = (error) => {
    return {
        type: actionTypes.GET_EXTRAFRAMEWORK_CAPABILITIES_FAIL,
        error: error,
    };
};

export const getExtraFrameworkCapabilities = () => {
    return (dispatch) => {
        dispatch(getExtraFrameworkCapabilitiesStart());

        return AdminCategoryAPI.getExtraFrameworkCapabilities()
            .then((res) => {
                dispatch(getExtraFrameworkCapabilitiesSuccess(res));
            })
            .catch((err) => {
                dispatch(getExtraFrameworkCapabilitiesFail(err));
            });
    };
};

export const getExtraFrameworkLevelsStart = () => {
    return {
        type: actionTypes.GET_EXTRAFRAMEWORK_LEVELS_START,
    };
};

export const getExtraFrameworkLevelsSuccess = (extraFrameworkLevels) => {
    return {
        type: actionTypes.GET_EXTRAFRAMEWORK_LEVELS_SUCCESS,
        extraFrameworkLevels,
    };
};

export const getExtraFrameworkLevelsFail = (error) => {
    return {
        type: actionTypes.GET_EXTRAFRAMEWORK_LEVELS_FAIL,
        error: error,
    };
};

export const getExtraFrameworkLevels = () => {
    return (dispatch) => {
        dispatch(getExtraFrameworkLevelsStart());

        return AdminCategoryAPI.getExtraFrameworkLevels()
            .then((res) => {
                dispatch(getExtraFrameworkLevelsSuccess(res));
            })
            .catch((err) => {
                dispatch(getExtraFrameworkLevelsFail(err));
            });
    };
};

export const createExtraFrameworkLevelStart = () => {
    return {
        type: actionTypes.CREATE_EXTRAFRAMEWORK_LEVEL_START,
    };
};

export const createExtraFrameworkLevelSuccess = (extraFrameworkLevel) => {
    return {
        type: actionTypes.CREATE_EXTRAFRAMEWORK_LEVEL_SUCCESS,
        extraFrameworkLevel,
    };
};

export const createExtraFrameworkLevelFail = (error) => {
    return {
        type: actionTypes.CREATE_EXTRAFRAMEWORK_LEVEL_FAIL,
        error: error,
    };
};

export const createExtraFrameworkLevel = (level) => {
    return (dispatch) => {
        dispatch(createExtraFrameworkLevelStart());

        return AdminCategoryAPI.createExtraFrameworkLevel(level)
            .then((res) => {
                dispatch(createExtraFrameworkLevelSuccess(res));
            })
            .catch((err) => {
                dispatch(createExtraFrameworkLevelFail(err));
            });
    };
};

export const updateExtraFrameworkLevelStart = () => {
    return {
        type: actionTypes.UPDATE_EXTRAFRAMEWORK_LEVEL_START,
    };
};

export const updateExtraFrameworkLevelSuccess = (extraFrameworkLevel) => {
    return {
        type: actionTypes.UPDATE_EXTRAFRAMEWORK_LEVEL_SUCCESS,
        extraFrameworkLevel,
    };
};

export const updateExtraFrameworkLevelFail = (error) => {
    return {
        type: actionTypes.UPDATE_EXTRAFRAMEWORK_LEVEL_FAIL,
        error: error,
    };
};

export const updateExtraFrameworkLevel = (id, level) => {
    return (dispatch) => {
        dispatch(updateExtraFrameworkLevelStart());

        return AdminCategoryAPI.updateExtraFrameworkLevel(id, level)
            .then((res) => {
                dispatch(updateExtraFrameworkLevelSuccess(res));
            })
            .catch((err) => {
                dispatch(updateExtraFrameworkLevelFail(err));
            });
    };
};

export const deleteExtraFrameworkLevelStart = () => {
    return {
        type: actionTypes.DELETE_EXTRAFRAMEWORK_LEVEL_START,
    };
};

export const deleteExtraFrameworkLevelSuccess = (extraFrameworkLevels) => {
    return {
        type: actionTypes.DELETE_EXTRAFRAMEWORK_LEVEL_SUCCESS,
        extraFrameworkLevels,
    };
};

export const deleteExtraFrameworkLevelFail = (error) => {
    return {
        type: actionTypes.DELETE_EXTRAFRAMEWORK_LEVEL_START,
        error: error,
    };
};

export const deleteExtraFrameworkLevel = (id) => {
    return (dispatch) => {
        dispatch(deleteExtraFrameworkLevelStart());

        return AdminCategoryAPI.deleteExtraFrameworkLevel(id)
            .then((res) => {
                dispatch(deleteExtraFrameworkLevelSuccess(res));
            })
            .catch((err) => {
                dispatch(deleteExtraFrameworkLevelFail(err));
            });
    };
};

export const moveExtraFrameworkLevelsStart = () => {
    return {
        type: actionTypes.MOVE_EXTRAFRAMEWORK_LEVELS_START,
    };
};

export const moveExtraFrameworkLevelsSuccess = (extraFrameworkLevels) => {
    return {
        type: actionTypes.MOVE_EXTRAFRAMEWORK_LEVELS_SUCCESS,
        extraFrameworkLevels,
    };
};

export const moveExtraFrameworkLevelsFail = (error) => {
    return {
        type: actionTypes.MOVE_EXTRAFRAMEWORK_LEVELS_FAIL,
        error: error,
    };
};

export const moveExtraFrameworkLevelUp = (id) => {
    return (dispatch) => {
        dispatch(moveExtraFrameworkLevelsStart());

        return AdminCategoryAPI.moveExtraFrameworkLevelUp(id)
            .then((res) => {
                dispatch(moveExtraFrameworkLevelsSuccess(res));
            })
            .catch((err) => {
                dispatch(moveExtraFrameworkLevelsFail(err));
            });
    };
};

export const moveExtraFrameworkLevelDown = (id) => {
    return (dispatch) => {
        dispatch(moveExtraFrameworkLevelsStart());

        return AdminCategoryAPI.moveExtraFrameworkLevelDown(id)
            .then((res) => {
                dispatch(moveExtraFrameworkLevelsSuccess(res));
            })
            .catch((err) => {
                dispatch(moveExtraFrameworkLevelsFail(err));
            });
    };
};

export const getExtraFrameworkCategoriesStart = () => {
    return {
        type: actionTypes.GET_EXTRAFRAMEWORK_CATEGORIES_START,
    };
};

export const getExtraFrameworkCategoriesSuccess = (categories) => {
    return {
        type: actionTypes.GET_EXTRAFRAMEWORK_CATEGORIES_SUCCESS,
        categories,
    };
};

export const getExtraFrameworkCategoriesFail = (error) => {
    return {
        type: actionTypes.GET_EXTRAFRAMEWORK_CATEGORIES_FAIL,
        error: error,
    };
};

export const getExtraFrameworkCategories = () => {
    return (dispatch) => {
        dispatch(getExtraFrameworkCategoriesStart());

        return AdminCategoryAPI.getExtraFrameworkCategories()
            .then((res) => {
                dispatch(getExtraFrameworkCategoriesSuccess(res));
            })
            .catch((err) => {
                dispatch(getExtraFrameworkCategoriesFail(err));
            });
    };
};

export const createExtraFrameworkCategoryStart = () => {
    return {
        type: actionTypes.CREATE_EXTRAFRAMEWORK_CATEGORY_START,
    };
};

export const createExtraFrameworkCategorySuccess = (category) => {
    return {
        type: actionTypes.CREATE_EXTRAFRAMEWORK_CATEGORY_SUCCESS,
        category,
    };
};

export const createExtraFrameworkCategoryFail = (error) => {
    return {
        type: actionTypes.CREATE_EXTRAFRAMEWORK_CATEGORY_FAIL,
        error: error,
    };
};

export const createExtraFrameworkCategory = (category) => {
    return (dispatch) => {
        dispatch(createExtraFrameworkCategoryStart());

        return AdminCategoryAPI.createExtraFrameworkCategory(category)
            .then((res) => {
                dispatch(createExtraFrameworkCategorySuccess(res));
            })
            .catch((err) => {
                dispatch(createExtraFrameworkCategoryFail(err));
            });
    };
};

export const updateExtraFrameworkCategoryStart = () => {
    return {
        type: actionTypes.UPDATE_EXTRAFRAMEWORK_CATEGORY_START,
    };
};

export const updateExtraFrameworkCategorySuccess = (category) => {
    return {
        type: actionTypes.UPDATE_EXTRAFRAMEWORK_CATEGORY_SUCCESS,
        category,
    };
};

export const updateExtraFrameworkCategoryFail = (error) => {
    return {
        type: actionTypes.UPDATE_EXTRAFRAMEWORK_CATEGORY_FAIL,
        error: error,
    };
};

export const updateExtraFrameworkCategory = (categoryId, category) => {
    return (dispatch) => {
        dispatch(updateExtraFrameworkCategoryStart());

        return AdminCategoryAPI.updateExtraFrameworkCategory(categoryId, category)
            .then((res) => {
                dispatch(updateExtraFrameworkCategorySuccess(res));
            })
            .catch((err) => {
                dispatch(updateExtraFrameworkCategoryFail(err));
            });
    };
};

export const deleteExtraFrameworkCategoryStart = () => {
    return {
        type: actionTypes.DELETE_EXTRAFRAMEWORK_CATEGORY_START,
    };
};

export const deleteExtraFrameworkCategorySuccess = (id) => {
    return {
        type: actionTypes.DELETE_EXTRAFRAMEWORK_CATEGORY_SUCCESS,
        id,
    };
};

export const deleteExtraFrameworkCategoryFail = (error) => {
    return {
        type: actionTypes.DELETE_EXTRAFRAMEWORK_CATEGORY_FAIL,
        error: error,
    };
};

export const deleteExtraFrameworkCategory = (categoryId) => {
    return (dispatch) => {
        dispatch(deleteExtraFrameworkCategoryStart());

        return AdminCategoryAPI.deleteExtraFrameworkCategory(categoryId)
            .then((res) => {
                dispatch(deleteExtraFrameworkCategorySuccess(categoryId));
            })
            .catch((err) => {
                dispatch(deleteExtraFrameworkCategoryFail(err));
            });
    };
};

export const createExtraFrameworkSubCategoryStart = () => {
    return {
        type: actionTypes.CREATE_EXTRAFRAMEWORK_SUBCATEGORY_START,
    };
};

export const createExtraFrameworkSubCategorySuccess = (categoryId, subCategory) => {
    return {
        type: actionTypes.CREATE_EXTRAFRAMEWORK_SUBCATEGORY_SUCCESS,
        categoryId,
        subCategory,
    };
};

export const createExtraFrameworkSubCategoryFail = (error) => {
    return {
        type: actionTypes.CREATE_EXTRAFRAMEWORK_SUBCATEGORY_FAIL,
        error: error,
    };
};

export const createExtraFrameworkSubCategory = (categoryId, subCategory) => {
    return (dispatch) => {
        dispatch(createExtraFrameworkSubCategoryStart());

        return AdminCategoryAPI.createExtraFrameworkSubCategory(categoryId, subCategory)
            .then((res) => {
                dispatch(createExtraFrameworkSubCategorySuccess(categoryId, res));
            })
            .catch((err) => {
                dispatch(createExtraFrameworkSubCategoryFail(err));
            });
    };
};

export const updateExtraFrameworkSubCategoryStart = () => {
    return {
        type: actionTypes.UPDATE_EXTRAFRAMEWORK_SUBCATEGORY_START,
    };
};

export const updateExtraFrameworkSubCategorySuccess = (categoryId, subCategory) => {
    return {
        type: actionTypes.UPDATE_EXTRAFRAMEWORK_SUBCATEGORY_SUCCESS,
        categoryId,
        subCategory,
    };
};

export const updateExtraFrameworkSubCategoryFail = (error) => {
    return {
        type: actionTypes.UPDATE_EXTRAFRAMEWORK_SUBCATEGORY_FAIL,
        error: error,
    };
};

export const updateExtraFrameworkSubCategory = (categoryId, subCategoryId, subCategory) => {
    return (dispatch) => {
        dispatch(updateExtraFrameworkSubCategoryStart());

        return AdminCategoryAPI.updateExtraFrameworkSubCategory(categoryId, subCategoryId, subCategory)
            .then((res) => {
                dispatch(updateExtraFrameworkSubCategorySuccess(categoryId, res));
            })
            .catch((err) => {
                dispatch(updateExtraFrameworkSubCategoryFail(err));
            });
    };
};

export const deleteExtraFrameworkSubCategoryStart = () => {
    return {
        type: actionTypes.DELETE_EXTRAFRAMEWORK_SUBCATEGORY_START,
    };
};

export const deleteExtraFrameworkSubCategorySuccess = (categoryId, subCategoryId) => {
    return {
        type: actionTypes.DELETE_EXTRAFRAMEWORK_SUBCATEGORY_SUCCESS,
        categoryId,
        subCategoryId,
    };
};

export const deleteExtraFrameworkSubCategoryFail = (error) => {
    return {
        type: actionTypes.DELETE_EXTRAFRAMEWORK_SUBCATEGORY_FAIL,
        error: error,
    };
};

export const deleteExtraFrameworkSubCategory = (categoryId, subCategoryId) => {
    return (dispatch) => {
        dispatch(deleteExtraFrameworkSubCategoryStart());

        return AdminCategoryAPI.deleteExtraFrameworkSubCategory(categoryId, subCategoryId)
            .then((res) => {
                dispatch(deleteExtraFrameworkSubCategorySuccess(categoryId, subCategoryId));
            })
            .catch((err) => {
                dispatch(deleteExtraFrameworkSubCategoryFail(err));
            });
    };
};

export const createExtraFrameworkSkillStart = () => {
    return {
        type: actionTypes.CREATE_EXTRAFRAMEWORK_SKILL_START,
    };
};

export const createExtraFrameworkSkillSuccess = (categoryId, subCategoryId, skill) => {
    return {
        type: actionTypes.CREATE_EXTRAFRAMEWORK_SKILL_SUCCESS,
        categoryId,
        subCategoryId,
        skill,
    };
};

export const createExtraFrameworkSkillFail = (error) => {
    return {
        type: actionTypes.CREATE_EXTRAFRAMEWORK_SKILL_FAIL,
        error: error,
    };
};

export const createExtraFrameworkSkill = (categoryId, subCategoryId, skill) => {
    return (dispatch) => {
        dispatch(createExtraFrameworkSkillStart());

        return AdminCategoryAPI.createExtraFrameworkSkill(categoryId, subCategoryId, skill)
            .then((res) => {
                dispatch(createExtraFrameworkSkillSuccess(categoryId, subCategoryId, res));
            })
            .catch((err) => {
                dispatch(createExtraFrameworkSkillFail(err));
            });
    };
};

export const updateExtraFrameworkSkillStart = () => {
    return {
        type: actionTypes.UPDATE_EXTRAFRAMEWORK_SKILL_START,
    };
};

export const updateExtraFrameworkSkillSuccess = (categoryId, subCategoryId, skill) => {
    return {
        type: actionTypes.UPDATE_EXTRAFRAMEWORK_SKILL_SUCCESS,
        categoryId,
        subCategoryId,
        skill,
    };
};

export const updateExtraFrameworkSkillFail = (error) => {
    return {
        type: actionTypes.UPDATE_EXTRAFRAMEWORK_SKILL_FAIL,
        error: error,
    };
};

export const updateExtraFrameworkSkill = (categoryId, subCategoryId, skillId, skill) => {
    return (dispatch) => {
        dispatch(updateExtraFrameworkSkillStart());

        return AdminCategoryAPI.updateExtraFrameworkSkill(categoryId, subCategoryId, skillId, skill)
            .then((res) => {
                dispatch(updateExtraFrameworkSkillSuccess(categoryId, subCategoryId, res));
            })
            .catch((err) => {
                dispatch(updateExtraFrameworkSkillFail(err));
            });
    };
};

export const deleteExtraFrameworkSkillStart = () => {
    return {
        type: actionTypes.DELETE_EXTRAFRAMEWORK_SKILL_START,
    };
};

export const deleteExtraFrameworkSkillSuccess = (categoryId, subCategoryId, skillId) => {
    return {
        type: actionTypes.DELETE_EXTRAFRAMEWORK_SKILL_SUCCESS,
        categoryId,
        subCategoryId,
        skillId,
    };
};

export const deleteExtraFrameworkSkillFail = (error) => {
    return {
        type: actionTypes.DELETE_EXTRAFRAMEWORK_SKILL_FAIL,
        error: error,
    };
};

export const deleteExtraFrameworkSkill = (categoryId, subCategoryId, skillId) => {
    return (dispatch) => {
        dispatch(deleteExtraFrameworkSkillStart());

        return AdminCategoryAPI.deleteExtraFrameworkSkill(categoryId, subCategoryId, skillId)
            .then((res) => {
                dispatch(deleteExtraFrameworkSkillSuccess(categoryId, subCategoryId, skillId));
            })
            .catch((err) => {
                dispatch(deleteExtraFrameworkSkillFail(err));
            });
    };
};

export const exportExtraFrameworksDataExcelStart = () => {
    return {
        type: actionTypes.EXPORT_EXTRAFRAMEWORKS_DATA_START,
    };
};

export const exportExtraFrameworksDataExcelSuccess = (exportData) => {
    return {
        type: actionTypes.EXPORT_EXTRAFRAMEWORKS_DATA_SUCCESS,
        exportData,
    };
};

export const exportExtraFrameworksDataExcelFail = (error) => {
    return {
        type: actionTypes.EXPORT_EXTRAFRAMEWORKS_DATA_FAIL,
        error: error,
    };
};

export const exportExtraFrameworksDataExcel = () => {
    return (dispatch) => {
        dispatch(exportExtraFrameworksDataExcelStart());

        return AdminCategoryAPI.exportExtraFrameworksDataExcel()
            .then((res) => {
                dispatch(exportExtraFrameworksDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportExtraFrameworksDataExcelFail(err));
            });
    };
};

export const exportExtraFrameworksDataExcelTemplate = () => {
    return (dispatch) => {
        dispatch(exportExtraFrameworksDataExcelStart());

        return AdminCategoryAPI.exportExtraFrameworksDataExcelTemplate()
            .then((res) => {
                dispatch(exportExtraFrameworksDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportExtraFrameworksDataExcelFail(err));
            });
    };
};

export const importExtraFrameworksDataExcelStart = () => {
    return {
        type: actionTypes.IMPORT_EXTRAFRAMEWORKS_DATA_START,
    };
};

export const importExtraFrameworksDataExcelSuccess = () => {
    return {
        type: actionTypes.IMPORT_EXTRAFRAMEWORKS_DATA_SUCCESS,
    };
};

export const importExtraFrameworksDataExcelFail = (error) => {
    return {
        type: actionTypes.IMPORT_EXTRAFRAMEWORKS_DATA_FAIL,
        error: error,
    };
};

export const importExtraFrameworksDataExcel = (extraFrameworksData) => {
    return (dispatch) => {
        dispatch(importExtraFrameworksDataExcelStart());

        return AdminCategoryAPI.importExtraFrameworksDataExcel(extraFrameworksData)
            .then((res) => {
                dispatch(importExtraFrameworksDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(importExtraFrameworksDataExcelFail(err));
            });
    };
};
