import AdminBadgeRequestAPI from "./../../../common/api/adminservice/AdminBadgeRequestService";
import * as actionTypes from "./adminActionTypes";

export const getBadgeRequestsStart = () => {
    return {
        type: actionTypes.GET_BADGE_REQUESTS_START,
    };
};

export const getBadgeRequestsSuccess = (badgeRequests) => {
    return {
        type: actionTypes.GET_BADGE_REQUESTS_SUCCESS,
        badgeRequests,
    };
};

export const getBadgeRequestsFail = (error) => {
    return {
        type: actionTypes.GET_BADGE_REQUESTS_FAIL,
        error: error,
    };
};

export const getBadgeRequests = () => {
    return (dispatch) => {
        dispatch(getBadgeRequestsStart());

        return AdminBadgeRequestAPI.getBadgeRequests()
            .then((res) => {
                dispatch(getBadgeRequestsSuccess(res));
            })
            .catch((err) => {
                dispatch(getBadgeRequestsFail(err));
            });
    };
};

export const updateBadgeRequestAssessorStart = () => {
    return {
        type: actionTypes.UPDATE_BADGE_REQUEST_ASSESSOR_START,
    };
};

export const updateBadgeRequestAssessorSuccess = (badgeRequest) => {
    return {
        type: actionTypes.UPDATE_BADGE_REQUEST_ASSESSOR_SUCCESS,
        badgeRequest,
    };
};

export const updateBadgeRequestAssessorFail = (error) => {
    return {
        type: actionTypes.UPDATE_BADGE_REQUEST_ASSESSOR_FAIL,
        error: error,
    };
};

export const updateBadgeRequestAssessor = (id, assessorId, assessorName) => {
    return (dispatch) => {
        dispatch(updateBadgeRequestAssessorStart());

        return AdminBadgeRequestAPI.updateBadgeRequestAssesor(id, assessorId, assessorName)
            .then((res) => {
                if (res) {
                    dispatch(updateBadgeRequestAssessorSuccess(res));
                } else {
                    dispatch(updateBadgeRequestAssessorFail(res));
                }
            })
            .catch((err) => {
                dispatch(updateBadgeRequestAssessorFail(err));
            });
    };
};

export const updateBadgeRequestCertificationEntriesStart = () => {
    return {
        type: actionTypes.UPDATE_BADGE_REQUEST_CERTIFICATION_ENTRIES_START,
    };
};

export const updateBadgeRequestCertificationEntriesSuccess = (badgeRequest) => {
    return {
        type: actionTypes.UPDATE_BADGE_REQUEST_CERTIFICATION_ENTRIES_SUCCESS,
        badgeRequest,
    };
};

export const updateBadgeRequestCertificationEntriesFail = (error) => {
    return {
        type: actionTypes.UPDATE_BADGE_REQUEST_CERTIFICATION_ENTRIES_FAIL,
        error: error,
    };
};

export const updateBadgeRequestCertificationEntries = (id, badgeRequest) => {
    return (dispatch) => {
        dispatch(updateBadgeRequestCertificationEntriesStart());

        return AdminBadgeRequestAPI.updateBadgeRequestCertificationEntries(id, badgeRequest)
            .then((res) => {
                if (res) {
                    dispatch(updateBadgeRequestCertificationEntriesSuccess(res));
                } else {
                    dispatch(updateBadgeRequestCertificationEntriesFail(res));
                }
            })
            .catch((err) => {
                dispatch(updateBadgeRequestCertificationEntriesFail(err));
            });
    };
};

export const submitBadgeRequestCertificationStart = () => {
    return {
        type: actionTypes.SUBMIT_BADGE_REQUEST_CERTIFICATION_START,
    };
};

export const submitBadgeRequestCertificationSuccess = (badgeRequest) => {
    return {
        type: actionTypes.SUBMIT_BADGE_REQUEST_CERTIFICATION_SUCCESS,
        badgeRequest,
    };
};

export const submitBadgeRequestCertificationFail = (error) => {
    return {
        type: actionTypes.SUBMIT_BADGE_REQUEST_CERTIFICATION_FAIL,
        error: error,
    };
};

export const submitBadgeRequestCertification = (id) => {
    return (dispatch) => {
        dispatch(submitBadgeRequestCertificationStart());

        return AdminBadgeRequestAPI.submitBadgeRequestCertification(id)
            .then((res) => {
                if (res) {
                    dispatch(submitBadgeRequestCertificationSuccess(res));
                } else {
                    dispatch(submitBadgeRequestCertificationFail(res));
                }
            })
            .catch((err) => {
                dispatch(submitBadgeRequestCertificationFail(err));
            });
    };
};

export const updateBadgeRequest = (badgeRequest) => {
    return {
        type: actionTypes.UPDATE_BADGE_REQUEST,
        badgeRequest,
    };
};

export const submitBadgeRequestCertificationsStart = () => {
    return {
        type: actionTypes.SUBMIT_BADGE_REQUEST_CERTIFICATIONS_START,
    };
};

export const submitBadgeRequestCertificationsSuccess = (badgeRequests) => {
    return {
        type: actionTypes.SUBMIT_BADGE_REQUEST_CERTIFICATIONS_SUCCESS,
        badgeRequests,
    };
};

export const submitBadgeRequestCertificationsFail = (error) => {
    return {
        type: actionTypes.SUBMIT_BADGE_REQUEST_CERTIFICATIONS_FAIL,
        error: error,
    };
};

export const submitBadgeRequestCertifications = (badgeRequestIds) => {
    return (dispatch) => {
        dispatch(submitBadgeRequestCertificationsStart());

        return AdminBadgeRequestAPI.submitBadgeRequestCertifications(badgeRequestIds)
            .then((res) => {
                if (res) {
                    dispatch(submitBadgeRequestCertificationsSuccess(res));
                } else {
                    dispatch(submitBadgeRequestCertificationsFail(res));
                }
            })
            .catch((err) => {
                dispatch(submitBadgeRequestCertificationsFail(err));
            });
    };
};

export const rejectBadgeRequestCertificationStart = () => {
    return {
        type: actionTypes.REJECT_BADGE_REQUEST_CERTIFICATION_START,
    };
};

export const rejectBadgeRequestCertificationSuccess = (badgeRequestId) => {
    return {
        type: actionTypes.REJECT_BADGE_REQUEST_CERTIFICATION_SUCCESS,
        badgeRequestId,
    };
};

export const rejectBadgeRequestCertificationFail = (error) => {
    return {
        type: actionTypes.REJECT_BADGE_REQUEST_CERTIFICATION_FAIL,
        error: error,
    };
};

export const rejectBadgeRequestCertification = (id) => {
    return (dispatch) => {
        dispatch(rejectBadgeRequestCertificationStart());

        return AdminBadgeRequestAPI.rejectBadgeRequestCertification(id)
            .then((res) => {
                if (res) {
                    dispatch(rejectBadgeRequestCertificationSuccess(id));
                } else {
                    dispatch(rejectBadgeRequestCertificationFail(res));
                }
            })
            .catch((err) => {
                dispatch(rejectBadgeRequestCertificationFail(err));
            });
    };
};
