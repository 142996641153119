import * as actionTypes from "../actions/actionTypes";
import { updateState } from "../utility";

const initialState = {
    actionTypes: [],
    actionSkills: [],
    actions: [],
    error: null,
    loading: false,
    actionPlanUpdating: false,
    actionPlanUpdatingError: null,
    addedAction: null,
    interventions: [],

    loadingPrint: false,
    printData: null,
};

const getActionTypesStart = (state) => {
    return updateState(state, { error: null, actionTypes: [], loading: true });
};

const getActionTypesSuccess = (state, action) => {
    return updateState(state, {
        actionTypes: action.actionTypes,
        error: null,
        loading: false,
    });
};

const getActionTypesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getActionSkillsStart = (state) => {
    return updateState(state, { error: null, actionSkills: [], loading: true });
};

const getActionSkillsSuccess = (state, action) => {
    return updateState(state, {
        actionSkills: action.actionSkills,
        error: null,
        loading: false,
    });
};

const getActionSkillsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const printActionReportStart = (state) => {
    return updateState(state, { error: null, loadingPrint: true });
};

const printActionReportSuccess = (state, action) => {
    return updateState(state, {
        printData: action.printData,
        error: null,
        loadingPrint: false,
    });
};

const printActionReportFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingPrint: false,
    });
};

const getInterventionsStart = (state) => {
    return updateState(state, { error: null, interventions: [], loading: true });
};

const getInterventionsSuccess = (state, action) => {
    return updateState(state, {
        interventions: action.interventions,
        error: null,
        loading: false,
    });
};

const getInterventionsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getActionsStart = (state) => {
    return updateState(state, { error: null, actions: [], loading: true });
};

const getActionsSuccess = (state, action) => {
    return updateState(state, {
        actions: action.actions,
        error: null,
        loading: false,
    });
};

const getActionsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const createActionStart = (state) => {
    return updateState(state, {
        actionPlanUpdating: true,
        actionPlanUpdatingError: null,
        addedAction: null,
    });
};

const createActionSuccess = (state, action) => {
    return updateState(state, {
        actionPlanUpdating: false,
        actionPlanUpdatingError: null,
        addedAction: action.addedAction,
    });
};

const createActionFail = (state, action) => {
    return updateState(state, {
        actionPlanUpdating: false,
        actionPlanUpdatingError: action.error,
    });
};

const updateActionStart = (state) => {
    return updateState(state, {
        actionPlanUpdating: true,
        actionPlanUpdatingError: null,
        addedAction: null,
    });
};

const updateActionSuccess = (state, action) => {
    return updateState(state, {
        actionPlanUpdating: false,
        actionPlanUpdatingError: null,
        addedAction: action.addedAction,
    });
};

const updateActionFail = (state, action) => {
    return updateState(state, {
        actionPlanUpdating: false,
        actionPlanUpdatingError: action.error,
    });
};

const deleteActionStart = (state) => {
    return updateState(state, {
        actionPlanUpdating: true,
        actionPlanUpdatingError: null,
    });
};

const deleteActionSuccess = (state) => {
    return updateState(state, {
        actionPlanUpdating: false,
        actionPlanUpdatingError: null,
    });
};

const deleteActionFail = (state, action) => {
    return updateState(state, {
        actionPlanUpdating: false,
        actionPlanUpdatingError: action.error,
    });
};

const sendUpdatedActionPlanNotificationStart = (state) => {
    return updateState(state, {
        actionPlanUpdating: true,
        actionPlanUpdatingError: null,
    });
};

const sendUpdatedActionPlanNotificationSuccess = (state) => {
    return updateState(state, {
        actionPlanUpdating: false,
        actionPlanUpdatingError: null,
    });
};

const sendUpdatedActionPlanNotificationFail = (state, action) => {
    return updateState(state, {
        actionPlanUpdating: false,
        actionPlanUpdatingError: action.error,
    });
};

const submitActionPromotionStart = (state) => {
    return updateState(state, {
        actionPlanUpdating: true,
        actionPlanUpdatingError: null,
    });
};

const submitActionPromotionSuccess = (state, action) => {
    const actionPromotion = action.actionPromotion;
    let actions = state.actions;

    const updatedAction = actions.find((x) => x.id == (actionPromotion ? actionPromotion.actionId : null));

    if (updatedAction) {
        updatedAction.promoted = true;
        updatedAction.promotedOn = actionPromotion.promotedOn;
        updatedAction.promotedById = actionPromotion.promoterId;
        updatedAction.promotedBy = actionPromotion.promoterName;
    }

    return updateState(state, {
        actionPlanUpdating: false,
        actionPlanUpdatingError: null,
        actions: [...actions],
    });
};

const submitActionPromotionFail = (state, action) => {
    return updateState(state, {
        actionPlanUpdating: false,
        actionPlanUpdatingError: action.error,
    });
};

const submitProposeActionStart = (state) => {
    return updateState(state, {
        actionPlanUpdating: true,
        actionPlanUpdatingError: null,
    });
};

const submitProposeActionSuccess = (state, action) => {
    const proposedAction = action.proposedAction;
    let actions = state.actions;

    const updatedAction = actions.find((x) => x.id == (proposedAction ? proposedAction.id : null));

    if (updatedAction) {
        updatedAction.updateProfileEvidenceProposed = true;
        updatedAction.updateProfileEvidenceProposedBy = proposedAction.updateProfileEvidenceProposedBy;
        updatedAction.updateProfileEvidenceProposedById = proposedAction.updateProfileEvidenceProposedById;
        updatedAction.updateProfileEvidenceProposedByEmail = proposedAction.updateProfileEvidenceProposedByEmail;
        updatedAction.updateProfileEvidenceProposedOn = proposedAction.updateProfileEvidenceProposedOn;
        updatedAction.updateProfileEvidenceProposalStatus = proposedAction.updateProfileEvidenceProposalStatus;
        updatedAction.updateProfileEvidenceProposalSkills = proposedAction.updateProfileEvidenceProposalSkills;
    }

    return updateState(state, {
        actionPlanUpdating: false,
        actionPlanUpdatingError: null,
        actions: [...actions],
    });
};

const submitProposeActionFail = (state, action) => {
    return updateState(state, {
        actionPlanUpdating: false,
        actionPlanUpdatingError: action.error,
    });
};

const acceptRejectActionProposalStart = (state) => {
    return updateState(state, {
        actionPlanUpdating: true,
        actionPlanUpdatingError: null,
    });
};

const acceptRejectActionProposalSuccess = (state, action) => {
    return updateState(state, {
        actionPlanUpdating: false,
    });
};

const acceptRejectActionProposalFail = (state, action) => {
    return updateState(state, {
        actionPlanUpdating: false,
        actionPlanUpdatingError: action.error,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ACTION_TYPES_START:
            return getActionTypesStart(state);
        case actionTypes.GET_ACTION_TYPES_SUCCESS:
            return getActionTypesSuccess(state, action);
        case actionTypes.GET_ACTION_TYPES_FAIL:
            return getActionTypesFail(state, action);
        case actionTypes.GET_ACTION_SKILLS_START:
            return getActionSkillsStart(state);
        case actionTypes.GET_ACTION_SKILLS_SUCCESS:
            return getActionSkillsSuccess(state, action);
        case actionTypes.GET_ACTION_SKILLS_FAIL:
            return getActionSkillsFail(state, action);

        case actionTypes.PRINT_MY_ACTION_REPORT_START:
        case actionTypes.PRINT_MANAGED_PEOPLE_ACTION_REPORT_START:
            return printActionReportStart(state);
        case actionTypes.PRINT_MY_ACTION_REPORT_SUCCESS:
        case actionTypes.PRINT_MANAGED_PEOPLE_ACTION_REPORT_SUCCESS:
            return printActionReportSuccess(state, action);
        case actionTypes.PRINT_MY_ACTION_REPORT_FAIL:
        case actionTypes.PRINT_MANAGED_PEOPLE_ACTION_REPORT_FAIL:
            return printActionReportFail(state, action);

        case actionTypes.GET_INTERVENTIONS_START:
            return getInterventionsStart(state);
        case actionTypes.GET_INTERVENTIONS_SUCCESS:
            return getInterventionsSuccess(state, action);
        case actionTypes.GET_INTERVENTIONS_FAIL:
            return getInterventionsFail(state, action);
        case actionTypes.GET_MY_ACTIONS_START:
        case actionTypes.GET_MANAGED_PEOPLE_ACTIONS_START:
            return getActionsStart(state);
        case actionTypes.GET_MY_ACTIONS_SUCCESS:
        case actionTypes.GET_MANAGED_PEOPLE_ACTIONS_SUCCESS:
            return getActionsSuccess(state, action);
        case actionTypes.GET_MY_ACTIONS_FAIL:
        case actionTypes.GET_MANAGED_PEOPLE_ACTIONS_FAIL:
            return getActionsFail(state, action);

        case actionTypes.CREATE_ACTION_START:
        case actionTypes.CREATE_MANAGED_PEOPLE_ACTION_START:
            return createActionStart(state);
        case actionTypes.CREATE_ACTION_SUCCESS:
        case actionTypes.CREATE_MANAGED_PEOPLE_ACTION_SUCCESS:
            return createActionSuccess(state, action);
        case actionTypes.CREATE_ACTION_FAIL:
        case actionTypes.CREATE_MANAGED_PEOPLE_ACTION_FAIL:
            return createActionFail(state, action);

        case actionTypes.UPDATE_ACTION_START:
        case actionTypes.UPDATE_MANAGED_PEOPLE_ACTION_START:
            return updateActionStart(state);
        case actionTypes.UPDATE_ACTION_SUCCESS:
        case actionTypes.UPDATE_MANAGED_PEOPLE_ACTION_SUCCESS:
            return updateActionSuccess(state, action);
        case actionTypes.UPDATE_ACTION_FAIL:
        case actionTypes.UPDATE_MANAGED_PEOPLE_ACTION_FAIL:
            return updateActionFail(state, action);

        case actionTypes.DELETE_ACTION_START:
        case actionTypes.DELETE_MANAGED_PEOPLE_ACTION_START:
            return deleteActionStart(state);
        case actionTypes.DELETE_ACTION_SUCCESS:
        case actionTypes.DELETE_MANAGED_PEOPLE_ACTION_SUCCESS:
            return deleteActionSuccess(state);
        case actionTypes.DELETE_ACTION_FAIL:
        case actionTypes.DELETE_MANAGED_PEOPLE_ACTION_FAIL:
            return deleteActionFail(state, action);

        case actionTypes.SEND_UPDATED_ACTIONPLAN_NOTIFICATION_START:
            return sendUpdatedActionPlanNotificationStart(state);
        case actionTypes.SEND_UPDATED_ACTIONPLAN_NOTIFICATION_SUCCESS:
            return sendUpdatedActionPlanNotificationSuccess(state);
        case actionTypes.SEND_UPDATED_ACTIONPLAN_NOTIFICATION_FAIL:
            return sendUpdatedActionPlanNotificationFail(state, action);

        case actionTypes.SUBMIT_ACTION_PROMOTION_START:
            return submitActionPromotionStart(state);
        case actionTypes.SUBMIT_ACTION_PROMOTION_SUCCESS:
            return submitActionPromotionSuccess(state, action);
        case actionTypes.SUBMIT_ACTION_PROMOTION_FAIL:
            return submitActionPromotionFail(state, action);

        case actionTypes.SUBMIT_PROPOSE_ACTION_START:
            return submitProposeActionStart(state);
        case actionTypes.SUBMIT_PROPOSE_ACTION_SUCCESS:
            return submitProposeActionSuccess(state, action);
        case actionTypes.SUBMIT_PROPOSE_ACTION_FAIL:
            return submitProposeActionFail(state, action);

        case actionTypes.ACCEPT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_START:
        case actionTypes.REJECT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_START:
            return acceptRejectActionProposalStart(state);
        case actionTypes.ACCEPT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_SUCCESS:
        case actionTypes.REJECT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_SUCCESS:
            return acceptRejectActionProposalSuccess(state, action);
        case actionTypes.ACCEPT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_FAIL:
        case actionTypes.REJECT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_FAIL:
            return acceptRejectActionProposalFail(state, action);

        default:
            return state;
    }
};

export default reducer;
