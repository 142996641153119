import * as authActions from "../../store/actions/authAction";
import * as adminEmailTemplateActions from "../../store/admin/actions/adminEmailTemplateActions";
import changeInput from "../../utils/changeInput";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import { dismisAlert, generateAlert } from "./../../utils/alertUtils";
import { contentTranslator } from "./../../utils/translatorUtils";
import { Switch } from "antd";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import { Prompt } from "react-router";
import ReactTextareaAutosize from "react-textarea-autosize";
import { Button, ButtonGroup, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

class ManageEmailTemplates extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            isUpdating: false,
            emailTemplateTypes: [],
            emailTemplate: null,
            showTemplateLoading: false,
            getTemplateLoading: false,
            showEditor: false,
            showUpdateActiveOption: false,
            form: {
                emailTemplateType: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                subject: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                emailBody: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                emailActive: {
                    validation: {
                        required: false,
                    },
                    value: true,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                editWithEditor: {
                    validation: {
                        required: false,
                    },
                    value: true,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                haveBasicContent: {
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                basicContent: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: false,
            isFormDirty: false,
            bodyMode: "ShowEditor",
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        const { form } = this.state;
        let showUpdateActiveOption = this.state.showUpdateActiveOption;

        this.props.onAuth.authCheckState().then(() => {
            this.props.onEmailTemplate.getEmailTemplateTypes().then(() => {
                if (!this.props.emailTemplateLoading) {
                    const emailTemplateTypes = this.props.emailTemplateTypes.filter((it) => it.name != "SFIA Badges Issued Notification" || (this.props.containerConfig && this.props.containerConfig.showSfiaCertificationAdmin && it.name == "SFIA Badges Issued Notification"));
                    if (emailTemplateTypes && emailTemplateTypes.length > 0) {
                        form.emailTemplateType.value = emailTemplateTypes[0].name;
                        form.emailTemplateType.valid = true;
                        form.emailTemplateType.isValidFormat = true;

                        showUpdateActiveOption = emailTemplateTypes[0].showUpdateActiveOption;
                    }

                    this.setState(
                        {
                            loading: this.props.loading || this.props.emailTemplateLoading,
                            emailTemplateTypes,
                            form,
                            showUpdateActiveOption,
                        },
                        () => {
                            if (emailTemplateTypes && emailTemplateTypes.length > 0) {
                                this.getEmailTemplateByType(emailTemplateTypes[0].name);
                            }
                        }
                    );
                }

                this.setState({ loading: this.props.loading });
            });

            this.setState({ loading: this.props.loading });
        });
    }

    getEmailTemplateByType = (emailTemplateType) => {
        const form = this.state.form;
        this.setState({ getTemplateLoading: true, showTemplateLoading: true });

        this.props.onEmailTemplate.getEmailTemplateByType(emailTemplateType).then(() => {
            if (!this.props.emailTemplateLoading) {
                const emailTemplate = this.props.emailTemplate;
                if (emailTemplate) {
                    form.subject.value = emailTemplate.subject;
                    form.subject.valid = emailTemplate.subject ? true : false;
                    form.subject.isValidFormat = emailTemplate.subject ? true : false;

                    form.emailBody.value = emailTemplate.body;
                    form.emailBody.valid = emailTemplate.body ? true : false;
                    form.emailBody.isValidFormat = emailTemplate.body ? true : false;

                    form.emailActive.value = emailTemplate.emailActive;
                    form.emailActive.valid = true;
                    form.emailActive.isValidFormat = true;

                    form.editWithEditor.value = emailTemplate.editWithEditor;
                    form.editWithEditor.valid = true;
                    form.editWithEditor.isValidFormat = true;

                    form.haveBasicContent.value = emailTemplate.haveBasicContent;
                    form.basicContent.value = emailTemplate.basicContent;

                    this.setState({ form, showEditor: true, isFormValid: true, isFormDirty: false, emailTemplate, bodyMode: emailTemplate.editWithEditor ? "ShowEditor" : "ShowHtml" });
                }
            }
            this.setState({ getTemplateLoading: this.props.emailTemplateLoading });
        });
    };

    onEditorStateChange(editorContent) {
        let html = draftToHtml(convertToRaw(editorContent.getCurrentContent()));
        if (html.trim() == "<p></p>") {
            html = "";
        }

        const updateState = changeInput(this.state.form, "emailBody", html);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
            isFormDirty: true,
        });
    }

    handleChangeEmailTemplate = (event) => {
        const { emailTemplateTypes } = this.state;
        let showUpdateActiveOption = this.state.showUpdateActiveOption;

        const emailTemplateType = emailTemplateTypes.find((it) => it.name == event.target.value);

        if (this.state.isFormDirty) {
            if (window.confirm("You have made changes to the current email template, do you wish to dismiss the changes and select an alternative template?")) {
                const { name, value } = event.target;

                const updateState = changeInput(this.state.form, "emailTemplateType", value);

                showUpdateActiveOption = emailTemplateType.showUpdateActiveOption;

                this.setState(
                    {
                        form: updateState.form,
                        isFormValid: updateState.valid,
                        showEditor: false,
                        showUpdateActiveOption,
                    },
                    () => {
                        this.getEmailTemplateByType(value);
                    }
                );
            } else {
                return false;
            }
        } else {
            const { name, value } = event.target;

            const updateState = changeInput(this.state.form, "emailTemplateType", value);
            showUpdateActiveOption = emailTemplateType.showUpdateActiveOption;

            this.setState(
                {
                    form: updateState.form,
                    isFormValid: updateState.valid,
                    showEditor: false,
                    showUpdateActiveOption,
                },
                () => {
                    this.getEmailTemplateByType(value);
                }
            );
        }
    };

    handleCheckedChange = (checked) => {
        const { form } = this.state;
        form.emailActive.value = checked;

        this.setState({ form, isFormDirty: true });
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
            isFormDirty: true,
        });
    };

    handleResetClick = () => {
        this.getEmailTemplateByType(this.state.form.emailTemplateType.value);
    };

    handleSaveClick = () => {
        const oriEmailTemplate = this.state.emailTemplate;
        const form = this.state.form;

        if (oriEmailTemplate) {
            if ((oriEmailTemplate.name == "Self Assessment Invite Email" || oriEmailTemplate.name == "Self Assessment Invite Email Reminder" || oriEmailTemplate.name == "Self Assessment Invite Email Final Reminder") && !form.emailBody.value.includes("$SurveyPortalLink$")) {
                this.setState({ showMissingLinkNotif: true });
            } else {
                const emailTemplate = {
                    id: oriEmailTemplate.id,
                    name: oriEmailTemplate.name,
                    assessmentId: oriEmailTemplate.assessmentId,
                    emailTemplateType: form.emailTemplateType.value,
                    subject: form.subject.value,
                    body: form.emailBody.value,
                    emailActive: form.emailActive.value,
                    editWithEditor: form.editWithEditor.value ? form.editWithEditor.value : false,
                    haveBasicContent: form.haveBasicContent.value ? form.haveBasicContent.value : false,
                    basicContent: form.haveBasicContent.value && form.haveBasicContent.value == true ? form.basicContent.value : "",
                };

                this.setState({ isUpdating: true });

                this.props.onEmailTemplate.createUpdateEmailTemplate(emailTemplate).then(() => {
                    if (!this.props.isUpdating) {
                        if (!this.props.emailTemplateError) {
                            this.generateAlert("success", "Save email template success.");
                            const emailTemplate = this.props.emailTemplate;
                            this.setState({ isFormDirty: false, emailTemplate });
                        } else {
                            this.generateAlert("danger", this.props.emailTemplateError.errData.Message);
                        }
                    }
                    this.setState({ isUpdating: this.props.isUpdating });
                });
            }
        }
    };

    changeBodyMode = (bodyMode) => {
        const form = this.state.form;
        if (form) {
            form.editWithEditor.value = bodyMode == "ShowEditor";
        }

        this.setState({ form, bodyMode });
    };

    togleMissingLinkNotifModal = () => {
        const showMissingLinkNotif = this.state.showMissingLinkNotif;
        this.setState({ showMissingLinkNotif: !showMissingLinkNotif });
    };

    modalInterventionDetail() {
        const { showMissingLinkNotif } = this.state;

        return (
            <Modal isOpen={showMissingLinkNotif} toggle={this.togleMissingLinkNotifModal}>
                <ModalBody>
                    Invites and Reminders MUST include a hyperlink to $SurveyPortalLink$.
                    <br />
                    Please update the email template and include this link.
                    <br />
                    If unsure you can use this HTML:
                    <br />
                    {`<a href="$SurveyPortalLink$" target="_blank">Start Assessment</a>`}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.togleMissingLinkNotifModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    generateEmailBodyEditorState = (emailBody) => {
        const blocksFromHtml = htmlToDraft(emailBody);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const emailBodyEditorState = EditorState.createWithContent(contentState);
        return emailBodyEditorState;
    };

    render() {
        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Manage Email Templates");

        const toolbar = {
            options: ["inline", "list", "textAlign", "fontSize", "link", "history", "fontFamily", "colorPicker"],
            inline: {
                inDropdown: false,
                className: undefined,
                options: ["bold", "italic", "underline", "superscript", "subscript"],
            },
            fontFamily: {
                options: ["Arial", "Georgia", "Impact", "Tahoma", "Times New Roman", "Verdana"],
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
            },
        };

        const basicToolbar = {
            options: [],
            inline: {
                inDropdown: false,
                className: undefined,
                options: [],
            },
            fontFamily: {
                options: ["Arial", "Georgia", "Impact", "Tahoma", "Times New Roman", "Verdana"],
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
            },
        };

        const { emailTemplateTypes, form, showUpdateActiveOption } = this.state;

        const emailBody = form.emailBody ? form.emailBody.value : "";
        const emailBodyEditorState = this.generateEmailBodyEditorState(emailBody);

        const basicContent = form.basicContent && form.basicContent.value ? form.basicContent.value : "";
        const basicContentEmailBodyEditorState = this.generateEmailBodyEditorState(basicContent);

        return (
            <React.Fragment>
                <Prompt when={this.state.isFormDirty} message="Your update is not saved! Are you sure you want to leave? " />
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.modalInterventionDetail()}
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <ContentWrapperLg style={{ backgroundColor: "#FFFFFF", width: "100%", maxWidth: "100%" }} className="contentWrapperLgAdmin-Style">
                            <Form>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup row>
                                            <Label sm={3}>Templates</Label>
                                            <Col sm={9}>
                                                <Input type="select" name="emailTemplateType" id="inputEmailTemplateType" value={form.emailTemplateType.value} disabled={this.state.isUpdating} onChange={this.handleChangeEmailTemplate}>
                                                    {emailTemplateTypes.map((emailTemplateType, key) => (
                                                        <option key={key} value={emailTemplateType.name}>
                                                            {emailTemplateType.name}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        {this.state.showTemplateLoading && (
                                            <React.Fragment>
                                                {this.state.getTemplateLoading ? (
                                                    <div style={{ width: "100%", textAlign: "center" }}>
                                                        <Spinner size="lg" animation="border" role="status"></Spinner>
                                                    </div>
                                                ) : (
                                                    <React.Fragment>
                                                        <FormGroup row>
                                                            <Label sm={3}>Subject</Label>
                                                            <Col sm={9}>
                                                                <Input type="text" name="subject" id="subject" value={form.subject.value} invalid={form.subject.touched && !form.subject.isValidFormat} onChange={this.handleChange} disabled={this.state.isUpdating} />
                                                            </Col>
                                                        </FormGroup>
                                                        {showUpdateActiveOption && (
                                                            <FormGroup row>
                                                                <Col sm={3}></Col>
                                                                <Col sm={9}>
                                                                    <Switch defaultChecked={form.emailActive.value} checked={form.emailActive.value} onChange={this.handleCheckedChange} />
                                                                    <Label>&nbsp;&nbsp;{form.emailActive.value ? "Email Active" : "Email Inactive"}</Label>
                                                                </Col>
                                                            </FormGroup>
                                                        )}
                                                        {form && form.haveBasicContent.value && (
                                                            <React.Fragment>
                                                                <FormGroup row>
                                                                    <Label sm={3}>Protected Body</Label>
                                                                    <Col sm={9}></Col>
                                                                </FormGroup>
                                                                <FormGroup row>
                                                                    <Col sm={12}>
                                                                        <Editor
                                                                            toolbar={basicToolbar}
                                                                            defaultEditorState={basicContentEmailBodyEditorState}
                                                                            stripPastedStyles={true}
                                                                            toolbarStyle={{ backgroundColor: "#d4d5d6", border: "1px solid #a6a6a6", display: "none" }}
                                                                            editorStyle={{ padding: "10px", border: "1px solid #a6a6a6" }}
                                                                            readOnly={true}
                                                                        />
                                                                    </Col>
                                                                </FormGroup>
                                                            </React.Fragment>
                                                        )}
                                                        <FormGroup row>
                                                            <Label sm={3}>Email Body</Label>
                                                            <Col sm={9}>
                                                                <ButtonGroup>
                                                                    <Button color="primary" outline={this.state.bodyMode == "ShowEditor" ? false : true} onClick={() => this.changeBodyMode("ShowEditor")} disabled={this.state.isUpdating}>
                                                                        Show Editor
                                                                    </Button>
                                                                    <Button color="primary" outline={this.state.bodyMode != "ShowEditor" ? false : true} onClick={() => this.changeBodyMode("ShowHtml")} disabled={this.state.isUpdating}>
                                                                        Show Html
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Col sm={12}>
                                                                {this.state.showEditor &&
                                                                    (this.state.bodyMode == "ShowEditor" ? (
                                                                        <Editor
                                                                            toolbar={toolbar}
                                                                            defaultEditorState={emailBodyEditorState}
                                                                            onEditorStateChange={this.onEditorStateChange.bind(this)}
                                                                            stripPastedStyles={true}
                                                                            toolbarStyle={{ backgroundColor: "#d4d5d6", border: "1px solid #a6a6a6" }}
                                                                            editorStyle={{ minHeight: "500px", padding: "10px", border: "1px solid #a6a6a6" }}
                                                                            readOnly={this.state.isUpdating}
                                                                        />
                                                                    ) : (
                                                                        <ReactTextareaAutosize
                                                                            minRows={10}
                                                                            style={{ width: "100%", padding: "10px", border: "1px solid #ced4da" }}
                                                                            aria-label="maximum height"
                                                                            value={this.state.form.emailBody ? this.state.form.emailBody.value : ""}
                                                                            onChange={this.handleChange}
                                                                            disabled={this.state.isUpdating}
                                                                            name="emailBody"
                                                                            id="emailBody"
                                                                        />
                                                                    ))}
                                                            </Col>
                                                        </FormGroup>
                                                        {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) && (
                                                            <React.Fragment>
                                                                <FormGroup row>
                                                                    <Col sm={12}>
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                this.handleSaveClick();
                                                                            }}
                                                                            disabled={!this.state.isFormValid || this.state.isUpdating}
                                                                        >
                                                                            Save
                                                                        </Button>
                                                                        &nbsp;&nbsp;&nbsp;
                                                                        <Button color="primary" onClick={this.handleResetClick} disabled={this.state.isUpdating}>
                                                                            Reset
                                                                        </Button>
                                                                    </Col>
                                                                </FormGroup>
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Col>
                                </Row>
                            </Form>
                        </ContentWrapperLg>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,

        emailTemplateLoading: state.adminEmailTemplate.loading,
        emailTemplateTypes: state.adminEmailTemplate.emailTemplateTypes,
        emailTemplate: state.adminEmailTemplate.emailTemplate,
        emailTemplateError: state.adminEmailTemplate.error,
        isUpdating: state.adminEmailTemplate.isUpdating,

        pageAssignments: state.adminPerson.pageAssignments,
        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onEmailTemplate: bindActionCreators(adminEmailTemplateActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageEmailTemplates);
