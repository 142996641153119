import ErrorHandler from "../errorResponseHandler";
import Instance from "../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminEmailTemplateAPI {
    static getTemplateTypes() {
        return axios
            .get(`/emailtemplates/types`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static getTemplateByType(emailTemplateType) {
        return axios
            .get(`/emailtemplates?emailTemplateType=${emailTemplateType}`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static createUpdateTemplate(emailTemplate) {
        return axios
            .post(`/emailtemplates`, emailTemplate)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }
}

export default AdminEmailTemplateAPI;