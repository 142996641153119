import ContentTranslator from "../translator/ContentTranslator";
import FormAddAction from "./FormAddAction";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";

const buttonActionPrimaryStyle = {
    height: "32px",
    width: "80px",
};

class ActionPlanResponsibilityBody extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showActionForm: false,
            // lor: this.props.lor,
        };

        this.initData = this.initData.bind(this);
        this.handleAddActionClick = this.handleAddActionClick.bind(this);
        this.handleActionfinish = this.handleActionfinish.bind(this);
        this.handleActionPlanAdded = this.handleActionPlanAdded.bind(this);
    }

    componentDidMount() {
        this.initData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showHiddenSkills !== this.props.showHiddenSkills) {
            this.setState({ showActionForm: false });
        }
    }

    initData() {}

    handleAddActionClick = () => {
        this.setState({
            showActionForm: !this.state.showActionForm,
        });
    };

    handleActionfinish = () => {
        this.setState({
            showActionForm: false,
        });
    };

    handleActionPlanAdded(addedActionPlans) {
        this.setState({
            showActionForm: false,
        });

        this.props.handleActionPlanAdded(addedActionPlans);
    }

    render() {
        const { lor, action, skillProfile, jobLorProfiles, currentJob, futureJob, selectedJob } = this.props;

        const currentJobLorProfile = currentJob && jobLorProfiles.find((it) => it.lor === lor.name && it.jobId == currentJob.jobId);
        const futureJobLorProfile = futureJob && jobLorProfiles.find((it) => it.lor === lor.name && it.jobId == futureJob.jobId);

        const selectedJobLorProfile = selectedJob && jobLorProfiles.find((it) => it.lor === lor.name && it.jobId == selectedJob.id);

        let notMandatory = false;
        let selectedDefaultSkill = null;

        if ((skillProfile && selectedJobLorProfile && skillProfile.level >= selectedJobLorProfile.numericVal) || (skillProfile && !selectedJobLorProfile)) {
            notMandatory = true;
        } else {
            notMandatory = false;

            if (selectedJobLorProfile) {
                selectedDefaultSkill = {
                    skillName: selectedJobLorProfile.lor,
                    skillCode: selectedJobLorProfile.lorCode,
                    skillLevel: selectedJobLorProfile.level,
                    skillType: "Lor",
                };
            }
        }

        return (
            <React.Fragment>
                {((selectedJobLorProfile && skillProfile && skillProfile.level < selectedJobLorProfile.numericVal) || this.props.showAll) && (
                    <tr style={{ display: !this.props.lorHidden ? "" : "none" }} key={this.props.index}>
                        <td className={"pt-1 pb-1 td-item align-middle"}>
                            <ContentTranslator page="Lors" name={lor.name} contentText={lor.name} />
                        </td>
                        <td className={"pt-1 pb-1 td-item align-middle text-center"}>{skillProfile ? skillProfile.level : 0}</td>
                        <td className={"pt-1 pb-1 td-item align-middle text-center"}>{currentJobLorProfile && currentJobLorProfile.numericVal}</td>
                        <td className={"pt-1 pb-1 td-item align-middle text-center"}>{futureJobLorProfile && futureJobLorProfile.numericVal}</td>
                        <td className={"pt-1 pb-1 td-item "}>
                            {!this.state.showActionForm ? (
                                <button
                                    className={`btn btn-primary ${!this.props.allowUpdate ? "btnDisabled-Style" : notMandatory || (!notMandatory && action) ? "btnAmber-Style" : "btnPrimary-Style"}`}
                                    style={buttonActionPrimaryStyle}
                                    onClick={this.handleAddActionClick}
                                    disabled={!this.props.allowUpdate}
                                >
                                    <FontAwesomeIcon icon={faPlus} style={{ fontSize: "13px" }} />
                                </button>
                            ) : (
                                <button className={`btn btn-secondary`} style={buttonActionPrimaryStyle} onClick={this.handleAddActionClick} disabled={!this.props.allowUpdate}>
                                    <FontAwesomeIcon icon={faMinus} style={{ fontSize: "13px", position: "relative", top: "-5px" }} />
                                </button>
                            )}
                        </td>
                    </tr>
                )}
                {this.state.showActionForm && (
                    <tr style={{ display: !this.props.lorHidden ? "" : "none" }}>
                        <td className="border-0" colSpan="7">
                            <div style={{ float: "right" }}>
                                <FormAddAction
                                    showHiddenSkills={this.props.showHiddenSkills}
                                    actionPlanId={lor.id}
                                    optionsActionTypes={this.props.optionsActionTypes}
                                    optionsActionSkills={this.props.optionsActionSkills}
                                    jobLorProfiles={this.props.jobLorProfiles}
                                    jobSkillProfiles={this.props.jobSkillProfiles}
                                    lorProfiles={this.props.lorProfiles}
                                    skillProfiles={this.props.skillProfiles}
                                    handleClose={this.handleActionfinish}
                                    handleActionPlanAdded={this.handleActionPlanAdded}
                                    providers={this.props.providers}
                                    selectedDefaultSkill={selectedDefaultSkill}
                                    optionsJob={this.props.optionsJob}
                                    categories={this.props.categories}
                                    generateAlert={this.props.generateAlert}
                                />
                            </div>
                        </td>
                    </tr>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionPlanResponsibilityBody);
