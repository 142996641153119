import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Table, Tag, Tooltip, Typography, Empty, Row, Col, Button } from "antd";
import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";

const { Text } = Typography;

const constantData = {
  titleLevel: 5,
  pageSize: 10,
  colors: {
    type1: "#69849c",
    type2: "#a5b8c8",
  },
};

const DivCenter = ({ Component, title }) => {
  return (
    <div
      className="p-grid p-align-center vertical-container"
      style={{ marginBottom: "40px" }}
    >
      <div className="layout-wrapper">
        <Title level={constantData.titleLevel}>{title}</Title>
        <Component />
      </div>
    </div>
  );
};

const { Title } = Typography;

const WrapperTable = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;

  .ant-table-container table > thead > tr:first-child th {
    text-align: center;
  }

  .ant-table-container table tr .ant-table-cell-row-hover {
    background: rgb(116, 226, 177, 0.2) !important;
  }

  .mr-10 {
    margin-right: 10px;
  }
  .grid {
    display: grid;
  }
  .anticon {
    vertical-align: 1px !important;
  }

  @media (max-width: 1680px) {
    .cell-cm {
      width: 150px;
    }
  }

  @media (max-width: 1580px) {
    .cell-cm {
      width: 100px;
    }
  }

  @media (max-width: 1480px) {
    .cell-cm {
      width: 60px;
    }
  }
  @media (max-width: 1280px) {
    .cell-cm {
      width: auto;
    }
  }
`;

const WrapperSubCell = styled.div`
  width: auto;
  text-wrap: wrap;
`;

const WrapperSlide = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  animation: slide 0.2s;
  @keyframes slide {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;

const ContainerTable = styled.div`
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
`;

const createCenteredTextColumnHeader = (title) => (
  <span style={{ textWrap: "wrap" }}>{title}</span>
);

// Helper function to generate a table column that centers its text content.
// This avoids repetition for multiple columns that require similar formatting.
const createCenteredTextColumn = (
  title,
  dataIndex,
  sorterFunction,
  isResponsive = false
) => ({
  title: createCenteredTextColumnHeader(title), // Column header
  dataIndex, // Data key
  key: dataIndex, // Key for React's list rendering
  sorter: (a, b) => sorterFunction(a[dataIndex], b[dataIndex]), // Sorting function for the column
  render: (text) => (
    <WrapperSubCell className={`text-center ${isResponsive && "cell-cm"}`}>
      {text ?? "-"}
    </WrapperSubCell>
  ), // Centering the text
});

// Utility function to compare text values
const sortByText = (a, b) => (a ?? "").localeCompare(b ?? "");

// Utility function to transform data source for tables
// This maps over the data and transforms it to have keys and all the item properties.
const transformDataSource = (data) =>
  data.map((item, index) => ({
    key: `${index + 1}`,
    ...item,
  }));

// Utility function to generate column definitions based on type and need status
// This will return the appropriate columns based on whether it's a professional or generic skill and if it's a needed skill.
const getColumnDefinitions = (isResponsive, func) => {
  const tempColum = [
    createCenteredTextColumn("Full Name", "fullName", sortByText),
    createCenteredTextColumn("Email", "email", sortByText, isResponsive),
    createCenteredTextColumn(
      "Position as Entered",
      "position",
      sortByText,
      isResponsive
    ),
    {
      title: createCenteredTextColumnHeader("Profile Status"),
      dataIndex: "profileStatus",
      key: "profileStatus",
      sorter: (a, b) => sortByText(a.profileStatus, b.profileStatus),
      render: (text, record) => (
        <div className="text-center">
          <span className="mr-10">{record.profileType}</span>
          <Tag
            icon={
              record.isApproved ? (
                <CheckCircleOutlined />
              ) : (
                <CloseCircleOutlined />
              )
            }
            color={record.isApproved ? "success" : "error"}
          >
            {record.isApproved ? "Approved " : "Not Approved "}
          </Tag>
        </div>
      ),
    },
    createCenteredTextColumn("Capability", "capability", sortByText),
    {
      title: createCenteredTextColumnHeader("Skill Level"),
      dataIndex: "skillLevel",
      key: "skillLevel",
      sorter: (a, b) => sortByText(a.skillCode, b.skillCode),
      render: (text, record) => (
        <div className="text-center text-nowrap">
          <Tooltip title={`${record.skill} Level ${record.level}`}>
            <Tag
              color={record.subCategoryDetail?.colour}
              style={{ color: "black" }}
            >
              {record.skillCode} {record.level}
            </Tag>
          </Tooltip>
        </div>
      ),
    },
    {
      title: createCenteredTextColumnHeader("Country & Location"),
      dataIndex: "countryLocation",
      key: "countryLocation",
      sorter: (a, b) =>
        sortByText(
          `${a.country} - ${a.location}`,
          `${b.country} - ${b.location}`
        ),
      render: (text, record) => (
        <div className="text-center">
          <Tag style={{ color: "black" }}>
            {record.country} - {record.location}
          </Tag>
        </div>
      ),
    },
    {
      title: createCenteredTextColumnHeader("Attributes"),
      dataIndex: "attributes",
      key: "attributes",
      render: (text, record) => (
        <div className="text-center">
          {record.attributes.length > 0 ? (
            <Tooltip title="Click to show more!">
              <Button onClick={() => func(record)} type="dashed" shape="circle">
                <EyeTwoTone />
              </Button>
            </Tooltip>
          ) : (
            <Text style={{ fontSize: "12px" }} type="danger">
              No Attributes
            </Text>
          )}
        </div>
      ),
    },
  ];
  return tempColum;
};

const TeamBuilderTable = ({
  data = [],
  pageSize = constantData.pageSize,
  isResponsive = false,
}) => {
  const [expendedRowKeysRef, setExpendedRowKeysRef] = useState([]);
  // This function determines if a row is expandable based on the 'isSkill' attribute.
  const onRowExpandable = (record) => {
    // Directly return the boolean value of 'levels' attribute of the record.
    // If the attribute isn't present, it defaults to false.
    return record.attributes.length > 0;
  };

  const onExpandedRowRendered = (record) => {
    // Return the formatted table contained within a styled wrapper.
    return (
      <WrapperSlide>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col span={2}>
            <Text className="text-nowrap" underline strong>
              Attributes:
            </Text>
          </Col>
          <Col span={22}>
            {record.attributes.map((item, index) => (
              <Tag key={index}> {item}</Tag>
            ))}
          </Col>
        </Row>
      </WrapperSlide>
    );
  };

  const onExpandedClicked = (record) => {
    if (expendedRowKeysRef.some((item) => item === record.key)) {
      setExpendedRowKeysRef([]);
    } else {
      setExpendedRowKeysRef([record.key]);
    }
  };

  // Memoized calculation for professional table data
  const dataSource = useMemo(() => {
    // If not showing professional or no data, return empty data.
    if (data?.length === 0) return [];

    // Return the transformed data source and the appropriate columns
    return transformDataSource(data);
  }, [data]);

  // Memoized calculation for professional table data
  const columns = useMemo(() => {
    // If not showing professional or no data, return empty data.
    if (data?.length === 0) return [];

    // Return the transformed data source and the appropriate columns
    return getColumnDefinitions(isResponsive, onExpandedClicked);
  }, [data]);

  useEffect(() => setExpendedRowKeysRef([]), [data]);

  return (
    <ContainerTable>
      {dataSource.length > 0 ? (
        // Render the professional table
        <>
          <Title level={constantData.titleLevel}>Professional Skills</Title>
          <WrapperTable>
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={{ pageSize }}
              expandable={{
                showExpandColumn: false,
                expandedRowRender: onExpandedRowRendered,
                rowExpandable: onRowExpandable,
                expandedRowKeys: expendedRowKeysRef,
              }}
              scroll={{ x: true }}
            />
          </WrapperTable>
        </>
      ) : (
        <DivCenter
          title="Professional Skills"
          Component={() => <Empty description={"No Data"} />}
        />
      )}
    </ContainerTable>
  );
};

export default TeamBuilderTable;
