import * as actionTypes from "../actions/actionTypes";

import { updateState } from "../utility";

const initialState = {
    user: {},
    error: null,
    loading: false,
};

const registerUserStart = (state) => {
    return updateState(state, { error: null, user: null, loading: true });
};

const registerUserSuccess = (state, action) => {
    const user = {
        id: action.id,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email
    }
    return updateState(state, {
        user: user,
        error: null,
        loading: false,
    });
};

const registerUserFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REGISTER_USER_START:
            return registerUserStart(state);
        case actionTypes.REGISTER_USER_SUCCESS:
            return registerUserSuccess(state, action);
        case actionTypes.REGISTER_USER_FAIL:
            return registerUserFail(state, action);
        default:
            return state;
    }
};

export default reducer;
