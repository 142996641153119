import * as jobActions from "../../store/actions/jobAction";
import * as personActions from "../../store/actions/personAction";
import * as skillProfileActions from "../../store/actions/skillProfileAction";
import { dismisAlert, generateAlert } from "../../utils/alertUtils";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import CardProfile from "./CardProfile";
import SkillProgress from "./SkillProgress";
import SkillStep from "./SkillStep";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Col, Container, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

class Profile extends React.Component {
    static displayName = Profile.name;

    constructor(props) {
        super(props);

        this.state = {
            selectedOptCurrentJob: null,
            selectedOptFutureJob: null,
            selectedOptEndorseCurrentJob: null,
            selectedOptEndorseFutureJob: null,
            jobMatches: [],
            currentJob: null,
            futureJob: null,
            optionCurrentJobs: [],
            optionFutureJobs: [],
            endorseCurrentJob: null,
            endorseFutureJob: null,
            loading: false,
            error: null,
            alerts: [],
            profileTypes: [],
        };

        this.initData = this.initData.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
    }

    componentDidMount() {
        this.initData();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.profileUpdatingSuccess !== this.props.profileUpdatingSuccess || prevProps.copyProfileSuccess !== this.props.copyProfileSuccess) {
            if (this.props.profileUpdatingSuccess || this.props.copyProfileSuccess) {
                console.log("RePopulate Job Matches");
                Promise.resolve(this.props.managedPeople ? this.props.onJob.getManagedPeopleJobMatches(this.props.managedPeople.personB.email) : this.props.onJob.getMyJobMatches()).then(() => {
                    if (!this.props.loading && !this.props.error) {
                        const jobMatches = this.props.jobMatches;
                        const currentJob = jobMatches.find((it) => it.jobType === "Current");
                        const futureJob = jobMatches.find((it) => it.jobType === "Future");

                        this.setState({ jobMatches, currentJob, futureJob });
                    }
                });

                Promise.resolve(this.props.managedPeople ? this.props.onPerson.getManagedPeopleLatestAccount(this.props.managedPeople.personB.email) : this.props.onPerson.getPersonLatestAccount());
            }
        }

        if (prevProps.endorsedProfileCopyingSuccessNotif !== this.props.endorsedProfileCopyingSuccessNotif) {
            if (this.props.endorsedProfileCopyingSuccessNotif) {
                console.log("RePopulate Job Matches after Copy Endorsed profile");
                this.props.onJob.getMyJobMatches().then(() => {
                    if (!this.props.loading && !this.props.error) {
                        const jobMatches = this.props.jobMatches;
                        const currentJob = jobMatches.find((it) => it.jobType === "Current");
                        const futureJob = jobMatches.find((it) => it.jobType === "Future");

                        this.setState({ jobMatches, currentJob, futureJob });
                    }
                });

                this.props.onSkillProfile.clearCopyEndorsedNotif();
            }
        }
    }

    initData() {
        Promise.resolve(this.props.managedPeople ? this.props.onSkillProfile.getManagedPeopleProfileTypes(this.props.managedPeople.personB.email) : this.props.onSkillProfile.getMyProfileTypes()).then(() => {
            if (!this.props.skillProfileLoading) {
                if (this.props.skillProfileError) {
                    this.setState({ loading: false });
                } else {
                    this.setState({ profileTypes: this.props.profileTypes });

                    this.props.onJob.getJobs().then(() => {
                        let optionCurrentJobs = [{ label: "Current", value: null }];
                        let optionFutureJobs = [{ label: "Future", value: null }];

                        if (this.props.jobs) {
                            optionCurrentJobs.push(...this.props.jobs.map((job) => ({ label: job.name, value: job.id })));

                            optionFutureJobs.push(...this.props.jobs.map((job) => ({ label: job.name, value: job.id })));
                        }

                        this.setState({ optionCurrentJobs, optionFutureJobs });
                        Promise.resolve(this.props.managedPeople ? this.props.onJob.getManagedPeopleJobMatches(this.props.managedPeople.personB.email) : this.props.onJob.getMyJobMatches()).then(() => {
                            if (!this.props.loading && !this.props.error) {
                                const currentJob = this.props.jobMatches.find((it) => it.jobType === "Current");
                                const futureJob = this.props.jobMatches.find((it) => it.jobType === "Future");
                                const jobMatches = this.props.jobMatches.filter((it) => it.jobType != "Proposed");
                                this.setState({ jobMatches, currentJob, futureJob });
                            }

                            this.setState({ loading: this.props.loading });
                        });
                    });
                }
            }
        });
    }

    handleChangeCurrentJob = (selectedOptCurrentJob) => {
        this.setState({ selectedOptCurrentJob });
        console.log(`Opt selected:`, selectedOptCurrentJob);
    };

    handleChangeFutureJob = (selectedOptFutureJob) => {
        this.setState({ selectedOptFutureJob });
        console.log(`Opt selected:`, selectedOptFutureJob);
    };

    handleChangeEndorseCurrentJob = (value) => {
        this.setState({ selectedOptEndorseCurrentJob: value });
        console.log(`Opt selected:`, value);
    };

    handleChangeEndorseFutureJob = (value) => {
        this.setState({ selectedOptEndorseFutureJob: value });
        console.log(`Opt selected:`, value);
    };

    handleJobMatchesUpdated = () => {
        this.setState({ loading: true });
        Promise.resolve(this.props.managedPeople ? this.props.onJob.getManagedPeopleJobMatches(this.props.managedPeople.personB.email) : this.props.onJob.getMyJobMatches()).then(() => {
            if (!this.props.loading && !this.props.error) {
                const jobMatches = this.props.jobMatches;
                const currentJob = jobMatches.find((it) => it.jobType === "Current");
                const futureJob = jobMatches.find((it) => it.jobType === "Future");

                this.setState({ jobMatches, currentJob, futureJob });
            }

            this.setState({ loading: this.props.loading });
        });
    };

    render() {
        return (
            <ContentWrapperLg>
                <Container>
                    <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                    <Row xs="1" sm="1" md="3">
                        <Col md="auto" className="pl-0 pr-4" style={{ borderRight: "2px solid #adb5bd" }}>
                            <CardProfile generateAlert={this.generateAlert} />
                        </Col>
                        {this.state.loading && (
                            <div style={{ textAlign: "center" }}>
                                <Spinner animation="border" role="status" style={{ marginLeft: "20px" }}></Spinner>
                            </div>
                        )}
                        {!this.state.loading && (
                            <React.Fragment>
                                <Col md="5">
                                    <SkillProgress
                                        forceOpenEditJob={this.props.forceOpenEditJob}
                                        isSelfAssessOk={this.state.profileTypes && this.state.profileTypes.includes("Self Assessed") ? true : false}
                                        isEndorseOk={this.state.profileTypes && this.state.profileTypes.includes("Endorsed") ? true : false}
                                        jobMatches={this.state.jobMatches}
                                        currentJob={this.state.currentJob}
                                        futureJob={this.state.futureJob}
                                        endorseCurrentJob={this.state.endorseCurrentJob}
                                        endorseFutureJob={this.state.endorseFutureJob}
                                        optCurrentJob={this.state.optionCurrentJobs}
                                        optFutureJob={this.state.optionFutureJobs}
                                        handleCurrentJob={this.handleChangeCurrentJob}
                                        handleFutureJob={this.handleChangeFutureJob}
                                        handleEndorseCurrentJob={this.handleChangeEndorseCurrentJob}
                                        handleEndorseFutureJob={this.handleChangeEndorseFutureJob}
                                        handleJobMatchesUpdated={this.handleJobMatchesUpdated}
                                    />
                                </Col>
                                <Col md="5">
                                    <SkillStep newActionPlanAdded={this.props.newActionPlanAdded} jobMatches={this.state.jobMatches} showHiddenSkills={this.props.showHiddenSkills} />
                                </Col>
                            </React.Fragment>
                        )}
                    </Row>
                </Container>
            </ContentWrapperLg>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.job.jobMatchesLoading,
        error: state.job.error,
        jobMatches: state.job.jobMatches,
        jobs: state.job.jobs,

        lorProfileLoading: state.skillProfile.loading,
        lorProfileError: state.skillProfile.error,
        profileTypes: state.skillProfile.profileTypes,

        profileUpdatingSuccess: state.skillProfile.profileUpdatingSuccess,
        endorsedProfileCopyingSuccessNotif: state.skillProfile.endorsedProfileCopyingSuccessNotif,
        copyProfileSuccess: state.skillProfile.copyProfileSuccess,

        managedPeople: state.auth.managedPeople,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onJob: bindActionCreators(jobActions, dispatch),
        onSkillProfile: bindActionCreators(skillProfileActions, dispatch),
        onPerson: bindActionCreators(personActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
