import * as authActions from "../../../store/actions/authAction";
import * as jobActions from "../../../store/actions/jobAction";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { filterArray } from "../../../utils/filterUtils";
import { generateRandomString } from "../../../utils/stringUtils";
import { contentTranslator } from "../../../utils/translatorUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import JobFamilyDetail from "./JobFamilyDetail";
import { faPenToSquare, faPlus, faSync, faMinusCircle, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "18px",
    fontFamily: "IBMPlexSans-Bold",
};

class ManageJobFamily extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            jobs: [],
            jobFamilies: [],
            showDialogImportTs: null,
            searchProperties: ["name"],
            jobFamilyEntries: [],
            showLoadingPanel: false,
        };

        this.filterArray = filterArray.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
        this.generateRandomString = generateRandomString.bind(this);
    }

    componentDidMount() {
        this.props.onAuth.authCheckState().then(() => {
            this.initData();
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.jobs !== this.props.jobs) {
            const jobs = this.props.jobs ? this.props.jobs.filter((it) => !(it.jobFamilyId && it.jobFamilyId != "")).sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1)) : null;
            this.setState({
                jobs: [...jobs],
            });
        }

        if (prevProps.jobFamilies !== this.props.jobFamilies) {
            const jobFamilies = this.props.jobFamilies ? this.props.jobFamilies.sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1)) : [];

            const jobFamiliesSelected = this.state.jobFamiliesSelected;

            if (!(jobFamiliesSelected && jobFamiliesSelected.length > 0) && jobFamilies.length > 0) {
                jobFamilies[0].toggleSelected = true;
                const jobFamilySelectedId = jobFamilies[0].id;

                this.setState(
                    {
                        jobFamilies: [...jobFamilies],
                        jobFamiliesSelected: [jobFamilySelectedId],
                    },
                    () => {
                        this.handleSelectRow(jobFamilySelectedId);
                    }
                );
            } else {
                this.setState({
                    jobFamilies: [...jobFamilies],
                });
            }
        }
    }

    handleRefresh = () => {
        this.initData();
    };

    initData = () => {
        const jobFamiliesSelected = this.state.jobFamiliesSelected;
        let jobFamilySelectedId = null;

        if (jobFamiliesSelected && jobFamiliesSelected.length > 0) {
            jobFamilySelectedId = jobFamiliesSelected[0];
        }

        Promise.all([this.props.onJob.getJobs(), this.props.onJob.getJobFamilies()]).then(() => {
            const jobs = this.props.jobs ? this.props.jobs.filter((it) => !(it.jobFamilyId && it.jobFamilyId != "")).sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1)) : null;
            const jobFamilies = this.props.jobFamilies ? this.props.jobFamilies.sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1)) : [];

            if (jobFamilySelectedId) {
                if (!jobFamilies.find((it) => it.id == jobFamilySelectedId)) {
                    if (jobFamilies.length > 0) {
                        jobFamilySelectedId = jobFamilies[0].id;
                        jobFamilies[0].toggleSelected = true;
                    }
                } else {
                    const jobFamily = jobFamilies.find((it) => it.id == jobFamilySelectedId);
                    jobFamily.toggleSelected = true;
                }
            } else {
                if (jobFamilies.length > 0) {
                    jobFamilySelectedId = jobFamilies[0].id;
                    jobFamilies[0].toggleSelected = true;
                }
            }

            this.setState(
                {
                    jobs: [...jobs],
                    jobFamilies: [...jobFamilies],
                    jobFamiliesSelected: jobFamilySelectedId ? [jobFamilySelectedId] : [],
                },
                () => {
                    this.handleSelectRow(jobFamilySelectedId);
                }
            );

            this.setState({ loading: this.props.loading || this.props.jobsLoading });
        });
    };

    handleAddNew = () => {
        this.setState({ showDialogJobFamilyDetailTs: new Date(), selectedJobFamily: null });
    };

    handleOnSelect = (row) => {
        if (this.state.isUpdating) {
            return false;
        }

        const jobFamilies = this.state.jobFamilies;
        const updatedData = jobFamilies.find((it) => it.id == row.id);

        if (!updatedData.toggleSelected) {
            jobFamilies.forEach((it) => {
                it.toggleSelected = false;
            });

            updatedData.toggleSelected = true;

            this.handleSelectRow(row.id);
            this.setState({ jobFamilies: [...jobFamilies], jobFamiliesSelected: [row.id] });
        }
    };

    handleAfterSearch = (searchKeyword) => {
        const { jobFamilies, searchProperties } = this.state;
        const filtered = this.filterArray(jobFamilies, searchKeyword, searchProperties);

        jobFamilies.forEach((it) => {
            it.toggleSelected = false;
        });

        if (filtered.length > 0) {
            const row = filtered[0];
            const jobFamily = jobFamilies.find((it) => it.id == row.id);
            jobFamily.toggleSelected = true;
            this.handleSelectRow(jobFamily.id);
            this.setState({ jobFamilies: [...jobFamilies], jobFamiliesSelected: [jobFamily.id] });
        } else {
            this.handleSelectRow();
            this.setState({ jobFamilies: [...jobFamilies], jobFamiliesSelected: [] });
        }
    };

    handleSelectRow = (jobFamilyId) => {
        const jobFamilies = this.props.jobFamilies;

        const { jobFamiliesSelected, jobs } = this.state;

        var jobFamilySelected = null;

        if (jobFamiliesSelected.length > 0) {
            jobFamilySelected = jobFamiliesSelected[0];
        }

        const selectedJobFamily = jobFamilies.find((it) => it.id == jobFamilyId);

        if (selectedJobFamily) {
            const jobFamilyEntries = selectedJobFamily.entries ? selectedJobFamily.entries : [];

            jobFamilyEntries.forEach((it) => {
                it.toggleSelected = false;
            });

            jobs.forEach((it) => {
                it.toggleSelected = false;
            });

            this.setState({
                jobFamilyEntries,
                jobFamilyEntriesDeleteSelected: [],
                jobs: jobs.sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1)),
                jobsSelected: [],
            });
        }
    };

    handleEditJobFamily = (jobFamily) => {
        this.setState({ showDialogJobFamilyDetailTs: new Date(), selectedJobFamily: jobFamily });
    };

    handleDeleteJobFamily = (jobFamily) => {
        if (window.confirm("Are you sure want to delete the job family?")) {
            this.setState({ isUpdating: true, showLoadingPanel: true, loadingPanelHeader: "Delete requirement family", loadingPanelBody: "Please wait..." });

            this.props.onJob.deleteJobFamily(jobFamily.id).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.jobsError) {
                        let jobFamilySelectedId = null;
                        const jobs = this.props.jobs ? this.props.jobs.filter((it) => !(it.jobFamilyId && it.jobFamilyId != "")).sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1)) : null;

                        jobs.forEach((it) => {
                            it.toggleSelected = false;
                        });

                        const jobFamilies = this.props.jobFamilies ? this.props.jobFamilies.sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1)) : [];
                        if (jobFamilies.length > 0) {
                            jobFamilySelectedId = jobFamilies[0].id;
                            jobFamilies[0].toggleSelected = true;
                        }

                        this.setState(
                            {
                                jobs: [...jobs],
                                jobFamilies: jobFamilies,
                                jobFamiliesSelected: jobFamilySelectedId ? [jobFamilySelectedId] : [],
                                jobFamilyEntries: [],
                            },
                            () => {
                                this.handleSelectRow(jobFamilySelectedId);
                            }
                        );

                        this.generateAlert("success", "Requirement Family Deleted.");
                    } else {
                        this.generateAlert("danger", this.props.jobsError.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating, showLoadingPanel: this.props.isUpdating });
            });
        }
    };

    handleOnSelectDeleteEntry = (row) => {
        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Manage Requirement Families");
        const allowEdit = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? true : false;

        if (this.state.isUpdating || !allowEdit) {
            return false;
        }

        const { jobFamilyEntries } = this.state;
        let jobFamilyEntriesDeleteSelected = this.state.jobFamilyEntriesDeleteSelected;

        if (jobFamilyEntriesDeleteSelected) {
            const jobFamilyEntry = jobFamilyEntries.find((it) => it.id == row.id);
            if (jobFamilyEntriesDeleteSelected.find((it) => it == row.jobId)) {
                jobFamilyEntry.toggleSelected = false;

                const jobFamilyEntryDeleteSelected = jobFamilyEntriesDeleteSelected.find((it) => it == row.jobId);
                var index = jobFamilyEntriesDeleteSelected.indexOf(jobFamilyEntryDeleteSelected);
                if (index !== -1) {
                    jobFamilyEntriesDeleteSelected.splice(index, 1);
                }
            } else {
                jobFamilyEntry.toggleSelected = true;
                jobFamilyEntriesDeleteSelected = [...jobFamilyEntriesDeleteSelected, row.jobId];
            }
        } else {
            const jobFamilyEntry = jobFamilyEntries.find((it) => it.id == row.id);
            jobFamilyEntry.toggleSelected = true;
            jobFamilyEntriesDeleteSelected = [jobFamilyEntry.jobId];
        }

        this.setState({ jobFamilyEntries: [...jobFamilyEntries], jobFamilyEntriesDeleteSelected: [...jobFamilyEntriesDeleteSelected] });
    };

    handleDeleteJobFamilyEntries = () => {
        const jobFamilies = this.props.jobFamilies;
        const { jobFamiliesSelected, jobFamilyEntriesDeleteSelected } = this.state;
        let jobFamiliySelectedId = null;

        if (jobFamiliesSelected && jobFamiliesSelected.length > 0) {
            jobFamiliySelectedId = jobFamiliesSelected[0];
        }

        const selectedJobFamily = jobFamilies.find((it) => it.id == jobFamiliySelectedId);

        if (selectedJobFamily) {
            this.setState({ isUpdating: true, showLoadingPanel: true, loadingPanelHeader: "Delete requirement family entries", loadingPanelBody: "Please wait..." });
            const deletedJobIds = [...jobFamilyEntriesDeleteSelected];

            this.props.onJob.updateJobFamilyDeleteEntries(selectedJobFamily.id, deletedJobIds).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.jobsError) {
                        this.setState(
                            {
                                jobFamilies: this.props.jobFamilies ? this.props.jobFamilies.sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1)) : [],
                                jobFamilyEntriesDeleteSelected: [],
                            },
                            () => {
                                this.handleSelectRow(selectedJobFamily.id);
                            }
                        );

                        this.generateAlert("success", "Requirement Family Entries Deleted.");
                    } else {
                        this.generateAlert("danger", this.props.jobsError.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating, showLoadingPanel: this.props.isUpdating });
            });
        }
    };

    handleOnSelectJob = (row) => {
        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Manage Requirement Families");
        const allowEdit = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? true : false;

        if (this.state.isUpdating || !allowEdit) {
            return false;
        }

        const { jobs } = this.state;
        let jobsSelected = this.state.jobsSelected;

        if (jobsSelected) {
            const job = jobs.find((it) => it.id == row.id);
            if (jobsSelected.find((it) => it == row.id)) {
                job.toggleSelected = false;

                const jobSelected = jobsSelected.find((it) => it == row.id);
                var index = jobsSelected.indexOf(jobSelected);
                if (index !== -1) {
                    jobsSelected.splice(index, 1);
                }
            } else {
                job.toggleSelected = true;
                jobsSelected = [...jobsSelected, row.id];
            }
        } else {
            const job = jobs.find((it) => it.id == row.id);
            job.toggleSelected = true;
            jobsSelected = [row.id];
        }

        this.setState({ jobs: [...jobs.sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1))], jobsSelected: [...jobsSelected] });
    };

    handleAddJobFamilyEntries = () => {
        const jobFamilies = this.props.jobFamilies;
        const { jobFamiliesSelected, jobsSelected } = this.state;
        let jobFamiliySelectedId = null;

        if (jobFamiliesSelected && jobFamiliesSelected.length > 0) {
            jobFamiliySelectedId = jobFamiliesSelected[0];
        }

        const selectedJobFamily = jobFamilies.find((it) => it.id == jobFamiliySelectedId);

        if (selectedJobFamily) {
            this.setState({ isUpdating: true, showLoadingPanel: true, loadingPanelHeader: "Add requirement family entries", loadingPanelBody: "Please wait..." });
            const addedJobIds = [...jobsSelected];

            this.props.onJob.updateJobFamilyAddEntries(selectedJobFamily.id, addedJobIds).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.jobsError) {
                        this.setState(
                            {
                                jobFamilies: this.props.jobFamilies ? this.props.jobFamilies.sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1)) : [],
                                jobsSelected: [],
                            },
                            () => {
                                this.handleSelectRow(selectedJobFamily.id);
                            }
                        );

                        this.generateAlert("success", "Requirement Family Entries Added.");
                    } else {
                        this.generateAlert("danger", this.props.jobsError.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating, showLoadingPanel: this.props.isUpdating });
            });
        }
    };

    renderLoadingPanel() {
        if (this.state.showLoadingPanel) {
            return (
                <Modal isOpen={this.state.showLoadingPanel}>
                    <ModalHeader>{this.state.loadingPanelHeader}</ModalHeader>
                    <ModalBody>
                        <Spinner size="md" color="primary" /> {this.state.loadingPanelBody}
                    </ModalBody>
                </Modal>
            );
        } else {
            return "";
        }
    }

    render() {
        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Manage Requirement Families");
        const allowAdd = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Add" && op.allow == true) ? true : false;
        const allowEdit = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? true : false;
        const allowDelete = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Delete" && op.allow == true) ? true : false;

        const handleOnSelect = this.handleOnSelect;
        const handleEditJobFamily = this.handleEditJobFamily;
        const handleDeleteJobFamily = this.handleDeleteJobFamily;

        const handleOnSelectDeleteEntry = this.handleOnSelectDeleteEntry;
        const handleOnSelectJob = this.handleOnSelectJob;

        const columns = [
            {
                id: "id",
                name: "Action",
                selector: (row) => row.id,
                sortable: false,
                width: "20%",
                style: { cursor: "pointer" },
                format: function (row) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                            {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) && (
                                <React.Fragment>
                                    <FontAwesomeIcon id={`iconJobDetail${row.id}`} icon={faPenToSquare} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => handleEditJobFamily(row)} />
                                    &nbsp;&nbsp;
                                </React.Fragment>
                            )}
                            {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Delete" && op.allow == true) && (
                                <React.Fragment>
                                    <FontAwesomeIcon id={`iconUserDelete${row.id}`} icon={faMinusCircle} size="lg" style={{ cursor: "pointer", color: "#FF0000" }} onClick={() => handleDeleteJobFamily(row)} />
                                </React.Fragment>
                            )}
                        </div>
                    );
                },
                omit: !allowEdit && !allowDelete,
            },
            {
                id: "name",
                name: "Job Family",
                selector: (row) => row.name,
                sortable: false,
                width: allowEdit || allowDelete ? "80%" : "100%",
                style: { cursor: "pointer" },
                format: function (row) {
                    return (
                        <div style={{ padding: "10px 5px", cursor: "pointer" }} onClick={() => handleOnSelect(row)}>
                            <span style={{ fontSize: "14px" }}>{`${row.name}`}</span>
                        </div>
                    );
                },
            },
        ];

        const columnsEntries = [
            {
                id: "jobId",
                name: "JobId",
                selector: (row) => row.jobId,
                sortable: false,
                width: "100%",
                style: { cursor: "pointer" },
                format: function (row) {
                    return (
                        <div style={{ padding: "10px 5px", cursor: "pointer" }} onClick={() => handleOnSelectDeleteEntry(row)}>
                            <span style={{ fontSize: "14px" }}>
                                {row.jobName}
                                {row.jobReference && row.jobReference != "" ? (
                                    <span>
                                        <br />
                                        {row.jobReference}
                                    </span>
                                ) : (
                                    ""
                                )}
                            </span>
                        </div>
                    );
                },
            },
        ];

        const columnsJob = [
            {
                id: "id",
                name: "id",
                selector: (row) => row.id,
                sortable: false,
                width: "100%",
                style: { cursor: "pointer" },
                format: function (row) {
                    return (
                        <div style={{ padding: "10px 5px", cursor: "pointer" }} onClick={() => handleOnSelectJob(row)}>
                            <span style={{ fontSize: "14px" }}>
                                {row.name}
                                {row.reference && row.reference != "" ? (
                                    <span>
                                        <br />
                                        {row.reference}
                                    </span>
                                ) : (
                                    ""
                                )}
                            </span>
                        </div>
                    );
                },
            },
        ];

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.renderLoadingPanel()}
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <JobFamilyDetail selectedJobFamily={this.state.selectedJobFamily} showDialog={this.state.showDialogJobFamilyDetailTs} generateAlert={this.generateAlert}></JobFamilyDetail>
                        <div>
                            <Row>
                                <Col md="12" sm="12">
                                    <Button
                                        color="primary"
                                        onClick={this.handleRefresh}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                        }}
                                        disabled={this.props.jobsLoading || this.state.isUpdating}
                                    >
                                        <FontAwesomeIcon icon={faSync} /> Refresh
                                    </Button>
                                    &nbsp;&nbsp;
                                    {allowAdd && (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={this.handleAddNew}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                }}
                                                disabled={this.props.jobsLoading}
                                            >
                                                <FontAwesomeIcon icon={faPlus} /> Add New
                                            </Button>
                                            &nbsp;&nbsp;
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col md="4">
                                    <p className={"page-title"} style={headerStyle}>
                                        Requirement Families
                                    </p>
                                    <div>
                                        <Row style={{ marginBottom: "10px" }}>
                                            <Col md="12">
                                                <SkillsTxTableSearchBar
                                                    searchKeyword={this.state.searchKeywordLevel1}
                                                    onChange={(searchKeywordLevel1) => {
                                                        this.setState({ searchKeywordLevel1 });
                                                        this.handleAfterSearch(searchKeywordLevel1);
                                                    }}
                                                ></SkillsTxTableSearchBar>
                                            </Col>
                                        </Row>
                                        <div style={{ maxHeight: "500px", minHeight: "500px", overflowY: "scroll" }}>
                                            <SkillsTxTable
                                                columns={columns}
                                                data={this.state.jobFamilies}
                                                pagination={false}
                                                noTableHead={true}
                                                searchKeyword={this.state.searchKeywordLevel1}
                                                searchProperties={this.state.searchProperties}
                                                selectableRows={true}
                                                selectableRowsHighlight={true}
                                                handleRowClick={this.handleOnSelect}
                                                progressPending={this.props.loading}
                                            ></SkillsTxTable>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <p className={"page-title"} style={headerStyle}>
                                        Requirement Entry
                                    </p>
                                    <div>
                                        <Row style={{ marginBottom: "10px" }}>
                                            <Col md="12">
                                                <SkillsTxTableSearchBar
                                                    searchKeyword={this.state.searchKeywordLevel3}
                                                    onChange={(searchKeywordLevel3) => {
                                                        this.setState({ searchKeywordLevel3 });
                                                    }}
                                                ></SkillsTxTableSearchBar>
                                            </Col>
                                        </Row>
                                        <div style={{ maxHeight: "500px", minHeight: "500px", overflowY: "scroll" }}>
                                            <SkillsTxTable
                                                columns={columnsEntries}
                                                data={this.state.jobFamilyEntries}
                                                pagination={false}
                                                noTableHead={true}
                                                searchKeyword={this.state.searchKeywordLevel3}
                                                searchProperties={["jobName", "jobReference"]}
                                                selectableRows={allowEdit}
                                                selectableRowsHighlight={true}
                                                handleRowClick={this.handleOnSelectDeleteEntry}
                                                progressPending={this.props.loading}
                                            ></SkillsTxTable>
                                        </div>
                                    </div>
                                    {allowEdit && (
                                        <Button
                                            color="warning"
                                            onClick={this.handleDeleteJobFamilyEntries}
                                            style={{
                                                width: "100%",
                                                marginBottom: "10px",
                                            }}
                                            disabled={!(this.state.jobFamilyEntriesDeleteSelected && this.state.jobFamilyEntriesDeleteSelected.length > 0) || this.state.isUpdating}
                                        >
                                            <Row>
                                                <Col md="10">Delete Entries</Col>
                                                <Col md="2">
                                                    <FontAwesomeIcon icon={faMinus} />
                                                </Col>
                                            </Row>
                                        </Button>
                                    )}
                                </Col>
                                <Col md="4">
                                    <p className={"page-title"} style={headerStyle}>
                                        Select requirement :
                                    </p>
                                    <div>
                                        <Row style={{ marginBottom: "10px" }}>
                                            <Col md="12">
                                                <SkillsTxTableSearchBar
                                                    searchKeyword={this.state.searchKeywordLevel2}
                                                    onChange={(searchKeywordLevel2) => {
                                                        this.setState({ searchKeywordLevel2 });
                                                    }}
                                                ></SkillsTxTableSearchBar>
                                            </Col>
                                        </Row>
                                        <div style={{ maxHeight: "500px", minHeight: "500px", overflowY: "scroll" }}>
                                            <SkillsTxTable
                                                columns={columnsJob}
                                                data={this.state.jobs}
                                                pagination={false}
                                                noTableHead={true}
                                                searchKeyword={this.state.searchKeywordLevel2}
                                                searchProperties={["name", "reference"]}
                                                selectableRows={allowEdit}
                                                selectableRowsHighlight={true}
                                                handleRowClick={this.handleOnSelectJob}
                                                progressPending={this.props.loading}
                                            ></SkillsTxTable>
                                        </div>
                                    </div>
                                    {allowEdit && (
                                        <Button
                                            color="primary"
                                            onClick={this.handleAddJobFamilyEntries}
                                            style={{
                                                width: "100%",
                                                marginBottom: "10px",
                                            }}
                                            disabled={!(this.state.jobFamiliesSelected && this.state.jobFamiliesSelected.length > 0 && this.state.jobsSelected && this.state.jobsSelected.length > 0) || this.state.isUpdating}
                                        >
                                            <Row>
                                                <Col md="10">Add Entries</Col>
                                                <Col md="2">
                                                    <FontAwesomeIcon icon={faPlus} />
                                                </Col>
                                            </Row>
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        authPersonId: state.auth.personId,

        jobsLoading: state.job.loading,
        jobsError: state.job.error,
        jobs: state.job.jobs,
        jobFamilies: state.job.jobFamilies,

        pageAssignments: state.adminPerson.pageAssignments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageJobFamily));
