import * as authActions from "../../../store/actions/authAction";
import * as categoryActions from "../../../store/actions/categoryAction";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { ContentWrapperLg } from "../../common/ContentWrapperLg";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import SkillAttributeBulkUpload from "./SkillAttributeBulkUpload";
import SkillsHiddenBulkUpload from "./SkillsHiddenBulkUpload";
import { faPenToSquare, faPlus, faFileExcel, faEdit, faMinusCircle, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Button, ButtonGroup, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Table, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "28px",
    fontFamily: "IBMPlexSans-Bold",
};

const rowStyle = {
    fontSize: "12px",
};

const buttonStyle = {
    marginLeft: "10px",
};

const manageAttributeWrapper = {
    width: "100%",
    maxWidth: "100%",
    fontSize: "11px",
};

class ManageSkillAttributes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            categories: [],
            editMode: false,
            editedCategoryId: null,
            editedCategory: null,
            editedSubCategoryId: null,
            editedSubCategory: null,
            editedSkill: null,
            editAttribute: false,
            editedAttribute: null,
            optionHidden: [
                { label: "Yes", value: true },
                { label: "No", value: false },
            ],
            skillAttributeIsUpdating: false,
            showDialogImportTs: null,
            showDialogImportSkillTs: null,
            showLoadingPanel: false,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.editSkillAnchor = React.createRef();
    }

    scroll(ref) {
        ref.current.scrollIntoView({ behavior: "smooth" });
    }

    componentDidMount() {
        this.props.onAuth.authCheckState().then(() => {
            this.props.onCategory.getCategories(true, null).then(() => {
                if (!(this.props.loading || this.props.categoryLoading)) {
                    this.setState({ categories: this.props.categories });
                }

                this.setState({ loading: this.props.loading || this.props.categoryLoading });
            });
        });
    }

    pad2 = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    handleExportAttributes = () => {
        this.props.onCategory.exportSkillAttributesDataExcel().then(() => {
            if (!this.props.loadingExport && !this.props.categoryError) {
                if (this.props.exportData) {
                    var today = new Date();
                    var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                    FileDownload(this.props.exportData, `ExportAttributes_${strToday}.xlsx`);
                }
            }
        });
    };

    handleExportSkillsHidden = () => {
        this.props.onCategory.exportSkillsHiddenDataExcel().then(() => {
            if (!this.props.loadingExport && !this.props.categoryError) {
                if (this.props.exportData) {
                    var today = new Date();
                    var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                    FileDownload(this.props.exportData, `ExportSkillsHiddenData_${strToday}.xlsx`);
                }
            }
        });
    };

    handleImportSkillsHidden = () => {
        this.setState({ showDialogImportSkillTs: new Date() });
    };

    handleImportSkillsHiddenSuccess = () => {
        this.props.onCategory.getCategories(true, null).then(() => {
            if (!(this.props.loading || this.props.categoryLoading)) {
                this.setState({ categories: this.props.categories });
            }

            this.setState({ loading: this.props.loading || this.props.categoryLoading });
        });
    };

    openEditSkillAttribute = (categoryId, subCategoryId, skill) => {
        const { categories } = this.state;

        const category = categories.find((it) => it.id == categoryId);
        const subCategory = category ? category.subCategories.find((it) => it.id == subCategoryId) : null;
        this.setState({
            editMode: true,
            editedCategoryId: categoryId,
            editedCategory: category,
            editedSubCategoryId: subCategoryId,
            editedSubCategory: subCategory,
            editedSkill: {
                id: skill.id,
                name: skill.name,
                skillsCode: skill.skillsCode,
                description: skill.description,
                levels: [...skill.levels],
                attributes: [...skill.attributes],
            },
        });
        setTimeout(() => {
            this.scroll(this.editSkillAnchor);
        }, 200);
    };

    handleBackToList = () => {
        this.setState({ editMode: false });
    };

    handleAddNewAttribute = () => {
        this.setState({ editAttribute: true, editedAttribute: { id: null, attribute: null, link: null, isHidden: false } });
    };

    handleEditAttribute = (attribute) => {
        this.setState({
            editAttribute: true,
            editedAttribute: {
                id: attribute.id,
                attribute: attribute.attribute,
                link: attribute.link,
                isHidden: attribute.isHidden,
            },
        });
    };

    cancelUpdateAttribute = () => {
        this.setState({ editAttribute: false, editedAttribute: null });
    };

    handleChangeAttribute = (event) => {
        const { editedAttribute } = this.state;
        let { name, value } = event.target;

        if (name == "attribute") {
            editedAttribute.attribute = value;
        } else if (name == "link") {
            editedAttribute.link = value;
        } else if (name == "isHidden") {
            editedAttribute.isHidden = value;
        }

        this.setState({ editedAttribute });
    };

    handleSaveAttribute = () => {
        const { editedAttribute, editedCategoryId, editedSubCategoryId, editedSkill } = this.state;
        if (editedAttribute.id) {
            this.setState({ skillAttributeIsUpdating: true });

            this.props.onCategory
                .updateSkillAttribute(editedCategoryId, editedSubCategoryId, editedSkill.id, editedAttribute.id, {
                    attribute: editedAttribute.attribute,
                    link: editedAttribute.link,
                    isHidden: editedAttribute.isHidden,
                })
                .then(() => {
                    if (!this.props.isUpdating) {
                        if (!this.props.categoryError) {
                            this.generateAlert("success", "Update attribute success.");
                            this.setState({ editAttribute: false, editedAttribute: null });
                        } else {
                            this.generateAlert("danger", this.props.categoryError.errData.Message);
                        }
                    }

                    this.setState({ skillAttributeIsUpdating: this.props.isUpdating });
                });
        } else {
            this.setState({ skillAttributeIsUpdating: true });

            this.props.onCategory
                .addSkillAttribute(editedCategoryId, editedSubCategoryId, editedSkill.id, {
                    attribute: editedAttribute.attribute,
                    link: editedAttribute.link,
                    isHidden: editedAttribute.isHidden,
                })
                .then(() => {
                    if (!this.props.isUpdating) {
                        if (!this.props.categoryError) {
                            this.generateAlert("success", "Add attribute success.");
                            this.setState({ editAttribute: false, editedAttribute: null });
                            const categories = this.props.categories;
                            const category = categories.find((it) => it.id == editedCategoryId);
                            if (category) {
                                const subCategory = category.subCategories.find((it) => it.id == editedSubCategoryId);
                                if (subCategory) {
                                    const skill = subCategory.skills.find((it) => it.id == editedSkill.id);
                                    if (skill) {
                                        editedSkill.attributes = [...skill.attributes];
                                    }
                                }
                            }
                            this.setState({ editedSkill });
                        } else {
                            this.generateAlert("danger", this.props.categoryError.errData.Message);
                        }
                    }

                    this.setState({ skillAttributeIsUpdating: this.props.isUpdating });
                });
        }
    };

    handleDeleteAttribute = (attribute) => {
        const { editedCategoryId, editedSubCategoryId, editedSkill } = this.state;
        this.setState({ skillAttributeIsUpdating: true });

        this.props.onCategory.deleteSkillAttribute(editedCategoryId, editedSubCategoryId, editedSkill.id, attribute.id).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.categoryError) {
                    this.generateAlert("success", "Delete attribute success.");
                    const categories = this.props.categories;
                    const category = categories.find((it) => it.id == editedCategoryId);
                    if (category) {
                        const subCategory = category.subCategories.find((it) => it.id == editedSubCategoryId);
                        if (subCategory) {
                            const skill = subCategory.skills.find((it) => it.id == editedSkill.id);
                            if (skill) {
                                editedSkill.attributes = [...skill.attributes];
                            }
                        }
                    }
                    this.setState({ editedSkill });
                } else {
                    this.generateAlert("danger", this.props.categoryError.errData.Message);
                }
            }

            this.setState({ skillAttributeIsUpdating: this.props.isUpdating });
        });
    };

    handleImportAttributes = () => {
        this.setState({ showDialogImportTs: new Date() });
    };

    handleImportSuccess = () => {
        this.props.onCategory.getCategories(true, null).then(() => {
            if (!(this.props.loading || this.props.categoryLoading)) {
                this.setState({ categories: this.props.categories });
            }

            this.setState({ loading: this.props.loading || this.props.categoryLoading });
        });
    };

    handleHideSkill = (categoryId, subCategoryId, skill) => {
        this.setState({ showLoadingPanel: true, loadingPanelHeader: null, loadingPanelBody: "Please wait ..." });
        this.props.onCategory.updateSkillSetIsHidden(categoryId, subCategoryId, skill.id, !skill.isHidden).then(() => {
            this.setState({ showLoadingPanel: this.props.isUpdating });
        });
    };

    renderLoadingPanel() {
        if (this.state.showLoadingPanel) {
            return (
                <Modal isOpen={this.state.showLoadingPanel}>
                    {this.state.loadingPanelHeader && <ModalHeader>{this.state.loadingPanelHeader}</ModalHeader>}
                    <ModalBody>
                        <Spinner size="md" color="primary" /> {this.state.loadingPanelBody}
                    </ModalBody>
                </Modal>
            );
        } else {
            return "";
        }
    }

    render() {
        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Manage Skills and Attributes");

        const { categories, editedCategory, editedSubCategory, editedSkill } = this.state;

        const handleEditAttribute = this.handleEditAttribute;
        const handleDeleteAttribute = this.handleDeleteAttribute;

        const columns = [
            {
                id: "attribute",
                name: "Attribute",
                selector: (row) => row.attribute,
                sortable: true,
                width: "30%",
            },
            {
                id: "link",
                name: "Link",
                selector: (row) => row.link,
                sortable: true,
                width: "40%",
                wrap: true,
                // formatter: function (value, row, index) {
                //     return value;
                // },
            },
            {
                id: "isHidden",
                name: "Is Hidden",
                selector: (row) => row.isHidden,
                sortable: true,
                width: "15%",
                format: function (row, index) {
                    return row.isHidden ? "Yes" : "No";
                },
            },
            {
                id: "id",
                selector: (row) => row.id,
                sortable: false,
                width: "15%",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                            <FontAwesomeIcon id={`iconEditAttribute${row.id}`} icon={faPenToSquare} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => handleEditAttribute(row)} />
                            <UncontrolledTooltip target={`iconEditAttribute${row.id}`} placement="bottom">
                                Edit Attribute
                            </UncontrolledTooltip>
                            &nbsp;&nbsp;&nbsp;
                            {!(row.accountSkillAttributes && row.accountSkillAttributes.length > 0) && (
                                <React.Fragment>
                                    <FontAwesomeIcon id={`iconDeleteAttribute${row.id}`} icon={faMinusCircle} size="lg" style={{ cursor: "pointer", color: "#FF0000" }} onClick={() => handleDeleteAttribute(row)} />
                                    <UncontrolledTooltip target={`iconDeleteAttribute${row.id}`} placement="bottom">
                                        Delete Attribute
                                    </UncontrolledTooltip>
                                    &nbsp;&nbsp;&nbsp;
                                </React.Fragment>
                            )}
                        </div>
                    );
                },
            },
        ];

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.renderLoadingPanel()}
                <SkillsHiddenBulkUpload handleImportSuccess={this.handleImportSkillsHiddenSuccess} showDialog={this.state.showDialogImportSkillTs} generateAlert={this.generateAlert}></SkillsHiddenBulkUpload>
                <SkillAttributeBulkUpload handleImportSuccess={this.handleImportSuccess} showDialog={this.state.showDialogImportTs} generateAlert={this.generateAlert}></SkillAttributeBulkUpload>
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : !this.state.editMode ? (
                    <React.Fragment>
                        <ContentWrapperLg className="contentWrapperLgAdmin-Style" style={manageAttributeWrapper}>
                            <p className="mb-0" style={headerStyle}>
                                Manage Skills and Attributes
                            </p>
                            <br />
                            <Row>
                                <Col md="12">
                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Export" && op.allow == true) && (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={this.handleExportSkillsHidden}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                }}
                                                disabled={this.props.categoriesLoading || this.props.loadingExport}
                                            >
                                                <FontAwesomeIcon icon={faFileExcel} /> Export Skills Hidden
                                            </Button>
                                            &nbsp;&nbsp;
                                        </React.Fragment>
                                    )}
                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Import" && op.allow == true) && (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={this.handleImportSkillsHidden}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                }}
                                                disabled={this.props.categoriesLoading}
                                            >
                                                <FontAwesomeIcon icon={faFileExcel} /> Import Skills Hidden
                                            </Button>
                                            &nbsp;&nbsp;
                                        </React.Fragment>
                                    )}
                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Export" && op.allow == true) && (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={this.handleExportAttributes}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                }}
                                                disabled={this.props.categoriesLoading || this.props.loadingExport}
                                            >
                                                <FontAwesomeIcon icon={faFileExcel} /> Export Attributes
                                            </Button>
                                            &nbsp;&nbsp;
                                        </React.Fragment>
                                    )}
                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Import" && op.allow == true) && (
                                        <Button
                                            color="primary"
                                            onClick={this.handleImportAttributes}
                                            style={{
                                                fontSize: "16px",
                                                height: "40px",
                                            }}
                                            disabled={this.props.categoriesLoading}
                                        >
                                            <FontAwesomeIcon icon={faFileExcel} /> Import Attributes
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>
                            <Table>
                                {categories.map((category, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <thead className="tableHeader">
                                                <tr>
                                                    <th
                                                        id={"group" + category.name.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                        colSpan={2}
                                                        className={"th-start"}
                                                        style={{
                                                            borderBottom: "0",
                                                            borderTop: "0",
                                                            backgroundColor: `${category.colour}`,
                                                        }}
                                                    >
                                                        {category.name}
                                                    </th>
                                                    <UncontrolledTooltip
                                                        target={"group" + category.name.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                        placement="bottom"
                                                        dangerouslySetInnerHTML={{
                                                            __html: category.description,
                                                        }}
                                                    ></UncontrolledTooltip>
                                                    <th
                                                        style={{
                                                            borderBottom: "0",
                                                            borderTop: "0",
                                                            backgroundColor: `${category.colour}`,
                                                            width: "528px",
                                                        }}
                                                    ></th>
                                                    <th
                                                        className={"th-fixwidth " + " th-end"}
                                                        colSpan={2}
                                                        style={{
                                                            borderBottom: "0",
                                                            borderTop: "0",
                                                            backgroundColor: `${category.colour}`,
                                                        }}
                                                    ></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {category.subCategories.map((subCategory, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td
                                                                    id={"skill" + subCategory.name.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                    colSpan={"3"}
                                                                    className={"pt-1 pb-1 td-item "}
                                                                    style={{
                                                                        fontFamily: "IBMPlexSans-Bold",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    {subCategory.name}
                                                                </td>
                                                                <UncontrolledTooltip
                                                                    target={"skill" + subCategory.name.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                    placement="bottom"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: subCategory.description,
                                                                    }}
                                                                ></UncontrolledTooltip>
                                                                <td
                                                                    style={{
                                                                        fontFamily: "IBMPlexSans-Bold",
                                                                        verticalAlign: "middle",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    Skill Is Shown
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            {subCategory.skills.map((skill, elIndex) => {
                                                                return (
                                                                    <tr key={elIndex}>
                                                                        <td id={"category" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} className={"pt-1 pb-1 td-item"} style={{ verticalAlign: "middle" }}>
                                                                            <Row>
                                                                                <React.Fragment>
                                                                                    <Col
                                                                                        xs={"12"}
                                                                                        style={{
                                                                                            paddingRight: "3px",
                                                                                        }}
                                                                                    >
                                                                                        {skill.name}
                                                                                    </Col>
                                                                                </React.Fragment>
                                                                            </Row>
                                                                        </td>
                                                                        <UncontrolledTooltip
                                                                            target={"category" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                            placement="bottom"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: skill.description,
                                                                            }}
                                                                        ></UncontrolledTooltip>
                                                                        <td id={"skill" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} className={"pt-1 pb-1 td-item"} style={{ verticalAlign: "middle" }}>
                                                                            {skill.skillsCode}
                                                                        </td>
                                                                        <UncontrolledTooltip
                                                                            target={"skill" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                            placement="bottom"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: skill.description,
                                                                            }}
                                                                        ></UncontrolledTooltip>
                                                                        <td colSpan={pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? "1" : "3"}>
                                                                            {skill &&
                                                                                skill.attributes &&
                                                                                skill.attributes.length > 0 &&
                                                                                skill.attributes
                                                                                    .sort((a, b) => a.attribute > b.attribute)
                                                                                    .map((it, index) => {
                                                                                        return (
                                                                                            <span key={index}>
                                                                                                {index > 0 && ", "}
                                                                                                {it.attribute}
                                                                                            </span>
                                                                                        );
                                                                                    })}
                                                                        </td>
                                                                        {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) && (
                                                                            <React.Fragment>
                                                                                <td align="center" id={"tdIsHidden" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} className={"pt-1 pb-1 td-value td-fixwidth-120"}>
                                                                                    <ButtonGroup>
                                                                                        <Button
                                                                                            color="success"
                                                                                            outline={skill.isHidden == false ? false : true}
                                                                                            style={{ cursor: skill.isHidden ? "pointer" : "default" }}
                                                                                            onClick={() => skill.isHidden && this.handleHideSkill(category.id, subCategory.id, skill)}
                                                                                        ></Button>
                                                                                        <Button
                                                                                            color="danger"
                                                                                            outline={skill.isHidden == true ? false : true}
                                                                                            style={{ cursor: !skill.isHidden ? "pointer" : "default" }}
                                                                                            onClick={() => !skill.isHidden && this.handleHideSkill(category.id, subCategory.id, skill)}
                                                                                        ></Button>
                                                                                    </ButtonGroup>
                                                                                </td>
                                                                                <td align="center" id={"tdedit" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} className={"pt-1 pb-1 td-value td-fixwidth-50"}>
                                                                                    <button className={`btn btn-light btnEdit-Style`} onClick={() => this.openEditSkillAttribute(category.id, subCategory.id, skill)}>
                                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                                    </button>
                                                                                </td>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </tr>
                                                                );
                                                            })}
                                                        </React.Fragment>
                                                    );
                                                })}
                                                <tr>
                                                    <td colSpan={4}></td>
                                                </tr>
                                            </tbody>
                                        </React.Fragment>
                                    );
                                })}
                            </Table>
                        </ContentWrapperLg>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <ContentWrapperLg className="contentWrapperLgAdmin-Style" style={manageAttributeWrapper}>
                            <p ref={this.editSkillAnchor} className="mb-0" style={headerStyle}>
                                Update Skill Attributes
                            </p>
                            <br />
                            <Row>
                                <Col md="12">
                                    <Button
                                        color="primary"
                                        onClick={this.handleBackToList}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} /> Back
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>
                            <Table bordered>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "30%", fontSize: "12px", backgroundColor: `${editedCategory ? editedCategory.colour : "#FFFFFF"}`, color: `${editedCategory ? "#FFFFFF" : "#000000"}`, fontWeight: "bold" }}>Category</td>
                                        <td style={{ width: "70%", fontSize: "12px", backgroundColor: `${editedCategory ? editedCategory.colour : "#FFFFFF"}`, color: `${editedCategory ? "#FFFFFF" : "#000000"}`, fontWeight: "bold" }}>{editedCategory && editedCategory.name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "30%", fontSize: "12px", backgroundColor: `${editedSubCategory ? editedSubCategory.colour : "#FFFFFF"}` }}>Sub Category</td>
                                        <td style={{ width: "70%", fontSize: "12px", backgroundColor: `${editedSubCategory ? editedSubCategory.colour : "#FFFFFF"}` }}>{editedSubCategory && editedSubCategory.name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "30%", fontSize: "12px" }}>Skill</td>
                                        <td style={{ width: "70%", fontSize: "12px" }}>{editedSkill && editedSkill.name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "30%", fontSize: "12px" }}>Skill Code</td>
                                        <td style={{ width: "70%", fontSize: "12px" }}>{editedSkill && editedSkill.skillsCode}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <br />
                            {editedSkill && (
                                <React.Fragment>
                                    <Row>
                                        <Col md={this.state.editAttribute ? "6" : "12"}>
                                            <div>
                                                <Row style={{ marginBottom: "0.5rem" }}>
                                                    <Col md="12">
                                                        <Button
                                                            color="primary"
                                                            onClick={this.handleAddNewAttribute}
                                                            style={{
                                                                fontSize: "16px",
                                                                height: "40px",
                                                            }}
                                                            disabled={this.state.skillAttributeIsUpdating}
                                                        >
                                                            <FontAwesomeIcon icon={faPlus} /> Add New
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <SkillsTxTable columns={columns} data={editedSkill.attributes.sort((a, b) => a.attribute > b.attribute)} pagination={false}></SkillsTxTable>
                                            </div>
                                        </Col>
                                        {this.state.editAttribute && (
                                            <Col md="6">
                                                <Form>
                                                    <Row>
                                                        <Col md={12}>
                                                            <FormGroup row>
                                                                <Label md={3} sm={12}>
                                                                    Attribute
                                                                </Label>
                                                                <Col md={9} sm={12}>
                                                                    <Input
                                                                        type="text"
                                                                        name="attribute"
                                                                        id="attribute"
                                                                        value={this.state.editedAttribute && this.state.editedAttribute.attribute ? this.state.editedAttribute.attribute : ""}
                                                                        //readOnly={this.state.editedAttribute && this.state.editedAttribute.id}
                                                                        invalid={!(this.state.editedAttribute && this.state.editedAttribute.attribute && this.state.editedAttribute.attribute != "")}
                                                                        onChange={this.handleChangeAttribute}
                                                                        disabled={this.state.skillAttributeIsUpdating}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label md={3} sm={12}>
                                                                    Link
                                                                </Label>
                                                                <Col md={9} sm={12}>
                                                                    <Input
                                                                        type="text"
                                                                        name="link"
                                                                        id="link"
                                                                        value={this.state.editedAttribute && this.state.editedAttribute.link ? this.state.editedAttribute.link : ""}
                                                                        onChange={this.handleChangeAttribute}
                                                                        disabled={this.state.skillAttributeIsUpdating}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label md={3} sm={12}>
                                                                    IsHidden
                                                                </Label>
                                                                <Col md={9} sm={12}>
                                                                    <Input type="select" name="isHidden" id="isHidden" value={this.state.editedAttribute ? this.state.editedAttribute.isHidden : false} onChange={this.handleChangeAttribute} disabled={this.state.skillAttributeIsUpdating}>
                                                                        {this.state.optionHidden.map((it, key) => (
                                                                            <option key={key} value={it.value}>
                                                                                {it.label}
                                                                            </option>
                                                                        ))}
                                                                    </Input>
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label md={3} sm={12}></Label>
                                                                <Col md={9} sm={12}>
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            this.handleSaveAttribute();
                                                                        }}
                                                                        disabled={!(this.state.editedAttribute && this.state.editedAttribute.attribute && this.state.editedAttribute.attribute != "") || this.state.skillAttributeIsUpdating}
                                                                    >
                                                                        Save
                                                                    </Button>
                                                                    &nbsp;&nbsp;
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            this.cancelUpdateAttribute();
                                                                        }}
                                                                        disabled={this.state.skillAttributeIsUpdating}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        )}
                                    </Row>
                                </React.Fragment>
                            )}
                        </ContentWrapperLg>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        authPersonId: state.auth.personId,

        categories: state.category.categories,
        isUpdating: state.category.isUpdating,
        categoryError: state.category.error,
        categoryLoading: state.category.loading,
        exportData: state.category.exportData,
        loadingExport: state.category.loadingExport,
        pageAssignments: state.adminPerson.pageAssignments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onCategory: bindActionCreators(categoryActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSkillAttributes);
