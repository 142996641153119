import * as authActions from "../../../store/actions/authAction";
import * as categoryActions from "../../../store/actions/categoryAction";
import * as contentActions from "../../../store/actions/contentAction";
import * as jobActions from "../../../store/actions/jobAction";
import * as lorActions from "../../../store/actions/lorAction";
import * as adminCategoryActions from "../../../store/admin/actions/adminCategoryActions";
import { AlertDialog } from "../../AlertDialog";
import { ContentWrapperLg } from "../../common/ContentWrapperLg";
import { dismisAlert, generateAlert } from "./../../../utils/alertUtils";
import { contentTranslator } from "./../../../utils/translatorUtils";
import ManageJobDetail from "./ManageJobDetail";
import ManageJobDetailMatches from "./ManageJobDetailMatches";
import ManageJobLorProfile from "./ManageJobLorProfile";
import ManageJobNote from "./ManageJobNote";
import ManageJobSkillProfile from "./ManageJobSkillProfile";
import { HubConnectionBuilder } from "@aspnet/signalr";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { Alert, Col, Container, Row, Spinner, Table } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
const messageHubHostname = process.env.NODE_ENV === "development" ? process.env.REACT_APP_MESSAGE_HUB_ENDPOINT : "/messagehub";

const largeContainer = {
    width: "100%",
    maxWidth: "100%",
    fontSize: "11px",
};

const commonContainer = {
    fontSize: "11px",
};

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "28px",
    fontFamily: "IBMPlexSans-Bold",
};

const buttonStyle = {
    marginLeft: "10px",
};

const FileDownload = require("js-file-download");

const signalRTimerMaxCount = 300;

class ManageJobProfile extends React.Component {
    constructor(props) {
        super(props);
        this.connection = null;
        this.state = {
            profileUpdating: this.props.profileUpdating,
            profileUpdatingSuccess: this.props.profileUpdatingSuccess,
            profileUpdatingError: this.props.profileUpdatingError,
            udpatedSkills: this.props.udpatedSkills,

            error: null,
            loading: true,
            alerts: [],
            jobId: null,
            job: null,
            loadingProfile: true,
            jobLorProfiles: [],
            jobSkillProfiles: [],
            jobSkillAttributes: [],
            showHidden: false,
            showDetail: false,
            showEdit: false,
            profileVersions: [],
            selectedProfileVersion: null,
            willCreateNewVersion: false,
            skillProfilesEdit: [],
            editCount: 0,
            jobDetailIsUpdating: false,
            jobDetailUpdateSuccess: false,
            jobDetailNoteIsUpdating: false,
            extraFrameworkLevels: [],
            signalRTimerStarted: false,
            signalRTimerCount: 0,
            isTopSkillProfileLegendInViewport: false,
            isDivAnchorInViewport: false,
            showFloatingLegend: false,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        document.addEventListener("scroll", this.checkSkillProfileLegendInViewport);

        if (this.props.language) {
            this.props.onContent.getContentTranslations(this.props.language.id, "SfiaAll-Menu-SfiaProfileMenu-SfiaProfile-Lors-Skills").then(() => {
                this.setState({ contentLoading: this.props.contentLoading });

                this.initData();
            });
        } else {
            this.initData();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.checkSkillProfileLegendInViewport);
    }

    checkSkillProfileLegendInViewport = () => {
        const pathName = window.location.pathname;
        const isAdmin = pathName.includes("/admin");

        let isTopSkillProfileLegendInViewport = this.state.isTopSkillProfileLegendInViewport;
        let isDivAnchorInViewport = this.state.isDivAnchorInViewport;

        //get how much pixels left to scrolling our ReactElement
        if (this.topSkillProfileLegendElement && this.divAnchorElement) {
            const topLegendTop = this.topSkillProfileLegendElement.getBoundingClientRect().top;
            const divAnchorTop = this.divAnchorElement.getBoundingClientRect().top - 200;

            isTopSkillProfileLegendInViewport = topLegendTop <= 0 ? false : true;
            isDivAnchorInViewport = divAnchorTop <= 0 ? false : true;

            this.setState({ isTopSkillProfileLegendInViewport, isDivAnchorInViewport });
            this.setState({ showFloatingLegend: !isTopSkillProfileLegendInViewport && isDivAnchorInViewport ? true : false });

            if (this.props.updateAdminFloatingLegendStatus) {
                this.props.updateAdminFloatingLegendStatus(isAdmin && !isTopSkillProfileLegendInViewport && isDivAnchorInViewport ? true : false);
            }
        }
    };

    initData = () => {
        this.props.onAuth.authCheckState().then(() => {
            const {
                match: { params },
            } = this.props;

            var jobId = params.id;

            this.connectSignalR(jobId);

            Promise.all([this.props.onJob.getJob(jobId), this.props.onJob.getJobProfileVersions(jobId), this.props.onLor.getLors(), this.props.onCategory.getCategories(), this.props.onAdminCategory.getExtraFrameworkLevels()])
                .then(() => {
                    if (!(this.props.loading || this.props.jobsLoading || this.props.lorLoading || this.props.categoryLoading)) {
                        const profileVersions = this.props.profileVersions;
                        let selectedProfileVersion = null;
                        if (profileVersions.length > 0) {
                            selectedProfileVersion = {
                                version: profileVersions[0].version,
                                sfiaVersion: profileVersions[0].sfiaVersion,
                            };
                        }
                        this.setState(
                            {
                                jobId,
                                loading: this.props.loading || this.props.jobsLoading || this.props.lorLoading || this.props.categoryLoading,
                                job: this.props.job,
                                profileVersions: this.props.profileVersions.map((it) => {
                                    return {
                                        value: `Version ${it.version}`,
                                        label: `v${it.version} - ${new Intl.DateTimeFormat("en-GB", {
                                            year: "2-digit",
                                            month: "short",
                                            day: "numeric",
                                        }).format(new Date(it.createdOn))}`,
                                        version: it.version,
                                        sfiaVersion: it.sfiaVersion,
                                        createdOn: it.createdOn,
                                    };
                                }),
                                selectedProfileVersion: {
                                    value: `Version ${this.props.profileVersions[0].version}`,
                                    label: `v${this.props.profileVersions[0].version} - ${new Intl.DateTimeFormat("en-GB", {
                                        year: "2-digit",
                                        month: "short",
                                        day: "numeric",
                                    }).format(new Date(this.props.profileVersions[0].createdOn))}`,
                                    version: this.props.profileVersions[0].version,
                                    sfiaVersion: this.props.profileVersions[0].sfiaVersion,
                                    createdOn: this.props.profileVersions[0].createdOn,
                                },
                            },
                            () => {
                                Promise.all([
                                    this.props.onJob.getJobLorProfiles(jobId, selectedProfileVersion && selectedProfileVersion.sfiaVersion, selectedProfileVersion && selectedProfileVersion.version),
                                    this.props.onJob.getJobSkillProfiles(jobId, selectedProfileVersion && selectedProfileVersion.sfiaVersion, selectedProfileVersion && selectedProfileVersion.version),
                                    this.props.onJob.getJobSkillAttributes(jobId),
                                ])
                                    .then(() => {
                                        if (!this.props.jobLoading && !this.props.jobError) {
                                            this.setState({
                                                jobLorProfiles: this.props.jobLorProfiles,
                                                jobSkillProfiles: this.props.jobSkillProfiles,
                                                jobSkillAttributes: this.props.jobSkillAttributes,
                                            });
                                        }

                                        this.setState({
                                            loadingProfile: this.props.jobLoading,
                                        });
                                    })
                                    .catch(() => {
                                        this.setState({
                                            loadingProfile: false,
                                        });
                                    });
                            }
                        );
                    }
                })
                .catch(() => {
                    this.setState({
                        loading: false,
                    });
                });
        });
    };

    componentWillUnmount() {
        this.disconnectSignalR();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.profileUpdating !== this.props.profileUpdating) {
            this.setState({ profileUpdating: this.props.profileUpdating });
        }

        if (prevProps.profileUpdatingSuccess !== this.props.profileUpdatingSuccess || prevProps.profileUpdatingError !== this.props.profileUpdatingError || prevProps.udpatedSkills !== this.props.udpatedSkills) {
            this.setState({
                profileUpdatingSuccess: this.props.profileUpdatingSuccess,
                profileUpdatingError: this.props.profileUpdatingError,
                udpatedSkills: this.props.udpatedSkills,
            });

            if (this.props.profileUpdatingSuccess && this.props.udpatedSkills) {
                const udpatedSkills = this.props.udpatedSkills;
                const { selectedProfileVersion, jobLorProfiles, jobSkillProfiles } = this.state;
                let profileVersions = this.state.profileVersions;

                const lorProfileResponses = udpatedSkills ? udpatedSkills.jobLorProfileResponses : [];
                const skillProfileResponses = udpatedSkills ? udpatedSkills.jobSkillProfileResponses : [];

                let version = null;
                if (lorProfileResponses.length > 0) {
                    version = {
                        version: lorProfileResponses[0].version,
                        sfiaVersion: lorProfileResponses[0].sfiaVersion,
                        createdOn: lorProfileResponses[0].createdOn,
                    };
                } else if (skillProfileResponses.length > 0) {
                    version = {
                        version: skillProfileResponses[0].version,
                        sfiaVersion: skillProfileResponses[0].sfiaVersion,
                        createdOn: skillProfileResponses[0].createdOn,
                    };
                }

                if (version.sfiaVersion != (selectedProfileVersion ? selectedProfileVersion.sfiaVersion : null) || version.version != (selectedProfileVersion ? selectedProfileVersion.version : null)) {
                    const zeroVersion = profileVersions.find((it) => it.version == 0 && it.sfiaVersion == version.sfiaVersion);
                    if (zeroVersion) {
                        const index = profileVersions.indexOf(zeroVersion);
                        if (index !== -1) {
                            profileVersions.splice(index, 1);
                        }
                    }

                    this.setState({
                        profileVersions: [
                            {
                                value: `Version ${version.version}`,
                                label: `v${version.version} - ${new Intl.DateTimeFormat("en-GB", {
                                    year: "2-digit",
                                    month: "short",
                                    day: "numeric",
                                }).format(new Date(version.createdOn))}`,
                                version: version.version,
                                sfiaVersion: version.sfiaVersion,
                                createdOn: version.createdOn,
                            },
                            ...profileVersions,
                        ],
                        selectedProfileVersion: {
                            value: `Version ${version.version}`,
                            label: `v${version.version} - ${new Intl.DateTimeFormat("en-GB", {
                                year: "2-digit",
                                month: "short",
                                day: "numeric",
                            }).format(new Date(version.createdOn))}`,
                            version: version.version,
                            sfiaVersion: version.sfiaVersion,
                            createdOn: version.createdOn,
                        },
                        jobLorProfiles: [...lorProfileResponses],
                        jobSkillProfiles: [...skillProfileResponses],
                    });
                } else {
                    if (lorProfileResponses) {
                        jobLorProfiles
                            .filter((it) => it.editedNumericVal)
                            .forEach((lorProfile) => {
                                if (lorProfileResponses.find((lorProfileResponse) => lorProfileResponse.id === lorProfile.id)) {
                                    const lorProfileResponse = lorProfileResponses.find((lorProfileResponse) => lorProfileResponse.id === lorProfile.id);
                                    lorProfile.numericVal = lorProfileResponse.numericVal;
                                    lorProfile.stringVal = lorProfileResponse.stringVal;
                                    lorProfile.labelVal = lorProfileResponse.labelVal;

                                    lorProfile.editedNumericVal = null;
                                    lorProfile.editedStringVal = null;
                                    lorProfile.editedLabelVal = null;
                                    lorProfile.edited = null;
                                }
                            });

                        lorProfileResponses
                            .filter(
                                (it) =>
                                    !jobLorProfiles
                                        .map((lorProfile) => {
                                            return lorProfile.id;
                                        })
                                        .includes(it.id)
                            )
                            .forEach((lorProfile) => {
                                jobLorProfiles.push(lorProfile);
                            });

                        this.setState({ jobLorProfiles: [...jobLorProfiles] });
                    }

                    if (skillProfileResponses) {
                        jobSkillProfiles
                            .filter((it) => it.editedNumericVal)
                            .forEach((skillProfile) => {
                                if (skillProfileResponses.find((skillProfileResponse) => skillProfileResponse.id === skillProfile.id || (skillProfileResponse.skillCode === skillProfile.skillCode && skillProfileResponse.level === skillProfile.level))) {
                                    const skillProfileResponse = skillProfileResponses.find((skillProfileResponse) => skillProfileResponse.id === skillProfile.id || (skillProfileResponse.skillCode === skillProfile.skillCode && skillProfileResponse.level === skillProfile.level));

                                    skillProfile.id = skillProfileResponse.id;
                                    skillProfile.numericVal = skillProfileResponse.numericVal;
                                    skillProfile.stringVal = skillProfileResponse.stringVal;
                                    skillProfile.labelVal = skillProfileResponse.labelVal;

                                    skillProfile.editedNumericVal = null;
                                    skillProfile.editedStringVal = null;
                                    skillProfile.editedLabelVal = null;
                                    skillProfile.edited = null;
                                }
                            });

                        skillProfileResponses
                            .filter(
                                (it) =>
                                    !jobSkillProfiles
                                        .map((skillProfile) => {
                                            return skillProfile.id;
                                        })
                                        .includes(it.id)
                            )
                            .forEach((skillProfile) => {
                                jobSkillProfiles.push(skillProfile);
                            });

                        this.setState({ jobSkillProfiles: [...jobSkillProfiles] });
                    }
                }
            }

            if (this.props.profileUpdatingError) {
                this.generateAlert("danger", this.props.profileUpdatingError);
            }

            if (this.props.profileUpdatingSuccess || this.props.profileUpdatingError) {
                setTimeout(() => {
                    this.props.onJob.clearUpdateJobSkillProfiles();
                }, alertAutoHideInterval);
            }
        }
    }

    connectSignalR = (jobId) => {
        if (!this.connection) {
            this.connection = new HubConnectionBuilder().withUrl(messageHubHostname).build();

            this.connection.on(`UpdateJobProfilesNotification${jobId}`, (response) => {
                console.log("response", response);
                if (response.action == "UpdateJobProfiles") {
                    if (response.status == "Success") {
                        let udpatedSkills = response.data;
                        this.props.onJob.updateJobSkillProfilesSuccess(udpatedSkills);
                    } else {
                        this.props.onJob.updateJobSkillProfilesFail(response.message);
                    }
                }
            });

            this.connection.start().catch((err) => console.error(err.toString()));
            this.startTimer();
        }
    };

    disconnectSignalR = () => {
        if (this.connection) {
            this.connection.stop().then(() => {
                this.connection = null;
            });
        }
    };

    renderSkillProfileHeader = () => {
        let setRefTopLegend = (el) => {
            this.topSkillProfileLegendElement = el;
        };

        return (
            <Table>
                <tbody ref={setRefTopLegend}>
                    <tr>
                        <td className="pl-0 pb-1" style={{ border: "0px", fontFamily: "IBMPlexSans-Bold" }} colSpan="5">
                            Skills Profile Key
                        </td>
                        <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                            Not Selected
                        </td>
                        <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                            Required
                        </td>
                        <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                            Desirable
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="5" style={{ background: "#F5F5F5", border: "0px" }}></td>
                        <td style={{ background: "#E6E6E6", border: "0px" }}></td>
                        <td style={{ background: "#AAACAC", border: "0px" }}></td>
                        <td className="col-legend-desirable border-0"></td>
                    </tr>
                </tbody>
            </Table>
        );
    };

    handleChangeVersion = (selectedProfileVersion) => {
        this.setState({ selectedProfileVersion, loadingProfile: true });

        Promise.all([this.props.onJob.getJobLorProfiles(this.state.jobId, selectedProfileVersion.sfiaVersion, selectedProfileVersion.version), this.props.onJob.getJobSkillProfiles(this.state.jobId, selectedProfileVersion.sfiaVersion, selectedProfileVersion.version)])
            .then(() => {
                if (!this.props.jobLoading && !this.props.jobError) {
                    this.setState({
                        jobLorProfiles: this.props.jobLorProfiles,
                        jobSkillProfiles: this.props.jobSkillProfiles,
                    });
                }

                this.setState({
                    loadingProfile: this.props.jobLoading,
                });
            })
            .catch(() => {
                this.setState({
                    loadingProfile: false,
                });
            });
    };

    handleChangeShowDetail = () => {
        this.setState({
            showDetail: !this.state.showDetail,
        });
    };

    handleChangeShowHidden = () => {
        this.setState({
            showHidden: !this.state.showHidden,
        });
    };

    handleChangeShowEdit = () => {
        let willCreateNewVersion = false;

        if (!this.state.showEdit) {
            willCreateNewVersion = true;
            this.setState({ showEdit: !this.state.showEdit, willCreateNewVersion });
        } else if (this.state.skillProfilesEdit.length > 0) {
            if (window.confirm("You have some updates, are you sure to cancel the updates?")) {
                let { jobLorProfiles, jobSkillProfiles } = this.state;
                if (jobLorProfiles) {
                    jobLorProfiles.forEach((it) => {
                        it.edited = false;
                        it.editedNumericVal = null;
                    });
                }

                if (jobSkillProfiles) {
                    jobSkillProfiles.forEach((it) => {
                        it.edited = false;
                        it.editedNumericVal = null;
                    });
                }

                this.setState({
                    skillProfilesEdit: [],
                    editCount: 0,
                    showEdit: !this.state.showEdit,
                    jobLorProfiles: jobLorProfiles.filter((it) => it.id),
                    jobSkillProfiles: jobSkillProfiles.filter((it) => it.id),
                });
            }
        } else {
            this.setState({ showEdit: !this.state.showEdit, willCreateNewVersion });
        }
    };

    handleEditProfileSkill = (profileEdit) => {
        let { skillProfilesEdit } = this.state;

        let oriProfileEdit = skillProfilesEdit.find(
            (it) => (it.id && it.id === profileEdit.id && it.source == profileEdit.source) || (!it.id && it.source == profileEdit.source && ((it.lorCode && it.lorCode === profileEdit.lorCode) || (it.skillCode && it.skillCode === profileEdit.skillCode && it.level === profileEdit.level)))
        );

        if (oriProfileEdit) {
            oriProfileEdit.numericVal = profileEdit.numericVal;
            oriProfileEdit.editedNumericVal = profileEdit.editedNumericVal;
            oriProfileEdit.editedLabelVal = profileEdit.editedLabelVal;

            const editCount = skillProfilesEdit.length;
            this.setState({ skillProfilesEdit: skillProfilesEdit, editCount });
        } else {
            skillProfilesEdit = [...skillProfilesEdit, profileEdit];

            const editCount = skillProfilesEdit.length;
            this.setState({ skillProfilesEdit, editCount });
        }
    };

    handleEditProfileSkills = (profilesEdit) => {
        let { skillProfilesEdit } = this.state;

        profilesEdit.forEach((profileEdit) => {
            let oriProfileEdit = skillProfilesEdit.find(
                (it) => (it.id && it.id === profileEdit.id && it.source == profileEdit.source) || (!it.id && it.source == profileEdit.source && ((it.lorCode && it.lorCode === profileEdit.lorCode) || (it.skillCode && it.skillCode === profileEdit.skillCode && it.level === profileEdit.level)))
            );

            if (oriProfileEdit) {
                oriProfileEdit.numericVal = profileEdit.numericVal;
                oriProfileEdit.editedNumericVal = profileEdit.editedNumericVal;
                oriProfileEdit.editedLabelVal = profileEdit.editedLabelVal;
            } else {
                skillProfilesEdit = [...skillProfilesEdit, profileEdit];
            }
        });

        const editCount = skillProfilesEdit.length;
        this.setState({ skillProfilesEdit: skillProfilesEdit, editCount });
    };

    handleSaveSkillUpdate = () => {
        const { jobId, selectedProfileVersion, willCreateNewVersion, skillProfilesEdit } = this.state;
        const lorProfiles = this.state.jobLorProfiles;
        const skillProfiles = this.state.jobSkillProfiles;

        if (willCreateNewVersion) {
            lorProfiles.forEach((profile) => {
                if (!skillProfilesEdit.find((it) => it.id == profile.id) || !profile.id) {
                    skillProfilesEdit.push({
                        sfiaVersion: selectedProfileVersion ? selectedProfileVersion.sfiaVersion : null,
                        version: selectedProfileVersion ? selectedProfileVersion.version * 1 + 1 : 1,
                        createdOn: new Date(),
                        updatedOn: new Date(),
                        lor: profile.lor,
                        lorCode: profile.lorCode,
                        level: profile.numericVal * 1,
                        stringVal: profile.stringVal,
                        numericVal: profile.numericVal,
                        labelVal: profile.labelVal,
                        profileType: profile.profileType,
                        isEditable: profile.isEditable,
                        source: "lorProfiles",
                    });
                }
            });

            skillProfiles.forEach((profile) => {
                if (!skillProfilesEdit.find((it) => it.id == profile.id)) {
                    skillProfilesEdit.push({
                        sfiaVersion: selectedProfileVersion ? selectedProfileVersion.sfiaVersion : null,
                        version: selectedProfileVersion ? selectedProfileVersion.version * 1 + 1 : 1,
                        createdOn: new Date(),
                        updatedOn: new Date(),
                        category: profile.category,
                        subCategory: profile.subCategory,
                        skill: profile.skill,
                        skillCode: profile.skillCode,
                        level: profile.level,
                        isExtraFramework: profile.isExtraFramework,
                        extraFrameworkLevel: profile.extraFrameworkLevel,
                        extraFrameworkLevelId: profile.extraFrameworkLevelId,
                        stringVal: profile.stringVal,
                        numericVal: profile.numericVal,
                        labelVal: profile.labelVal,
                        profileType: profile.profileType,
                        isEditable: profile.isEditable,
                        source: "skillProfiles",
                    });
                }
            });

            skillProfilesEdit.forEach((skillProfile) => {
                skillProfile.id = null;
                skillProfile.sfiaVersion = selectedProfileVersion ? selectedProfileVersion.sfiaVersion : null;
                skillProfile.version = selectedProfileVersion ? selectedProfileVersion.version * 1 + 1 : 1;
                skillProfile.createdOn = new Date();
                skillProfile.updatedOn = new Date();
                skillProfile.isEditable = true;

                if (skillProfile.editedStringVal) {
                    skillProfile.stringVal = skillProfile.editedStringVal;
                    skillProfile.numericVal = skillProfile.editedNumericVal;
                    skillProfile.labelVal = skillProfile.editedLabelVal;
                }
            });
        } else {
            skillProfilesEdit.forEach((skillProfile) => {
                if (!skillProfile.id) {
                    skillProfile.sfiaVersion = selectedProfileVersion.sfiaVersion;
                    skillProfile.version = selectedProfileVersion.version;
                    skillProfile.createdOn = selectedProfileVersion.createdOn;
                    skillProfile.updatedOn = new Date();
                    skillProfile.isEditable = true;
                } else {
                    skillProfile.updatedOn = new Date();
                }
            });
        }

        this.props.onJob.updateJobSkillProfiles(jobId, skillProfilesEdit);

        if (lorProfiles) {
            lorProfiles.forEach((it) => {
                it.edited = false;
            });
        }

        if (skillProfiles) {
            skillProfiles.forEach((it) => {
                it.edited = false;
            });
        }

        this.setState({
            skillProfilesEdit: [],
            showEdit: !this.state.showEdit,
            jobLorProfiles: lorProfiles,
            jobSkillProfiles: skillProfiles,
        });
    };

    handleSaveJobDetailUpdate = (job) => {
        this.setState({ jobDetailIsUpdating: true, jobDetailUpdateSuccess: false });

        this.props.onJob.updateJob(job.id, job).then(() => {
            if (!this.props.jobIsUpdating) {
                if (!this.props.jobsError) {
                    this.generateAlert("success", "Update job success.");
                    this.setState({ jobDetailUpdateSuccess: true });
                } else {
                    this.generateAlert("danger", this.props.jobsError.errData.Message);
                }
            }

            this.setState({ jobDetailIsUpdating: this.props.jobIsUpdating });
        });
    };

    handleSaveJobNoteUpdate = (job) => {
        this.setState({ jobDetailNoteIsUpdating: true });

        this.props.onJob.updateJobNote(job.id, job.comment).then(() => {
            if (!this.props.jobIsUpdating) {
                if (!this.props.jobsError) {
                    this.generateAlert("success", "Update job note success.");
                } else {
                    this.generateAlert("danger", this.props.jobsError.errData.Message);
                }
            }

            this.setState({ jobDetailNoteIsUpdating: this.props.jobIsUpdating });
        });
    };

    updateSkillAttributes = (updatedJobSkillAttributes) => {
        this.setState({ jobSkillAttributes: [...updatedJobSkillAttributes] });
    };

    handlePrintDefinition = () => {
        const job = this.state.job;

        this.props.onJob.printJobReport(job.id).then(() => {
            if (!this.props.loadingPrint) {
                if (this.props.printData) {
                    let reportFormat = "PDF";
                    if (this.props.jobProfileReportFormat) {
                        reportFormat = this.props.jobProfileReportFormat;
                    }

                    FileDownload(this.props.printData, `${job.name}_SkillsTx_Requirement_Profile.${reportFormat == "PDF" ? "pdf" : "docx"}`);
                }
            }
        });
    };

    navigateBack = () => {
        localStorage.setItem("routeGoBack", "TRUE");
        this.props.history.goBack();
    };

    startTimer = () => {
        const signalRTimerStarted = this.state.signalRTimerStarted;

        if (!signalRTimerStarted) {
            setInterval(() => {
                let signalRTimerCount = this.state.signalRTimerCount + 1;

                if (signalRTimerCount >= signalRTimerMaxCount && !this.props.skillsProfileUpdating) {
                    signalRTimerCount = 0;

                    this.disconnectSignalR();
                    setTimeout(() => {
                        const {
                            match: { params },
                        } = this.props;

                        var jobId = params.id;

                        this.connectSignalR(jobId);
                    }, 100);
                }

                this.setState({ signalRTimerCount: signalRTimerCount });
            }, 1000);

            this.setState({ signalRTimerStarted: true });
        }
    };

    render() {
        let setRefAnchor = (el) => {
            this.divAnchorElement = el;
        };

        const { pageAssignments, isManagedByOwner, loginWithTeams } = this.props;

        const pageAssignment = pageAssignments ? pageAssignments.find((it) => it.pageName == "Manage Requirements") : null;

        const pathName = window.location.pathname;
        const isAdmin = pathName.includes("/admin");

        const { skillProfilesEdit, editCount, job, jobLorProfiles } = this.state;
        const { lors } = this.props;
        const { authPersonId } = this.props;

        let enableSave = false;
        if (skillProfilesEdit.length != 0 && editCount > 0) {
            const visibleLors = lors
                .filter((it) => !it.isHidden)
                .map((it) => {
                    return {
                        lor: it.name,
                        lorCode: it.lorCode,
                    };
                });

            let lorProfilesAdded = lors
                .map((lor) => {
                    const lorSkillProfileEdit = skillProfilesEdit.find((it) => it.source == "lorProfiles" && it.lorCode == lor.lorCode);
                    return { lorCode: lor.lorCode, numericVal: lorSkillProfileEdit ? (lorSkillProfileEdit.numericVal ? lorSkillProfileEdit.numericVal : lorSkillProfileEdit.editedNumericVal) : null };
                })
                .filter((it) => it.numericVal);

            let lorProfilesCheck = lors
                .map((lor) => {
                    const lorSkillProfileEdit = skillProfilesEdit.find((it) => it.source == "lorProfiles" && it.lorCode == lor.lorCode);
                    const jobLorProfile = jobLorProfiles.find((it) => it.lorCode == lor.lorCode);
                    return { lorCode: lor.lorCode, numericVal: lorSkillProfileEdit ? (lorSkillProfileEdit.numericVal ? lorSkillProfileEdit.numericVal : lorSkillProfileEdit.editedNumericVal) : jobLorProfile ? jobLorProfile.numericVal : null };
                })
                .filter((it) => it.numericVal);

            const missingAddedLors = visibleLors.filter((it) => !lorProfilesAdded.find((l) => l.lorCode == it.lorCode));
            const missingCheckLors = visibleLors.filter((it) => !lorProfilesCheck.find((l) => l.lorCode == it.lorCode));
            const missingLorProfiles = visibleLors.filter((it) => !jobLorProfiles.find((l) => l.lorCode == it.lorCode));

            enableSave = ((missingCheckLors.length == 0 || missingAddedLors.length == 0) && skillProfilesEdit.filter((it) => it.source == "skillProfiles" && it.editedNumericVal != 0).length > 0) || ((missingCheckLors.length == 0 || missingLorProfiles.length == 0) && skillProfilesEdit.length > 0);
        }

        return (
            <React.Fragment>
                <div className={`${isAdmin ? "containerMax" : "container contentMain"}`}>
                    {this.state.profileUpdating && <Alert color="warning">Update requirement profiles still in progress</Alert>}
                    {!this.state.profileUpdating && this.state.profileUpdatingSuccess && (
                        <div style={{ padding: "0px 0px 20px 20px", marginTop: "-20px", marginRight: "-20px" }}>
                            <AlertDialog className="alert-full" color="success" message="Requirement profile has been successfully updated"></AlertDialog>
                        </div>
                    )}
                    {!this.state.profileUpdating && !this.state.profileUpdatingSuccess && this.state.profileUpdatingError && (
                        <div style={{ padding: "0px 0px 20px 20px", marginTop: "-20px", marginRight: "-20px" }}>
                            <AlertDialog className="alert-full" color="danger" message={this.state.profileUpdatingError} sticky={true}></AlertDialog>
                        </div>
                    )}
                    <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                </div>
                {this.state.loading || this.state.contentLoading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <ContentWrapperLg style={isAdmin ? largeContainer : commonContainer} className={isAdmin && "contentWrapperLgAdmin-Style"}>
                            <p className="mb-0" style={headerStyle}>
                                {job && job.name}
                            </p>
                            <br />
                            <Container className="p-0">
                                <Row className="justify-content-between">
                                    <Col
                                        sm="2"
                                        style={{
                                            paddingTop: "5px",
                                        }}
                                    >
                                        <span style={{ fontSize: "16px", fontFamily: "IBMPlexSans-Bold" }}>Version : </span>{" "}
                                    </Col>
                                    <Col
                                        sm="2"
                                        style={{
                                            fontSize: "16px",
                                            padding: "0px",
                                        }}
                                    >
                                        <Select value={this.state.selectedProfileVersion} onChange={this.handleChangeVersion} options={this.state.profileVersions} isDisabled={this.state.showEdit} />
                                    </Col>
                                    {/* {skillProfilesEdit.length == 0 && <Col sm="1"></Col>} */}
                                    <Col sm={8} style={{ paddingLeft: "0px", textAlign: "right" }}>
                                        {skillProfilesEdit.length > 0 && (
                                            <React.Fragment>
                                                <div style={{ display: "inline-block", fontSize: "16px" }}>
                                                    <span className="label-warning">{`You have ${editCount} pending skill changes.`}</span>
                                                </div>
                                            </React.Fragment>
                                        )}
                                        {this.props.containerConfig && this.props.containerConfig.allowShowHiddenSkills && (
                                            <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleChangeShowHidden}>
                                                {this.state.showHidden ? "Hide Hidden Skills" : "Show Hidden Skills"}
                                            </button>
                                        )}
                                        <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleChangeShowDetail} disabled={this.state.showEdit}>
                                            {this.state.showDetail || this.state.showEdit ? "Hide Detail" : "Show Detail"}
                                        </button>
                                        {this.state.showEdit && (
                                            <button className="btn btn-light btnSecondary-Style" disabled={!enableSave} style={buttonStyle} onClick={this.handleSaveSkillUpdate}>
                                                Save
                                            </button>
                                        )}
                                        {(isManagedByOwner || (pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true))) &&
                                            this.state.selectedProfileVersion &&
                                            this.state.selectedProfileVersion.sfiaVersion == this.state.profileVersions[0].sfiaVersion &&
                                            this.state.selectedProfileVersion.version == this.state.profileVersions[0].version &&
                                            (!job.ownerId || job.ownerId == authPersonId) && (
                                                <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleChangeShowEdit}>
                                                    {this.state.showEdit ? "Cancel" : "Edit Profile"}
                                                </button>
                                            )}
                                        <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handlePrintDefinition} disabled={this.props.loadingPrint}>
                                            {!this.props.loadingPrint ? "Print Report" : <Spinner type="grow" size="sm" color="primary" />}
                                        </button>
                                        {!isManagedByOwner && (
                                            <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.navigateBack}>
                                                Back
                                            </button>
                                        )}
                                    </Col>
                                </Row>
                            </Container>
                            {this.state.loadingProfile ? (
                                <div style={{ width: "100%", textAlign: "center", paddingTop: "20px" }}>
                                    <Spinner size="lg" animation="border" role="status"></Spinner>
                                </div>
                            ) : (
                                <React.Fragment>
                                    <Container className="p-0 mt-4" style={{ fontSize: "11px" }}>
                                        <ManageJobLorProfile
                                            thStyle={"th-responsibility"}
                                            lorProfiles={this.state.jobLorProfiles}
                                            lors={this.props.lors}
                                            showEditSkill={this.state.showEdit}
                                            handleEditProfileSkill={this.handleEditProfileSkill}
                                            showHiddenSkills={this.state.showHidden}
                                        ></ManageJobLorProfile>
                                        {this.renderSkillProfileHeader()}
                                        <ManageJobSkillProfile
                                            categories={this.props.categories}
                                            job={job}
                                            extraFrameworkLevels={this.props.extraFrameworkLevels}
                                            skillProfiles={this.state.jobSkillProfiles}
                                            skillAttributes={this.state.jobSkillAttributes}
                                            showAllSkill={this.state.showDetail}
                                            showHiddenSkills={this.state.showHidden}
                                            showEditSkill={this.state.showEdit}
                                            handleEditProfileSkills={this.handleEditProfileSkills}
                                            generateAlert={this.generateAlert}
                                            updateSkillAttributes={this.updateSkillAttributes}
                                        ></ManageJobSkillProfile>
                                    </Container>
                                </React.Fragment>
                            )}
                        </ContentWrapperLg>
                        <div ref={setRefAnchor}></div>
                        <ManageJobDetail
                            languages={this.props.languages}
                            job={job}
                            allowEdit={(isManagedByOwner || (pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? true : false)) && (!job.ownerId || job.ownerId == authPersonId)}
                            isUpdating={this.state.jobDetailIsUpdating}
                            updateSuccess={this.state.jobDetailUpdateSuccess}
                            handleSaveJobDetailUpdate={this.handleSaveJobDetailUpdate}
                        ></ManageJobDetail>
                        <Container className={isAdmin ? "contentWrapperLgMaxTransparent-Style" : "contentWrapperLgTransparent-Style"} style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "5px" }}>
                            <div>
                                <div style={{ float: "left", width: isAdmin ? "49%" : "49%" }}>
                                    <ManageJobDetailMatches allowEdit={!job.ownerId || job.ownerId == authPersonId} job={job} jobType="Current" title={"Current requirement for:"}></ManageJobDetailMatches>
                                </div>
                                <div style={{ float: "right", width: isAdmin ? "49%" : "49%" }}>
                                    <ManageJobDetailMatches allowEdit={!job.ownerId || job.ownerId == authPersonId} job={job} jobType="Future" title={"Future requirement for:"}></ManageJobDetailMatches>
                                </div>
                            </div>
                        </Container>
                        <div style={{ clear: "both" }}></div>
                        <ManageJobNote
                            job={job}
                            allowEdit={(isManagedByOwner || (pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? true : false)) && (!job.ownerId || job.ownerId == authPersonId)}
                            authPersonId={authPersonId}
                            isUpdating={this.state.jobDetailNoteIsUpdating}
                            handleSaveJobNoteUpdate={this.handleSaveJobNoteUpdate}
                        ></ManageJobNote>
                        {!isAdmin && (
                            <ContentWrapperLg className="contentWrapperLg-Style-Sticky fixed-top" style={{ display: this.state.showFloatingLegend ? "block" : "none" }}>
                                <Container className="p-0" style={{ fontSize: "11px", background: "#FFFFFF" }}>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td className="pl-0 pb-1" style={{ border: "0px", fontFamily: "IBMPlexSans-Bold" }} colSpan="5">
                                                    Skills Profile Key
                                                </td>
                                                <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                                    Not Selected
                                                </td>
                                                <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                                    Required
                                                </td>
                                                <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                                    Desirable
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="5" style={{ background: "#F5F5F5", border: "0px" }}></td>
                                                <td style={{ background: "#E6E6E6", border: "0px" }}></td>
                                                <td style={{ background: "#AAACAC", border: "0px" }}></td>
                                                <td className="col-legend-desirable border-0"></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Container>
                            </ContentWrapperLg>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authPersonId: state.auth.personId,
        jobProfileReportFormat: state.auth.jobProfileReportFormat,
        selfAssessedProfileReportFormat: state.auth.selfAssessedProfileReportFormat,
        loading: state.auth.loading,
        error: state.auth.error,
        loginWithTeams: state.auth.loginWithTeams,

        lorLoading: state.lor.loading,
        lorError: state.lor.error,
        lors: state.lor.lors,

        categoryLoading: state.category.loading,
        categoryError: state.category.error,
        categories: state.category.categories,

        adminCategoryLoading: state.adminCategory.loading,
        adminCategoryError: state.adminCategory.error,
        extraFrameworkLevels: state.adminCategory.extraFrameworkLevels,

        jobsLoading: state.job.loading,
        jobsError: state.job.error,
        job: state.job.job,
        jobIsUpdating: state.job.isUpdating,
        jobLorProfiles: state.job.jobLorProfiles,
        jobSkillProfiles: state.job.jobSkillProfiles,
        jobSkillAttributes: state.job.jobSkillAttributes,
        profileVersions: state.job.profileVersions,

        profileUpdating: state.job.profileUpdating,
        profileUpdatingSuccess: state.job.profileUpdatingSuccess,
        profileUpdatingError: state.job.profileUpdatingError,
        udpatedSkills: state.job.udpatedSkills,

        loadingPrint: state.job.loadingPrint,
        printData: state.job.printData,

        pageAssignments: state.adminPerson.pageAssignments,

        language: state.language.language,
        languages: state.language.languages,
        contentTranslations: state.content.contentTranslations,
        contentLoading: state.content.loading,
        contentError: state.content.error,

        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
        onLor: bindActionCreators(lorActions, dispatch),
        onCategory: bindActionCreators(categoryActions, dispatch),
        onAdminCategory: bindActionCreators(adminCategoryActions, dispatch),
        onContent: bindActionCreators(contentActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageJobProfile));
