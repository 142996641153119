import OpenAIAPI from "../common/api/service/OpenAIService";
import "./../chatbot.css";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";

const ChatbotOpenAi = () => {
    const [input, setInput] = useState("");
    const [messages, setMessages] = useState([]);
    const [openChatBot, setOpenChatBot] = useState(false);
    const [threadCreated, setThreadCreated] = useState(false);
    const [thread, setThread] = useState(null);
    const [run, setRun] = useState(null);
    const [runCompleted, setRunCompleted] = useState(true);
    const [pollingIntervalId, setPollingIntervalId] = useState(null);
    const [question1Closed, setQuestion1Closed] = useState(false);
    const [question2Closed, setQuestion2Closed] = useState(false);
    const [question3Closed, setQuestion3Closed] = useState(false);
    const [defaultQuestion, setDefaultQuestion] = useState(null);

    const handleOpenChatBot = async () => {
        setOpenChatBot(!openChatBot);
        if (!threadCreated) {
            const thread = await OpenAIAPI.createThread();
            if (thread) {
                setThreadCreated(true);
                setThread(thread);

                setMessages((prevMessages) => [...prevMessages, { text: "Hello! How can I assist you today?", user: false }]);
            }
        }
    };

    useEffect(() => {
        if (thread && defaultQuestion) {
            handleSubmitDefaultQuestion(defaultQuestion);
        }
    }, [thread, defaultQuestion]);

    const handleOpenQuestion = async (message) => {
        setOpenChatBot(!openChatBot);
        if (!threadCreated) {
            const thread = await OpenAIAPI.createThread();
            if (thread) {
                setThreadCreated(true);
                setThread(thread);
                setDefaultQuestion(message);
            }
        }
    };

    const handleSubmitDefaultQuestion = async (message) => {
        setDefaultQuestion(null);
        const userMessage = { text: message, user: true, loadingMessage: false };
        setInput("");

        setMessages((prevMessages) => [...prevMessages, userMessage]);
        const aiMessage = { text: "...", user: false, loadingMessage: true };
        setMessages((prevMessages) => [...prevMessages, aiMessage]);

        setRunCompleted(false);
        await OpenAIAPI.createThreadMessage(thread.id, message);
        const threadRun = await OpenAIAPI.createThreadRun(thread.id);
        if (threadRun) {
            setRun(threadRun);
            // Start polling after creation
            startPolling(threadRun.id);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!input.trim()) return;

        const userMessage = { text: input, user: true, loadingMessage: false };
        setInput("");

        setMessages((prevMessages) => [...prevMessages, userMessage]);
        const aiMessage = { text: "...", user: false, loadingMessage: true };
        setMessages((prevMessages) => [...prevMessages, aiMessage]);

        setRunCompleted(false);
        await OpenAIAPI.createThreadMessage(thread.id, input);
        const threadRun = await OpenAIAPI.createThreadRun(thread.id);
        if (threadRun) {
            setRun(threadRun);
            // Start polling after creation
            startPolling(threadRun.id);
        }
    };

    useEffect(() => {
        // Clean up polling on unmount
        return () => {
            if (pollingIntervalId) clearInterval(pollingIntervalId);
        };
    }, [pollingIntervalId]);

    useEffect(() => {
        if (!run || run.status === "completed") return;
        startPolling(run.id);
    }, [run]);

    const startPolling = (runId) => {
        if (!thread) return;

        const intervalId = setInterval(async () => {
            try {
                const updatedRun = await OpenAIAPI.getThreadRun(thread.id, runId);
                console.log("run status", updatedRun.status, new Date());
                setRun(updatedRun);

                if (["cancelled", "failed", "completed", "expired"].includes(updatedRun.status)) {
                    setRunCompleted(true);
                    clearInterval(intervalId);
                    setPollingIntervalId(null);

                    fetchMessages();
                }
            } catch (error) {
                clearInterval(intervalId);
                setPollingIntervalId(null);
            }
        }, 1000);

        setPollingIntervalId(intervalId);
    };

    const fetchMessages = async () => {
        if (!thread || !run) return;

        try {
            const runMessages = await OpenAIAPI.getThreadRunMessages(thread.id, run.id);

            runMessages.forEach((runMessage) => {
                if (!messages.find((it) => it.id == runMessage.id)) {
                    if (runMessage.content.find((it) => it.type == "text")) {
                        //console.log("runMessage", runMessage);
                        const newAiMessage = { id: runMessage.id, text: runMessage.content.filter((it) => it.type == "text")[0].text.cleaned_value, user: false };
                        if (messages.find((it) => it.loadingMessage)) {
                            setMessages((prevMessages) => [...prevMessages.slice(0, -1), newAiMessage]);
                        } else {
                            setMessages((prevMessages) => [...prevMessages, newAiMessage]);
                        }
                    } else {
                        setMessages((prevMessages) => [...prevMessages.slice(0, -1)]);
                    }
                }
            });
        } catch (error) {
            console.log("error", error);
        }
    };

    return (
        <React.Fragment>
            <div className="chatbot-questions-container">
                <div className="chatbot-questions" style={{ display: openChatBot || question1Closed || threadCreated ? "none" : "block" }}>
                    <div style={{ marginTop: "-20px", marginLeft: "-20px" }}>
                        <FontAwesomeIcon icon={faCircleXmark} size="xl" style={{ cursor: "pointer" }} onClick={() => setQuestion1Closed(true)} />
                    </div>
                    <div style={{ cursor: "pointer" }} onClick={() => handleOpenQuestion("How can SkillsTX help in my IT career?")}>
                        How can SkillsTX help in my IT career?
                    </div>
                </div>
                <div className="chatbot-questions" style={{ display: openChatBot || question2Closed || threadCreated ? "none" : "block" }}>
                    <div style={{ marginTop: "-20px", marginLeft: "-20px" }}>
                        <FontAwesomeIcon icon={faCircleXmark} size="xl" style={{ cursor: "pointer" }} onClick={() => setQuestion2Closed(true)} />
                    </div>
                    <div style={{ cursor: "pointer" }} onClick={() => handleOpenQuestion("Can you suggest a career path in software development?")}>
                        Can you suggest a career path in software development?
                    </div>
                </div>
                <div className="chatbot-questions" style={{ display: openChatBot || question3Closed || threadCreated ? "none" : "block" }}>
                    <div style={{ marginTop: "-20px", marginLeft: "-20px" }}>
                        <FontAwesomeIcon icon={faCircleXmark} size="xl" style={{ cursor: "pointer" }} onClick={() => setQuestion3Closed(true)} />
                    </div>
                    <div style={{ cursor: "pointer" }} onClick={() => handleOpenQuestion("What SFIA8 skills should I develop for cybersecurity?")}>
                        What SFIA8 skills should I develop for cybersecurity?
                    </div>
                </div>
            </div>
            <img src={process.env.PUBLIC_URL + "/assets/img/chatbot.png"} className="chatboticon" style={{ display: openChatBot ? "none" : "block", height: "100px" }} onClick={handleOpenChatBot} />
            <div className="chatbot-container" style={{ display: openChatBot == true ? "block" : "none" }}>
                <div>
                    <FontAwesomeIcon icon={faCircleXmark} size="2xl" style={{ cursor: "pointer" }} onClick={() => setOpenChatBot(!openChatBot)} />
                </div>
                <div className="chatbot-messages">
                    {messages.map((message, index) => (
                        <div
                            key={index}
                            className={`message ${message.user ? "user-message" : "ai-message"}`}
                            dangerouslySetInnerHTML={{
                                __html: message.text.replaceAll("\n", "<br />"),
                            }}
                        >
                            {/* {message.text} */}
                        </div>
                    ))}
                </div>
                {!threadCreated || !runCompleted ? (
                    <div style={{ textAlign: "right" }}>
                        <Spinner type="grow" size="md" color="black" />
                    </div>
                ) : (
                    <form className="chatbot-input-form" onSubmit={handleSubmit}>
                        <input type="text" value={input} onChange={(e) => setInput(e.target.value)} placeholder="Type your message..." disabled={!threadCreated || !runCompleted} />
                        <button type="submit" disabled={!threadCreated || !runCompleted}>
                            Send
                        </button>
                    </form>
                )}
            </div>
        </React.Fragment>
    );
};
export default ChatbotOpenAi;
