import changeInput from "../../../utils/changeInput";
import React from "react";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";

class JobCopyDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDialog: false,
            isUpdating: false,
            form: {
                name: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                reference: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                copyAttributes: {
                    validation: {
                        required: true,
                    },
                    value: true,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: false,
            optionCopy: [
                {
                    label: "Copy Attributes",
                    value: true,
                },
                {
                    label: "Don't Copy Attributes",
                    value: false,
                },
            ],
            selectedJob: null,
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
            this.prepareFormState();
        }

        if (prevProps.selectedJob !== this.props.selectedJob) {
            this.setState({
                selectedJob: this.props.selectedJob,
            });
        }

        if (prevProps.isUpdating !== this.props.isUpdating) {
            this.setState({
                isUpdating: this.props.isUpdating,
            });
        }

        if (prevProps.copyJobSuccess !== this.props.copyJobSuccess) {
            if (this.props.copyJobSuccess == true) {
                this.setState({ showDialog: false });
            }
        }
    }

    prepareFormState = () => {
        this.setState({
            form: {
                name: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                reference: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                copyAttributes: {
                    validation: {
                        required: true,
                    },
                    value: true,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: false,
        });
    };

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({
            showDialog: !showDialog,
        });
    };

    handleChange = (event) => {
        const persons = this.state.persons;
        const { name, value } = event.target;

        let updateState = changeInput(this.state.form, name, value);

        if (name == "definedById") {
            if (value != "") {
                const person = persons.find((it) => it.value == value);
                if (person) {
                    updateState = changeInput(updateState.form, "definedBy", person.label);
                }
            }
        }

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handlePerformSave = () => {
        const { selectedJob, form } = this.state;

        const request = {
            jobToCopyId: selectedJob.id,
            newName: form.name.value,
            newReference: form.reference.value,
            copyAttributes: form.copyAttributes.value,
        };

        this.props.handleSaveCopyJob(request);
    };

    render() {
        const { form, optionCopy, selectedJob } = this.state;

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={!this.state.isUpdating ? this.togleDialogModal : null} size="md">
                    <ModalHeader toggle={!this.state.isUpdating ? this.togleDialogModal : null}>Copy Requirement</ModalHeader>
                    <ModalBody>
                        {selectedJob && (
                            <Form>
                                <Row>
                                    <Col md={12} sm={12}>
                                        <FormGroup row>
                                            <Label sm={4}>New Name</Label>
                                            <Col sm={8}>
                                                <Input type="text" name="name" id="name" value={form.name.value ? form.name.value : ""} invalid={form.name.touched && !form.name.valid} onChange={this.handleChange} disabled={this.state.isUpdating} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>New Unique reference</Label>
                                            <Col sm={8}>
                                                <Input id="reference" name="reference" type="textarea" value={form.reference.value ? form.reference.value : ""} invalid={form.reference.touched && !form.reference.valid} onChange={this.handleChange} disabled={this.state.isUpdating} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Copy Attributes</Label>
                                            <Col sm={8}>
                                                <Input type="select" name="copyAttributes" id="copyAttributes" value={form.copyAttributes.value} invalid={form.copyAttributes.touched && !form.copyAttributes.valid} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                    {optionCopy.map((it, key) => (
                                                        <option key={key} value={it.value}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.handlePerformSave();
                            }}
                            disabled={!this.state.isFormValid || this.state.isUpdating}
                        >
                            Save
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.togleDialogModal();
                            }}
                            disabled={this.state.isUpdating}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default JobCopyDialog;
