import * as adminPersonActions from "../../store/admin/actions/adminPersonActions";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";

class ColumnDisplaySetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDisplaySettingLoadings: [],
            columnDisplaySettings: null,
        };
    }

    componentDidMount() {
        this.setState({
            columnDisplaySettings: this.props.columnDisplaySettings,
            columnDisplaySettingLoadings: this.props.columnDisplaySettingLoadings,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.columnDisplaySettings !== this.props.columnDisplaySettings) {
            this.setState({ columnDisplaySettings: this.props.columnDisplaySettings });
        }

        if (prevProps.columnDisplaySettingLoadings !== this.props.columnDisplaySettingLoadings) {
            this.setState({ columnDisplaySettingLoadings: this.props.columnDisplaySettingLoadings });
        }
    }

    updateColumnDisplaySetting = (columnDisplaySetting) => {
        columnDisplaySetting.visible = !columnDisplaySetting.visible;
        this.props.onPerson.updateColumnDisplaySetting(columnDisplaySetting).then(() => {});
    };

    render() {
        const { columnDisplaySettings, config } = this.props;

        return (
            <React.Fragment>
                <Row>
                    <Col md="12">
                        {columnDisplaySettings &&
                            columnDisplaySettings
                                .filter((it) => it.pageName == this.props.pageName && it.updateable)
                                .map((columnDisplaySetting, index) => {
                                    const loading = this.props.columnDisplaySettingLoadings ? this.props.columnDisplaySettingLoadings.find((it) => it.id == columnDisplaySetting.id) : null;
                                    let show = true;
                                    let caption = columnDisplaySetting.caption;

                                    if (columnDisplaySetting.columnName.includes("miscellaneous")) {
                                        if (config) {
                                            if (columnDisplaySetting.columnName == "miscellaneous1" && !config.personMiscellaneous1Enable) {
                                                show = false;
                                            } else if (columnDisplaySetting.columnName == "miscellaneous2" && !config.personMiscellaneous2Enable) {
                                                show = false;
                                            } else if (columnDisplaySetting.columnName == "miscellaneous3" && !config.personMiscellaneous3Enable) {
                                                show = false;
                                            } else if (columnDisplaySetting.columnName == "miscellaneous4" && !config.personMiscellaneous4Enable) {
                                                show = false;
                                            } else if (columnDisplaySetting.columnName == "miscellaneous5" && !config.personMiscellaneous5Enable) {
                                                show = false;
                                            }

                                            if (columnDisplaySetting.columnName == "miscellaneous1" && config.personMiscellaneous1Enable) {
                                                caption = config.personMiscellaneous1Caption;
                                            }

                                            if (columnDisplaySetting.columnName == "miscellaneous2" && config.personMiscellaneous2Enable) {
                                                caption = config.personMiscellaneous2Caption;
                                            }

                                            if (columnDisplaySetting.columnName == "miscellaneous3" && config.personMiscellaneous3Enable) {
                                                caption = config.personMiscellaneous3Caption;
                                            }

                                            if (columnDisplaySetting.columnName == "miscellaneous4" && config.personMiscellaneous4Enable) {
                                                caption = config.personMiscellaneous4Caption;
                                            }

                                            if (columnDisplaySetting.columnName == "miscellaneous5" && config.personMiscellaneous5Enable) {
                                                caption = config.personMiscellaneous5Caption;
                                            }
                                        } else {
                                            show = false;
                                        }
                                    } else if (columnDisplaySetting.columnName == "skipSelfAssessmentSurvey") {
                                        if (config && config.allowSkipSelfAssessmentSurvey) {
                                            show = true;
                                        } else {
                                            show = false;
                                        }
                                    }

                                    if (show) {
                                        return (
                                            <React.Fragment key={index}>
                                                <Button
                                                    color={columnDisplaySetting.visible ? "success" : "primary"}
                                                    onClick={() => this.updateColumnDisplaySetting(columnDisplaySetting)}
                                                    style={{
                                                        fontSize: "12px",
                                                        height: "30px",
                                                        marginBottom: "5px",
                                                    }}
                                                    disabled={loading ? loading.loading : false}
                                                >
                                                    {`${caption}`}
                                                </Button>
                                                &nbsp;&nbsp;
                                            </React.Fragment>
                                        );
                                    } else {
                                        return "";
                                    }
                                })}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        columnDisplaySettings: state.adminPerson.columnDisplaySettings,
        columnDisplaySettingLoadings: state.adminPerson.columnDisplaySettingLoadings,
        config: state.config.config,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPerson: bindActionCreators(adminPersonActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ColumnDisplaySetting);
