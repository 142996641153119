import { contentTranslator } from "../../../utils/translatorUtils";
import ContentTranslator from "../../translator/ContentTranslator";
import ManageJobSkillAttribute from "./ManageJobSkillAttribute";
import { faComment, faEdit, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FormGroup, Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, UncontrolledTooltip } from "reactstrap";

class ManageJobSkillProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            skillValueOptions: [],
            editSkill: false,
            editedCategory: null,
            editedSubCategory: null,
            editedSkill: null,
            editedSkillProfiles: [],
            skillProfiles: this.props.skillProfiles,
            skillAttributes: this.props.skillAttributes,
            editAttribute: false,
        };
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        const skillValueOptions = [
            {
                value: "N",
                label: "None",
                numericVal: 0,
            },
            {
                value: "P",
                label: "Desirable",
                numericVal: 0.5,
            },
            {
                value: "M",
                label: "Required",
                numericVal: 1,
            },
        ];

        this.setState({ skillValueOptions });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.skillProfiles !== this.props.skillProfiles) {
            this.setState({ skillProfiles: this.props.skillProfiles });
        }

        if (prevProps.skillAttributes !== this.props.skillAttributes) {
            this.setState({ skillAttributes: this.props.skillAttributes });
        }
    }

    elementSkill(skillsCode, level, levels, subCategorySkillProfiles, catColour, colour, skillColour, category, subCategory) {
        const { showEditSkill } = this.props;

        const colFully = `${colour}`;
        const colUnmatched = `${skillColour}`;

        const colDesirable = `repeating-linear-gradient(90deg, ${colour}, ${colour} 5px, ${skillColour} 5px, ${skillColour} 10px)`;

        const skillLevel = levels.filter((x) => x.level == level)[0];
        let skillLevelDescription = skillLevel ? skillLevel.description : "";
        skillLevelDescription = this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${skillsCode} ${level} Description`, skillLevelDescription);

        if (subCategorySkillProfiles.filter((x) => x.level == level).length > 0) {
            const skillProfile = subCategorySkillProfiles.find((x) => x.level == level);
            const skillVal = skillProfile && (skillProfile.editedStringVal ? skillProfile.editedStringVal : skillProfile.stringVal);

            return (
                <React.Fragment>
                    <td
                        id={`lv${level}-${skillProfile && skillProfile.id}`}
                        className={`pt-1 pb-1 td-value ${showEditSkill ? "td-fixwidth-85" : "td-fixwidth"}`}
                        style={{
                            background: skillVal === "M" ? colFully : skillVal === "P" ? colDesirable : colUnmatched,
                            borderRight: "1px solid #FFFFFF",
                            borderBottom: `1px solid #dee2e6`,
                        }}
                    >
                        {skillProfile.comment && <FontAwesomeIcon icon={faComment} style={{ color: "#ffa500" }} />}
                    </td>
                    <UncontrolledTooltip
                        target={`lv${level}-${skillProfile && skillProfile.id}`}
                        placement="bottom"
                        dangerouslySetInnerHTML={{
                            __html: skillLevelDescription,
                        }}
                    ></UncontrolledTooltip>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <td
                        id={`lv${skillsCode}${level}`}
                        className={`pt-1 pb-1 td-value ${showEditSkill ? "td-fixwidth-85" : "td-fixwidth"}`}
                        style={{
                            background: skillLevel ? colUnmatched : "#FFFFFF",
                            borderRight: "1px solid #FFFFFF",
                            borderBottom: `1px solid #dee2e6`,
                        }}
                    ></td>
                    {skillLevel && (
                        <UncontrolledTooltip
                            target={`lv${skillsCode}${level}`}
                            placement="bottom"
                            dangerouslySetInnerHTML={{
                                __html: skillLevelDescription,
                            }}
                        ></UncontrolledTooltip>
                    )}
                </React.Fragment>
            );
        }
    }

    elementSkillExtraFramework(skillsCode, extraFrameworkLevelId, levels, subCategorySkillProfiles, catColour, colour, skillColour, category, subCategory) {
        const { showEditSkill, isShowJobProfile } = this.props;

        const colFully = `${colour}`;
        const colUnmatched = `${skillColour}`;

        const colDesirable = `repeating-linear-gradient(90deg, ${colour}, ${colour} 5px, ${skillColour} 5px, ${skillColour} 10px)`;

        const skillLevel = levels.filter((x) => x.extraFrameworkLevelId == extraFrameworkLevelId)[0];
        let skillLevelDescription = skillLevel ? skillLevel.description : "";
        skillLevelDescription = this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${skillsCode} ${extraFrameworkLevelId} Description`, skillLevelDescription);

        if (subCategorySkillProfiles.filter((x) => x.extraFrameworkLevelId == extraFrameworkLevelId).length > 0) {
            const skillProfile = subCategorySkillProfiles.find((x) => x.extraFrameworkLevelId == extraFrameworkLevelId);
            const skillVal = skillProfile && (skillProfile.editedStringVal ? skillProfile.editedStringVal : skillProfile.stringVal);

            return (
                <React.Fragment key={extraFrameworkLevelId}>
                    <td
                        id={`lv${extraFrameworkLevelId}-${skillProfile && skillProfile.id}`}
                        className={`pt-1 pb-1 td-value ${showEditSkill ? "td-fixwidth-85" : "td-fixwidth"}`}
                        style={{
                            background: skillVal === "M" ? colFully : skillVal === "P" ? colDesirable : colUnmatched,
                            borderRight: "1px solid #FFFFFF",
                            borderBottom: `1px solid #dee2e6`,
                        }}
                    >
                        {skillProfile.comment && <FontAwesomeIcon icon={faComment} style={{ color: "#ffa500" }} />}
                    </td>
                    <UncontrolledTooltip
                        target={`lv${extraFrameworkLevelId}-${skillProfile && skillProfile.id}`}
                        placement="bottom"
                        dangerouslySetInnerHTML={{
                            __html: skillLevelDescription,
                        }}
                    ></UncontrolledTooltip>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment key={extraFrameworkLevelId}>
                    <td
                        id={`lv${skillsCode}${extraFrameworkLevelId}`}
                        className={`pt-1 pb-1 td-value ${showEditSkill ? "td-fixwidth-85" : "td-fixwidth"}`}
                        style={{
                            background: skillLevel ? colUnmatched : "#FFFFFF",
                            borderRight: "1px solid #FFFFFF",
                            borderBottom: `1px solid #dee2e6`,
                        }}
                    ></td>
                    {skillLevel && (
                        <UncontrolledTooltip
                            target={`lv${skillsCode}${extraFrameworkLevelId}`}
                            placement="bottom"
                            dangerouslySetInnerHTML={{
                                __html: skillLevelDescription,
                            }}
                        ></UncontrolledTooltip>
                    )}
                </React.Fragment>
            );
        }
    }

    openEditSkillProfile = (editedCategory, editedSubCategory, editedSkill, editedSkillProfiles) => {
        this.setState({ editSkill: true, editedCategory, editedSubCategory, editedSkill, editedSkillProfiles });
    };

    togleEditSkillProfile = () => {
        const { editSkill } = this.state;
        this.setState({ editSkill: !editSkill });
    };

    handleChangeSkillLevel = (skill) => {
        let editedSkillProfiles = this.state.editedSkillProfiles;
        let changedSkill = null;

        if (skill.id) {
            changedSkill = editedSkillProfiles.find((it) => it.id == skill.id);
            if (changedSkill) {
                changedSkill.editedStringVal = skill.value;
            }
        } else {
            changedSkill = editedSkillProfiles.find((it) => it.skillCode == skill.skillsCode && it.level == skill.skillLevel);
            if (changedSkill) {
                changedSkill.editedStringVal = skill.value;
            } else {
                const addedSkill = {
                    category: skill.category,
                    subCategory: skill.subCategory,
                    skill: skill.skill,
                    skillCode: skill.skillsCode,
                    level: skill.skillLevel,
                    editedStringVal: skill.value,
                    isExtraFramework: skill.extraFrameworkLevelId ? true : false,
                    extraFrameworkLevel: skill.extraFrameworkLevel,
                    extraFrameworkLevelId: skill.extraFrameworkLevelId,
                };

                editedSkillProfiles.push(addedSkill);
            }
        }

        this.setState({ editedSkillProfiles });
    };

    handleSaveEditProfileClick = () => {
        let { skillProfiles, editedSkillProfiles, skillValueOptions } = this.state;
        let editedProfiles = [];
        if (editedSkillProfiles.some((it) => it.editedStringVal && it.editedStringVal != it.stringVal)) {
            editedSkillProfiles
                .filter((editedSkillProfile) => editedSkillProfile.editedStringVal && editedSkillProfile.editedStringVal != editedSkillProfile.stringVal)
                .forEach((editedSkillProfile) => {
                    if (editedSkillProfile.id) {
                        let skillProfile = skillProfiles.find((skillProfile) => skillProfile.id == editedSkillProfile.id);
                        if (skillProfile) {
                            skillProfile.editedStringVal = editedSkillProfile.editedStringVal;
                            skillProfile.edited = true;
                            skillProfile.source = "skillProfiles";
                            const selectedSkillValue = skillValueOptions.find((it) => it.value == editedSkillProfile.editedStringVal);
                            if (selectedSkillValue) {
                                skillProfile.editedNumericVal = selectedSkillValue.numericVal;
                                skillProfile.editedLabelVal = selectedSkillValue.labelVal;
                            }
                            editedProfiles.push(skillProfile);
                        }
                    } else {
                        const selectedSkillValue = skillValueOptions.find((it) => it.value == editedSkillProfile.editedStringVal);
                        const skillProfile = {
                            category: editedSkillProfile.category,
                            subCategory: editedSkillProfile.subCategory,
                            skill: editedSkillProfile.skill,
                            skillCode: editedSkillProfile.skillCode,
                            level: editedSkillProfile.level,
                            isExtraFramework: editedSkillProfile.isExtraFramework,
                            extraFrameworkLevel: editedSkillProfile.extraFrameworkLevel,
                            extraFrameworkLevelId: editedSkillProfile.extraFrameworkLevelId,
                            stringVal: editedSkillProfile.editedStringVal,
                            numericVal: selectedSkillValue && selectedSkillValue.numericVal,
                            labelVal: selectedSkillValue && selectedSkillValue.labelVal,
                            editedStringVal: editedSkillProfile.editedStringVal,
                            editedNumericVal: selectedSkillValue && selectedSkillValue.numericVal,
                            editedLabelVal: selectedSkillValue && selectedSkillValue.labelVal,
                            edited: true,
                            source: "skillProfiles",
                        };
                        skillProfiles.push(skillProfile);
                        editedProfiles.push(skillProfile);
                    }
                });
            this.props.handleEditProfileSkills(editedProfiles);
            this.setState({ editSkill: false, skillProfiles: [...skillProfiles] });
        } else {
            this.setState({ editSkill: false });
        }
    };

    renderModalEditSkill = () => {
        const { editedCategory, editedSubCategory, editedSkill, editedSkillProfiles, skillValueOptions } = this.state;

        if (this.state.editSkill) {
            return (
                <Modal isOpen={this.state.editSkill} toggle={this.togleEditSkillProfile}>
                    <ModalHeader toggle={this.togleEditSkillProfile}>Edit Skill</ModalHeader>
                    <ModalBody>
                        <Form>
                            <div style={{ overflowY: "auto", overflowX: "hidden", minHeight: "300px", maxHeight: "500px" }}>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr valign="top">
                                            <td colSpan="3" style={{ width: "100%", backgroundColor: editedCategory.colour }} className="td-edit-skill-header">
                                                {editedCategory && <ContentTranslator page="Categories" name={editedCategory.name} contentText={editedCategory.name} />}
                                            </td>
                                        </tr>
                                        <tr valign="top">
                                            <td colSpan="3" style={{ width: "100%", backgroundColor: editedSubCategory.colour }} className="td-edit-skill-subCategory-header">
                                                {editedSubCategory && <ContentTranslator page="SubCategories" name={editedSubCategory.name} contentText={editedSubCategory.name} />}
                                            </td>
                                        </tr>
                                        <tr valign="top">
                                            <td
                                                colSpan="3"
                                                style={{
                                                    width: "100%",
                                                    backgroundColor: editedSubCategory.skillColour,
                                                }}
                                                className="td-edit-skill"
                                            >
                                                {editedSkill && <ContentTranslator page="Skills" name={editedSkill.name} contentText={editedSkill.name} />}
                                            </td>
                                        </tr>
                                        <tr valign="top">
                                            <td colSpan="3" style={{ width: "100%" }} className="td-edit-skill">
                                                {editedSkill && <ContentTranslator page="Skills" name={`${editedSkill.name} Description`} contentText={editedSkill.description} />}
                                            </td>
                                        </tr>
                                        {editedSkill &&
                                            editedSkill.levels &&
                                            editedSkill.levels.map((level, index) => {
                                                const skill = editedSkillProfiles.find((it) => it.skillCode == editedSkill.skillsCode && it.level == level.level);

                                                return (
                                                    <tr key={index} valign="top">
                                                        <td style={{ width: "5%" }} className="td-edit-profile-skill">
                                                            {level.level}
                                                        </td>
                                                        <td style={{ width: "65%" }} className="td-edit-profile-skill">
                                                            <ContentTranslator page="SkillLevels" name={`${editedSkill.skillsCode} ${level.level} Description`} contentText={level.description} />
                                                        </td>
                                                        <td style={{ width: "30%" }} className="td-edit-profile-skill">
                                                            <FormGroup>
                                                                <Select
                                                                    value={skillValueOptions.find((it) => it.value == (skill ? (skill.editedStringVal ? skill.editedStringVal : skill.stringVal) : "N"))}
                                                                    menuPlacement={index == editedSkill.levels.length - 1 ? "top" : "auto"}
                                                                    onChange={(value) =>
                                                                        this.handleChangeSkillLevel(
                                                                            skill
                                                                                ? {
                                                                                      category: editedCategory.name,
                                                                                      subCategory: editedSubCategory.name,
                                                                                      skill: editedSkill.name,
                                                                                      id: skill.id,
                                                                                      skillsCode: skill.skillCode,
                                                                                      skillLevel: skill.level,
                                                                                      value: value.value,
                                                                                      extraFrameworkLevel: skill.level.extraFrameworkLevel,
                                                                                      extraFrameworkLevelId: skill.level.extraFrameworkLevelId,
                                                                                  }
                                                                                : {
                                                                                      category: editedCategory.name,
                                                                                      subCategory: editedSubCategory.name,
                                                                                      skill: editedSkill.name,
                                                                                      skillsCode: editedSkill.skillsCode,
                                                                                      skillLevel: level.level,
                                                                                      value: value.value,
                                                                                      extraFrameworkLevel: level.extraFrameworkLevel,
                                                                                      extraFrameworkLevelId: level.extraFrameworkLevelId,
                                                                                  }
                                                                        )
                                                                    }
                                                                    options={skillValueOptions}
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.togleEditSkillProfile}>
                            Close
                        </Button>{" "}
                        <Button color="primary" onClick={() => this.handleSaveEditProfileClick()}>
                            Save changes
                        </Button>
                    </ModalFooter>
                </Modal>
            );
        } else {
            return "";
        }
    };

    openEditSkillAttribute = (editedCategory, editedSubCategory, editedSkill) => {
        this.setState({ editAttribute: true, editedCategory, editedSubCategory, editedSkill });
    };

    togleEditSkillAttribute = () => {
        const { editAttribute } = this.state;
        this.setState({ editAttribute: !editAttribute });
    };

    updateSkillAttributes = (updatedJobSkillAttributes) => {
        this.props.updateSkillAttributes(updatedJobSkillAttributes);
        this.setState({ editAttribute: false });
    };

    render() {
        const { categories, showAllSkill, showHiddenSkills, showEditSkill, extraFrameworkLevels, authPersonId, job } = this.props;
        const { skillProfiles } = this.state;

        const allowEdit = !job.ownerId || job.ownerId == authPersonId;

        return (
            <React.Fragment>
                {this.renderModalEditSkill()}
                <ManageJobSkillAttribute
                    key={this.state.editedSkill ? "editAttribute" + this.state.editedSkill.id : "EmptySkillProfilesEditAttribute"}
                    editAttribute={this.state.editAttribute}
                    editedCategory={this.state.editedCategory}
                    editedSubCategory={this.state.editedSubCategory}
                    editedSkill={this.state.editedSkill}
                    togleEditSkillAttribute={this.togleEditSkillAttribute}
                    job={this.props.job}
                    skillAttributes={this.state.skillAttributes}
                    updateSkillAttributes={this.updateSkillAttributes}
                    generateAlert={this.props.generateAlert}
                    allowEdit={allowEdit}
                ></ManageJobSkillAttribute>
                <Table>
                    {categories.map((category, index) => {
                        var emptyLevels = [];
                        for (let i = extraFrameworkLevels.length + 1; i <= 7; i++) {
                            emptyLevels.push(
                                <React.Fragment key={`emptyLevel${category.id}${i}`}>
                                    <th
                                        className={"th-fixwidth " + (i == 7 ? (showEditSkill ? "" : " th-end") : "")}
                                        style={{
                                            borderBottom: "0",
                                            borderTop: "0",
                                            backgroundColor: `${category.colour}`,
                                            verticalAlign: "middle",
                                        }}
                                    ></th>
                                </React.Fragment>
                            );
                        }

                        const categoryFound = skillProfiles.filter((x) => x.category == category.name).length > 0 ? true : false;
                        if (categoryFound || showAllSkill || showEditSkill) {
                            const categoryHasValue = skillProfiles.filter((c) => c.category == category.name && (c.stringVal !== "N" || c.editedStringVal)).length > 0 ? true : false;
                            const catSkillProfilesCodeWithValue = skillProfiles.filter((it) => it.category == category.name && (it.stringVal !== "N" || it.editedStringVal)).map((skill) => skill.skillCode);

                            const categorySkills = category.subCategories.flatMap((subCategory, index) => {
                                return subCategory.skills.flatMap((skill, index) => {
                                    return skill;
                                });
                            });

                            const checkedCatSkills = showAllSkill || showEditSkill ? categorySkills : categorySkills.filter((it) => catSkillProfilesCodeWithValue.includes(it.skillsCode));
                            const categoryHidden = !checkedCatSkills.some((skill) => !skill.isHidden) && !showHiddenSkills;

                            if (categoryHasValue || showAllSkill || showEditSkill) {
                                return (
                                    <React.Fragment key={index}>
                                        <thead className="tableHeader" style={{ display: !categoryHidden ? "" : "none" }}>
                                            <tr>
                                                <th
                                                    id={"group" + category.name.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                    colSpan={2}
                                                    className={"th-start"}
                                                    style={{
                                                        borderBottom: "0",
                                                        borderTop: "0",
                                                        backgroundColor: `${category.colour}`,
                                                    }}
                                                >
                                                    <ContentTranslator page="Categories" name={category.name} contentText={category.name} />
                                                </th>
                                                <UncontrolledTooltip
                                                    target={"group" + category.name.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                    placement="bottom"
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.contentTranslator(this.props.contentTranslations, "Categories", `${category.name} Description`, category.description),
                                                    }}
                                                ></UncontrolledTooltip>
                                                {!category.isExtraFramework == true ? (
                                                    <React.Fragment>
                                                        <th
                                                            className={"th-fixwidth "}
                                                            style={{
                                                                borderBottom: "0",
                                                                borderTop: "0",
                                                                backgroundColor: `${category.colour}`,
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            1
                                                        </th>
                                                        <th
                                                            className={"th-fixwidth "}
                                                            style={{
                                                                borderBottom: "0",
                                                                borderTop: "0",
                                                                backgroundColor: `${category.colour}`,
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            2
                                                        </th>
                                                        <th
                                                            className={"th-fixwidth "}
                                                            style={{
                                                                borderBottom: "0",
                                                                borderTop: "0",
                                                                backgroundColor: `${category.colour}`,
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            3
                                                        </th>
                                                        <th
                                                            className={"th-fixwidth "}
                                                            style={{
                                                                borderBottom: "0",
                                                                borderTop: "0",
                                                                backgroundColor: `${category.colour}`,
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            4
                                                        </th>
                                                        <th
                                                            className={"th-fixwidth "}
                                                            style={{
                                                                borderBottom: "0",
                                                                borderTop: "0",
                                                                backgroundColor: `${category.colour}`,
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            5
                                                        </th>
                                                        <th
                                                            className={"th-fixwidth "}
                                                            style={{
                                                                borderBottom: "0",
                                                                borderTop: "0",
                                                                backgroundColor: `${category.colour}`,
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            6
                                                        </th>
                                                        <th
                                                            className={"th-fixwidth " + (showEditSkill ? "" : " th-end")}
                                                            style={{
                                                                borderBottom: "0",
                                                                borderTop: "0",
                                                                backgroundColor: `${category.colour}`,
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            7
                                                        </th>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {extraFrameworkLevels.map((extraFrameworkLevel, levelIndex) => {
                                                            return (
                                                                <React.Fragment key={levelIndex}>
                                                                    <th
                                                                        className={"th-fixwidth " + (extraFrameworkLevels.length == 7 && levelIndex == extraFrameworkLevels.length - 1 ? (showEditSkill ? "" : " th-end") : "")}
                                                                        style={{
                                                                            borderBottom: "0",
                                                                            borderTop: "0",
                                                                            backgroundColor: `${category.colour}`,
                                                                            verticalAlign: "middle",
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        {extraFrameworkLevel.level}
                                                                    </th>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                        {emptyLevels}
                                                    </React.Fragment>
                                                )}
                                                {showEditSkill && (
                                                    <th
                                                        className={"th-fixwidth " + " th-end"}
                                                        style={{
                                                            borderBottom: "0",
                                                            borderTop: "0",
                                                            backgroundColor: `${category.colour}`,
                                                        }}
                                                    ></th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody style={{ display: !categoryHidden ? "" : "none" }}>
                                            {category.subCategories.map((subCategory, index) => {
                                                const subCategoryHasValue = skillProfiles.filter((it) => it.subCategory === subCategory.name && (it.stringVal !== "N" || it.editedStringVal)).length > 0 ? true : false;

                                                const subCatSkillProfilesCodeWithValue = skillProfiles.filter((it) => it.subCategory === subCategory.name && (it.stringVal !== "N" || it.editedStringVal)).map((skill) => skill.skillCode);
                                                const checkedSubCatSkills = showAllSkill || showEditSkill ? subCategory.skills : subCategory.skills.filter((it) => subCatSkillProfilesCodeWithValue.includes(it.skillsCode));

                                                const subCategoryHidden = !checkedSubCatSkills.some((skill) => !skill.isHidden) && !showHiddenSkills;

                                                if (subCategoryHasValue || showAllSkill || showEditSkill) {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr style={{ display: !subCategoryHidden ? "" : "none" }}>
                                                                <td
                                                                    id={"skill" + subCategory.name.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                    colSpan={showEditSkill ? "10" : "9"}
                                                                    className={"pt-1 pb-1 td-item "}
                                                                    style={{
                                                                        fontFamily: "IBMPlexSans-Bold",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <ContentTranslator page="SubCategories" name={subCategory.name} contentText={subCategory.name} />
                                                                </td>
                                                                <UncontrolledTooltip
                                                                    target={"skill" + subCategory.name.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                    placement="bottom"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: this.contentTranslator(this.props.contentTranslations, "SubCategories", `${subCategory.name} Description`, subCategory.description),
                                                                    }}
                                                                ></UncontrolledTooltip>
                                                            </tr>
                                                            {subCategory.skills.map((skill, elIndex) => {
                                                                const subCatDetailSkillProfiles = skillProfiles.filter((x) => x.skillCode === skill.skillsCode && (x.stringVal !== "N" || x.editedStringVal));

                                                                const hasValue = subCatDetailSkillProfiles.length > 0 ? true : false;
                                                                let selectedSkillAttributes = [];

                                                                if (skill.attributes && skill.attributes.length > 0) {
                                                                    selectedSkillAttributes = skill.attributes.filter((it) => !it.isHidden && this.state.skillAttributes.find((attribute) => it.id == attribute.skillAttributeId));
                                                                }

                                                                const skillHidden = skill.isHidden && !showHiddenSkills;

                                                                var emptySkillLevels = [];
                                                                for (let i = extraFrameworkLevels.length + 1; i <= 7; i++) {
                                                                    emptySkillLevels.push(
                                                                        <React.Fragment key={`emptyLevel${subCategory.id}${skill.id}${i}`}>
                                                                            {this.elementSkillExtraFramework(skill.skillsCode, `emptyLevel${subCategory.id}${skill.id}${i}`, skill.levels, subCatDetailSkillProfiles, category.colour, subCategory.colour, subCategory.skillColour, category, subCategory)}
                                                                        </React.Fragment>
                                                                    );
                                                                }

                                                                return (
                                                                    <tr
                                                                        key={elIndex}
                                                                        style={{
                                                                            display: !skillHidden && (hasValue || showAllSkill || showEditSkill) ? "" : "none",
                                                                        }}
                                                                    >
                                                                        <td id={"category" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} className={"pt-1 pb-1 td-item"} style={{ verticalAlign: "middle" }}>
                                                                            <Row>
                                                                                {hasValue && skill.attributes && skill.attributes.length > 0 ? (
                                                                                    <React.Fragment>
                                                                                        <Col
                                                                                            xs={showAllSkill || showEditSkill ? "10" : "11"}
                                                                                            style={{
                                                                                                paddingRight: "3px",
                                                                                            }}
                                                                                        >
                                                                                            <ContentTranslator page="Skills" name={skill.name} contentText={skill.name} />
                                                                                            {selectedSkillAttributes.length > 0 && (
                                                                                                <React.Fragment>
                                                                                                    <br />
                                                                                                    {selectedSkillAttributes
                                                                                                        .sort((a, b) => a.attribute > b.attribute)
                                                                                                        .map((attribute, index) => {
                                                                                                            return (
                                                                                                                <span key={index}>
                                                                                                                    <i>
                                                                                                                        {attribute.link && !this.props.loginWithTeams ? (
                                                                                                                            <Link
                                                                                                                                to={{
                                                                                                                                    pathname: attribute.link,
                                                                                                                                }}
                                                                                                                                target="_blank"
                                                                                                                            >
                                                                                                                                {attribute.attribute}
                                                                                                                            </Link>
                                                                                                                        ) : (
                                                                                                                            attribute.attribute
                                                                                                                        )}
                                                                                                                        {index + 1 < selectedSkillAttributes.length && ", "}
                                                                                                                    </i>
                                                                                                                </span>
                                                                                                            );
                                                                                                        })}
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </Col>
                                                                                        <Col
                                                                                            xs={showAllSkill || showEditSkill ? "2" : "1"}
                                                                                            style={{
                                                                                                paddingLeft: "0px",
                                                                                            }}
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={faList}
                                                                                                style={{
                                                                                                    cursor: "pointer",
                                                                                                }}
                                                                                                onClick={() =>
                                                                                                    this.openEditSkillAttribute(
                                                                                                        {
                                                                                                            id: category.id,
                                                                                                            name: category.name,
                                                                                                            colour: category.colour,
                                                                                                        },
                                                                                                        {
                                                                                                            id: subCategory.id,
                                                                                                            name: subCategory.name,
                                                                                                            colour: subCategory.colour,
                                                                                                            skillColour: subCategory.skillColour,
                                                                                                        },
                                                                                                        skill
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </Col>
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <Col xs="12">
                                                                                        <ContentTranslator page="Skills" name={skill.name} contentText={skill.name} />
                                                                                    </Col>
                                                                                )}
                                                                            </Row>
                                                                        </td>
                                                                        <UncontrolledTooltip
                                                                            target={"category" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                            placement="bottom"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: this.contentTranslator(this.props.contentTranslations, "Skills", `${skill.name} Description`, skill.description),
                                                                            }}
                                                                        ></UncontrolledTooltip>
                                                                        <td id={"skill" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} className={"pt-1 pb-1 td-item"} style={{ verticalAlign: "middle" }}>
                                                                            {skill.skillsCode}
                                                                        </td>
                                                                        <UncontrolledTooltip
                                                                            target={"skill" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                            placement="bottom"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: this.contentTranslator(this.props.contentTranslations, "Skills", `${skill.name} Description`, skill.description),
                                                                            }}
                                                                        ></UncontrolledTooltip>
                                                                        {!category.isExtraFramework == true ? (
                                                                            <React.Fragment>
                                                                                {this.elementSkill(skill.skillsCode, 1, skill.levels, subCatDetailSkillProfiles, category.colour, subCategory.colour, subCategory.skillColour, category, subCategory)}
                                                                                {this.elementSkill(skill.skillsCode, 2, skill.levels, subCatDetailSkillProfiles, category.colour, subCategory.colour, subCategory.skillColour, category, subCategory)}
                                                                                {this.elementSkill(skill.skillsCode, 3, skill.levels, subCatDetailSkillProfiles, category.colour, subCategory.colour, subCategory.skillColour, category, subCategory)}
                                                                                {this.elementSkill(skill.skillsCode, 4, skill.levels, subCatDetailSkillProfiles, category.colour, subCategory.colour, subCategory.skillColour, category, subCategory)}
                                                                                {this.elementSkill(skill.skillsCode, 5, skill.levels, subCatDetailSkillProfiles, category.colour, subCategory.colour, subCategory.skillColour, category, subCategory)}
                                                                                {this.elementSkill(skill.skillsCode, 6, skill.levels, subCatDetailSkillProfiles, category.colour, subCategory.colour, subCategory.skillColour, category, subCategory)}
                                                                                {this.elementSkill(skill.skillsCode, 7, skill.levels, subCatDetailSkillProfiles, category.colour, subCategory.colour, subCategory.skillColour, category, subCategory)}
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                {extraFrameworkLevels.map((extraFrameworkLevel, levelIndex) => {
                                                                                    return this.elementSkillExtraFramework(skill.skillsCode, extraFrameworkLevel.id, skill.levels, subCatDetailSkillProfiles, category.colour, subCategory.colour, subCategory.skillColour, category, subCategory);
                                                                                })}
                                                                                {emptySkillLevels}
                                                                            </React.Fragment>
                                                                        )}
                                                                        {showEditSkill && (
                                                                            <td align="center" id={"tdedit" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} className={"pt-1 pb-1 td-value td-fixwidth-50"}>
                                                                                <button
                                                                                    className={`btn btn-light ${subCatDetailSkillProfiles.filter((it) => it.edited).length > 0 ? " btn-warning-edit" : " btnEdit-Style"}`}
                                                                                    onClick={() =>
                                                                                        this.openEditSkillProfile(
                                                                                            {
                                                                                                id: category.id,
                                                                                                name: category.name,
                                                                                                colour: category.colour,
                                                                                            },
                                                                                            {
                                                                                                id: subCategory.id,
                                                                                                name: subCategory.name,
                                                                                                colour: subCategory.colour,
                                                                                                skillColour: subCategory.skillColour,
                                                                                            },
                                                                                            skill,
                                                                                            skillProfiles.filter((x) => x.skillCode === skill.skillsCode)
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                                </button>
                                                                            </td>
                                                                        )}
                                                                    </tr>
                                                                );
                                                            })}
                                                        </React.Fragment>
                                                    );
                                                }
                                            })}
                                            <tr>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </React.Fragment>
                                );
                            } else {
                                return <React.Fragment key={index}></React.Fragment>;
                            }
                        } else {
                            return <React.Fragment key={index}></React.Fragment>;
                        }
                    })}
                </Table>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authPersonId: state.auth.personId,
        loginWithTeams: state.auth.loginWithTeams,
        contentTranslations: state.content.contentTranslations,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageJobSkillProfile);
