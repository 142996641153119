import { Layout } from "../components/Layout";
import { ContentWrapper } from "../components/common/ContentWrapper";
import ContentTranslator from "../components/translator/ContentTranslator";
import { contentTranslator } from "../utils/translatorUtils";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { bindActionCreators } from "redux";

class ThankYou extends Component {
    static displayName = ThankYou.name;

    constructor(props) {
        super(props);
        this.connection = null;
        this.state = {};
        this.contentTranslator = contentTranslator.bind(this);
    }

    render() {
        const containerConfig = this.props.containerConfig;

        return (
            <Layout language={this.props.language} setCurrentLanguage={this.props.setCurrentLanguage}>
                <div>
                    <ContentWrapper>
                        <Container>
                            <Row>
                                <Col className="p-0">
                                    {containerConfig && containerConfig.selfRegistrationConfirmationWording ? (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: containerConfig.selfRegistrationConfirmationWording.replace("<ol>", "<ol style='padding-left: 20px;'>").replaceAll("<li>", "<li style='padding-left: 5px;'>"),
                                            }}
                                        ></div>
                                    ) : (
                                        <React.Fragment>
                                            <p className="color-dark" style={{ fontFamily: "IBMPlexSans-Bold", fontSize: 20 }}>
                                                <ContentTranslator page="Registration" name="RegistrationSuccessTitle" contentText="Great you're NOW registered." />
                                            </p>
                                            <div className="color-dark" style={{ fontFamily: "IBMPlexSans", fontSize: 16 }}>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.contentTranslator(
                                                            this.props.contentTranslations,
                                                            "Registration",
                                                            "RegistrationSuccessMessage",
                                                            "NOTE: You will shortly receive your survey invitation email to the address you registered with.<br />If you haven't received it within 5 minutes please check junk/clutter folders.<br />After completing your self-assessment you will:<br />"
                                                        ),
                                                    }}
                                                ></span>
                                                <ol style={{ paddingLeft: "20px" }}>
                                                    <li
                                                        style={{ paddingLeft: "5px" }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.contentTranslator(this.props.contentTranslations, "Registration", "RegistrationSuccessStep1", "Receive an email with a report attached based on your survey answers"),
                                                        }}
                                                    ></li>
                                                    <li
                                                        style={{ paddingLeft: "5px" }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.contentTranslator(
                                                                this.props.contentTranslations,
                                                                "Registration",
                                                                "RegistrationSuccessStep2",
                                                                "Receive an email to set up your password for access to the SkillsTx Planner where you can:<br />* Review and refine your Profile<br />* Compare your skills against sample jobs<br />* Create an action plan"
                                                            ),
                                                        }}
                                                    ></li>
                                                </ol>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </ContentWrapper>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contentTranslations: state.content.contentTranslations,
        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
