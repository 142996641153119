import * as authActions from "../store/actions/authAction";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";

class Logout extends Component {
    componentDidMount() {
        localStorage.setItem("showApprovedProfileOnly", "FALSE");
        localStorage.setItem("manageProfileByAdminPopup", "FALSE");
        this.props.disconnectSignalR();
        this.props.onAuth.authLogoutIntended();
    }

    render() {
        return <Redirect to="/" />;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
    };
};

export default connect(null, mapDispatchToProps)(Logout);
