import BadgeAPI from "../../../common/api/service/BadgeRequestService";
import { emailRegex } from "../../../utils/consts";
import {
  Modal,
  Input,
  Button,
  DatePicker,
  Space,
  Tag,
  notification,
  Form
} from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  content: {
    textAlign: "center",
    marginTop: 25,
    marginBottom: 25,
    maxWidth: "30ch",
    color: "#4F4F4F",
  },
  text: {
    maxWidth: "30ch",
    color: "#4F4F4F",
    margin: "0 0 5px 0",
    fontWeight: 500,
  },
  inputArea: {
    margin: "20px 50px 20px 0",
    minWidth: "280px",
  },
  spacing: {
    marginTop: "10px",
  },
  inputContainer: {
    width: "280px",
    marginTop: 10,
  },
  illustrationContainer: {
    margin: "30px 80px 30px 40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

const { TextArea, Search } = Input;
const maxNumberOfEmails = 5;
const ShareBadgesModal = ({
  isModalOpen,
  onOk,
  onCancel,
  setSuccessShareData,
  setSuccessSharedDataPreview,
}) => {
  const [form] = Form.useForm()
  const expirationDate = Form.useWatch("expDate", form)
  const noteForContact = Form.useWatch("note", form)
  const [isLoading, setIsLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  const shareBadges = () => {
    setIsLoading(true);
    // form.submit()

    const respData = [];
    Promise.all(
      emails.map((email) =>
        BadgeAPI.createBadgeLinkRequest({
          email: email,
          expDate: expirationDate,
          remark: noteForContact,
        }).then((response) => respData.push(response))
      )
    ).then(() => {
      setSuccessShareData(respData);
      setSuccessSharedDataPreview({
        note: noteForContact,
        expiration: expirationDate?.format('YYYY-MM-DD HH:mm') + ` in (${expirationDate.diff(new Date(), "days")} days)`,
      });
      // setIsLoading(false);
      onCancel?.();
    }).finally(() => {
      setEmails([])
      form.resetFields()
      setIsLoading(false);
    });
  };

  const onSearch = (payload) => {
    if (form.getFieldError("email").length < 1) {
      const emailString = payload.toLowerCase()
      if (emailRegex.test(emailString)) {
        if (emails.indexOf(emailString) < 0) {
          setEmails((oldEmails) => [...oldEmails, emailString]);
          form.setFieldValue("email",null)
        } else {
          api.warning({
            message: "Email already exist",
            description: "",
            placement: "bottom",
          });
        }
      }
    }
  };

  const deleteEmail = (payload) => {
    setEmails((oldEmails) => oldEmails.filter((email) => email !== payload));
    form.setFieldValue("email",null)
    form.validateField("email")
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day").add(1);
  };


  return (
    <Modal
      open={isModalOpen}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      width="fit-content"
    >
      {contextHolder}
      <Form style={styles.container} form={form} name="formSendEmail"
        layout="vertical"
        onFinish={shareBadges}>
        <div style={styles.illustrationContainer}>
          <img
            src={process.env.PUBLIC_URL + "/assets/svg/mailBox.svg"}
            alt="Mail box illustration"
            width={180}
          />
          <p style={styles.content}>
            Share your achievement with your network in one place.
          </p>
        </div>
        <div style={styles.inputArea}>
          <div style={styles.inputContainer}>
            <Form.Item name="email" style={styles.text} label={<>Up to {maxNumberOfEmails} contact
              {maxNumberOfEmails > 1 ? "s" : ""}</>} rules={[
                {
                  required: emails.length < 1,
                },
                {
                  pattern: emailRegex,
                  message: "Please enter a valid email address."
                },
                {
                  validator: (_, value) => {
                    if (emails.includes(value)) {
                      return Promise.reject("email address exist.");
                    }
                    return Promise.resolve();
                  },
                }
              ]}>
              <Search
                placeholder="Contact email"
                enterButton="Add"
                onSearch={onSearch}
                size="middle"
                allowClear
                disabled={emails?.length >= maxNumberOfEmails}
              />
            </Form.Item>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <Space size={[0, "small"]} wrap style={{ marginTop: 10 }}>
                {emails.map((email, index) => (
                  <Tag
                    key={`${email}-${index}`}
                    closable
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                    onClose={() => deleteEmail(email)}
                    color="blue"
                  >
                    {email}
                  </Tag>
                ))}
              </Space>
            </div>
          </div>
          <div style={styles.inputContainer}>
            <Form.Item name="expDate" style={styles.text} label="Expiration date" rules={[
              {
                required: true,
                message: "Please enter The expiration date"
              }
            ]}>
              <DatePicker
                style={{ width: "100%" }}
                disabledDate={disabledDate}
              />
            </Form.Item>

          </div>
          <div style={styles.inputContainer}>
            <Form.Item name="note" style={styles.text} label="Note" rules={[
              {
                max: 1500,
                message: "Maximum 1500 characters"
              }
            ]}>
              <TextArea
                rows={4}
                placeholder="Note to your contact"

              />
            </Form.Item>

          </div>
          <div style={styles.inputContainer}>
            <Button
              type="primary"
              block
              size="large"
              style={styles.spacing}
              disabled={emails?.length === 0 || !expirationDate}
              onClick={() => { form.submit() }}
              loading={isLoading}
            >
              Share
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ShareBadgesModal;
