import * as actionTypes from "./adminActionTypes";

import AdminEmailTemplateAPI from "../../../common/api/adminservice/AdminEmailTemplateService";

export const getEmailTemplateTypesStart = () => {
    return {
        type: actionTypes.GET_EMAIL_TEMPLATE_TYPES_START
    };
};

export const getEmailTemplateTypesSuccess = (emailTemplateTypes) => {
    return {
        type: actionTypes.GET_EMAIL_TEMPLATE_TYPES_SUCCESS,
        emailTemplateTypes
    };
};

export const getEmailTemplateTypesFail = error => {
    return {
        type: actionTypes.GET_EMAIL_TEMPLATE_TYPES_FAIL,
        error: error,
    };
};

export const getEmailTemplateTypes = () => {
    return dispatch => {
        dispatch(getEmailTemplateTypesStart());

        return AdminEmailTemplateAPI.getTemplateTypes()
            .then(res => {
                dispatch(getEmailTemplateTypesSuccess(res));
            })
            .catch(err => {
                dispatch(getEmailTemplateTypesFail(err));
            });
    };
};

export const getEmailTemplateByTypeStart = () => {
    return {
        type: actionTypes.GET_EMAIL_TEMPLATE_BY_TYPE_START
    };
};

export const getEmailTemplateByTypeSuccess = (emailTemplate) => {
    return {
        type: actionTypes.GET_EMAIL_TEMPLATE_BY_TYPE_SUCCESS,
        emailTemplate
    };
};

export const getEmailTemplateByTypeFail = error => {
    return {
        type: actionTypes.GET_EMAIL_TEMPLATE_BY_TYPE_FAIL,
        error: error,
    };
};

export const getEmailTemplateByType = (emailTemplateType) => {
    return dispatch => {
        dispatch(getEmailTemplateByTypeStart());

        return AdminEmailTemplateAPI.getTemplateByType(emailTemplateType)
            .then(res => {
                dispatch(getEmailTemplateByTypeSuccess(res));
            })
            .catch(err => {
                dispatch(getEmailTemplateByTypeFail(err));
            });
    };
};

export const createUpdateEmailTemplateStart = () => {
    return {
        type: actionTypes.CREATE_UPDATE_EMAIL_TEMPLATE_START
    };
};

export const createUpdateEmailTemplateSuccess = (emailTemplate) => {
    return {
        type: actionTypes.CREATE_UPDATE_EMAIL_TEMPLATE_SUCCESS,
        emailTemplate
    };
};

export const createUpdateEmailTemplateFail = error => {
    return {
        type: actionTypes.CREATE_UPDATE_EMAIL_TEMPLATE_SUCCESS,
        error: error,
    };
};

export const createUpdateEmailTemplate = (emailTemplate) => {
    return dispatch => {
        dispatch(createUpdateEmailTemplateStart());

        return AdminEmailTemplateAPI.createUpdateTemplate(emailTemplate)
            .then(res => {
                dispatch(createUpdateEmailTemplateSuccess(res));
            })
            .catch(err => {
                dispatch(createUpdateEmailTemplateFail(err));
            });
    };
};