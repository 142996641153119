const checkValidity = (value, validation) => {
    let isValid = true;
    if (!validation) {
        return true;
    }

    if (validation.minLength) {
        isValid = value.length >= validation.minLength && isValid;
    }

    if (validation.maxLength) {
        isValid = value.length <= validation.maxLength && isValid;
    }

    if (validation.minNumber) {
        isValid = value >= validation.minNumber && isValid;
    }

    if (validation.maxNumber) {
        isValid = value <= validation.maxNumber && isValid;
    }

    if (validation.isEmail) {
        const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        isValid = pattern.test(value) && isValid;
    }

    if (validation.isNumeric) {
        const pattern = /^\d+(\.\d{1,2})?$/;
        isValid = pattern.test(value) && isValid;
    }

    if (validation.isPassword) {
        isValid = checkPassword(value) && isValid;
    }

    if (validation.isFte) {
        const pattern = /^\d+(\.\d{1,1})?$/;
        isValid = pattern.test(value) && isValid;
    }

    if (validation.isUserFte) {
        const pattern = /^\d+(\.\d{1,2})?$/;
        isValid = pattern.test(value) && isValid;
    }

    return isValid;
};

const checkPassword = (value) => {
    if (value.match(/[A-Z]/) && value.match(/[a-z]/) && value.match(/\d/) && value.match(/\W|_/)) {
        return true;
    }

    return false;
};

export default checkValidity;
