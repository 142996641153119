import PersonService from "../../../../../common/api/service/PersonService";
import MentorTable from "../../table/mentor";
import ConfirmModal from "../confirm";
import { SaveOutlined } from "@ant-design/icons";
import { Button, Modal, Spin, notification, Row, Col, Alert } from "antd";
import React, { useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import { useImmer } from "use-immer";

const ContainerCenter = styled.div`
  text-align: -webkit-center;
`;

const MentorProfileModal = ({ showDialog, contentTranslations, contentTranslator, data, onClose }) => {
  const [state, setState] = useImmer({
    showDialog: false,
    dataValue: [],
    isShowConfirm: false,
    isShowNotMentor: false,
    isLoading: false,
    isEdit: false,
    rawData: [],
    isUpdate: false,
    contentTranslations: contentTranslations
  });

  const setDefaultData = () => {
    setState((draft) => {
      draft.showDialog = true;
      draft.dataValue = [];
      draft.isShowConfirm = false;
      draft.isShowNotMentor = false;
      draft.isEdit = false;
      draft.isLoading = true;
      draft.rawData = [];
      draft.isUpdate = false;
    });
  };

  const processDataAsync = async () => {
    try {
      const dataPersonPersonMyProfile =
        await PersonService.getPersonMyMentorProfiles();
      setState((draft) => {
        draft.rawData = dataPersonPersonMyProfile.map((item) => {
          return {
            id: item.id,
            selected: item.selected,
            mentorPreferenceId: item.mentorPreferenceId,
            name: item.preference,
          };
        });
      });
    } catch (error) {
      // Log the error if fetching fails
      console.error("Failed to fetch action plan data:", error);
      // Optionally, you can set an error state to display an error message to users.
    }
  };

  const processUpdateBecomeMentorAsync = async () => {
    try {
      const dataBecomeMentor = await PersonService.updateBecomeMentor();
      if (dataBecomeMentor?.isMentor) {
        await processDataAsync();
      } else {
        setState((draft) => {
          draft.isShowNotMentor = true;
        });
      }
    } catch (error) {
      // Log the error if fetching fails
      console.error("Failed to fetch action plan data:", error);
      // Optionally, you can set an error state to display an error message to users.
    }
  };

  const onToggleDialogModal = () => {
    const showDialog = state.showDialog;
    setState((draft) => {
      draft.showDialog = !showDialog;
      draft.isLoading = false;
    });
  };

  const onSaveClicked = useCallback((e) => {
    setState((draft) => {
      draft.isShowConfirm = true;
    });
  });

  const onOkClicked = useCallback(async (e) => {
    setState((draft) => {
      draft.isLoading = true;
      draft.isShowConfirm = false;
    });
    try {
      const listUpdate = state.dataValue.map((item) => {
        return {
          id: item.id,
          mentorPreferenceId: item.mentorPreferenceId,
          selected: item.isChecked ?? false,
        };
      });
      await PersonService.updatePersonMyMentorProfiles(listUpdate);
      if (!state.isUpdate) {
        onClose(!state.isUpdate);
      }
      setDefaultData();
      onToggleDialogModal();

      notification["success"]({
        message: contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMentorProfileSuccessTitle", "Success !"),
        description: contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMentorProfileSuccessDescription", "Your mentor profile has been updated."),
      });
    } catch (error) {
      // Optionally, you can set an error state to display an error message to users.
      setState((draft) => {
        draft.isLoading = false;
      });
      notification["error"]({
        message: "error",
        description: error?.errData?.title,
      });
    }
  });

  const onCancelClicked = useCallback((e) => {
    setState((draft) => {
      draft.isShowConfirm = false;
    });
  });

  const onValueChanged = useCallback((val) => {
    setState((draft) => {
      if (!draft.isEdit) {
        draft.isEdit = true;
      }
      draft.dataValue = val ?? [];
    });
  });

  useEffect(() => {
    if (showDialog) {
      setDefaultData();
      // This variable helps us ensure that we don't call `setIsContentLoaded` if the component has unmounted before our asynchronous operation completes.
      let isMounted = true;
      const isMentor = data?.isMentor ?? false;
      if (isMentor) {
        // Fetch and process all data
        processDataAsync().then(() => {
          if (isMounted) {
            // Only set the loading state to false if the component is still mounted
            setState((draft) => {
              draft.isLoading = false;
              draft.isUpdate = isMentor;
            });
          }
        });
      } else {
        processUpdateBecomeMentorAsync().then(() => {
          if (isMounted) {
            // Only set the loading state to false if the component is still mounted
            setState((draft) => {
              draft.isLoading = false;
            });
          }
        });
      }

      // The cleanup function for the effect
      return () => {
        // If the component unmounts, we set isMounted to false to prevent any state updates after unmounting.
        isMounted = false;
      };
    }
  }, [showDialog]);

  if (state.showDialog === false) return null;

  // ... rest of the logic and JSX
  return (
    <Modal
      title={
        state.isShowConfirm || state.isShowNotMentor ? null : contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMentorProfileDialogTitle", "Mentor Profile")
      }
      closable={
        !state.isLoading && !state.isShowConfirm && !state.isShowNotMentor
      }
      open={state.showDialog}
      onCancel={
        !state.isUpdating
          ? () => {
              setDefaultData();
              onToggleDialogModal();
            }
          : null
      }
      width={
        state.isShowConfirm || state.isShowNotMentor ? "fit-content" : "60%"
      }
      style={{ borderRadius: "20px" }}
      footer={null}
    >
      <Spin
        spinning={state.isLoading}
        size="large"
        tip="Waiting..."
        delay={200}
      >
        {state.isShowConfirm == false &&
          state.isShowNotMentor == false &&
          state.isLoading == false &&
          state.rawData.length > 0 && (
            <Row className="ml-3 mr-3">
              <Col span={20}>
                <Alert
                  banner
                  description={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMentorProfileDialogNotes", "Uncheck ALL selections to remove yourself as a mentor. However, you will remain a mentor to those who you have previously accepted requests.")}
                  showIcon
                  type="warning"
                />
              </Col>
              <Col span={4} className="pl-2  text-right">
                <button
                  className="btn btn-light btnSecondary-Style mb-2"
                  disabled={!state.isEdit}
                  onClick={onSaveClicked}
                >
                  {state.isUpdate ? contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMentorProfileDialogButtonUpdate", "Update") : contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMentorProfileDialogButtonSave", "Save")}
                </button>
              </Col>
            </Row>
          )}
        <ContainerCenter>
          <ConfirmModal
            open={state.isShowConfirm}
            onOk={onOkClicked}
            onCancel={onCancelClicked}
            okText={state.isUpdate ? contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeProfileConfirmButtonUpdate", "Update") : contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeProfileConfirmButtonSave", "Save")}
            cancelText={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeProfileConfirmButtonCancel", "Cancel")}
            titleText={state.isUpdate ? contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeProfileConfirmUpdatePopupMessage", "You are about to update your mentor profile.") : contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeProfileConfirmSavePopupMessage", "You are about to save your mentor profile.")}
            msg={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeProfileConfirmMessage", "This profile will be used to help people find you as a suitable mentor.")}
            questionText={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeProfileConfirmProceedText", "Do you want to proceed ?")}
          ></ConfirmModal>
          <ConfirmModal
            open={state.isShowNotMentor}
            onOk={(e) => {
              setDefaultData();
              onToggleDialogModal();
            }}
            isCancel={false}
            okText={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeProfileNotMentorConfirmButtonOk", "Ok, Understand")}
            titleText={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeProfileNotMentorDialogTitle", "You cannot set up mentor profile for now.")}
            msg={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeProfileNotMentorDialogMessage", "You can be a mentor as soon as you have more than 4 Business skills.")}
          ></ConfirmModal>
        </ContainerCenter>
        {state.isShowConfirm == false && state.isShowNotMentor == false && (
          <MentorTable
            refData={state.rawData}
            values={state.dataValue}
            nameHeader={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMentorProfileDialogTableHeader", "Profile Options")}
            nameSelect="selected"
            onChange={onValueChanged}
          />
        )}
      </Spin>
    </Modal>
  );
};
export default MentorProfileModal;
