import * as countryActions from "../../../store/actions/countryAction";
import * as adminPersonActions from "../../../store/admin/actions/adminPersonActions";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";

class UserPageAssignments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDialog: false,
            isUpdating: false,
            selectedPerson: this.props.selectedPerson,
            personPageAssignments: null,
        };
    }

    componentDidMount() {
        if (this.props.selectedPerson) {
            this.props.onPerson.getPersonPageAssignments(this.props.selectedPerson.id).then(() => {
                this.setState({ personPageAssignments: [...this.props.personPageAssignments] });
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
        }

        if (prevProps.selectedPerson !== this.props.selectedPerson) {
            this.setState({ selectedPerson: this.props.selectedPerson });
            if (this.props.selectedPerson) {
                this.props.onPerson.getPersonPageAssignments(this.props.selectedPerson.id).then(() => {
                    this.setState({ personPageAssignments: [...this.props.personPageAssignments] });
                });
            }
        }
    }

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        if (showDialog) {
            this.props.reopenPersonDetail();
        }
        this.setState({
            showDialog: !showDialog,
            editMode: false,
        });
    };

    handleClearAll = () => {
        let { personPageAssignments } = this.state;

        personPageAssignments.forEach((personPageAssignment) => {
            personPageAssignment.operations.forEach((operation) => {
                operation.allow = false;
            });
        });

        this.setState({ personPageAssignments: [...personPageAssignments] });
    };

    handleChangeCheckBox = (pageName, operation, allow) => {
        let { personPageAssignments } = this.state;

        let personPageAssignment = personPageAssignments.find((it) => it.pageName == pageName);
        if (personPageAssignment) {
            if (personPageAssignment.operations && personPageAssignment.operations.find((it) => it.operation == operation)) {
                let objOperation = personPageAssignment.operations.find((it) => it.operation == operation);
                objOperation.allow = allow;
            }
        }

        this.setState({ personPageAssignments: [...personPageAssignments] });
    };

    handlePerformSave = () => {
        const { selectedPerson, personPageAssignments } = this.state;
        this.setState({ isUpdating: true });
        this.props.onPerson.updatePersonPageAssignments(selectedPerson.id, personPageAssignments).then(() => {
            if (!this.props.isUpdating) {
                if (this.props.error) {
                    this.props.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    this.props.generateAlert("success", "Update access permissions success");
                    this.setState({ personPageAssignments: [...this.props.personPageAssignments] });
                    this.togleDialogModal();
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    render() {
        const { personPageAssignments } = this.state;

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={!this.state.isUpdating ? this.togleDialogModal : null} size="xl">
                    <ModalHeader toggle={!this.state.isUpdating ? this.togleDialogModal : null}>Person Page Assignments</ModalHeader>
                    <ModalBody>
                        <Form>
                            {personPageAssignments &&
                                personPageAssignments.length > 0 &&
                                personPageAssignments
                                    .filter(
                                        (it) =>
                                            (it.pageName != "Manage Extra Frameworks" && it.pageName != "Manage SFIA Credentials and Reports" && it.pageName != "Manage SSO Setting" && it.pageName != "Manage LoR Category" && it.pageName != "Manage LoR") ||
                                            (this.props.containerConfig && this.props.containerConfig.showExtraFrameworkAdmin && it.pageName == "Manage Extra Frameworks" ? true : false) ||
                                            (this.props.containerConfig && this.props.containerConfig.showSfiaCertificationAdmin && it.pageName == "Manage SFIA Credentials and Reports" ? true : false) ||
                                            (this.props.containerConfig && this.props.containerConfig.allowManageSSOSetting && it.pageName == "Manage SSO Setting" ? true : false) ||
                                            (this.props.containerConfig && this.props.containerConfig.eeaFrameworkContainer && it.pageName == "Manage LoR Category" ? true : false) ||
                                            (this.props.containerConfig && this.props.containerConfig.eeaFrameworkContainer == false && it.pageName == "Manage LoR" ? true : false)
                                    )
                                    .map((personPageAssignment) => (
                                        <React.Fragment key={personPageAssignment.pageName}>
                                            <Row>
                                                <Col md={3}>{personPageAssignment.pageName}</Col>
                                                <Col md={9}>
                                                    <Row>
                                                        {personPageAssignment.operations &&
                                                            personPageAssignment.operations.length &&
                                                            personPageAssignment.operations.map((operation) => (
                                                                <Col md={3} key={`colchk${personPageAssignment.pageName.trim()}${operation.operation.trim()}`}>
                                                                    <Input
                                                                        type="checkbox"
                                                                        name={`chk${personPageAssignment.pageName.trim()}${operation.operation.trim()}`}
                                                                        id={`chk${personPageAssignment.pageName.trim()}${operation.operation.trim()}`}
                                                                        style={{ marginRight: "5px" }}
                                                                        checked={operation.allow}
                                                                        disabled={this.state.isUpdating}
                                                                        onChange={() => this.handleChangeCheckBox(personPageAssignment.pageName, operation.operation, !operation.allow)}
                                                                    />
                                                                    <Label for={`chk${personPageAssignment.pageName.trim()}${operation.operation.trim()}`} style={{ fontSize: "12px" }} check>
                                                                        {operation.operation}
                                                                    </Label>
                                                                </Col>
                                                            ))}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    ))}
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <React.Fragment>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.handleClearAll();
                                }}
                                disabled={this.state.isUpdating}
                            >
                                Clear All
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.handlePerformSave();
                                }}
                                disabled={this.state.isUpdating}
                            >
                                Save
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.togleDialogModal();
                                }}
                                disabled={this.state.isUpdating}
                            >
                                Cancel
                            </Button>
                        </React.Fragment>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        personsError: state.adminPerson.error,
        loading: state.adminPerson.loading,
        isUpdating: state.adminPerson.isUpdating,
        countries: state.country.countries,
        resetPasswordSent: state.adminPerson.resetPasswordSent,
        personPageAssignments: state.adminPerson.personPageAssignments,
        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPerson: bindActionCreators(adminPersonActions, dispatch),
        onCountry: bindActionCreators(countryActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPageAssignments);
