import * as evidenceActions from "../../store/actions/evidenceAction";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

class EvidenceDownload extends React.Component {
    static displayName = EvidenceDownload.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            fileName: null,
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const id = query.get("id");
        const fileName = query.get("filename");
        const email = query.get("email");

        Promise.resolve(this.props.managed ? this.props.onEvidence.downloadManagedPeopleEvidenceFileById(email, id) : this.props.onEvidence.downloadEvidenceFileById(id)).then(() => {
            if (!this.props.loading) {
                if (!this.props.error && this.props.evidenceFile) {
                    const url = window.URL.createObjectURL(this.props.evidenceFile);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    setTimeout(() => {
                        window.close();
                    }, 100);
                }
            }
        });
    }

    render() {
        return <React.Fragment></React.Fragment>;
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.evidence.loading,
        error: state.evidence.error,
        evidenceFile: state.evidence.evidenceFile,

        managedPeople: state.auth.managedPeople,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onEvidence: bindActionCreators(evidenceActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EvidenceDownload);
