import NewAnalytics from "../../components/trienpont";
import * as authActions from "../../store/actions/authAction";
import * as contentActions from "../../store/actions/contentAction";
import * as personActions from "../../store/actions/personAction";
import { dismisAlert, generateAlert } from "../../utils/alertUtils";
import { parameterizedString } from "../../utils/stringUtils";
import { contentTranslator } from "../../utils/translatorUtils";
import "./../../custom-my-people.css";
import React from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

class TeamAnalytics extends React.Component {
    static displayName = TeamAnalytics.name;

    constructor(props) {
        super(props);
        this.connection = null;
        this.state = {
            loading: true,
            contentLoading: true,
            teamAnalytics: null,
            alerts: [],
        };

        this.initData = this.initData.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
        this.parameterizedString = parameterizedString.bind(this);
    }

    componentDidMount() {
        const manageProfileByAdminPopup = localStorage.getItem("manageProfileByAdminPopup") && localStorage.getItem("manageProfileByAdminPopup") == "TRUE" ? true : false;

        const query = new URLSearchParams(this.props.location.search);
        const email = query.get("email");

        if (this.props.language) {
            this.props.onContent.getContentTranslations(this.props.language.id, "SfiaAll-Menu-SfiaProfileMenu-SfiaManage-Lors-Skills").then(() => {
                this.setState({ contentLoading: this.props.contentLoading });

                if (manageProfileByAdminPopup && !this.props.managedPeople && email) {
                    this.props.onPerson.getPersonMyPeopleByEmail(email).then(() => {
                        if (!this.props.loading && this.props.personManagedPeople) {
                            this.props.onAuth.myPeopleSetManagedRelationship(this.props.personManagedPeople).then(() => {
                                if (!this.props.loading) {
                                    this.initData();
                                }
                            });
                        }
                    });
                } else {
                    this.initData();
                }
            });
        } else {
            if (manageProfileByAdminPopup && !this.props.managedPeople && email) {
                this.props.onPerson.getPersonMyPeopleByEmail(email).then(() => {
                    if (!this.props.loading && this.props.personManagedPeople) {
                        this.props.onAuth.myPeopleSetManagedRelationship(this.props.personManagedPeople).then(() => {
                            if (!this.props.loading) {
                                this.initData();
                            }
                        });
                    }
                });
            } else {
                this.initData();
            }
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    initData() {
        this.props.onPerson.getPersonMyPeoplesTeamAnalytic().then(() => {
            if (!this.props.loading) {
                this.setState({
                    teamAnalytics: this.props.myPeopleTeamAnalytic,
                });
            }

            this.setState({ loading: this.props.loading });
        });
    }

    render() {
        const manageProfileByAdminPopup = localStorage.getItem("manageProfileByAdminPopup") && localStorage.getItem("manageProfileByAdminPopup") == "TRUE" ? true : false;

        const { teamAnalytics } = this.state;

        return (
            <>
                {this.state.loading || this.state.contentLoading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <NewAnalytics manageProfileByAdminPopup={manageProfileByAdminPopup} managedPeople={manageProfileByAdminPopup ? (this.props.managedPeople ? this.props.managedPeople : this.props.personManagedPeople) : null} />
                    </React.Fragment>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.person.loading,
        error: state.person.error,
        myPeopleTeamAnalytic: state.person.myPeopleTeamAnalytic,
        personManagedPeople: state.person.managedPeople,

        language: state.language.language,
        contentTranslations: state.content.contentTranslations,
        contentLoading: state.content.loading,
        contentError: state.content.error,

        authMode: state.auth.authMode,
        managedPeople: state.auth.managedPeople,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onPerson: bindActionCreators(personActions, dispatch),
        onContent: bindActionCreators(contentActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamAnalytics);
