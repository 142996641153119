import * as actionTypes from "../actions/actionTypes";
import { updateState } from "../utility";

const initialState = {
    registerReasons: [],
    error: null,
    loading: false,
};

const getRegisterReasonsStart = (state) => {
    return updateState(state, { error: null, registerReasons: [], loading: true });
};

const getRegisterReasonsSuccess = (state, action) => {
    return updateState(state, {
        registerReasons: action.registerReasons,
        error: null,
        loading: false,
    });
};

const getRegisterReasonsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_REGISTER_REASONS_START:
            return getRegisterReasonsStart(state);
        case actionTypes.GET_REGISTER_REASONS_SUCCESS:
            return getRegisterReasonsSuccess(state, action);
        case actionTypes.GET_REGISTER_REASONS_FAIL:
            return getRegisterReasonsFail(state, action);
        default:
            return state;
    }
};

export default reducer;
