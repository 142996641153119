import React from "react";


const borderWidth = 15;
const styles = {
  badgeContentContainer: {},
  skillAvatar: {
    borderRadius: "20px 100px 100px 100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tmpAvatar: {
    width: "90px",
    height: "90px",
    borderRadius: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const BadgeAvatar = ({ imgSource, bgColor, size = 100 }) => {
  return (
    <div
      style={{
        ...styles.badgeContentContainer,
      }}
    >
      <div style={{
        ...styles.skillAvatar,
        backgroundColor: !imgSource ? bgColor : "#f1f2f6",
        width: (size + borderWidth),
        height: (size + borderWidth)
      }}>
        <div
          style={{
            ...styles.tmpAvatar,
            backgroundColor: !imgSource ? "#ffffff" : "",
          }}
        >
          <img src={imgSource} alt="Skill Avatar" width={size} />
        </div>
      </div>
    </div>
  );
};

export default BadgeAvatar;
