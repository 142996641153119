import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class PersonAPI {
  // Static property to hold the base API name for mentor preferences.
  static SERVICE_NAME = "persons";

  static getSearchMentors(ignoreSkillGap = false, jobType = "Current", profileType = "Self Assessed") {
    return axios
      .get(
        `/${this.SERVICE_NAME}/searchmentors/?ignoreSkillGap=${ignoreSkillGap}&jobType=${jobType}&profileType=${profileType}`
      )
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static updateConnectMentors(jobType = "Current", profileType = "Self Assessed", listConnect) {
    return axios
      .post(`/${this.SERVICE_NAME}/connectmentors?jobType=${jobType}&profileType=${profileType}`, listConnect)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getMenteeRequests() {
    return axios
      .get(`/${this.SERVICE_NAME}/menteerequests`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static updateMenteeRequests(data) {
    return axios
      .post(`/${this.SERVICE_NAME}/updatementeerequests`, data)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static updateBecomeMentor() {
    return axios
      .post(`/${this.SERVICE_NAME}/becomementor`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getPersonMyMentorProfiles() {
    return axios
      .get(`/${this.SERVICE_NAME}/mymentorprofiles/list`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static updatePersonMyMentorProfiles(listUpdate) {
    return axios
      .post(`/${this.SERVICE_NAME}/mymentorprofiles/update`, listUpdate)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getPersonMyMentorPreferences() {
    return axios
      .get(`/${this.SERVICE_NAME}/mymentorpreferences/list`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static updatePersonMyMentorPreferences(listUpdate) {
    return axios
      .post(`/${this.SERVICE_NAME}/mymentorpreferences/update`, listUpdate)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getPersonMyProfile() {
    return axios
      .get(`/persons/myprofile`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static updatePersonMyProfile(person) {
    return axios
      .put(`/persons/myprofile`, person)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static downloadPersonMyProfile() {
    return axios
      .get(`/persons/myprofile/export`, {
        responseType: "blob",
      })
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        console.log("err", err);
        throw ErrorHandler(err);
      });
  }

  static downloadPersonProfilePicture() {
    return axios
      .get(`/persons/myprofilepicture`, {
        responseType: "blob",
      })
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        console.log("err", err);
        throw ErrorHandler(err);
      });
  }

  static downloadManagedPersonProfilePicture(email) {
    return axios
      .get(`/persons/managedPeopleProfilePicture?email=${email}`, {
        responseType: "blob",
      })
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        console.log("err", err);
        throw ErrorHandler(err);
      });
  }

  static uploadPersonProfilePicture(profilePicture) {
    const formData = new FormData();
    formData.append("profilepicture", profilePicture);
    return axios
      .put(`/persons/myprofilepicture`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getPersonLatestAccount() {
    return axios
      .get(`/persons/latestaccount`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getManagedPeopleLatestAccount(email) {
    return axios
      .get(`/persons/managedPeopleLatestaccount?email=${email}`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static updatePersonAccount(accountId, accountRequest) {
    return axios
      .put(`/persons/accounts/${accountId}`, accountRequest)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static updatePersonAccountNote(accountId, note) {
    return axios
      .post(`/persons/accounts/${accountId}/updatenote`, { note })
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static updateManagedPeoplePersonAccountNote(accountId, note, email) {
    return axios
      .post(`/persons/accounts/${accountId}/updateManagedPeopleNote`, {
        note,
        email,
      })
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static personAccountRequestApproval(accountId, comments) {
    return axios
      .post(`/persons/accounts/${accountId}/requestapproval`, { comments })
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static personAccountShowPostponeComment(accountId) {
    return axios
      .post(`/persons/accounts/${accountId}/showpostponecomment`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getPersonMyPeoples() {
    return axios
      .get(`/persons/mypeoples`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getPersonMyPeopleByEmail(email) {
    return axios
      .get(`/persons/mypeoples/byemail?email=${email}`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getPersonMyLeaders() {
    return axios
      .get(`/persons/mymanagers`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static approveMyPeopleProfile(data) {
    return axios
      .post(`/persons/mypeoples/approve`, data)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static postponeApproveMyPeopleProfile(data) {
    return axios
      .post(`/persons/mypeoples/postponeapprove`, data)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static forgetMe() {
    return axios
      .post(`/persons/forgetme`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static updatePersonActivityStatementInterval(activityStatementInterval) {
    const data = {
      activityStatementInterval,
    };
    return axios
      .put(`/persons/updateactivitystatementinterval`, data)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static generateActivityStatement() {
    return axios
      .post(`/persons/generateactivitystatement`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getPersonMyPeoplesTeamAnalytic() {
    return axios
      .get(`/persons/mypeoples/teamanalytic`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getPersonSkillCoins() {
    return axios
      .get(`/persons/skillcoins`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getColumnDisplaySettings(pageName) {
    return axios
      .get(`/persons/columnDisplaySettings?pageName=${pageName}`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static updateColumnDisplaySetting(columnDisplaySetting) {
    return axios
      .post(`/persons/columnDisplaySettings`, columnDisplaySetting)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static updateColumnDisplaySettingMoveUp(id) {
    return axios
      .post(`/persons/columnDisplaySettings/${id}/moveUp`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static updateColumnDisplaySettingMoveDown(id) {
    return axios
      .post(`/persons/columnDisplaySettings/${id}/moveDown`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }
}

export default PersonAPI;
