import LinkComponent from "../LinkComponent";
import { MessageOutlined, HourglassOutlined } from "@ant-design/icons";
import { Modal, Result, Typography } from "antd";
import React from "react";

const { Text } = Typography;

const SuccessShareDataModal = ({
  open,
  onOk,
  onCancel,
  previewData = { expiration: undefined, note: undefined },
  dataList,
}) => {
  return (
    <Modal
      open={open}
      onOk={onOk}
      footer={null}
      onCancel={onCancel}
      style={{ top: 20 }}
      width="fit-content"
    >
      <Result
        status="success"
        title="Emails sent successfully"
        style={{ paddingRight: 0, paddingLeft: 0, paddingBottom: 20 }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <HourglassOutlined style={{ marginRight: 10 }} />
            <Text>
              <Text strong>Expiration date:</Text>
              {` ${previewData.expiration}`}
            </Text>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <MessageOutlined style={{ marginRight: 10, marginTop: 5 }} />
            <Text style={{ maxWidth: "45ch" }}>
              <Text strong>Note:</Text>
              {` ${previewData.note ?? "N/A"}`}
            </Text>
          </div>
        </div>
      </Result>
      {dataList?.map((item) => (
        <LinkComponent
          key={item.email}
          email={item.email}
          url={item.url}
        ></LinkComponent>
      ))}
    </Modal>
  );
};

export default SuccessShareDataModal;
