import React, { Component } from 'react';

import { Container } from 'reactstrap';
import { Footer } from './Footer';
import { FooterContent } from './FooterContent';
import { NavMenu } from './common/NavMenu';

// import topBg1 from `${process.env.PUBLIC_URL}/assets/img/bg/top-bg1.png`;
// import topBg2 from `${process.env.PUBLIC_URL}/assets/img/bg/top-bg2.png`;

const bgWrapStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
}
const outerWrapStyle = {
    position: 'absolute',
    width: '100%',
}

const topBgStyle = {
    position: 'absolute',
    overflow: 'hidden',
    top: '0px',
    right: '0px',
    width: '100%',
    height: '300px'
}

const img1TopStyle = {
    position: 'absolute',
    top: '-103px',
    right: '-287px',
    overflow: 'hidden',
}

const img2TopStyle = {
    position: 'absolute',
    top: '-50px',
    right: '-390px',
    overflow: 'hidden',
}

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);

        this.state = {
            logoTimestamp: new Date(),
        };
    }

    logoUpdated = () => {
        this.setState({ logoTimestamp: new Date() })
    }

    render() {
        const pathName = window.location.pathname;
        const isAdmin = pathName.includes("/admin");

        const updateChildrenWithProps = React.Children.map(
            this.props.children,
            (child, i) => {
                return React.cloneElement(child, {
                    logoUpdated: this.logoUpdated,
                    index: i
                });
            }
        );

        return (
            <div >
                <div id="bg-wrapper" style={bgWrapStyle}>
                    <React.Fragment>
                        <div id="top-bg" style={topBgStyle}>
                            <img src={process.env.PUBLIC_URL + "/assets/img/bg/top-bg1.png"} alt="Top Bg1" style={img1TopStyle} width="600" height="236" />
                            <img src={process.env.PUBLIC_URL + "/assets/img/bg/top-bg2.png"} alt="Top Bg2" style={img2TopStyle} width="600" height="309" />
                        </div>
                    </React.Fragment>
                </div>
                <div id="outer-wrapper" style={outerWrapStyle}>
                    <NavMenu logoTimestamp={this.state.logoTimestamp} disconnectSignalR={this.props.disconnectSignalR} language={this.props.language} setCurrentLanguage={this.props.setCurrentLanguage} boolLoginMembee={this.props.boolLoginMembee} />
                    {isAdmin || window.location.pathname.includes("TeamAnalytics")
                        ? <React.Fragment>
                            {updateChildrenWithProps}
                        </React.Fragment>
                        : <Container id="layout-container" className="layoutContainer">
                            {updateChildrenWithProps}
                        </Container>
                    }
                    <Footer>
                        <FooterContent logoTimestamp={this.state.logoTimestamp} />
                    </Footer>
                </div>
            </div>
        );
    }
}
