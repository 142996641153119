import { ContentWrapper } from '../common/ContentWrapper';
import { Layout } from '../Layout';
import React from 'react';

class How extends React.Component {
    static displayName = How.name;

    componentDidMount() {
        console.log('how');
    }

    render() {
        console.log('how');
        return (
            <Layout>
                <ContentWrapper>
                    <div>HOW</div>
                </ContentWrapper>
            </Layout>
        );
    }
}

export default How;