import BadgeAPI from "../../common/api/service/BadgeRequestService";
import CategoryAPI from "../../common/api/service/CategoryService";
import EvidenceAPI from "../../common/api/service/EvidenceService";
import { lightenColor } from "../../utils/functions";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import BadgesList from "./BadgesList";
import HistoryModal from "./Modal/HistoryModal";
import ShareBadgesModal from "./Modal/ShareBadgesModal";
import SkillDetailsModal from "./Modal/SkillDetailsModal";
import SuccessShareDataModal from "./Modal/SuccessShareDataModal";
import { Empty, Switch, notification } from "antd";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";

const styles = {
  emptyContainer: {
    height: "40vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  shareContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 0 25px 0",
    boxShadow: `0px 15px 10px -15px rgba(0, 0, 0, 0.1)`,
  },
};

const createSkillBadgeImage = (skill, skill_code, skill_type, skill_level, badgeProvider) => {
  if (badgeProvider && badgeProvider == "DSA" ){
    return `${process.env.REACT_APP_AZURE_STORAGE_BADGE_DSA_IMAGE_BASE_URL}${skill_type} - ${skill.replaceAll("/", " ")} - Level ${skill_level}.png`;
  }else{
    return `${process.env.REACT_APP_AZURE_STORAGE_BADGE_IMAGE_BASE_URL}${skill_code}${skill_level}${skill_type}.png`;
  }
};

const findSkillsCode = (objArr, code) => {
  const found = _.find(objArr, (obj) => {
    return _.some(obj.subCategories, (sub) => {
      return _.some(sub.skills, (skill) => {
        return skill.skillsCode === code;
      });
    });
  });

  return found;
};

function findSkills(objects, skill, payload) {
  let result = null;
  _.forEach(objects, (obj) => {
    _.forEach(obj.subCategories, (sub) => {
      const child = _.find(sub.skills, { skillsCode: skill.skillCode });
      if (child) {
        const { id, colour, name, sfiaVersion } = obj;
        const { description, level } = child.levels.find(
          (filteredSkill) => filteredSkill.level === skill.level
        );
        result = {
          ...payload,
          id,
          categoryName: name,
          color: colour,
          skillCode: child.skillsCode,
          link: skill?.link ?? "",
          description,
          level,
          sfiaVersion,
          skillName: child.name,
          skillType: skill.skillType,
        };
        return false;
      }
    });
    if (result) {
      return false;
    }
  });
  return result;
}
const DigitalWallet = () => {
  const [badges, setBadges] = useState();
  const [showByCategory, setShowByCategory] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [successShareData, setSuccessShareData] = useState([]);
  const [history, setHistory] = useState();
  const [successSharedDataPreview, setSuccessSharedDataPreview] = useState({
    note: undefined,
    expiration: undefined,
  });

  const fussionBadgesData = useMemo(() => {
    if (badges) {
      return Object.keys(badges).reduce(
        (accumulator, currentValue) =>
          accumulator.concat(badges?.[currentValue]?.data),
        []
      );
    }
    return [];
  }, [badges]);

  const [isResultsModalOpen, setResultsModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const showResultsModal = () => {
    setResultsModalOpen(true);
  };

  const handleResultsOk = () => {
    setResultsModalOpen(false);
  };

  useEffect(() => {
    if (successShareData?.length !== 0) {
      showResultsModal();
    } else {
      handleResultsOk;
    }
  }, [successShareData]);

  useEffect(() => {
    const fetchBadges = async () => {
      const listOfCategories = await CategoryAPI.getCategories();
      const fetchedBadges = await EvidenceAPI.getMyBadges();
      if (Array.isArray(fetchedBadges) && fetchedBadges.length === 0) {
        window.location = `${window.location.origin}/SfiaProfile`;
        return;
      }

      if (Array.isArray(fetchedBadges) && Array.isArray(listOfCategories)) {
        const MappedData = {};
        fetchedBadges.forEach((element) => {
          const results = findSkillsCode(listOfCategories, element?.skillCode);
          const parChildRes = findSkills(listOfCategories, element, {
            imgSource: createSkillBadgeImage(
              element?.skill,
              element?.skillCode,
              element?.skillType,
              element?.level,
              element?.badgeProvider
            ),
            lightColor: lightenColor(results.colour, 95),
          });

          if (results) {
            if (MappedData?.[results?.name]) {
              MappedData[results.name].data = [
                ...MappedData[results.name].data,
                parChildRes,
              ];
            } else {
              MappedData[results?.name] = {
                color: results.colour,
                lightColor: lightenColor(results.colour, 95),
                categoryName: results.name,
                data: [parChildRes],
              };
            }
          }
          setBadges(MappedData);
        });
      }
    };
    fetchBadges();
  }, []);

  const showModal = (payload) => {
    setModalData(payload);
  };

  useEffect(() => {
    if (modalData) {
      setModalOpen(true);
    }
  }, [modalData]);

  const handleOk = () => {
    setModalOpen(false);
  };

  const handleCancel = () => {
    setModalOpen(false);
    setModalData(undefined);
  };

  const requestHistory = async () => {
    BadgeAPI.getBadgeWalletLink().then((response) => {
      setHistory(response);
      setIsHistoryModalOpen(true);
    });
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement) => {
    api.success({
      message: `Copy to clipboard`,
      description: "",
      placement,
    });
  };

  return (
    <ContentWrapperLg>
      {contextHolder}
      {badges && (
        <div style={styles.shareContainer}>
          <p style={{ margin: 0 }}>Earned badges</p>
          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <button
              className="btn btn-light btnSecondary-Style"
              onClick={() => setShareModalOpen(true)}
            >
              Share my badges
            </button>
            <button
              className="btn btn-light btnSecondary-Style"
              onClick={() => requestHistory()}
            >
              Previously shared
            </button>
          </div>
        </div>
      )}
      <div style={{ marginBottom: 40 }} />
      {badges && (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Switch
              defaultChecked={showByCategory}
              onChange={setShowByCategory}
            />
            <p style={{ margin: "0 0 0 10px" }}>Show By Category</p>
          </div>
          {showByCategory && <div style={{ marginBottom: 20 }} />}
        </>
      )}
      {badges ? (
        showByCategory ? (
          Object.keys(badges).map((key, index) => (
            <div key={`${badges[key].categoryName}-${index}`}>
              <BadgesList
                sectionTitle={badges[key].categoryName}
                sectionDarkColor={badges[key].color}
                sectionLightColor={badges[key].lightColor}
                badgesData={badges[key].data}
                onClick={showModal}
              />
              {Object.keys(badges).length !== index + 1 && (
                <div style={{ marginTop: 70 }} />
              )}
            </div>
          ))
        ) : (
          <BadgesList
            sectionTitle=""
            badgesData={fussionBadgesData}
            onClick={showModal}
          />
        )
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>No badges found!</span>}
          style={styles.emptyContainer}
        />
      )}
      {modalData && (
        <>
          <SkillDetailsModal
            isModalOpen={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Close"
            data={modalData}
          />
        </>
      )}
      <ShareBadgesModal
        isModalOpen={isShareModalOpen}
        onOk={() => setShareModalOpen(false)}
        onCancel={() => setShareModalOpen(false)}
        setSuccessShareData={setSuccessShareData}
        setSuccessSharedDataPreview={setSuccessSharedDataPreview}
      />
      <SuccessShareDataModal
        open={isResultsModalOpen}
        onOk={showResultsModal}
        onCancel={handleResultsOk}
        previewData={successSharedDataPreview}
        dataList={successShareData}
      />
      <HistoryModal
        open={isHistoryModalOpen}
        onOk={() => setIsHistoryModalOpen(true)}
        onCancel={() => setIsHistoryModalOpen(false)}
        history={history}
      />
    </ContentWrapperLg>
  );
};

export default DigitalWallet;
