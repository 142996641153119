import * as allowedRegistrationReasonsActions from "../../../store/admin/actions/adminRegistrationReasonActions";
import changeInput from "../../../utils/changeInput";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import { faPenToSquare, faMinusCircle, faPlus, faSync, faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const rowStyle = {
    fontSize: "12px",
};

class ManageRegistrationReasons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            registrationReasons: [],
            isUpdating: false,
            showDialog: false,
            showUpdateDialog: false,
            updateMode: null,
            touched: false,
            editedRegistrationReasonId: null,
            form: {
                value: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                label: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
            },
            isFormValid: false,
            searchProperties: ["value", "label"],
        };
    }

    componentDidMount() {
        //this.initData();
    }

    handleRefresh = () => {
        this.initData();
    };

    initData = () => {
        this.setState({ loading: true });
        this.props.onRegistrationReasons.getRegistrationReasons().then(() => {
            if (!this.props.loading) {
                this.setState({
                    registrationReasons: this.props.registrationReasons.sort((a, b) => (a.order > b.order ? 1 : -1)),
                });
            }

            this.setState({ loading: this.props.loading });
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
            this.initData();
        }

        if (prevProps.registrationReasons !== this.props.registrationReasons) {
            this.setState({
                registrationReasons: this.props.registrationReasons.sort((a, b) => (a.order > b.order ? 1 : -1)),
            });
        }
    }

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({ showDialog: !showDialog });
    };

    handleAddNew = () => {
        this.setState({
            updateMode: "Add",
            showUpdateDialog: true,
            touched: false,
            form: {
                value: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                label: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
            },
            isFormValid: false,
        });
    };

    openUpdate = (registrationReason) => {
        this.setState({
            updateMode: "Update",
            showUpdateDialog: true,
            touched: false,
            form: {
                value: {
                    validation: {
                        required: true,
                    },
                    value: registrationReason.value,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                label: {
                    validation: {
                        required: true,
                    },
                    value: registrationReason.label,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: true,
            editedRegistrationReasonId: registrationReason.id,
        });
    };

    handleChange = (event) => {
        const form = this.state.form;
        const { name, value } = event.target;

        let updateState = changeInput(form, name, value);

        this.setState({ form: updateState.form, isFormValid: updateState.valid });
    };

    handleSubmit = () => {
        const { updateMode, form, editedRegistrationReasonId } = this.state;
        this.setState({ isUpdating: true });

        if (updateMode == "Add") {
            this.props.onRegistrationReasons
                .addRegistrationReason({
                    label: form.label.value,
                    value: form.value.value,
                })
                .then(() => {
                    if (!this.props.isUpdating) {
                        if (this.props.error) {
                            this.props.generateAlert("danger", this.props.error.errData.Message);
                        } else {
                            this.setState({
                                registrationReasons: this.props.registrationReasons.sort((a, b) => (a.order > b.order ? 1 : -1)),
                            });
                            this.togleUpdateDialog();
                            this.props.generateAlert("success", "Registration reason added");
                        }
                    }

                    this.setState({ isUpdating: this.props.isUpdating });
                });
        } else {
            this.props.onRegistrationReasons
                .updateRegistrationReason(editedRegistrationReasonId, {
                    label: form.label.value,
                    value: form.value.value,
                })
                .then(() => {
                    if (!this.props.isUpdating) {
                        if (this.props.error) {
                            this.props.generateAlert("danger", this.props.error.errData.Message);
                        } else {
                            this.setState({
                                registrationReasons: this.props.registrationReasons.sort((a, b) => (a.order > b.order ? 1 : -1)),
                            });
                            this.togleUpdateDialog();
                            this.props.generateAlert("success", "Registration reason updated");
                        }
                    }

                    this.setState({ isUpdating: this.props.isUpdating });
                });
        }
    };

    togleUpdateDialog = () => {
        const showUpdateDialog = this.state.showUpdateDialog;
        this.setState({ showUpdateDialog: !showUpdateDialog });
    };

    renderUpdate = () => {
        const form = this.state.form;

        return (
            <Modal isOpen={this.state.showUpdateDialog} size="lg" toggle={!this.state.isUpdating ? this.togleUpdateDialog : null}>
                <ModalHeader toggle={!this.state.isUpdating ? this.togleUpdateDialog : null}>{this.state.updateMode ? this.state.updateMode : ""} Registration Reason</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup row style={{ marginBottom: "5px" }}>
                            <Label sm={3}>Value</Label>
                            <Col sm={9}>
                                <Input id="value" name="value" type="text" value={form && form.value.value ? form.value.value : ""} invalid={form && form.value.touched && !form.value.valid} onChange={this.handleChange} disabled={this.state.isUpdating} placeholder="Value" />
                            </Col>
                        </FormGroup>
                        <FormGroup row style={{ marginBottom: "5px" }}>
                            <Label sm={3}>Label</Label>
                            <Col sm={9}>
                                <Input id="label" name="label" type="textarea" value={form && form.label.value ? form.label.value : ""} invalid={form && form.label.touched && !form.label.valid} onChange={this.handleChange} disabled={this.state.isUpdating} placeholder="Label" />
                            </Col>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color={this.state.isUpdating ? "secondary" : "primary"}
                        onClick={() => {
                            this.handleSubmit();
                        }}
                        disabled={this.state.isUpdating || this.state.emailDomain == ""}
                    >
                        Save
                    </Button>
                    <Button color="secondary" onClick={this.togleUpdateDialog} disabled={this.state.isUpdating}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleDelete = (row) => {
        if (window.confirm("Are you sure want to delete this record?")) {
            this.setState({ isUpdating: true });

            this.props.onRegistrationReasons.deleteRegistrationReason(row.id).then(() => {
                if (!this.props.isUpdating) {
                    if (this.props.error) {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    } else {
                        const registrationReasons = this.props.registrationReasons
                            .sort((a, b) => (a.order > b.order ? 1 : -1))
                            .map((it) => {
                                return {
                                    id: it.id,
                                    value: it.value,
                                    label: it.label,
                                    order: it.order,
                                };
                            });
                        this.setState({ registrationReasons: [...registrationReasons.filter((it) => it.id != row.id)] });
                        this.props.generateAlert("success", "Registration reason deleted");
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    handleMoveUp = (row) => {
        this.props.onRegistrationReasons.moveRegistrationReasonUp(row.id).then(() => {});
    };

    handleMoveDown = (row) => {
        this.props.onRegistrationReasons.moveRegistrationReasonDown(row.id).then(() => {});
    };

    render() {
        const { registrationReasons, searchKeyword, searchProperties } = this.state;

        const openUpdate = this.openUpdate;
        const handleDelete = this.handleDelete;
        const moveUp = this.handleMoveUp;
        const moveDown = this.handleMoveDown;

        const columns = [
            {
                id: "id",
                name: "Action",
                selector: (row) => row.id,
                sortable: false,
                width: "15%",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <FontAwesomeIcon id={`iconUpdate${row.id}`} icon={faPenToSquare} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => openUpdate(row)} />
                            <UncontrolledTooltip target={`iconUpdate${row.id}`} placement="bottom">
                                Update
                            </UncontrolledTooltip>
                            {!row.isUsed && (
                                <React.Fragment>
                                    &nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon id={`iconDelete${row.id}`} icon={faMinusCircle} size="lg" style={{ cursor: "pointer", color: "#FF0000" }} onClick={() => handleDelete(row)} />
                                    <UncontrolledTooltip target={`iconDelete${row.id}`} placement="bottom">
                                        Delete
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            )}
                        </div>
                    );
                },
                omit: !this.props.allowEdit,
            },
            {
                id: "value",
                name: "Value",
                selector: (row) => row.value,
                sortable: false,
                width: this.props.allowEdit ? "25%" : "30%",
                wrap: true,
            },
            {
                id: "label",
                name: "Label",
                selector: (row) => row.label,
                sortable: false,
                width: this.props.allowEdit ? "50%" : "60%",
                wrap: true,
            },
            {
                id: "order",
                name: "",
                selector: (row) => row.order,
                center: true,
                width: "10%",
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                            <FontAwesomeIcon id={`iconMoveUp${row.id}`} icon={faArrowUp} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => moveUp(row)} />
                            <UncontrolledTooltip target={`iconMoveUp${row.id}`} placement="bottom">
                                Move Up
                            </UncontrolledTooltip>
                            &nbsp;&nbsp;&nbsp;
                            <FontAwesomeIcon id={`iconMoveDown${row.id}`} icon={faArrowDown} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => moveDown(row)} />
                            <UncontrolledTooltip target={`iconMoveDown${row.id}`} placement="bottom">
                                Move Down
                            </UncontrolledTooltip>
                        </div>
                    );
                },
                omit: !this.props.allowEdit,
            },
        ];

        return (
            <React.Fragment>
                {this.renderUpdate()}
                <Modal isOpen={this.state.showDialog} size="lg">
                    <ModalHeader toggle={!this.state.isUpdating ? this.togleDialogModal : null}>Registration Reasons</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col md={12} sm={12}>
                                    <div style={{ marginBottom: "10px" }}>
                                        <Row>
                                            <Col md="6" sm="8">
                                                <SkillsTxTableSearchBar
                                                    searchKeyword={searchKeyword}
                                                    onChange={(searchKeyword) => {
                                                        this.setState({ searchKeyword });
                                                    }}
                                                ></SkillsTxTableSearchBar>
                                            </Col>
                                            <Col md="6" sm="4">
                                                <SkillsTxTableClearSearch
                                                    style={{
                                                        fontSize: "16px",
                                                        height: "40px",
                                                    }}
                                                    color="primary"
                                                    disabled={this.props.loading || this.state.isUpdating}
                                                    onClear={() => {
                                                        this.setState({ searchKeyword: "" });
                                                    }}
                                                ></SkillsTxTableClearSearch>
                                                &nbsp;&nbsp;
                                                <Button
                                                    color="primary"
                                                    onClick={this.handleRefresh}
                                                    style={{
                                                        fontSize: "16px",
                                                        height: "40px",
                                                    }}
                                                    disabled={this.props.loading || this.state.isUpdating}
                                                >
                                                    <FontAwesomeIcon icon={faSync} /> Refresh
                                                </Button>
                                                &nbsp;&nbsp;
                                                {this.props.allowEdit && (
                                                    <React.Fragment>
                                                        <Button
                                                            color="primary"
                                                            onClick={this.handleAddNew}
                                                            style={{
                                                                fontSize: "16px",
                                                                height: "40px",
                                                            }}
                                                            disabled={this.props.loading}
                                                        >
                                                            <FontAwesomeIcon icon={faPlus} /> Add New
                                                        </Button>
                                                        &nbsp;&nbsp;
                                                    </React.Fragment>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                    <SkillsTxTable columns={columns} data={registrationReasons} pagination={true} searchKeyword={searchKeyword} searchProperties={searchProperties} progressPending={this.props.loading}></SkillsTxTable>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.adminRegistrationReason.loading,
        error: state.adminRegistrationReason.error,
        registrationReasons: state.adminRegistrationReason.registrationReasons,
        isUpdating: state.adminRegistrationReason.isUpdating,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRegistrationReasons: bindActionCreators(allowedRegistrationReasonsActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageRegistrationReasons);
