import AdminActionLogAPI from "./../../../common/api/adminservice/AdminActionLogService";
import * as adminActionTypes from "./adminActionTypes";

export const getActionLogsStart = () => {
    return {
        type: adminActionTypes.GET_ACTION_LOGS_START,
    };
};

export const getActionLogsSuccess = (actionLogs) => {
    return {
        type: adminActionTypes.GET_ACTION_LOGS_SUCCESS,
        actionLogs,
    };
};

export const getActionLogsFail = (error) => {
    return {
        type: adminActionTypes.GET_ACTION_LOGS_FAIL,
        error: error,
    };
};

export const getActionLogs = (startDate, endDate) => {
    return (dispatch) => {
        dispatch(getActionLogsStart());

        return AdminActionLogAPI.getActionLogs(startDate, endDate)
            .then((res) => {
                dispatch(getActionLogsSuccess(res));
            })
            .catch((err) => {
                dispatch(getActionLogsFail(err));
            });
    };
};

export const exportActionLogsExcelStart = () => {
    return {
        type: adminActionTypes.EXPORT_ACTION_LOGS_START,
    };
};

export const exportActionLogsExcelSuccess = (printData) => {
    return {
        type: adminActionTypes.EXPORT_ACTION_LOGS_SUCCESS,
        printData,
    };
};

export const exportActionLogsExcelFail = (error) => {
    return {
        type: adminActionTypes.EXPORT_ACTION_LOGS_FAIL,
        error: error,
    };
};

export const exportActionLogsExcel = (request) => {
    return (dispatch) => {
        dispatch(exportActionLogsExcelStart());

        return AdminActionLogAPI.exportActionLogsExcel(request)
            .then((res) => {
                dispatch(exportActionLogsExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportActionLogsExcelFail(err));
            });
    };
};
