import React from "react";

class Admin403Page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
        };
    }

    render() {
        return "";
    }
}

export default Admin403Page;
