import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Container, Input } from "reactstrap";

export class SkillsTxTableSearchBar extends Component {
    static displayName = SkillsTxTableSearchBar.name;

    constructor(props) {
        super(props);

        this.state = {};
    }

    searchKeyword = (event) => {
        const { name, value } = event.target;
        this.props.onChange(value);
    };

    render() {
        return <Input type="text" name="searchKeyword" id="searchKeyword" value={this.props.searchKeyword} onChange={this.searchKeyword} placeholder="Search" />;
    }
}
