import AdminAllowedRegistrationEmailDomainAPI from "./../../../common/api/adminservice/AdminAllowedRegistrationEmailDomainService";
import * as adminActionTypes from "./adminActionTypes";

export const getAllowedRegistrationEmailDomainsStart = () => {
    return {
        type: adminActionTypes.GET_ALLOWED_REGISTRATION_EMAIL_DOMAINS_START,
    };
};

export const getAllowedRegistrationEmailDomainsSuccess = (allowedRegistrationEmailDomains) => {
    return {
        type: adminActionTypes.GET_ALLOWED_REGISTRATION_EMAIL_DOMAINS_SUCCESS,
        allowedRegistrationEmailDomains,
    };
};

export const getAllowedRegistrationEmailDomainsFail = (error) => {
    return {
        type: adminActionTypes.GET_ALLOWED_REGISTRATION_EMAIL_DOMAINS_FAIL,
        error: error,
    };
};

export const getAllowedRegistrationEmailDomains = () => {
    return (dispatch) => {
        dispatch(getAllowedRegistrationEmailDomainsStart());

        return AdminAllowedRegistrationEmailDomainAPI.getAllowedRegistrationEmailDomains()
            .then((res) => {
                dispatch(getAllowedRegistrationEmailDomainsSuccess(res));
            })
            .catch((err) => {
                dispatch(getAllowedRegistrationEmailDomainsFail(err));
            });
    };
};

export const addAllowedRegistrationEmailDomainStart = () => {
    return {
        type: adminActionTypes.ADD_ALLOWED_REGISTRATION_EMAIL_DOMAIN_START,
    };
};

export const addAllowedRegistrationEmailDomainSuccess = (addedAllowedRegistrationEmailDomain) => {
    return {
        type: adminActionTypes.ADD_ALLOWED_REGISTRATION_EMAIL_DOMAIN_SUCCESS,
        addedAllowedRegistrationEmailDomain,
    };
};

export const addAllowedRegistrationEmailDomainFail = (error) => {
    return {
        type: adminActionTypes.ADD_ALLOWED_REGISTRATION_EMAIL_DOMAIN_FAIL,
        error: error,
    };
};

export const addAllowedRegistrationEmailDomain = (emailDomain) => {
    return (dispatch) => {
        dispatch(addAllowedRegistrationEmailDomainStart());

        return AdminAllowedRegistrationEmailDomainAPI.createAllowedRegistrationEmailDomain(emailDomain)
            .then((res) => {
                dispatch(addAllowedRegistrationEmailDomainSuccess(res));
            })
            .catch((err) => {
                dispatch(addAllowedRegistrationEmailDomainFail(err));
            });
    };
};

export const updateAllowedRegistrationEmailDomainStart = () => {
    return {
        type: adminActionTypes.UPDATE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_START,
    };
};

export const updateAllowedRegistrationEmailDomainSuccess = (updatedAllowedRegistrationEmailDomain) => {
    return {
        type: adminActionTypes.UPDATE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_SUCCESS,
        updatedAllowedRegistrationEmailDomain,
    };
};

export const updateAllowedRegistrationEmailDomainFail = (error) => {
    return {
        type: adminActionTypes.UPDATE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_FAIL,
        error: error,
    };
};

export const updateAllowedRegistrationEmailDomain = (id, emailDomain) => {
    return (dispatch) => {
        dispatch(updateAllowedRegistrationEmailDomainStart());

        return AdminAllowedRegistrationEmailDomainAPI.updateAllowedRegistrationEmailDomain(id, emailDomain)
            .then((res) => {
                dispatch(updateAllowedRegistrationEmailDomainSuccess(res));
            })
            .catch((err) => {
                dispatch(updateAllowedRegistrationEmailDomainFail(err));
            });
    };
};

export const deleteAllowedRegistrationEmailDomainStart = () => {
    return {
        type: adminActionTypes.DELETE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_START,
    };
};

export const deleteAllowedRegistrationEmailDomainSuccess = (deletedAllowedRegistrationEmailDomainId) => {
    return {
        type: adminActionTypes.DELETE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_SUCCESS,
        deletedAllowedRegistrationEmailDomainId,
    };
};

export const deleteAllowedRegistrationEmailDomainFail = (error) => {
    return {
        type: adminActionTypes.DELETE_ALLOWED_REGISTRATION_EMAIL_DOMAIN_FAIL,
        error: error,
    };
};

export const deleteAllowedRegistrationEmailDomain = (id) => {
    return (dispatch) => {
        dispatch(deleteAllowedRegistrationEmailDomainStart());

        return AdminAllowedRegistrationEmailDomainAPI.deleteAllowedRegistrationEmailDomain(id)
            .then((res) => {
                dispatch(deleteAllowedRegistrationEmailDomainSuccess(id));
            })
            .catch((err) => {
                dispatch(deleteAllowedRegistrationEmailDomainFail(err));
            });
    };
};
