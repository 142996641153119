import { Col, Container, Row } from 'reactstrap';
import React, { Component } from 'react';

import ContentTranslator from '../translator/ContentTranslator';
import { contentTranslator } from "../../utils/translatorUtils";
import { parameterizedString } from "../../utils/stringUtils";

const stepAssessmentStyle = {
    border: 1,
    width: '810px',
    height: '100%',
    backgroundColor: '#EFEFEF',
    paddingLeft: '82px',
    paddingRight: '82px',
    paddingTop: '33px',
    paddingBottom: '40px',
    marginBottom: '10px',
    borderRadius: '20px',
}

const stepHeaderStyle = {
    fontFamily: 'IBMPlexSans-Bold',
    fontSize: 20,
    width: '394px'
}

const stepInfoStyle = {
    fontFamily: 'IBMPlexSans-Light',
    fontSize: 11,
    width: '135px'
}

export class StepAssessment extends Component {
    static displayName = StepAssessment.name;

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
        }

        this.contentTranslator = contentTranslator.bind(this);
        this.parameterizedString = parameterizedString.bind(this);
    }

    render() {
        const isComplete = this.props.isComplete;

        return (
            <Container style={stepAssessmentStyle}>
                <div id="step-header">
                    {isComplete ?
                        <p className="color-dark" style={stepHeaderStyle}
                            dangerouslySetInnerHTML={{ __html: this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "Assessment", "CompleteMessage", "Nice work {s1}<br />You completed your self assessment"), this.state.name) }}
                        ></p>
                        :
                        <p className="color-dark" style={stepHeaderStyle}
                            dangerouslySetInnerHTML={{ __html: this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "Assessment", "WelcomeMessage", "Welcome, {s1}.<br />Let’s get your assessment started!"), this.state.name) }}
                        ></p>
                    }
                </div>
                <Container>
                    <Row xs="1" sm="1" md="3">
                        <Col className="p-0">
                            <Container>
                                <Row className="mb-1 align-items-center">
                                    <Col xs="auto" className="p-0">
                                        <div className="rounded-circle col align-self-center border stepCircle   stepCircle-active">
                                            <p className="mt-1 stepCircleContent-active">1</p>
                                        </div>
                                    </Col>
                                    <Col className="p-0">
                                        <p className="ml-2 mb-0 color-dark" style={stepInfoStyle}>
                                            <ContentTranslator page="Assessment" name="AssessmentStep1" contentText="Complete assigned surveys below." />
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col className="p-0">
                            <Container>
                                <Row className="mb-1 align-items-center">
                                    <Col xs="auto" className="p-0">
                                        <div className={isComplete ? " rounded-circle col align-self-center border stepCircle stepCircle-active" : "rounded-circle col align-self-center border stepCircle"} >
                                            <p className={isComplete ? "mt-1 stepCircleContent-active" : "mt-1 stepCircleContent"} >2</p>
                                        </div>
                                    </Col>
                                    <Col className="p-0">
                                        <p className="ml-2 mb-0 color-dark" style={stepInfoStyle}>
                                            <ContentTranslator page="Assessment" name="AssessmentStep2" contentText="View your skills assessment report." />
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col className="p-0">
                            <Container>
                                <Row className="mb-1 align-items-center">
                                    <Col xs="auto" className="p-0">
                                        <div className="rounded-circle col align-self-center border stepCircle">
                                            <p className="mt-1 stepCircleContent" >3</p>
                                        </div>
                                    </Col>
                                    <Col className="p-0">
                                        <p className="ml-2 mb-0 color-dark" style={stepInfoStyle}>
                                            <ContentTranslator page="Assessment" name="AssessmentStep3" contentText="Start planning future career moves." />
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
}