import * as actionTypes from "../actions/actionTypes";

import { updateState } from "../utility";

const initialState = {
    error: null,
    loading: false,
    surveys: [],
    surveyRoles: [],
    question: null,
    answerResult: null,
    answerLoading: false,
};

const getMySurveysStart = (state) => {
    return updateState(state, { error: null, loading: true });
};

const getMySurveysSuccess = (state, action) => {
    return updateState(state, {
        surveys: action.surveys,
        surveyRoles: action.surveyRoles,
        error: null,
        loading: false,
    });
};

const getMySurveysFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const selectRoleStart = (state) => {
    return updateState(state, { error: null, answerLoading: true });
};

const selectRoleSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        answerLoading: false,
    });
};

const selectRoleFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        answerLoading: false,
    });
};

const getSurveyQuestionStart = (state) => {
    return updateState(state, { error: null, loading: true });
};

const getSurveyQuestionSuccess = (state, action) => {
    return updateState(state, {
        question: action.question,
        error: null,
        loading: false,
    });
};

const getSurveyQuestionFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const answerQuestionStart = (state) => {
    return updateState(state, { error: null, answerLoading: true });
};

const answerQuestionSuccess = (state, action) => {
    return updateState(state, {
        answerResult: action.answerResult,
        error: null,
        answerLoading: false,
    });
};

const answerQuestionFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        answerLoading: false,
    });
};

const skipQuestionStart = (state) => {
    return updateState(state, { error: null, answerLoading: true });
};

const skipQuestionSuccess = (state, action) => {
    return updateState(state, {
        answerResult: action.answerResult,
        error: null,
        answerLoading: false,
    });
};

const skipQuestionFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        answerLoading: false,
    });
};

const completeMySurveyStart = (state) => {
    return updateState(state, { error: null, });
};

const completeMySurveySuccess = (state) => {
    return updateState(state, {
        error: null,
    });
};

const completeMySurveyFail = (state, action) => {
    return updateState(state, {
        error: action.error,
    });
};

const resetMySurveyStart = (state) => {
    return updateState(state, { error: null, answerLoading: true });
};

const resetMySurveySuccess = (state) => {
    return updateState(state, {
        answerLoading: false,
        error: null,
    });
};

const resetMySurveyFail = (state, action) => {
    return updateState(state, {
        answerLoading: false,
        error: action.error,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MY_SURVEYS_START:
            return getMySurveysStart(state);
        case actionTypes.GET_MY_SURVEYS_SUCCESS:
            return getMySurveysSuccess(state, action);
        case actionTypes.GET_MY_SURVEYS_FAIL:
            return getMySurveysFail(state, action);
        case actionTypes.MY_SURVEYS_SELECT_ROLE_START:
            return selectRoleStart(state);
        case actionTypes.MY_SURVEYS_SELECT_ROLE_SUCCESS:
            return selectRoleSuccess(state, action);
        case actionTypes.MY_SURVEYS_SELECT_ROLE_FAIL:
            return selectRoleFail(state, action);
        case actionTypes.GET_SURVEY_QUESTION_START:
            return getSurveyQuestionStart(state);
        case actionTypes.GET_SURVEY_QUESTION_SUCCESS:
            return getSurveyQuestionSuccess(state, action);
        case actionTypes.GET_SURVEY_QUESTION_FAIL:
            return getSurveyQuestionFail(state, action);
        case actionTypes.ANSWER_QUESTION_START:
            return answerQuestionStart(state);
        case actionTypes.ANSWER_QUESTION_SUCCESS:
            return answerQuestionSuccess(state, action);
        case actionTypes.ANSWER_QUESTION_FAIL:
            return answerQuestionFail(state, action);
        case actionTypes.SKIP_QUESTION_START:
            return skipQuestionStart(state);
        case actionTypes.SKIP_QUESTION_SUCCESS:
            return skipQuestionSuccess(state, action);
        case actionTypes.SKIP_QUESTION_FAIL:
            return skipQuestionFail(state, action);
        case actionTypes.COMPLETE_MY_SURVEYS_START:
            return completeMySurveyStart(state);
        case actionTypes.COMPLETE_MY_SURVEYS_SUCCESS:
            return completeMySurveySuccess(state);
        case actionTypes.COMPLETE_MY_SURVEYS_FAIL:
            return completeMySurveyFail(state, action);

        case actionTypes.RESET_MY_SURVEYS_START:
            return resetMySurveyStart(state);
        case actionTypes.RESET_MY_SURVEYS_SUCCESS:
            return resetMySurveySuccess(state);
        case actionTypes.RESET_MY_SURVEYS_FAIL:
            return resetMySurveyFail(state, action);
        default:
            return state;
    }
};

export default reducer;
