import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class RegisterReasonAPI {
    static getRegisterReasons() {
        return axios
            .get(`/registrationReasons`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }
}

export default RegisterReasonAPI;