import * as actionTypes from "../actions/adminActionTypes";

import { updateState } from "../../utility";

const initialState = {
    emailTemplateTypes: [],
    emailTemplate: null,
    error: null,
    loading: false,
    isUpdating: false,
};

const getEmailTemplateTypesStart = (state) => {
    return updateState(state, { error: null, emailTemplateTypes: [], loading: true });
};

const getEmailTemplateTypesSuccess = (state, action) => {
    return updateState(state, {
        emailTemplateTypes: action.emailTemplateTypes,
        error: null,
        loading: false,
    });
};

const getEmailTemplateTypesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getEmailTemplateByTypeStart = (state) => {
    return updateState(state, { error: null, emailTemplate: null, loading: true });
};

const getEmailTemplateByTypeSuccess = (state, action) => {
    return updateState(state, {
        emailTemplate: action.emailTemplate,
        error: null,
        loading: false,
    });
};

const getEmailTemplateByTypeFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const createUpdateEmailTemplateStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const createUpdateEmailTemplateSuccess = (state, action) => {
    return updateState(state, {
        emailTemplate: action.emailTemplate,
        error: null,
        isUpdating: false,
    });
};

const createUpdateEmailTemplateFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EMAIL_TEMPLATE_TYPES_START:
            return getEmailTemplateTypesStart(state);
        case actionTypes.GET_EMAIL_TEMPLATE_TYPES_SUCCESS:
            return getEmailTemplateTypesSuccess(state, action);
        case actionTypes.GET_EMAIL_TEMPLATE_TYPES_FAIL:
            return getEmailTemplateTypesFail(state, action);

        case actionTypes.GET_EMAIL_TEMPLATE_BY_TYPE_START:
            return getEmailTemplateByTypeStart(state);
        case actionTypes.GET_EMAIL_TEMPLATE_BY_TYPE_SUCCESS:
            return getEmailTemplateByTypeSuccess(state, action);
        case actionTypes.GET_EMAIL_TEMPLATE_BY_TYPE_FAIL:
            return getEmailTemplateByTypeFail(state, action);

        case actionTypes.CREATE_UPDATE_EMAIL_TEMPLATE_START:
            return createUpdateEmailTemplateStart(state);
        case actionTypes.CREATE_UPDATE_EMAIL_TEMPLATE_SUCCESS:
            return createUpdateEmailTemplateSuccess(state, action);
        case actionTypes.CREATE_UPDATE_EMAIL_TEMPLATE_FAIL:
            return createUpdateEmailTemplateFail(state, action);

        default:
            return state;
    }
};

export default reducer;
