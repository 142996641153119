const errorResponseHandler = err => {
    const ErrorResponse = {};
    if (err.response) {
      if (err.response.data.ErrorType) {
        ErrorResponse.errData = {};
        ErrorResponse.errData.ErrorType = err.response.data.ErrorType;
        ErrorResponse.errData.Message = err.response.data.Message;
        ErrorResponse.errStatus = err.response.data.ErrorType;
      } else {
        ErrorResponse.errData = err.response.data;
        ErrorResponse.errStatus = err.response.status + ": " + err.response.statusText;
      }
    } else {
      ErrorResponse.errData = {ErrorType: "Error", Message: "ERR_FAILED"};
    }
    return ErrorResponse;
  };
  
  export default errorResponseHandler;
  