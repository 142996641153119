import * as jobActions from "../../../store/actions/jobAction";
import * as adminPersonActions from "../../../store/admin/actions/adminPersonActions";
import { faCheckCircle, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Input, Popover, PopoverBody, PopoverHeader, Row, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

class JobAssignmentCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popOverUpdateAssignmentOpen: false,
            jobs: [],
            jobMatchType: this.props.jobMatchType,
            jobMatch: this.props.jobMatch,
            jobMatchId: this.props.jobMatchId,
            jobReference: this.props.jobReference,
            newJobMatchId: this.props.jobMatchId,
            dirty: false,
            inProgress: false,
        };
    }

    componentDidMount() {
        const jobMatchType = this.props.jobMatchType;
        const person = this.props.person;

        const jobs =
            this.props.jobs &&
            this.props.jobs
                .filter((it) => (jobMatchType == "Current" || jobMatchType == "Future" ? (it.isHidden == false && (person.isCandidate == null || person.isCandidate == false)) || (it.isCandidateJob == true && person.isCandidate) : true))
                .map((it) => {
                    return { value: it.id, label: it.name };
                })
                .sort((a, b) => (`${a.label}` > `${b.label}` ? 1 : -1));

        this.setState({ jobs: [{ label: "None", value: null }, ...jobs] });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.jobs !== this.props.jobs && this.props.jobs) {
            const jobMatchType = this.props.jobMatchType;
            const person = this.props.person;

            const jobs =
                this.props.jobs &&
                this.props.jobs
                    .filter((it) => (jobMatchType == "Current" || jobMatchType == "Future" ? (it.isHidden == false && (person.isCandidate == null || person.isCandidate == false)) || (it.isCandidateJob == true && person.isCandidate) : true))
                    .map((it) => {
                        return { value: it.id, label: it.name };
                    })
                    .sort((a, b) => (`${a.label}` > `${b.label}` ? 1 : -1));

            this.setState({ jobs: [{ label: "None", value: null }, ...jobs] });
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    togglePopover = () => {
        const popOverUpdateAssignmentOpen = this.state.popOverUpdateAssignmentOpen;
        const jobMatchId = this.state.jobMatchId;

        this.setState({ popOverUpdateAssignmentOpen: !popOverUpdateAssignmentOpen, dirty: false, inProgress: false, newJobMatchId: jobMatchId });
    };

    openUpdateAssignment = () => {
        const popOverUpdateAssignmentOpen = this.state.popOverUpdateAssignmentOpen;
        this.setState({ popOverUpdateAssignmentOpen: !popOverUpdateAssignmentOpen });
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({ newJobMatchId: value && value != "None" ? value : null, dirty: true });
    };

    handleUpdateJobMatch = () => {
        const { newJobMatchId, jobMatchType, jobs } = this.state;

        this.setState({ inProgress: true });
        this.props.onJob.assignJobMatchPerson(this.props.person.personId, newJobMatchId, jobMatchType).then(() => {
            if (!this.props.isUpdating && !this.props.error) {
                const job = jobs.find((it) => it.value == newJobMatchId);
                if (job) {
                    this.setState({ jobMatch: job.label, jobMatchId: job.value, jobReference: job.reference });
                }

                this.setState({ popOverUpdateAssignmentOpen: false });
            }

            this.setState({ inProgress: this.props.isUpdating });
        });
    };

    render() {
        const person = this.props.person;
        const { jobMatch, jobMatchType, jobMatchId, jobReference, jobs, newJobMatchId } = this.state;

        return (
            <React.Fragment>
                <div style={{ padding: "5px 0px" }}>
                    {this.props.allowEdit ? (
                        <Link to="#" id={`LKRequirementAssigned${jobMatchType}${person.personId}`} onClick={() => this.openUpdateAssignment()}>
                            {jobMatchId ? jobMatch : "None"}
                        </Link>
                    ) : (
                        <span>{jobMatchId ? jobMatch : "None"}</span>
                    )}
                    {jobReference && jobReference != "" && (
                        <React.Fragment>
                            <br />
                            {jobReference}
                        </React.Fragment>
                    )}
                    {this.props.allowEdit && (
                        <React.Fragment>
                            <UncontrolledTooltip target={`LKRequirementAssigned${jobMatchType}${person.personId}`} placement="bottom">
                                Update Requirement Assigned
                            </UncontrolledTooltip>
                            <Popover placement="bottom" isOpen={this.state.popOverUpdateAssignmentOpen} target={`LKRequirementAssigned${jobMatchType}${person.personId}`} toggle={this.togglePopover}>
                                <PopoverHeader>
                                    <Row>
                                        <Col sm="7">Update roles</Col>
                                        <Col sm="5">
                                            <button className={this.state.dirty ? "btn-primary" : "btn-secondary"} disabled={!this.state.dirty || this.state.inProgress} onClick={this.handleUpdateJobMatch}>
                                                <FontAwesomeIcon icon={faCheckCircle} />
                                            </button>
                                            &nbsp;
                                            <button className={`btn-danger`} onClick={this.togglePopover} disabled={this.state.inProgress}>
                                                <FontAwesomeIcon icon={faCancel} />
                                            </button>
                                        </Col>
                                    </Row>
                                </PopoverHeader>
                                <PopoverBody>
                                    <Row>
                                        <Col sm="12">
                                            <Input type="select" name="job" id="inputjob" value={newJobMatchId} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                {jobs.map((job, key) => (
                                                    <option key={key} value={job.value}>
                                                        {job.label}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                    </Row>
                                </PopoverBody>
                            </Popover>
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isUpdating: state.job.isUpdating,
        error: state.job.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPerson: bindActionCreators(adminPersonActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobAssignmentCell);
