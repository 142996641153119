import { dismisAlert, generateAlert } from "../../../src/utils/alertUtils";
import * as contentActions from "../../store/actions/contentAction";
import * as authActions from "../../store/actions/authAction";
import * as jobActions from "../../store/actions/jobAction";
import { contentTranslator } from "../../utils/translatorUtils";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import "./../../custom-my-people.css";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Spinner, Table } from "reactstrap";
import { bindActionCreators } from "redux";

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "22px",
    fontFamily: "IBMPlexSans-Bold",
};

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

class Requirements extends React.Component {
    static displayName = Requirements.name;

    constructor(props) {
        super(props);
        this.connection = null;
        this.state = {
            loading: false,
            contentLoading: true,
            jobs: [],
            alerts: [],
        };

        this.initData = this.initData.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        if (this.props.language) {
            this.props.onContent.getContentTranslations(this.props.language.id, "SfiaAll-Menu-SfiaProfileMenu-SfiaManage").then(() => {
                this.setState({ contentLoading: this.props.contentLoading });

                this.props.onAuth.authCheckState().then(() => {
                    this.setState({ loading: this.props.authLoading });
                    this.initData();
                });
            });
        } else {
            this.props.onAuth.authCheckState().then(() => {
                this.setState({ loading: this.props.authLoading });
                this.initData();
            });
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    initData() {
        this.setState({ loading: true });
        this.props.onJob.getOwnedJobs().then(() => {
            if (!this.props.loading) {
                const jobs = this.props.jobs ? this.props.jobs.sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1)) : [];
                this.setState({
                    jobs,
                });
            }
            this.setState({ loading: this.props.loading });
        });
    }

    render() {
        const { jobs } = this.state;

        return (
            <Container className="contentMain">
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading || this.state.contentLoading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <ContentWrapperLg>
                        <p className={"page-title"} style={headerStyle}>
                            Manage Requirements
                        </p>
                        <Table>
                            <thead>
                                <tr>
                                    <th className="tblHeaderStyle" style={{ width: "60%" }}>
                                        Requirement Name
                                    </th>
                                    <th className="tblHeaderStyle" style={{ width: "40%" }}>
                                        Unique Reference
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.loading && (
                                    <tr>
                                        <td colSpan={2} style={{ textAlign: "center" }}>
                                            <Spinner size="lg" animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                )}
                                {jobs.map((job, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="tblRowStyle">
                                                <Link to={`requirements/${job.id}/profile`} target="ownedjobprofile">{job.name}</Link>
                                            </td>
                                            <td className="tblRowStyle">{job.reference}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </ContentWrapperLg>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language.language,
        contentTranslations: state.content.contentTranslations,
        contentLoading: state.content.loading,
        contentError: state.content.error,

        authLoading: state.auth.loading,

        loading: state.job.loading,
        error: state.job.error,
        jobs: state.job.jobs,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
        onContent: bindActionCreators(contentActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Requirements);
