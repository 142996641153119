import LinkComponent from "../LinkComponent";
import { Modal, Empty, Typography, Select } from "antd";
import React, { useMemo, useState } from "react";
import dayjs from "dayjs"

const styles = {
  emptyContainer: {
    height: "40vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
};

const { Text } = Typography;

const HistoryModal = ({ open, onOk, onCancel, history }) => {
  const [filter, setFilter] = useState('all')

  const dataToRender = useMemo(() => {
    if (Array.isArray(history)) {
      const rowData = history.reverse()
      switch (filter) {
        case "all":
          return rowData;
        case "active":
          return rowData.filter((item) => dayjs().isBefore(dayjs(item.expireOn)))
        case "inactive":
          return rowData.filter((item) => dayjs().isAfter(dayjs(item.expireOn)))
        default:
          return rowData;
      }
    }
  }, [filter, history]);
  return (
    <Modal
      open={open}
      onOk={onOk}
      footer={null}
      onCancel={onCancel}
      style={{ top: 20 }}
      title="Shared badges history"
    >
      {history && history.length !== 0 ? (
        <>
          <div style={styles.infoContainer}>
            <Text strong>{dataToRender.length} Link shared</Text>
            <Select
              defaultValue="all"
              style={{
                width: 120,
              }}
              onChange={setFilter}
              options={[
                {
                  value: "all",
                  label: "All",
                },
                {
                  value: "active",
                  label: "Active only",
                },
                {
                  value: "inactive",
                  label: "Inactive only",
                }
              ]}
            />
          </div>
          {dataToRender?.map((item) => (
            <LinkComponent
              key={`${item.id}`}
              email={item.email}
              url={item.url}
              hasStatus
              isActive={dayjs().isBefore(dayjs(item.expireOn))}
              expireOn={item.expireOn.substring(0,10)}
            />
          ))}
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>No shared links yet!</span>}
          style={styles.emptyContainer}
        />
      )}
    </Modal>
  );
};

export default HistoryModal;
