import * as adminCategoryActions from "../../../store/admin/actions/adminCategoryActions";
import changeInput from "../../../utils/changeInput";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";

class ExtraFrameworkLevelDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDialog: false,
            isUpdating: false,
            form: {
                level: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
            },
            isFormValid: false,
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
        }

        if (prevProps.extraFrameworkLevel !== this.props.extraFrameworkLevel) {
            this.setState({
                extraFrameworkLevel: this.props.extraFrameworkLevel,
                form: {
                    level: {
                        validation: {
                            required: true,
                        },
                        value: this.props.extraFrameworkLevel ? this.props.extraFrameworkLevel.level : "",
                        valid: this.props.extraFrameworkLevel ? true : false,
                        isValidFormat: this.props.extraFrameworkLevel ? true : false,
                        touched: false,
                    },
                },
                isFormValid: this.props.extraFrameworkLevel ? true : false,
            });
        }
    }

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({
            showDialog: !showDialog,
            form: {
                level: {
                    validation: {
                        required: true,
                    },
                    value: this.props.extraFrameworkLevel ? this.props.extraFrameworkLevel.level : "",
                    valid: this.props.extraFrameworkLevel ? true : false,
                    isValidFormat: this.props.extraFrameworkLevel ? true : false,
                    touched: false,
                },
            },
            isFormValid: this.props.extraFrameworkLevel ? true : false,
        });
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handlePerformSave = () => {
        const form = this.state.form;
        const extraFrameworkLevel = {
            level: form.level.value,
        };

        this.setState({ isUpdating: true });

        if (this.props.extraFrameworkLevel && this.props.extraFrameworkLevel.id) {
            this.props.onAdminCategory.updateExtraFrameworkLevel(this.props.extraFrameworkLevel.id, extraFrameworkLevel).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.error) {
                        this.props.generateAlert("success", "Update level success.");
                        this.togleDialogModal();
                    } else {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        } else {
            this.props.onAdminCategory.createExtraFrameworkLevel(extraFrameworkLevel).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.error) {
                        this.props.generateAlert("success", "Add level success.");
                        this.togleDialogModal();
                    } else {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    render() {
        const { form } = this.state;
        const { extraFrameworkLevel } = this.props;

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={this.togleDialogModal}>
                    <ModalHeader toggle={this.togleDialogModal}>{extraFrameworkLevel && extraFrameworkLevel.id ? "Update Level" : "Add Level"}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Level</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="level" id="level" value={form.level.value} invalid={form.level.touched && !form.level.isValidFormat} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.handlePerformSave();
                            }}
                            disabled={!this.state.isFormValid}
                        >
                            Save
                        </Button>
                        <Button color="secondary" onClick={this.togleDialogModal} disabled={this.state.isImporting}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.adminCategory.error,
        isUpdating: state.adminCategory.isUpdating,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAdminCategory: bindActionCreators(adminCategoryActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraFrameworkLevelDetail);
