import * as authActions from "../store/actions/authAction";

import { Col, Row, } from "reactstrap";

import ContentTranslator from "./translator/ContentTranslator";
import { ContentWrapper } from "./common/ContentWrapper";
import { Layout } from "./Layout";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { contentTranslator } from "../utils/translatorUtils";

const titleStyle = {
    fontFamily: 'IBMPlexSans-Bold',
    fontSize: '38px',
    align: 'left',
}

const descStyle = {
    fontFamily: 'IBMPlexSans-Light',
    fontSize: '14px',
    align: 'left'
}

class ResetPasswordConfirmation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
        };
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        this.props.onAuth.authLogout().then(() => {
        });
    }

    render() {
        return (
            <Layout language={this.props.language} setCurrentLanguage={this.props.setCurrentLanguage}>
                <ContentWrapper>
                    <Row md="12">
                        <Col>
                            <p className="color-dark" style={titleStyle}><ContentTranslator page="ForgotPassword" name="ResetPasswordConfirmation" contentText="Reset Password Confirmation" /></p>
                            <p className="color-dark" style={descStyle}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        this.contentTranslator(this.props.contentTranslations, "ForgotPassword", "ResetPasswordMessage", "We have sent a reset link to your email address. Please check your inbox ASAP because the reset link expires after 24 hours.<br />NOTE : Reset links will ONLY be sent to recognised email addresses.")
                                }}></p>
                        </Col>
                    </Row>
                </ContentWrapper>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        contentTranslations: state.content.contentTranslations,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordConfirmation);
