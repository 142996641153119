import * as actionTypes from "../actions/adminActionTypes";

import { updateState } from "../../utility";

const initialState = {
    error: null,
    loading: false,
    isUpdating: false,
    brandingLogoFound: false,
};

const getCompanyBrandingLogoStart = (state) => {
    return updateState(state, { error: null, loading: true, brandingLogoFound: false });
};

const getCompanyBrandingLogoSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        loading: false,
        brandingLogoFound: true,
    });
};

const getCompanyBrandingLogoFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const updateCompanyBrandingLogoStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateCompanyBrandingLogoSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        isUpdating: false,
    });
};

const updateCompanyBrandingLogoFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ADMIN_COMPANY_BRANDING_LOGO_START:
            return getCompanyBrandingLogoStart(state);
        case actionTypes.GET_ADMIN_COMPANY_BRANDING_LOGO_SUCCESS:
            return getCompanyBrandingLogoSuccess(state, action);
        case actionTypes.GET_ADMIN_COMPANY_BRANDING_LOGO_FAIL:
            return getCompanyBrandingLogoFail(state, action);
            
        case actionTypes.UPDATE_COMPANY_BRANDING_LOGO_START:
            return updateCompanyBrandingLogoStart(state);
        case actionTypes.UPDATE_COMPANY_BRANDING_LOGO_SUCCESS:
            return updateCompanyBrandingLogoSuccess(state, action);
        case actionTypes.UPDATE_COMPANY_BRANDING_LOGO_FAIL:
            return updateCompanyBrandingLogoFail(state, action);

        default:
            return state;
    }
};

export default reducer;
