import * as actionTypes from "../actions/adminActionTypes";

import { updateState } from "../../utility";

const initialState = {
    relationshipTypes: [],
    error: null,
    loading: false,
};

const getRelationshipTypesStart = (state) => {
    return updateState(state, { error: null, relationshipTypes: [], loading: true });
};

const getRelationshipTypesSuccess = (state, action) => {
    return updateState(state, {
        relationshipTypes: action.relationshipTypes,
        error: null,
        loading: false,
    });
};

const getRelationshipTypesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_RELATIONSHIP_TYPES_START:
            return getRelationshipTypesStart(state);
        case actionTypes.GET_RELATIONSHIP_TYPES_SUCCESS:
            return getRelationshipTypesSuccess(state, action);
        case actionTypes.GET_RELATIONSHIP_TYPES_FAIL:
            return getRelationshipTypesFail(state, action);

        default:
            return state;
    }
};

export default reducer;
