import { Empty, Divider } from "antd";
import { Chart } from "chart.js";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const constantData = {
  chartType: "pie",
  colors: {
    background: [
      "#E3BDD6",
      "#F989A2",
      "#FACA5F",
      "#e0c2a5",
      "#99dbf4",
      "#99dbb9",
      "#fae8a1",
    ],
  },
};

const DivCenter = ({ Component }) => {
  return (
    <div className="p-grid p-align-center vertical-container">
      <div className="layout-wrapper">
        <Component />
      </div>
    </div>
  );
};
const Wrapper = styled.div`
  .color-t {
    color: #969696;
  }
`;

function PieChart({
  source: { data = [], labels = [], counts = [] } = {},
  title,
}) {
  const canvas = useRef(null);

  // Refactored data for the chart using destructuring for more concise code.

  const dataChart = {
    labels: [...labels],
    datasets: [
      {
        counts: [...counts],
        data: [...data],
        backgroundColor: constantData.colors.background,
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };

  // Centralized configuration for the chart. Static values are directly set
  // and values that depend on props are computed inline.
  const configChart = {
    type: constantData.chartType,
    data: dataChart,
    options: {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 5 / 4,
      plugins: {
        datalabels: {
          formatter: (value, context) => `${value}%`,
        },
        legend: {
          position: "bottom",
        },
        tooltip: {
          callbacks: {
            label: (ctx) =>
              ` value : ${ctx.dataset.counts[ctx.datasetIndex]}, percent : ${
                ctx.formattedValue
              }%`,
            title: (ctx) => ctx[0].label,
          },
        },
      },
    },
  };

  // Ensure the chart is only re-rendered when the data source changes.
  useEffect(() => {
    let chartInstance = null;

    if (data?.length > 0) {
      chartInstance = new Chart(canvas.current, configChart);
    }

    // Cleanup the previous chart instance to prevent memory leaks
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [data]); // Dependency array updated to re-render the chart when data changes

  // Conditionally render the canvas if data is present, otherwise display a centered 'No Data' message
  if (data?.length < 1) {
    return <DivCenter Component={() => <Empty description={"No Data"} />} />;
  }

  return (
    <Wrapper>
      <div className=" text-center color-t">{title ?? "-"}</div>
      <Divider />
      <canvas ref={canvas} />
    </Wrapper>
  );
}

export default PieChart;
