import * as actionTypes from "../actions/actionTypes";
import { updateState } from "../utility";

const initialState = {
    lors: [],
    error: null,
    loading: false,
    isUpdating: false,
};

const getLorsStart = (state) => {
    return updateState(state, { error: null, lors: [], loading: true });
};

const getLorsSuccess = (state, action) => {
    return updateState(state, {
        lors: action.lors,
        error: null,
        loading: false,
    });
};

const getLorsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const updateLorStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateLorSuccess = (state, action) => {
    let lors = state.lors;

    const updatedLor = lors.find((x) => x.id == action.lor.id);
    if (updatedLor) {
        updatedLor.category = action.lor.category;
        updatedLor.categoryColor = action.lor.categoryColor;
        updatedLor.lorColor = action.lor.lorColor;
    }

    return updateState(state, {
        lors: [...lors],
        error: null,
        isUpdating: false,
    });
};

const updateLorFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateLorSetIsHiddenStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateLorSetIsHiddenSuccess = (state, action) => {
    const lors = state.lors;

    const isHidden = action.isHidden;

    const lor = lors.find((it) => it.id == action.id);
    
    if (lor) {
        lor.isHidden = isHidden;
    }

    return updateState(state, {
        lors: [...lors],
        error: null,
        isUpdating: false,
    });
};

const updateLorSetIsHiddenFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_LORS_START:
            return getLorsStart(state);
        case actionTypes.GET_LORS_SUCCESS:
            return getLorsSuccess(state, action);
        case actionTypes.GET_LORS_FAIL:
            return getLorsFail(state, action);

        case actionTypes.UPDATE_LOR_START:
            return updateLorStart(state);
        case actionTypes.UPDATE_LOR_SUCCESS:
            return updateLorSuccess(state, action);
        case actionTypes.UPDATE_LOR_FAIL:
            return updateLorFail(state, action);

        case actionTypes.UPDATE_LOR_SET_ISHIDDEN_START:
            return updateLorSetIsHiddenStart(state);
        case actionTypes.UPDATE_LOR_SET_ISHIDDEN_SUCCESS:
            return updateLorSetIsHiddenSuccess(state, action);
        case actionTypes.UPDATE_LOR_SET_ISHIDDEN_FAIL:
            return updateLorSetIsHiddenFail(state, action);

        default:
            return state;
    }
};

export default reducer;
