import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class ValueGroupAPI {
    static getValuesByGroupName(groupName, languageId) {
        return axios
            .get(`/valueGroups/getvalues?groupName=${groupName}${languageId ? `&languageId=${languageId}` : ""}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default ValueGroupAPI;
