import { Table, Typography, Empty, Checkbox, Tag, Row } from "antd";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { useImmer } from "use-immer";

const constantData = {
  titleLevel: 5,
  pageSize: 10,
  rangeColors: [
    {
      color: "green",
      label: "80 - 100",
      isMatch: (val) => val >= 80,
    },
    {
      color: "cyan",
      label: "60 - 79",
      isMatch: (val) => val >= 60,
    },
    {
      color: "gold",
      label: "40 - 59",
      isMatch: (val) => val >= 40,
    },
    {
      color: "orange",
      label: "20 - 39",
      isMatch: (val) => val >= 20,
    },
    {
      color: "volcano",
      label: "1 - 19",
      isMatch: (val) => val >= 0,
    },
    {
      color: "red",
      label: "0",
      isMatch: (val) => val === 0,
    },
  ],
};

const DivCenter = ({ Component, title }) => {
  return (
    <div
      className="p-grid p-align-center vertical-container"
      style={{ marginBottom: "40px" }}
    >
      <div className="layout-wrapper">
        <Title level={constantData.titleLevel}>{title}</Title>
        <Component />
      </div>
    </div>
  );
};

const { Title } = Typography;

const WrapperTable = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;

  .font-i {
    color: #000;
  }

  .ant-table-container table > thead > tr:first-child th {
    text-align: center;
  }

  .ant-table-container table tr .ant-table-cell-row-hover {
    background: rgb(116, 226, 177, 0.2) !important;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 14px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 14px;
  }

  .ant-table-content > table {
    table-layout: auto;
    border-color: gainsboro;
    border-style: solid;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    border-width: 0.5px;
  }

  .mr-10 {
    margin-right: 10px;
  }
  .grid {
    display: grid;
  }
  .anticon {
    vertical-align: 1px !important;
  }

  @media (max-width: 1680px) {
    .cell-cm {
      width: 150px;
    }
  }

  @media (max-width: 1580px) {
    .cell-cm {
      width: 100px;
    }
  }

  @media (max-width: 1480px) {
    .cell-cm {
      width: 60px;
    }
  }
  @media (max-width: 1280px) {
    .cell-cm {
      width: auto;
    }
  }
`;

const WrapperSubCell = styled.div`
  width: auto;
  text-wrap: wrap;
`;

const ContainerTable = styled.div`
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;

  .circle {
    border-radius: 10px;
    width: 10px;
    height: 10px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .pd-10 {
    padding: 10px;
  }

  .flex {
    display: flex;
  }

  .font-sm {
    font-size: small;
  }

  .item-center {
    align-items: center;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    width: 125px;
  }
`;

const createCenteredTextColumnHeader = (title) => (
  <span className="font-i" style={{ textWrap: "nowrap" }}>
    {title}
  </span>
);

const createCenteredCheckboxColumnHeader = (func, stateCheckbox) => (
  <>
    <Checkbox
      indeterminate={stateCheckbox.indeterminate}
      checked={stateCheckbox.isChecked}
      onChange={(e) => func(e, true, undefined)}
    />
  </>
);

// Helper function to generate a table column that centers its text content.
// This avoids repetition for multiple columns that require similar formatting.
const createCenteredArrayColumn = (title, dataIndex, sorterFunction) => ({
  title: createCenteredTextColumnHeader(title), // Column header
  dataIndex, // Data key
  key: dataIndex, // Key for React's list rendering
  sorter: (a, b) => sorterFunction(a[dataIndex], b[dataIndex]), // Sorting function for the column
  render: (text) => (
    <WrapperSubCell className="text-center">
      {text?.join(", ") ?? "-"}
    </WrapperSubCell>
  ), // Centering the text
});

// Helper function to generate a table column that centers its text content.
// This avoids repetition for multiple columns that require similar formatting.
const createCenteredArrayTagColumn = (title, dataIndex, sorterFunction) => ({
  title: createCenteredTextColumnHeader(title), // Column header
  dataIndex, // Data key
  key: dataIndex, // Key for React's list rendering
  sorter: (a, b) => sorterFunction(a[dataIndex], b[dataIndex]), // Sorting function for the column
  render: (text) => (
    <WrapperSubCell className="text-center">
      {text?.length > 0
        ? text.map((item, index) => (
            <Tag className="mb-1" key={index}>
              {item}
            </Tag>
          ))
        : "-"}
    </WrapperSubCell>
  ), // Centering the text
});

const createCenteredNumberColumn = (
  title,
  dataIndex,
  sorterFunction,
  isResponsive = false,
  isCenter = true
) => {
  const getColor = (val) =>
    constantData.rangeColors.find((item) => item.isMatch(val))?.color;

  return {
    title: createCenteredTextColumnHeader(title), // Column header
    dataIndex, // Data key
    key: dataIndex, // Key for React's list rendering
    sorter: (a, b) => sorterFunction(a[dataIndex], b[dataIndex]), // Sorting function for the column
    render: (text) => (
      <WrapperSubCell
        className={`${isCenter && "text-center"} ${isResponsive && "cell-cm"}`}
      >
        {text !== undefined && text !== null ? (
          <Tag color={getColor(text)}>{text}%</Tag>
        ) : (
          "-"
        )}
      </WrapperSubCell>
    ), // Centering the text
  };
};

// Helper function to generate a table column that centers its text content.
// This avoids repetition for multiple columns that require similar formatting.
const createCenteredTextColumn = (title, dataIndex, sorterFunction) => ({
  title: createCenteredTextColumnHeader(title), // Column header
  dataIndex, // Data key
  key: dataIndex, // Key for React's list rendering
  sorter: (a, b) => sorterFunction(a[dataIndex], b[dataIndex]), // Sorting function for the column
  render: (text) => (
    <WrapperSubCell className="font-i">{text ?? "-"}</WrapperSubCell>
  ), // Centering the text
});

const createCenteredCheckboxColumn = (
  dataIndex,
  func,
  stateCheckbox,
  selectable
) => {
  const onRender = (text, record) => {
    const findCheck = stateCheckbox.list.find(
      (item) => item.key === record.key
    );

    return (
      <WrapperSubCell className="text-center ">
        <Checkbox
          checked={findCheck?.isChecked}
          onChange={(e) => func(e, false, record)}
        />
      </WrapperSubCell>
    );
  };
  return {
    title: selectable
      ? createCenteredCheckboxColumnHeader(func, stateCheckbox)
      : null, // Column header
    dataIndex, // Data key
    key: dataIndex, // Key for React's list rendering
    render: (text, record) => onRender(text, record),
  };
};

// Utility function to compare text values array
const sortByTextArray = (a, b) => {
  // Determine the minimum length between the two current arrays
  const minLength = Math.min(a.length, b.length);

  // Iterate over the elements up to the minimum length
  for (let i = 0; i < minLength; i++) {
    // Only proceed to string conversion and comparison if elements are different
    // This saves performance by avoiding unnecessary operations
    if (a[i] !== b[i]) {
      // Convert current elements to strings and make them lowercase
      // for case-insensitive comparison
      const elemA = a[i].toString().toLowerCase();
      const elemB = b[i].toString().toLowerCase();

      // Compare the elements and determine their order
      if (elemA < elemB) return -1; // b comes before a
      if (elemA > elemB) return 1; // b comes after a
    }
  }

  // If all compared elements are equal, then compare array lengths
  // The shorter array comes first
  return a.length - b.length;
};

// Utility function to compare text values
const sortByText = (a, b) => (a ?? "").localeCompare(b ?? "");

// Utility function to compare number values
const sortByNumber = (a, b) => (a < b ? -1 : a > b ? 1 : 0);

// Utility function to transform data source for tables
// This maps over the data and transforms it to have keys and all the item properties.
const transformDataSource = (data) =>
  data.map((item, index) => ({
    key: `${index + 1}`,
    ...item,
  }));

// Utility function to generate column definitions based on type and need status
// This will return the appropriate columns based on whether it's a professional or generic skill and if it's a needed skill.
const getColumnDefinitions = (func, stateCheckbox, selectable, contentTranslations, contentTranslator) => {
  const tempColum = [
    createCenteredCheckboxColumn("select", func, stateCheckbox, selectable),
    createCenteredTextColumn(contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorTableName", "Name"), "name", sortByText),
    createCenteredTextColumn(contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorTableEmail", "Email"), "email", sortByText),
    // createCenteredTextColumn("Relationship", "currentRelationship", sortByText),
    createCenteredNumberColumn(contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorTableMatch", "Match %"), "matchedSkillPercent", sortByNumber),
    createCenteredArrayTagColumn(contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorTableSkills", "Skills"), "skills", sortByTextArray),
    createCenteredArrayColumn(contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorTablePreferences", "Preferences"), "preferences", sortByTextArray),
  ];
  return tempColum;
};

const PossibleMentorTable = (props) => {
  const {
    refData = [],
    values = [],
    pageSize = constantData.pageSize,
    nameSelect = "",
    selectable = false,
    onChange = undefined,
    contentTranslations,
    contentTranslator
  } = props;

  const [stateCheckbox, setStateCheckbox] = useImmer({
    indeterminate: false,
    isChecked: false,
    list: [],
  });
  /**
   * Handles change events from a checkbox input, updating the component state accordingly.
   *
   * @param {Object} e - the event object.
   * @param {boolean} isHeader - flag indicating whether the checkbox is a header checkbox.
   * @param {Object} record - an object representing additional data, used here to match against list items.
   */
  const onCheckboxChanged = (e, isHeader, record) => {
    // Create a deep copy of the 'stage' object to ensure immutability.
    // Direct mutation of the state is avoided to ensure reliable UI updates.
    const newStage = JSON.parse(JSON.stringify(stateCheckbox));

    if (isHeader) {
      // If the changed checkbox is the header checkbox, we'll update all list items' 'isChecked' property
      // and set the header's 'isChecked' property to match the new state.
      newStage.list.forEach((item) => {
        item.isChecked = e.target.checked;
      });
      newStage.isChecked = e.target.checked;
      // 'indeterminate' is set to false because the header checkbox has explicit true/false state.
      newStage.indeterminate = false;
    } else {
      // If the changed checkbox is not the header, we find the matching item in the list by key
      // and update its 'isChecked' property.
      const find = newStage.list.find((item) => item.key === record.key);
      if (find) {
        find.isChecked = e.target.checked;
      }

      // Check the checkbox state of all list items to determine the collective checkbox state.
      const allChecked = newStage.list.every((item) => item.isChecked);
      const noneChecked = newStage.list.every((item) => !item.isChecked);

      // Update the 'isChecked' property of the header checkbox based on whether all list items are checked.
      newStage.isChecked = allChecked;
      // The 'indeterminate' property is true when not all and not none of the list items are checked,
      // otherwise, it's false.
      newStage.indeterminate = !allChecked && !noneChecked;
    }
    setStateCheckbox((draft) => {
      // Update the original state with the modified values from the new state.
      draft.list = newStage.list;
      draft.isChecked = newStage.isChecked;
      draft.indeterminate = newStage.indeterminate;
    });

    if (onChange != undefined) {
      onChange(newStage.list);
    }
  };

  // useMemo will recompute the memoized value (`tableData`) only when one of the dependencies
  // in the dependency array changes. This optimization helps avoid expensive calculations on
  // every render when the relevant data hasn't changed.

  const tableData = useMemo(() => {
    // Early return when content is not loaded; avoids processing empty or undefined data.
    if (!refData?.length) return { dataSource: [], columns: [] };

    // Call `transformDataSource` to process the raw data into the desired structure/format
    // for use by the table. This might include converting dates to a specific format,
    // reshaping objects, adding additional fields, etc.
    const dataSource = transformDataSource(refData);

    // Retrieve column definitions, which could depend on various factors such as user role,
    // specific permissions, feature flags, etc. This function is also responsible for
    // integrating interactive elements like checkboxes into the columns, setting up the
    // appropriate handlers, and defining how they interact with the component's state.
    const columns = getColumnDefinitions(
      onCheckboxChanged, // Handler function for checkbox state changes
      stateCheckbox, // Current state of the checkboxes
      selectable, // Current state of the selectable
      contentTranslations,
      contentTranslator
    );

    // The useMemo hook returns an object containing the processed `dataSource` and `columns`,
    // ready for consumption by a table component (e.g., in a <Table dataSource={...} columns={...} /> setup).
    return { dataSource, columns };

    // Dependency array: the memoized value will only recompute if `data`, `stateCheckbox`, or
    // are computationally expensive or if the data volume is large.
  }, [refData, stateCheckbox]);

  // This useEffect is responsible for handling the side-effect when the content is not yet loaded.
  // It updates the state by marking the content as loaded and sets the initial expanded subcategories.
  useEffect(() => {
    // Generate an array of expanded subcategories from the data
    const tempList = transformDataSource(refData).map((item) => ({
      ...item,
      isChecked: item[nameSelect],
    }));

    // Check the checkbox state of all list items to determine the collective checkbox state.
    const allChecked = tempList.every((item) => item.isChecked);
    const noneChecked = tempList.every((item) => !item.isChecked);

    // Update the 'isChecked' property of the header checkbox based on whether all list items are checked.
    const tempIsChecked = allChecked;
    // The 'indeterminate' property is true when not all and not none of the list items are checked,
    // otherwise, it's false.
    const tempIndeterminate = !allChecked && !noneChecked;

    // Update the listExpand state by appending the new expanded subcategories.
    // We use a functional update to safely access and update the previous state.
    setStateCheckbox((draft) => {
      // Update the original state with the modified values from the new state.
      if (values.length > 0) {
        draft.list = values;
      } else {
        draft.list = tempList;
      }
      draft.isChecked = tempIsChecked;
      draft.indeterminate = tempIndeterminate;
    });
  }, [refData]); // The effect depends on the data and the refData flag.

  // Create a copy of the listExpand array

  return (
    // Container for the entire table component
    <ContainerTable>
      {refData.length > 0 ? (
        // Conditionally render the table or a 'No Data' message based on the length of the 'data' array
        // If 'data' has elements, render the table and associated components
        <div>
          {/* Wrapper for styling and layout of the table */}
          <WrapperTable>
            <Table
              dataSource={tableData.dataSource}
              columns={tableData.columns}
              pagination={{ pageSize, hideOnSinglePage: true }}
              scroll={{ x: true }}
            />
            {tableData.dataSource.length > 0 && (
              <Row className="mt-4">
                {constantData.rangeColors.map((item, index) => {
                  return (
                    <div className="text-center item-center pd-10" key={index}>
                      <span className="font-sm ">{contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanFindMentorRange", "Range")}:</span>
                      <Tag className="ml-10" color={item.color}>
                        {item.label}
                      </Tag>
                    </div>
                  );
                })}
              </Row>
            )}
          </WrapperTable>
        </div>
      ) : (
        <DivCenter Component={() => <Empty description={"No Data"} />} />
      )}
    </ContainerTable>
  );
};

export default PossibleMentorTable;
