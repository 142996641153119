import TeamAnalyticsService from "../../../../common/api/service/TeamAnalyticsService";
import PieChart from "../../components/charts/pie";
import ActionPlanFilters from "../../components/filters/actionplan";
import ActionPlanTable from "../../components/table/actionplan";
import { Col, Row, DatePicker, Typography } from "antd";
import { groupBy } from "lodash";
import moment from "moment";
import React, { useState, useEffect, useMemo } from "react";
import { Spinner } from "reactstrap";
import styled from "styled-components";
import { useImmer } from "use-immer";

const { RangePicker } = DatePicker;

const { Title } = Typography;

const Container = styled.div`
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ContainerBorder = styled.div`
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #dddddd;
  border-radius: 14px;
`;

const ContainerBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 35px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);

  backdrop-filter: blur(15px);

  .pl-10 {
    padding-left: 10px;
  }
  .pr-10 {
    padding-right: 10px;
  }

  .mt-30 {
    margin-top: 20px;
  }

  .mb-30 {
    margin-bottom: 40px;
  }
`;

const DivCenter = ({ Component }) => {
  return (
    <div className="p-grid p-align-center vertical-container">
      <div className="layout-wrapper">
        <div className="p-grid">
          <div
            className="p-col-12"
            style={{ position: "fixed", top: "50%", left: "50%" }}
          >
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * A styled column component to reduce the repetition of classes and props.
 * This component encapsulates the common style properties of columns.
 */
const ColumnWithStyle = ({ children, span }) => (
  <Col className="pl-10 pr-10" span={span || 8}>
    {children}
  </Col>
);

// Constant Data
const constantData = {
  fullName: "fullName",
  pageSize: 5,
  actionType: "actionType",
  actionStatus: "actionStatus",
  actionProvider: "actionProvider",
};
// Returns a new array containing only the unique elements from the provided `dataArray` based on a custom comparison function.
const distinctArray = (dataArray, func) => {
  // `seen` will store a unique "hash" for each item we've already encountered.
  const seen = new Set();

  // The array to accumulate unique items from `dataArray`.
  const uniqueArray = [];

  dataArray.forEach((item) => {
    // Create a unique "hash" for the current item based on the comparator function.
    // This "hash" is simply a string representation of all items considered equal to the current item.
    const hash = uniqueArray.some((itemIn) => func(item, itemIn));

    // If we haven't seen this unique "hash" before, add the item to our unique array.
    if (!hash) {
      seen.add(hash);
      uniqueArray.push(item);
    }
  });

  return uniqueArray;
};

const ActionPlan = () => {
  // data
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [dataOptions, setDataOptions] = useImmer({
    actionName: [],
    skillCode: [],
  });

  // selection
  const [selectedSkillCode, setSelectedSkillCode] = useState([]);
  const [selectedActionName, setSelectedActionName] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedTargetCompletionDate, setSelectedTargetCompletionDate] =
    useState([]);

  const processData = (value) => {
    return value.map((item) => ({
      ...item,
      actionStatus: item.status.replace(/([a-z])([A-Z])/g, "$1 $2"),
      actionProvider: item.interventionProviderName ?? "Empty",
    }));
  };

  /**
   * Fetch and process all the necessary data.
   *
   * The function:
   * 1. Fetches action plan data from the service.
   * 2. Sets the fetched data to the component's state.
   * 3. Handles errors gracefully and logs them.
   */
  const processAllData = async () => {
    try {
      // Fetch the action plan data from the service
      const actionPlanData = await TeamAnalyticsService.getActionPlan();
      // Set the fetched data to the component's state
      setDataOptions((item) => {
        item.actionName = actionPlanData?.options?.optionActionName ?? [];
        item.skillCode = actionPlanData?.options?.optionSkillCode ?? [];
      });
      const tempData = processData(actionPlanData?.list);
      // Set the fetched data to the component's state
      setData(tempData);
    } catch (error) {
      // Log the error if fetching fails
      console.error("Failed to fetch action plan data:", error);

      // Optionally, you can set an error state here if you'd like to display an error message to users.
    }
  };

  const generatePie = (val, name) => {
    // Group the dataFilter by the actionType for pie chart segmentation
    const dataGroup = groupBy(val, name);

    // Extract labels directly from the grouped data keys
    const labels = Object.keys(dataGroup);

    // Calculate the pie chart data values (percentages) for each label
    // We derive the percentage by dividing the count of each group by the total dataFilter length
    const data = labels.map((label) =>
      ((dataGroup[label].length / dataFilter.length) * 100).toFixed(1)
    );

    const counts = labels.map((label) => dataGroup[label].length);

    // Return the structured pie chart data
    return {
      data, // Percentage values for pie chart segments
      labels, // Labels for the pie chart segments
      counts,
    };
  };

  // Filter by Person
  const optionsName = useMemo(() => {
    if (data?.length === 0) return [];

    // Deduplicate the combined array based on the fullName.
    const uniqueSkills = distinctArray(
      data,
      (itemA, itemB) => itemA.fullName === itemB.fullName
    );

    const groupedData = groupBy(uniqueSkills, constantData.fullName);

    return Object.keys(groupedData)
      .map((item) => ({
        label: item,
        value: item,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [data]);

  // Filter by Action Name
  const optionsActionName = useMemo(() => {
    if (dataOptions.actionName.length === 0) return [];

    return dataOptions.actionName
      .map((item) => ({
        label: item,
        value: item,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [dataOptions.actionName]);

  // Filter by Skill Code
  const optionsSkillCode = useMemo(() => {
    if (dataOptions.skillCode.length === 0) return [];

    return dataOptions.skillCode
      .map((item) => ({
        label: item,
        value: item,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [dataOptions.skillCode]);

  // Data Filter => Ref By Data
  const dataFilter = useMemo(() => {
    let resultData = [];

    if (data.length) {
      resultData = data.filter((item) => {
        // If selectedTargetCompletionDate is populated and the condition is not met, filter out
        if (selectedTargetCompletionDate.length > 0) {
          const isBetween = moment(item.targetCompletionOn).isBetween(
            selectedTargetCompletionDate[0],
            selectedTargetCompletionDate[1]
          );
          if (isBetween === false) {
            return false;
          }
        }

        // If selectedName is populated and the condition is not met, filter out
        if (selectedName.length > 0 && !selectedName.includes(item.fullName)) {
          return false;
        }

        // If selectedSkillCode is populated and the condition is not met, filter out
        if (
          selectedSkillCode.length > 0 &&
          !selectedSkillCode.includes(item.skillCode)
        ) {
          return false;
        }

        // If selectedActionName is populated and the condition is not met, filter out
        if (
          selectedActionName.length > 0 &&
          !selectedActionName.includes(item.actionName)
        ) {
          return false;
        }

        // If all conditions are met or not applicable, include the item
        return true;
      });
    }
    return resultData;
  }, [
    data,
    selectedName,
    selectedSkillCode,
    selectedActionName,
    selectedTargetCompletionDate,
  ]);

  const dashboardTable = useMemo(() => dataFilter ?? [], [dataFilter]); // Recompute only if dataFilter changes

  const dashboardActionTypePie = useMemo(() => {
    // If dataFilter is empty, we return an empty array
    if (!dataFilter.length) return { data: [], labels: [] };
    // Return the structured pie chart data
    return generatePie(dataFilter, constantData.actionType);
  }, [dataFilter]); // This useMemo will only recompute when dataFilter changes

  const dashboardActionStatusPie = useMemo(() => {
    // If dataFilter is empty, we return an empty array
    if (!dataFilter.length) return { data: [], labels: [] };
    // Return the structured pie chart data
    return generatePie(dataFilter, constantData.actionStatus);
  }, [dataFilter]); // This useMemo will only recompute when dataFilter changes

  const dashboardActionProviderPie = useMemo(() => {
    // If dataFilter is empty, we return an empty array
    if (!dataFilter.length) return { data: [], labels: [] };
    // Return the structured pie chart data
    return generatePie(dataFilter, constantData.actionProvider);
  }, [dataFilter]); // This useMemo will only recompute when dataFilter changes

  // This effect runs when the component mounts.
  useEffect(() => {
    // This variable helps us ensure that we don't call `setIsContentLoaded` if the component has unmounted before our asynchronous operation completes.
    let isMounted = true;

    // Fetch and process all data
    processAllData().then(() => {
      if (isMounted) {
        // Only set the loading state to false if the component is still mounted
        setIsContentLoaded(true);
      }
    });

    // The cleanup function for the effect
    return () => {
      // If the component unmounts, we set isMounted to false to prevent any state updates after unmounting.
      isMounted = false;
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts and never again.

  // If content is still loading, display a spinner.
  if (!isContentLoaded) {
    return <DivCenter Component={() => <Spinner />} />;
  }

  // Main content of the component.
  return (
    <Container>
      {/* Action filters */}
      <ActionPlanFilters
        objName={{
          options: optionsName,
          value: selectedName,
          setValue: setSelectedName,
        }}
        objSkillCode={{
          options: optionsSkillCode,
          value: selectedSkillCode,
          setValue: setSelectedSkillCode,
        }}
        objActions={{
          options: optionsActionName,
          value: selectedActionName,
          setValue: setSelectedActionName,
        }}
      />

      <ContainerBar>
        {/* Date picker section */}
        <Row>
          <Col className="pl-10 pr-10 text-center" span={24}>
            <Title level={4}>Choose Target Completion Date</Title>
          </Col>
          <Col className="pl-10 mt-30 mb-30 pr-10 text-center" span={24}>
            <RangePicker
              value={selectedTargetCompletionDate}
              onChange={(val) => setSelectedTargetCompletionDate(val ?? [])}
            />
          </Col>
        </Row>

        {/* Pie charts displaying various data */}
        <Row>
          <ColumnWithStyle>
            <PieChart
              source={dashboardActionTypePie}
              title="Count Of Action Type"
            />
          </ColumnWithStyle>
          <ColumnWithStyle>
            <PieChart
              source={dashboardActionStatusPie}
              title="Count Of Action Status"
            />
          </ColumnWithStyle>
          <ColumnWithStyle>
            <PieChart
              source={dashboardActionProviderPie}
              title="Count Of Action Provider"
            />
          </ColumnWithStyle>
        </Row>
      </ContainerBar>

      {/* Actions table */}
      <ContainerBorder>
        <ActionPlanTable
          pageSize={constantData.pageSize}
          data={dashboardTable}
        ></ActionPlanTable>
      </ContainerBorder>
    </Container>
  );
};

export { ActionPlan };
