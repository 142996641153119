import * as adminPersonActions from "../../../store/admin/actions/adminPersonActions";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
class GenerateUserProfileReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDialog: false,
            person: null,
            boolSkillProfile: false,
            boolActionPlan: false,
            boolEndorsedProfile: false,
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
        }

        if (prevProps.person !== this.props.person && this.props.person) {
            this.setState({ person: this.props.person, boolSkillProfile: false, boolActionPlan: false, boolEndorsedProfile: false });
        }
    }

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({ showDialog: !showDialog, selectedFiles: [] });
    };

    handleChangeCheckBox = (option) => {
        const { boolSkillProfile, boolActionPlan, boolEndorsedProfile } = this.state;

        if (option == "boolSkillProfile") {
            this.setState({ boolSkillProfile: !boolSkillProfile });
        }

        if (option == "boolActionPlan") {
            this.setState({ boolActionPlan: !boolActionPlan });
        }

        if (option == "boolEndorsedProfile") {
            this.setState({ boolEndorsedProfile: !boolEndorsedProfile });
        }
    };

    handlePerformGenerateReports = () => {
        const { boolSkillProfile, boolActionPlan, boolEndorsedProfile, person } = this.state;

        this.setState({ loadingPrint: true });
        this.props.onPerson.generatePersonProfileReports(person.id, boolSkillProfile, boolActionPlan, boolEndorsedProfile).then(() => {
            if (!this.props.loadingPrint) {
                if (this.props.printData) {
                    FileDownload(this.props.printData, `${person.firstName}${person.lastName}_SkillsTx_Person_Profiles.zip`);
                    this.setState({ boolSkillProfile: false, boolActionPlan: false, boolEndorsedProfile: false, showDialog: false });
                }
            }

            this.setState({ loadingPrint: this.props.loadingPrint });
        });
    };

    render() {
        const { boolSkillProfile, boolActionPlan, boolEndorsedProfile, person } = this.state;

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={!this.state.loadingPrint && this.togleDialogModal}>
                    <ModalHeader toggle={!this.state.loadingPrint && this.togleDialogModal}>Generate profile reports</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Col sm={12}>
                                            <Input type="checkbox" name="boolSkillProfile" id="boolSkillProfile" checked={boolSkillProfile} style={{ marginRight: "10px" }} disabled={this.state.loadingPrint} onChange={() => this.handleChangeCheckBox("boolSkillProfile")} />
                                            <Label for="boolSkillProfile" check>
                                                Self Assessed Profile Report
                                            </Label>
                                        </Col>
                                    </FormGroup>
                                    {person && person.hasAction && (
                                        <FormGroup row>
                                            <Col sm={12}>
                                                <Input type="checkbox" name="boolActionPlan" id="boolActionPlan" checked={boolActionPlan} style={{ marginRight: "10px" }} disabled={this.state.loadingPrint} onChange={() => this.handleChangeCheckBox("boolActionPlan")} />
                                                <Label for="boolActionPlan" check>
                                                    Action Plan Report
                                                </Label>
                                            </Col>
                                        </FormGroup>
                                    )}
                                    {person && person.hasEndorseProfile && (
                                        <FormGroup row>
                                            <Col sm={12}>
                                                <Input type="checkbox" name="boolEndorsedProfile" id="boolEndorsedProfile" checked={boolEndorsedProfile} style={{ marginRight: "10px" }} disabled={this.state.loadingPrint} onChange={() => this.handleChangeCheckBox("boolEndorsedProfile")} />
                                                <Label for="boolEndorsedProfile" check>
                                                    Endorsed Profile Report
                                                </Label>
                                            </Col>
                                        </FormGroup>
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.handlePerformGenerateReports();
                            }}
                            disabled={!(boolSkillProfile || boolActionPlan || boolEndorsedProfile) || this.state.loadingPrint}
                        >
                            Generate Reports
                        </Button>
                        <Button color="secondary" onClick={this.togleDialogModal} disabled={this.state.loadingPrint}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        personsError: state.adminPerson.error,
        loadingPrint: state.adminPerson.loadingPrint,
        printData: state.adminPerson.printData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPerson: bindActionCreators(adminPersonActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateUserProfileReports);
