import * as authActions from "../../../store/actions/authAction";
import { dismisAlert, generateAlert } from "./../../../utils/alertUtils";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { bindActionCreators } from "redux";

const apiHostname = process.env.REACT_APP_API_ENDPOINT;

class ShowPowerBIReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            showBIReport: this.props.showBIReport,
            reportId: this.props.reportId,
            embedUrl: this.props.embedUrl,
            accessToken: this.props.accessToken,
            height: 0,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps) {
        if (this.props.showBIReport !== prevProps.showBIReport) {
            this.setState({ showBIReport: this.props.showBIReport });
        }

        if (this.props.reportId !== prevProps.reportId) {
            this.setState({ reportId: this.props.reportId, embedUrl: this.props.embedUrl, accessToken: this.props.accessToken });
        }
    }

    updateWindowDimensions() {
        this.setState({ height: window.innerHeight - 100 });
    }

    toggleDialogModal = () => {
        const showBIReport = this.state.showBIReport;
        this.setState({ showBIReport: !showBIReport });
        this.props.closeReport(false);
    };

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.state.showBIReport} size="full" modalClassName="fullscreen-modal">
                    <ModalHeader toggle={this.toggleDialogModal}>Power BI Report</ModalHeader>
                    <ModalBody>
                        {this.state.showBIReport && (
                            <React.Fragment>
                                <div style={{ height: `${this.state.height}px` }}>
                                    <PowerBIEmbed
                                        embedConfig={{
                                            type: "report", // Supported types: report, dashboard, tile, visual and qna
                                            id: this.state.reportId ? this.state.reportId : null,
                                            embedUrl: this.state.embedUrl ? this.state.embedUrl : null,
                                            accessToken: this.state.accessToken ? this.state.accessToken : null,
                                            tokenType: models.TokenType.Embed,
                                            settings: {
                                                panes: {
                                                    filters: {
                                                        expanded: false,
                                                        visible: false,
                                                    },
                                                },
                                                background: models.BackgroundType.Transparent,
                                            },
                                        }}
                                        eventHandlers={
                                            new Map([
                                                [
                                                    "loaded",
                                                    function () {
                                                        console.log("Report loaded");
                                                    },
                                                ],
                                                [
                                                    "rendered",
                                                    function () {
                                                        console.log("Report rendered");
                                                    },
                                                ],
                                                [
                                                    "error",
                                                    function (event) {
                                                        console.log(event.detail);
                                                    },
                                                ],
                                            ])
                                        }
                                        cssClassName={"powerbi-report-style-class"}
                                        getEmbeddedComponent={(embeddedReport) => {
                                            this.report = embeddedReport;
                                        }}
                                    />
                                </div>
                            </React.Fragment>
                        )}
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        token: state.auth.token,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowPowerBIReport);
