import * as authActions from "../store/actions/authAction";
import { updateState } from "../store/utility";
import { dismisAlert, generateAlert } from "../utils/alertUtils";
import changeInput from "../utils/changeInput";
import { Layout } from "./Layout";
import { ContentWrapper } from "./common/ContentWrapper";
import ContentTranslator from "./translator/ContentTranslator";
import moment from "moment/min/moment-with-locales";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const titleStyle = {
    fontFamily: "IBMPlexSans-Bold",
    fontSize: "38px",
    align: "left",
};

const descStyle = {
    fontFamily: "IBMPlexSans-Light",
    fontSize: "14px",
    align: "left",
};

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            tokenValid: null,
            alerts: [],
            resetPassword: null,
            form: {
                email: {
                    validation: {
                        required: true,
                        isEmail: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                password: {
                    validation: {
                        required: true,
                        minLength: 8,
                        isPassword: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                repassword: {
                    validation: {
                        required: true,
                        minLength: 8,
                        isPassword: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
            },
            isFormValid: false,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        var token = query.get("token");

        if (token) {
            if (this.props.isAuthenticated) {
                this.props.onAuth.authLogout();
            } else {
                this.props.onAuth.getResetPassword(token).then(() => {
                    if (!this.props.loading) {
                        if (!this.props.error) {
                            const resetPassword = this.props.resetPassword;
                            var expiredDate = moment(this.props.resetPassword.expiredDate)._d;
                            const now = new Date();

                            if (resetPassword.inactive || now > expiredDate) {
                                this.setState({ tokenValid: false });
                            } else {
                                this.setState({ tokenValid: true, resetPassword });
                            }
                        } else {
                            this.setState({ tokenValid: false });
                        }
                    }
                    this.setState({ loading: this.props.loading });
                });
            }
        } else {
            this.props.history.push("/login");
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    onConfirmPass = (eventValue, inputIdentifier) => {
        const updateLocalState = changeInput(this.state.form, inputIdentifier, eventValue);

        // Check confirm password value is equal with password
        const isEquals = eventValue === this.state.form.password.value;
        if (!isEquals) {
            updateLocalState.form[inputIdentifier] = updateState(updateLocalState.form[inputIdentifier], {
                valid: false,
            });
        }

        this.setState({
            form: updateLocalState.form,
            isFormValid: updateLocalState.valid && isEquals,
        });
    };

    submitForm = (event) => {
        event.preventDefault();
        this.setState({ error: null, loading: true });

        const token = this.state.resetPassword.token;
        const email = this.state.form.email.value;
        const password = this.state.form.password.value;

        const body = {
            token,
            email,
            password,
        };

        this.props.onAuth.processResetPassword(body).then(() => {
            if (!this.props.loading) {
                if (this.props.error) {
                    this.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    this.props.generateAlert("success", "Your password has been updated");
                    this.props.history.push("/login");
                }
            }
            this.setState({ loading: this.props.loading });
        });
    };

    render() {
        const { form } = this.state;

        return (
            <Layout language={this.props.language} setCurrentLanguage={this.props.setCurrentLanguage}>
                <ContentWrapper>
                    <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                    {this.state.loading && this.state.tokenValid == null ? (
                        <center>
                            <Spinner color="dark" />
                        </center>
                    ) : (
                        <React.Fragment>
                            {!this.state.tokenValid ? (
                                <Row xs="1" md="2">
                                    <Col>
                                        <p className="color-dark" style={titleStyle}>
                                            <ContentTranslator page="ResetPassword" name="ResetPasswordInvalidToken" contentText="Invalid Token" />
                                        </p>
                                        <p className="color-dark" style={descStyle}>
                                            <ContentTranslator page="ResetPassword" name="ResetPasswordInvalidTokenMessage" contentText="Your reset password link is invalid or expired." />
                                        </p>
                                    </Col>
                                </Row>
                            ) : (
                                <Row xs="1" md="2">
                                    <Col>
                                        <p className="color-dark" style={titleStyle}>
                                            <ContentTranslator page="ResetPassword" name="ResetPasswordTitle" contentText="Reset Password" />
                                        </p>
                                        <p className="color-dark" style={descStyle}>
                                            <ContentTranslator page="ResetPassword" name="ResetPasswordMessage" contentText="Enter the details to reset your password." />
                                        </p>
                                    </Col>
                                    <Col>
                                        <Form onSubmit={this.submitForm}>
                                            <FormGroup>
                                                <Label className="font-weight-bold" for="lblEmail">
                                                    <ContentTranslator page="ResetPassword" name="ResetPasswordEmail" contentText="Email" />
                                                </Label>
                                                <Input type="email" name="email" id="inputEmail" value={form.email.value} invalid={form.email.touched && (!form.email.valid || !form.email.isValidFormat)} onChange={this.handleChange} />
                                                {form.email.value != "" && !form.email.isValidFormat && (
                                                    <FormFeedback>
                                                        <ContentTranslator page="ResetPassword" name="ResetPasswordEmailIncorrect" contentText="Incorrect email format" />
                                                    </FormFeedback>
                                                )}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label className="font-weight-bold" for="lblPassword">
                                                    <ContentTranslator page="ResetPassword" name="ResetPasswordPassword" contentText="Password" />
                                                </Label>
                                                <Input type="password" name="password" id="inputPassword" value={form.password.value} invalid={form.password.touched && (!form.password.valid || !form.password.isValidFormat)} onChange={this.handleChange} />
                                                {form.password.touched && (!form.password.valid || !form.password.isValidFormat) && (
                                                    <Label style={{ color: "#dc3545" }}>
                                                        {form.password.value.length >= form.password.validation.minLength ? (
                                                            <ContentTranslator page="ResetPassword" name="ResetPasswordAllowedPasswordNotification" contentText="Enter a combination of at least 8 characters alphanumeric, uppercase, lowercase, and punctuation marks (such as ! and &)" />
                                                        ) : (
                                                            <ContentTranslator page="ResetPassword" name="ResetPasswordMinLengthNotification" contentText="Field min length 8 character" />
                                                        )}
                                                    </Label>
                                                )}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label className="font-weight-bold" for="lblPassword">
                                                    <ContentTranslator page="ResetPassword" name="ResetPasswordConfirmPassword" contentText="Confirm Password" />
                                                </Label>
                                                <Input
                                                    type="password"
                                                    name="repassword"
                                                    id="inputRePassword"
                                                    value={form.repassword.value}
                                                    invalid={form.repassword.touched && (!form.repassword.valid || !form.repassword.isValidFormat)}
                                                    onChange={(e) => this.onConfirmPass(e.target.value, "repassword")}
                                                />
                                            </FormGroup>
                                            <Button disabled={!this.state.isFormValid || this.state.loading} block color="primary" style={{ height: "55px", fontFamily: "IBMPlexSans-Bold" }}>
                                                {!this.state.loading ? <ContentTranslator page="ResetPassword" name="ResetPasswordButtonSubmit" contentText="Submit" /> : <Spinner type="grow" size="md" color="light" />}
                                            </Button>
                                        </Form>
                                    </Col>
                                </Row>
                            )}
                        </React.Fragment>
                    )}
                </ContentWrapper>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loadingResetPassword,
        error: state.auth.errorResetPassword,
        resetPassword: state.auth.resetPassword,
        isAuthenticated: state.auth.token !== null,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
