import * as actionTypes from "../actions/actionTypes";

import { updateState } from "../utility";

const initialState = {
    seniorityOptions: [],
    contractTypeOptions: [],
    yearRangeJobOptions: [],
    yearRangeOrgOptions: [],
};

const getSeniorityOptionsStart = (state) => {
    return updateState(state, { seniorityOptions: [] });
};

const getSeniorityOptionsSuccess = (state, action) => {
    return updateState(state, {
        seniorityOptions: action.values,
    });
};

const getSeniorityOptionsFail = (state) => {
    return updateState(state, {
        seniorityOptions: [],
    });
};

const getContractTypeOptionsStart = (state) => {
    return updateState(state, { contractTypeOptions: [] });
};

const getContractTypeOptionsSuccess = (state, action) => {
    return updateState(state, {
        contractTypeOptions: action.values,
    });
};

const getContractTypeOptionsFail = (state) => {
    return updateState(state, {
        contractTypeOptions: [],
    });
};

const getYearRangeJobOptionsStart = (state) => {
    return updateState(state, { yearRangeJobOptions: [] });
};

const getYearRangeJobOptionsSuccess = (state, action) => {
    return updateState(state, {
        yearRangeJobOptions: action.values,
    });
};

const getYearRangeJobOptionsFail = (state) => {
    return updateState(state, {
        yearRangeJobOptions: [],
    });
};

const getYearRangeOrgOptionsStart = (state) => {
    return updateState(state, { yearRangeOrgOptions: [] });
};

const getYearRangeOrgOptionsSuccess = (state, action) => {
    return updateState(state, {
        yearRangeOrgOptions: action.values,
    });
};

const getYearRangeOrgOptionsFail = (state) => {
    return updateState(state, {
        yearRangeOrgOptions: [],
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SENIORITY_VALUES_START:
            return getSeniorityOptionsStart(state);
        case actionTypes.GET_SENIORITY_VALUES_SUCCESS:
            return getSeniorityOptionsSuccess(state, action);
        case actionTypes.GET_SENIORITY_VALUES_FAIL:
            return getSeniorityOptionsFail(state);
        case actionTypes.GET_CONTRACT_TYPE_VALUES_START:
            return getContractTypeOptionsStart(state);
        case actionTypes.GET_CONTRACT_TYPE_VALUES_SUCCESS:
            return getContractTypeOptionsSuccess(state, action);
        case actionTypes.GET_CONTRACT_TYPE_VALUES_FAIL:
            return getContractTypeOptionsFail(state);
        case actionTypes.GET_YEAR_RANGE_JOB_VALUES_START:
            return getYearRangeJobOptionsStart(state);
        case actionTypes.GET_YEAR_RANGE_JOB_VALUES_SUCCESS:
            return getYearRangeJobOptionsSuccess(state, action);
        case actionTypes.GET_YEAR_RANGE_JOB_VALUES_FAIL:
            return getYearRangeJobOptionsFail(state);
        case actionTypes.GET_YEAR_RANGE_ORG_VALUES_START:
            return getYearRangeOrgOptionsStart(state);
        case actionTypes.GET_YEAR_RANGE_ORG_VALUES_SUCCESS:
            return getYearRangeOrgOptionsSuccess(state, action);
        case actionTypes.GET_YEAR_RANGE_ORG_VALUES_FAIL:
            return getYearRangeOrgOptionsFail(state);
        default:
            return state;
    }
};

export default reducer;
