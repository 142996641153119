import { updateState } from "../../utility";
import * as actionTypes from "../actions/adminActionTypes";

const initialState = {
    error: null,
    loading: false,
    isUpdating: false,
    actionTypes: [],
};

const getActionTypesStart = (state) => {
    return updateState(state, { error: null, loading: true, actionTypes: [] });
};

const getActionTypesSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        loading: false,
        actionTypes: action.actionTypes,
    });
};

const getActionTypesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const addActionTypeStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const addActionTypeSuccess = (state, action) => {
    const actionTypes = state.actionTypes;
    actionTypes.push(action.addedActionType);

    return updateState(state, {
        error: null,
        isUpdating: false,
        actionTypes: [...actionTypes],
    });
};

const addActionTypeFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateActionTypeStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateActionTypeSuccess = (state, action) => {
    const updatedActionType = action.updatedActionType;
    const actionTypes = state.actionTypes;

    const actionType = actionTypes.find((it) => it.id == updatedActionType.id);
    if (actionType) {
        actionType.name = updatedActionType.name;
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        actionTypes: [...actionTypes],
    });
};

const updateActionTypeFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteActionTypeStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteActionTypeSuccess = (state, action) => {
    const deletedActionTypeId = action.deletedActionTypeId;
    const actionTypes = state.actionTypes;

    const actionType = actionTypes.find((it) => it.id == deletedActionTypeId);
    if (actionType) {
        const index = actionTypes.indexOf(actionType);
        if (index !== -1) {
            actionTypes.splice(index, 1);
        }
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        actionTypes: [...actionTypes],
    });
};

const deleteActionTypeFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ACTIONTYPES_START:
            return getActionTypesStart(state);
        case actionTypes.GET_ACTIONTYPES_SUCCESS:
            return getActionTypesSuccess(state, action);
        case actionTypes.GET_ACTIONTYPES_FAIL:
            return getActionTypesFail(state, action);

        case actionTypes.ADD_ACTIONTYPE_START:
            return addActionTypeStart(state);
        case actionTypes.ADD_ACTIONTYPE_SUCCESS:
            return addActionTypeSuccess(state, action);
        case actionTypes.ADD_ACTIONTYPE_FAIL:
            return addActionTypeFail(state, action);

        case actionTypes.UPDATE_ACTIONTYPE_START:
            return updateActionTypeStart(state);
        case actionTypes.UPDATE_ACTIONTYPE_SUCCESS:
            return updateActionTypeSuccess(state, action);
        case actionTypes.UPDATE_ACTIONTYPE_FAIL:
            return updateActionTypeFail(state, action);

        case actionTypes.DELETE_ACTIONTYPE_START:
            return deleteActionTypeStart(state);
        case actionTypes.DELETE_ACTIONTYPE_SUCCESS:
            return deleteActionTypeSuccess(state, action);
        case actionTypes.DELETE_ACTIONTYPE_FAIL:
            return deleteActionTypeFail(state, action);

        default:
            return state;
    }
};

export default reducer;
