import * as actionTypes from "./actionTypes";

import SurveyAPI from "./../../common/api/service/SurveyService";

export const getMySurveysStart = () => {
    return {
        type: actionTypes.GET_MY_SURVEYS_START,
    };
};

export const getMySurveysSuccess = (surveys, surveyRoles) => {
    return {
        type: actionTypes.GET_MY_SURVEYS_SUCCESS,
        surveys,
        surveyRoles
    };
};

export const getMySurveysFail = error => {
    return {
        type: actionTypes.GET_MY_SURVEYS_FAIL,
        error: error,
    };
};

export const getMySurveys = (assessmentId) => {
    return dispatch => {
        dispatch(getMySurveysStart());

        return SurveyAPI.getMySurveys(assessmentId)
            .then(res => {
                if (res.type && res.type == "Role Selection") {
                    dispatch(getMySurveysSuccess([], res.roles));
                } else {
                    dispatch(getMySurveysSuccess(res, []));
                }
            })
            .catch(err => {
                dispatch(getMySurveysFail(err));
            });
    };
};

export const mySurveySelectRoleStart = () => {
    return {
        type: actionTypes.MY_SURVEYS_SELECT_ROLE_START,
    };
};

export const mySurveySelectRoleSuccess = () => {
    return {
        type: actionTypes.MY_SURVEYS_SELECT_ROLE_SUCCESS,
    };
};

export const mySurveySelectRoleFail = error => {
    return {
        type: actionTypes.MY_SURVEYS_SELECT_ROLE_FAIL,
        error: error,
    };
};

export const mySurveySelectRole = (assessmentId, roleId) => {
    return dispatch => {
        dispatch(mySurveySelectRoleStart());

        return SurveyAPI.selectRole(assessmentId, roleId)
            .then(res => {
                dispatch(mySurveySelectRoleSuccess());
            })
            .catch(err => {
                dispatch(mySurveySelectRoleFail(err));
            });
    };
};

export const getSurveyQuestionStart = () => {
    return {
        type: actionTypes.GET_SURVEY_QUESTION_START,
    };
};

export const getSurveyQuestionSuccess = (question) => {
    return {
        type: actionTypes.GET_SURVEY_QUESTION_SUCCESS,
        question
    };
};

export const getSurveyQuestionFail = error => {
    return {
        type: actionTypes.GET_SURVEY_QUESTION_FAIL,
        error: error,
    };
};

export const getSurveyQuestion = (componentGroupId, elementId) => {
    return dispatch => {
        dispatch(getSurveyQuestionStart());

        return SurveyAPI.getSurveyQuestion(componentGroupId, elementId)
            .then(res => {
                dispatch(getSurveyQuestionSuccess(res));
            })
            .catch(err => {
                dispatch(getSurveyQuestionFail(err));
            });
    };
};

export const answerQuestionStart = () => {
    return {
        type: actionTypes.ANSWER_QUESTION_START,
    };
};

export const answerQuestionSuccess = (answerResult) => {
    return {
        type: actionTypes.ANSWER_QUESTION_SUCCESS,
        answerResult
    };
};

export const answerQuestionFail = error => {
    return {
        type: actionTypes.ANSWER_QUESTION_FAIL,
        error: error,
    };
};

export const answerQuestion = (answer) => {
    return dispatch => {
        dispatch(answerQuestionStart());

        return SurveyAPI.answerQuestion(answer)
            .then(res => {
                dispatch(answerQuestionSuccess(res));
            })
            .catch(err => {
                dispatch(answerQuestionFail(err));
            });
    };
};

export const skipQuestionStart = () => {
    return {
        type: actionTypes.SKIP_QUESTION_START,
    };
};

export const skipQuestionSuccess = (answerResult) => {
    return {
        type: actionTypes.SKIP_QUESTION_SUCCESS,
        answerResult
    };
};

export const skipQuestionFail = error => {
    return {
        type: actionTypes.SKIP_QUESTION_FAIL,
        error: error,
    };
};

export const skipQuestion = (answer) => {
    return dispatch => {
        dispatch(skipQuestionStart());

        return SurveyAPI.skipQuestion(answer)
            .then(res => {
                dispatch(skipQuestionSuccess(res));
            })
            .catch(err => {
                dispatch(skipQuestionFail(err));
            });
    };
};

export const completeMySurveyStart = () => {
    return {
        type: actionTypes.COMPLETE_MY_SURVEYS_START,
    };
};

export const completeMySurveySuccess = () => {
    return {
        type: actionTypes.COMPLETE_MY_SURVEYS_SUCCESS,
    };
};

export const completeMySurveyFail = error => {
    return {
        type: actionTypes.COMPLETE_MY_SURVEYS_FAIL,
        error: error,
    };
};

export const completeMySurvey = () => {
    return dispatch => {
        dispatch(completeMySurveyStart());

        return SurveyAPI.completeSurvey()
            .then(res => {
                dispatch(completeMySurveySuccess(res));
            })
            .catch(err => {
                dispatch(completeMySurveyFail(err));
            });
    };
};

export const resetMySurveyStart = () => {
    return {
        type: actionTypes.RESET_MY_SURVEYS_START,
    };
};

export const resetMySurveySuccess = () => {
    return {
        type: actionTypes.RESET_MY_SURVEYS_SUCCESS,
    };
};

export const resetMySurveyFail = error => {
    return {
        type: actionTypes.RESET_MY_SURVEYS_FAIL,
        error: error,
    };
};

export const resetMySurvey = () => {
    return dispatch => {
        dispatch(resetMySurveyStart());

        return SurveyAPI.resetMySurvey()
            .then(res => {
                dispatch(resetMySurveySuccess(res));
            })
            .catch(err => {
                dispatch(resetMySurveyFail(err));
            });
    };
};