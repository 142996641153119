import * as categoryActions from "../../../store/actions/categoryAction";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");

class SkillsHiddenBulkUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDialog: false,
            selectedFile: null,
            isImporting: false,
            importMessage: null,
        };

        this.excelRef = React.createRef();
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
        }
    }

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.excelRef.current.value = null;
        this.setState({ showDialog: !showDialog, selectedFile: null });
    };

    handleSelectFiles = () => {
        this.excelRef.current.click();
    };

    handleSelectExcels = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            this.setState({ selectedFile: event.target.files[0] });
        }
    };

    handlePerformUpload = () => {
        this.setState({ isImporting: true });
        this.props.onCategory.importSkillsHiddenDataExcel(this.state.selectedFile).then(() => {
            if (!this.props.isImporting) {
                if (!this.props.categoryError) {
                    this.excelRef.current.value = null;

                    this.props.generateAlert("success", "Import skills hidden data success.");
                    this.togleDialogModal();
                    this.props.handleImportSuccess();
                } else {
                    this.props.generateAlert("danger", this.props.categoryError.errData.Message);
                }
            }

            this.setState({ isImporting: this.props.isImporting });
        });
    };

    render() {
        const { selectedFile } = this.state;

        return (
            <React.Fragment>
                <input ref={this.excelRef} type="file" style={{ display: "none" }} accept=".xlsx, .xls" multiple={false} onChange={this.handleSelectExcels} />
                <Modal isOpen={this.state.showDialog} toggle={this.togleDialogModal}>
                    <ModalHeader toggle={this.togleDialogModal}>Import Skills Hidden Data</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label sm={12}>
                                            You can bulk upload Skills Hidden Data from an Excel workbook.
                                            <br />
                                            <b>NOTE</b>: The upload will only update matched Valid SFIA Skill Code hidden data value in the workbook. USE WITH CAUTION
                                        </Label>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col sm={12} style={{ textAlign: "center" }}>
                                            <Button
                                                color="success"
                                                onClick={this.handleSelectFiles}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                }}
                                                disabled={this.props.isImporting}
                                            >
                                                Select File
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                    {selectedFile && (
                                        <FormGroup row>
                                            <Col sm={12}>
                                                <Label>File to upload</Label>
                                                <br />
                                                <ListGroup>{selectedFile && <ListGroupItem>{selectedFile.name}</ListGroupItem>}</ListGroup>
                                            </Col>
                                        </FormGroup>
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.handlePerformUpload();
                            }}
                            disabled={!selectedFile || this.state.isImporting}
                        >
                            Process Upload
                        </Button>
                        <Button color="secondary" onClick={this.togleDialogModal} disabled={this.state.isImporting}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        categoryError: state.category.error,
        loadingExport: state.category.loadingExport,
        exportData: state.category.exportData,

        isImporting: state.category.isImporting,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCategory: bindActionCreators(categoryActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsHiddenBulkUpload);
