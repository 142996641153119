import * as actionTypes from "../actions/actionTypes";
import { updateState } from "../utility";

const initialState = {
    profileTypes: [],
    profileVersions: [],
    skillProfiles: [],
    error: null,
    loading: false,
    updating: false,

    lorProfiles: [],
    lorError: null,
    lorLoading: false,

    profileUpdating: false,
    profileUpdatingSuccess: false,
    profileUpdatingError: null,
    udpatedSkills: null,

    endorsedProfileCopying: false,
    endorsedProfileCopyingSuccess: false,
    endorsedProfileCopyingError: null,
    endorsedProfileCopyingSuccessNotif: null,

    copyProfile: false,
    copyProfileError: null,
    copyProfileSuccess: false,

    updateEndorsedStatus: false,
    updateEndorsedStatusError: null,
    updatedPersonEndorsement: null,

    loadingPrint: false,
    printData: null,
};

const getProfileTypesStart = (state) => {
    return updateState(state, { error: null, loading: true });
};

const getProfileTypesSuccess = (state, action) => {
    const profileTypes = action.profileTypes;
    return updateState(state, {
        profileTypes,
        error: null,
        loading: false,
    });
};

const getProfileTypesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getProfileVersionsStart = (state) => {
    return updateState(state, { error: null, loading: true });
};

const getProfileVersionsSuccess = (state, action) => {
    const profileVersions = action.profileVersions;
    return updateState(state, {
        profileVersions,
        error: null,
        loading: false,
    });
};

const getProfileVersionsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getLorProfileStart = (state) => {
    return updateState(state, { lorError: null, lorLoading: true });
};

const getLorProfileSuccess = (state, action) => {
    const lorProfiles = action.lorProfiles;
    return updateState(state, {
        lorProfiles: lorProfiles,
        lorError: null,
        lorLoading: false,
    });
};

const getLorProfileFail = (state, action) => {
    return updateState(state, {
        lorError: action.error,
        lorLoading: false,
    });
};

const getSkillProfileStart = (state) => {
    return updateState(state, { error: null, loading: true });
};

const getSkillProfileSuccess = (state, action) => {
    const skillProfiles = action.skillProfiles;

    return updateState(state, {
        skillProfiles: skillProfiles,
        error: null,
        loading: false,
    });
};

const getSkillProfileFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const printProfileStart = (state) => {
    return updateState(state, { error: null, loadingPrint: true });
};

const printProfileSuccess = (state, action) => {
    return updateState(state, {
        printData: action.printData,
        error: null,
        loadingPrint: false,
    });
};

const printProfileFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingPrint: false,
    });
};

const emailMyProfileReportStart = (state) => {
    return updateState(state, { error: null, loadingPrint: true });
};

const emailMyProfileReportSuccess = (state) => {
    return updateState(state, {
        error: null,
        loadingPrint: false,
    });
};

const emailMyProfileReportFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingPrint: false,
    });
};

const generatePersonalDataExcelStart = (state) => {
    return updateState(state, { error: null, loadingPrint: true });
};

const generatePersonalDataExcelSuccess = (state, action) => {
    return updateState(state, {
        printData: action.printData,
        error: null,
        loadingPrint: false,
    });
};

const generatePersonalDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingPrint: false,
    });
};

const updateMySkillProfileStart = (state) => {
    return updateState(state, { profileUpdatingError: null, profileUpdating: true, profileUpdatingSuccess: false, udpatedSkills: null });
};

const updateMySkillProfileSuccess = (state, action) => {
    return updateState(state, { profileUpdatingError: null, profileUpdating: false, profileUpdatingSuccess: true, udpatedSkills: action.udpatedSkills });
};

const updateMySkillProfileFail = (state, action) => {
    return updateState(state, { profileUpdatingError: action.error, profileUpdating: false });
};

const clearUpdateMySkillProfile = (state) => {
    return updateState(state, { profileUpdatingError: null, profileUpdating: false, profileUpdatingSuccess: false, udpatedSkills: null });
};

const updateEndorsedStatusStart = (state) => {
    return updateState(state, { updateEndorsedStatus: true, updateEndorsedStatusError: null });
};

const updateEndorsedStatusSuccess = (state, action) => {
    return updateState(state, { updateEndorsedStatus: false, updatedPersonEndorsement: action.updatedPersonEndorsement });
};

const updateEndorsedStatusFail = (state, action) => {
    return updateState(state, { updateEndorsedStatus: false, updateEndorsedStatusError: action.error });
};

const copyEndorsedProfileStart = (state) => {
    return updateState(state, { endorsedProfileCopyingError: null, endorsedProfileCopying: true, endorsedProfileCopyingSuccess: false, endorsedProfileCopyingSuccessNotif: null });
};

const copyEndorsedProfileSuccess = (state) => {
    return updateState(state, { endorsedProfileCopyingError: null, endorsedProfileCopying: false, endorsedProfileCopyingSuccess: true, endorsedProfileCopyingSuccessNotif: true });
};

const copyEndorsedProfileFail = (state, action) => {
    return updateState(state, { endorsedProfileCopyingError: action.error, endorsedProfileCopying: false });
};

const clearCopyEndorsedProfileNotif = (state) => {
    return updateState(state, { endorsedProfileCopyingSuccessNotif: null });
};

const copyProfileStart = (state) => {
    return updateState(state, { copyProfileError: null, copyProfile: true, copyProfileSuccess: false, udpatedSkills: null });
};

const copyProfileSuccess = (state, action) => {
    return updateState(state, { copyProfileError: null, copyProfile: false, copyProfileSuccess: true, udpatedSkills: action.udpatedSkills });
};

const copyProfileFail = (state, action) => {
    return updateState(state, { copyProfileError: action.error, copyProfile: false, copyProfileSuccess: false, udpatedSkills: null });
};

const updateSkillProfileCommentStart = (state) => {
    return updateState(state, { error: null, updating: true });
};

const updateSkillProfileCommentSuccess = (state) => {
    return updateState(state, { error: null, updating: false });
};

const updateSkillProfileCommentFail = (state, action) => {
    return updateState(state, { error: action.error, updating: false });
};

const updateLorProfileCommentStart = (state) => {
    return updateState(state, { error: null, updating: true });
};

const updateLorProfileCommentSuccess = (state) => {
    return updateState(state, { error: null, updating: false });
};

const updateLorProfileCommentFail = (state, action) => {
    return updateState(state, { error: action.error, updating: false });
};

const submitUpdateProfileSuggestionStart = (state) => {
    return updateState(state, { error: null, updating: true });
};

const submitUpdateProfileSuggestionSuccess = (state) => {
    return updateState(state, { error: null, updating: false });
};

const submitUpdateProfileSuggestionFail = (state, action) => {
    return updateState(state, { error: action.error, updating: false });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MY_PROFILE_TYPES_START:
        case actionTypes.GET_MANAGED_PEOPLE_PROFILE_TYPES_START:
            return getProfileTypesStart(state);
        case actionTypes.GET_MY_PROFILE_TYPES_SUCCESS:
        case actionTypes.GET_MANAGED_PEOPLE_PROFILE_TYPES_SUCCESS:
            return getProfileTypesSuccess(state, action);
        case actionTypes.GET_MY_PROFILE_TYPES_FAIL:
        case actionTypes.GET_MANAGED_PEOPLE_PROFILE_TYPES_FAIL:
            return getProfileTypesFail(state, action);

        case actionTypes.GET_MY_PROFILE_VERSIONS_START:
        case actionTypes.GET_MANAGED_PEOPLE_PROFILE_VERSIONS_START:
            return getProfileVersionsStart(state);
        case actionTypes.GET_MY_PROFILE_VERSIONS_SUCCESS:
        case actionTypes.GET_MANAGED_PEOPLE_PROFILE_VERSIONS_SUCCESS:
            return getProfileVersionsSuccess(state, action);
        case actionTypes.GET_MY_PROFILE_VERSIONS_FAIL:
        case actionTypes.GET_MANAGED_PEOPLE_PROFILE_VERSIONS_FAIL:
            return getProfileVersionsFail(state, action);

        case actionTypes.GET_MY_LOR_PROFILE_START:
        case actionTypes.GET_MANAGED_PEOPLE_LOR_PROFILE_START:
            return getLorProfileStart(state);
        case actionTypes.GET_MY_LOR_PROFILE_SUCCESS:
        case actionTypes.GET_MANAGED_PEOPLE_LOR_PROFILE_SUCCESS:
            return getLorProfileSuccess(state, action);
        case actionTypes.GET_MY_LOR_PROFILE_FAIL:
        case actionTypes.GET_MANAGED_PEOPLE_LOR_PROFILE_FAIL:
            return getLorProfileFail(state, action);
        case actionTypes.GET_MY_SKILL_PROFILE_START:
        case actionTypes.GET_MANAGED_PEOPLE_SKILL_PROFILE_START:
            return getSkillProfileStart(state);
        case actionTypes.GET_MY_SKILL_PROFILE_SUCCESS:
        case actionTypes.GET_MANAGED_PEOPLE_SKILL_PROFILE_SUCCESS:
            return getSkillProfileSuccess(state, action);
        case actionTypes.GET_MY_SKILL_PROFILE_FAIL:
        case actionTypes.GET_MANAGED_PEOPLE_SKILL_PROFILE_FAIL:
            return getSkillProfileFail(state, action);

        case actionTypes.PRINT_MY_PROFILE_START:
        case actionTypes.PRINT_MANAGED_PEOPLE_PROFILE_START:
            return printProfileStart(state);
        case actionTypes.PRINT_MY_PROFILE_SUCCESS:
        case actionTypes.PRINT_MANAGED_PEOPLE_PROFILE_SUCCESS:
            return printProfileSuccess(state, action);
        case actionTypes.PRINT_MY_PROFILE_FAIL:
        case actionTypes.PRINT_MANAGED_PEOPLE_PROFILE_FAIL:
            return printProfileFail(state, action);

        case actionTypes.EMAIL_MY_PROFILE_REPORT_START:
            return emailMyProfileReportStart(state);
        case actionTypes.EMAIL_MY_PROFILE_REPORT_SUCCESS:
            return emailMyProfileReportSuccess(state);
        case actionTypes.EMAIL_MY_PROFILE_REPORT_FAIL:
            return emailMyProfileReportFail(state, action);

        case actionTypes.GENERATE_PERSONAL_DATA_EXCEL_START:
            return generatePersonalDataExcelStart(state);
        case actionTypes.GENERATE_PERSONAL_DATA_EXCEL_SUCCESS:
            return generatePersonalDataExcelSuccess(state, action);
        case actionTypes.GENERATE_PERSONAL_DATA_EXCEL_FAIL:
            return generatePersonalDataExcelFail(state, action);

        case actionTypes.UPDATE_SKILL_PROFILES_START:
            return updateMySkillProfileStart(state);
        case actionTypes.UPDATE_SKILL_PROFILES_SUCCESS:
            return updateMySkillProfileSuccess(state, action);
        case actionTypes.UPDATE_SKILL_PROFILES_FAIL:
            return updateMySkillProfileFail(state, action);
        case actionTypes.CLEAR_UPDATE_SKILL_PROFILES:
            return clearUpdateMySkillProfile(state);

        case actionTypes.UPDATE_ENDORSED_STATUS_START:
            return updateEndorsedStatusStart(state);
        case actionTypes.UPDATE_ENDORSED_STATUS_SUCCESS:
            return updateEndorsedStatusSuccess(state, action);
        case actionTypes.UPDATE_ENDORSED_STATUS_FAIL:
            return updateEndorsedStatusFail(state, action);

        case actionTypes.COPY_ENDORSED_PROFILES_START:
            return copyEndorsedProfileStart(state);
        case actionTypes.COPY_ENDORSED_PROFILES_SUCCESS:
            return copyEndorsedProfileSuccess(state);
        case actionTypes.COPY_ENDORSED_PROFILES_FAIL:
            return copyEndorsedProfileFail(state, action);
        case actionTypes.CLEAR_COPY_ENDORSED_NOTIF:
            return clearCopyEndorsedProfileNotif(state);

        case actionTypes.COPY_PROFILE_START:
            return copyProfileStart(state);
        case actionTypes.COPY_PROFILE_SUCCESS:
            return copyProfileSuccess(state, action);
        case actionTypes.COPY_PROFILE_FAIL:
            return copyProfileFail(state, action);

        case actionTypes.UPDATE_MY_SKILL_PROFILE_COMMENT_START:
        case actionTypes.UPDATE_MANAGED_PEOPLE_SKILL_PROFILE_COMMENT_START:
            return updateSkillProfileCommentStart(state);
        case actionTypes.UPDATE_MY_SKILL_PROFILE_COMMENT_SUCCESS:
        case actionTypes.UPDATE_MANAGED_PEOPLE_SKILL_PROFILE_COMMENT_SUCCESS:
            return updateSkillProfileCommentSuccess(state);
        case actionTypes.UPDATE_MY_SKILL_PROFILE_COMMENT_FAIL:
        case actionTypes.UPDATE_MANAGED_PEOPLE_SKILL_PROFILE_COMMENT_FAIL:
            return updateSkillProfileCommentFail(state, action);

        case actionTypes.SUBMIT_UPDATE_PROFILE_SUGGESTION_START:
        case actionTypes.ACCEPT_UPDATE_PROFILE_SUGGESTION_START:
        case actionTypes.REJECT_UPDATE_PROFILE_SUGGESTION_START:
            return submitUpdateProfileSuggestionStart(state);
        case actionTypes.SUBMIT_UPDATE_PROFILE_SUGGESTION_SUCCESS:
        case actionTypes.ACCEPT_UPDATE_PROFILE_SUGGESTION_SUCCESS:
        case actionTypes.REJECT_UPDATE_PROFILE_SUGGESTION_SUCCESS:
            return submitUpdateProfileSuggestionSuccess(state);
        case actionTypes.SUBMIT_UPDATE_PROFILE_SUGGESTION_FAIL:
        case actionTypes.ACCEPT_UPDATE_PROFILE_SUGGESTION_FAIL:
        case actionTypes.REJECT_UPDATE_PROFILE_SUGGESTION_FAIL:
            return submitUpdateProfileSuggestionFail(state, action);

        case actionTypes.UPDATE_MY_LOR_PROFILE_COMMENT_START:
        case actionTypes.UPDATE_MANAGED_PEOPLE_LOR_PROFILE_COMMENT_START:
            return updateLorProfileCommentStart(state);
        case actionTypes.UPDATE_MY_LOR_PROFILE_COMMENT_SUCCESS:
        case actionTypes.UPDATE_MANAGED_PEOPLE_LOR_PROFILE_COMMENT_SUCCESS:
            return updateLorProfileCommentSuccess(state);
        case actionTypes.UPDATE_MY_LOR_PROFILE_COMMENT_FAIL:
        case actionTypes.UPDATE_MANAGED_PEOPLE_LOR_PROFILE_COMMENT_FAIL:
            return updateLorProfileCommentFail(state, action);

        default:
            return state;
    }
};

export default reducer;
