import * as actionTypes from "./actionTypes";

import CountryAPI from "./../../common/api/service/CountryService";

export const getCountriesStart = () => {
    return {
        type: actionTypes.GET_COUNTRIES_START,
    };
};

export const getCountriesSuccess = (countries) => {
    return {
        type: actionTypes.GET_COUNTRIES_SUCCESS,
        countries
    };
};

export const getCountriesFail = error => {
    return {
        type: actionTypes.GET_COUNTRIES_FAIL,
        error: error,
    };
};

export const getCountries = () => {
    return dispatch => {
        dispatch(getCountriesStart());

        return CountryAPI.getCountries()
            .then(res => {
                if (res) {
                    dispatch(getCountriesSuccess(res));
                } else {
                    dispatch(getCountriesFail(res));
                }
            })
            .catch(err => {
                dispatch(getCountriesFail(err));
            });
    };
};
