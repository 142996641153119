import AdminInterventionAPI from "./../../../common/api/adminservice/AdminInterventionService";
import * as actionTypes from "./adminActionTypes";

export const getInterventionsStart = () => {
    return {
        type: actionTypes.GET_INTERVENTIONS_START,
    };
};

export const getInterventionsSuccess = (interventions) => {
    return {
        type: actionTypes.GET_INTERVENTIONS_SUCCESS,
        interventions,
    };
};

export const getInterventionsFail = (error) => {
    return {
        type: actionTypes.GET_INTERVENTIONS_FAIL,
        error: error,
    };
};

export const getInterventions = (selectedProviderOnly, includeInterventionJobs, showWorkspaceOwnerActionOnly) => {
    return (dispatch) => {
        dispatch(getInterventionsStart());

        return AdminInterventionAPI.getInterventions(selectedProviderOnly, includeInterventionJobs, showWorkspaceOwnerActionOnly)
            .then((res) => {
                dispatch(getInterventionsSuccess(res));
            })
            .catch((err) => {
                dispatch(getInterventionsFail(err));
            });
    };
};

export const addInterventionStart = () => {
    return {
        type: actionTypes.ADD_INTERVENTION_START,
    };
};

export const addInterventionSuccess = (addedIntervention) => {
    return {
        type: actionTypes.ADD_INTERVENTION_SUCCESS,
        addedIntervention,
    };
};

export const addInterventionFail = (error) => {
    return {
        type: actionTypes.ADD_INTERVENTION_FAIL,
        error: error,
    };
};

export const addIntervention = (intervention) => {
    return (dispatch) => {
        dispatch(addInterventionStart());

        return AdminInterventionAPI.createIntervention(intervention)
            .then((res) => {
                dispatch(addInterventionSuccess(res));
            })
            .catch((err) => {
                dispatch(addInterventionFail(err));
            });
    };
};

export const updateInterventionStart = () => {
    return {
        type: actionTypes.UPDATE_INTERVENTION_START,
    };
};

export const updateInterventionSuccess = (updatedIntervention) => {
    return {
        type: actionTypes.UPDATE_INTERVENTION_SUCCESS,
        updatedIntervention,
    };
};

export const updateInterventionFail = (error) => {
    return {
        type: actionTypes.UPDATE_INTERVENTION_FAIL,
        error: error,
    };
};

export const updateIntervention = (id, intervention) => {
    return (dispatch) => {
        dispatch(updateInterventionStart());

        return AdminInterventionAPI.updateIntervention(id, intervention)
            .then((res) => {
                dispatch(updateInterventionSuccess(res));
            })
            .catch((err) => {
                dispatch(updateInterventionFail(err));
            });
    };
};

export const deleteInterventionStart = () => {
    return {
        type: actionTypes.DELETE_INTERVENTION_START,
    };
};

export const deleteInterventionSuccess = (deletedInterventionId) => {
    return {
        type: actionTypes.DELETE_INTERVENTION_SUCCESS,
        deletedInterventionId,
    };
};

export const deleteInterventionFail = (error) => {
    return {
        type: actionTypes.DELETE_INTERVENTION_FAIL,
        error: error,
    };
};

export const deleteIntervention = (id) => {
    return (dispatch) => {
        dispatch(deleteInterventionStart());

        return AdminInterventionAPI.deleteIntervention(id)
            .then((res) => {
                dispatch(deleteInterventionSuccess(id));
            })
            .catch((err) => {
                dispatch(deleteInterventionFail(err));
            });
    };
};

export const deleteInterventionsStart = () => {
    return {
        type: actionTypes.DELETE_INTERVENTIONS_START,
    };
};

export const deleteInterventionsSuccess = (deletedInterventionIds) => {
    return {
        type: actionTypes.DELETE_INTERVENTIONS_SUCCESS,
        deletedInterventionIds,
    };
};

export const deleteInterventionsFail = (error) => {
    return {
        type: actionTypes.DELETE_INTERVENTIONS_FAIL,
        error: error,
    };
};

export const deleteInterventions = (interventionIds) => {
    return (dispatch) => {
        dispatch(deleteInterventionsStart());

        return AdminInterventionAPI.deleteInterventions(interventionIds)
            .then((res) => {
                dispatch(deleteInterventionsSuccess(interventionIds));
            })
            .catch((err) => {
                dispatch(deleteInterventionsFail(err));
            });
    };
};

export const exportInterventionsDataExcelStart = () => {
    return {
        type: actionTypes.EXPORT_INTERVENTIONS_DATA_START,
    };
};

export const exportInterventionsDataExcelSuccess = (exportData) => {
    return {
        type: actionTypes.EXPORT_INTERVENTIONS_DATA_SUCCESS,
        exportData,
    };
};

export const exportInterventionsDataExcelFail = (error) => {
    return {
        type: actionTypes.EXPORT_INTERVENTIONS_DATA_FAIL,
        error: error,
    };
};

export const exportInterventionsDataExcel = (timezoneOffset) => {
    return (dispatch) => {
        dispatch(exportInterventionsDataExcelStart());

        return AdminInterventionAPI.exportInterventionsDataExcel(timezoneOffset)
            .then((res) => {
                dispatch(exportInterventionsDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportInterventionsDataExcelFail(err));
            });
    };
};

export const exportInterventionsDataExcelTemplate = () => {
    return (dispatch) => {
        dispatch(exportInterventionsDataExcelStart());

        return AdminInterventionAPI.exportInterventionsDataExcelTemplate()
            .then((res) => {
                dispatch(exportInterventionsDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportInterventionsDataExcelFail(err));
            });
    };
};

export const importSkillAttributesDataExcelStart = () => {
    return {
        type: actionTypes.IMPORT_INTERVENTIONS_DATA_START,
    };
};

export const importSkillAttributesDataExcelSuccess = () => {
    return {
        type: actionTypes.IMPORT_INTERVENTIONS_DATA_SUCCESS,
    };
};

export const importSkillAttributesDataExcelFail = (error) => {
    return {
        type: actionTypes.IMPORT_INTERVENTIONS_DATA_FAIL,
        error: error,
    };
};

export const importSkillAttributesDataExcel = (interventionsData) => {
    return (dispatch) => {
        dispatch(importSkillAttributesDataExcelStart());

        return AdminInterventionAPI.importInterventionsDataExcel(interventionsData)
            .then((res) => {
                dispatch(importSkillAttributesDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(importSkillAttributesDataExcelFail(err));
            });
    };
};

export const assignInterventionJobStart = () => {
    return {
        type: actionTypes.ASSIGN_INTERVENTION_JOB_START,
    };
};

export const assignInterventionJobSuccess = (addedInterventionJob) => {
    return {
        type: actionTypes.ASSIGN_INTERVENTION_JOB_SUCCESS,
        addedInterventionJob,
    };
};

export const assignInterventionJobFail = (error) => {
    return {
        type: actionTypes.ASSIGN_INTERVENTION_JOB_FAIL,
        error: error,
    };
};

export const assignInterventionJob = (interventionId, jobId) => {
    return (dispatch) => {
        dispatch(assignInterventionJobStart());

        return AdminInterventionAPI.assignInterventionJob(interventionId, jobId)
            .then((res) => {
                dispatch(assignInterventionJobSuccess(res));
            })
            .catch((err) => {
                dispatch(assignInterventionJobFail(err));
            });
    };
};

export const deleteInterventionJobStart = () => {
    return {
        type: actionTypes.DELETE_INTERVENTION_JOB_START,
    };
};

export const deleteInterventionJobSuccess = (deletedInterventionJobId) => {
    return {
        type: actionTypes.DELETE_INTERVENTION_JOB_SUCCESS,
        deletedInterventionJobId,
    };
};

export const deleteInterventionJobFail = (error) => {
    return {
        type: actionTypes.DELETE_INTERVENTION_JOB_FAIL,
        error: error,
    };
};

export const deleteInterventionJob = (id) => {
    return (dispatch) => {
        dispatch(deleteInterventionJobStart());

        return AdminInterventionAPI.deleteInterventionJob(id)
            .then((res) => {
                dispatch(deleteInterventionJobSuccess(id));
            })
            .catch((err) => {
                dispatch(deleteInterventionJobFail(err));
            });
    };
};

export const deleteInterventionJobsStart = () => {
    return {
        type: actionTypes.DELETE_INTERVENTION_JOBS_START,
    };
};

export const deleteInterventionJobsSuccess = (deletedInterventionJobIds) => {
    return {
        type: actionTypes.DELETE_INTERVENTION_JOBS_SUCCESS,
        deletedInterventionJobIds,
    };
};

export const deleteInterventionJobsFail = (error) => {
    return {
        type: actionTypes.DELETE_INTERVENTION_JOBS_FAIL,
        error: error,
    };
};

export const deleteInterventionJobs = (interventionJobIds) => {
    return (dispatch) => {
        dispatch(deleteInterventionJobsStart());

        return AdminInterventionAPI.deleteInterventionJobs(interventionJobIds)
            .then((res) => {
                dispatch(deleteInterventionJobsSuccess(interventionJobIds));
            })
            .catch((err) => {
                dispatch(deleteInterventionJobsFail(err));
            });
    };
};

export const getNoOfActiveActionPromotionsStart = () => {
    return {
        type: actionTypes.GET_NO_OF_ACTIVE_ACTION_PROMOTIONS_START,
    };
};

export const getNoOfActiveActionPromotionsSuccess = (numberActiveActionPromotions) => {
    return {
        type: actionTypes.GET_NO_OF_ACTIVE_ACTION_PROMOTIONS_SUCCESS,
        numberActiveActionPromotions,
    };
};

export const getNoOfActiveActionPromotionsFail = (error) => {
    return {
        type: actionTypes.GET_NO_OF_ACTIVE_ACTION_PROMOTIONS_FAIL,
        error: error,
    };
};

export const getNoOfActiveActionPromotions = () => {
    return (dispatch) => {
        dispatch(getNoOfActiveActionPromotionsStart());

        return AdminInterventionAPI.getNoOfActiveActionPromotion()
            .then((res) => {
                dispatch(getNoOfActiveActionPromotionsSuccess(res));
            })
            .catch((err) => {
                dispatch(getNoOfActiveActionPromotionsFail(err));
            });
    };
};

export const getActiveActionPromotionsStart = () => {
    return {
        type: actionTypes.GET_ACTION_PROMOTIONS_START,
    };
};

export const getActiveActionPromotionsSuccess = (actionPromotions) => {
    return {
        type: actionTypes.GET_ACTION_PROMOTIONS_SUCCESS,
        actionPromotions,
    };
};

export const getActiveActionPromotionsFail = (error) => {
    return {
        type: actionTypes.GET_ACTION_PROMOTIONS_FAIL,
        error: error,
    };
};

export const getActionPromotions = () => {
    return (dispatch) => {
        dispatch(getActiveActionPromotionsStart());

        return AdminInterventionAPI.getActiveActionPromotions()
            .then((res) => {
                dispatch(getActiveActionPromotionsSuccess(res));
            })
            .catch((err) => {
                dispatch(getActiveActionPromotionsFail(err));
            });
    };
};

export const updateActionPromotionStart = () => {
    return {
        type: actionTypes.UPDATE_ACTION_PROMOTION_START,
    };
};

export const updateActionPromotionSuccess = (updatedActionPromotion) => {
    return {
        type: actionTypes.UPDATE_ACTION_PROMOTION_SUCCESS,
        updatedActionPromotion,
    };
};

export const updateActionPromotionFail = (error) => {
    return {
        type: actionTypes.UPDATE_ACTION_PROMOTION_FAIL,
        error: error,
    };
};

export const updateActionPromotion = (id, actionPromotion) => {
    return (dispatch) => {
        dispatch(updateActionPromotionStart());

        return AdminInterventionAPI.updateActionPromotion(id, actionPromotion)
            .then((res) => {
                dispatch(updateActionPromotionSuccess(res));
            })
            .catch((err) => {
                dispatch(updateActionPromotionFail(err));
            });
    };
};

export const acceptActionPromotionStart = () => {
    return {
        type: actionTypes.ACCEPT_ACTION_PROMOTION_START,
    };
};

export const acceptActionPromotionSuccess = (updatedActionPromotion) => {
    return {
        type: actionTypes.ACCEPT_ACTION_PROMOTION_SUCCESS,
        updatedActionPromotion,
    };
};

export const acceptActionPromotionFail = (error) => {
    return {
        type: actionTypes.ACCEPT_ACTION_PROMOTION_FAIL,
        error: error,
    };
};

export const acceptActionPromotion = (id) => {
    return (dispatch) => {
        dispatch(acceptActionPromotionStart());

        return AdminInterventionAPI.acceptActionPromotion(id)
            .then((res) => {
                dispatch(acceptActionPromotionSuccess(res));
            })
            .catch((err) => {
                dispatch(acceptActionPromotionFail(err));
            });
    };
};

export const declineActionPromotionStart = () => {
    return {
        type: actionTypes.ACCEPT_ACTION_PROMOTION_START,
    };
};

export const declineActionPromotionSuccess = (updatedActionPromotion) => {
    return {
        type: actionTypes.ACCEPT_ACTION_PROMOTION_SUCCESS,
        updatedActionPromotion,
    };
};

export const declineActionPromotionFail = (error) => {
    return {
        type: actionTypes.ACCEPT_ACTION_PROMOTION_FAIL,
        error: error,
    };
};

export const declineActionPromotion = (id, declineReason) => {
    return (dispatch) => {
        dispatch(declineActionPromotionStart());

        return AdminInterventionAPI.declineActionPromotion(id, declineReason)
            .then((res) => {
                dispatch(declineActionPromotionSuccess(res));
            })
            .catch((err) => {
                dispatch(declineActionPromotionFail(err));
            });
    };
};
