import adminActionLogReducer from "./../admin/reducers/adminActionLogReducer";
import adminActionTypeReducer from "./../admin/reducers/adminActionTypeReducer";
import adminAllowedRegistrationEmailDomainReducer from "./../admin/reducers/adminAllowedRegistrationEmailDomainReducer";
import adminBadgeRequestReducer from "./../admin/reducers/adminBadgeRequestReducer";
import adminCategoryReducer from "./../admin/reducers/adminCategoryReducer";
import adminCompanyReducer from "./../admin/reducers/adminCompanyReducer";
import adminContentReducer from "./../admin/reducers/adminContentReducer";
import adminEmailTemplateReducer from "./../admin/reducers/adminEmailTemplateReducer";
import adminEvidenceTypeReducer from "./../admin/reducers/adminEvidenceTypeReducer";
import adminInterventionReducer from "./../admin/reducers/adminInterventionReducer";
import adminMentorPreferenceReducer from "./../admin/reducers/adminMentorPreferenceReducer";
import adminPersonReducer from "./../admin/reducers/adminPersonReducer";
import adminRegistrationReasonReducer from "./../admin/reducers/adminRegistrationReasonReducer";
import adminRelationshipReducer from "./../admin/reducers/adminRelationshipReducer";
import adminRelationshipTypeReducer from "./../admin/reducers/adminRelationshipTypeReducer";
import actionPlanReducer from "./actionPlanReducer";
import authReducer from "./authReducer";
import badgeRequestReducer from "./badgeRequestReducer";
import categoryReducer from "./categoryReducer";
import companyReducer from "./companyReducer";
import configReducer from "./configReducer";
import contentReducer from "./contentReducer";
import countryReducer from "./countryReducer";
import evidenceReducer from "./evidenceReducer";
import groupValueReducer from "./groupValueReducer";
import jobReducer from "./jobReducer";
import languageReducer from "./languageReducer";
import lorReducer from "./lorReducer";
import personReducer from "./personReducer";
import registerReasonReducer from "./registerReasonReducer";
import skillProfileReducer from "./skillProfileReducer";
import surveyReducer from "./surveyReducer";
import userReducer from "./userReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
    country: countryReducer,
    registerReason: registerReasonReducer,
    category: categoryReducer,
    lor: lorReducer,
    user: userReducer,
    auth: authReducer,
    person: personReducer,
    skillProfile: skillProfileReducer,
    job: jobReducer,
    actionPlan: actionPlanReducer,
    groupValue: groupValueReducer,
    company: companyReducer,
    survey: surveyReducer,
    evidence: evidenceReducer,
    language: languageReducer,
    content: contentReducer,
    config: configReducer,
    adminPerson: adminPersonReducer,
    adminCompany: adminCompanyReducer,
    adminRelationshipType: adminRelationshipTypeReducer,
    adminEmailTemplate: adminEmailTemplateReducer,
    adminBadgeRequest: adminBadgeRequestReducer,
    adminIntervention: adminInterventionReducer,
    adminRelationship: adminRelationshipReducer,
    adminActionType: adminActionTypeReducer,
    adminEvidenceType: adminEvidenceTypeReducer,
    adminMentorPreference: adminMentorPreferenceReducer,
    adminContent: adminContentReducer,
    adminCategory: adminCategoryReducer,
    adminAllowedRegistrationEmailDomain: adminAllowedRegistrationEmailDomainReducer,
    adminRegistrationReason: adminRegistrationReasonReducer,
    adminActionLog: adminActionLogReducer,
    badgeRequest: badgeRequestReducer,
});

export default rootReducer;
