import ErrorHandler from "./../errorResponseHandler";
import Instance from "./../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminRelationshipAPI {
    static addRelationships(relationships) {
        return axios
            .post(`/relationships/postmultiple`, {
                relationships
            })
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static deleteRelationships(relationshipIds) {
        return axios
            .post(`/relationships/deletemultiple`, {
                relationshipIds
            })
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static exportRelationshipsDataExcel() {
        return axios
            .get(`/relationships/export`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static exportRelationshipsDataExcelTemplate() {
        return axios
            .get(`/relationships/exporttemplate`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static importRelationshipsDataExcel(relationshipsData) {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        const body = new FormData();
        body.append("ContentDisposition", "attachment");
        body.append("ContentType", "multipart/form-data");

        relationshipsData.forEach((file) => {
            body.append("relationshipsData", file);
        });

        return axios
            .post(`/relationships/import`, body, config)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default AdminRelationshipAPI;