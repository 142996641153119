import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class SurveyAPI {
    static getMySurveys(assessmentId) {
        return axios
            .get(`/surveys/mysurveys?assessmentId=${assessmentId}`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static selectRole(assessmentId, roleId) {
        return axios
            .post(`/surveys/mysurveys/selectrole?assessmentId=${assessmentId}&roleId=${roleId}`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static getSurveyQuestion(componentGroupId, elementId) {
        return axios
            .get(`/surveys/question?componentGroupId=${componentGroupId}&elementId=${elementId}`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static answerQuestion(body) {
        return axios
            .post(`/surveys/question/answer`, body)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static skipQuestion(body) {
        return axios
            .post(`/surveys/question/skip`, body)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static completeSurvey() {
        return axios
            .post(`/surveys/mysurveys/complete`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }

    static resetMySurvey() {
        return axios
            .post(`/surveys/mysurveys/reset`)
            .then(res => {
                const response = res.data;
                return response;
            })
            .catch(err => {
                throw ErrorHandler(err);
            });
    }
}

export default SurveyAPI;