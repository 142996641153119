import * as authActions from "../../../store/actions/authAction";
import * as companyActions from "../../../store/actions/companyAction";
import * as countryActions from "../../../store/actions/countryAction";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { contentTranslator } from "../../../utils/translatorUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import ManageProviderDetail from "./ManageProviderDetail";
import ProviderSelectCell from "./ProviderSelectCell";
import { faPenToSquare, faSync, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Button, Col, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const rowStyle = {
    fontSize: "12px",
};

class ManageProviders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            providers: [],
            isUpdating: false,
            selectedProvider: null,
            showDialogProviderDetailTs: null,
            countries: [],
            searchProperties: ["name", "website", "country"],
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        this.props.onAuth.authCheckState().then(() => {
            this.props.onCountry.getCountries().then(() => {
                let countries = [
                    {
                        label: "Select Country",
                        value: "",
                    },
                ];
                if (this.props.countries) {
                    countries.push(...this.props.countries.map((country) => ({ label: country.name, value: country.name })));
                }

                this.setState({ countries });
            });

            this.initData();
            this.setState({ loading: this.props.loading });
        });
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.providers !== this.props.providers) {
            this.setState({ providers: this.reformProviders(this.props.providers.sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1))) });
        }
    }

    reformProviders = (providers) => {
        return providers.map((it) => {
            return {
                id: it.id,
                name: it.name,
                website: it.website,
                address: it.address,
                country: it.country,
                companyType: it.companyType,
                providerCompanyId: it.providerCompanyId,
                providerCompany: it.providerCompany,
                isSelected: it.isSelected,
                isPreferred: it.isPreferred,
                selected: it.isPreferred ? "Preferred" : it.isSelected ? "Yes" : "No",
            };
        });
    };

    initData = () => {
        this.props.onCompany.getProviderCompanies().then(() => {
            if (!this.props.companyLoading) {
                this.setState({ providers: this.reformProviders(this.props.providers) });
            }

            this.setState({ loading: this.props.loading || this.props.companyLoading });
        });
    };

    handleRefresh = () => {
        this.initData();
    };

    openProviderDetail = (provider) => {
        this.setState({ selectedProvider: provider, showDialogProviderDetailTs: new Date() });
    };

    handleAddNew = () => {
        this.setState({
            selectedProvider: {
                id: null,
                companyType: "Provider",
                name: null,
                website: null,
                isSelected: false,
                isPreferred: false,
            },
            showDialogProviderDetailTs: new Date(),
        });
    };

    handleSaveUpdateProvider = (provider) => {
        this.setState({ isUpdating: true, updateSuccess: false });
        this.props.onCompany.updateCompany(provider.id, provider).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.companyError) {
                    this.generateAlert("success", "Update training provider success.");
                    this.setState({ updateSuccess: true });
                } else {
                    this.generateAlert("danger", this.props.companyError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleSaveAddProvider = (provider) => {
        this.setState({ isUpdating: true, updateSuccess: false });
        this.props.onCompany.createCompany(provider).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.companyError) {
                    this.generateAlert("success", "Create training provider success.");
                    this.setState({ updateSuccess: true });
                    this.handleRefresh();
                } else {
                    this.generateAlert("danger", this.props.companyError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    onTableFilterChange = (e, searchType) => {
        let columnSearchKeywords = this.state.columnSearchKeywords;
        if (!columnSearchKeywords) {
            columnSearchKeywords = [];
        }

        if (columnSearchKeywords.find((it) => it.name == e.target.name)) {
            let columnSearchKeyword = columnSearchKeywords.find((it) => it.name == e.target.name);
            columnSearchKeyword.value = e.target.value;
        } else {
            columnSearchKeywords.push({
                name: e.target.name,
                value: e.target.value,
                searchType,
            });
        }

        this.setState({ columnSearchKeywords });
    };

    render() {
        const { providers, searchKeyword, searchProperties, columnSearchKeywords } = this.state;
        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Select Training Providers");

        const openProviderDetail = this.openProviderDetail;
        const onTableFilterChange = this.onTableFilterChange;

        const columns = [
            {
                id: "id",
                name: "#",
                selector: (row) => row.id,
                sortable: false,
                width: "10%",
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <FontAwesomeIcon id={`iconProviderDetail${row.id}`} icon={faPenToSquare} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => openProviderDetail(row)} />
                            <UncontrolledTooltip target={`iconProviderDetail${row.id}`} placement="bottom">
                                Update Provider
                            </UncontrolledTooltip>
                        </div>
                    );
                },
                omit: true,
            },
            {
                id: "name",
                name: (
                    <div style={{ width: "90%" }}>
                        Name
                        <br />
                        <input type="text" name="name" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "name") ? columnSearchKeywords.find((it) => it.name == "name").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.name,
                sortable: true,
                width: "30%",
            },
            {
                id: "website",
                name: (
                    <div style={{ width: "90%" }}>
                        Website
                        <br />
                        <input type="text" name="website" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "website") ? columnSearchKeywords.find((it) => it.name == "website").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.website,
                sortable: true,
                width: "40%",
            },
            {
                id: "country",
                name: (
                    <div style={{ width: "90%" }}>
                        Country
                        <br />
                        <input type="text" name="country" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "country") ? columnSearchKeywords.find((it) => it.name == "country").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.country,
                sortable: true,
                width: "20%",
            },
            {
                id: "isSelected",
                name: (
                    <div style={{ width: "90%" }}>
                        Selected
                        <br />
                        <input type="text" name="selected" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "selected") ? columnSearchKeywords.find((it) => it.name == "selected").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.isSelected,
                sortable: false,
                width: "10%",
                format: function (row, index) {
                    return <ProviderSelectCell key={`LKProvider${row.id} `} provider={row} allowEdit={pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? true : false}></ProviderSelectCell>;
                },
            },
        ];

        const selectRow = {
            mode: "checkbox",
            clickToSelect: true,
            selected: this.state.personsSelected,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll,
            hideSelectColumn: true,
        };

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <ManageProviderDetail
                            provider={this.state.selectedProvider}
                            showDialog={this.state.showDialogProviderDetailTs}
                            generateAlert={this.generateAlert}
                            isUpdating={this.state.isUpdating}
                            handleSaveUpdateProvider={this.handleSaveUpdateProvider}
                            handleSaveAddProvider={this.handleSaveAddProvider}
                            updateSuccess={this.state.updateSuccess}
                        ></ManageProviderDetail>
                        <div style={{ marginBottom: "10px" }}>
                            <Row>
                                <Col md="5" sm="6">
                                    <SkillsTxTableSearchBar
                                        searchKeyword={searchKeyword}
                                        onChange={(searchKeyword) => {
                                            this.setState({ searchKeyword });
                                        }}
                                    ></SkillsTxTableSearchBar>
                                </Col>
                                <Col md="7" sm="6">
                                    <SkillsTxTableClearSearch
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                        }}
                                        color="primary"
                                        disabled={this.props.companyLoading || this.state.isUpdating}
                                        onClear={() => {
                                            this.setState({ searchKeyword: "" });
                                        }}
                                    ></SkillsTxTableClearSearch>
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        onClick={this.handleRefresh}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                        }}
                                        disabled={this.props.companyLoading || this.state.isUpdating}
                                    >
                                        <FontAwesomeIcon icon={faSync} /> Refresh
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        onClick={this.handleAddNew}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            display: "none",
                                        }}
                                        disabled={this.props.companyLoading}
                                    >
                                        <FontAwesomeIcon icon={faPlus} /> Add New
                                    </Button>
                                    &nbsp;&nbsp;
                                </Col>
                            </Row>
                        </div>
                        <SkillsTxTable columns={columns} data={providers} pagination={true} searchKeyword={searchKeyword} columnSearchKeywords={columnSearchKeywords}></SkillsTxTable>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,

        companyLoading: state.company.loading,
        companyError: state.company.error,
        providers: state.company.providers,
        isUpdating: state.company.isUpdating,

        countries: state.country.countries,
        pageAssignments: state.adminPerson.pageAssignments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onCompany: bindActionCreators(companyActions, dispatch),
        onCountry: bindActionCreators(countryActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageProviders);
