import * as authActions from "../store/actions/authAction";

import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner, } from "reactstrap";
import { dismisAlert, generateAlert } from "../utils/alertUtils";

import { AlertList } from "react-bs-notifier";
import ContentTranslator from "./translator/ContentTranslator";
import { ContentWrapper } from "./common/ContentWrapper";
import { Layout } from "./Layout";
import React from "react";
import { bindActionCreators } from "redux";
import changeInput from "../utils/changeInput";
import { connect } from "react-redux";
import { contentTranslator } from "../utils/translatorUtils";

const titleStyle = {
    fontFamily: 'IBMPlexSans-Bold',
    fontSize: '38px',
    align: 'left',
}

const descStyle = {
    fontFamily: 'IBMPlexSans-Light',
    fontSize: '14px',
    align: 'left'
}

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            alerts: [],
            form: {
                email: {
                    validation: {
                        required: true,
                        isEmail: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                }
            },
            isFormValid: false,
            resetEmailSent: false,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {

    }

    handleChange = (event) => {
        const { name, value } = event.target

        const updateState = changeInput(
            this.state.form,
            name,
            value
        );

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid
        })
    }

    submitForm = (event) => {
        event.preventDefault();
        this.setState({ resetEmailSent: false, error: null, loading: true });
        const email = this.state.form.email.value;

        this.props.onAuth.forgotPassword(email).then(() => {
            if (!this.props.loading) {
                if (this.props.error) {
                    this.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    this.setState({ resetEmailSent: true });
                }
            }
            this.setState({ loading: this.props.loading });
        });
    }

    render() {
        const { form } = this.state;

        return (
            <Layout language={this.props.language} setCurrentLanguage={this.props.setCurrentLanguage}>
                <ContentWrapper>
                    <AlertList
                        className={"alert-fixed"}
                        position={"top-right"}
                        alerts={this.state.alerts}
                        timeout={alertAutoHideInterval * 1}
                        dismissTitle="Close!"
                        onDismiss={this.dismisAlert}
                    />
                    {!this.state.resetEmailSent ?
                        <Row xs="1" md="2">
                            <Col>
                                <p className="color-dark" style={titleStyle}><ContentTranslator page="ForgotPassword" name="ForgotPasswordTitle" contentText="Reset Password" /></p>
                                <p className="color-dark" style={descStyle}><ContentTranslator page="ForgotPassword" name="ForgotPasswordMessage" contentText="Enter your email address below and you will be sent an email link with a confirmation link." /></p>
                            </Col>
                            <Col>
                                <Form onSubmit={this.submitForm}>
                                    <FormGroup>
                                        <Label className="font-weight-bold" for="lblEmail"><ContentTranslator page="ForgotPassword" name="ForgotPasswordEmail" contentText="Email" /></Label>
                                        <Input type="email" name="email" id="inputEmail"
                                            value={form.email.value} invalid={form.email.touched && (!form.email.valid || !form.email.isValidFormat)}
                                            onChange={this.handleChange} />
                                        {form.email.value != "" && !form.email.isValidFormat && <FormFeedback><ContentTranslator page="ForgotPassword" name="ForgotPasswordEmailIncorrect" contentText="Incorrect email format" /></FormFeedback>}
                                    </FormGroup>
                                    <Button
                                        disabled={!this.state.isFormValid || this.state.loading}
                                        block color="primary" style={{ height: '55px', fontFamily: 'IBMPlexSans-Bold' }}>
                                        {!this.state.loading ? <ContentTranslator page="ForgotPassword" name="ForgotPasswordButtonConfirm" contentText="Confirm" />
                                            :
                                            <Spinner
                                                type="grow"
                                                size="md"
                                                color="light"
                                            />
                                        }
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                        :
                        <Row md="12">
                            <Col>
                                <p className="color-dark" style={titleStyle}><ContentTranslator page="ForgotPassword" name="ResetPasswordConfirmation" contentText="Reset Password Confirmation" /></p>
                                <p className="color-dark" style={descStyle}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            this.contentTranslator(this.props.contentTranslations, "ForgotPassword", "ResetPasswordMessage", "We have sent a reset link to your email address. Please check your inbox ASAP because the reset link expires after 24 hours.<br />NOTE : Reset links will ONLY be sent to recognised email addresses.")
                                    }}></p>
                            </Col>
                        </Row>
                    }
                </ContentWrapper>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        contentTranslations: state.content.contentTranslations,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
