import * as authActions from "../../../store/actions/authAction";
import * as lorActions from "../../../store/actions/lorAction";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { filterArray } from "../../../utils/filterUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import UpdateLor from "./UpdateLor";
import { faPenToSquare, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, ButtonGroup, Col, Modal, ModalBody, ModalHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

class ManageLor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            lors: [],
            searchProperties: ["name", "category", "lorCode"],
            showLoadingPanel: false,
        };

        this.filterArray = filterArray.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
    }

    componentDidMount() {
        this.props.onAuth.authCheckState().then(() => {
            this.initData();
        });
    }

    componentWillUnmount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.lors !== this.props.lors) {
            this.setState({
                lors: this.reformLors(this.props.lors),
            });
        }
    }

    initData = () => {
        this.props.onLor.getLors().then(() => {
            const lors = this.reformLors(this.props.lors);

            this.setState({
                lors,
            });

            this.setState({ loading: this.props.loading || this.props.lorLoading });
        });
    };

    reformLors = (lors) => {
        return lors.map((it) => {
            return {
                id: it.id,
                name: it.name,
                description: it.description,
                lorCode: it.lorCode,
                category: it.category != null ? it.category : "Generic Attributes",
                categoryColor: it.category != null ? it.categoryColor : "#69849c",
                lorColor: it.category != null ? it.lorColor : "#ecf0f5",
                sequence: it.sequence,
                canHide: it.canHide,
                isHidden: it.isHidden,
                levels: it.levels,
            };
        });
    };

    handleRefresh = () => {
        this.initData();
    };

    handleClearSelection = () => {
        const interventions = this.state.interventions;

        interventions.forEach((it) => {
            it.toggleSelected = false;
        });

        this.setState({ interventions: [...interventions] });
    };

    onTableFilterChange = (e, searchType) => {
        let columnSearchKeywords = this.state.columnSearchKeywords;
        if (!columnSearchKeywords) {
            columnSearchKeywords = [];
        }

        if (columnSearchKeywords.find((it) => it.name == e.target.name)) {
            let columnSearchKeyword = columnSearchKeywords.find((it) => it.name == e.target.name);
            columnSearchKeyword.value = e.target.value;
        } else {
            columnSearchKeywords.push({
                name: e.target.name,
                value: e.target.value,
                searchType,
            });
        }

        this.setState({ columnSearchKeywords });
    };

    openEditLor = (lor) => {
        this.setState({ showDialogLorDetailTs: new Date(), selectedLor: lor });
    };

    handleHideLor = (lor) => {
        this.setState({ showLoadingPanel: true, loadingPanelHeader: null, loadingPanelBody: "Please wait ..." });
        this.props.onLor.updateLorSetIsHidden(lor.id, !lor.isHidden).then(() => {
            this.setState({ showLoadingPanel: this.props.isUpdating });
        });
    };

    renderLoadingPanel() {
        if (this.state.showLoadingPanel) {
            return (
                <Modal isOpen={this.state.showLoadingPanel}>
                    {this.state.loadingPanelHeader && <ModalHeader>{this.state.loadingPanelHeader}</ModalHeader>}
                    <ModalBody>
                        <Spinner size="md" color="primary" /> {this.state.loadingPanelBody}
                    </ModalBody>
                </Modal>
            );
        } else {
            return "";
        }
    }

    render() {
        const { lors, searchKeyword, searchProperties, columnSearchKeywords } = this.state;

        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Manage LoR Category");

        const onTableFilterChange = this.onTableFilterChange;
        const openEditLor = this.openEditLor;
        const handleHideLor = this.handleHideLor;

        const filtered = this.filterArray(lors, searchKeyword, searchProperties, columnSearchKeywords);

        const showHideLor = this.props.containerConfig && !this.props.containerConfig.eeaFrameworkContainer; //lors && lors.find(it => it.canHide);

        const columns = [
            {
                id: "id",
                name: "Action",
                selector: (row) => row.id,
                sortable: false,
                width: "7%",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                            <React.Fragment>
                                <FontAwesomeIcon id={`iconProviderDetail${row.id}`} icon={faPenToSquare} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => openEditLor(row)} />
                                <UncontrolledTooltip target={`iconProviderDetail${row.id}`} placement="bottom">
                                    Update LoR
                                </UncontrolledTooltip>
                            </React.Fragment>
                        </div>
                    );
                },
                omit:
                    this.props.containerConfig &&
                    this.props.containerConfig.eeaFrameworkContainer &&
                    pageAssignment &&
                    pageAssignment.operations &&
                    (pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) || pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true))
                        ? false
                        : true,
            },
            {
                id: "name",
                name: (
                    <div style={{ width: "90%" }}>
                        Name
                        <br />
                        <input type="text" name="name" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "name") ? columnSearchKeywords.find((it) => it.name == "name").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.name,
                sortable: true,
                wrap: true,
                width:
                    this.props.containerConfig &&
                    this.props.containerConfig.eeaFrameworkContainer &&
                    pageAssignment &&
                    pageAssignment.operations &&
                    (pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) || pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true))
                        ? "32%"
                        : "39%",
            },
            {
                id: "lorCode",
                name: (
                    <div style={{ width: "90%" }}>
                        LoR Code
                        <br />
                        <input type="text" name="lorCode" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "lorCode") ? columnSearchKeywords.find((it) => it.name == "lorCode").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.lorCode,
                sortable: true,
                width: showHideLor ? "10%" : "12%",
                wrap: true,
                center: true,
            },
            {
                id: "category",
                name: (
                    <div style={{ width: "90%" }}>
                        Category
                        <br />
                        <input type="text" name="category" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "category") ? columnSearchKeywords.find((it) => it.name == "category").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.category,
                sortable: true,
                width: showHideLor ? "23%" : "25%",
                wrap: true,
            },
            {
                id: "categoryColor",
                name: (
                    <div style={{ width: "90%" }}>
                        Category Color
                        <br />
                        <input
                            type="text"
                            name="categoryColor"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "categoryColor") ? columnSearchKeywords.find((it) => it.name == "categoryColor").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.categoryColor,
                sortable: true,
                width: showHideLor ? "10%" : "12%",
                wrap: true,
                center: true,
                format: function (row, index) {
                    return row.categoryColor && <div style={{ width: "75px", backgroundColor: row.categoryColor, padding: "5px", textAlign: "center", color: "#000000", fontWeight: "bold" }}>{row.categoryColor}</div>;
                },
            },
            {
                id: "lorColor",
                name: (
                    <div style={{ width: "90%" }}>
                        LoR Color
                        <br />
                        <input type="text" name="lorColor" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "lorColor") ? columnSearchKeywords.find((it) => it.name == "lorColor").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.lorColor,
                sortable: true,
                width: showHideLor ? "10%" : "12%",
                wrap: true,
                center: true,
                format: function (row, index) {
                    return row.lorColor && <div style={{ width: "75px", backgroundColor: row.lorColor, padding: "5px", textAlign: "center", color: "#000000", fontWeight: "bold" }}>{row.lorColor}</div>;
                },
            },
            {
                id: "isHidden",
                name: <div style={{ width: "90%" }}>LoR Is Shown</div>,
                selector: (row) => row.isHidden,
                sortable: false,
                width: "8%",
                wrap: true,
                center: true,
                format: function (row, index) {
                    return (
                        <React.Fragment>
                            <ButtonGroup>
                                <Button color="success" outline={row.isHidden == false ? false : true} style={{ cursor: row.canHide && row.isHidden ? "pointer" : "default" }} disabled={!row.canHide} onClick={() => row.canHide && row.isHidden && handleHideLor(row)}></Button>
                                <Button color="danger" outline={row.isHidden == true ? false : true} style={{ cursor: row.canHide && !row.isHidden ? "pointer" : "default" }} disabled={!row.canHide} onClick={() => row.canHide && !row.isHidden && handleHideLor(row)}></Button>
                            </ButtonGroup>
                        </React.Fragment>
                    );
                },
                omit: !showHideLor,
            },
        ];

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.renderLoadingPanel()}
                <UpdateLor lor={this.state.selectedLor} showDialog={this.state.showDialogLorDetailTs} generateAlert={this.generateAlert}></UpdateLor>
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <div style={{ marginBottom: "10px" }}>
                            <Row>
                                <Col md="4" sm="6">
                                    <SkillsTxTableSearchBar
                                        searchKeyword={this.state.searchKeyword}
                                        onChange={(searchKeyword) => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword, selectedInterventions: [] });
                                        }}
                                    ></SkillsTxTableSearchBar>
                                </Col>
                                <Col md="8" sm="6">
                                    <SkillsTxTableClearSearch
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        color="primary"
                                        disabled={this.props.interventionLoading || this.state.isUpdating}
                                        onClear={() => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword: "", selectedInterventions: [] });
                                        }}
                                    ></SkillsTxTableClearSearch>
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        onClick={this.handleRefresh}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        disabled={this.props.interventionLoading || this.state.isUpdating}
                                    >
                                        <FontAwesomeIcon icon={faSync} /> Refresh
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <SkillsTxTable
                            columns={columns}
                            data={lors}
                            pagination={false}
                            searchKeyword={searchKeyword}
                            searchProperties={searchProperties}
                            columnSearchKeywords={columnSearchKeywords}
                            selectableRows={false}
                            selectableRowsHighlight={false}
                            progressPending={this.props.lorLoading}
                        ></SkillsTxTable>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        authPersonId: state.auth.personId,

        lors: state.lor.lors,
        lorLoading: state.lor.loading,

        pageAssignments: state.adminPerson.pageAssignments,
        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onLor: bindActionCreators(lorActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageLor));
