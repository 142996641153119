import CategoryAPI from "../../../../common/api/service/CategoryService";
import PersonService from "../../../../common/api/service/PersonService";
import ProfileService from "../../../../common/api/service/ProfileService";
import { Bar } from "../../components/charts";
import { LevelResponsibility } from "../../components/config/mockdata/levels";
import { OrderCategory } from "../../components/config/mockdata/order";
import SkillsWeHaveFilters from "../../components/filters/skillswehave";
import {
  SkillsWeHaveNeedTable,
  SkillsWeHaveNeedTableNormal,
} from "../../components/table";
import { Typography } from "antd";
import { groupBy } from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import { Spinner } from "reactstrap";
import styled from "styled-components";

const { Title } = Typography;

const DivCenter = ({ Component }) => {
  return (
    <div className="p-grid p-align-center vertical-container">
      <div className="layout-wrapper">
        <div className="p-grid">
          <div
            className="p-col-12"
            style={{ position: "fixed", top: "50%", left: "50%" }}
          >
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
};

// styled components
const Container = styled.div`
  padding: 0px 30px 0px 30px;
  .mt-40 {
    margin-top: 40px;
  }
`;

const ContainerBorder = styled.div`
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #dddddd;
  border-radius: 14px;
`;

const ContainerContent = styled.div`
  padding: 20px 30px;
`;

const ContainerDefault = styled.div`
  padding: 0px 0px 20px 0px;
`;

// Constant Data
const constantData = {
  pageSize: 5,
  jobLorProfileLevel: 8,
  empty: "",
  spaceBar: " ",
  levelName: "level",
  profileStatusName: "profileStatus",
  skillCodeLevelName: "skillCodeLevel",
  desirableName: "Desirable",
  requiredName: "Required",
  jobTypeList: ["Current", "Future"],
  fullName: "fullName",
  categoryName: "category",
  skillName: "skill",
  categoryTitleName: "Category",
  subCategoryName: "subCategory",
  endorsedName: "Endorsed",
  competencyName: "Competency",
  capabilityName: "capability",
  lorCodeName: "lorCode",
  proficiencyName: "Proficiency",
  knowledgeName: "Knowledge",
  genericAttributeName: "GenericAttribute",
  personName: "Person",
  keyResult: ["haveSkill", "needSkill"],
  styles: {
    maxWidth: "auto",
  },
  colors: {
    type1: "#3C65C2",
    type2: "#EEF2FF",
    type3: "#69849c",
    type4: "#a5b8c8",
  },
  characters: {
    m: "M",
    n: "N",
    p: "P",
  },
};

// Helper function to deep clone data using JSON methods
// This works for simple data structures without circular references or functions
const deepClone = (data) => JSON.parse(JSON.stringify(data));

// Returns a new array containing only the unique elements from the provided `dataArray` based on a custom comparison function.
const distinctArray = (dataArray, func) => {
  // `seen` will store a unique "hash" for each item we've already encountered.
  const seen = new Set();

  // The array to accumulate unique items from `dataArray`.
  const uniqueArray = [];

  dataArray.forEach((item) => {
    // Create a unique "hash" for the current item based on the comparator function.
    // This "hash" is simply a string representation of all items considered equal to the current item.
    const hash = uniqueArray.some((itemIn) => func(item, itemIn));

    // If we haven't seen this unique "hash" before, add the item to our unique array.
    if (!hash) {
      seen.add(hash);
      uniqueArray.push(item);
    }
  });

  return uniqueArray;
};

const SkillsWeHave = () => {
  // Data
  const [data, setData] = useState([]);
  const [genericAttributeData, setGenericAttributeData] = useState([]);
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  // Selection
  const [selectedLorSkill, setSelectedLorSkill] = useState([]);
  const [selectedGeneric, setSelectedGeneric] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState([]);

  // Processes the data of a person for a given item and its associated versions.
  const processPersonData = async (item, versions, categories) => {
    const availableSkillCodes = categories
      ? categories.flatMap((category, index) => {
            return category.subCategories.flatMap((subCategory, index) => {
                return subCategory.skills
                    .filter((it) => !it.isHidden)
                    .flatMap((skill, index) => {
                        return skill.skillsCode;
                    });
            });
        })
      : [];

    // Creates a copy of personB's accounts and sorts them in descending order based on the creation date.
    // Then, selects the most recent account.
    const account = item.personB.accounts
      .slice()
      .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))[0];

    // If an account exists, creates a copy of its approvals, sorts them in descending order based on the approval date,
    // and then selects the most recent approval.
    const approver =
      account &&
      account.approvals
        .slice()
        .sort((a, b) => new Date(b.approvedOn) - new Date(a.approvedOn))[0];

    // Retrieves skillProfiles from the item. If they're not available, and a version exists,
    // fetches them using the ProfileService. If none of these conditions are met, defaults to an empty array.
    const skillProfiles =
      item.skillProfile ||
      (versions.length > 0 &&
        (await ProfileService.getManagedPeopleSkillPofiles(
          item.personB.email,
          versions[0].sfiaVersion,
          versions[0].version
        ))) ||
      [];

    // Returns the processed data.
    return {
      approver,
      profiles: processDetail(skillProfiles.filter(it => availableSkillCodes.includes(it.skillCode)), categories),
      name: constantData.personName,
    };
  };

  //Processes generic attribute data for a given item and its versions.z`
  const processGenericAttribute = async (item, versions, categories) => {
    // Sorts the accounts of personB based on the creation date.
    const account = item.personB.accounts.sort(
      (a, b) => new Date(a.createdOn) - new Date(b.createdOn)
    );

    // Sorts the approvals of the oldest account based on the approval date.
    const sortedApprovals = account[0].approvals.sort(
      (a, b) => new Date(a.approvedOn) - new Date(b.approvedOn)
    );

    // Gets the first (oldest) approver if available, otherwise null.
    const approver = sortedApprovals.length > 0 ? sortedApprovals[0] : null;

    // Retrieves lorProfiles from the item or fetches them if not available and a version exists.
    const lorProfiles =
      item.lorProfile ||
      (versions.length > 0
        ? await ProfileService.getManagedPeopleLorPofiles(
            item.personB.email,
            versions[0].sfiaVersion,
            versions[0].version
          )
        : []);

    // Returns the processed data.
    return {
      approver,
      profiles: processDetail(lorProfiles, categories),
      name: constantData.genericAttributeName,
    };
  };

  // Processes data related to people who possess certain skills.
  const processData = async (personPeople, categories) => {
    // Initialize arrays to store processed data.
    const personData = [];
    const genericAttributeData = [];

    // Map over each person item and process their data concurrently.
    await Promise.all(
      personPeople.map(async (item) => {
        // Get the profile versions of the current person.
        // If not provided, fetch them using the ProfileService.
        const versions =
          item.profileVersion ||
          (await ProfileService.getManagedPeopleProfileVersions(
            item.personB.email
          ));

        // Concurrently fetch and process person data and generic attribute data.
        const listData = await Promise.all([
          processPersonData(item, versions, categories),
          processGenericAttribute(item, versions, categories),
        ]);

        // For each processed data value, transform and filter the profiles.
        for (const value of listData) {
          const mapData = value.profiles
            .map((_) => ({
              ..._,
              // Append full name and email details to each profile.
              fullName: `${item.personB.firstName} ${item.personB.lastName}`,
              email: item.personB.email,
              ...value.approver,
              // Set the profile status based on its type and approver details.
              profileStatus: !_.profileType.includes(constantData.endorsedName)
                ? value.approver
                  ? `${_.profileType} (Approved)`
                  : `${_.profileType} (Not Approved)`
                : _.profileType,
              isApproved: !_.profileType.includes(constantData.endorsedName)
                ? value.approver
                  ? true
                  : false
                : null,
              approvedText: !_.profileType.includes(constantData.endorsedName)
                ? value.approver
                  ? "Approved"
                  : "Not Approved"
                : null,
              // Determine the capability based on stringVal.
              capability:
                _.stringVal === constantData.characters.m
                  ? constantData.competencyName
                  : _.stringVal === constantData.characters.p
                  ? constantData.proficiencyName
                  : constantData.knowledgeName,
            }))
            .filter((_) => _.stringVal !== constantData.characters.n);

          // If the processed data name matches "personName", push to personData, else to genericAttributeData.
          if (value.name === constantData.personName) {
            personData.push(mapData);
          } else {
            genericAttributeData.push(mapData);
          }
        }
      })
    );

    // Flatten and set the processed data to their respective states or storage locations.
    setData(personData.flat(2));
    setGenericAttributeData(genericAttributeData.flat(2));
  };

  const processDetail = (data, categories) =>
    data.map((element) => {
      let findCategory = categories?.find(
        (item) => item.name === element?.category
      );
      if (!findCategory) {
        findCategory = {
          colour: constantData.colors.type3,
          description: constantData.empty,
        };
      }
      let findSubCategory = findCategory?.subCategories?.find(
        (item) => item.name === element?.subCategory
      );
      if (!findSubCategory) {
        findSubCategory = {
          colour: constantData.colors.type4,
          description: constantData.empty,
        };
      }
      const findSkill = findSubCategory?.skills?.find(
        (item) => item.name === element?.skill
      );
      const findLevel = findSkill?.levels?.find(
        (item) => item.level === element?.level
      );

      return {
        categoryDetail: {
          colour: findCategory?.colour,
          description: findCategory?.description,
        },
        subCategoryDetail: {
          skillColour: findSubCategory?.skillColour,
          colour: findSubCategory?.colour,
          description: findSubCategory?.description,
        },
        skillDetail: {
          description: findSkill?.description,
        },
        levelDetail: {
          description: findLevel?.description,
        },
        ...element,
      };
    });

  // Processes all relevant data for skills.
  // This function fetches a set of initial data, and then prepares and processes
  // two types of skill-related data: haveSkill and needSkill.
  const processAllData = async () => {
    try {
      // Concurrently fetch multiple sets of data using Promise.all for improved performance.
      const [
        personPeople,
        personProfile,
        profileVersion,
        skillProfile,
        lorProfile,
        categories,
      ] = await Promise.all([
        PersonService.getPersonMyPeoples(),
        PersonService.getPersonMyProfile(),
        ProfileService.getMyProfileVersions(),
        ProfileService.getMySkillPofiles(),
        ProfileService.getMyLorPofiles(),
        CategoryAPI.getCategories(),
      ]);

      // Construct a new data entry based on the fetched personal profile and related data.
      const newDataEntry = {
        personB: personProfile,
        profileVersion,
        skillProfile,
        lorProfile,
      };

      // Append the new data entry to the existing list of personPeople for processing "haveSkill" data.
      const prepareDataHaveSkill = [...personPeople, newDataEntry];

      await processData(prepareDataHaveSkill, categories);
    } catch (error) {
      // Log the error if fetching fails
      console.error("Failed to fetch action plan data:", error);

      // Optionally, you can set an error state here if you'd like to display an error message to users.
    }
  };

  const generateTableSkillCategory = (data) => {
    // Destructure for easier access.
    const { categoryName } = constantData;

    // Use a single reduce function to categorize and structure the data.
    const groupedData = data.reduce((acc, item) => {
      // Extract keys for categorization.
      const categoryKey = item[categoryName];

      // If the category does not exist, initialize it.
      if (!acc[categoryKey]) {
        acc[categoryKey] = {
          values: [],
        };
      }

      // Push the item into the category values.
      acc[categoryKey].values.push(item);
      return acc;
    }, {});

    // Extract keys from groupedData
    const groupedKeys = Object.keys(groupedData);

    let orderedCats = OrderCategory.map((key) =>
      groupedKeys.find((e) => e.name === key)
    );

    let orderedKeys = [];
    if (orderedCats.filter(it => it == undefined).length > 0){
      orderedKeys = [...groupedKeys];
    }else{
      //Filter and reorder the keys based on OrderCategory
      orderedKeys = OrderCategory.filter((key) =>
        groupedKeys.includes(key)
      );
    }

    // Construct the ordered grouped data
    const orderedGroupedData = orderedKeys.map((key) => [
      key,
      groupedData[key],
    ]);

    const resultData = {
      name: constantData.categoryTitleName,
      meta: { levelResponsibility: LevelResponsibility },
      description: constantData.empty,
      styles: {
        tableHeaderColor: constantData.colors.type1,
        tableHeaderBackgroundColor: constantData.colors.type2,
      },
      subCategories: orderedGroupedData.map(
        ([categoryKey, { values: categoryValues }]) => {
          const { categoryDetail, subCategoryDetail } = categoryValues[0];
          return {
            name: categoryKey,
            meta: { levelResponsibility: LevelResponsibility },
            description: categoryDetail.description,
            styles: {
              cateColour: categoryDetail.colour,
              skillColour: subCategoryDetail.skillColour,
              colour: subCategoryDetail.colour || "",
            },
            people: categoryValues,
          };
        }
      ),
    };

    return resultData;
  };

  // Generates bar data for given input.
  const generateBar = (data) => {
    // Use reduce to create an object where the keys are the skillCodeLevel
    // and the values are their respective counts.
    const groupedData = data.reduce((acc, item) => {
      const skillCodeLevel = `${item.lorCode} - ${item.level}`;

      // If the skillCodeLevel doesn't exist in the accumulator, initialize it.
      if (!acc[skillCodeLevel]) {
        acc[skillCodeLevel] = 0;
      }

      // Increment the count for the skillCodeLevel.
      acc[skillCodeLevel]++;

      return acc;
    }, {});

    // Convert the grouped data object to an array of {label, value} objects.
    const aggregatedData = Object.entries(groupedData).map(
      ([label, value]) => ({
        label,
        value,
      })
    );

    // Sort the data in descending order based on their values (counts).
    aggregatedData.sort((a, b) => b.value - a.value);

    return {
      maxCount: Math.max(...aggregatedData.map((item) => item.value)) + 1, // Calculate the maximum count
      source: aggregatedData,
    };
  };

  const generateTreeSelect = (data, name) =>
    // Using Object.entries to get both key and value of each entry in the data object
    Object.entries(data)
      .map(([category, itemsArray], categoryIdx) => {
        // Generating a Set to deduplicate the values based on the 'name' property
        // Then spread the Set back into an array for easy mapping
        const uniqueValues = [...new Set(itemsArray.map((item) => item[name]))];
        const rootData = JSON.stringify({
          isRoot: true,
          value: category,
        });
        return {
          title: category,
          value: rootData,
          key: rootData,

          // For each unique value, we map it to generate the 'children' array
          children: uniqueValues
            .map((uniqueValue, valueIdx) => {
              const childData = JSON.stringify({
                isRoot: false,
                value: uniqueValue,
              });
              return {
                // Fetching the 'text' property directly from the original itemsArray for each child
                title: itemsArray[valueIdx].text,
                value: childData,
                key: childData,
              };
            })
            .sort((a, b) => a.title.localeCompare(b.title)),
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));

  // Filter by Person Name
  const optionsName = useMemo(() => {
    if (data.length === 0) return [];

    // Deduplicate the combined array based on the fullName.
    const uniqueSkills = distinctArray(
      data,
      (itemA, itemB) => itemA.fullName === itemB.fullName
    );

    const groupedData = groupBy(uniqueSkills, constantData.fullName);

    return Object.keys(groupedData)
      .map((item) => ({
        label: item,
        value: item,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [data]);

  // Filter Profile Status
  const optionProfileStatus = useMemo(() => {
    if (data.length === 0) return [];

    const temp = data.map((item) => ({
      capability: `${item.capability} (${item.approvedText})`,
      profileStatus: item.profileStatus,
      text: `${item.capability} (${item.approvedText})`,
    }));
    // Deduplicate the combined array based on the capability.
    const uniqueArray = distinctArray(
      temp,
      (itemA, itemB) => itemA.capability === itemB.capability
    );

    // Generate the tree select structure.
    return generateTreeSelect(
      groupBy(uniqueArray, constantData.profileStatusName),
      constantData.capabilityName
    );
  }, [data]);

  // Filter Level of Skill Have
  const optionLorSkillLevel = useMemo(() => {
    // Exit early if there's no data to process.
    if (data.length === 0) return [];

    // Transform
    const combinedSkills = [...data].map((item) => ({
      skillCode: `${item.skillCode} - ${item.level}`,
      level: `Level ${item.level}`,
      text: `${item.skillCode} - ${item.level}`,
    }));

    // Deduplicate the combined array based on the skillCode.
    const uniqueSkills = distinctArray(
      combinedSkills,
      (itemA, itemB) => itemA.skillCode === itemB.skillCode
    );

    // Generate the tree select structure.
    return generateTreeSelect(
      groupBy(uniqueSkills, constantData.levelName),
      "skillCode"
    );
  }, [data]);

  // Filter Level of Generic Attribute Have
  const optionGenericAttribute = useMemo(() => {
    // Exit early if there's no data to process.
    if (genericAttributeData.length === 0) return [];

    // Transform
    const combinedSkills = [...genericAttributeData].map((item) => ({
      lorCode: `${item.lorCode} - ${item.level}`,
      level: `Level ${item.level}`,
      text: `${item.lorCode} - ${item.level}`,
    }));

    // Deduplicate the combined array based on the skillCode.
    const uniqueSkills = distinctArray(
      combinedSkills,
      (itemA, itemB) => itemA.lorCode === itemB.lorCode
    );

    // Generate the tree select structure.
    return generateTreeSelect(
      groupBy(uniqueSkills, constantData.levelName),
      constantData.lorCodeName
    );
  }, [genericAttributeData]);

  // Data Filter  => Ref By Data
  const dataFilter = useMemo(() => {
    let resultData = [];

    if (data.length) {
      resultData = data.filter((item) => {
        // If selectedName is populated and the condition is not met, filter out
        if (selectedName.length > 0 && !selectedName.includes(item.fullName)) {
          return false;
        }

        // If selectedProfile is populated and the condition is not met, filter out
        if (selectedProfile.length > 0) {
          const isFound = selectedProfile
            .map((_) => JSON.parse(_))
            .some((_) => {
              if (_.isRoot) {
                return item.profileStatus === _.value;
              } else {
                return `${item.capability} (${item.approvedText})` === _.value;
              }
            });
          if (!isFound) {
            return false;
          }
        }

        // If selectedLorSkill is populated and the condition is not met, filter out
        if (selectedLorSkill.length > 0) {
          const isFound = selectedLorSkill
            .map((_) => JSON.parse(_))
            .some((_) => {
              if (_.isRoot) {
                return `Level ${item.level}` === _.value;
              } else {
                return `${item.skillCode} - ${item.level}` === _.value;
              }
            });
          if (!isFound) {
            return false;
          }
        }

        // If all conditions are met or not applicable, include the item
        return true;
      });
    }

    return resultData;
  }, [data, selectedLorSkill, selectedName, selectedProfile]);

  // Data Filter => Ref By Data
  const dataFilterGenericAttribute = useMemo(() => {
    let resultData = [];

    if (genericAttributeData.length) {
      resultData = genericAttributeData.filter((item) => {
        // If selectedName is populated and the condition is not met, filter out
        if (selectedName.length > 0 && !selectedName.includes(item.fullName)) {
          return false;
        }

        // If selectedGeneric is populated and the condition is not met, filter out
        if (selectedGeneric.length > 0) {
          const isFound = selectedGeneric
            .map((_) => JSON.parse(_))
            .some((_) => {
              if (_.isRoot) {
                return `Level ${item.level}` === _.value;
              } else {
                return `${item.lorCode} - ${item.level}` === _.value;
              }
            });
          if (!isFound) {
            return false;
          }
        }

        // If all conditions are met or not applicable, include the item
        return true;
      });
    }

    return resultData;
  }, [genericAttributeData, selectedGeneric, selectedName]);

  const dashboardTableSkillCategory = useMemo(
    () => generateTableSkillCategory(dataFilter) ?? {},
    [dataFilter]
  ); // Recompute only if dataFilter changes

  const dashboardTableGeneric = useMemo(
    () =>
      dataFilterGenericAttribute.length > 0
        ? { generic: deepClone(dataFilterGenericAttribute) }
        : {},
    [dataFilterGenericAttribute]
  );

  const dashboardTableProfessional = useMemo(
    () =>
      dataFilter.length > 0 ? { professional: deepClone(dataFilter) } : {},
    [dataFilter]
  );

  // Use useMemo to ensure we only recompute the data when `dataFilter` changes.
  const dashboardBar = useMemo(
    () =>
      dataFilterGenericAttribute.length > 0
        ? generateBar(dataFilterGenericAttribute)
        : {},
    [dataFilterGenericAttribute]
  );

  useEffect(() => {
    // This variable helps us ensure that we don't call `setIsContentLoaded` if the component has unmounted before our asynchronous operation completes.
    let isMounted = true;

    processAllData().then(() => {
      if (isMounted) {
        // Only set the loading state to false if the component is still mounted
        setIsContentLoaded(true);
      }
    });

    // The cleanup function for the effect
    return () => {
      // If the component unmounts, we set isMounted to false to prevent any state updates after unmounting.
      isMounted = false;
    };
  }, []);

  // If content is still loading, display a spinner.
  if (!isContentLoaded) {
    return <DivCenter Component={() => <Spinner />} />;
  }

  return (
    <Container>
      <ContainerDefault>
        <SkillsWeHaveFilters
          objName={{
            options: optionsName,
            value: selectedName,
            setValue: setSelectedName,
          }}
          objProfile={{
            options: optionProfileStatus,
            value: selectedProfile,
            setValue: setSelectedProfile,
          }}
          objLorSkill={{
            options: optionLorSkillLevel,
            value: selectedLorSkill,
            setValue: setSelectedLorSkill,
          }}
          objGeneric={{
            options: optionGenericAttribute,
            value: selectedGeneric,
            setValue: setSelectedGeneric,
          }}
        />
      </ContainerDefault>
      <ContainerBorder>
        <ContainerContent>
          <SkillsWeHaveNeedTable data={dashboardTableSkillCategory} />
        </ContainerContent>
        <ContainerContent style={{ textAlign: "-webkit-center" }}>
          <Title level={5} className="text-left">
            Generic Attributes
          </Title>
          <div style={{ maxWidth: "900px" }}>
            <Bar data={dashboardBar} />
          </div>
        </ContainerContent>
      </ContainerBorder>
      <ContainerBorder className="mt-40">
        <ContainerContent>
          <SkillsWeHaveNeedTableNormal
            data={dashboardTableProfessional}
            isShowProfessional={true}
            pageSize={constantData.pageSize}
          />
          <SkillsWeHaveNeedTableNormal
            data={dashboardTableGeneric}
            isShowGeneric={true}
            pageSize={constantData.pageSize}
          />
        </ContainerContent>
      </ContainerBorder>
    </Container>
  );
};

export { SkillsWeHave };
