import * as authActions from "../../../store/actions/authAction";
import * as adminCategoryActions from "../../../store/admin/actions/adminCategoryActions";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import ExtraFrameworkCategoryDetail from "./ExtraFrameworkCategoryDetail";
import ExtraFrameworkSkillDetail from "./ExtraFrameworkSkillDetail";
import ExtraFrameworkSubCategoryDetail from "./ExtraFrameworkSubCategoryDetail";
import { faPlus, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Row, Spinner, Table, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const rowStyle = {
    fontSize: "12px",
};

class ManageExtraFrameworkCategories extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            extraFrameworkLevels: this.props.extraFrameworkLevels,
            categories: this.props.categories,
            selectedCategory: null,
            showDialogCategoryDetailTs: null,
            selectedSubCategory: null,
            showDialogSubCategoryDetailTs: null,
            selectedSkill: null,
            showDialogSkillDetailTs: null,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
    }

    componentDidMount() {
        this.props.onAdminCategory.getExtraFrameworkCategories().then(() => {
            this.setState({
                categories: this.props.categories,
            });

            this.setState({ loading: this.props.categoryLoading });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.extraFrameworkLevels !== this.props.extraFrameworkLevels) {
            this.setState({
                extraFrameworkLevels: this.props.extraFrameworkLevels,
            });
        }

        if (prevProps.categories !== this.props.categories) {
            this.setState({
                categories: this.props.categories,
            });
        }

        if (prevProps.forceRefreshExtraFrameworks !== this.props.forceRefreshExtraFrameworks && this.props.forceRefreshExtraFrameworks) {
            this.handleRefreshCategories();
        }
    }

    componentWillUnmount() {}

    handleRefreshCategories = () => {
        this.props.onAdminCategory.getExtraFrameworkCategories().then(() => {
            this.setState({
                categories: this.props.categories,
            });
        });
    };

    handleAddNewCategory = () => {
        this.setState({ showDialogCategoryDetailTs: new Date(), selectedCategory: null });
    };

    handleOpenEditCategory = (category) => {
        this.setState({ showDialogCategoryDetailTs: new Date(), selectedCategory: category });
    };

    handleDeleteCategory = (category) => {
        if (window.confirm("Are you sure want to delete this category?")) {
            this.setState({ isUpdating: true });

            this.props.onAdminCategory.deleteExtraFrameworkCategory(category.id).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.error) {
                        this.props.generateAlert("success", "Delete category success.");
                    } else {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    handleAddNewSubCategory = (category) => {
        this.setState({ showDialogSubCategoryDetailTs: new Date(), selectedCategory: category, selectedSubCategory: null });
    };

    handleOpenEditSubCategory = (category, subCategory) => {
        this.setState({ showDialogSubCategoryDetailTs: new Date(), selectedCategory: category, selectedSubCategory: subCategory });
    };

    handleDeleteSubCategory = (category, subCategory) => {
        if (window.confirm("Are you sure want to delete this sub category?")) {
            this.setState({ isUpdating: true });

            this.props.onAdminCategory.deleteExtraFrameworkSubCategory(category.id, subCategory.id).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.error) {
                        this.props.generateAlert("success", "Delete sub category success.");
                    } else {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    handleAddNewSkill = (category, subCategory) => {
        this.setState({ showDialogSkillDetailTs: new Date(), selectedCategory: category, selectedSubCategory: subCategory, selectedSkill: null });
    };

    handleOpenEditSkill = (category, subCategory, skill) => {
        this.setState({ showDialogSkillDetailTs: new Date(), selectedCategory: category, selectedSubCategory: subCategory, selectedSkill: skill });
    };

    handleDeleteSkill = (category, subCategory, skill) => {
        if (window.confirm("Are you sure want to delete this skill?")) {
            this.setState({ isUpdating: true });

            this.props.onAdminCategory.deleteExtraFrameworkSkill(category.id, subCategory.id, skill.id).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.error) {
                        this.props.generateAlert("success", "Delete skill success.");
                    } else {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    render() {
        const { pageAssignment } = this.props;
        const { extraFrameworkLevels, categories } = this.state;
        const showEditSkill = false;

        let allowManage = false;
        //allowManage = pageAssignment.operations.find((it) => it.operation == "Manage" && it.allow == true) ? true : false;

        return (
            <React.Fragment>
                <ExtraFrameworkCategoryDetail category={this.state.selectedCategory} showDialog={this.state.showDialogCategoryDetailTs} generateAlert={this.props.generateAlert}></ExtraFrameworkCategoryDetail>
                <ExtraFrameworkSubCategoryDetail category={this.state.selectedCategory} subCategory={this.state.selectedSubCategory} showDialog={this.state.showDialogSubCategoryDetailTs} generateAlert={this.props.generateAlert}></ExtraFrameworkSubCategoryDetail>
                <ExtraFrameworkSkillDetail
                    extraFrameworkLevels={extraFrameworkLevels}
                    category={this.state.selectedCategory}
                    subCategory={this.state.selectedSubCategory}
                    skill={this.state.selectedSkill}
                    showDialog={this.state.showDialogSkillDetailTs}
                    generateAlert={this.props.generateAlert}
                ></ExtraFrameworkSkillDetail>
                <p className="mb-0" style={{ color: "#2B2B2B", fontSize: "22px", fontFamily: "IBMPlexSans-Bold", paddingBottom: "5px" }}>
                    Extra Frameworks
                </p>
                <div>
                    <Row>
                        <Col md="6" sm="6">
                            <Button
                                color="primary"
                                onClick={this.handleRefreshCategories}
                                style={{
                                    fontSize: "12px",
                                    height: "35px",
                                    marginBottom: "10px",
                                }}
                                disabled={this.props.categoryLoading || this.state.isUpdating}
                            >
                                <FontAwesomeIcon icon={faSync} /> Refresh
                            </Button>
                            &nbsp;&nbsp;
                            {allowManage && (
                                <Button
                                    color="primary"
                                    onClick={this.handleAddNewCategory}
                                    style={{
                                        fontSize: "12px",
                                        height: "35px",
                                        marginBottom: "10px",
                                    }}
                                    disabled={this.props.categoryLoading || this.state.isUpdating}
                                >
                                    <FontAwesomeIcon icon={faPlus} /> Add Framework Category
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div>
                {categories && categories.length > 0 ? (
                    <React.Fragment>
                        <div style={{ fontSize: "11px" }}>
                            <Table>
                                {categories.map((category, index) => {
                                    var emptyLevels = [];
                                    for (let i = 1; i <= 7 - extraFrameworkLevels.length; i++) {
                                        emptyLevels.push(
                                            <React.Fragment key={`emptyLevel${category.id}${i}`}>
                                                <th
                                                    className={"th-fixwidth "}
                                                    style={{
                                                        borderBottom: "0",
                                                        borderTop: "0",
                                                        backgroundColor: `${category.colour}`,
                                                        verticalAlign: "middle",
                                                    }}
                                                ></th>
                                            </React.Fragment>
                                        );
                                    }

                                    return (
                                        <React.Fragment key={index}>
                                            <thead className="tableHeader">
                                                <tr>
                                                    <th
                                                        id={"group" + category.name.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                        colSpan={2}
                                                        className={"th-start"}
                                                        style={{
                                                            borderBottom: "0",
                                                            borderTop: "0",
                                                            backgroundColor: `${category.colour}`,
                                                            verticalAlign: "middle",
                                                        }}
                                                    >
                                                        {category.name}
                                                    </th>
                                                    <UncontrolledTooltip
                                                        target={"group" + category.name.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                        placement="bottom"
                                                        dangerouslySetInnerHTML={{
                                                            __html: category.description,
                                                        }}
                                                    ></UncontrolledTooltip>
                                                    {extraFrameworkLevels.map((extraFrameworkLevel, levelIndex) => {
                                                        return (
                                                            <React.Fragment key={levelIndex}>
                                                                <th
                                                                    className={"th-fixwidth "}
                                                                    style={{
                                                                        borderBottom: "0",
                                                                        borderTop: "0",
                                                                        backgroundColor: `${category.colour}`,
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    {extraFrameworkLevel.level}
                                                                </th>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                    {emptyLevels}
                                                    {
                                                        <th
                                                            className={"th-fixwidth "}
                                                            style={{
                                                                borderBottom: "0",
                                                                borderTop: "0",
                                                                backgroundColor: `${category.colour}`,
                                                                verticalAlign: "middle",
                                                            }}
                                                        >
                                                            Selection
                                                        </th>
                                                    }
                                                    {
                                                        <th
                                                            className={"th-fixwidth-170 " + " th-end"}
                                                            style={{
                                                                borderBottom: "0",
                                                                borderTop: "0",
                                                                backgroundColor: `${category.colour}`,
                                                                whiteSpace: "nowrap",
                                                                textAlign: "right",
                                                            }}
                                                        >
                                                            {allowManage && (
                                                                <React.Fragment>
                                                                    <button className={`btn btn-light btnEdit-Style`} onClick={() => this.handleOpenEditCategory(category)}>
                                                                        Edit
                                                                    </button>
                                                                    &nbsp;&nbsp;
                                                                    <button className={`btn btn-light btnEdit-Style`} onClick={() => this.handleDeleteCategory(category)}>
                                                                        Delete
                                                                    </button>
                                                                </React.Fragment>
                                                            )}
                                                        </th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {category.subCategories && category.subCategories.length > 0
                                                    ? category.subCategories.map((subCategory, index) => {
                                                          return (
                                                              <React.Fragment key={index}>
                                                                  <tr>
                                                                      <td
                                                                          id={"skill" + subCategory.name.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                          colSpan={10}
                                                                          className={"pt-1 pb-1 td-item "}
                                                                          style={{
                                                                              fontFamily: "IBMPlexSans-Bold",
                                                                              verticalAlign: "middle",
                                                                              backgroundColor: "#FFFFFF",
                                                                          }}
                                                                      >
                                                                          {subCategory.name}
                                                                      </td>
                                                                      <UncontrolledTooltip
                                                                          target={"skill" + subCategory.name.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                          placement="bottom"
                                                                          dangerouslySetInnerHTML={{
                                                                              __html: subCategory.description,
                                                                          }}
                                                                      ></UncontrolledTooltip>
                                                                      {
                                                                          <td style={{ whiteSpace: "nowrap", textAlign: "right", backgroundColor: "#FFFFFF", borderRight: !allowManage && "1px solid #FFFFFF", borderBottom: !allowManage && `1px solid #dee2e6` }} className="pt-1 pb-1 td-value">
                                                                              {allowManage && (
                                                                                  <React.Fragment>
                                                                                      <button className={`btn btn-light btnEdit-Style`} onClick={() => this.handleAddNewSubCategory(category)}>
                                                                                          New
                                                                                      </button>
                                                                                      &nbsp;&nbsp;
                                                                                      <button className={`btn btn-light btnEdit-Style`} onClick={() => this.handleOpenEditSubCategory(category, subCategory)}>
                                                                                          Edit
                                                                                      </button>
                                                                                      &nbsp;&nbsp;
                                                                                      <button className={`btn btn-light btnEdit-Style`} onClick={() => this.handleDeleteSubCategory(category, subCategory)}>
                                                                                          Delete
                                                                                      </button>
                                                                                  </React.Fragment>
                                                                              )}
                                                                          </td>
                                                                      }
                                                                  </tr>
                                                                  {subCategory.skills && subCategory.skills.length > 0
                                                                      ? subCategory.skills.map((skill, elIndex) => {
                                                                            var emptySkillLevels = [];
                                                                            for (let i = 1; i <= 7 - extraFrameworkLevels.length; i++) {
                                                                                emptySkillLevels.push(
                                                                                    <React.Fragment key={`emptySkillLevel${category.id}${i}`}>
                                                                                        <th
                                                                                            className={"th-fixwidth "}
                                                                                            style={{
                                                                                                borderBottom: "0",
                                                                                                borderTop: "0",
                                                                                                backgroundColor: "#FFFFFF",
                                                                                                verticalAlign: "middle",
                                                                                                borderRight: "1px solid #FFFFFF",
                                                                                                borderBottom: `1px solid #dee2e6`,
                                                                                            }}
                                                                                        ></th>
                                                                                    </React.Fragment>
                                                                                );
                                                                            }

                                                                            return (
                                                                                <tr key={elIndex}>
                                                                                    <td id={"category" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} className={"pt-1 pb-1 td-item"} style={{ verticalAlign: "middle", backgroundColor: "#FFFFFF" }}>
                                                                                        <Row>
                                                                                            <Col xs="12">{skill.name}</Col>
                                                                                        </Row>
                                                                                    </td>
                                                                                    <UncontrolledTooltip
                                                                                        target={"category" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                        placement="bottom"
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: skill.description,
                                                                                        }}
                                                                                    ></UncontrolledTooltip>
                                                                                    <td id={"skill" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")} className={"pt-1 pb-1 td-item"} style={{ verticalAlign: "middle", backgroundColor: "#FFFFFF" }}>
                                                                                        {skill.skillsCode}
                                                                                    </td>
                                                                                    <UncontrolledTooltip
                                                                                        target={"skill" + skill.skillsCode.replace(/ /g, "").replace(/&/g, "").replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                                                                        placement="bottom"
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: skill.description,
                                                                                        }}
                                                                                    ></UncontrolledTooltip>
                                                                                    {extraFrameworkLevels.map((extraFrameworkLevel, levelIndex) => {
                                                                                        const level = skill.levels ? skill.levels.find((it) => it.extraFrameworkLevelId == extraFrameworkLevel.id) : false;
                                                                                        return (
                                                                                            <React.Fragment key={levelIndex}>
                                                                                                <td
                                                                                                    id={`tdSkillLevel${skill.id}${extraFrameworkLevel.id}`}
                                                                                                    className={"pt-1 pb-1 td-value td-fixwidth "}
                                                                                                    style={{
                                                                                                        borderTop: "0",
                                                                                                        backgroundColor: level ? subCategory.skillColour : "#FFFFFF",
                                                                                                        verticalAlign: "middle",
                                                                                                        borderRight: "1px solid #FFFFFF",
                                                                                                        borderBottom: `1px solid #dee2e6`,
                                                                                                    }}
                                                                                                ></td>
                                                                                                {level && (
                                                                                                    <UncontrolledTooltip
                                                                                                        target={`tdSkillLevel${skill.id}${extraFrameworkLevel.id}`}
                                                                                                        placement="bottom"
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: level.description,
                                                                                                        }}
                                                                                                    ></UncontrolledTooltip>
                                                                                                )}
                                                                                            </React.Fragment>
                                                                                        );
                                                                                    })}
                                                                                    {emptySkillLevels}
                                                                                    {
                                                                                        <td
                                                                                            className={"pt-1 pb-1 td-value td-fixwidth "}
                                                                                            style={{
                                                                                                borderTop: "0",
                                                                                                backgroundColor: "#FFFFFF",
                                                                                                verticalAlign: "middle",
                                                                                                borderRight: "1px solid #FFFFFF",
                                                                                                borderBottom: `1px solid #dee2e6`,
                                                                                            }}
                                                                                        >
                                                                                            {skill && skill.levelSelection ? skill.levelSelection : "Multiple"}
                                                                                        </td>
                                                                                    }
                                                                                    {
                                                                                        <td
                                                                                            style={{ whiteSpace: "nowrap", textAlign: "right", backgroundColor: "#FFFFFF", borderRight: !allowManage && "1px solid #FFFFFF", borderBottom: !allowManage && `1px solid #dee2e6` }}
                                                                                            className="pt-1 pb-1 td-value"
                                                                                        >
                                                                                            {allowManage && (
                                                                                                <React.Fragment>
                                                                                                    <button className={`btn btn-light btnEdit-Style`} onClick={() => this.handleAddNewSkill(category, subCategory)}>
                                                                                                        New
                                                                                                    </button>
                                                                                                    &nbsp;&nbsp;
                                                                                                    <button className={`btn btn-light btnEdit-Style`} onClick={() => this.handleOpenEditSkill(category, subCategory, skill)}>
                                                                                                        Edit
                                                                                                    </button>
                                                                                                    &nbsp;&nbsp;
                                                                                                    <button className={`btn btn-light btnEdit-Style`} onClick={() => this.handleDeleteSkill(category, subCategory, skill)}>
                                                                                                        Delete
                                                                                                    </button>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </td>
                                                                                    }
                                                                                </tr>
                                                                            );
                                                                        })
                                                                      : allowManage && (
                                                                            <tr>
                                                                                <td
                                                                                    colSpan={10}
                                                                                    className={"pt-1 pb-1 td-item "}
                                                                                    style={{
                                                                                        fontFamily: "IBMPlexSans-Bold",
                                                                                        verticalAlign: "middle",
                                                                                        textAlign: "center",
                                                                                        backgroundColor: "#FFFFFF",
                                                                                    }}
                                                                                >
                                                                                    <button className={`btn btn-light btnEdit-Style`} onClick={() => this.handleAddNewSkill(category, subCategory)}>
                                                                                        Add New Skill
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                  {}
                                                              </React.Fragment>
                                                          );
                                                      })
                                                    : allowManage && (
                                                          <tr>
                                                              <td
                                                                  colSpan={10}
                                                                  className={"pt-1 pb-1 td-item "}
                                                                  style={{
                                                                      fontFamily: "IBMPlexSans-Bold",
                                                                      verticalAlign: "middle",
                                                                      textAlign: "center",
                                                                      backgroundColor: "#FFFFFF",
                                                                  }}
                                                              >
                                                                  <button className={`btn btn-light btnEdit-Style`} onClick={() => this.handleAddNewSubCategory(category)}>
                                                                      Add New Sub Category
                                                                  </button>
                                                              </td>
                                                          </tr>
                                                      )}
                                                <tr>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </React.Fragment>
                                    );
                                })}
                            </Table>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>No Data to Display</React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        authPersonId: state.auth.personId,

        isUpdating: state.adminCategory.isUpdating,
        categoryLoading: state.adminCategory.categoryLoading,
        categoryError: state.adminCategory.error,
        extraFrameworkLevels: state.adminCategory.extraFrameworkLevels,
        categories: state.adminCategory.categories,

        pageAssignments: state.adminPerson.pageAssignments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onAdminCategory: bindActionCreators(adminCategoryActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageExtraFrameworkCategories));
