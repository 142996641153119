import PersonService from "../../../../../common/api/service/PersonService";
import MentorTable from "../../table/mentor";
import ConfirmModal from "../confirm";
import { SaveOutlined } from "@ant-design/icons";
import { Button, Modal, Spin, notification, Row, Col, Alert } from "antd";
import React, { useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import { useImmer } from "use-immer";

const ContainerCenter = styled.div`
  text-align: -webkit-center;
`;

const MentorPreferencesModal = ({
  data,
  showDialog,
  updatePersonMentorPreference, contentTranslations, contentTranslator,
}) => {
  const [state, setState] = useImmer({
    showDialog: false,
    dataValue: [],
    isShowConfirm: false,
    isLoading: false,
    rawData: [],
    isEdit: false,
    contentTranslations: contentTranslations
  });

  const setDefaultData = () => {
    setState((draft) => {
      draft.showDialog = true;
      draft.dataValue = [];
      draft.isShowConfirm = false;
      draft.isEdit = false;
      draft.isLoading = true;
      draft.rawData = [];
    });
  };

  const onToggleDialogModal = () => {
    const showDialog = state.showDialog;
    setState((draft) => {
      draft.showDialog = !showDialog;
      draft.isLoading = false;
    });
  };

  const onSaveClicked = useCallback((e) => {
    setState((draft) => {
      draft.isShowConfirm = true;
    });
  });

  const onOkClicked = useCallback(async (e) => {
    setState((draft) => {
      draft.isLoading = true;
      draft.isShowConfirm = false;
    });
    try {
      const listUpdate = state.dataValue.map((item) => {
        return {
          id: item.id,
          mentorPreferenceId: item.mentorPreferenceId,
          selected: item.isChecked ?? false,
        };
      });
      await PersonService.updatePersonMyMentorPreferences(listUpdate);
      updatePersonMentorPreference();
      setDefaultData();
      onToggleDialogModal();
      notification["success"]({
        message: contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanMentorPreferencesSuccessTitle", "Success"),
        description: contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanMentorPreferencesSuccessDescription", "Mentor preferences is updated"),
      });
    } catch (error) {
      // Optionally, you can set an error state to display an error message to users.
      setState((draft) => {
        draft.isLoading = false;
      });
      notification["error"]({
        message: "error",
        description: error?.errData?.title,
      });
    }
  });

  const onCancelClicked = useCallback((e) => {
    setState((draft) => {
      draft.isShowConfirm = false;
    });
  });

  const onValueChanged = useCallback((val) => {
    setState((draft) => {
      if (!draft.isEdit) {
        draft.isEdit = true;
      }
      draft.dataValue = val ?? [];
    });
  });

  const isValid = useMemo(
    () =>
      state.isEdit
        ? !state.dataValue.some((item) => item.isChecked === true)
        : !state.rawData.some((item) => item.selected === true),
    [state.dataValue, state.rawData]
  );

  useEffect(() => {
    if (showDialog) {
      setDefaultData();
      setState((draft) => {
        draft.rawData = data.map((item) => {
          return {
            id: item.id,
            selected: item.selected,
            mentorPreferenceId: item.mentorPreferenceId,
            name: item.preference,
          };
        });
        draft.isLoading = false;
      });
    }
  }, [showDialog]);

  if (state.showDialog === false) return null;

  // ... rest of the logic and JSX
  return (
    <Modal
      title={state.isShowConfirm ? null : contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanMentorPreferencesDialogTitle", "Mentee Preferences")}
      closable={!state.isLoading && !state.isShowConfirm}
      open={state.showDialog}
      onCancel={
        !state.isUpdating
          ? () => {
              setDefaultData();
              onToggleDialogModal();
            }
          : null
      }
      width={state.isShowConfirm ? "fit-content" : "60%"}
      style={{ borderRadius: "20px" }}
      footer={null}
    >
      <Spin
        spinning={state.isLoading}
        size="large"
        tip="Waiting..."
        delay={200}
      >
        {state.isShowConfirm == false &&
          state.isLoading == false &&
          state.rawData.length > 0 && (
            <Row className="ml-3 mr-3">
              <Col span={20}>
                {isValid && (
                  <Alert
                    banner
                    message={contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanMentorPreferencesDialogNotes", "Please select at least one desired setting before save")}
                    type="warning"
                  />
                )}
              </Col>
              <Col span={4} className="pl-2  text-right">
                <button
                  className="btn btn-light btnSecondary-Style mb-2"
                  disabled={!state.isEdit || isValid}
                  onClick={onSaveClicked}
                >
                  {contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanMentorPreferencesConfirmButtonSave", "Save")}
                </button>
              </Col>
            </Row>
          )}
        <ContainerCenter>
          <ConfirmModal
            open={state.isShowConfirm}
            onOk={onOkClicked}
            onCancel={onCancelClicked}
            titleText={contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanMentorPreferencesConfirmUpdatePopupMessage", "You are about to save preferences for your possible mentors.")}
            msg={contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanMentorPreferencesConfirmSavePopupMessage", "We do NOT guarantee your mentor(s) will meet all the preferences.")}
            okText={contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanMentorPreferencesConfirmButtonOk", "Ok")}
            cancelText={contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanMentorPreferencesConfirmButtonCancel", "Cancel")}
          ></ConfirmModal>
        </ContainerCenter>

        {state.isShowConfirm == false && (
          <MentorTable
            refData={state.rawData}
            values={state.dataValue}
            nameHeader={contentTranslator(contentTranslations, "SfiaPlan", "SfiaPlanMentorPreferencesDialogTableHeader", "Preferences")}
            nameSelect="selected"
            onChange={onValueChanged}
          />
        )}
      </Spin>
    </Modal>
  );
};
export default MentorPreferencesModal;
