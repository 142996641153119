import * as actionTypes from "./adminActionTypes";

import AdminRelationshipTypeAPI from "./../../../common/api/adminservice/AdminRelationshipTypeService";

export const getRelationshipTypesStart = () => {
    return {
        type: actionTypes.GET_RELATIONSHIP_TYPES_START
    };
};

export const getRelationshipTypesSuccess = (relationshipTypes) => {
    return {
        type: actionTypes.GET_RELATIONSHIP_TYPES_SUCCESS,
        relationshipTypes
    };
};

export const getRelationshipTypesFail = error => {
    return {
        type: actionTypes.GET_RELATIONSHIP_TYPES_FAIL,
        error: error,
    };
};

export const getRelationshipTypes = () => {
    return dispatch => {
        dispatch(getRelationshipTypesStart());

        return AdminRelationshipTypeAPI.getRelationshipTypes()
            .then(res => {
                dispatch(getRelationshipTypesSuccess(res));
            })
            .catch(err => {
                dispatch(getRelationshipTypesFail(err));
            });
    };
};