import CategoryAPI from "./../../common/api/service/CategoryService";
import * as actionTypes from "./actionTypes";

export const getExtraFrameworkCapabilitiesStart = () => {
    return {
        type: actionTypes.GET_EXTRAFRAMEWORK_CAPABILITIES_START,
    };
};

export const getExtraFrameworkCapabilitiesSuccess = (extraFrameworkCapabilities) => {
    return {
        type: actionTypes.GET_EXTRAFRAMEWORK_CAPABILITIES_SUCCESS,
        extraFrameworkCapabilities,
    };
};

export const getExtraFrameworkCapabilitiesFail = (error) => {
    return {
        type: actionTypes.GET_EXTRAFRAMEWORK_CAPABILITIES_FAIL,
        error: error,
    };
};

export const getExtraFrameworkCapabilities = () => {
    return (dispatch) => {
        dispatch(getExtraFrameworkCapabilitiesStart());

        return CategoryAPI.getExtraFrameworkCapabilities()
            .then((res) => {
                if (res) {
                    dispatch(getExtraFrameworkCapabilitiesSuccess(res));
                } else {
                    dispatch(getExtraFrameworkCapabilitiesFail(res));
                }
            })
            .catch((err) => {
                dispatch(getExtraFrameworkCapabilitiesFail(err));
            });
    };
};

export const getExtraFrameworkLevelsStart = () => {
    return {
        type: actionTypes.GET_EXTRAFRAMEWORK_LEVELS_START,
    };
};

export const getExtraFrameworkLevelsSuccess = (extraFrameworkLevels) => {
    return {
        type: actionTypes.GET_EXTRAFRAMEWORK_LEVELS_SUCCESS,
        extraFrameworkLevels,
    };
};

export const getExtraFrameworkLevelsFail = (error) => {
    return {
        type: actionTypes.GET_EXTRAFRAMEWORK_LEVELS_FAIL,
        error: error,
    };
};

export const getExtraFrameworkLevels = () => {
    return (dispatch) => {
        dispatch(getExtraFrameworkLevelsStart());

        return CategoryAPI.getExtraFrameworkLevels()
            .then((res) => {
                if (res) {
                    dispatch(getExtraFrameworkLevelsSuccess(res));
                } else {
                    dispatch(getExtraFrameworkLevelsFail(res));
                }
            })
            .catch((err) => {
                dispatch(getExtraFrameworkLevelsFail(err));
            });
    };
};

export const getCategoriesStart = () => {
    return {
        type: actionTypes.GET_CATEGORIES_START,
    };
};

export const getCategoriesSuccess = (categories) => {
    return {
        type: actionTypes.GET_CATEGORIES_SUCCESS,
        categories,
    };
};

export const getCategoriesFail = (error) => {
    return {
        type: actionTypes.GET_CATEGORIES_FAIL,
        error: error,
    };
};

export const getCategories = (includeAccountAttributes, languageId) => {
    return (dispatch) => {
        dispatch(getCategoriesStart());

        return CategoryAPI.getCategories(includeAccountAttributes, languageId)
            .then((res) => {
                if (res) {
                    dispatch(getCategoriesSuccess(res));
                } else {
                    dispatch(getCategoriesFail(res));
                }
            })
            .catch((err) => {
                dispatch(getCategoriesFail(err));
            });
    };
};

export const addSkillAttributeStart = () => {
    return {
        type: actionTypes.ADD_SKILL_ATTRIBUTE_DATA_START,
    };
};

export const addSkillAttributeSuccess = (categoryId, subCategoryId, skillId, addedAttribute) => {
    return {
        type: actionTypes.ADD_SKILL_ATTRIBUTE_DATA_SUCCESS,
        categoryId,
        subCategoryId,
        skillId,
        addedAttribute,
    };
};

export const addSkillAttributeFail = (error) => {
    return {
        type: actionTypes.ADD_SKILL_ATTRIBUTE_DATA_FAIL,
        error: error,
    };
};

export const addSkillAttribute = (categoryId, subCategoryId, skillId, attribute) => {
    return (dispatch) => {
        dispatch(addSkillAttributeStart());

        return CategoryAPI.addSkillAttribute(categoryId, subCategoryId, skillId, attribute)
            .then((res) => {
                if (res) {
                    dispatch(addSkillAttributeSuccess(categoryId, subCategoryId, skillId, res));
                } else {
                    dispatch(addSkillAttributeFail(res));
                }
            })
            .catch((err) => {
                dispatch(addSkillAttributeFail(err));
            });
    };
};

export const updateSkillAttributeStart = () => {
    return {
        type: actionTypes.UPDATE_SKILL_ATTRIBUTE_DATA_START,
    };
};

export const updateSkillAttributeSuccess = (categoryId, subCategoryId, skillId, updatedAttribute) => {
    return {
        type: actionTypes.UPDATE_SKILL_ATTRIBUTE_DATA_SUCCESS,
        categoryId,
        subCategoryId,
        skillId,
        updatedAttribute,
    };
};

export const updateSkillAttributeFail = (error) => {
    return {
        type: actionTypes.UPDATE_SKILL_ATTRIBUTE_DATA_FAIL,
        error: error,
    };
};

export const updateSkillAttribute = (categoryId, subCategoryId, skillId, attributeId, attribute) => {
    return (dispatch) => {
        dispatch(updateSkillAttributeStart());

        return CategoryAPI.updateSkillAttribute(categoryId, subCategoryId, skillId, attributeId, attribute)
            .then((res) => {
                if (res) {
                    dispatch(updateSkillAttributeSuccess(categoryId, subCategoryId, skillId, res));
                } else {
                    dispatch(updateSkillAttributeFail(res));
                }
            })
            .catch((err) => {
                dispatch(updateSkillAttributeFail(err));
            });
    };
};

export const deleteSkillAttributeStart = () => {
    return {
        type: actionTypes.DELETE_SKILL_ATTRIBUTE_DATA_START,
    };
};

export const deleteSkillAttributeSuccess = (categoryId, subCategoryId, skillId, deletedAttributeId) => {
    return {
        type: actionTypes.DELETE_SKILL_ATTRIBUTE_DATA_SUCCESS,
        categoryId,
        subCategoryId,
        skillId,
        deletedAttributeId,
    };
};

export const deleteSkillAttributeFail = (error) => {
    return {
        type: actionTypes.DELETE_SKILL_ATTRIBUTE_DATA_FAIL,
        error: error,
    };
};

export const deleteSkillAttribute = (categoryId, subCategoryId, skillId, attributeId) => {
    return (dispatch) => {
        dispatch(deleteSkillAttributeStart());

        return CategoryAPI.deleteSkillAttribute(categoryId, subCategoryId, skillId, attributeId)
            .then((res) => {
                if (res) {
                    dispatch(deleteSkillAttributeSuccess(categoryId, subCategoryId, skillId, attributeId));
                } else {
                    dispatch(deleteSkillAttributeFail(res));
                }
            })
            .catch((err) => {
                dispatch(deleteSkillAttributeFail(err));
            });
    };
};

export const updateSkillSetIsHiddenStart = () => {
    return {
        type: actionTypes.UPDATE_SKILL_SET_ISHIDDEN_START,
    };
};

export const updateSkillSetIsHiddenSuccess = (categoryId, subCategoryId, skillId, isHidden) => {
    return {
        type: actionTypes.UPDATE_SKILL_SET_ISHIDDEN_SUCCESS,
        categoryId,
        subCategoryId,
        skillId,
        isHidden,
    };
};

export const updateSkillSetIsHiddenFail = (error) => {
    return {
        type: actionTypes.UPDATE_SKILL_SET_ISHIDDEN_FAIL,
        error: error,
    };
};

export const updateSkillSetIsHidden = (categoryId, subCategoryId, skillId, isHidden) => {
    return (dispatch) => {
        dispatch(updateSkillSetIsHiddenStart());

        return CategoryAPI.updateSkillIsHidden(categoryId, subCategoryId, skillId, isHidden)
            .then((res) => {
                if (res) {
                    dispatch(updateSkillSetIsHiddenSuccess(categoryId, subCategoryId, skillId, isHidden));
                } else {
                    dispatch(updateSkillSetIsHiddenFail(res));
                }
            })
            .catch((err) => {
                dispatch(updateSkillSetIsHiddenFail(err));
            });
    };
};

export const exportSkillAttributesDataExcelStart = () => {
    return {
        type: actionTypes.EXPORT_SKILL_ATTRIBUTES_DATA_START,
    };
};

export const exportSkillAttributesDataExcelSuccess = (exportData) => {
    return {
        type: actionTypes.EXPORT_SKILL_ATTRIBUTES_DATA_SUCCESS,
        exportData,
    };
};

export const exportSkillAttributesDataExcelFail = (error) => {
    return {
        type: actionTypes.EXPORT_SKILL_ATTRIBUTES_DATA_FAIL,
        error: error,
    };
};

export const exportSkillAttributesDataExcel = () => {
    return (dispatch) => {
        dispatch(exportSkillAttributesDataExcelStart());

        return CategoryAPI.exportSkillAttributesDataExcel()
            .then((res) => {
                dispatch(exportSkillAttributesDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportSkillAttributesDataExcelFail(err));
            });
    };
};

export const exportSkillAttributesDataExcelTemplate = () => {
    return (dispatch) => {
        dispatch(exportSkillAttributesDataExcelStart());

        return CategoryAPI.exportSkillAttributesDataExcelTemplate()
            .then((res) => {
                dispatch(exportSkillAttributesDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportSkillAttributesDataExcelFail(err));
            });
    };
};

export const importSkillAttributesDataExcelStart = () => {
    return {
        type: actionTypes.IMPORT_SKILL_ATTRIBUTES_DATA_START,
    };
};

export const importSkillAttributesDataExcelSuccess = () => {
    return {
        type: actionTypes.IMPORT_SKILL_ATTRIBUTES_DATA_SUCCESS,
    };
};

export const importSkillAttributesDataExcelFail = (error) => {
    return {
        type: actionTypes.IMPORT_SKILL_ATTRIBUTES_DATA_FAIL,
        error: error,
    };
};

export const importSkillAttributesDataExcel = (attributesData) => {
    return (dispatch) => {
        dispatch(importSkillAttributesDataExcelStart());

        return CategoryAPI.importAttributesDataExcel(attributesData)
            .then((res) => {
                dispatch(importSkillAttributesDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(importSkillAttributesDataExcelFail(err));
            });
    };
};

export const exportSkillsHiddenDataExcelStart = () => {
    return {
        type: actionTypes.EXPORT_SKILLS_HIDDEN_DATA_START,
    };
};

export const exportSkillsHiddenDataExcelSuccess = (exportData) => {
    return {
        type: actionTypes.EXPORT_SKILLS_HIDDEN_DATA_SUCCESS,
        exportData,
    };
};

export const exportSkillsHiddenDataExcelFail = (error) => {
    return {
        type: actionTypes.EXPORT_SKILLS_HIDDEN_DATA_FAIL,
        error: error,
    };
};

export const exportSkillsHiddenDataExcel = () => {
    return (dispatch) => {
        dispatch(exportSkillsHiddenDataExcelStart());

        return CategoryAPI.exportSkillsHiddenDataExcel()
            .then((res) => {
                dispatch(exportSkillsHiddenDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportSkillsHiddenDataExcelFail(err));
            });
    };
};

export const importSkillsHiddenDataExcelStart = () => {
    return {
        type: actionTypes.IMPORT_SKILLS_HIDDEN_DATA_START,
    };
};

export const importSkillsHiddenDataExcelSuccess = () => {
    return {
        type: actionTypes.IMPORT_SKILLS_HIDDEN_DATA_SUCCESS,
    };
};

export const importSkillsHiddenDataExcelFail = (error) => {
    return {
        type: actionTypes.IMPORT_SKILLS_HIDDEN_DATA_FAIL,
        error: error,
    };
};

export const importSkillsHiddenDataExcel = (skillsHiddenData) => {
    return (dispatch) => {
        dispatch(importSkillsHiddenDataExcelStart());

        return CategoryAPI.importSkillsHiddenDataExcel(skillsHiddenData)
            .then((res) => {
                dispatch(importSkillsHiddenDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(importSkillsHiddenDataExcelFail(err));
            });
    };
};