import AdminProfileAPI from "../../../common/api/adminservice/AdminProfileService";
import AdminPersonAPI from "./../../../common/api/adminservice/AdminPersonService";
import * as actionTypes from "./adminActionTypes";

export const getPersonsStart = () => {
    return {
        type: actionTypes.GET_PERSONS_START,
    };
};

export const getPersonsSuccess = (persons) => {
    return {
        type: actionTypes.GET_PERSONS_SUCCESS,
        persons,
    };
};

export const getPersonsFail = (error) => {
    return {
        type: actionTypes.GET_PERSONS_FAIL,
        error: error,
    };
};

export const getPersons = () => {
    return (dispatch) => {
        dispatch(getPersonsStart());

        return AdminPersonAPI.getPersons()
            .then((res) => {
                dispatch(getPersonsSuccess(res));
            })
            .catch((err) => {
                dispatch(getPersonsFail(err));
            });
    };
};

export const getPersonsIncRelationships = () => {
    return (dispatch) => {
        dispatch(getPersonsStart());

        return AdminPersonAPI.getPersonsIncRelationships(true)
            .then((res) => {
                dispatch(getPersonsSuccess(res));
            })
            .catch((err) => {
                dispatch(getPersonsFail(err));
            });
    };
};

export const getPersonsIncEndorsements = () => {
    return (dispatch) => {
        dispatch(getPersonsStart());

        return AdminPersonAPI.getPersons(true)
            .then((res) => {
                dispatch(getPersonsSuccess(res));
            })
            .catch((err) => {
                dispatch(getPersonsFail(err));
            });
    };
};

export const getPersonsIncludeCopySelfProfileStatus = () => {
    return (dispatch) => {
        dispatch(getPersonsStart());

        return AdminPersonAPI.getPersons(false, true)
            .then((res) => {
                dispatch(getPersonsSuccess(res));
            })
            .catch((err) => {
                dispatch(getPersonsFail(err));
            });
    };
};

export const getPersonsIncSelfAssessed = () => {
    return (dispatch) => {
        dispatch(getPersonsStart());

        return AdminPersonAPI.getPersons(false, false, false, true)
            .then((res) => {
                dispatch(getPersonsSuccess(res));
            })
            .catch((err) => {
                dispatch(getPersonsFail(err));
            });
    };
};

export const getPersonsSfiaCredentials = () => {
    return (dispatch) => {
        dispatch(getPersonsStart());

        return AdminPersonAPI.getPersonsSfiaCredentials()
            .then((res) => {
                dispatch(getPersonsSuccess(res));
            })
            .catch((err) => {
                dispatch(getPersonsFail(err));
            });
    };
};

export const getPersonsAdminStart = () => {
    return {
        type: actionTypes.GET_ADMIN_PERSONS_START,
    };
};

export const getPersonsAdminSuccess = (persons) => {
    return {
        type: actionTypes.GET_ADMIN_PERSONS_SUCCESS,
        persons,
    };
};

export const getPersonsAdminFail = (error) => {
    return {
        type: actionTypes.GET_ADMIN_PERSONS_FAIL,
        error: error,
    };
};

export const getPersonsAdmin = () => {
    return (dispatch) => {
        dispatch(getPersonsAdminStart());

        return AdminPersonAPI.getPersons(false, false, true)
            .then((res) => {
                dispatch(getPersonsAdminSuccess(res));
            })
            .catch((err) => {
                dispatch(getPersonsAdminFail(err));
            });
    };
};

export const addPersonStart = () => {
    return {
        type: actionTypes.ADD_PERSON_START,
    };
};

export const addPersonSuccess = (person) => {
    return {
        type: actionTypes.ADD_PERSON_SUCCESS,
        person,
    };
};

export const addPersonFail = (error) => {
    return {
        type: actionTypes.ADD_PERSON_FAIL,
        error: error,
    };
};

export const addPerson = (person) => {
    return (dispatch) => {
        dispatch(addPersonStart());

        return AdminPersonAPI.addPerson(person)
            .then((res) => {
                dispatch(addPersonSuccess(res));
            })
            .catch((err) => {
                dispatch(addPersonFail(err));
            });
    };
};

export const updatePersonStart = () => {
    return {
        type: actionTypes.UPDATE_PERSON_START,
    };
};

export const updatePersonSuccess = (person) => {
    return {
        type: actionTypes.UPDATE_PERSON_SUCCESS,
        person,
    };
};

export const updatePersonFail = (error) => {
    return {
        type: actionTypes.UPDATE_PERSON_FAIL,
        error: error,
    };
};

export const updatePerson = (id, person) => {
    return (dispatch) => {
        dispatch(updatePersonStart());

        return AdminPersonAPI.updatePerson(id, person)
            .then((res) => {
                dispatch(updatePersonSuccess(res));
            })
            .catch((err) => {
                dispatch(updatePersonFail(err));
            });
    };
};

export const deletePersonStart = () => {
    return {
        type: actionTypes.DELETE_PERSON_START,
    };
};

export const deletePersonSuccess = (id) => {
    return {
        type: actionTypes.DELETE_PERSON_SUCCESS,
        id,
    };
};

export const deletePersonFail = (error) => {
    return {
        type: actionTypes.DELETE_PERSON_FAIL,
        error: error,
    };
};

export const deletePerson = (id) => {
    return (dispatch) => {
        dispatch(deletePersonStart());

        return AdminPersonAPI.deletePerson(id)
            .then((res) => {
                dispatch(deletePersonSuccess(id));
            })
            .catch((err) => {
                dispatch(deletePersonFail(err));
            });
    };
};

export const resetPersonPasswordStart = () => {
    return {
        type: actionTypes.RESET_PERSON_PASSWORD_START,
    };
};

export const resetPersonPasswordSuccess = (id) => {
    return {
        type: actionTypes.RESET_PERSON_PASSWORD_SUCCESS,
        id,
    };
};

export const resetPersonPasswordFail = (error) => {
    return {
        type: actionTypes.RESET_PERSON_PASSWORD_FAIL,
        error: error,
    };
};

export const resetPersonPassword = (id) => {
    return (dispatch) => {
        dispatch(resetPersonPasswordStart());

        return AdminPersonAPI.resetPersonPassword(id)
            .then((res) => {
                dispatch(resetPersonPasswordSuccess(id));
            })
            .catch((err) => {
                dispatch(resetPersonPasswordFail(err));
            });
    };
};

export const exportPersonsDataExcelStart = () => {
    return {
        type: actionTypes.EXPORT_PERSONS_DATA_START,
    };
};

export const exportPersonsDataExcelSuccess = (printData) => {
    return {
        type: actionTypes.EXPORT_PERSONS_DATA_SUCCESS,
        printData,
    };
};

export const exportPersonsDataExcelFail = (error) => {
    return {
        type: actionTypes.EXPORT_PERSONS_DATA_FAIL,
        error: error,
    };
};

export const exportPersonsDataExcel = (timezoneOffset) => {
    return (dispatch) => {
        dispatch(exportPersonsDataExcelStart());

        return AdminPersonAPI.exportPersonsDataExcel(timezoneOffset)
            .then((res) => {
                dispatch(exportPersonsDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportPersonsDataExcelFail(err));
            });
    };
};

export const exportPersonsDataExcelTemplate = () => {
    return (dispatch) => {
        dispatch(exportPersonsDataExcelStart());

        return AdminPersonAPI.exportPersonsDataExcelTemplate()
            .then((res) => {
                dispatch(exportPersonsDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportPersonsDataExcelFail(err));
            });
    };
};

export const exportPersonsAssessmentProgressDataExcel = (timezoneOffset) => {
    return (dispatch) => {
        dispatch(exportPersonsDataExcelStart());

        return AdminPersonAPI.exportPersonsAssessmentProgressDataExcel(timezoneOffset)
            .then((res) => {
                dispatch(exportPersonsDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportPersonsDataExcelFail(err));
            });
    };
};

export const exportEndorsementsDataExcel = (timezoneOffset) => {
    return (dispatch) => {
        dispatch(exportPersonsDataExcelStart());

        return AdminPersonAPI.exportEndorsementsDataExcel(timezoneOffset)
            .then((res) => {
                dispatch(exportPersonsDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportPersonsDataExcelFail(err));
            });
    };
};

export const importPersonsDataExcelStart = () => {
    return {
        type: actionTypes.IMPORT_PERSONS_DATA_START,
    };
};

export const importPersonsDataExcelSuccess = (message, importedPersons) => {
    return {
        type: actionTypes.IMPORT_PERSONS_DATA_SUCCESS,
        message,
        importedPersons,
    };
};

export const importPersonsDataExcelFail = (error) => {
    return {
        type: actionTypes.IMPORT_PERSONS_DATA_FAIL,
        error: error,
    };
};

export const importPersonsDataExcel = (operation, personsDataFiles) => {
    return (dispatch) => {
        dispatch(importPersonsDataExcelStart());

        return AdminPersonAPI.importPersonsDataExcel(operation, personsDataFiles)
            .then((res) => {
                dispatch(importPersonsDataExcelSuccess(res.message, res.personResponses));
            })
            .catch((err) => {
                dispatch(importPersonsDataExcelFail(err));
            });
    };
};

export const generatePersonProfileReportsStart = () => {
    return {
        type: actionTypes.GENERATE_PERSON_PROFILE_REPORTS_START,
    };
};

export const generatePersonProfileReportsSuccess = (printData) => {
    return {
        type: actionTypes.GENERATE_PERSON_PROFILE_REPORTS_SUCCESS,
        printData,
    };
};

export const generatePersonProfileReportsFail = (error) => {
    return {
        type: actionTypes.GENERATE_PERSON_PROFILE_REPORTS_FAIL,
        error: error,
    };
};

export const generatePersonProfileReports = (personId, skillProfile, actionPlan, endorsedProfile) => {
    return (dispatch) => {
        dispatch(generatePersonProfileReportsStart());

        return AdminPersonAPI.generatePersonProfileReports(personId, skillProfile, actionPlan, endorsedProfile)
            .then((res) => {
                if (res) {
                    dispatch(generatePersonProfileReportsSuccess(res));
                } else {
                    dispatch(generatePersonProfileReportsFail(res));
                }
            })
            .catch((err) => {
                dispatch(generatePersonProfileReportsFail(err));
            });
    };
};

export const generateProfileReportsStart = () => {
    return {
        type: actionTypes.GENERATE_PROFILE_REPORTS_START,
    };
};

export const generateProfileReportsSuccess = (printData, updatedPersons) => {
    return {
        type: actionTypes.GENERATE_PROFILE_REPORTS_SUCCESS,
        printData,
        updatedPersons,
    };
};

export const generateProfileReportsFail = (error) => {
    return {
        type: actionTypes.GENERATE_PROFILE_REPORTS_FAIL,
        error: error,
    };
};

export const generateProfileReports = (request) => {
    return (dispatch) => {
        dispatch(generateProfileReportsStart());

        return AdminPersonAPI.generateProfileReports(request)
            .then((res) => {
                if (res) {
                    dispatch(generateProfileReportsSuccess(res, request.reportRequests));
                } else {
                    dispatch(generateProfileReportsFail(res));
                }
            })
            .catch((err) => {
                dispatch(generateProfileReportsFail(err));
            });
    };
};

export const downloadProfileReports = (token) => {
    return (dispatch) => {
        dispatch(generateProfileReportsStart());

        return AdminPersonAPI.downloadProfileReports(token)
            .then((res) => {
                if (res) {
                    dispatch(generateProfileReportsSuccess(res, []));
                } else {
                    dispatch(generateProfileReportsFail(res));
                }
            })
            .catch((err) => {
                dispatch(generateProfileReportsFail(err));
            });
    };
};

export const generateProfileReportsToEmail = (request) => {
    return (dispatch) => {
        AdminPersonAPI.generateProfileReportsToEmail(request);        
    };
};

export const updatePersonRolesStart = () => {
    return {
        type: actionTypes.UPDATE_PERSON_ROLES_START,
    };
};

export const updatePersonRolesSuccess = (roles) => {
    return {
        type: actionTypes.UPDATE_PERSON_ROLES_SUCCESS,
        roles,
    };
};

export const updatePersonRolesFail = (error) => {
    return {
        type: actionTypes.UPDATE_PERSON_ROLES_FAIL,
        error: error,
    };
};

export const updatePersonRoles = (personId, roles) => {
    return (dispatch) => {
        dispatch(updatePersonRolesStart());

        return AdminPersonAPI.updatePersonRoles(personId, roles)
            .then((res) => {
                dispatch(updatePersonRolesSuccess(res));
            })
            .catch((err) => {
                dispatch(updatePersonRolesFail(err));
            });
    };
};

export const assignPersonsStart = () => {
    return {
        type: actionTypes.ASSIGN_PERSONS_START,
    };
};

export const assignPersonsSuccess = (personIds) => {
    return {
        type: actionTypes.ASSIGN_PERSONS_SUCCESS,
        personIds,
    };
};

export const assignPersonsFail = (error) => {
    return {
        type: actionTypes.ASSIGN_PERSONS_FAIL,
        error: error,
    };
};

export const assignPersons = (personIds) => {
    return (dispatch) => {
        dispatch(assignPersonsStart());

        return AdminPersonAPI.assignPersons(personIds)
            .then((res) => {
                dispatch(assignPersonsSuccess(personIds));
            })
            .catch((err) => {
                dispatch(assignPersonsFail(err));
            });
    };
};

export const unassignPersonsStart = () => {
    return {
        type: actionTypes.UNASSIGN_PERSONS_START,
    };
};

export const unassignPersonsSuccess = (personIds) => {
    return {
        type: actionTypes.UNASSIGN_PERSONS_SUCCESS,
        personIds,
    };
};

export const unassignPersonsFail = (error) => {
    return {
        type: actionTypes.UNASSIGN_PERSONS_FAIL,
        error: error,
    };
};

export const unassignPersons = (personIds) => {
    return (dispatch) => {
        dispatch(unassignPersonsStart());

        return AdminPersonAPI.unAssignPersons(personIds)
            .then((res) => {
                dispatch(unassignPersonsSuccess(personIds));
            })
            .catch((err) => {
                dispatch(unassignPersonsFail(err));
            });
    };
};

export const invitePersonsStart = () => {
    return {
        type: actionTypes.INVITE_PERSONS_START,
    };
};

export const invitePersonsSuccess = (personIds) => {
    return {
        type: actionTypes.INVITE_PERSONS_SUCCESS,
        personIds,
    };
};

export const invitePersonsFail = (error) => {
    return {
        type: actionTypes.INVITE_PERSONS_FAIL,
        error: error,
    };
};

export const invitePersons = (personIds) => {
    return (dispatch) => {
        dispatch(invitePersonsStart());

        return AdminPersonAPI.invitePersons(personIds)
            .then((res) => {
                dispatch(invitePersonsSuccess(personIds));
            })
            .catch((err) => {
                dispatch(invitePersonsFail(err));
            });
    };
};

export const getPageAssignmentsStart = () => {
    return {
        type: actionTypes.GET_PAGE_ASSIGNMENTS_START,
    };
};

export const getPageAssignmentsSuccess = (pageAssignments) => {
    return {
        type: actionTypes.GET_PAGE_ASSIGNMENTS_SUCCESS,
        pageAssignments,
    };
};

export const getPageAssignmentsFail = (error) => {
    return {
        type: actionTypes.GET_PAGE_ASSIGNMENTS_FAIL,
        error: error,
    };
};

export const getPageAssignments = () => {
    return (dispatch) => {
        dispatch(getPageAssignmentsStart());

        return AdminPersonAPI.getPageAssignments()
            .then((res) => {
                dispatch(getPageAssignmentsSuccess(res));
            })
            .catch((err) => {
                dispatch(getPageAssignmentsFail(err));
            });
    };
};

export const clearPageAssignmentsState = () => {
    return {
        type: actionTypes.CLEAR_PAGE_ASSIGNMENTS,
    };
};

export const getPersonPageAssignmentsStart = () => {
    return {
        type: actionTypes.GET_PERSON_PAGE_ASSIGNMENTS_START,
    };
};

export const getPersonPageAssignmentsSuccess = (pageAssignments) => {
    return {
        type: actionTypes.GET_PERSON_PAGE_ASSIGNMENTS_SUCCESS,
        pageAssignments,
    };
};

export const getPersonPageAssignmentsFail = (error) => {
    return {
        type: actionTypes.GET_PERSON_PAGE_ASSIGNMENTS_FAIL,
        error: error,
    };
};

export const getPersonPageAssignments = (personId) => {
    return (dispatch) => {
        dispatch(getPersonPageAssignmentsStart());

        return AdminPersonAPI.getPersonPageAssignments(personId)
            .then((res) => {
                dispatch(getPersonPageAssignmentsSuccess(res));
            })
            .catch((err) => {
                dispatch(getPersonPageAssignmentsFail(err));
            });
    };
};

export const updatePersonPageAssignmentsStart = () => {
    return {
        type: actionTypes.UPDATE_PERSON_PAGE_ASSIGNMENTS_START,
    };
};

export const updatePersonPageAssignmentsSuccess = (pageAssignments) => {
    return {
        type: actionTypes.UPDATE_PERSON_PAGE_ASSIGNMENTS_SUCCESS,
        pageAssignments,
    };
};

export const updatePersonPageAssignmentsFail = (error) => {
    return {
        type: actionTypes.UPDATE_PERSON_PAGE_ASSIGNMENTS_FAIL,
        error: error,
    };
};

export const updatePersonPageAssignments = (personId, pageAssignments) => {
    return (dispatch) => {
        dispatch(updatePersonPageAssignmentsStart());

        return AdminPersonAPI.updatePersonPageAssignments(personId, pageAssignments)
            .then((res) => {
                dispatch(updatePersonPageAssignmentsSuccess(res));
            })
            .catch((err) => {
                dispatch(updatePersonPageAssignmentsFail(err));
            });
    };
};

export const updatePersonEndorsement = (personEndorsement) => {
    return {
        type: actionTypes.UPDATE_PERSON_ENDORSEMENT,
        personEndorsement,
    };
};

export const updatePersonSelfAssessedApproved = (updatedPerson) => {
    return {
        type: actionTypes.UPDATE_PERSON_SELFASSESSED_APPROVED,
        updatedPerson,
    };
};

export const updatePersonSelfAssessedPostPoned = (updatedPerson) => {
    return {
        type: actionTypes.UPDATE_PERSON_SELFASSESSED_POSTPONED,
        updatedPerson,
    };
};

export const updatePersonEndorserStart = () => {
    return {
        type: actionTypes.UPDATE_PERSON_ENDORSER_START,
    };
};

export const updatePersonEndorserSuccess = (personEndorsement) => {
    return {
        type: actionTypes.UPDATE_PERSON_ENDORSER_SUCCESS,
        personEndorsement,
    };
};

export const updatePersonEndorserFail = (error) => {
    return {
        type: actionTypes.UPDATE_PERSON_ENDORSER_FAIL,
        error: error,
    };
};

export const updatePersonEndorser = (personId, endorsedById, endorsedBy) => {
    return (dispatch) => {
        dispatch(updatePersonEndorserStart());

        return AdminPersonAPI.updatePersonEndorser(personId, endorsedById, endorsedBy)
            .then((res) => {
                dispatch(updatePersonEndorserSuccess(res));
            })
            .catch((err) => {
                dispatch(updatePersonEndorserFail(err));
            });
    };
};

export const updatePersonEndorsementAssessor = (personId, assessorId, assessorName) => {
    return (dispatch) => {
        dispatch(updatePersonEndorserStart());

        return AdminPersonAPI.updatePersonEndorsementAssessor(personId, assessorId, assessorName)
            .then((res) => {
                dispatch(updatePersonEndorserSuccess(res));
            })
            .catch((err) => {
                dispatch(updatePersonEndorserFail(err));
            });
    };
};

export const getColumnDisplaySettingsStart = () => {
    return {
        type: actionTypes.GET_ADMIN_COLUMN_DISPLAY_SETTINGS_START,
    };
};

export const getColumnDisplaySettingsSuccess = (columnDisplaySettings) => {
    return {
        type: actionTypes.GET_ADMIN_COLUMN_DISPLAY_SETTINGS_SUCCESS,
        columnDisplaySettings,
    };
};

export const getColumnDisplaySettingsFail = (error) => {
    return {
        type: actionTypes.GET_ADMIN_COLUMN_DISPLAY_SETTINGS_FAIL,
        error: error,
    };
};

export const getColumnDisplaySettings = () => {
    return (dispatch) => {
        dispatch(getColumnDisplaySettingsStart());

        return AdminPersonAPI.getColumnDisplaySettings()
            .then((res) => {
                dispatch(getColumnDisplaySettingsSuccess(res));
            })
            .catch((err) => {
                dispatch(getColumnDisplaySettingsFail(err));
            });
    };
};

export const clearColumnDisplaySettingsState = () => {
    return {
        type: actionTypes.CLEAR_COLUMN_DISPLAY_SETTINGS,
    };
};

export const updateColumnDisplaySettingStart = (id) => {
    return {
        type: actionTypes.UPDATE_ADMIN_COLUMN_DISPLAY_SETTING_START,
        id,
    };
};

export const updateColumnDisplaySettingSuccess = (updatedColumnDisplaySetting) => {
    return {
        type: actionTypes.UPDATE_ADMIN_COLUMN_DISPLAY_SETTING_SUCCESS,
        updatedColumnDisplaySetting,
    };
};

export const updateColumnDisplaySettingFail = (id, error) => {
    return {
        type: actionTypes.UPDATE_ADMIN_COLUMN_DISPLAY_SETTING_FAIL,
        error: error,
    };
};

export const updateColumnDisplaySetting = (columnDisplaySetting) => {
    return (dispatch) => {
        dispatch(updateColumnDisplaySettingStart(columnDisplaySetting.id));

        return AdminPersonAPI.updateColumnDisplaySetting(columnDisplaySetting)
            .then((res) => {
                dispatch(updateColumnDisplaySettingSuccess(res));
            })
            .catch((err) => {
                dispatch(updateColumnDisplaySettingFail(columnDisplaySetting.id, err));
            });
    };
};

export const requestCopySelfProfilesAsEndorsedStart = () => {
    return {
        type: actionTypes.REQUEST_COPY_SELF_PROFILES_AS_ENDORSED_START,
    };
};

export const requestCopySelfProfilesAsEndorsedSuccess = (copyProfileRequests) => {
    return {
        type: actionTypes.REQUEST_COPY_SELF_PROFILES_AS_ENDORSED_SUCCESS,
        copyProfileRequests,
    };
};

export const requestCopySelfProfilesAsEndorsedFail = (error) => {
    return {
        type: actionTypes.REQUEST_COPY_SELF_PROFILES_AS_ENDORSED_FAIL,
        error: error,
    };
};

export const requestCopySelfProfilesAsEndorsed = (copiedProfiles) => {
    return (dispatch) => {
        dispatch(requestCopySelfProfilesAsEndorsedStart());

        return AdminProfileAPI.requestCopySelfProfilesAsEndorsed(copiedProfiles)
            .then((res) => {
                dispatch(requestCopySelfProfilesAsEndorsedSuccess(res));
            })
            .catch((err) => {
                dispatch(requestCopySelfProfilesAsEndorsedFail(err));
            });
    };
};

export const processCopySelfProfilesAsEndorsed = (copyProfileRequestIds) => {
    return (dispatch) => {
        return AdminProfileAPI.processCopySelfProfilesAsEndorsed(copyProfileRequestIds)
            .then((res) => {})
            .catch((err) => {});
    };
};

export const updateCopySelfProfilesAsEndorsedStatus = (copyProfileRequestResponse) => {
    return {
        type: actionTypes.UPDATE_COPY_SELF_PROFILES_AS_ENDORSED_STATUS,
        copyProfileRequestResponse,
    };
};

export const exportPersonsProfilesStart = () => {
    return {
        type: actionTypes.EXPORT_PERSONS_PROFILES_START,
    };
};

export const exportPersonsProfilesSuccess = (printData) => {
    return {
        type: actionTypes.EXPORT_PERSONS_PROFILES_SUCCESS,
        printData,
    };
};

export const exportPersonsProfilesFail = (error) => {
    return {
        type: actionTypes.EXPORT_PERSONS_PROFILES_FAIL,
        error: error,
    };
};

export const exportPersonsProfiles = (personIds) => {
    return (dispatch) => {
        dispatch(exportPersonsProfilesStart());

        return AdminPersonAPI.exportPersonsProfiles(personIds)
            .then((res) => {
                dispatch(exportPersonsProfilesSuccess(res));
            })
            .catch((err) => {
                dispatch(exportPersonsProfilesFail(err));
            });
    };
};

export const importPersonsProfilesStart = () => {
    return {
        type: actionTypes.IMPORT_PERSONS_PROFILES_START,
    };
};

export const importPersonsProfilesSuccess = (message, importedPersons) => {
    return {
        type: actionTypes.IMPORT_PERSONS_PROFILES_SUCCESS,
        message,
        importedPersons,
    };
};

export const importPersonsProfilesFail = (error) => {
    return {
        type: actionTypes.IMPORT_PERSONS_PROFILES_FAIL,
        error: error,
    };
};

export const importPersonsProfiles = (personProfilesJsons) => {
    return (dispatch) => {
        dispatch(importPersonsProfilesStart());

        return AdminPersonAPI.importPersonsProfiles(personProfilesJsons)
            .then((res) => {
                dispatch(importPersonsProfilesSuccess(res.message, res.personResponses));
            })
            .catch((err) => {
                dispatch(importPersonsProfilesFail(err));
            });
    };
};

export const approveProfileRequestsStart = () => {
    return {
        type: actionTypes.APPROVE_SKILL_PROFILES_START,
    };
};

export const approveProfileRequestsSuccess = (updatedPersons) => {
    return {
        type: actionTypes.APPROVE_SKILL_PROFILES_SUCCESS,
        updatedPersons,
    };
};

export const approveProfileRequestsFail = (error) => {
    return {
        type: actionTypes.APPROVE_SKILL_PROFILES_FAIL,
        error: error,
    };
};

export const approveProfileRequests = (requests) => {
    return (dispatch) => {
        dispatch(approveProfileRequestsStart());

        return AdminPersonAPI.approveProfileRequests(requests)
            .then((res) => {
                if (res) {
                    dispatch(approveProfileRequestsSuccess(res));
                } else {
                    dispatch(approveProfileRequestsFail(res));
                }
            })
            .catch((err) => {
                dispatch(approveProfileRequestsFail(err));
            });
    };
};

export const exportLatestSelfAssessedProfilesDataExcel = (timezoneOffset) => {
    return (dispatch) => {
        dispatch(exportPersonsDataExcelStart());

        return AdminPersonAPI.exportLatestSelfAssessedProfilesDataExcel(timezoneOffset)
            .then((res) => {
                dispatch(exportPersonsDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportPersonsDataExcelFail(err));
            });
    };
};