import * as surveyActions from "../../store/actions/surveyAction";
import { dismisAlert, generateAlert } from "../../utils/alertUtils";
import { contentTranslator } from "../../utils/translatorUtils";
import { ContentWrapper } from "../common/ContentWrapper";
import ContentTranslator from "../translator/ContentTranslator";
import SurveyListVersion from "./SurveyListAnswer";
import React, { Component, useState } from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, CustomInput, Input, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const buttonNav1Style = {
    // width: '72px',
    minHeight: "33px",
};

const buttonNav2Style = {
    width: "100px",
    minHeight: "33px",
};

const buttonActionStyle = {
    height: "55px",
    fontSize: 18,
    fontFamily: "IBMPlexSans-Bold",
    textTransform: "capitalize",
};

const questionStyle = {
    fontSize: 14,
    fontFamily: "IBMPlexSans-Light",
};

const buttonNavStyle = {
    height: "33px",
};

const buttonActionPrimaryStyle = {
    height: "55px",
    fontSize: 18,
};

const buttonActionSecondaryStyle = {
    height: "55px",
    fontSize: 12,
};

const descStyle = {
    fontSize: 12,
    fontFamily: "IBMPlexSans-Light",
};

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

class SurveyQuestion extends Component {
    static displayName = SurveyQuestion.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            question: null,
            comment: "",
            startTime: new Date(),
            inProgress: false,
            selectedAnswerId: null,
            showSkipConfirmation: false,
            alerts: [],
            selectedValues: [],
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        const isAuthenticated = this.props.isAuthenticated;

        if (!isAuthenticated) {
            this.props.history.push("/");
        } else {
            this.setState({ loading: false });
            const {
                match: { params },
            } = this.props;

            var componentGroupId = params.componentGroupId;
            var elementId = params.elementId;

            this.initQuestionData(componentGroupId, elementId);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.match.params.elementId !== nextProps.match.params.elementId) {
            this.initQuestionData(nextProps.match.params.componentGroupId, nextProps.match.params.elementId);
        }
    }

    initQuestionData = (componentGroupId, elementId) => {
        this.setState({ loading: true });
        this.props.onSurvey.getSurveyQuestion(componentGroupId, elementId).then(() => {
            if (!this.props.loading) {
                if (!this.props.error) {
                    this.setState({ question: this.props.question, startTime: new Date() });
                } else {
                    this.generateAlert("danger", this.props.error.errData.Message);
                }
            }
            this.setState({ loading: this.props.loading });
        });
    };

    surveyDesc = () => {
        const question = this.state.question;

        return (
            <ContentWrapper>
                {question &&
                    (question.next ? (
                        <Row xs="1" sm="1" md="2">
                            <Col>
                                <Link to={`/survey/assessment/${question.assessmentId}`}>
                                    <button className="btn btn-light btnSecondary-Style" style={buttonNavStyle}>
                                        <ContentTranslator page="Question" name="QuestionButtonBack" contentText="Back to Surveys" />
                                    </button>
                                </Link>
                            </Col>
                        </Row>
                    ) : (
                        <Row xs="1" sm="1" md="2" className="justify-content-between">
                            <Col xs="2" sm="3" md="auto">
                                {question.previous && (
                                    <button className="btn btn-light btnSecondary-Style mr-2" style={buttonNav1Style} onClick={() => this.prevQuestion()}>
                                        <ContentTranslator page="Question" name="QuestionButtonPrev" contentText="Prev" />
                                    </button>
                                )}
                            </Col>
                            <Col xs="2" sm="3" md="auto">
                                <Link to={`/survey/assessment/${question.assessmentId}`}>
                                    <button className="btn btn-light btnSecondary-Style" style={buttonNavStyle}>
                                        <ContentTranslator page="Question" name="QuestionButtonBack" contentText="Back to Surveys" />
                                    </button>
                                </Link>
                            </Col>
                        </Row>
                    ))}
                <Row className="mt-4 align-items-center" xs="1" sm="1" md="2">
                    <Col sx="12" md="2">
                        <p className="color-dark mb-0" style={{ fontSize: 10, fontFamily: "IBMPlexSans-Bold" }}>
                            <ContentTranslator page="Assessment" name="AssessmentProgress" contentText="Progress:" />
                        </p>
                    </Col>
                    <Col sx="12" md="10">
                        {question && <Progress value={question.progress} />}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {question && (
                            <p style={{ fontSize: 32, fontFamily: "IBMPlexSans-Bold", lineHeight: 1 }}>
                                <span style={{ fontSize: 24 }} className="color-primary">
                                    {question.componentGroupDescription}
                                </span>
                                <br />
                                {question.moduleName}
                            </p>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>{question && <p className="color-dark" style={descStyle} dangerouslySetInnerHTML={{ __html: this.contentTranslator(this.props.contentTranslations, "Elements", `${question.elementId} Label`, question.elementLabel) }}></p>}</Col>
                </Row>
                <Row className="mt-4" xs="1" sm="1" md="2">
                    <Col sx="12" md="6">
                        {question && (
                            <button className="btn btn-block btn-primary btnPrimary-Style" style={buttonActionPrimaryStyle} onClick={() => this.selectAnswer(question.values[0].id)} disabled={this.state.inProgress}>
                                {question.next ? <ContentTranslator page="Question" name="QuestionButtonGetStarted" contentText="Get started!" /> : <ContentTranslator page="Question" name="QuestionButtonComplete" contentText="Complete!" />}
                            </button>
                        )}
                    </Col>
                    {this.state.question.next && this.state.question.allowSkip && (
                        <Col sx="12" md="6">
                            <button
                                className="btn btn-block btn-light btnSecondary-Style"
                                style={buttonActionSecondaryStyle}
                                onClick={() => this.skipSurveyConfirmation()}
                                disabled={this.state.inProgress}
                                dangerouslySetInnerHTML={{
                                    __html: this.contentTranslator(this.props.contentTranslations, "Question", "QuestionSkipSurvey", "Skip entire survey.<br />I do not believe this category is relevant to me."),
                                }}
                            ></button>
                        </Col>
                    )}
                </Row>
            </ContentWrapper>
        );
    };

    surveyAnswer = () => {
        const question = this.state.question;

        return (
            <ContentWrapper>
                <Row xs="1" sm="1" md="2" className="justify-content-between">
                    <Col xs="2" sm="3" md="auto">
                        {question && question.previous && (
                            <button className={`btn ${!this.state.inProgress && "btn-light"} btnSecondary-Style mr-2`} style={buttonNav1Style} onClick={() => this.prevQuestion()} disabled={this.state.inProgress}>
                                <ContentTranslator page="Question" name="QuestionButtonPrev" contentText="Prev" />
                            </button>
                        )}
                        {question && question.assessmentPersonResultId != null && question.next && (
                            <button className={`btn ${!this.state.inProgress && "btn-light"} btnSecondary-Style`} style={buttonNav1Style} onClick={() => this.nextQuestion()} disabled={this.state.inProgress}>
                                <ContentTranslator page="Question" name="QuestionButtonNext" contentText="Next" />
                            </button>
                        )}
                    </Col>
                    <Col xs="2" sm="3" md="auto">
                        {question && (
                            <Link disabled={this.state.inProgress} to={`/survey/assessment/${question.assessmentId}`}>
                                <button className={`btn ${!this.state.inProgress && "btn-light"} btnSecondary-Style mr-2`} style={buttonNav2Style} disabled={this.state.inProgress}>
                                    <ContentTranslator page="Question" name="QuestionButtonPause" contentText="Pause" />
                                </button>
                            </Link>
                        )}
                        {question && question.allowSkip && (
                            <button className={`btn ${!this.state.inProgress && "btn-light"} btnSecondary-Style`} style={buttonNav2Style} onClick={() => this.skipSurveyConfirmation()} disabled={this.state.inProgress}>
                                <ContentTranslator page="Question" name="QuestionButtonSkipSurvey" contentText="Skip Survey" />
                            </button>
                        )}
                    </Col>
                </Row>
                <Row className="mt-4 align-items-center" xs="1" sm="1" md="2">
                    <Col sx="12" md="2">
                        <p className="color-dark mb-0" style={{ fontSize: 10, fontFamily: "IBMPlexSans-Bold" }}>
                            <ContentTranslator page="Assessment" name="AssessmentProgress" contentText="Progress:" />
                            {/* <span className="color-primary">{question.numberOfAnswer}/{question.numberOfQuestion}</span> Questions */}
                        </p>
                    </Col>
                    <Col sx="12" md="10">
                        {question && <Progress value={question.progress} />}
                    </Col>
                </Row>
                <Row className="mt-4 ml-4">
                    <Col>{question && <p className="color-dark" style={questionStyle} dangerouslySetInnerHTML={{ __html: `<b>Q: </b>${this.contentTranslator(this.props.contentTranslations, "Elements", `${question.elementId} Label`, question.elementLabel)}` }} />}</Col>
                </Row>
                {question && question.enableComments && (
                    <Row className="mt-4 align-items-center" xs="1" sm="1" md="2">
                        <Col md="12">
                            <ContentTranslator page="Question" name="QuestionComment" contentText="Comment (optional)" />
                            <br />
                            <Input type="textarea" name="comment" id={"comment"} value={this.state.comment} onChange={this.handleChangeComment} disabled={this.state.inProgress} />
                            <br />
                        </Col>
                    </Row>
                )}
                {question && (
                    <React.Fragment>
                        <SurveyListVersion
                            resultType={question.resultType}
                            answersData={question.values}
                            selectAnswer={this.selectAnswer}
                            selectAnswers={this.selectAnswers}
                            inProgress={this.state.inProgress}
                            selectedAnswerId={this.state.selectedAnswerId}
                            contentTranslator={this.contentTranslator}
                            contentTranslations={this.props.contentTranslations}
                        ></SurveyListVersion>
                    </React.Fragment>
                )}
                {question && question.resultType == "Multiple" && (
                    <div style={{ paddingTop: "10px" }}>
                        <Row className="mt-0 align-items-center" xs="1" sm="1" md="2">
                            <Col sx="12" md="9"></Col>
                            <Col sx="12" md="3">
                                <button className="btn btn-block btn-primary" style={buttonActionStyle} disabled={this.state.inProgress || this.state.selectedValues.length == 0} onClick={() => this.submitAnswers()}>
                                    {this.props.inProgress ? <Spinner type="grow" size="md" color="light" /> : <ContentTranslator page="Question" name="QuestionButtonNext" contentText="Next" />}
                                </button>
                            </Col>
                        </Row>
                    </div>
                )}
            </ContentWrapper>
        );
    };

    handleChangeComment = (event) => {
        const { value } = event.target;
        this.setState({ comment: value });
    };

    nextQuestion = () => {
        const question = this.state.question;
        this.props.history.push(`/survey/question/${question.componentGroupId}/${question.next.elementId}`);
    };

    prevQuestion = () => {
        const question = this.state.question;
        this.props.history.push(`/survey/question/${question.componentGroupId}/${question.previous.elementId}`);
    };

    selectAnswer = (valueId, stringVal) => {
        const { startTime, question, comment } = this.state;

        var curTime = new Date();
        var dif = startTime.getTime() - curTime.getTime();
        var Seconds_from_T1_to_T2 = dif / 1000;
        var timeTaken = Math.round(Math.abs(Seconds_from_T1_to_T2));

        const answer = {
            componentGroupId: question.componentGroupId,
            elementId: question.elementId,
            valueId,
            timeTaken,
            comment,
            stringVal,
            resultType: "Single",
        };

        this.setState({ inProgress: true, selectedAnswerId: valueId });

        this.props.onSurvey.answerQuestion(answer).then(() => {
            if (!this.props.answerLoading) {
                if (!this.props.error && this.props.answerResult) {
                    this.setState({ comment: "" });

                    if (this.props.answerResult.status == "In Progress") {
                        this.props.history.push(`/survey/question/${this.props.answerResult.componentGroupId}/${this.props.answerResult.nextElementId}`);
                    } else if (this.props.answerResult.status == "Completed") {
                        if (this.props.answerResult.surveyIsComplete) {
                            this.props.onSurvey.completeMySurvey();
                        }
                        this.props.history.push(`/survey/assessment/${question.assessmentId}`);
                    }
                }
            }
            this.setState({ inProgress: this.props.answerLoading });
        });
    };

    selectAnswers = (selectedValues) => {
        this.setState({ selectedValues: [...selectedValues] });
    };

    submitAnswers = () => {
        const { startTime, question, comment, selectedValues } = this.state;

        if (selectedValues.length > 0) {
            var curTime = new Date();
            var dif = startTime.getTime() - curTime.getTime();
            var Seconds_from_T1_to_T2 = dif / 1000;
            var timeTaken = Math.round(Math.abs(Seconds_from_T1_to_T2));

            const answer = {
                componentGroupId: question.componentGroupId,
                elementId: question.elementId,
                timeTaken,
                comment,
                resultType: "Multiple",
                values: selectedValues,
            };

            this.setState({ inProgress: true });

            this.props.onSurvey.answerQuestion(answer).then(() => {
                if (!this.props.answerLoading) {
                    if (!this.props.error && this.props.answerResult) {
                        this.setState({ comment: "" });

                        if (this.props.answerResult.status == "In Progress") {
                            this.props.history.push(`/survey/question/${this.props.answerResult.componentGroupId}/${this.props.answerResult.nextElementId}`);
                        } else if (this.props.answerResult.status == "Completed") {
                            if (this.props.answerResult.surveyIsComplete) {
                                this.props.onSurvey.completeMySurvey();
                            }
                            this.props.history.push(`/survey/assessment/${question.assessmentId}`);
                        }
                    }
                }
                this.setState({ inProgress: this.props.answerLoading });
            });
        }
    };

    skipSurveyConfirmation = () => {
        this.setState({ showSkipConfirmation: true });
    };

    togleSkipSurveyConfirmation = () => {
        const showSkipConfirmation = this.state.showSkipConfirmation;
        this.setState({ showSkipConfirmation: !showSkipConfirmation });
    };

    skipSurvey = () => {
        const { question } = this.state;

        this.setState({ showSkipConfirmation: false });

        const answer = {
            componentGroupId: question.componentGroupId,
            elementId: question.elementId,
        };

        this.setState({ inProgress: true });

        this.props.onSurvey.skipQuestion(answer).then(() => {
            if (!this.props.answerLoading) {
                if (!this.props.error && this.props.answerResult) {
                    this.setState({ comment: "" });

                    if (this.props.answerResult.status == "In Progress") {
                        this.props.history.push(`/survey/question/${this.props.answerResult.componentGroupId}/${this.props.answerResult.nextElementId}`);
                    } else if (this.props.answerResult.status == "Completed") {
                        if (this.props.answerResult.surveyIsComplete) {
                            this.props.onSurvey.completeMySurvey();
                        }
                        this.props.history.push(`/survey/assessment/${question.assessmentId}`);
                    }
                }
            }
            this.setState({ inProgress: this.props.answerLoading });
        });
    };

    render() {
        const question = this.state.question;

        return (
            <div>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading ? (
                    <div>
                        <Row>
                            <Col className="p-0">
                                <center>
                                    <Spinner color="dark" />
                                </center>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <React.Fragment>
                        <Modal isOpen={this.state.showSkipConfirmation} toggle={this.togleSkipSurveyConfirmation}>
                            <ModalHeader toggle={this.togleSkipSurveyConfirmation}>
                                <ContentTranslator page="Question" name="QuestionSkipSurveyConfirmationHeader" contentText="Skip Survey Confirmation" />
                            </ModalHeader>
                            <ModalBody>
                                <ContentTranslator page="Question" name="QuestionSkipSurveyConfirmationMessage" contentText="This action is NOT reversible and will skip questions and set the survey to 100% complete. Are you sure?" />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.skipSurvey}>
                                    <ContentTranslator page="Question" name="QuestionButtonSkipSurvey" contentText="Skip Survey" />
                                </Button>
                                <Button color="secondary" onClick={this.togleSkipSurveyConfirmation}>
                                    <ContentTranslator page="SfiaAll" name="Close" contentText="Close" />
                                </Button>
                            </ModalFooter>
                        </Modal>
                        {question ? (
                            (question.values.length == 1 && question.values[0].name == "Continue") || (question.values.length == 2 && question.values[0].stringVal == "Continue" && question.values[1].stringVal == "Skip") || (question.values.length == 1 && question.values[0].stringVal == "Next") ? (
                                this.surveyDesc()
                            ) : (
                                this.surveyAnswer()
                            )
                        ) : (
                            <ContentWrapper>
                                <Row xs="1" sm="1" md="2">
                                    <Col>
                                        <Link to="" onClick={this.props.history.goBack}>
                                            <button className="btn btn-light btnSecondary-Style" style={buttonNavStyle}>
                                                <ContentTranslator page="Question" name="QuestionButtonBack" contentText="Back to Surveys" />
                                            </button>
                                        </Link>
                                    </Col>
                                </Row>
                            </ContentWrapper>
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null,

        loading: state.survey.loading,
        error: state.survey.error,
        question: state.survey.question,

        answerResult: state.survey.answerResult,
        answerLoading: state.survey.answerLoading,

        contentTranslations: state.content.contentTranslations,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSurvey: bindActionCreators(surveyActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyQuestion);
