const OrderCategory = [
  "Strategy and architecture",
  "Change and transformation",
  "Development and implementation",
  "Delivery and operation",
  "People and skills",
  "Relationship and engagement",
];

const OrderGeneric = [
  "Autonomy", 
  "Responsibility and Autonomy", 
  "Influence", 
  "Complexity", 
  "Complexity and Scope", 
  "Business Skills", 
  "Skills", 
  "Knowledge" 
];

export { OrderCategory, OrderGeneric };
