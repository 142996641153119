import changeInput from "../../../utils/changeInput";
import { formatDate_localeString } from "../../../utils/stringUtils";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactTextareaAutosize from "react-textarea-autosize";
import { Button, Col, CustomInput, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

const textareaStyle = {
    width: "100%",
    padding: "10px",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    color: "#495057",
};

const textareaStyleDisable = {
    width: "100%",
    padding: "10px",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    background: "#e9ecef",
    color: "#495057",
};

class ManageTrainingDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            intervention: this.props.intervention,
            interventionLevels: [],
            interventionLorLevels: [],
            form: null,
            isFormValid: this.props.intervention ? true : false,
            isUpdating: false,
            updateSuccess: false,
            optionIsActive: [
                { value: true, label: "True" },
                { value: false, label: "False" },
            ],
            optionBadgeProvider: [
                {
                    label: "AMPG",
                    value: "APMG",
                },
                {
                    label: "DSA",
                    value: "DSA",
                },
            ],
            optionSkillTypes: [
                {
                    label: "KNOWLEDGE",
                    value: "Knowledge",
                },
                {
                    label: "PROFICIENCY",
                    value: "Proficiency",
                },
                // {
                //     label: "COMPETENCY",
                //     value: "Competency",
                // },
            ],
        };

        this.formatDate_localeString = formatDate_localeString.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.intervention !== this.props.intervention) {
            this.setState({ intervention: this.props.intervention });
        }

        if (prevProps.isUpdating !== this.props.isUpdating) {
            this.setState({ isUpdating: this.props.isUpdating });
        }

        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
            this.prepareFormState();
        }

        if (prevProps.updateSuccess !== this.props.updateSuccess) {
            if (this.props.updateSuccess) {
                this.setState({ showDialog: false });
            }
        }
    }

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({
            showDialog: !showDialog,
        });
    };

    prepareFormState = () => {
        const intervention = this.props.intervention;
        this.setState({
            form: {
                name: {
                    validation: {
                        required: true,
                    },
                    value: intervention && intervention.name ? intervention.name : "",
                    valid: intervention && intervention.name && intervention.name != "" ? true : false,
                    isValidFormat: intervention && intervention.name && intervention.name != "" ? true : false,
                    touched: false,
                },
                actionType: {
                    validation: {
                        required: true,
                    },
                    value: intervention && intervention.actionType ? intervention.actionType : null,
                    valid: intervention && intervention.actionType && intervention.actionType != "" ? true : false,
                    isValidFormat: intervention && intervention.actionType && intervention.actionType != "" ? true : false,
                    touched: false,
                },
                companyId: {
                    validation: {
                        required: false,
                    },
                    value: intervention && intervention.companyId ? intervention.companyId : null,
                    valid: intervention && intervention.companyId ? true : true,
                    isValidFormat: intervention && intervention.companyId ? true : true,
                    touched: false,
                },
                description: {
                    validation: {
                        required: true,
                    },
                    value: intervention && intervention.description ? intervention.description : "",
                    valid: intervention ? true : false,
                    isValidFormat: intervention ? true : false,
                    touched: false,
                },
                isActive: {
                    validation: {
                        required: false,
                        isBool: true,
                    },
                    value: intervention && intervention.isActive ? intervention.isActive : false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                duration: {
                    validation: {
                        required: false,
                        isNumeric: true,
                    },
                    value: intervention && intervention.duration ? intervention.duration : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                durationHours: {
                    validation: {
                        required: false,
                        isNumeric: true,
                    },
                    value: intervention && intervention.durationHours ? intervention.durationHours : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                link: {
                    validation: {
                        required: false,
                    },
                    value: intervention && intervention.link ? intervention.link : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                publishedOn: {
                    validation: {
                        required: false,
                    },
                    value: intervention && intervention.publishedOn,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                retireOn: {
                    validation: {
                        required: false,
                    },
                    value: intervention && intervention.retireOn,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                reviewOn: {
                    validation: {
                        required: false,
                    },
                    value: intervention && intervention.reviewOn,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                versionNumber: {
                    validation: {
                        required: true,
                        isNumeric: true,
                    },
                    value: intervention && intervention.versionNumber ? intervention.versionNumber : 1,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                badgeIssuer: {
                    validation: {
                        required: true,
                    },
                    value: intervention && intervention.badgeIssuer ? intervention.badgeIssuer : this.props.containerConfig && this.props.containerConfig.badgeProvider && this.props.containerConfig.badgeProvider != null ? this.props.containerConfig.badgeProvider : "APMG",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            interventionLevels: intervention && intervention.levels ? [...intervention.levels] : [],
            interventionLorLevels: intervention && intervention.lorLevels ? [...intervention.lorLevels] : [],
            isFormValid: intervention && ((intervention.levels && intervention.levels.length > 0) || (intervention.lorLevels && intervention.levels.lorLevels > 0)) && intervention.id ? true : false,
        });
    };

    handleChange = (event) => {
        const interventionLorLevels = this.state.interventionLorLevels ? this.state.interventionLorLevels : [];
        let interventionLevels = this.state.interventionLevels ? this.state.interventionLevels : [];

        let { name, value } = event.target;

        if (name == "duration" && value && value != "") {
            if (value < 0) {
                value = 0;
            }
        }

        if (name == "versionNumber" && value && value != "") {
            if (value < 1) {
                value = 1;
            }
        }

        if (name == "actionType" && value && value == "Select Type") {
            value = "";
        }

        if (name == "companyId" && value && value == "Select Provider") {
            value = "";
        }

        let updateState = changeInput(this.state.form, name, value);

        if (name == "actionType") {
            if (value == "Accredited Badge Issuing Action") {
                interventionLevels.map((interventionLevel) => {
                    interventionLevel.skillType = "Proficient";
                });
            } else {
                interventionLevels.map((interventionLevel) => {
                    interventionLevel.skillType = null;
                });
            }
        }

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid && (interventionLorLevels.length > 0 || interventionLevels.length > 0),
        });
    };

    handleDateChange = (name, value) => {
        const interventionLorLevels = this.state.interventionLorLevels ? this.state.interventionLorLevels : [];
        const interventionLevels = this.state.interventionLevels ? this.state.interventionLevels : [];

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid && (interventionLorLevels.length > 0 || interventionLevels.length > 0),
        });
    };

    handleLorsCheckedChange = (lorName, lorCode, level) => {
        const interventionLorLevels = this.state.interventionLorLevels ? this.state.interventionLorLevels : [];
        const interventionLevels = this.state.interventionLevels ? this.state.interventionLevels : [];

        if (interventionLorLevels.find((it) => `${it.lorCode}${it.level}` == `${lorCode}${level}`)) {
            const interventionLorLevel = interventionLorLevels.find((it) => `${it.lorCode}${it.level}` == `${lorCode}${level}`);
            const index = interventionLorLevels.indexOf(interventionLorLevel);
            if (index !== -1) {
                interventionLorLevels.splice(index, 1);
            }
        } else {
            interventionLorLevels.push({
                lorName,
                lorCode,
                level,
            });
        }

        const updateState = changeInput(this.state.form, "name", this.state.form.name.value);
        this.setState({ interventionLorLevels, isFormValid: updateState.valid && (interventionLorLevels.length > 0 || interventionLevels.length > 0) });
    };

    handleSkillsCheckedChange = (skillsName, skillsCode, level, skillType) => {
        const interventionLorLevels = this.state.interventionLorLevels ? this.state.interventionLorLevels : [];
        const interventionLevels = this.state.interventionLevels ? this.state.interventionLevels : [];

        console.log("skillType", skillType);

        if (interventionLevels.find((it) => `${it.skillsCode}${it.level}` == `${skillsCode}${level}`)) {
            const interventionLevel = interventionLevels.find((it) => `${it.skillsCode}${it.level}` == `${skillsCode}${level}`);
            const index = interventionLevels.indexOf(interventionLevel);
            if (index !== -1) {
                interventionLevels.splice(index, 1);
            }
        } else {
            interventionLevels.push({
                skillsName,
                skillsCode,
                level,
                skillType,
            });
        }

        const updateState = changeInput(this.state.form, "name", this.state.form.name.value);
        this.setState({ interventionLevels, isFormValid: updateState.valid && (interventionLorLevels.length > 0 || interventionLevels.length > 0) });
    };

    handleSave = () => {
        const { form, intervention, interventionLevels, interventionLorLevels } = this.state;

        intervention.name = form.name.value;
        intervention.actionType = form.actionType.value;
        intervention.companyId = form.companyId.value;
        intervention.description = form.description.value;
        intervention.duration = form.duration.value;
        intervention.durationHours = form.durationHours.value;
        intervention.link = form.link.value;
        intervention.publishedOn = form.publishedOn.value;
        intervention.retireOn = form.retireOn.value;
        intervention.reviewOn = form.reviewOn.value;
        intervention.versionNumber = form.versionNumber.value;
        intervention.isActive = form.isActive.value;
        intervention.levels = interventionLevels;
        intervention.lorLevels = interventionLorLevels;
        intervention.badgeIssuer = form.badgeIssuer.value;

        if (intervention.id) {
            this.props.handleSaveUpdateIntervention(intervention);
        } else {
            this.props.handleSaveAddIntervention(intervention);
        }
    };

    render() {
        const { form, interventionLevels, interventionLorLevels, optionIsActive, optionBadgeProvider, optionSkillTypes } = this.state;

        const lors = this.props.lors
            .flatMap((lor, index) => {
                return lor.levels.flatMap((level, index) => {
                    return {
                        lorName: lor.name,
                        lorCode: lor.lorCode,
                        level: level.levelNumber,
                    };
                });
            })
            .sort((a, b) => (`${a.lorCode}` > `${b.lorCode}` ? 1 : -1));

        const categories = this.props.categories
            .flatMap((category, index) => {
                return category.subCategories.flatMap((subCategory, index) => {
                    return subCategory.skills.flatMap((skill, index) => {
                        return skill.levels.flatMap((level, index) => {
                            return {
                                skillsName: skill.name,
                                skillsCode: skill.skillsCode,
                                level: level.level,
                            };
                        });
                    });
                });
            })
            .sort((a, b) => (`${a.skillsCode}` > `${b.skillsCode}` ? 1 : -1));

        const actionTypes = [{ name: null }, ...this.props.actionTypes];
        const providers = [{ id: null, name: "Select Provider" }, ...this.props.providers];

        const publishedOn = form && form.publishedOn && form.publishedOn.value ? this.formatDate_localeString(new Date(form.publishedOn.value)) : null;
        const retireOn = form && form.retireOn && form.retireOn.value ? this.formatDate_localeString(new Date(form.retireOn.value)) : null;
        const reviewOn = form && form.reviewOn && form.reviewOn.value ? this.formatDate_localeString(new Date(form.reviewOn.value)) : null;

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={!this.state.isUpdating ? this.togleDialogModal : null} size="xl">
                    <ModalHeader toggle={!this.state.isUpdating ? this.togleDialogModal : null}>Training Detail</ModalHeader>
                    <ModalBody>
                        <Form style={{ maxHeight: "500px", overflowX: "hidden", overflowY: "scroll" }}>
                            <Row>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label md={2} sm={4}>
                                            Name
                                        </Label>
                                        <Col md={10} sm={8}>
                                            <Input type="text" name="name" id="name" value={form && form.name.value ? form.name.value : ""} invalid={form && form.name.touched && !form.name.valid} onChange={this.handleChange} disabled={this.state.isUpdating} placeholder="Name" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label md={2} sm={4}>
                                            Type
                                        </Label>
                                        <Col md={10} sm={8}>
                                            <Input type="select" name="actionType" id="actionType" value={form && form.actionType.value ? form.actionType.value : ""} invalid={form && form.actionType.touched && !form.actionType.valid} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                {actionTypes.map((it, key) => (
                                                    <option key={key} value={it.name}>
                                                        {it.name ? it.name : "Select Type"}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label md={2} sm={4}>
                                            Description
                                        </Label>
                                        <Col md={10} sm={8}>
                                            <ReactTextareaAutosize
                                                minRows={5}
                                                style={!this.state.isUpdating ? textareaStyle : textareaStyleDisable}
                                                aria-label="maximum height"
                                                value={form && form.description.value ? form.description.value : ""}
                                                onChange={this.handleChange}
                                                disabled={this.state.isUpdating}
                                                name="description"
                                                id="description"
                                                placeholder="Description"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label md={2} sm={4}>
                                            Website
                                        </Label>
                                        <Col md={10} sm={8}>
                                            <Input type="text" name="link" id="link" value={form && form.link.value ? form.link.value : ""} invalid={form && form.link.touched && !form.link.valid} onChange={this.handleChange} disabled={this.state.isUpdating} placeholder="Link" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={6} sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>IsActive</Label>
                                        <Col sm={8}>
                                            <Input type="select" name="isActive" id="isActive" value={form && form.isActive.value} invalid={form && form.isActive.touched && !form.isActive.valid} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                {optionIsActive.map((it, key) => (
                                                    <option key={key} value={it.value}>
                                                        {it.label}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={6} sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Version</Label>
                                        <Col sm={8}>
                                            <Input
                                                type="number"
                                                name="versionNumber"
                                                id="versionNumber"
                                                value={form && form.versionNumber.value ? form.versionNumber.value : 1}
                                                invalid={form && form.versionNumber.touched && !form.versionNumber.valid}
                                                onChange={this.handleChange}
                                                disabled={this.state.isUpdating}
                                                placeholder="Version Number"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={6} sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Duration (Days)</Label>
                                        <Col sm={8}>
                                            <Input
                                                type="number"
                                                name="duration"
                                                id="duration"
                                                value={form && form.duration.value ? form.duration.value : ""}
                                                invalid={form && form.duration.touched && !form.duration.valid}
                                                onChange={this.handleChange}
                                                disabled={this.state.isUpdating}
                                                placeholder="Duration (days)"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={6} sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Duration (Hours)</Label>
                                        <Col sm={8}>
                                            <Input
                                                type="number"
                                                name="durationHours"
                                                id="durationHours"
                                                value={form && form.durationHours.value ? form.durationHours.value : ""}
                                                invalid={form && form.durationHours.touched && !form.durationHours.valid}
                                                onChange={this.handleChange}
                                                disabled={this.state.isUpdating}
                                                placeholder="Duration (hours)"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={6} sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Publish On</Label>
                                        <Col sm={8}>
                                            <DatePicker
                                                selected={publishedOn ? new Date(publishedOn) : null}
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(value) => this.handleDateChange("publishedOn", value)}
                                                disabled={this.state.isUpdating}
                                                className="form-control"
                                                isClearable={!this.state.isUpdating}
                                                placeholderText="Publish On"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={6} sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Retire On</Label>
                                        <Col sm={8}>
                                            <DatePicker
                                                selected={retireOn ? new Date(retireOn) : null}
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(value) => this.handleDateChange("retireOn", value)}
                                                disabled={this.state.isUpdating}
                                                className="form-control"
                                                isClearable={!this.state.isUpdating}
                                                placeholderText="Retire On"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={6} sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Review On</Label>
                                        <Col sm={8}>
                                            <DatePicker
                                                selected={reviewOn ? new Date(reviewOn) : null}
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(value) => this.handleDateChange("reviewOn", value)}
                                                disabled={this.state.isUpdating}
                                                className="form-control"
                                                isClearable={!this.state.isUpdating}
                                                placeholderText="Review On"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                {form && form.actionType.value && form.actionType.value == "Accredited Badge Issuing Action" && (
                                    <Col md={6} sm={12}>
                                        <FormGroup row>
                                            <Label sm={4}>Badge Issuer</Label>
                                            <Col sm={8}>
                                                <Input type="select" name="badgeIssuer" id="badgeIssuer" value={form && form.badgeIssuer.value != null ? form.badgeIssuer.value : "APMG"} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                    {optionBadgeProvider.map((it, key) => (
                                                        <option key={key} value={it.value}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                )}
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label sm={12}>Level of Responsibilities</Label>
                                        <Col md={12}>
                                            {lors &&
                                                lors.map((lor, index) => {
                                                    return (
                                                        <div key={index} style={{ width: "100px", float: "left" }}>
                                                            <CustomInput
                                                                key={index}
                                                                type="checkbox"
                                                                name={`chkLors${lor.lorCode}${lor.level}`}
                                                                id={`chkLors${lor.lorCode}${lor.level}`}
                                                                checked={interventionLorLevels && interventionLorLevels.find((it) => `${it.lorCode}${it.level}` == `${lor.lorCode}${lor.level}`) ? true : false}
                                                                label={`${lor.lorCode}${lor.level}`}
                                                                onChange={() => this.handleLorsCheckedChange(lor.lorName, lor.lorCode, lor.level)}
                                                                inline
                                                                className="copy-skill-checkbox"
                                                            />
                                                        </div>
                                                    );
                                                })}
                                        </Col>
                                    </FormGroup>
                                </Col>
                                {form && form.actionType.value && form.actionType.value == "Accredited Badge Issuing Action" ? (
                                    <React.Fragment>
                                        {optionSkillTypes.map((optionSkillType, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <Col md={12} sm={12}>
                                                        <FormGroup row>
                                                            <Label sm={12}>{`Skills for ${optionSkillType.label} BADGES`}</Label>
                                                            <Col md={12}>
                                                                {categories &&
                                                                    categories.map((category, index) => {
                                                                        return (
                                                                            <div key={index} style={{ width: "100px", float: "left" }}>
                                                                                <CustomInput
                                                                                    key={index}
                                                                                    type="checkbox"
                                                                                    name={`chk${optionSkillType.value}Categories${category.skillsCode}${category.level}`}
                                                                                    id={`chk${optionSkillType.value}Categories${category.skillsCode}${category.level}`}
                                                                                    checked={interventionLevels && interventionLevels.find((it) => `${it.skillsCode}${it.level}` == `${category.skillsCode}${category.level}` && it.skillType == optionSkillType.value) ? true : false}
                                                                                    label={`${category.skillsCode}${category.level}`}
                                                                                    onChange={() => this.handleSkillsCheckedChange(category.skillsName, category.skillsCode, category.level, optionSkillType.value)}
                                                                                    disabled={interventionLevels ? interventionLevels.find((it) => `${it.skillsCode}${it.level}` == `${category.skillsCode}${category.level}` && it.skillType != optionSkillType.value) : false}
                                                                                    inline
                                                                                    className="copy-skill-checkbox"
                                                                                />
                                                                            </div>
                                                                        );
                                                                    })}
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                </React.Fragment>
                                            );
                                        })}
                                    </React.Fragment>
                                ) : (
                                    <Col md={12} sm={12}>
                                        <FormGroup row>
                                            <Label sm={12}>Skills</Label>
                                            <Col md={12}>
                                                {categories &&
                                                    categories.map((category, index) => {
                                                        return (
                                                            <div key={index} style={{ width: "100px", float: "left" }}>
                                                                <CustomInput
                                                                    key={index}
                                                                    type="checkbox"
                                                                    name={`chkCategories${category.skillsCode}${category.level}`}
                                                                    id={`chkCategories${category.skillsCode}${category.level}`}
                                                                    checked={interventionLevels && interventionLevels.find((it) => `${it.skillsCode}${it.level}` == `${category.skillsCode}${category.level}`) ? true : false}
                                                                    label={`${category.skillsCode}${category.level}`}
                                                                    onChange={() => this.handleSkillsCheckedChange(category.skillsName, category.skillsCode, category.level, null)}
                                                                    inline
                                                                    className="copy-skill-checkbox"
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                )}
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <React.Fragment>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.handleSave();
                                }}
                                disabled={!this.state.isFormValid || this.state.isUpdating}
                            >
                                Save
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.togleDialogModal();
                                }}
                                disabled={this.state.isUpdating}
                            >
                                Cancel
                            </Button>
                        </React.Fragment>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ManageTrainingDetail;
