import { updateState } from "../../utility";
import * as actionTypes from "../actions/adminActionTypes";

const initialState = {
    error: null,
    capabilityLoading: false,
    levelLoading: false,
    categoryLoading: false,
    isUpdating: false,
    extraFrameworkCapabilities: [],
    extraFrameworkLevels: [],
    categories: [],

    exportData: null,
    loadingExport: false,
    isImporting: false,
};

const getExtraFrameworkCapabilitiesStart = (state) => {
    return updateState(state, { error: null, capabilityLoading: true, extraFrameworkCapabilities: [] });
};

const getExtraFrameworkCapabilitiesSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        capabilityLoading: false,
        extraFrameworkCapabilities: action.extraFrameworkCapabilities,
    });
};

const getExtraFrameworkCapabilitiesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        capabilityLoading: false,
    });
};

const getExtraFrameworkLevelsStart = (state) => {
    return updateState(state, { error: null, levelLoading: true, extraFrameworkLevels: [] });
};

const getExtraFrameworkLevelsSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        levelLoading: false,
        extraFrameworkLevels: action.extraFrameworkLevels,
    });
};

const getExtraFrameworkLevelsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        levelLoading: false,
    });
};

const createExtraFrameworkLevelStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const createExtraFrameworkLevelSuccess = (state, action) => {
    const extraFrameworkLevels = state.extraFrameworkLevels;
    extraFrameworkLevels.push(action.extraFrameworkLevel);

    return updateState(state, {
        error: null,
        isUpdating: false,
        extraFrameworkLevels: [...extraFrameworkLevels],
    });
};

const createExtraFrameworkLevelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateExtraFrameworkLevelStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateExtraFrameworkLevelSuccess = (state, action) => {
    const extraFrameworkLevels = state.extraFrameworkLevels;

    const extraFrameworkLevel = extraFrameworkLevels.find((it) => it.id == action.extraFrameworkLevel.id);
    if (extraFrameworkLevel) {
        extraFrameworkLevel.level = action.extraFrameworkLevel.level;
        extraFrameworkLevel.levelNumber = action.extraFrameworkLevel.levelNumber;
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        extraFrameworkLevels: [...extraFrameworkLevels],
    });
};

const updateExtraFrameworkLevelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteExtraFrameworkLevelStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteExtraFrameworkLevelSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        isUpdating: false,
        extraFrameworkLevels: [...action.extraFrameworkLevels],
    });
};

const deleteExtraFrameworkLevelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const moveExtraFrameworkLevelsStart = (state) => {
    return updateState(state, { error: null, levelLoading: true });
};

const moveExtraFrameworkLevelsSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        levelLoading: false,
        extraFrameworkLevels: action.extraFrameworkLevels,
    });
};

const moveExtraFrameworkLevelsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        levelLoading: false,
    });
};

const getExtraFrameworkCategoriesStart = (state) => {
    return updateState(state, { error: null, categoryLoading: true, categories: [] });
};

const getExtraFrameworkCategoriesSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        categoryLoading: false,
        categories: action.categories,
    });
};

const getExtraFrameworkCategoriesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        categoryLoading: false,
    });
};

const createExtraFrameworkCategoryStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const createExtraFrameworkCategorySuccess = (state, action) => {
    const categories = state.categories;
    categories.push(action.category);

    return updateState(state, {
        error: null,
        isUpdating: false,
        categories: [...categories],
    });
};

const createExtraFrameworkCategoryFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateExtraFrameworkCategoryStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateExtraFrameworkCategorySuccess = (state, action) => {
    const categories = state.categories;

    const category = categories.find((it) => it.id == action.category.id);
    if (category) {
        category.name = action.category.name;
        category.description = action.category.description;
        category.colour = action.category.colour;
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        categories: [...categories],
    });
};

const updateExtraFrameworkCategoryFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteExtraFrameworkCategoryStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteExtraFrameworkCategorySuccess = (state, action) => {
    const categories = state.categories;

    const category = categories.find((it) => it.id == action.id);
    if (category) {
        const index = categories.indexOf(category);
        if (index !== -1) {
            categories.splice(index, 1);
        }
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        categories: [...categories],
    });
};

const deleteExtraFrameworkCategoryFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const createExtraFrameworkSubCategoryStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const createExtraFrameworkSubCategorySuccess = (state, action) => {
    const categories = state.categories;
    const category = categories.find((it) => it.id == action.categoryId);
    if (category) {
        if (category.subCategories) {
            category.subCategories.push(action.subCategory);
        } else {
            category.subCategories = [action.subCategory];
        }
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        categories: [...categories],
    });
};

const createExtraFrameworkSubCategoryFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateExtraFrameworkSubCategoryStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateExtraFrameworkSubCategorySuccess = (state, action) => {
    const categories = state.categories;

    const category = categories.find((it) => it.id == action.categoryId);
    if (category) {
        const subCategory = category.subCategories.find((it) => it.id == action.subCategory.id);
        if (subCategory) {
            subCategory.name = action.subCategory.name;
            subCategory.description = action.subCategory.description;
            subCategory.colour = action.subCategory.colour;
            subCategory.skillColour = action.subCategory.skillColour;
        }
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        categories: [...categories],
    });
};

const updateExtraFrameworkSubCategoryFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteExtraFrameworkSubCategoryStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteExtraFrameworkSubCategorySuccess = (state, action) => {
    const categories = state.categories;

    const category = categories.find((it) => it.id == action.categoryId);
    if (category) {
        const subCategory = category.subCategories.find((it) => it.id == action.subCategoryId);
        if (subCategory) {
            const index = category.subCategories.indexOf(subCategory);
            if (index !== -1) {
                category.subCategories.splice(index, 1);
            }
        }
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        categories: [...categories],
    });
};

const deleteExtraFrameworkSubCategoryFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const createExtraFrameworkSkillStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const createExtraFrameworkSkillSuccess = (state, action) => {
    const categories = state.categories;
    const category = categories.find((it) => it.id == action.categoryId);
    if (category) {
        const subCategory = category.subCategories.find((it) => it.id == action.subCategoryId);
        if (subCategory) {
            if (subCategory.skills) {
                subCategory.skills.push(action.skill);
            } else {
                subCategory.skills = [action.skill];
            }
        }
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        categories: [...categories],
    });
};

const createExtraFrameworkSkillFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateExtraFrameworkSkillStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateExtraFrameworkSkillSuccess = (state, action) => {
    const categories = state.categories;

    const category = categories.find((it) => it.id == action.categoryId);
    if (category) {
        const subCategory = category.subCategories.find((it) => it.id == action.subCategoryId);
        if (subCategory) {
            const skill = subCategory.skills.find((it) => it.id == action.skill.id);
            if (skill) {
                skill.name = action.skill.name;
                skill.description = action.skill.description;
                skill.skillsCode = action.skill.skillsCode;
                skill.levels = action.skill.levels;
            }
        }
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        categories: [...categories],
    });
};

const updateExtraFrameworkSkillFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteExtraFrameworkSkillStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteExtraFrameworkSkillSuccess = (state, action) => {
    const categories = state.categories;

    const category = categories.find((it) => it.id == action.categoryId);
    if (category) {
        const subCategory = category.subCategories.find((it) => it.id == action.subCategoryId);
        if (subCategory) {
            const skill = subCategory.skills.find((it) => it.id == action.skillId);
            if (skill) {
                const index = subCategory.skills.indexOf(skill);
                if (index !== -1) {
                    subCategory.skills.splice(index, 1);
                }
            }
        }
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        categories: [...categories],
    });
};

const deleteExtraFrameworkSkillFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const exportExtraFrameworksDataExcelStart = (state) => {
    return updateState(state, { error: null, loadingExport: true });
};

const exportExtraFrameworksDataExcelSuccess = (state, action) => {
    return updateState(state, {
        exportData: action.exportData,
        error: null,
        loadingExport: false,
    });
};

const exportExtraFrameworksDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingExport: false,
    });
};

const importExtraFrameworksDataExcelStart = (state) => {
    return updateState(state, { error: null, isImporting: true });
};

const importExtraFrameworksDataExcelSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        isImporting: false,
    });
};

const importExtraFrameworksDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isImporting: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EXTRAFRAMEWORK_CAPABILITIES_START:
            return getExtraFrameworkCapabilitiesStart(state);
        case actionTypes.GET_EXTRAFRAMEWORK_CAPABILITIES_SUCCESS:
            return getExtraFrameworkCapabilitiesSuccess(state, action);
        case actionTypes.GET_EXTRAFRAMEWORK_CAPABILITIES_FAIL:
            return getExtraFrameworkCapabilitiesFail(state, action);

        case actionTypes.GET_EXTRAFRAMEWORK_LEVELS_START:
            return getExtraFrameworkLevelsStart(state);
        case actionTypes.GET_EXTRAFRAMEWORK_LEVELS_SUCCESS:
            return getExtraFrameworkLevelsSuccess(state, action);
        case actionTypes.GET_EXTRAFRAMEWORK_LEVELS_FAIL:
            return getExtraFrameworkLevelsFail(state, action);

        case actionTypes.CREATE_EXTRAFRAMEWORK_LEVEL_START:
            return createExtraFrameworkLevelStart(state);
        case actionTypes.CREATE_EXTRAFRAMEWORK_LEVEL_SUCCESS:
            return createExtraFrameworkLevelSuccess(state, action);
        case actionTypes.CREATE_EXTRAFRAMEWORK_LEVEL_FAIL:
            return createExtraFrameworkLevelFail(state, action);

        case actionTypes.UPDATE_EXTRAFRAMEWORK_LEVEL_START:
            return updateExtraFrameworkLevelStart(state);
        case actionTypes.UPDATE_EXTRAFRAMEWORK_LEVEL_SUCCESS:
            return updateExtraFrameworkLevelSuccess(state, action);
        case actionTypes.UPDATE_EXTRAFRAMEWORK_LEVEL_FAIL:
            return updateExtraFrameworkLevelFail(state, action);

        case actionTypes.DELETE_EXTRAFRAMEWORK_LEVEL_START:
            return deleteExtraFrameworkLevelStart(state);
        case actionTypes.DELETE_EXTRAFRAMEWORK_LEVEL_SUCCESS:
            return deleteExtraFrameworkLevelSuccess(state, action);
        case actionTypes.DELETE_EXTRAFRAMEWORK_LEVEL_FAIL:
            return deleteExtraFrameworkLevelFail(state, action);

        case actionTypes.MOVE_EXTRAFRAMEWORK_LEVELS_START:
            return moveExtraFrameworkLevelsStart(state);
        case actionTypes.MOVE_EXTRAFRAMEWORK_LEVELS_SUCCESS:
            return moveExtraFrameworkLevelsSuccess(state, action);
        case actionTypes.MOVE_EXTRAFRAMEWORK_LEVELS_FAIL:
            return moveExtraFrameworkLevelsFail(state, action);

        case actionTypes.GET_EXTRAFRAMEWORK_CATEGORIES_START:
            return getExtraFrameworkCategoriesStart(state);
        case actionTypes.GET_EXTRAFRAMEWORK_CATEGORIES_SUCCESS:
            return getExtraFrameworkCategoriesSuccess(state, action);
        case actionTypes.GET_EXTRAFRAMEWORK_CATEGORIES_FAIL:
            return getExtraFrameworkCategoriesFail(state, action);

        case actionTypes.CREATE_EXTRAFRAMEWORK_CATEGORY_START:
            return createExtraFrameworkCategoryStart(state);
        case actionTypes.CREATE_EXTRAFRAMEWORK_CATEGORY_SUCCESS:
            return createExtraFrameworkCategorySuccess(state, action);
        case actionTypes.CREATE_EXTRAFRAMEWORK_CATEGORY_FAIL:
            return createExtraFrameworkCategoryFail(state, action);

        case actionTypes.UPDATE_EXTRAFRAMEWORK_CATEGORY_START:
            return updateExtraFrameworkCategoryStart(state);
        case actionTypes.UPDATE_EXTRAFRAMEWORK_CATEGORY_SUCCESS:
            return updateExtraFrameworkCategorySuccess(state, action);
        case actionTypes.UPDATE_EXTRAFRAMEWORK_CATEGORY_FAIL:
            return updateExtraFrameworkCategoryFail(state, action);

        case actionTypes.DELETE_EXTRAFRAMEWORK_CATEGORY_START:
            return deleteExtraFrameworkCategoryStart(state);
        case actionTypes.DELETE_EXTRAFRAMEWORK_CATEGORY_SUCCESS:
            return deleteExtraFrameworkCategorySuccess(state, action);
        case actionTypes.DELETE_EXTRAFRAMEWORK_CATEGORY_FAIL:
            return deleteExtraFrameworkCategoryFail(state, action);

        case actionTypes.CREATE_EXTRAFRAMEWORK_SUBCATEGORY_START:
            return createExtraFrameworkSubCategoryStart(state);
        case actionTypes.CREATE_EXTRAFRAMEWORK_SUBCATEGORY_SUCCESS:
            return createExtraFrameworkSubCategorySuccess(state, action);
        case actionTypes.CREATE_EXTRAFRAMEWORK_SUBCATEGORY_FAIL:
            return createExtraFrameworkSubCategoryFail(state, action);

        case actionTypes.UPDATE_EXTRAFRAMEWORK_SUBCATEGORY_START:
            return updateExtraFrameworkSubCategoryStart(state);
        case actionTypes.UPDATE_EXTRAFRAMEWORK_SUBCATEGORY_SUCCESS:
            return updateExtraFrameworkSubCategorySuccess(state, action);
        case actionTypes.UPDATE_EXTRAFRAMEWORK_SUBCATEGORY_FAIL:
            return updateExtraFrameworkSubCategoryFail(state, action);

        case actionTypes.DELETE_EXTRAFRAMEWORK_SUBCATEGORY_START:
            return deleteExtraFrameworkSubCategoryStart(state);
        case actionTypes.DELETE_EXTRAFRAMEWORK_SUBCATEGORY_SUCCESS:
            return deleteExtraFrameworkSubCategorySuccess(state, action);
        case actionTypes.DELETE_EXTRAFRAMEWORK_SUBCATEGORY_FAIL:
            return deleteExtraFrameworkSubCategoryFail(state, action);

        case actionTypes.CREATE_EXTRAFRAMEWORK_SKILL_START:
            return createExtraFrameworkSkillStart(state);
        case actionTypes.CREATE_EXTRAFRAMEWORK_SKILL_SUCCESS:
            return createExtraFrameworkSkillSuccess(state, action);
        case actionTypes.CREATE_EXTRAFRAMEWORK_SKILL_FAIL:
            return createExtraFrameworkSkillFail(state, action);

        case actionTypes.UPDATE_EXTRAFRAMEWORK_SKILL_START:
            return updateExtraFrameworkSkillStart(state);
        case actionTypes.UPDATE_EXTRAFRAMEWORK_SKILL_SUCCESS:
            return updateExtraFrameworkSkillSuccess(state, action);
        case actionTypes.UPDATE_EXTRAFRAMEWORK_SKILL_FAIL:
            return updateExtraFrameworkSkillFail(state, action);

        case actionTypes.DELETE_EXTRAFRAMEWORK_SKILL_START:
            return deleteExtraFrameworkSkillStart(state);
        case actionTypes.DELETE_EXTRAFRAMEWORK_SKILL_SUCCESS:
            return deleteExtraFrameworkSkillSuccess(state, action);
        case actionTypes.DELETE_EXTRAFRAMEWORK_SKILL_FAIL:
            return deleteExtraFrameworkSkillFail(state, action);

        case actionTypes.EXPORT_EXTRAFRAMEWORKS_DATA_START:
            return exportExtraFrameworksDataExcelStart(state);
        case actionTypes.EXPORT_EXTRAFRAMEWORKS_DATA_SUCCESS:
            return exportExtraFrameworksDataExcelSuccess(state, action);
        case actionTypes.EXPORT_EXTRAFRAMEWORKS_DATA_FAIL:
            return exportExtraFrameworksDataExcelFail(state, action);

        case actionTypes.IMPORT_EXTRAFRAMEWORKS_DATA_START:
            return importExtraFrameworksDataExcelStart(state);
        case actionTypes.IMPORT_EXTRAFRAMEWORKS_DATA_SUCCESS:
            return importExtraFrameworksDataExcelSuccess(state, action);
        case actionTypes.IMPORT_EXTRAFRAMEWORKS_DATA_FAIL:
            return importExtraFrameworksDataExcelFail(state, action);

        default:
            return state;
    }
};

export default reducer;
