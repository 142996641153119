import { dismisAlert, generateAlert } from "../../../src/utils/alertUtils";
import * as authActions from "../../store/actions/authAction";
import * as contentActions from "../../store/actions/contentAction";
import * as personActions from "../../store/actions/personAction";
import { parameterizedString } from "../../utils/stringUtils";
import { contentTranslator } from "../../utils/translatorUtils";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import ContentTranslator from "../translator/ContentTranslator";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import ReactTextareaAutosize from "react-textarea-autosize";
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "22px",
    fontFamily: "IBMPlexSans-Bold",
};

const subHeaderStyle = {
    fontFamily: "IBMPlexSans-Light",
    fontSize: "11px",
};

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

class Note extends React.Component {
    static displayName = Note.name;

    constructor(props) {
        super(props);
        this.connection = null;
        this.state = {
            loading: false,
            account: null,
            alerts: [],
            contentLoading: true,
        };

        this.initData = this.initData.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);

        this.contentTranslator = contentTranslator.bind(this);
        this.parameterizedString = parameterizedString.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true });

        if (this.props.language) {
            this.props.onContent.getContentTranslations(this.props.language.id, "SfiaAll-Menu-SfiaProfileMenu-SfiaNote-Lors-Skills").then(() => {
                this.setState({ contentLoading: this.props.contentLoading });

                this.props.onAuth.authCheckState().then(() => {
                    this.setState({ loading: this.props.authLoading });

                    if (window.location.pathname.includes("/Manage")) {
                        const query = new URLSearchParams(this.props.location.search);
                        const email = query.get("email");

                        if (!this.props.managedPeople || !(this.props.managedPeople && this.props.managedPeople.personB.email.toLowerCase() == email.toLowerCase())) {
                            this.setState({ loading: true });

                            this.props.onPerson.getPersonMyPeopleByEmail(email).then(() => {
                                if (!this.props.loading && this.props.personManagedPeople) {
                                    this.props.onAuth.myPeopleSetManagedRelationship(this.props.personManagedPeople).then(() => {
                                        if (!this.props.loading && this.props.personManagedPeople) {
                                            this.initData();
                                        }

                                        this.setState({ loading: this.props.loading });
                                    });
                                }

                                this.setState({ loading: this.props.loading });
                            });
                        } else {
                            this.initData();
                        }
                    } else {
                        this.initData();
                    }
                });
            });
        } else {
            this.props.onAuth.authCheckState().then(() => {
                this.setState({ loading: this.props.authLoading });

                if (window.location.pathname.includes("/Manage")) {
                    const query = new URLSearchParams(this.props.location.search);
                    const email = query.get("email");

                    if (!this.props.managedPeople || !(this.props.managedPeople && this.props.managedPeople.personB.email.toLowerCase() == email.toLowerCase())) {
                        this.setState({ loading: true });

                        this.props.onPerson.getPersonMyPeopleByEmail(email).then(() => {
                            if (!this.props.loading && this.props.personManagedPeople) {
                                this.props.onAuth.myPeopleSetManagedRelationship(this.props.personManagedPeople).then(() => {
                                    if (!this.props.loading && this.props.personManagedPeople) {
                                        this.initData();
                                    }

                                    this.setState({ loading: this.props.loading });
                                });
                            }

                            this.setState({ loading: this.props.loading });
                        });
                    } else {
                        this.initData();
                    }
                } else {
                    this.initData();
                }
            });
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    initData() {
        this.setState({ loading: true });

        Promise.resolve(this.props.managedPeople ? this.props.onPerson.getManagedPeopleLatestAccount(this.props.managedPeople.personB.email) : this.props.onPerson.getPersonLatestAccount()).then(() => {
            if (!this.props.loading && this.props.account) {
                const account = this.props.account;

                this.setState({ account });
            }
            this.setState({ loading: this.props.loading });
        });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        const account = this.state.account;
        account.note = value;
        this.setState({ account });
    };

    submitForm = () => {
        this.setState({ loading: true });

        const account = this.state.account;

        Promise.resolve(this.props.managedPeople ? this.props.onPerson.updateManagedPeoplePersonAccountNote(account.id, account.note, this.props.managedPeople.personB.email) : this.props.onPerson.updatePersonAccountNote(account.id, account.note)).then(() => {
            if (!this.props.updating && this.props.account) {
                const account = this.props.account;

                this.setState({ account });
                this.generateAlert("success", "Your notes has been saved");
            } else if (!this.props.updating && this.props.error) {
                this.generateAlert("danger", this.props.error.errData.Message);
            }
            this.setState({ loading: this.props.updating });
        });
    };

    render() {
        let isAdmin = false;

        const authMode = this.props.authMode;
        let authModes = authMode.split(",").map((item) => item.trim());
        if (authModes.filter((it) => it != "Admin").length == 0) {
            isAdmin = true;
        }

        return (
            <Container className="contentMain">
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.contentLoading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <ContentWrapperLg>
                        <p className={"page-title"} style={headerStyle}>
                            {this.props.managedPeople
                                ? this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "SfiaNote", "SfiaNoteNotesFor", "Notes for {s1}"), `${this.props.managedPeople.personB.firstName} ${this.props.managedPeople.personB.lastName}`)
                                : this.contentTranslator(this.props.contentTranslations, "SfiaNote", "SfiaNoteMyNotes", "My Notes")}
                        </p>
                        <p style={subHeaderStyle}>
                            <ContentTranslator page="SfiaNote" name="SfiaNoteTitleMessage" contentText="Please add your notes using the edit panel below. We recommended adding a date." />
                        </p>
                        <Row>
                            <Col sm={12}>
                                <ReactTextareaAutosize
                                    minRows={10}
                                    style={{ width: "100%", padding: "10px", border: "1px solid #ced4da" }}
                                    aria-label="maximum height"
                                    placeholder="Enter notes here"
                                    value={this.state.account ? this.state.account.note : ""}
                                    onChange={this.handleChange}
                                    readOnly={isAdmin}
                                />
                            </Col>
                        </Row>
                        {!isAdmin && (
                            <Row style={{ paddingTop: "20px" }}>
                                <Col sm={12}>
                                    <div style={{ float: "right" }}>
                                        <Button style={{ width: "135px", height: "36px", fontSize: "14px" }} color="primary" className={`btn btn-primary ${!this.state.loading ? "btnPrimary-Style" : "btnDisabled-Style"}`} disabled={this.state.loading} onClick={this.submitForm}>
                                            <ContentTranslator page="SfiaAll" name="Save" contentText="Save" />
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </ContentWrapperLg>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.person.loading,
        updating: state.person.updating,
        error: state.person.error,
        account: state.person.account,

        personManagedPeople: state.person.managedPeople,
        managedPeople: state.auth.managedPeople,

        authLoading: state.auth.loading,
        authMode: state.auth.authMode,

        language: state.language.language,
        contentTranslations: state.content.contentTranslations,
        contentLoading: state.content.loading,
        contentError: state.content.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onPerson: bindActionCreators(personActions, dispatch),
        onContent: bindActionCreators(contentActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Note);
