import AdminMentorPreferenceAPI from "./../../../common/api/adminservice/AdminMentorPreferenceService";
import * as actionTypes from "./adminActionTypes";

export const getMentorPreferencesStart = () => {
    return {
        type: actionTypes.GET_MENTOR_PREFERENCES_START,
    };
};

export const getMentorPreferencesSuccess = (mentorPreferences) => {
    return {
        type: actionTypes.GET_MENTOR_PREFERENCES_SUCCESS,
        mentorPreferences,
    };
};

export const getMentorPreferencesFail = (error) => {
    return {
        type: actionTypes.GET_MENTOR_PREFERENCES_FAIL,
        error: error,
    };
};

export const getMentorPreferences = () => {
    return (dispatch) => {
        dispatch(getMentorPreferencesStart());

        return AdminMentorPreferenceAPI.getMentorPreferences()
            .then((res) => {
                dispatch(getMentorPreferencesSuccess(res));
            })
            .catch((err) => {
                dispatch(getMentorPreferencesFail(err));
            });
    };
};

export const addMentorPreferenceStart = () => {
    return {
        type: actionTypes.ADD_MENTOR_PREFERENCE_START,
    };
};

export const addMentorPreferenceSuccess = (addedMentorPreference) => {
    return {
        type: actionTypes.ADD_MENTOR_PREFERENCE_SUCCESS,
        addedMentorPreference,
    };
};

export const addMentorPreferenceFail = (error) => {
    return {
        type: actionTypes.ADD_MENTOR_PREFERENCE_FAIL,
        error: error,
    };
};

export const addMentorPreference = (mentorPreference) => {
    return (dispatch) => {
        dispatch(addMentorPreferenceStart());

        return AdminMentorPreferenceAPI.createMentorPreference(mentorPreference)
            .then((res) => {
                dispatch(addMentorPreferenceSuccess(res));
            })
            .catch((err) => {
                dispatch(addMentorPreferenceFail(err));
            });
    };
};

export const updateMentorPreferenceStart = () => {
    return {
        type: actionTypes.UPDATE_MENTOR_PREFERENCE_START,
    };
};

export const updateMentorPreferenceSuccess = (updatedMentorPreference) => {
    return {
        type: actionTypes.UPDATE_MENTOR_PREFERENCE_SUCCESS,
        updatedMentorPreference,
    };
};

export const updateMentorPreferenceFail = (error) => {
    return {
        type: actionTypes.UPDATE_MENTOR_PREFERENCE_FAIL,
        error: error,
    };
};

export const updateMentorPreference = (id, mentorPreference) => {
    return (dispatch) => {
        dispatch(updateMentorPreferenceStart());

        return AdminMentorPreferenceAPI.updateMentorPreference(id, mentorPreference)
            .then((res) => {
                dispatch(updateMentorPreferenceSuccess(res));
            })
            .catch((err) => {
                dispatch(updateMentorPreferenceFail(err));
            });
    };
};

export const deleteMentorPreferenceStart = () => {
    return {
        type: actionTypes.DELETE_MENTOR_PREFERENCE_START,
    };
};

export const deleteMentorPreferenceSuccess = (deletedMentorPreferenceId) => {
    return {
        type: actionTypes.DELETE_MENTOR_PREFERENCE_SUCCESS,
        deletedMentorPreferenceId,
    };
};

export const deleteMentorPreferenceFail = (error) => {
    return {
        type: actionTypes.DELETE_MENTOR_PREFERENCE_FAIL,
        error: error,
    };
};

export const deleteMentorPreference = (id) => {
    return (dispatch) => {
        dispatch(deleteMentorPreferenceStart());

        return AdminMentorPreferenceAPI.deleteMentorPreference(id)
            .then((res) => {
                dispatch(deleteMentorPreferenceSuccess(id));
            })
            .catch((err) => {
                dispatch(deleteMentorPreferenceFail(err));
            });
    };
};
