import { dismisAlert, generateAlert } from "../../../src/utils/alertUtils";
import * as authActions from "../../store/actions/authAction";
import * as configActions from "../../store/actions/configAction";
import * as contentActions from "../../store/actions/contentAction";
import * as personActions from "../../store/actions/personAction";
import { contentTranslator } from "../../utils/translatorUtils";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import { SkillsTxTable } from "../common/SkillsTxTable";
import ContentTranslator from "../translator/ContentTranslator";
import "./../../custom-my-people.css";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Container, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "22px",
    fontFamily: "IBMPlexSans-Bold",
};

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

class MyLeaders extends React.Component {
    static displayName = MyLeaders.name;

    constructor(props) {
        super(props);
        this.connection = null;
        this.state = {
            loading: false,
            contentLoading: true,
            myLeaders: [],
            alerts: [],
            isUpdating: false,
        };

        this.initData = this.initData.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        if (this.props.language) {
            this.props.onContent.getContentTranslations(this.props.language.id, "SfiaAll-Menu-SfiaProfileMenu-SfiaMyLeaders-Lors-Skills").then(() => {
                this.setState({ contentLoading: this.props.contentLoading });

                this.initData();
            });
        } else {
            this.initData();
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    initData() {
        this.setState({ loading: true });

        this.props.onPerson
            .getPersonMyLeaders()
            .then(() => {
                if (!this.props.loading) {
                    const myLeaders = this.props.myLeaders.sort((a, b) => `${a.personB.firstName} ${a.personB.lastName}` > `${b.personB.firstName} ${b.personB.lastName}`);

                    this.setState({
                        myLeaders,
                    });
                }

                this.setState({ loading: this.props.loading });
            })
            .catch(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    render() {
        const { myLeaders } = this.state;

        const columns = [
            {
                id: "id",
                name: (
                    <div style={{ textAlign: "left", width: "100%" }}>
                        <ContentTranslator page="SfiaMyLeaders" name="SfiaMyLeadersTableManager" contentText="Who can see my profile" />
                    </div>
                ),
                selector: (row) => row.id,
                sortable: false,
                width: "25%",
                format: function (row, index) {
                    return `${row.personA.firstName} ${row.personA.lastName}`;
                },
                wrap: true,
                center: false,
            },
            {
                id: "relationshipType",
                name: (
                    <div style={{ textAlign: "left", width: "100%" }}>
                        <ContentTranslator page="SfiaMyLeaders" name="SfiaMyLeadersTableType" contentText="They are my" />
                    </div>
                ),
                selector: (row) => row.relationshipType,
                sortable: false,
                width: "25%",
                format: function (row, index) {
                    return `${row.relationshipType} ${row.relationshipType == "Mentor" && row.status != "Accepted" ? `(${row.status})` : ""}`;
                },
                wrap: true,
                center: false,
            },
            {
                id: "createdOn",
                name: (
                    <div style={{ textAlign: "left", width: "100%" }}>
                        <ContentTranslator page="SfiaMyLeaders" name="SfiaMyLeadersTableCreatedOn" contentText="Relationship created" />
                    </div>
                ),
                selector: (row) => row.createdOn,
                sortable: false,
                width: "25%",
                format: function (row, index) {
                    return row.createdOn
                        ? new Intl.DateTimeFormat("en-GB", {
                              year: "2-digit",
                              month: "short",
                              day: "numeric",
                          }).format(new Date(row.createdOn))
                        : "";
                },
                wrap: true,
                center: false,
            },
            {
                id: "personAId",
                name: (
                    <div style={{ textAlign: "left", width: "100%" }}>
                        <ContentTranslator page="SfiaMyLeaders" name="SfiaMyLeadersTableEmail" contentText="Contact them" />
                    </div>
                ),
                selector: (row) => row.personAId,
                sortable: false,
                width: "25%",
                format: function (row, index) {
                    return <a href={`mailto:${row.personA.email}`}>{row.personA.email}</a>;
                },
                wrap: true,
                center: false,
            },
        ];

        return (
            <Container className="contentMain">
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading || this.state.contentLoading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <ContentWrapperLg>
                        <p className={"page-title"} style={headerStyle}>
                            <ContentTranslator page="SfiaMyLeaders" name="MyLeaders" contentText="My Leaders" />
                        </p>
                        <SkillsTxTable
                            columns={columns}
                            data={myLeaders}
                            pagination={false}
                            progressPending={this.state.loading}
                            cellsStyle={{
                                style: {
                                    backgroundColor: "#e9ebf5",
                                    padding: "5px 10px",
                                    fontSize: "12px",
                                    border: "1px solid #dee2e6",
                                },
                            }}
                            headCellsStyle={{
                                style: {
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    border: "1px solid #dee2e6",
                                    whiteSpace: "wrap",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                    paddingRight: "8px",
                                    paddingLeft: "8px",
                                    backgroundColor: "#333f50",
                                    color: "#FFFFFF",
                                },
                            }}
                        ></SkillsTxTable>
                    </ContentWrapperLg>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.person.loading,
        error: state.person.error,
        myLeaders: state.person.myLeaders,
        account: state.person.account,
        updating: state.person.updating,

        language: state.language.language,
        contentTranslations: state.content.contentTranslations,
        contentLoading: state.content.loading,
        contentError: state.content.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onPerson: bindActionCreators(personActions, dispatch),
        onContent: bindActionCreators(contentActions, dispatch),
        onConfig: bindActionCreators(configActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyLeaders);
