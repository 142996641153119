import Button from "../common/Button";
import { LinkOutlined } from "@ant-design/icons";
import { Card, notification } from "antd";
import React from "react";

const LinkComponent = ({ email, url, isActive, expireOn, hasStatus }) => {
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement) => {
    api.success({
      message: `Copy to clipboard`,
      description: "",
      placement,
    });
  };

  return (
    <Card style={{ marginBottom: 10 }}>
      {contextHolder}
      <div>
        <p
          style={{
            margin: 0,
            fontWeight: 600,
            display: "flex",
            flexDirection: "row",
            gap: 5,
          }}
        >
          {`${email} `}
          {hasStatus && (
            <>
              <p
                style={{
                  margin: 0,
                }}
              >
                -
              </p>
              <p
                style={{
                  margin: 0,
                  fontWeight: 400,
                  color: isActive ? "green" : "red",
                }}
              >
                {isActive ? `Active ( until ${expireOn} )` : `Inactive`}
              </p>
            </>
          )}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              margin: 0,
              maxWidth: "35ch",
              fontSize: 12,
              marginRight: 20,
            }}
          >
            {url}
          </p>
          {((isActive && hasStatus) || !hasStatus) && (
            <Button
              onClick={() => {
                openNotification("bottom");
                navigator.clipboard.writeText(url);
              }}
              icon={<LinkOutlined />}
            >
              Copy link
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};

export default LinkComponent;
