import NavMain from "./NavMain";
import NavMainLogin from "./NavMainLogin";
import "./NavMenu.css";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.state = {
            logoTimestamp: this.props.logoTimestamp,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.logoTimestamp !== this.props.logoTimestamp) {
            this.setState({ logoTimestamp: this.props.logoTimestamp });
        }
    }

    render() {
        return (
            <header>
                <Switch>
                    <Route path="/SfiaProfile/" render={(props) => <NavMainLogin {...props} logoTimestamp={this.state.logoTimestamp} disconnectSignalR={this.props.disconnectSignalR} language={this.props.language} setCurrentLanguage={this.props.setCurrentLanguage} />} />
                    <Route path="/mysetting" render={(props) => <NavMainLogin {...props} logoTimestamp={this.state.logoTimestamp} disconnectSignalR={this.props.disconnectSignalR} language={this.props.language} setCurrentLanguage={this.props.setCurrentLanguage} />} />
                    <Route path="/admin" render={(props) => <NavMainLogin {...props} logoTimestamp={this.state.logoTimestamp} disconnectSignalR={this.props.disconnectSignalR} language={this.props.language} setCurrentLanguage={this.props.setCurrentLanguage} />} />
                    <Route path="/" render={(props) => <NavMain {...props} logoTimestamp={this.state.logoTimestamp} language={this.props.language} setCurrentLanguage={this.props.setCurrentLanguage} boolLoginMembee={this.props.boolLoginMembee} />} />
                </Switch>
            </header>
        );
    }
}
