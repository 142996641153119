import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";

export const axios = Instance.apiInstance();

class BadgeRequestAPI {
  static getMyLatestBadgeRequest() {
    return axios
      .get(`/badgerequests/mylatestbadgerequest`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static submitBadgeRequest(badgeRequest) {
    return axios
      .post(`/badgerequests`, badgeRequest)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static createBadgeLinkRequest(badgeRequest) {
    return axios
      .post(`/badgerequests/createwalletaccess`, badgeRequest)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }
  static getBadgeWallet(id) {
    return axios
      .get(`/badgerequests/badgewallet/${id}`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getBadgeWalletLink() {
    return axios
      .get(`/badgerequests/badgewalletlink`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }
}

export default BadgeRequestAPI;
