import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row, Spinner, UncontrolledTooltip } from "reactstrap";

class StepProfile extends Component {
    static displayName = StepProfile.name;

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            stepData: this.props.stepData,
            currentStep: null,
        };
    }

    step1Clicked = () => {
        const currentStep = 1;
        this.setState({ currentStep });
        this.props.step1Clicked();
    };

    step2Clicked = () => {
        const currentStep = 2;
        this.setState({ currentStep });
        this.props.step2Clicked();
    };

    step3Clicked = () => {
        const currentStep = 3;
        this.setState({ currentStep });
        this.props.step3Clicked();
    };

    render() {
        const { stepData, currentStep } = this.state;

        const stepPanelBackgroundColor = this.props.containerConfig && this.props.containerConfig.stepPanelBackgroundColor ? this.props.containerConfig.stepPanelBackgroundColor : "#2D2D2D";
        const stepPanelFontColor = this.props.containerConfig && this.props.containerConfig.stepPanelFontColor ? this.props.containerConfig.stepPanelFontColor : "#FFFFFF";

        return !this.props.containerConfig ? (
            <div style={{ width: "100%", textAlign: "center" }}>
                <Spinner size="lg" animation="border" role="status"></Spinner>
            </div>
        ) : (
            <Container style={{ border: 1, width: "974px", height: "100%", backgroundColor: stepPanelBackgroundColor, paddingLeft: "72px", paddingRight: "72px", paddingTop: "28px", paddingBottom: "28px", marginBottom: "10px", borderRadius: "10px" }}>
                <Row xs="1" sm="1" md="3">
                    <Col className="p-0">
                        <Container>
                            <Row className="mb-1 align-items-center">
                                <Col xs="auto" className="p-0">
                                    <div id="divStep1Circle" className={"rounded-circle col align-self-center stepCircle" + (currentStep >= 1 ? " stepCircleDark-active" : "")} style={{ cursor: "pointer", border: `1px solid ${stepPanelFontColor}` }} onClick={this.step1Clicked}>
                                        <p className="mt-1" style={{ color: stepPanelFontColor, fontFamily: "IBMPlexSans-Bold", fontSize: "20px" }}>
                                            1
                                        </p>
                                    </div>
                                    <UncontrolledTooltip target={`divStep1Circle`} autohide={false} placement="bottom" dangerouslySetInnerHTML={{ __html: stepData.step1.hover }}></UncontrolledTooltip>
                                </Col>
                                <Col className="p-0">
                                    <p id="pStep1" onClick={this.step1Clicked} className="ml-2 mb-0" style={{ fontFamily: "IBMPlexSans-Light", fontSize: 11, width: "135px", cursor: "pointer", color: stepPanelFontColor }} dangerouslySetInnerHTML={{ __html: stepData.step1.desc }}></p>
                                    <UncontrolledTooltip target={`pStep1`} autohide={false} placement="bottom" dangerouslySetInnerHTML={{ __html: stepData.step1.hover }}></UncontrolledTooltip>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col className="p-0">
                        <Container>
                            <Row className="mb-1 align-items-center">
                                <Col xs="auto" className="p-0">
                                    <div id="divStep2Circle" className={"rounded-circle col align-self-center stepCircle" + (currentStep >= 2 ? " stepCircleDark-active" : "")} style={{ cursor: "pointer", border: `1px solid ${stepPanelFontColor}` }} onClick={this.step2Clicked}>
                                        <p className="mt-1" style={{ color: stepPanelFontColor, fontFamily: "IBMPlexSans-Bold", fontSize: "20px" }}>
                                            2
                                        </p>
                                    </div>
                                    <UncontrolledTooltip target={`divStep2Circle`} autohide={false} placement="bottom" dangerouslySetInnerHTML={{ __html: stepData.step2.hover }}></UncontrolledTooltip>
                                </Col>
                                <Col className="p-0">
                                    <p id="pStep2" onClick={this.step2Clicked} className="ml-2 mb-0" style={{ fontFamily: "IBMPlexSans-Light", fontSize: 11, width: "135px", cursor: "pointer", color: stepPanelFontColor }} dangerouslySetInnerHTML={{ __html: stepData.step2.desc }}></p>
                                    <UncontrolledTooltip target={`pStep2`} autohide={false} placement="bottom" dangerouslySetInnerHTML={{ __html: stepData.step2.hover }}></UncontrolledTooltip>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col className="p-0">
                        <Container>
                            <Row className="mb-1  align-items-center">
                                <Col xs="auto" className="p-0">
                                    <div id="divStep3Circle" className={"rounded-circle col align-self-center stepCircle" + (currentStep >= 3 ? " stepCircleDark-active" : "")} style={{ cursor: "pointer", border: `1px solid ${stepPanelFontColor}` }} onClick={this.step3Clicked}>
                                        <p className="mt-1" style={{ color: stepPanelFontColor, fontFamily: "IBMPlexSans-Bold", fontSize: "20px" }}>
                                            3
                                        </p>
                                    </div>
                                    <UncontrolledTooltip target={`divStep3Circle`} autohide={false} placement="bottom" dangerouslySetInnerHTML={{ __html: stepData.step3.hover }}></UncontrolledTooltip>
                                </Col>
                                <Col className="p-0">
                                    <p id="pStep3" onClick={this.step3Clicked} className="ml-2 mb-0" style={{ fontFamily: "IBMPlexSans-Light", fontSize: 11, width: "135px", cursor: "pointer", color: stepPanelFontColor }} dangerouslySetInnerHTML={{ __html: stepData.step3.desc }}></p>
                                    <UncontrolledTooltip target={`pStep3`} autohide={false} placement="bottom" dangerouslySetInnerHTML={{ __html: stepData.step3.hover }}></UncontrolledTooltip>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        containerConfig: state.config.containerConfig,
        loading: state.config.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StepProfile);
