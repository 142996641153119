import React, { useState } from "react";
import BadgeAvatar from "./BadgeAvatar"

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "200px",
    backgroundColor: "#ffffff",
    borderRadius: "20px",
    cursor: "pointer",
    paddingTop: "30px"
  },
  badgeContentContainer: {
    padding: "30px 0",
  },
  badgeTitleContainer: {
    borderRadius: "0 0 20px 20px",
    padding: "12px 0",
    color: "#ffffff",
    marginTop: "30px",
    width: "100%"
  },
  flex: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  skillAvatar: {
    width: "100px",
    height: "100px",
    borderRadius: "20px 100px 100px 100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tmpAvatar: {
    width: "90px",
    height: "90px",
    backgroundColor: "#ffffff",
    borderRadius: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const Badge = ({
  title = "",
  bgColor = "",
  lightBgColor = "",
  imgSource,
  onClick = () => {},
  data
}) => {
  const [isHover, setIsHover] = useState(false);
  const mouseEnter = () => {
    setIsHover(true);
  };
  const mouseLeave = () => {
    setIsHover(false);
  };
  return (
    <div
      style={{
        ...styles.container,
        boxShadow: `0px 8px 25px rgba(0, 0, 0, ${isHover ? "0.2" : "0.1"})`,
      }}
      onMouseOver={mouseEnter}
      onMouseOut={mouseLeave}
      onClick={() => onClick?.(data)}
    >
      <BadgeAvatar bgColor={lightBgColor} imgSource={imgSource} />
      <div
        style={{
          ...styles.badgeTitleContainer,
          ...styles.flex,
          backgroundColor: bgColor,
        }}
      >
        {title}
      </div>
    </div>
  );
};

export default Badge;
