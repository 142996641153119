import * as authActions from "../../store/actions/authAction";
import * as surveyActions from "../../store/actions/surveyAction";
import { dismisAlert, generateAlert } from "../../utils/alertUtils";
import { ContentWrapper } from "../common/ContentWrapper";
import ContentTranslator from "../translator/ContentTranslator";
import ListSurvey from "./ListSurvey";
import { StepAssessment } from "./StepAssessment";
import React, { Component } from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Col, Container, Row, Spinner } from "reactstrap";
import { Button } from "reactstrap";
import { Form } from "reactstrap";
import { FormGroup } from "reactstrap";
import { Label } from "reactstrap";
import { Modal } from "reactstrap";
import { ModalBody } from "reactstrap";
import { ModalFooter } from "reactstrap";
import { ModalHeader } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
class SurveyAssessment extends Component {
    static displayName = SurveyAssessment.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            invalidToken: false,
            surveys: [],
            surveyRoles: [],
            passwordRequestSent: false,
            assessmentId: null,
            selectedRoleId: null,
            alerts: [],
            showSelectRoleConfirmationDialog: false,
            answerLoading: false,
            showResetSurveyConfirmationDialog: false,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
    }

    componentDidMount() {
        const isAuthenticated = this.props.isAuthenticated;

        if (!isAuthenticated) {
            this.props.history.push("/");
        } else {
            this.setState({ loading: false });
            const {
                match: { params },
            } = this.props;

            var assessmentId = params.assessmentId;
            this.setState({ assessmentId });
            this.initSurveyDatas(assessmentId);
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    initSurveyDatas = (assessmentId) => {
        this.setState({ loading: true });
        this.props.onSurvey.getMySurveys(assessmentId).then(() => {
            if (!this.props.loadingSurvey) {
                if (!this.props.errorSurvey) {
                    if (this.props.surveys && this.props.surveys.length > 0) {
                        const unAnswerred = this.props.surveys.filter((it) => it.elements.some((it) => !(it.assessmentPersonResultId && (it.valueId || (it.values && it.values.length > 0)))));
                        if (unAnswerred.length == 0) {
                            this.props.onSurvey.completeMySurvey();
                        }
                    }

                    this.setState({ surveys: this.props.surveys, surveyRoles: this.props.surveyRoles });
                } else {
                    this.generateAlert("danger", this.props.errorSurvey.errData.Message);
                }
            }
            this.setState({ loading: this.props.loadingSurvey });
        });
    };

    createAccount = (surveyRating) => {
        this.setState({ loading: true });
        this.props.onAuth.createAccount(surveyRating).then(() => {
            if (!this.props.loading) {
                if (!this.props.error) {
                    this.setState({ passwordRequestSent: true });
                    this.props.history.push("/resetpasswordconfirmation");
                }
            }
            this.setState({ loading: this.props.loading });
        });
    };

    submitRating = (surveyRating) => {
        this.setState({ loading: true });
        this.props.onAuth.submitRating(surveyRating).then(() => {
            if (!this.props.loading) {
                if (!this.props.error) {
                    this.props.onAuth.authLogout().then(() => {
                        window.location = "/sso/login";
                    });
                } else {
                    this.generateAlert("danger", this.props.error.errData.Message);
                }
            }
            this.setState({ loading: this.props.loading });
        });
    };

    submitRatingTeams = (surveyRating) => {
        this.setState({ loading: true });
        this.props.onAuth.submitRatingTeams(surveyRating).then(() => {
            if (!this.props.loading) {
                if (!this.props.error) {
                    this.props.onAuth.forceRefreshToken().then(() => {
                        window.location = "/SfiaProfile";
                    });
                } else {
                    this.generateAlert("danger", this.props.error.errData.Message);
                }
            }
            this.setState({ loading: this.props.loading });
        });
    };

    selectRole = (roleId) => {
        this.setState({ selectedRoleId: roleId, showSelectRoleConfirmationDialog: true });
    };

    togleSelectRoleConfirmationDialog = () => {
        const showSelectRoleConfirmationDialog = this.state.showSelectRoleConfirmationDialog;
        this.setState({ showSelectRoleConfirmationDialog: !showSelectRoleConfirmationDialog });
    };

    handleConfimRoleSelection = () => {
        const roleId = this.state.selectedRoleId;
        const assessmentId = this.state.assessmentId;

        this.setState({ answerLoading: true });

        this.props.onSurvey.mySurveySelectRole(assessmentId, roleId).then(() => {
            if (!this.props.answerLoading) {
                if (!this.props.errorSurvey) {
                    this.setState({ showSelectRoleConfirmationDialog: false });
                    this.initSurveyDatas(assessmentId);
                }
            }

            this.setState({ answerLoading: this.props.answerLoading });
        });
    };

    renderSelectRoleConfirmation = () => {
        return (
            <Modal isOpen={this.state.showSelectRoleConfirmationDialog} toggle={!this.state.answerLoading ? this.togleSelectRoleConfirmationDialog : null}>
                <ModalHeader toggle={!this.state.answerLoading ? this.togleSelectRoleConfirmationDialog : null}>
                    <ContentTranslator page="Assessment" name="AssessmentConfirmRoleSelection" contentText="Confirm your selection" />
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        <ContentTranslator page="Assessment" name="AssessmentConfirmRoleSelectionMessage" contentText="Are you sure you have selected the correct level of experience? Your selection will determine what surveys you answer." />
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfimRoleSelection();
                        }}
                        disabled={this.state.answerLoading}
                    >
                        <ContentTranslator page="SfiaAll" name="Confirm" contentText="Confirm" />
                    </Button>
                    <Button color="secondary" onClick={this.togleSelectRoleConfirmationDialog} disabled={this.state.answerLoading}>
                        <ContentTranslator page="SfiaAll" name="Cancel" contentText="Cancel" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    resetSurvey = () => {
        this.setState({ showResetSurveyConfirmationDialog: true });
    };

    togleResetSurveyConfirmation = () => {
        const showResetSurveyConfirmationDialog = this.state.showResetSurveyConfirmationDialog;
        this.setState({ showResetSurveyConfirmationDialog: !showResetSurveyConfirmationDialog });
    };

    handleConfimResetSurvey = () => {
        const assessmentId = this.state.assessmentId;
        this.setState({ answerLoading: true });

        this.props.onSurvey.resetMySurvey().then(() => {
            if (!this.props.answerLoading) {
                if (!this.props.errorSurvey) {
                    this.setState({ showResetSurveyConfirmationDialog: false });
                    this.initSurveyDatas(assessmentId);
                } else {
                    this.generateAlert("danger", this.props.errorSurvey.errData.Message);
                }
            }

            this.setState({ answerLoading: this.props.answerLoading });
        });
    };

    renderResetSurveyConfirmation = () => {
        return (
            <Modal isOpen={this.state.showResetSurveyConfirmationDialog} toggle={!this.state.answerLoading ? this.togleResetSurveyConfirmation : null}>
                <ModalHeader toggle={!this.state.answerLoading ? this.togleResetSurveyConfirmation : null}>
                    <ContentTranslator page="Assessment" name="AssessmentStartAgainConfirmation" contentText="Start Again" />
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        <ContentTranslator page="Assessment" name="AssessmentStartAgainMessage" contentText="IMPORTANT: By Starting Again, ALL your answers will be CLEARED and you will be able reselect your level of experience. Are you SURE you wish to continue?" />
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfimResetSurvey();
                        }}
                        disabled={this.state.answerLoading}
                    >
                        <ContentTranslator page="SfiaAll" name="OK" contentText="OK" />
                    </Button>
                    <Button color="secondary" onClick={this.togleResetSurveyConfirmation} disabled={this.state.answerLoading}>
                        <ContentTranslator page="SfiaAll" name="Cancel" contentText="Cancel" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    render() {
        let firstName = localStorage.getItem("firstName");

        let isComplete = false;

        if (this.state.surveys && this.state.surveys.length > 0) {
            const unAnswerred = this.state.surveys.filter((it) => it.elements.some((it) => !(it.assessmentPersonResultId && (it.valueId || (it.values && it.values.length > 0)))));
            if (unAnswerred.length == 0) {
                isComplete = true;
            }
        }

        return (
            <div>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.renderSelectRoleConfirmation()}
                {this.renderResetSurveyConfirmation()}
                {this.state.loading ? (
                    <div>
                        <Row>
                            <Col className="p-0">
                                <center>
                                    <Spinner color="dark" />
                                </center>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <React.Fragment>
                        {this.props.isAuthenticated && (
                            <React.Fragment>
                                {this.state.passwordRequestSent ? (
                                    <React.Fragment>
                                        <ContentWrapper>
                                            <Container>
                                                <Row>
                                                    <Col className="p-0">
                                                        <p
                                                            className="color-dark"
                                                            style={{
                                                                fontFamily: "IBMPlexSans-Bold",
                                                                fontSize: "38px",
                                                                align: "left",
                                                            }}
                                                        >
                                                            Account Confirmation
                                                        </p>
                                                        <p
                                                            className="color-dark"
                                                            style={{
                                                                fontFamily: "IBMPlexSans-Light",
                                                                fontSize: "14px",
                                                                align: "left",
                                                            }}
                                                        >
                                                            We have sent you an email with a link to setup your password and access your profile.
                                                            <br />
                                                            Please check your emails ASAP because the link will expire after 24 hours.
                                                            <br />
                                                            You must use the same email address that you registered for your self assessment.
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </ContentWrapper>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <StepAssessment name={firstName} isComplete={isComplete} contentTranslations={this.props.contentTranslations} />
                                        <ListSurvey
                                            surveys={this.state.surveys}
                                            surveyRoles={this.state.surveyRoles}
                                            createAccount={this.createAccount}
                                            submitRating={this.submitRating}
                                            submitRatingTeams={this.submitRatingTeams}
                                            selectRole={this.selectRole}
                                            resetSurvey={this.resetSurvey}
                                            inProgress={this.props.answerLoading}
                                            selectedRoleId={this.state.selectedRoleId}
                                            contentTranslations={this.props.contentTranslations}
                                            samlEnabled={this.props.samlEnabled}
                                            defaultCompany={this.props.defaultCompany}
                                            loginWithTeams={this.props.loginWithTeams}
                                            loading={this.state.loading}
                                        />
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null,
        loading: state.auth.loading,
        error: state.auth.error,
        email: state.auth.email,
        loginResult: state.auth.loginResult,
        loginWithTeams: state.auth.loginWithTeams,

        loadingSurvey: state.survey.loading,
        errorSurvey: state.survey.error,
        surveys: state.survey.surveys,
        surveyRoles: state.survey.surveyRoles,
        answerLoading: state.survey.answerLoading,

        contentTranslations: state.content.contentTranslations,
        samlEnabled: state.config.samlEnabled,
        defaultCompany: state.company.defaultCompany,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onSurvey: bindActionCreators(surveyActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyAssessment);
