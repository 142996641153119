import * as authActions from "../../store/actions/authAction";
import * as surveyActions from "../../store/actions/surveyAction";
import { ContentWrapper } from "../common/ContentWrapper";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";
import { Userpilot } from "userpilot";

const userPilotToken = process.env.REACT_APP_USER_PILOT_TOKEN;
Userpilot.initialize(userPilotToken);

class Survey extends Component {
    static displayName = Survey.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            invalidToken: false,
            surveys: [],
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get("token");

        const isAuthenticated = this.props.isAuthenticated;

        if (!isAuthenticated) {
            if (!token) {
                this.props.history.push("/");
            } else {
                this.props.onAuth.signInUseToken(token).then(() => {
                    if (!this.props.loading) {
                        if (!this.props.error) {
                            Userpilot.identify(this.props.loginResult.personId, { name: `${this.props.loginResult.firstName} ${this.props.loginResult.lastName}`, email: this.props.loginResult.email });

                            Promise.resolve(
                                this.props.onAuth.signInSuccess(
                                    this.props.loginResult.token,
                                    this.props.email,
                                    this.props.assessmentId,
                                    this.props.jobProfileReportFormat,
                                    this.props.selfAssessedProfileReportFormat,
                                    this.props.hasPeoples,
                                    this.props.isEndorser,
                                    this.props.authMode,
                                    this.props.loginResult.personId,
                                    this.props.ownJobs,
                                    this.props.manageEndorsements,
                                    this.props.manageCertifications,
                                    this.props.loginWithMembee,
                                    this.props.loginWithTeams,
                                    this.props.receiveLicenseNotification,
                                    this.props.lastActiveUserNotification,
                                    this.props.allowSkipSurvey,
                                    this.props.manageSelfAssessed,
                                    this.props.manageSelfProfiles,
                                    this.props.hasRelationships
                                )
                            ).then(() => {
                                this.props.history.push(`/survey/assessment/${this.props.assessmentId}`);
                            });
                        } else {
                            this.props.history.push(`/login`);
                        }
                    }
                    this.setState({ loading: this.props.loading });
                });
            }
        } else {
            this.setState({ loading: false });
            this.props.history.push(`/survey/assessment/${this.props.assessmentId}`);
        }
    }

    invalidToken() {
        return (
            <ContentWrapper>
                <Container>
                    <Row>
                        <Col className="p-0">
                            <p
                                className="color-dark"
                                style={{
                                    fontFamily: "IBMPlexSans-Bold",
                                    fontSize: 20,
                                }}
                            >
                                Error!!! Invalid authentication token
                            </p>
                        </Col>
                    </Row>
                </Container>
            </ContentWrapper>
        );
    }

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <div>
                        <Row>
                            <Col className="p-0">
                                <center>
                                    <Spinner color="dark" />
                                </center>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <React.Fragment>{!this.props.isAuthenticated && this.invalidToken()}</React.Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null,
        assessmentId: state.auth.assessmentId,
        jobProfileReportFormat: state.auth.jobProfileReportFormat,
        selfAssessedProfileReportFormat: state.auth.selfAssessedProfileReportFormat,
        authMode: state.auth.authMode,
        loading: state.auth.loading,
        error: state.auth.error,
        email: state.auth.email,
        loginResult: state.auth.loginResult,
        hasPeoples: state.auth.hasPeoples,
        isEndorser: state.auth.isEndorser,
        ownJobs: state.auth.ownJobs,
        hasRelationships: state.auth.hasRelationships,

        manageEndorsements: state.auth.manageEndorsements,
        manageCertifications: state.auth.manageCertifications,
        manageSelfAssessed: state.auth.manageSelfAssessed,
        manageSelfProfiles: state.auth.manageSelfProfiles,
        loginWithMembee: state.auth.loginWithMembee,
        loginWithTeams: state.auth.loginWithTeams,
        receiveLicenseNotification: state.auth.receiveLicenseNotification,
        lastActiveUserNotification: state.auth.lastActiveUserNotification,
        allowSkipSurvey: state.auth.allowSkipSurvey,

        loadingSurvey: state.survey.loading,
        errorSurvey: state.survey.error,
        surveys: state.survey.surveys,
        surveyRoles: state.survey.surveyRoles,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onSurvey: bindActionCreators(surveyActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
