import BadgeRequestAPI from "./../../common/api/service/BadgeRequestService";
import * as actionTypes from "./actionTypes";

export const getMyLatestBadgeRequestStart = () => {
    return {
        type: actionTypes.GET_MY_LATEST_BADGE_REQUEST_START,
    };
};

export const getMyLatestBadgeRequestSuccess = (myBadgeRequest) => {
    return {
        type: actionTypes.GET_MY_LATEST_BADGE_REQUEST_SUCCESS,
        myBadgeRequest,
    };
};

export const getMyLatestBadgeRequestFail = (error) => {
    return {
        type: actionTypes.GET_MY_LATEST_BADGE_REQUEST_FAIL,
        error: error,
    };
};

export const getMyLatestBadgeRequest = () => {
    return (dispatch) => {
        dispatch(getMyLatestBadgeRequestStart());

        return BadgeRequestAPI.getMyLatestBadgeRequest()
            .then((res) => {
                if (res) {
                    dispatch(getMyLatestBadgeRequestSuccess(res));
                } else {
                    dispatch(getMyLatestBadgeRequestFail(res));
                }
            })
            .catch((err) => {
                dispatch(getMyLatestBadgeRequestFail(err));
            });
    };
};

export const submitBadgeRequestStart = () => {
    return {
        type: actionTypes.SUBMIT_BADGE_REQUEST_START,
    };
};

export const submitBadgeRequestSuccess = (badgeRequest) => {
    return {
        type: actionTypes.SUBMIT_BADGE_REQUEST_SUCCESS,
        badgeRequest,
    };
};

export const submitBadgeRequestFail = (error) => {
    return {
        type: actionTypes.SUBMIT_BADGE_REQUEST_FAIL,
        error: error,
    };
};

export const submitBadgeRequest = (badgeRequest) => {
    return (dispatch) => {
        dispatch(submitBadgeRequestStart());

        return BadgeRequestAPI.submitBadgeRequest(badgeRequest)
            .then((res) => {
                if (res) {
                    dispatch(submitBadgeRequestSuccess(res));
                } else {
                    dispatch(submitBadgeRequestFail(res));
                }
            })
            .catch((err) => {
                dispatch(submitBadgeRequestFail(err));
            });
    };
};
