import { Select, TreeSelect } from "antd";
import React, { useState } from "react";

const { SHOW_PARENT } = TreeSelect;

const CustomSelect = ({
  label,
  obj = {},
  isTreeSelect = false,
  placeholder,
}) => {
  // Expanded keys state for TreeSelect
  const [expandedKeys, setExpandedKeys] = useState([]);

  // Function to handle tree expansion
  const onTreeExpanded = (keys) => setExpandedKeys(keys);

  // Function to handle dropdown visibility change
  const onDropdownVisibleChanged = (isOpen) => {
    // Reset expanded keys when dropdown opens, no values are selected, and there are expanded keys
    if (isOpen && !obj.value?.length && expandedKeys.length) {
      setExpandedKeys([]);
    }
  };

  // Common props shared by both TreeSelect and Select
  const commonProps = {
    value: obj.value,
    onChange: (val) => obj.setValue?.(val), // Using optional chaining to avoid potential errors
    allowClear: true,
    showArrow: true,
    size: "middle",
    placeholder,
    style: { width: "100%" },
  };

  return (
    <>
      {/* Render label */}
      <span className="font-small">{label}</span>

      {/* Conditional rendering: If isTreeSelect is true, render TreeSelect, else render Select */}
      {isTreeSelect ? (
        <TreeSelect
          {...commonProps} // Spread common properties
          treeData={obj.options ?? []} // Fallback to an empty array if options are undefined
          treeCheckable={true}
          showCheckedStrategy={SHOW_PARENT}
          treeExpandedKeys={expandedKeys}
          onDropdownVisibleChange={onDropdownVisibleChanged}
          onTreeExpand={onTreeExpanded}
        />
      ) : (
        <Select
          {...commonProps} // Spread common properties
          options={obj.options ?? []} // Fallback to an empty array if options are undefined
          mode="multiple"
        />
      )}
    </>
  );
};

export default CustomSelect;
