import axios from "axios";
import instanceInterceptor from "./../interceptorService";

const apiHostname = process.env.REACT_APP_API_ENDPOINT;

const apiAxiosInstance = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? `${apiHostname}` : `/api`,
});

class instance {
    static apiInstance() {
        instanceInterceptor.responseInterceptor(apiAxiosInstance);
        instanceInterceptor.requestInterceptor(apiAxiosInstance);
        return apiAxiosInstance;
    }
}

export default instance;