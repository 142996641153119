import * as actionTypes from "../actions/actionTypes";
import { updateState } from "../utility";

const initialState = {
    companies: [],
    providers: [],
    selectedProviders: [],
    error: null,
    loading: false,
    defaultCompany: null,
    brandingLogoImage: null,
    brandingLogoChecked: false,
};

const getCompaniesStart = (state) => {
    return updateState(state, { error: null, companies: [], loading: true });
};

const getCompaniesSuccess = (state, action) => {
    return updateState(state, {
        companies: action.companies,
        error: null,
        loading: false,
    });
};

const getCompaniesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getDefaultCompanyStart = (state) => {
    return updateState(state, { error: null, defaultCompany: null, loading: true });
};

const getDefaultCompanySuccess = (state, action) => {
    return updateState(state, {
        defaultCompany: action.defaultCompany,
        error: null,
        loading: false,
    });
};

const getDefaultCompanyFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getCompanyBrandingLogoStart = (state) => {
    return updateState(state, { error: null, brandingLogoImage: null, loading: true, brandingLogoChecked: false });
};

const getCompanyBrandingLogoSuccess = (state, action) => {
    return updateState(state, {
        brandingLogoImage: action.brandingLogoImage,
        error: null,
        loading: false,
        brandingLogoChecked: true,
    });
};

const getCompanyBrandingLogoFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
        brandingLogoChecked: true,
    });
};

const createCompanyStart = (state) => {
    return updateState(state, { error: null, loading: true });
};

const createCompanySuccess = (state, action) => {
    const addedCompany = action.addedCompany;
    let companies = state.companies;

    return updateState(state, {
        companies: [...companies, addedCompany],
        error: null,
        loading: false,
    });
};

const createCompanyFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const updateCompanyStart = (state) => {
    return updateState(state, { error: null, loading: true });
};

const updateCompanySuccess = (state, action) => {
    const updatedCompany = action.updatedCompany;
    let companies = state.companies;
    const company = companies.find((it) => it.id == updatedCompany.id);
    if (company) {
        company.name = updatedCompany.name;
        company.website = updatedCompany.website;
        company.country = updatedCompany.country;
        company.isSelected = updatedCompany.isSelected;
        company.isPreferred = updatedCompany.isPreferred;
    }

    return updateState(state, {
        companies: [...companies],
        error: null,
        loading: false,
    });
};

const updateCompanyFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getSelectedProviderCompaniesStart = (state) => {
    return updateState(state, { error: null, selectedProviders: [], loading: true });
};

const getSelectedProviderCompaniesSuccess = (state, action) => {
    return updateState(state, {
        selectedProviders: action.companies,
        error: null,
        loading: false,
    });
};

const getSelectedProviderCompaniesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getProviderCompaniesStart = (state) => {
    return updateState(state, { error: null, providers: [], loading: true });
};

const getProviderCompaniesSuccess = (state, action) => {
    return updateState(state, {
        providers: action.companies,
        error: null,
        loading: false,
    });
};

const getProviderCompaniesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMPANIES_START:
            return getCompaniesStart(state);
        case actionTypes.GET_COMPANIES_SUCCESS:
            return getCompaniesSuccess(state, action);
        case actionTypes.GET_COMPANIES_FAIL:
            return getCompaniesFail(state, action);

        case actionTypes.GET_DEFAULT_COMPANY_START:
            return getDefaultCompanyStart(state);
        case actionTypes.GET_DEFAULT_COMPANY_SUCCESS:
            return getDefaultCompanySuccess(state, action);
        case actionTypes.GET_DEFAULT_COMPANY_FAIL:
            return getDefaultCompanyFail(state, action);

        case actionTypes.GET_COMPANY_BRANDING_LOGO_START:
            return getCompanyBrandingLogoStart(state);
        case actionTypes.GET_COMPANY_BRANDING_LOGO_SUCCESS:
            return getCompanyBrandingLogoSuccess(state, action);
        case actionTypes.GET_COMPANY_BRANDING_LOGO_FAIL:
            return getCompanyBrandingLogoFail(state, action);

        case actionTypes.CREATE_COMPANY_START:
            return createCompanyStart(state);
        case actionTypes.CREATE_COMPANY_SUCCESS:
            return createCompanySuccess(state, action);
        case actionTypes.CREATE_COMPANY_FAIL:
            return createCompanyFail(state, action);

        case actionTypes.UPDATE_COMPANY_START:
            return updateCompanyStart(state);
        case actionTypes.UPDATE_COMPANY_SUCCESS:
            return updateCompanySuccess(state, action);
        case actionTypes.UPDATE_COMPANY_FAIL:
            return updateCompanyFail(state, action);

        case actionTypes.GET_SELECTED_PROVIDER_COMPANIES_START:
            return getSelectedProviderCompaniesStart(state);
        case actionTypes.GET_SELECTED_PROVIDER_COMPANIES_SUCCESS:
            return getSelectedProviderCompaniesSuccess(state, action);
        case actionTypes.GET_SELECTED_PROVIDER_COMPANIES_FAIL:
            return getSelectedProviderCompaniesFail(state, action);

        case actionTypes.GET_PROVIDER_COMPANIES_START:
            return getProviderCompaniesStart(state);
        case actionTypes.GET_PROVIDER_COMPANIES_SUCCESS:
            return getProviderCompaniesSuccess(state, action);
        case actionTypes.GET_PROVIDER_COMPANIES_FAIL:
            return getProviderCompaniesFail(state, action);

        default:
            return state;
    }
};

export default reducer;
