import * as authActions from "../../store/actions/authAction";
import * as adminCompanyActions from "../../store/admin/actions/adminCompanyActions";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import { dismisAlert, generateAlert } from "./../../utils/alertUtils";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Button, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "18px",
    fontFamily: "IBMPlexSans-Bold",
};

class AdminBranding extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            selectedImage: null,
            isUpdating: false,
            timestamp: new Date(),
            brandingLogoFound: false,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.imageRef = React.createRef();
    }

    componentDidMount() {
        this.props.onAuth.authCheckState().then(() => {
            this.props.onCompany.getCompanyBrandingLogo().then(() => {
                this.setState({ brandingLogoFound: this.props.brandingLogoFound });
            });

            this.setState({ loading: this.props.loading });
        });
    }

    handleSelectPicture = () => {
        this.imageRef.current.click();
    };

    handlePictureChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({ selectedImage: reader.result });
            });
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    handleResetPicture = () => {
        this.setState({ selectedImage: null });
    };

    handleUploadPicture = () => {
        this.setState({ isUpdating: true });
        const selectedImage = this.state.selectedImage;
        const selectedImages = selectedImage.split(";base64,");

        this.props.onCompany.updateCompanyBrandingLogo(selectedImages[1]).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.companyError) {
                    this.generateAlert("success", "Update branding logo success.");
                    this.setState({ selectedImage: null, timestamp: new Date(), brandingLogoFound: true });
                    this.props.logoUpdated();
                } else {
                    this.generateAlert("danger", this.props.companyError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleDeletePicture = () => {
        if (window.confirm("Are you sure want to delete the branding logo?")) {
            this.setState({ isUpdating: true });

            this.props.onCompany.deleteCompanyBrandingLogo().then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.companyError) {
                        this.generateAlert("success", "Delete branding logo success.");
                        this.setState({ selectedImage: null, timestamp: new Date(), brandingLogoFound: false });
                        this.props.logoUpdated();
                    } else {
                        this.generateAlert("danger", this.props.companyError.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    render() {
        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Upload Company Logo");

        const apiHostname = process.env.REACT_APP_API_ENDPOINT;
        const baseURL = process.env.NODE_ENV === "development" ? `${apiHostname}` : `/api`;

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <input ref={this.imageRef} type="file" style={{ display: "none" }} accept="image/*" onChange={this.handlePictureChange} />
                        <ContentWrapperLg style={{ width: "100%", maxWidth: "100%" }} className="contentWrapperLgAdmin-Style">
                            <p className={"page-title"} style={headerStyle}>
                                Branding Logo
                            </p>
                            {this.state.selectedImage ? (
                                <img src={this.state.selectedImage} alt="Branding Logo" style={{ maxHeight: "100px" }} />
                            ) : (
                                <img
                                    src={`${baseURL}/companies/brandinglogo?timestamp=${this.state.timestamp}`}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = process.env.PUBLIC_URL + "/assets/img/lgo/skillstx-logo-top.png";
                                    }}
                                    alt="Branding Logo"
                                    style={{ maxHeight: "100px" }}
                                />
                            )}
                            <br />
                            <br />
                            {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) && (
                                <React.Fragment>
                                    <Button
                                        color="primary"
                                        onClick={this.handleSelectPicture}
                                        style={{
                                            fontSize: "16px",
                                        }}
                                        disabled={this.state.isUpdating}
                                    >
                                        <FontAwesomeIcon icon={faImage} /> Select Image
                                    </Button>
                                    &nbsp;&nbsp;
                                    {this.state.selectedImage && (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={this.handleUploadPicture}
                                                style={{
                                                    fontSize: "16px",
                                                }}
                                                disabled={this.state.isUpdating}
                                            >
                                                Upload
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                color="primary"
                                                onClick={this.handleResetPicture}
                                                style={{
                                                    fontSize: "16px",
                                                }}
                                                disabled={this.state.isUpdating}
                                            >
                                                Cancel
                                            </Button>
                                            &nbsp;&nbsp;
                                        </React.Fragment>
                                    )}
                                    {this.state.brandingLogoFound && (
                                        <React.Fragment>
                                            <Button
                                                color="danger"
                                                onClick={this.handleDeletePicture}
                                                style={{
                                                    fontSize: "16px",
                                                }}
                                                disabled={this.state.isUpdating}
                                            >
                                                Delete Image
                                            </Button>
                                            &nbsp;&nbsp;
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </ContentWrapperLg>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,

        companyLoading: state.adminCompany.loading,
        companyError: state.adminCompany.error,
        isUpdating: state.adminCompany.isUpdating,
        brandingLogoFound: state.adminCompany.brandingLogoFound,
        pageAssignments: state.adminPerson.pageAssignments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onCompany: bindActionCreators(adminCompanyActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminBranding);
