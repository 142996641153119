import checkValidity from './checkValidity';
import { updateState } from './../store/utility';

const changeInput = (formObject, name, value) => {
    let isNotEmpty = false;
    let isValidFormat = false;

    if (!formObject[name].validation.isNumeric && !formObject[name].validation.isBool && !formObject[name].validation.isDateTime && formObject[name].validation.required) {
        isNotEmpty = value ? value.trim() !== '' : false;
    } else if (formObject[name].validation.isBool && formObject[name].validation.required) {
        isNotEmpty = value;
    } else if (formObject[name].validation.isDateTime && formObject[name].validation.required) {
        isNotEmpty = value;
    } else {
        isNotEmpty = true;
    }

    isValidFormat = checkValidity(value, formObject[name].validation);

    const updatedFormElement = updateState(formObject[name], {
        value: value,
        valid: isNotEmpty,
        isValidFormat: isValidFormat,
        touched: true,
    });
    const updatedOrderForm = updateState(formObject, {
        [name]: updatedFormElement,
    });

    let formIsValid = true;
    for (let name in updatedOrderForm) {
        formIsValid =
            updatedOrderForm[name].valid &&
            updatedOrderForm[name].isValidFormat &&
            formIsValid;
    }

    return {
        form: updatedOrderForm,
        valid: formIsValid,
    };
};

export default changeInput;
