import React, { Component } from "react";

// import bottomBg1 from './../assets/img/bg/bottom-bg1.png';
// import bottomBg2 from './../assets/img/bg/bottom-bg2.png';

const bgWrapperStyle = {
    backgroundColor: '#F8F8F8',
    width: '100%',
    height: "100%",
    position: 'absolute',
    bottom: '0px',
    zIndex: -2
};

const bottomBgStyle = {
    position: 'absolute',
    overflow: 'hidden',
    bottom: '0px',
    left: '0px',
    width: '100%',
    maxWidth: '563px',
    height: '100%',
    zIndex: -1
}


const img1BottomStyle = {
    position: 'absolute',
    bottom: '-150px',
    left: '-50px',
    overflow: 'hidden',
}

const img2BottomStyle = {
    position: 'absolute',
    bottom: '-230px',
    left: '-50px',
    overflow: 'hidden',
}


export class Footer extends Component {
    static displayName = Footer.name;

    render() {
        return (
            <div id="footer-wrapper" className="footerWrapperStyle">
                <div id="bottom-bg" style={bottomBgStyle}>
                    <img src={process.env.PUBLIC_URL + "/assets/img/bg/bottom-bg1.png"} alt="Bottom Bg1" style={img1BottomStyle} width="563" height="914" />
                    <img src={process.env.PUBLIC_URL + "/assets/img/bg/bottom-bg2.png"} alt="Bottom Bg2" style={img2BottomStyle} width="563" height="914" />
                </div>
                <div style={bgWrapperStyle}>
                </div>
                <div className="footerStyle">{this.props.children}</div>
            </div>
        );
    }
}