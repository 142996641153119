import { InfoCircleOutlined, UpOutlined } from "@ant-design/icons";
import { Table, Tag, Tooltip } from "antd";
import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";

const WrapperTable = styled.div`
  white-space: nowrap;
  border-top: none;
  margin-top: 20px;

  .ant-table.ant-table-middle
    .ant-table-tbody
    .ant-table-wrapper:only-child
    .ant-table {
    margin: 0px;
  }

  .ant-table-cell:has(.p-zero) {
    padding: 0px !important;
  }

  .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed .ant-table,
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: 0px;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table {
    border-top-left-radius: 0x;
    border-top-right-radius: 0px;
  }

  .ant-table {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table {
    table-layout: auto;
    border-color: gainsboro;
    border-style: solid;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-width: 0px;
  }

  .ant-table-content > table {
    table-layout: auto;
    border-color: gainsboro;
    border-style: solid;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    border-width: 0.5px;
  }

  .ant-table-cell:has(.b-none) {
    border-color: white !important;
    transition: background 0.3s;
  }

  .ant-table-cell:has(.c-lv) {
    text-align: center;
  }

  .ant-table-cell:has(.c-w) {
    width: 50px !important;
  }

  .ant-table-cell:has(.e-w) {
    width: 80px !important;
  }

  .ant-table-container table > thead > tr:first-child th {
    background: ${(props) =>
      props?.customStyle?.tableHeaderBackgroundColor || ""};
    color: ${(props) => props?.customStyle?.tableHeaderColor || ""};
    text-align: center;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    table
    > thead
    > tr:first-child
    th {
    background: #fafafa;
    color: black;
    text-align: center;
    cursor: pointer;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    table
    > thead
    > tr:first-child
    th:first-child {
    border-top-left-radius: 0px;
    text-align: start;
    width: auto !important;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 14px;
    text-align: start;
    width: 280px;
  }
  .ant-table-cell {
    padding: 10px;
  }
  .ant-table-row-expand-icon {
    display: none;
  }

  .ant-table-row-indent {
    padding-left: 0 !important;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    table
    > thead
    > tr:first-child
    td:first-child {
    border-top-left-radius: 0px;
    width: auto !important;
  }

  .table-s .ant-table-column-sorters {
    text-align: center;
  }

  .ant-table-container table > thead > tr:first-child td:first-child {
    border-top-left-radius: 14px;
    text-align: start;
    width: 280px;
  }

  .table-s
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    table
    > thead
    > tr:first-child
    th:last-child {
    border-top-right-radius: 0px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 14px;
  }

  .rotate-180 {
    transform: rotate(180deg);
    transition: transform 0.5s;
  }
  .rotate-0 {
    transform: rotate(0deg);
    transition: transform 0.5s;
  }
`;

const WrapperTitleCustom = styled.div`
  text-align: center;
  margin-top: -7px;
  cursor: pointer;
`;

const WrapperTooltipCustom = styled(Tooltip)`
  position: absolute;
  right: 5%;
  cursor: help;
  margin-top: 7px;
`;

const WrapperLevelCustom = styled.div`
  padding: 5px;
`;

const WrapperCellCustom = styled.div`
  text-align: center;
  margin-top: -7px;
  cursor: pointer;
`;

const WrapperCellSkillCategoryCustom = styled(Tooltip)`
  padding-left: 20px;
`;

const WrapperCellSubCategoryCustom = styled(Tooltip)`
  font-weight: 700;
`;

const WrapperSlide = styled.div`
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  animation: slide 0.2s;
  @keyframes slide {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;

const constantData = {
  lvBackgroundColorDefault: "#F0F0F0",
  tableSize: "default",
  expandedTableSize: "default",
  skillLevelCount: 7,
  colorLevel: 5,
  categoryName: "category",
  expandName: "expand",
  bottomName: "bottom",
  levelName: "Level",
  rootName: "root",
};

// Helper function to generate a table column that centers its text content.
// This avoids repetition for multiple columns that require similar formatting.
const createCenteredTextColumn = (title, dataIndex, sorterFunction) => ({
  title, // Column header
  dataIndex, // Data key
  key: dataIndex, // Key for React's list rendering
  sorter: sorterFunction, // Sorting function for the column
  render: (text) => <div className="text-center">{text ?? "-"}</div>, // Centering the text
});

// Utility function to compare text values
const sortByText = (a, b) => (a ?? "").localeCompare(b ?? "");

const SkillGapTable = ({ data = null }) => {
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [listExpand, setListExpand] = useState([
    {
      name: constantData.rootName,
      isExpand: true,
    },
  ]);
  const [expendedRowKeysRef, setExpendedRowKeysRef] = useState({
    level: null,
    name: null,
    keys: [],
  });
  const [expendedRowKeysCurrent, setExpendedRowKeysCurrent] = useState({
    level: null,
    name: null,
    keys: [],
  });

  const onExpandedRowRendered = (record) => {
    // Transform the record's people data into the format suitable for the table.
    const dataSource = record.people
      .filter((item) => item.level === expendedRowKeysRef?.level) // Filter by the specific level
      .map((item, index) => ({
        // Map over the filtered items
        key: `${index + 1}`,
        ...item, // Spread the properties of each item
      }));

    // Define columns for the table. These determine how data is displayed in the table.
    const columns = [
      createCenteredTextColumn("Full Name", "fullName", (a, b) =>
        sortByText(a.fullName, b.fullName)
      ),
      createCenteredTextColumn("Person Email", "email", (a, b) =>
        sortByText(a.email, b.email)
      ),
      {
        title: "Person Skill Level",
        dataIndex: "skillLevel",
        key: "skillLevel",
        sorter: (a, b) =>
          sortByText(`${a.skillCode} ${a.level}`, `${b.skillCode} ${b.level}`),
        render: (text, record) => (
          <div className="text-center">
            <Tooltip
              title={`${record.capability ? record.skill : ""} Level ${
                record.level
              }`}
            >
              <Tag color={record.colour} style={{ color: "black" }}>
                {`${record.skillCode} ${record.level}`}
              </Tag>
            </Tooltip>
          </div>
        ),
      },
      createCenteredTextColumn("Capability", "capability", (a, b) =>
        sortByText(a.capability, b.capability)
      ),
      createCenteredTextColumn("Requirement Type", "jobType", (a, b) =>
        sortByText(a.jobType, b.jobType)
      ),
      {
        title: "Skill Gap",
        dataIndex: "skillGap",
        key: "skillGap",
        sorter: (a, b) =>
          {
            const askillGap = a.selfAssessedSkillGap == a.endorsedSkillGap ? a.skillGap : `Self Assessed ${a.selfAssessedSkillGap} - Endorsed ${a.endorsedSkillGap}`;
            const bskillGap = b.selfAssessedSkillGap == b.endorsedSkillGap ? b.skillGap : `Self Assessed ${b.selfAssessedSkillGap} - Endorsed ${b.endorsedSkillGap}`;
            sortByText(askillGap, bskillGap) 
          },
        render: (text, record) => (
          <div className="text-center">
            {record.selfAssessedSkillGap == record.endorsedSkillGap ? record.skillGap :
            <span>
            {`Self Assessed - ${record.selfAssessedSkillGap}`}  <br />
            {`Endorsed - ${record.endorsedSkillGap}`}
            </span>
            }
          </div>
        ),
      },
    ];

    // Return the formatted table contained within a styled wrapper.
    return (
      <WrapperSlide className="p-zero">
        <div className="table-s">
          <Table
            size={constantData.expandedTableSize} // Set table size from constants
            pagination={false} // Disable pagination
            columns={columns} // Use the defined columns
            dataSource={dataSource} // Use the transformed data source
          />
        </div>
      </WrapperSlide>
    );
  };

  // This function determines if a row is expandable based on the 'isSkill' attribute.
  const onRowExpandable = (record) => {
    // Directly return the boolean value of 'isSkill' attribute of the record.
    // If the attribute isn't present, it defaults to false.
    return record.isSkill || false;
  };

  const onExpandCellRendered = (text, record) => {
    // Find the corresponding expandable item from the listExpand array using the record's category.
    const findExpand = listExpand.find((_) => _.name === record.category);

    return (
      <div className="b-none e-w">
        {/* 
        Conditional rendering: 
        If 'isShowExpand' attribute of the record is true, then render the custom cell wrapper. 
        Otherwise, nothing will be rendered inside the div. 
      */}
        {record.isShowExpand && (
          <WrapperCellCustom
            // Apply rotation CSS class based on the 'isExpand' attribute of the found item.
            className={findExpand?.isExpand ? "rotate-0" : "rotate-180"}
            // Set the onClick handler to call 'onExpendClicked' with the found item.
            onClick={() => onExpendClicked(findExpand)}
          >
            <UpOutlined />
          </WrapperCellCustom>
        )}
      </div>
    );
  };

  const onCategoryCellRendered = (text, record, index) => {
    // Depending on the 'isSkill' property of the record, determine which component to render.
    // If 'isSkill' is true, use 'WrapperCellSkillCategoryCustom'.
    // If 'isSkill' is false, use 'WrapperCellSubCategoryCustom'.
    const ComponentToRender = record.isSkill
      ? WrapperCellSkillCategoryCustom
      : WrapperCellSubCategoryCustom;

    // Render the chosen component inside a div with the given class.
    // The title and placement properties are consistent for both components.
    return (
      <div className="b-none">
        <ComponentToRender
          title={record.meta.description}
          placement={constantData.bottomName}
        >
          {text}
        </ComponentToRender>
      </div>
    );
  };

  const onExpandCellTitleRendered = (meta) => {
    // Get the root expandable item to decide the direction of the 'UpOutlined' icon.
    const expand = getRootExpand();

    // Define the custom title for the tooltip:
    // It contains the name of the level responsibility and a list of levels beneath it.
    const customTitle = () => (
      <div>
        {/* Display the name of the level responsibility */}
        <WrapperLevelCustom>{meta.levelResponsibility.name}</WrapperLevelCustom>

        {/* Map through the levels and display each one */}
        {meta.levelResponsibility.levels.map((item, index) => (
          <WrapperLevelCustom key={index}>
            {`${constantData.levelName} ${item.level} : ${item.name}`}
          </WrapperLevelCustom>
        ))}
      </div>
    );

    // Render the custom title wrapped in a 'WrapperTitleCustom' component.
    // It contains an expandable icon and an informational icon within a tooltip.
    return (
      <WrapperTitleCustom className="h-bg-c e-w">
        {/* Expandable Icon: Its rotation depends on the 'expand.isExpand' value */}
        <UpOutlined
          className={expand.isExpand ? "rotate-0" : "rotate-180"}
          onClick={() => onExpendClicked(expand)}
        />

        {/* Information Icon wrapped in a tooltip */}
        <WrapperTooltipCustom
          title={customTitle}
          placement={constantData.bottomName}
        >
          <InfoCircleOutlined />
        </WrapperTooltipCustom>
      </WrapperTitleCustom>
    );
  };

  const onExpendClicked = (obj) => {
    if (!obj) return; // Early exit if obj is falsy

    const tempExpandStatus = !obj.isExpand;

    let updatedListExpand;

    if (obj.name === constantData.rootName) {
      // If the clicked object is the root, update expand status of all items
      updatedListExpand = listExpand.map((item) => ({
        ...item,
        isExpand: tempExpandStatus,
      }));
    } else {
      // Toggle the expand status of the clicked item
      obj.isExpand = tempExpandStatus;

      const rootItem = getRootExpand();
      // Check if any child item (excluding root) is expanded
      const hasExpandedChild = listExpand.some(
        (item) => item.name !== constantData.rootName && item.isExpand
      );

      // If the clicked item is expanded or no other child item is expanded, update the root expand status
      if (tempExpandStatus || !hasExpandedChild) {
        rootItem.isExpand = tempExpandStatus;
      }

      updatedListExpand = [...listExpand]; // Create a copy of the listExpand array
    }

    setListExpand(updatedListExpand); // Update state with the new list
  };

  // Renders cell content based on the level value
  const onLevelTextCellRendered = (text, record, index, lv) => {
    // Check if the record qualifies and find the respective level.
    const tempLevel =
      record.isSkill &&
      record.levels &&
      record.levels.find((level) => level.lv === `${lv}`);

    // If level found, render its value with a tooltip.
    if (tempLevel) {
      return (
        <div className="b-none c-lv c-w">
          <Tooltip
            title="Click to show more!"
            placement={constantData.bottomName}
          >
            {tempLevel.value}
          </Tooltip>
        </div>
      );
    }

    // Default empty cell for non-matching records.
    return <div className="b-none c-w"></div>;
  };

  /**
   * Determines the styling and interactivity of cells based on the given record and level.
   * This function primarily checks the level and adjusts the cell style based on the record's details.
   */
  const onLevelColorCellRendered = (record, index, lv) => {
    // When record isn't a skill, just return an empty style object.
    if (!record.isSkill) return {};

    // This is a default style for a cell. Used for skill-based records when no specific level styling is found.
    const defaultStyle = {
      backgroundColor: constantData.lvBackgroundColorDefault,
      borderStyle: "solid",
      borderWidth: "0.5px",
    };

    // If the record has no levels defined, just return the default styling.
    if (!record.levels) return { style: defaultStyle };

    // Attempt to find the corresponding level from the record's levels.
    const tempLevel = record.levels.find((level) => level.lv === `${lv}`);

    // If no such level is found, return with the default style.
    if (!tempLevel) return { style: defaultStyle };

    // Calculate a value which will be used for determining the color of the cell.
    const val = tempLevel.value * -1;

    return {
      // Add click functionality if a corresponding level is found for the cell.
      onClick: () => onCellExpandClicked(record, lv),

      // Set up the cell's styling based on the found level's value.
      style: {
        cursor: "pointer",
        // Determine the cell's background color based on the value.
        backgroundColor:
          val < constantData.colorLevel
            ? record.meta.colour
            : record.meta.cateColour,
        // Adjust text color depending on the value.
        color: val < constantData.colorLevel ? "black" : "white",
        borderStyle: "solid",
        borderWidth: "0.5px",
      },
    };
  };

  // A simple tooltip renderer for cell titles
  const onCellTitleRendered = (text, description) => (
    <Tooltip placement={constantData.bottomName} title={description}>
      {text}
    </Tooltip>
  );

  // Handle cell expansion based on the record and level
  const onCellExpandClicked = (record, lv) => {
    setExpendedRowKeysRef((prevState) => ({
      ...prevState, // Keep other properties of the state unchanged
      level: lv,
      name: record.parent,
      keys: [record.key],
    }));
  };

  const getRootExpand = () =>
    listExpand.find((_) => _.name === constantData.rootName);

  const getDataSource = () => {
    // Cache the root expand state to avoid calculating it repeatedly in the loop
    const isRootExpanded = getRootExpand().isExpand;

    // If the root is not expanded, return the sub-category data without skills
    if (!isRootExpanded) {
      return data.subCategories.map((item, index) => ({
        key: `${index + 1}`,
        category: item.name,
        isShowExpand: true,
        isSkill: false,
        meta: {
          description: item.description,
          skillColour: item.skillColour,
          colour: item.colour,
          cateColour: item.cateColour,
        },
      }));
    }

    // If the root is expanded, handle both subcategories and their skills
    return data.subCategories.flatMap((item, index) => {
      const { name, description, skillColour, colour, cateColour, skills } =
        item;

      // Construct base data for the sub-category
      const baseData = {
        key: `${index + 1}`,
        category: name,
        isShowExpand: true,
        isSkill: false,
        meta: { description, skillColour, colour, cateColour },
      };

      // Check if the current sub-category is in the list of expanded items
      const findExpand = listExpand.find(
        (expandItem) => expandItem.name === name
      );

      // If found but it's not expanded, only return the base data
      if (findExpand && !findExpand.isExpand) {
        return [baseData];
      }

      // Construct data for each skill under the current sub-category
      const skillsData = skills.map((itemSkill, indexSkill) => {
        const { name, description, people, levels } = itemSkill;

        return {
          key: `${index + 1}.${indexSkill + 1}`,
          category: name,
          parent: item.name,
          isShowExpand: false,
          isSkill: true,
          meta: { skillColour, colour, cateColour, description },
          people,
          // Map each level of the skill to its data structure
          levels: levels.map((level) => ({
            lv: level.lv,
            value: level.value,
            description: level.description,
          })),
        };
      });

      // Return a combined array of the base sub-category data and all its skills data
      return [baseData, ...skillsData];
    });
  };

  const getColumns = () => {
    // Initial columns for category and expand cells
    const initialColumns = [
      {
        title: () => onCellTitleRendered(data.name, data.description),
        dataIndex: constantData.categoryName,
        key: constantData.categoryName,
        render: onCategoryCellRendered,
      },
      {
        title: () => onExpandCellTitleRendered(data.meta),
        key: constantData.expandName,
        dataIndex: constantData.expandName,
        render: (text, record, index) =>
          onExpandCellRendered(text, record, index),
      },
    ];

    // Generate columns for each skill level
    const skillLevelColumns = Array.from(
      { length: constantData.skillLevelCount },
      (_, lv) => {
        const level = lv + 1;
        return {
          title: `${level}`,
          dataIndex: `${level}`,
          key: `${level}`,
          onCell: (record, index) =>
            onLevelColorCellRendered(record, index, level),
          render: (text, record, index) =>
            onLevelTextCellRendered(text, record, index, level),
        };
      }
    );

    // Combine and return all columns
    return [...initialColumns, ...skillLevelColumns];
  };

  // useMemo calculates and memoizes the table data based on its dependencies.
  // It will only re-compute the table data when either data, listExpand, or isContentLoaded changes.
  const tableData = useMemo(() => {
    if (isContentLoaded === false) return { dataSource: [], columns: [] };
    // Get the data source using the provided getDataSource function
    const dataSource = getDataSource();

    // Get the columns using the provided getColumns function
    const columns = getColumns();

    // Return the computed values in an object
    return { dataSource, columns };
  }, [data, listExpand, isContentLoaded]); // Memoization depends on these three values.

  // This useEffect is responsible for handling the side-effect when the content is not yet loaded.
  // It updates the state by marking the content as loaded and sets the initial expanded subcategories.
  useEffect(() => {
    // Check if the content has not been loaded yet
    if (!isContentLoaded) {
      // Mark content as loaded
      setIsContentLoaded(true);

      // Generate an array of expanded subcategories from the data
      const tempListExpand = data.subCategories.map((subCategory) => ({
        name: subCategory.name,
        isExpand: true,
      }));

      // Update the listExpand state by appending the new expanded subcategories.
      // We use a functional update to safely access and update the previous state.
      setListExpand((prevListExpand) => [...prevListExpand, ...tempListExpand]);
    }
  }, [data, isContentLoaded]); // The effect depends on the data and the isContentLoaded flag.

  // useEffect for handling the side-effect related to listExpand updates
  useEffect(() => {
    const find = listExpand.find(
      (item) => item.isExpand === false && item.name === expendedRowKeysRef.name
    );

    if (find) {
      // Reset expendedRowKeysCurrent to its default values
      setExpendedRowKeysCurrent((prevState) => ({
        ...prevState,
        level: null,
        name: null,
        keys: [],
      }));
    }
  }, [listExpand, expendedRowKeysRef.name]);

  // useEffect for handling the side-effect related to expendedRowKeysRef updates
  useEffect(() => {
    // Use a functional state update for expendedRowKeysCurrent since its new value depends on the previous state
    setExpendedRowKeysCurrent((prevState) => {
      const temp = { ...prevState };

      if (expendedRowKeysRef.keys.length < 1) {
        temp.level = null;
        temp.name = null;
        temp.keys = [];
      } else if (
        temp.keys.length > 0 &&
        temp.keys[0] === expendedRowKeysRef.keys[0] &&
        temp.level === expendedRowKeysRef.level
      ) {
        temp.level = null;
        temp.name = null;
        temp.keys = [];
      } else {
        temp.level = expendedRowKeysRef.level;
        temp.name = expendedRowKeysRef.name;
        temp.keys = [...expendedRowKeysRef.keys];
      }
      return temp;
    });
  }, [expendedRowKeysRef]);

  return (
    <WrapperTable customStyle={data?.styles}>
      <div className="table-m">
        <Table
          size={constantData.tableSize}
          columns={tableData.columns}
          pagination={false}
          expandable={{
            showExpandColumn: false,
            expandedRowRender: onExpandedRowRendered,
            rowExpandable: onRowExpandable,
            expandedRowKeys: expendedRowKeysCurrent.keys,
          }}
          dataSource={tableData.dataSource}
        />
      </div>
    </WrapperTable>
  );
};

export { SkillGapTable };
