import { filterArray } from "../../../utils/filterUtils";
import { generateRandomString } from "../../../utils/stringUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSquareCheck, faSquareMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";

const rowStyle = {
    fontSize: "12px",
};

class ManageJobSample extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobs: this.props.jobs,
            jobsSelected: [],
            isUpdating: false,
            showDialog: false,
            searchProperties: ["name"],
        };

        this.generateRandomString = generateRandomString.bind(this);
        this.filterArray = filterArray.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.jobs !== this.props.jobs) {
            this.setState({ jobs: this.props.jobs });
        }

        if (prevProps.showDialog !== this.props.showDialog) {
            this.setState({ showDialog: true });
        }

        if (prevProps.isUpdating !== this.props.isUpdating) {
            this.setState({ isUpdating: this.props.isUpdating });
        }

        if (prevProps.copyJobsSuccess !== this.props.copyJobsSuccess) {
            if (this.props.copyJobsSuccess == true) {
                this.setState({ showDialog: false, jobsSelected: [] });
            }
        }
    }

    // handleSave = () => {
    //     let job = this.state.job;
    //     this.props.handleSaveJobNoteUpdate(job);
    // };

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({ showDialog: !showDialog, jobsSelected: [] });
    };

    handleRowClick = (row) => {
        const jobs = this.state.jobs;
        const updatedData = jobs.find((it) => it.id == row.id);
        updatedData.toggleSelected = !updatedData.toggleSelected;

        this.setState({ jobs: [...jobs], jobsSelected: jobs.filter((it) => it.toggleSelected).map((row) => row.id) });
    };

    handleSelectAll = () => {
        const { jobs, searchKeyword, searchProperties } = this.state;
        let selectAll = false;

        const filtered = this.filterArray(jobs, searchKeyword, searchProperties);

        if (filtered.filter((it) => it.toggleSelected).length == 0) {
            selectAll = true;
        } else if (filtered.filter((it) => !it.toggleSelected).length == 0) {
            selectAll = false;
        } else {
            selectAll = true;
        }

        jobs.forEach((it) => {
            it.toggleSelected = false;
        });

        if (selectAll) {
            jobs.forEach((it) => {
                if (filtered.find((f) => f.id == it.id)) {
                    it.toggleSelected = true;
                }
            });
        }

        this.setState({ jobs: [...jobs], jobsSelected: jobs.filter((it) => it.toggleSelected).map((row) => row.id) });
    };

    handleClearSelection = () => {
        const jobs = this.state.jobs;

        jobs.forEach((it) => {
            it.toggleSelected = false;
        });

        this.setState({ jobs: [...jobs] });
    };

    handlePerformCopy = () => {
        const { jobs, jobsSelected } = this.state;
        const randomString = this.generateRandomString(5);

        const copiedJobs = jobs
            .filter((it) => jobsSelected.includes(it.id))
            .map((job) => {
                return {
                    jobToCopyId: job.id,
                    newName: `${job.name} SAMPLE`,
                    copyAttributes: true,
                };
            });

        this.props.handlePerformCopyJobs(copiedJobs);
        this.handleClearSelection();
    };

    render() {
        const { jobs, searchKeyword, searchProperties } = this.state;

        const handleRowClick = this.handleRowClick;
        const handleSelectAll = this.handleSelectAll;

        const filtered = this.filterArray(jobs, searchKeyword, searchProperties);

        const columns = [
            {
                id: "id",
                name: (
                    <div style={{ textAlign: "center" }}>
                        <FontAwesomeIcon
                            id={`headerChkSelectAll`}
                            icon={filtered.filter((it) => it.toggleSelected).length == 0 ? faSquare : filtered.filter((it) => !it.toggleSelected).length == 0 ? faSquareCheck : faSquareMinus}
                            style={{ cursor: "pointer", color: filtered.filter((it) => it.toggleSelected).length == 0 ? "#8f8f9d" : "#007bff" }}
                            size="lg"
                            onClick={() => handleSelectAll()}
                        />
                    </div>
                ),
                selector: (row) => row.id,
                sortable: false,
                width: "10%",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Input type="checkbox" id={`chkRow${row.id}`} checked={row.toggleSelected} onClick={(e) => handleRowClick(row)} />
                        </div>
                    );
                },
            },
            {
                id: "name",
                name: "Name",
                selector: (row) => row.name,
                sortable: false,
                width: "90%",
            },
        ];

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={!this.state.isUpdating ? this.togleDialogModal : null} size="lg">
                    <ModalHeader toggle={!this.state.isUpdating ? this.togleDialogModal : null}>Sample Requirements</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label sm={12}>Select the follow sample requirements to add to your workspace. You will be able to edit the requirement once added.</Label>
                                    </FormGroup>
                                </Col>
                                <Col md={12} sm={12}>
                                    <div>
                                        <Row>
                                            <Col md="6" sm="8">
                                                <SkillsTxTableSearchBar
                                                    searchKeyword={this.state.searchKeyword}
                                                    onChange={(searchKeyword) => {
                                                        this.handleClearSelection();
                                                        this.setState({ searchKeyword, jobsSelected: [] });
                                                    }}
                                                ></SkillsTxTableSearchBar>
                                            </Col>
                                            <Col md="6" sm="4">
                                                <SkillsTxTableClearSearch
                                                    style={{
                                                        fontSize: "16px",
                                                        height: "40px",
                                                        marginBottom: "10px",
                                                    }}
                                                    color="primary"
                                                    disabled={this.props.jobsLoading || this.state.isUpdating}
                                                    onClear={() => {
                                                        this.handleClearSelection();
                                                        this.setState({ searchKeyword: "", jobsSelected: [] });
                                                    }}
                                                ></SkillsTxTableClearSearch>
                                            </Col>
                                        </Row>
                                        <SkillsTxTable
                                            columns={columns}
                                            data={jobs}
                                            pagination={true}
                                            searchKeyword={searchKeyword}
                                            searchProperties={searchProperties}
                                            selectableRowsHighlight={true}
                                            handleRowClick={this.handleRowClick}
                                            progressPending={this.props.jobsLoading}
                                            selectableRows={true}
                                        ></SkillsTxTable>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={this.state.isUpdating ? "secondary" : "primary"}
                            onClick={() => {
                                this.handlePerformCopy();
                            }}
                            disabled={this.state.jobsSelected.length == 0 || this.state.isUpdating}
                        >
                            Copy Requirements
                        </Button>
                        <Button color="secondary" onClick={this.togleDialogModal} disabled={this.state.isUpdating}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ManageJobSample;
