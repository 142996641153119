import { updateState } from "../../utility";
import * as actionTypes from "../actions/adminActionTypes";

const initialState = {
    error: null,
    loading: false,
    isUpdating: false,
    pages: [],
    contents: [],
    contentTranslations: [],
    printData: null,
    loadingPrint: false,
    isImporting: false,
};

const getContentPagesStart = (state) => {
    return updateState(state, { error: null, loading: true });
};

const getContentPagesSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        loading: false,
        pages: action.pages,
    });
};

const getContentPagesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getContentsStart = (state) => {
    return updateState(state, { error: null, loading: true });
};

const getContentsSuccess = (state, action) => {
    const filterContents = [
        "SfiaProfileStep1Description",
        "SfiaProfileStep1Hover",
        "SfiaProfileStep2Description",
        "SfiaProfileStep2Hover",
        "SfiaProfileStep3Description",
        "SfiaProfileStep3Hover",
        "SfiaPlanStep1Description",
        "SfiaPlanStep1Hover",
        "SfiaPlanStep2Description",
        "SfiaPlanStep2Hover",
        "SfiaPlanStep3Description",
        "SfiaPlanStep3Hover",
    ];
    const contents = action.contents.filter((it) => filterContents.includes(it.name));
    return updateState(state, {
        error: null,
        loading: false,
        contents: [...contents],
    });
};

const getContentsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getContentTranslationsStart = (state) => {
    return updateState(state, { error: null, loading: true });
};

const getContentTranslationsSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        loading: false,
        translations: action.translations,
    });
};

const getContentTranslationsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const createContentTranslationStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const createContentTranslationSuccess = (state, action) => {
    const contents = state.contents;
    contents.push(action.addedContent);

    return updateState(state, {
        error: null,
        isUpdating: false,
        contents: [...contents],
    });
};

const createContentTranslationFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateContentTranslationStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateContentTranslationSuccess = (state, action) => {
    const updatedContent = action.updatedContent;
    const contents = state.contents;

    const content = contents.find((it) => it.id == updatedContent.id);
    if (content) {
        content.contentId = updatedContent.contentId;
        content.contentTranslationId = updatedContent.contentTranslationId;
        content.page = updatedContent.page;
        content.name = updatedContent.name;
        content.contentText = updatedContent.contentText;
        content.contentTextTranslation = updatedContent.contentTextTranslation;
        content.languageId = updatedContent.languageId;
        content.language = updatedContent.language;
    }

    return updateState(state, {
        error: null,
        isUpdating: false,
        contents: [...contents],
    });
};

const updateContentTranslationFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const exportContentTranslationsExcelStart = (state) => {
    return updateState(state, { error: null, loadingPrint: true });
};

const exportContentTranslationsExcelSuccess = (state, action) => {
    return updateState(state, {
        printData: action.printData,
        error: null,
        loadingPrint: false,
    });
};

const exportContentTranslationsExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingPrint: false,
    });
};

const importContentTranslationsExcelStart = (state) => {
    return updateState(state, { error: null, isImporting: true });
};

const importContentTranslationsExcelSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        isImporting: false,
    });
};

const importContentTranslationsExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isImporting: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CONTENT_PAGES_START:
            return getContentPagesStart(state);
        case actionTypes.GET_CONTENT_PAGES_SUCCESS:
            return getContentPagesSuccess(state, action);
        case actionTypes.GET_CONTENT_PAGES_FAIL:
            return getContentPagesFail(state, action);

        case actionTypes.GET_CONTENTS_START:
            return getContentsStart(state);
        case actionTypes.GET_CONTENTS_SUCCESS:
            return getContentsSuccess(state, action);
        case actionTypes.GET_CONTENTS_FAIL:
            return getContentsFail(state, action);

        case actionTypes.GET_CONTENT_TRANSLATIONS_START:
            return getContentTranslationsStart(state);
        case actionTypes.GET_CONTENT_TRANSLATIONS_SUCCESS:
            return getContentTranslationsSuccess(state, action);
        case actionTypes.GET_CONTENT_TRANSLATIONS_FAIL:
            return getContentTranslationsFail(state, action);

        case actionTypes.EXPORT_CONTENT_TRANSLATIONS_START:
            return exportContentTranslationsExcelStart(state);
        case actionTypes.EXPORT_CONTENT_TRANSLATIONS_SUCCESS:
            return exportContentTranslationsExcelSuccess(state, action);
        case actionTypes.EXPORT_CONTENT_TRANSLATIONS_FAIL:
            return exportContentTranslationsExcelFail(state, action);

        case actionTypes.IMPORT_CONTENT_TRANSLATIONS_START:
            return importContentTranslationsExcelStart(state);
        case actionTypes.IMPORT_CONTENT_TRANSLATIONS_SUCCESS:
            return importContentTranslationsExcelSuccess(state, action);
        case actionTypes.IMPORT_CONTENT_TRANSLATIONS_FAIL:
            return importContentTranslationsExcelFail(state, action);

        case actionTypes.CREATE_CONTENT_TRANSLATION_START:
            return createContentTranslationStart(state);
        case actionTypes.CREATE_CONTENT_TRANSLATION_SUCCESS:
            return createContentTranslationSuccess(state, action);
        case actionTypes.CREATE_CONTENT_TRANSLATION_FAIL:
            return createContentTranslationFail(state, action);

        case actionTypes.UPDATE_CONTENT_TRANSLATION_START:
            return updateContentTranslationStart(state);
        case actionTypes.UPDATE_CONTENT_TRANSLATION_SUCCESS:
            return updateContentTranslationSuccess(state, action);
        case actionTypes.UPDATE_CONTENT_TRANSLATION_FAIL:
            return updateContentTranslationFail(state, action);

        default:
            return state;
    }
};

export default reducer;
