import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavLink } from 'reactstrap';
import React, { Component, Fragment } from 'react';

import ContentTranslator from '../translator/ContentTranslator';
import Flag from 'react-flagkit';
import { Link } from 'react-router-dom';

//import sampleProfile from '../../assets/img/lgo/sample-profile.png';

const linkRightStyle = {
    padding: 0,
    marginLeft: '5px',
    marginRight: '5px',
    maxWidth: '150px',
    fontSize: 13,
}

export class NavRightLogin extends Component {
    static displayName = NavRightLogin.name;

    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false,
            countryFlags: [
                {
                    "name": "(Arabic) العربية",
                    "code": "SA"
                },
                {
                    "name": "Deutsch (German)",
                    "code": "DE"
                },
                {
                    "name": "Español (Spanish)",
                    "code": "ES"
                },
                {
                    "name": "日本語 (Japanese)",
                    "code": "JP"
                },
                {
                    "name": "中国 (Chinese)",
                    "code": "CN"
                },
                {
                    "name": "Français (French)",
                    "code": "FR"
                },
                {
                    "name": "Italiano (Italian)",
                    "code": "IT"
                },
                {
                    "name": "Polskie (Polish)",
                    "code": "PL"
                },
                {
                    "name": "Русский (Russian)",
                    "code": "RU"
                },
                {
                    "name": "bahasa Indonesia (Indonesian)",
                    "code": "ID"
                },
                {
                    "name": "ไทย (Thai)",
                    "code": "TH"
                },
                {
                    "name": "US (English)",
                    "code": "US"
                },
                {
                    "name": "English",
                    "code": "GB"
                }
            ]
        };
    }

    toggle = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));

    }

    selectLanguage = (language) => {
        this.props.setCurrentLanguage(language);
    }

    render() {
        const countryFlags = this.state.countryFlags;

        let email = localStorage.getItem("email");
        let languages = this.props.languages;

        languages.map(language => {
            if (countryFlags.find(it => it.name == language.name)) {
                const countryFlag = countryFlags.find(it => it.name == language.name);
                language.code = countryFlag.code;
            }
        });

        let selectedLanguage = this.props.selectedLanguage;
        if (selectedLanguage && countryFlags.find(it => it.name == selectedLanguage.name)) {
            const countryFlag = countryFlags.find(it => it.name == selectedLanguage.name);
            selectedLanguage.code = countryFlag.code;
        }

        return (
            <Fragment>
                <div className="row align-items-top">
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle caret style={{ fontSize: "12px" }}>
                            {selectedLanguage && <Fragment><Flag country={selectedLanguage.code} /> {selectedLanguage.name}</Fragment>}
                        </DropdownToggle>
                        <DropdownMenu>
                            {languages && languages.map(language => {
                                return <DropdownItem key={language.id} style={{ fontSize: "12px" }} onClick={() => this.selectLanguage(language)}><Flag key={`flag${language.id}`} country={language.code} /> {language.name}</DropdownItem>
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div className="row align-items-top">
                    <NavLink tag={Link} className="color-dark text-wrap font-weight-bold d-inline-block" style={linkRightStyle} to="/mysetting">
                        <ContentTranslator page="Menu" name="MenuAccountSetting" contentText="Account & Settings" />
                    </NavLink>
                    {/* <a href="http://www.gravatar.com" className="pt-1">
                        <Gravatar email={email} default="mp" width="36" height="36" />
                    </a> */}
                </div>
            </Fragment>
        );
    }
}