import ErrorHandler from "./../errorResponseHandler";
import Instance from "./../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminMentorPreferenceAPI {
    static getMentorPreferences() {
        return axios
            .get(`/mentorPreferences/list`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createMentorPreference(mentorPreference) {
        return axios
            .post(`/mentorPreferences`, mentorPreference)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateMentorPreference(id, mentorPreference) {
        return axios
            .put(`/mentorPreferences/${id}`, mentorPreference)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static deleteMentorPreference(id) {
        return axios
            .delete(`/mentorPreferences/${id}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default AdminMentorPreferenceAPI;
