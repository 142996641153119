import PersonService from "../../../../../common/api/service/PersonService";
import MenteeRequestFilters from "../../filters/menteerequest";
import MenteeTable from "../../table/mentee";
import AcceptDenyModal from "../acceptdeny";
import ConfirmModal from "../confirm";
import { CaretRightOutlined } from "@ant-design/icons";
import { Modal, Spin, notification, Collapse, Alert, Col, Row } from "antd";
import { groupBy } from "lodash";
import React, { useEffect, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { useImmer } from "use-immer";

const { Panel } = Collapse;

const ContainerCenter = styled.div`
  text-align: -webkit-center;
`;

const MenteeRequestModal = ({ showDialog, contentTranslations, contentTranslator, parameterizedString}) => {
  const [selectedStatus, setSelectedStatus] = useState([]);

  const [state, setState] = useImmer({
    showDialog: false,
    dataValue: [],
    rawData: [],
    isShowConfirm: false,
    isShowComment: false,
    isLoading: false,
    isEdit: false,
    commentData: undefined,
    contentTranslations: contentTranslations
  });

  const setDefaultData = () => {
    setState((draft) => {
      draft.showDialog = true;
      draft.dataValue = [];
      draft.isShowComment = false;
      draft.isEdit = false;
      draft.isLoading = true;
      draft.rawData = [];
      draft.commentData = undefined;
    });
    setSelectedStatus([]);
  };

  const toFixedIfNecessary = (value, dp) => {
    return +parseFloat(value).toFixed(dp);
  };

  const processDataAsync = async (isSetStatus = false) => {
    try {
      const dataMenteeRequests = await PersonService.getMenteeRequests();

      dataMenteeRequests.map((item) => {
        item.matchedSkillPercent = item.matchedSkillPercent ? toFixedIfNecessary(item.matchedSkillPercent * 100, 2) : item.matchedSkillPercent
      })

      setState((draft) => {
        draft.rawData =
          dataMenteeRequests?.map((item) => {
            return {
              ...item,
              name: `${item.firstName} ${item.lastName}`,
            };
          }) ?? [];
      });

      //dataMenteeRequests.length
      if (isSetStatus && dataMenteeRequests.length > 0) {
        setSelectedStatus(["Requested"]);
      }
    } catch (error) {
      // Log the error if fetching fails
      console.error("Failed to fetch action plan data:", error);
      // Optionally, you can set an error state to display an error message to users.
    }
  };

  const onToggleDialogModal = () => {
    const showDialog = state.showDialog;
    setState((draft) => {
      draft.showDialog = !showDialog;
      draft.isLoading = false;
    });
  };

  const onCommentOkClicked = useCallback(async (e, comment) => {
    setState((draft) => {
      draft.isShowComment = false;
      draft.isLoading = true;
    });
    try {
      const dataUpdate = {
        personId: state.commentData.data.personId,
        accept: state.commentData.isAccept,
        comment: comment,
      };
      await PersonService.updateMenteeRequests(dataUpdate);
      await processDataAsync();
      notification[state.commentData.isAccept ? "success" : "error"]({
        message: state.commentData.isAccept 
          ? parameterizedString(contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestAcceptedNotification", "{s1}'s request was accepted."), state.commentData.data.name)
          : parameterizedString(contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestDeniedNotification", "{s1}'s request was denied."), state.commentData.data.name),
        description: state.commentData.isAccept ? contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestNotificationDescription", "Please log out and log in again to load new relationship data.") :  null,
      });
    } catch (error) {
      // Optionally, you can set an error state to display an error message to users.
      notification["error"]({
        message: "error",
        description: error?.errData?.Message ?? error?.errStatus,
      });
    } finally {
      setState((draft) => {
        draft.commentData = undefined;
        draft.isLoading = false;
      });
    }
  });

  const onCommentCancelClicked = useCallback((e) => {
    setState((draft) => {
      draft.isShowComment = false;
      draft.isShowConfirm = false;
      draft.commentData = undefined;
    });
  });

  const openShowConfirm = (record, isAccept) => {
    // Open the modal and set its data
    setState((draft) => {
      draft.isShowComment = false;
      draft.isShowConfirm = true;
      draft.commentData = { data: record, isAccept: isAccept };
    });
  };

  // Handler for when Accept or Deny button is clicked
  const onConfirmOkClicked = useCallback((record) => {
    // Open the modal and set its data
    setState((draft) => {
      draft.isShowComment = true;
      draft.isShowConfirm = false;
    });
  }); // No dependencies since it doesn't rely on external state or props

  // Handler for when Accept or Deny button is clicked
  const onConfirmCancelClicked = useCallback(
    (record) => {
      // Open the modal and set its data
      setState((draft) => {
        draft.isShowComment = false;
        draft.isShowConfirm = false;
      });
    }
    //openShowConfirm(record, true)
  ); // No dependencies since it doesn't rely on external state or props

  // Handler for when Accept or Deny button is clicked
  const onAcceptClicked = useCallback((record) =>
    openShowConfirm(record, true)
  ); // No dependencies since it doesn't rely on external state or props

  // Handler for when Accept or Deny button is clicked
  const onDenyClicked = useCallback((record) => openShowConfirm(record, false)); // No dependencies since it doesn't rely on external state or props

  // Filter by Status
  const optionsStatus = useMemo(() => {
    if (state.rawData?.length === 0) return [];

    const dataGroup = groupBy(state.rawData, "mentorStatus");

    return Object.keys(dataGroup)
      .map((item) => ({
        label: item,
        value: item,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [state.rawData]);

  const tableData = useMemo(() => {
    let resultData = [];
    if (state.rawData?.length === 0) return resultData;

    return state.rawData.filter((item) => {
      // If selectedStatus is populated and the condition is not met, filter out
      if (
        selectedStatus.length > 0 &&
        !selectedStatus.includes(item.mentorStatus)
      ) {
        return false;
      }
      // If all conditions are met or not applicable, include the item
      return true;
    });
  }, [state.rawData, selectedStatus]);

  useEffect(() => {
    if (showDialog) {
      setDefaultData();

      // This variable helps us ensure that we don't call `setIsContentLoaded` if the component has unmounted before our asynchronous operation completes.
      let isMounted = true;

      // Fetch and process all data
      processDataAsync(true).then(() => {
        if (isMounted) {
          // Only set the loading state to false if the component is still mounted
          setState((draft) => {
            draft.isLoading = false;
          });
        }
      });

      // The cleanup function for the effect
      return () => {
        // If the component unmounts, we set isMounted to false to prevent any state updates after unmounting.
        isMounted = false;
      };
    }
  }, [showDialog]);

  if (state.showDialog === false) return null;

  // ... rest of the logic and JSX
  return (
    <Modal
      title={
        state.isShowComment || state.isShowConfirm ? null : contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestDialogTitle", "Mentee Requests")
      }
      closable={
        !state.isLoading && !state.isShowComment && !state.isShowConfirm
      }
      open={state.showDialog}
      onCancel={
        !state.isUpdating
          ? () => {
              setDefaultData();
              onToggleDialogModal();
            }
          : null
      }
      width={state.isShowComment || state.isShowConfirm ? "fit-content" : "60%"}
      style={{ borderRadius: "20px" }}
      footer={null}
    >
      <Spin
        spinning={state.isLoading}
        size="large"
        tip="Waiting..."
        delay={200}
      >
        <ContainerCenter>
          <AcceptDenyModal
            open={state.isShowComment}
            onOk={onCommentOkClicked}
            onCancel={onCommentCancelClicked}
            data={state.commentData}
            contentTranslations={contentTranslations}
            contentTranslator={contentTranslator}
          ></AcceptDenyModal>
          <ConfirmModal
            open={state.isShowConfirm}
            onOk={onConfirmOkClicked}
            onCancel={onConfirmCancelClicked}
            okText={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestTableAcceptDenyButtonOk", "Ok")}
            cancelText={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestTableAcceptDenyButtonCancel", "Cancel")}
            titleNode={
              <div
                style={{ fontSize: "18px", marginBottom: "15px" }}
                className="font-weight-bold"
                dangerouslySetInnerHTML={{
                  __html: state.commentData?.isAccept ? contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestTableAcceptPopupMessage", "You are about to <span style='color:green;'>Accept</span> this mentor request !") : contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestTableDenyPopupMessage", "You are about to <span style='color:red;'>Deny</span> this mentor request !"),
                }}
              >                
              </div>
            }
            msg={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestTableAcceptDenyPopupNotes", "This will trigger an email to the requester with your details.")}
            questionText={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestTableAcceptDenyPopupProceedText", "Do you want to proceed ?")}
          ></ConfirmModal>
        </ContainerCenter>
        {state.isShowComment == false && state.isShowConfirm == false && (
          <div>
            {state.rawData.length > 0 && (
              <>
                <div className="ml-3 mb-4 mr-3">
                  <MenteeRequestFilters
                    objStatus={{
                      options: optionsStatus,
                      value: selectedStatus,
                      setValue: setSelectedStatus,
                    }}
                    contentTranslations={contentTranslations}
                    contentTranslator={contentTranslator}
                  />
                </div>
                <Row className="ml-3 mb-4 mr-3">
                  <Col>
                    <Alert
                      banner
                      showIcon
                      description={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestDialogNotes", "Once you've pressed accept, you must log out and log in again to load the new relationship data.")}
                      type="warning"
                    />
                  </Col>
                </Row>
              </>
            )}
            <MenteeTable
              data={tableData}
              onAccept={onAcceptClicked}
              onDeny={onDenyClicked}
              contentTranslations={contentTranslations}
              contentTranslator={contentTranslator}
            />
            {tableData.length > 0 && (
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                className="site-collapse-custom-collapse mt-4"
              >
                <Panel
                  header={contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestRemarkPanelTitle", "Remark")}
                  key="1"
                  className="site-collapse-custom-panel"
                >
                  <div className="ml-4 mr-4"
                  dangerouslySetInnerHTML={{
                    __html: contentTranslator(contentTranslations, "SfiaProfile", "SfiaProfileMenteeRequestRemarkPanelNotes", "<div>Match : Your match to their skill gaps</div><div>Skills : Skills you can help </div><div>Profiles : Their Preferences that match your profile</div><div>Status : Status of mentor. Denied, Requested, Accepted</div>"),
                  }}
                  >
                  </div>
                </Panel>
              </Collapse>
            )}
          </div>
        )}
      </Spin>
    </Modal>
  );
};
export default MenteeRequestModal;
