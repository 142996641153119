import * as authActions from "../../store/actions/authAction";
import * as companyActions from "../../store/actions/companyAction";
import ContentTranslator from "../translator/ContentTranslator";
import { NavRight } from "./NavRight";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, NavLink as RRNavLink } from "react-router-dom";
import { Collapse, Container, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler } from "reactstrap";
import { bindActionCreators } from "redux";

const navlinkStyle = {
    color: "#2B2B2B",
    fontSize: "18px",
    fontFamily: "IBMPlexSans-Bold",
    marginRight: "10px",
    marginLeft: "10px",
};

const navlinkActiveStyle = {
    color: "#4279E6",
    textDecoration: "none",
};

const headerLeftStyle = {
    position: "absolute",
    left: "50px",
    top: "45px",
};

const navbarStyle = {
    borderRadius: "20px",
};

const headerRightStyle = {
    fontFamily: "IBMPlexSans-Bold",
    position: "absolute",
    right: "50px",
    top: "50px",
    width: "200px",
};

const headerStyle = {
    fontFamily: "IBMPlexSans-Bold",
    marginBottom: "69px",
};

class NavMain extends Component {
    static displayName = NavMain.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            logoTimestamp: new Date(),
            brandingLogoImage: null,
        };
    }

    componentDidMount() {
        this.setState({ brandingLogoImage: this.props.brandingLogoImage });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.logoTimestamp !== this.props.logoTimestamp) {
            this.setState({ logoTimestamp: this.props.logoTimestamp });

            this.props.onCompany.getCompanyBrandingLogo().then(() => {
                this.setState({ brandingLogoImage: this.props.brandingLogoImage });
            });
        }
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    logout = () => {
        Promise.resolve(this.props.onAuth.myPeopleClearManagedRelationship()).then(() => {
            this.props.onAuth.authLogoutIntended().then(() => {
                this.props.history.push("/");
            });
        });
    };

    render() {
        const { containerConfig } = this.props;
        const apiHostname = process.env.REACT_APP_API_ENDPOINT;
        const baseURL = process.env.NODE_ENV === "development" ? `${apiHostname}` : `/api`;

        return (
            <Navbar style={headerStyle} className="navbar-expand-md navbar-toggleable-md ng-white" light>
                <Container>
                    <Link to="/">
                        {this.props.brandingLogoChecked && this.props.brandingLogoImage ? (
                            <img style={headerLeftStyle} src={"data:image/png;base64," + this.state.brandingLogoImage} alt="Comp Logo" width="120" className="compLogo" />
                        ) : (
                            <img
                                style={headerLeftStyle}
                                src={`${baseURL}/companies/brandinglogo?timestamp=${this.state.logoTimestamp}`}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = process.env.PUBLIC_URL + "/assets/img/lgo/skillstx-logo-top.png";
                                }}
                                alt="Comp Logo"
                                width="120"
                                className="compLogo"
                            />
                        )}
                    </Link>
                    {!this.props.boolLoginMembee && (
                        <React.Fragment>
                            <NavbarBrand></NavbarBrand>
                            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                            <Collapse className="d-md-inline-flex justify-content-center" isOpen={!this.state.collapsed} navbar>
                                <ul style={navbarStyle} className="navbar-nav flex-grow bg-white mt-5">
                                    {!this.props.isAuthenticated && this.props.containerConfig && this.props.containerConfig.allowSelfRegistration && (
                                        <React.Fragment>
                                            <NavItem>
                                                <NavLink tag={RRNavLink} activeStyle={navlinkActiveStyle} style={navlinkStyle} exact to="/">
                                                    <ContentTranslator page="Menu" name="MenuFreeSignUp" contentText="Free Sign-Up" />
                                                </NavLink>
                                            </NavItem>
                                        </React.Fragment>
                                    )}
                                    <NavItem>
                                        <NavLink tag={RRNavLink} activeStyle={navlinkActiveStyle} style={navlinkStyle} to={{ pathname: "https://skillstx.com/about-sfia/" }} target="_blank">
                                            <ContentTranslator page="Menu" name="MenuAboutSFIA" contentText="About SFIA" />
                                        </NavLink>
                                    </NavItem>
                                    {!this.props.loginWithTeams ? (
                                        <React.Fragment>
                                            {!this.props.isAuthenticated ? (
                                                <NavItem>
                                                    <NavLink tag={RRNavLink} activeStyle={navlinkActiveStyle} style={navlinkStyle} to="/login">
                                                        <ContentTranslator page="Menu" name="MenuLogin" contentText="Login" />
                                                    </NavLink>
                                                </NavItem>
                                            ) : (
                                                <NavItem>
                                                    <Link to="#" className="nav-link" style={navlinkStyle} onClick={this.logout}>
                                                        <ContentTranslator page="Menu" name="MenuLogout" contentText="Logout" />
                                                    </Link>
                                                </NavItem>
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        ""
                                    )}
                                </ul>
                            </Collapse>
                        </React.Fragment>
                    )}
                    <div id="rightMenu" style={headerRightStyle}>
                        <NavRight isAuthenticated={this.props.isAuthenticated} languages={this.props.languages} selectedLanguage={this.props.language} setCurrentLanguage={this.props.setCurrentLanguage} />
                    </div>
                </Container>
            </Navbar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null,
        hasPeoples: state.auth.hasPeoples,
        isEndorser: state.auth.isEndorser,
        hasRelationships: state.auth.hasRelationships,
        languages: state.language.languages,
        containerConfig: state.config.containerConfig,

        brandingLogoImage: state.company.brandingLogoImage,
        brandingLogoChecked: state.company.brandingLogoChecked,
        loginWithTeams: state.auth.loginWithTeams,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onCompany: bindActionCreators(companyActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavMain);
