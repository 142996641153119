import * as contentActions from "../../../store/admin/actions/adminContentActions";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";

class GroupValueTranslationBulkUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDialog: false,
            selectedFiles: [],
            isImporting: false,
            importMessage: null,
        };

        this.excelRef = React.createRef();
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
        }

        if (prevProps.selectedLanguage !== this.props.selectedLanguage && this.props.selectedLanguage) {
            this.setState({ selectedLanguage: this.props.selectedLanguage });
        }
    }

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.excelRef.current.value = null;
        this.setState({ showDialog: !showDialog, selectedFiles: [] });
    };

    handleSelectFiles = () => {
        this.excelRef.current.click();
    };

    handleSelectExcels = (event) => {
        const selectedFiles = this.state.selectedFiles;

        if (event.target.files && event.target.files.length > 0) {
            this.setState({ selectedFiles: [...selectedFiles, ...event.target.files] });
        }
    };

    handleChangeLanguage = (event) => {
        const { value } = event.target;
        this.setState({ selectedLanguage: value });
    };

    handlePerformUpload = () => {
        this.setState({ isImporting: true });
        this.props.onContent.importGroupValueTranslationsExcel(this.state.selectedLanguage, this.state.selectedFiles).then(() => {
            if (!this.props.isImporting) {
                if (!this.props.contentsError) {
                    this.props.generateAlert("success", "Group value translations imported");
                    this.excelRef.current.value = null;
                    this.togleDialogModal();
                } else {
                    this.props.generateAlert("danger", this.props.contentsError.errData.Message);
                }
            }
            this.setState({ isImporting: this.props.isImporting });
        });
    };

    render() {
        const { selectedFiles } = this.state;

        return (
            <React.Fragment>
                <input ref={this.excelRef} type="file" style={{ display: "none" }} accept=".xlsx, .xls" multiple={true} onChange={this.handleSelectExcels} />
                <Modal isOpen={this.state.showDialog} toggle={this.togleDialogModal}>
                    <ModalHeader toggle={this.togleDialogModal}>Import Group Value Translations</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label sm={3}>Language</Label>
                                        <Col sm={9}>
                                            <Input type="select" name="language" id="language" value={this.state.selectedLanguage ? this.state.selectedLanguage : ""} onChange={this.handleChangeLanguage} disabled={this.state.isImporting}>
                                                {this.props.languages.map((language, key) => (
                                                    <option key={key} value={language.id}>
                                                        {language.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col sm={12} style={{ textAlign: "center" }}>
                                            <Button
                                                color="success"
                                                onClick={this.handleSelectFiles}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                }}
                                                disabled={this.state.isImporting}
                                            >
                                                Select Files
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                    {selectedFiles.length > 0 && (
                                        <FormGroup row>
                                            <Col sm={12}>
                                                <Label>Files to upload</Label>
                                                <br />
                                                <ListGroup>
                                                    {selectedFiles.map((it, key) => {
                                                        return (
                                                            <React.Fragment key={key}>
                                                                <ListGroupItem key={key}>{it.name}</ListGroupItem>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </ListGroup>
                                            </Col>
                                        </FormGroup>
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.handlePerformUpload();
                            }}
                            disabled={selectedFiles.length == 0 || this.state.isImporting}
                        >
                            Process Upload
                        </Button>
                        <Button color="secondary" onClick={this.togleDialogModal} disabled={this.state.isImporting}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contentsError: state.adminContent.error,
        isImporting: state.adminContent.isImporting,
        languages: state.language.languages,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onContent: bindActionCreators(contentActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupValueTranslationBulkUpload);
