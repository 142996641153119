import React, { Component } from "react";
import { UncontrolledAlert } from "reactstrap";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
export class AlertDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: true,
            sticky: this.props.sticky,
        };
    }

    componentDidMount() {
        const sticky = this.state.sticky;
        if (!sticky) {
            setTimeout(() => {
                this.setState({ isActive: false });
            }, alertAutoHideInterval);
        }
    }

    render() {
        const isActive = this.state.isActive;

        const { message, color, nowrap, className } = this.props;

        if (isActive) {
            return (
                <UncontrolledAlert className={className ? className : "alert-fixed"} color={color ? color : "primary"}>
                    <span style={{ whiteSpace: nowrap && nowrap == true ? "nowrap" : "normal" }}>{message}</span>
                </UncontrolledAlert>
            );
        } else {
            return "";
        }
    }
}
