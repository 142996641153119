import ErrorHandler from "./../errorResponseHandler";
import Instance from "./../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminContentAPI {
    static getPages() {
        return axios
            .get(`/contents/pages`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getContents(page, languageId) {
        return axios
            .get(`/contents${page || languageId ? "?" : ""}${page ? `page=${page}` : ""}${languageId ? `${page ? "&" : ""}languageId=${languageId}` : ""}`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createContent(content) {
        return axios
            .post(`/contents`, content)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateContent(content) {
        return axios
            .put(`/contents`, content)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getContentTranslations(contentId) {
        return axios
            .get(`/contents/${contentId}/translations`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static exportContentTranslationsExcel(languageId) {
        return axios
            .get(`/contents/exportDefaultTranslations?languageId=${languageId}`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static importContentTranslationsExcel(languageId, translationsDataFiles) {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        const body = new FormData();
        body.append("ContentDisposition", "attachment");
        body.append("ContentType", "multipart/form-data");

        translationsDataFiles.forEach((file) => {
            body.append("translationsData", file);
        });

        return axios
            .post(`/contents/importTranslations?languageId=${languageId}`, body, config)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static exportGroupValueTranslationsExcel(languageId) {
        return axios
            .get(`/contents/exportGroupValueTranslations?languageId=${languageId}`, {
                responseType: "blob",
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                console.log("err", err);
                throw ErrorHandler(err);
            });
    }

    static importGroupValueTranslationsExcel(languageId, translationsDataFiles) {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        const body = new FormData();
        body.append("ContentDisposition", "attachment");
        body.append("ContentType", "multipart/form-data");

        translationsDataFiles.forEach((file) => {
            body.append("translationsData", file);
        });

        return axios
            .post(`/contents/importGroupValueTranslations?languageId=${languageId}`, body, config)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default AdminContentAPI;
