import { Row, Col, InputNumber, Slider, Typography } from "antd";
import React from "react";
import styled from "styled-components";

const { Title } = Typography;

const ContainerFilter = styled.div`
  margin: 10px;
  margin-bottom: 40px;

  .w-100 {
    width: 100% !important;
  }

  .w-75 {
    width: 75% !important;
  }
  .ant-input-number-input-wrap > input {
    text-align: center;
  }
`;

const WrapperCard = styled.div`
  display: inline-block;
  justify-content: space-between;

  padding: 35px;

  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);

  backdrop-filter: blur(15px);
`;

const WrapperHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 35px;
`;

// Constant Data
const constantData = {
  min: 0,
  max: 100,
  sliderDefaultValue: [0, 100],
};

// Reusable Slider Component
// This component handles rendering and functionality for the Slider and associated input fields
const RangeSlider = ({ title, obj, constantData }) => {
  return (
    <WrapperHeader className="text-center align-self-center">
      <Title level={5}>{title}</Title> {/* Display the title */}
      <div className="text-center align-self-center w-75">
        {/* Slider Component */}
        <Slider
          className="mt-4"
          range
          defaultValue={constantData.sliderDefaultValue}
          value={[obj?.value?.min, obj?.value?.max]}
          // Update value when slider changes
          onChange={(val) =>
            obj?.setValue((item) => {
              item.min = val[0];
              item.max = val[1];
            })
          }
        />
        {/* Input Fields for Min and Max */}
        <InputRange obj={obj} constantData={constantData} />
      </div>
    </WrapperHeader>
  );
};

// Reusable Input Component
// This component handles rendering and functionality for the Min and Max input fields
const InputRange = ({ obj, constantData }) => (
  <Row className="mt-4">
    <Col key="Min" span={11}>
      <InputNumber
        addonBefore="Min"
        addonAfter="%"
        className="w-100"
        min={constantData.min}
        max={constantData.max}
        // Get the value for the current label ('min' or 'max') from obj
        value={obj?.value?.["min"]}
        // Update value when input changes
        onChange={(val) =>
          obj?.setValue((item) => {
            item["min"] = val;
          })
        }
      />
    </Col>
    <Col className="text-center" style={{ alignSelf: "center" }} span={2}>
      <span>-</span>
    </Col>
    <Col key="Max" span={11}>
      <InputNumber
        addonBefore="Max"
        addonAfter="%"
        className="w-100"
        min={constantData.min}
        max={constantData.max}
        // Get the value for the current label ('min' or 'max') from obj
        value={obj?.value?.["max"]}
        // Update value when input changes
        onChange={(val) =>
          obj?.setValue((item) => {
            item["min"] = val;
          })
        }
      />
    </Col>
  </Row>
);

// Main Component
// This component contains the overall layout and utilizes the reusable components
const MatchRangeFilters = ({
  objSelfAssessed,
  objEndorsed,
  objPerson,
  objRequirementMatch,
}) => {
  return (
    <ContainerFilter>
      {/* Row for Self-Assessed and Endorsed sliders */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          {/* Self-Assessed Slider */}
          <RangeSlider
            title="Select Match Range For Self- Assessed"
            obj={objSelfAssessed}
            constantData={constantData}
          />
        </Col>
        <Col span={12}>
          {/* Endorsed Slider */}
          <RangeSlider
            title="Select Match Range For Endorsed"
            obj={objEndorsed}
            constantData={constantData}
          />
        </Col>
      </Row>
      {/* Row for Number Of Person and Number Of Requirement Matches */}
      <Row className="mt-4" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {/* Number Of Person */}
        <Col className="text-center align-self-center" span={12}>
          <WrapperCard className="w-75">
            <Title level={5}>Number Of Person</Title>
            <Title level={1}>{objPerson?.count ?? 0}</Title>
          </WrapperCard>
        </Col>
        {/* Number Of Requirement Matches */}
        <Col className="text-center align-self-center" span={12}>
          <WrapperCard className="w-75">
            <Title level={5}>Number Of Requirement Matches</Title>
            <Title level={1}>{objRequirementMatch?.count ?? 0}</Title>
          </WrapperCard>
        </Col>
      </Row>
    </ContainerFilter>
  );
};

export default MatchRangeFilters;
