import { ContentWrapperLg } from "../../common/ContentWrapperLg";
import React, { Component } from "react";
import ReactTextareaAutosize from "react-textarea-autosize";
import { Button, Col, Form, Row } from "reactstrap";

const buttonStyle = {
    marginLeft: "10px",
};

const labelCaptionStyle = {
    fontWeight: "bold",
};

const textareaStyle = {
    width: "100%",
    padding: "10px",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
};

const textareaStyleDisable = {
    width: "100%",
    padding: "10px",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    background: "#e9ecef",
};

const largeContainer = {
    width: "100%",
    maxWidth: "100%",
    fontSize: "14px",
};

const commonContainer = {
    fontSize: "14px",
};

class ManageJobNote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            job: this.props.job,
            isUpdating: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.job !== this.props.job) {
            this.setState({ job: this.props.job });
        }

        if (prevProps.isUpdating !== this.props.isUpdating) {
            this.setState({ isUpdating: this.props.isUpdating });
        }
    }

    handleChange = (event) => {
        const job = this.state.job;
        const { name, value } = event.target;
        job.comment = value;

        this.setState({ job });
    };

    handleSave = () => {
        let job = this.state.job;
        this.props.handleSaveJobNoteUpdate(job);
    };

    render() {
        const pathName = window.location.pathname;
        const isAdmin = pathName.includes("/admin");

        return (
            <React.Fragment>
                <ContentWrapperLg style={isAdmin ? largeContainer : commonContainer} className={isAdmin && "contentWrapperLgAdmin-Style"}>
                    <Row style={{ paddingBottom: "10px" }}>
                        <Col md={3}>
                            <p
                                className="mb-0"
                                style={{
                                    color: "#2B2B2B",
                                    fontSize: "16px",
                                    fontFamily: "IBMPlexSans-Bold",
                                    paddingBottom: "10px",
                                }}
                            >
                                Note
                            </p>
                        </Col>
                    </Row>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <ReactTextareaAutosize
                                    minRows={15}
                                    style={!this.state.isUpdating ? textareaStyle : textareaStyleDisable}
                                    aria-label="maximum height"
                                    value={this.state.job && this.state.job.comment ? this.state.job.comment : ""}
                                    onChange={this.handleChange}
                                    disabled={this.state.isUpdating}
                                    name="note"
                                    id="note"
                                    placeholder="Enter Notes"
                                />
                            </Col>
                        </Row>
                        {this.props.allowEdit && (
                            <Row style={{ paddingTop: "10px" }}>
                                <Col md={12} sm={12} style={{ textAlign: "right" }}>
                                    <Button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleSave} disabled={this.state.isUpdating}>
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </ContentWrapperLg>
            </React.Fragment>
        );
    }
}

export default ManageJobNote;
