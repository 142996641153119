import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

// import partnerLogo from '../assets/img/lgo/partner-logo.png';
// import footRightImg1 from '../assets/img/lgo/footer-right-img1.png';
// import footRightImg2 from '../assets/img/lgo/footer-right-img2.png';

const rightImg1Style = {
    position: "relative",
    right: "-87px",
    top: "-10px",
};

const rightImg2Style = {
    position: "relative",
    right: "-65px",
};

export class FooterContent extends Component {
    static displayName = FooterContent.name;

    constructor(props) {
        super(props);

        this.state = {
            logoTimestamp: new Date(),
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.logoTimestamp !== this.props.logoTimestamp) {
            this.setState({ logoTimestamp: this.props.logoTimestamp });
        }
    }

    render() {
        return (
            <div id="footer-content">
                <Container className="p-0">
                    <Row xs="1" sm="3">
                        <Col className="p-0">
                            <div id="footer-left" className="footerLeftStyle" style={{ textAlign: "center" }}>
                                <div>
                                    <img
                                        src={process.env.PUBLIC_URL + "/assets/img/lgo/skillstx-logo-bottom.png"}
                                        alt="Comp Logo Bottom"
                                        width="120"
                                    />
                                </div>
                                <div style={{ fontSize: 11, color: "#B2B2B2" }}>
                                    <p>Official partners:</p>
                                </div>
                                <div>
                                    <img src={process.env.PUBLIC_URL + "/assets/img/lgo/accredited-partner-logo.png"} alt="Partner Logo" height="34" />
                                </div>
                            </div>
                        </Col>
                        <Col className="p-0">
                            <div id="footer-center" className="footerCenterStyle">
                                <p style={{ marginBottom: 0 }}>
                                    {/* <a href="#" className="text-decoration-none">  Privacy  </a> •
                                    <a href="#" className="text-decoration-none">  Terms & Conditions  </a> •
                                    <a href="#" className="text-decoration-none">  Sitemap  </a><br /> */}
                                    © Tx Management Systems Pty Ltd
                                </p>
                            </div>
                        </Col>
                        <Col className="p-0">
                            <div id="footer-right" className="footerRightStyle">
                                <div id="footer-img">
                                    <img src={process.env.PUBLIC_URL + "/assets/img/lgo/footer-right-img1.png"} alt="Right Img1" style={rightImg1Style} width="20" height="20" />
                                    <img src={process.env.PUBLIC_URL + "/assets/img/lgo/footer-right-img2.png"} alt="Right Img2" style={rightImg2Style} width="21" height="21" />
                                </div>
                                <p style={{ fontSize: 10, lineHeight: 2, marginBottom: 0 }}>
                                    SkillsTx is a registered trademark of
                                    <br />
                                    TX Management Systems Pty Ltd 2016
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
