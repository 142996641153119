import { contentTranslator } from "../../utils/translatorUtils";
import "./../../custom-admin-menu.css";
import { faBarsProgress, faBriefcase, faEnvelopesBulk, faUserCheck, faUserGraduate, faUserPen, faUsers, faScrewdriverWrench, faEye, faCircleInfo, faBuildingColumns, faFilter, faTag, faFileCirclePlus, faCertificate, faChartPie, faStamp, faIdCard, faClone, faPersonCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Navigation } from "react-minimal-side-navigation/lib";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container } from "reactstrap";
import { bindActionCreators } from "redux";

class NavAdmin extends React.Component {
    static displayName = NavAdmin.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            isDdOpen: false,
            //setIsOpen: false,
            contentTranslations: [],
        };
        this.contentTranslator = contentTranslator.bind(this);
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contentTranslations !== this.props.contentTranslations || prevProps.language !== this.props.language) {
            this.setState({ contentTranslations: this.props.contentTranslations });
        }
    }

    render() {
        const containerConfig = this.props.containerConfig;
        const pageAssignments = this.props.pageAssignments;
        const manageUserAccess = pageAssignments ? pageAssignments.find((it) => it.pageName == "Manage Users" && it.operations.find((it) => it.operation == "Open" && it.allow == true)) : null;

        let navigationItems = [];
        let manageUserNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Users" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageUserNav = {
                title: "Manage Users",
                itemId: "/admin/users",
                elemBefore: () => <FontAwesomeIcon icon={faUserPen} />,
            };

            navigationItems.push(manageUserNav);
        }

        let assignParticipantsNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Assign Participants" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            assignParticipantsNav = {
                title: "Assign Participants",
                itemId: "/admin/assignparticipant",
                elemBefore: () => <FontAwesomeIcon icon={faUserCheck} />,
            };

            navigationItems.push(assignParticipantsNav);
        }

        let trackParticipantProgressNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Track Participant Progress" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            trackParticipantProgressNav = {
                title: "Track Participant Progress",
                itemId: "/admin/surveyprogress",
                elemBefore: () => <FontAwesomeIcon icon={faBarsProgress} />,
            };

            navigationItems.push(trackParticipantProgressNav);
        }

        let analyticsNav = null;
        if (
            containerConfig &&
            containerConfig.powerBiReportId &&
            containerConfig.powerBiWorkspaceId &&
            containerConfig.powerBiEmbeddedToken &&
            containerConfig.powerBiEmbeddedUrl &&
            pageAssignments &&
            pageAssignments.find((it) => it.pageName == "Analytics" && it.operations.find((it) => it.operation == "Open" && it.allow == true))
        ) {
            analyticsNav = {
                title: "Analytics",
                itemId: "/admin/analytics",
                openNewTab: false,
                elemBefore: () => <FontAwesomeIcon icon={faChartPie} />,
            };

            navigationItems.push(analyticsNav);
        }

        let manageSelfAssessedApproveNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Approve SelfAssessed" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageSelfAssessedApproveNav = {
                title: "Approve SelfAssessed",
                itemId: "/admin/selfassessed",
                elemBefore: () => <FontAwesomeIcon icon={faPersonCircleCheck} />,
            };

            navigationItems.push(manageSelfAssessedApproveNav);
        }

        let manageRelationshipsNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Relationships" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageRelationshipsNav = {
                title: "Manage Relationships",
                itemId: "/admin/userrelationships",
                elemBefore: () => <FontAwesomeIcon icon={faUsers} />,
            };

            navigationItems.push(manageRelationshipsNav);
        }

        let manageRequirementsNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Requirements" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageRequirementsNav = {
                title: "Manage Requirements",
                itemId: "/admin/requirements",
                elemBefore: () => <FontAwesomeIcon icon={faBriefcase} />,
            };

            navigationItems.push(manageRequirementsNav);
        }

        let manageRequirementFamiliesNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Requirement Families" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageRequirementFamiliesNav = {
                title: "Manage Requirement Families",
                itemId: "/admin/requirementFamilies",
                elemBefore: () => <FontAwesomeIcon icon={faBriefcase} />,
            };

            navigationItems.push(manageRequirementFamiliesNav);
        }

        let assignRequirementsNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Assign Requirements" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            assignRequirementsNav = {
                title: "Assign Requirements",
                itemId: "/admin/requirementassignments",
                elemBefore: () => <FontAwesomeIcon icon={faUserCheck} />,
            };

            navigationItems.push(assignRequirementsNav);
        }

        let manageEndorsementsNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Endorsements and Reports" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageEndorsementsNav = {
                title: "Manage Endorsements and Reports",
                itemId: "/admin/endorsements",
                elemBefore: () => <FontAwesomeIcon icon={faCertificate} />,
            };

            navigationItems.push(manageEndorsementsNav);
        }

        let manageSelfAssessedProfilesNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Create Endorsed from Approved" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageSelfAssessedProfilesNav = {
                title: "Create Endorsed from Approved",
                itemId: "/admin/selfprofiles",
                elemBefore: () => <FontAwesomeIcon icon={faClone} />,
            };

            navigationItems.push(manageSelfAssessedProfilesNav);
        }

        let manageSFIACredentialsNav = null;
        if (containerConfig && containerConfig.showSfiaCertificationAdmin && pageAssignments && pageAssignments.find((it) => it.pageName == "Manage SFIA Credentials and Reports" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageSFIACredentialsNav = {
                title: "Manage SFIA Credentials and Reports",
                itemId: "/admin/sfiacredentials",
                elemBefore: () => <FontAwesomeIcon icon={faStamp} />,
            };

            navigationItems.push(manageSFIACredentialsNav);
        }

        let manageSkillAttributesNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Skills and Attributes" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageSkillAttributesNav = {
                title: "Manage Skills and Attributes",
                itemId: "/admin/skills",
                elemBefore: () => <FontAwesomeIcon icon={faCircleInfo} />,
            };

            navigationItems.push(manageSkillAttributesNav);
        }

        let manageLorNav = null;
        if (this.props.containerConfig && this.props.containerConfig.eeaFrameworkContainer == false &&  pageAssignments && pageAssignments.find((it) => it.pageName == "Manage LoR" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageLorNav = {
                title: "Manage LoR",
                itemId: "/admin/lors",
                elemBefore: () => <FontAwesomeIcon icon={faFileCirclePlus} />,
            };

            navigationItems.push(manageLorNav);
        }

        let manageTrainingProvidersNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Select Training Providers" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageTrainingProvidersNav = {
                title: "Select Training Providers",
                itemId: "/admin/trainingprofiders",
                elemBefore: () => <FontAwesomeIcon icon={faBuildingColumns} />,
            };

            navigationItems.push(manageTrainingProvidersNav);
        }

        let manageActionsNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Action List" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageActionsNav = {
                title: "Manage Action List",
                itemId: "/admin/trainings",
                elemBefore: () => <FontAwesomeIcon icon={faUserGraduate} />,
            };

            navigationItems.push(manageActionsNav);
        }

        let assignActionRequirementsNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Assign Action Requirements" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            assignActionRequirementsNav = {
                title: "Assign Action Requirements",
                itemId: "/admin/trainingjobs",
                elemBefore: () => <FontAwesomeIcon icon={faFilter} />,
            };

            navigationItems.push(assignActionRequirementsNav);
        }

        let manageFeaturesSettingsNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Features Settings" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageFeaturesSettingsNav = {
                title: "Manage Features/Settings",
                itemId: "/admin/settings",
                elemBefore: () => <FontAwesomeIcon icon={faScrewdriverWrench} />,
            };

            navigationItems.push(manageFeaturesSettingsNav);
        }

        let manageEmailTemplatesNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Email Templates" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageEmailTemplatesNav = {
                title: "Manage Email Templates",
                itemId: "/admin/emails",
                elemBefore: () => <FontAwesomeIcon icon={faEnvelopesBulk} />,
            };

            navigationItems.push(manageEmailTemplatesNav);
        }

        let uploadCompanyLogoNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Upload Company Logo" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            uploadCompanyLogoNav = {
                title: "Upload Company Logo",
                itemId: "/admin/branding",
                elemBefore: () => <FontAwesomeIcon icon={faEye} />,
            };

            navigationItems.push(uploadCompanyLogoNav);
        }

        let manageContentsNav = null;
        if (pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Page Labels" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageContentsNav = {
                title: "Manage Page Labels",
                itemId: "/admin/contents",
                elemBefore: () => <FontAwesomeIcon icon={faTag} />,
            };

            navigationItems.push(manageContentsNav);
        }

        let manageExtraFrameworksNav = null;
        if (this.props.containerConfig && this.props.containerConfig.showExtraFrameworkAdmin == true && pageAssignments && pageAssignments.find((it) => it.pageName == "Manage Extra Frameworks" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageExtraFrameworksNav = {
                title: "Manage Extra Frameworks",
                itemId: "/admin/extraframeworks",
                elemBefore: () => <FontAwesomeIcon icon={faFileCirclePlus} />,
            };

            navigationItems.push(manageExtraFrameworksNav);
        }

        let manageLorCategoryNav = null;
        if (this.props.containerConfig && this.props.containerConfig.eeaFrameworkContainer == true &&  pageAssignments && pageAssignments.find((it) => it.pageName == "Manage LoR Category" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageLorCategoryNav = {
                title: "Manage LoR Category",
                itemId: "/admin/lors",
                elemBefore: () => <FontAwesomeIcon icon={faFileCirclePlus} />,
            };

            navigationItems.push(manageLorCategoryNav);
        }

        let manageSSOSettingNav = null;
        if (this.props.containerConfig && this.props.containerConfig.allowManageSSOSetting == true && pageAssignments && pageAssignments.find((it) => it.pageName == "Manage SSO Setting" && it.operations.find((it) => it.operation == "Open" && it.allow == true))) {
            manageSSOSettingNav = {
                title: "Manage SSO Setting",
                itemId: "/admin/ssosetting",
                elemBefore: () => <FontAwesomeIcon icon={faIdCard} />,
            };

            navigationItems.push(manageSSOSettingNav);
        }

        return (
            <Container className="p-0" style={{ backgroundColor: "#FFFFFF" }}>
                <Navigation
                    activeItemId={window.location.pathname}
                    onSelect={({ itemId }) => {
                        const navigationItem = navigationItems.find((it) => it.itemId == itemId);

                        if (itemId) {
                            if (navigationItem && navigationItem.openNewTab) {
                                const win = window.open(itemId, "_blank");
                                win.focus();
                            } else {
                                this.props.history.push(itemId);
                            }
                        }
                    }}
                    items={navigationItems}
                />
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        hasPeoples: state.auth.hasPeoples,
        isEndorser: state.auth.isEndorser,
        managedPeople: state.auth.managedPeople,
        hasRelationships: state.auth.hasRelationships,
        language: state.language.language,
        contentTranslations: state.content.contentTranslations,

        authMode: state.auth.authMode,
        pageAssignments: state.adminPerson.pageAssignments,
        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavAdmin));
