import ErrorHandler from "./../errorResponseHandler";
import Instance from "./../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminProfileAPI {
    static requestCopySelfProfilesAsEndorsed(copiedProfiles) {
        return axios
            .post(`/profiles/requestCopySelfProfilesAsEndorsed`, {
                copiedProfiles: [...copiedProfiles],
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static processCopySelfProfilesAsEndorsed(copyProfileRequestIds) {
        return axios
            .post(`/profiles/processCopySelfProfilesAsEndorsed`, {
                copyProfileRequestIds,
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default AdminProfileAPI;
