import * as evidenceActions from "../../store/actions/evidenceAction";
import changeInput from "../../utils/changeInput";
import { contentTranslator } from "../../utils/translatorUtils";
import ContentTranslator from "../translator/ContentTranslator";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import Combobox from "react-widgets/Combobox";
import { Button, Col, Container, CustomInput, Form, FormGroup, Input, Label, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const wrapperStyle = {
    borderRadius: "10px",
    width: "700px",
    background: "#F5F5F5",
    paddingLeft: "63px",
    paddingRight: "63px",
    paddingTop: "35px",
    paddingBottom: "35px",
};

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const forbiddenExtensions = ["asp", "aspx", "axd", "asx", "asmx", "ashx", "shtml", "mhtml", "xhtml", "mht", "scr", "lnk", "msi", "msp", "ps1", "reg", "vb", "vbs", "hta", "ws", "dll", "exe"];

class AddNewEvidenceForm extends React.Component {
    static displayName = AddNewEvidenceForm.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            submitInProgress: false,
            selectedSkills: [],
            selectedLors: [],
            evidenceOptions: [],
            evidenceOption: "Upload File",
            form: {
                label: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                evidenceTypeId: {
                    validation: {
                        required: true,
                    },
                    value: this.props.evidenceTypes && this.props.evidenceTypes.length > 0 && this.props.evidenceTypes[0].id,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                evidenceType: {
                    validation: {
                        required: true,
                    },
                    value: this.props.evidenceTypes && this.props.evidenceTypes.length > 0 && this.props.evidenceTypes[0].name,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                completedOn: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                expiryOn: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                supplier: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                link: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                text: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                notes: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            fileName: null,
            fileExtension: null,
            fileData: null,
            isFormValid: false,
        };

        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        const evidenceOptions = [
            { value: "Upload File", label: this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceUploadFile", "Upload File") },
            { value: "Specify Link", label: this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceSpecifyLink", "Specify Link") },
            { value: "Description in the Notes", label: this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceDescriptionNotes", "Description in the Notes") },
        ];

        this.setState({ evidenceOptions });

        const { selectedSkills, selectedLors } = this.state;
        const { defaultSelectedSkill, defaultSelectedSkills, defaultSelectedLor, defaultSelectedLors } = this.props;

        if (defaultSelectedSkill) {
            this.setState({ selectedSkills: [...selectedSkills, defaultSelectedSkill] });
        } else if (defaultSelectedSkills) {
            this.setState({ selectedSkills: [...selectedSkills, ...defaultSelectedSkills] });
        }

        if (defaultSelectedLor) {
            this.setState({ selectedLors: [...selectedLors, defaultSelectedLor] });
        }
        if (defaultSelectedLors) {
            this.setState({ selectedLors: [...selectedLors, ...defaultSelectedLors] });
        }
    }

    handleChangeEvidenceOption = (evidenceOption) => {
        this.setState({ evidenceOption });

        let updateState = changeInput(this.state.form, "label", this.state.form.label.value);

        updateState.form.link.valid = true;
        updateState.form.link.isValidFormat = true;

        updateState.form.notes.valid = true;
        updateState.form.notes.isValidFormat = true;

        if (evidenceOption == "Specify Link") {
            updateState = changeInput(updateState.form, "link", updateState.form.link.value);
        } else if (evidenceOption == "Description in the Notes") {
            updateState = changeInput(updateState.form, "notes", updateState.form.notes.value);
        }

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        let updateState = changeInput(this.state.form, name, value);

        if (name == "evidenceTypeId") {
            const evidenceType = this.props.evidenceTypes.find((it) => it.id == value);
            if (evidenceType) {
                updateState = changeInput(updateState.form, "evidenceType", evidenceType.name);

                if (this.state.evidenceOption == "Specify Link") {
                    if (updateState.form.link.value == "" || updateState.form.link.value == null) {
                        updateState.form.link.valid = false;
                        updateState.form.link.isValidFormat = false;

                        updateState.valid = false;
                    }
                } else if (this.state.evidenceOption == "Description in the Notes") {
                    if (updateState.form.notes.value == "" || updateState.form.notes.value == null) {
                        updateState.form.notes.valid = false;
                        updateState.form.notes.isValidFormat = false;

                        updateState.valid = false;
                    }
                }
            }
        } else if (name == "notes") {
            if (this.state.evidenceOption == "Description in the Notes") {
                if (updateState.form.notes.value == "" || updateState.form.notes.value == null) {
                    updateState.form.notes.valid = false;
                    updateState.form.notes.isValidFormat = false;

                    updateState.valid = false;
                }
            }
        } else if (name == "link") {
            if (this.state.evidenceOption == "Specify Link") {
                if (updateState.form.link.value == "" || updateState.form.link.value == null) {
                    updateState.form.link.valid = false;
                    updateState.form.link.isValidFormat = false;

                    updateState.valid = false;
                }
            }
        }

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleChangeDate(name, value) {
        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    }

    setEvidenceFile = (evidenceFile) => {
        const extension = evidenceFile.name.split(".").pop().toLowerCase();
        toBase64(evidenceFile).then((result) => {
            const fileData = result.split(",")[1];
            this.setState({ fileName: evidenceFile.name, fileData, fileExtension: extension });
        });
    };

    handleSkillCheckedChange = (skill, skillCode, level) => {
        let selectedSkills = this.state.selectedSkills;
        const { categories } = this.props;

        const selectedSkill = {
            skillLevelId: null,
            skill,
            skillCode,
            level,
        };

        if (selectedSkills.find((it) => it.skill == skill && it.skillCode == skillCode && it.level == level)) {
            const unSelectedSkill = selectedSkills.find((it) => it.skill == skill && it.skillCode == skillCode && it.level == level);
            const index = selectedSkills.indexOf(unSelectedSkill);
            if (index !== -1) {
                selectedSkills.splice(index, 1);
            }

            this.setState({ selectedSkills });
        } else {
            const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((it) => it.name == skill && it.skillsCode == skillCode)));
            const categorySubCategory = category && category.subCategories.find((it) => it.skills.some((it) => it.name == skill && it.skillsCode == skillCode));
            const categorySkill = categorySubCategory && categorySubCategory.skills.find((it) => it.name == skill && it.skillsCode == skillCode);
            const skillLevel = categorySkill && categorySkill.levels.find((it) => it.level == level);

            if (skillLevel) {
                selectedSkill.skillLevelId = skillLevel.id;
            }

            this.setState({ selectedSkills: [...selectedSkills, selectedSkill] });
        }
    };

    handlelorCheckedChange = (lor, lorCode, level) => {
        let selectedLors = this.state.selectedLors;
        const { lors } = this.props;

        const selectedLor = {
            lorLevelId: null,
            lor,
            lorCode,
            level,
        };

        if (selectedLors.find((it) => it.lor == lor && it.lorCode == lorCode && it.level == level)) {
            const unSelectedLor = selectedLors.find((it) => it.lor == lor && it.lorCode == lorCode && it.level == level);
            const index = selectedLors.indexOf(unSelectedLor);
            if (index !== -1) {
                selectedLors.splice(index, 1);
            }

            this.setState({ selectedLors });
        } else {
            const lorLevel = lors.find((it) => it.name == lor && it.lorCode == lorCode).levels.find((it) => it.levelNumber == level);

            if (lorLevel) {
                selectedLor.lorLevelId = lorLevel.id;
            }

            this.setState({ selectedLors: [...selectedLors, selectedLor] });
        }
    };

    submitForm = () => {
        const { form, evidenceOption, selectedSkills, selectedLors } = this.state;
        const evidence = {
            personEmail: this.props.managedPeople && this.props.managedPeople.personB.email,
            evidenceTypeId: form.evidenceTypeId.value,
            evidenceType: form.evidenceType.value,
            label: form.label.value,
            link: evidenceOption == "Specify Link" ? form.link.value : null,
            expiryOn: form.expiryOn.value,
            fileName: this.state.fileName,
            fileData: this.state.fileData,
            lorLevels: selectedLors,
            skillLevels: selectedSkills,
            text: form.text.value,
            notes: form.notes.value,
            completedOn: form.completedOn.value,
            supplier: form.supplier.value,
        };

        this.setState({ submitInProgress: true });

        Promise.resolve(this.props.managedPeople ? this.props.onEvidence.addManagedPeopleEvidence(evidence) : this.props.onEvidence.addEvidence(evidence)).then(() => {
            if (!this.props.updateInProgress) {
                if (this.props.error) {
                    this.props.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    this.props.handleEvidenceAdded(this.props.addedEvidence);
                }
            }
            this.setState({ submitInProgress: this.props.updateInProgress });
        });
    };

    render() {
        const { form, selectedSkills, selectedLors } = this.state;
        const { lors, categories, skillProfiles, lorProfiles, defaultSelectedSkill, defaultSelectedLor, showHiddenSkills } = this.props;

        const availableLorCodes = lors
            ? lors
                  .filter((it) => !it.isHidden || showHiddenSkills)
                  .flatMap((lor, index) => {
                      return lor.lorCode;
                  })
            : [];

        const availableSkillCodes = categories
            ? categories.flatMap((category, index) => {
                  return category.subCategories.flatMap((subCategory, index) => {
                      return subCategory.skills
                          .filter((it) => !it.isHidden || showHiddenSkills)
                          .flatMap((skill, index) => {
                              return skill.skillsCode;
                          });
                  });
              })
            : [];

        return (
            <Container style={wrapperStyle}>
                {this.state.submitInProgress ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <Form>
                            <Row xs={1} sm={1} md={1} lg={3}>
                                <Col xs={12} sm={12} md={6} lg={6} className="p-1">
                                    <FormGroup>
                                        <Label className="font-weight-bold" for="lblLabel">
                                            <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceLabel" contentText="Label" />
                                        </Label>
                                        <Input type="text" name="label" id={"label"} value={form.label.value} invalid={form.label.touched && !form.label.valid} onChange={this.handleChange} />
                                    </FormGroup>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} className="p-1">
                                    <FormGroup>
                                        <Label className="font-weight-bold" for="lblType">
                                            <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceType" contentText="Type" />
                                        </Label>
                                        <Combobox
                                            data={this.props.evidenceTypes}
                                            onSelect={(value) => {
                                                this.handleChange({
                                                    target: {
                                                        name: "evidenceTypeId",
                                                        value: value.id,
                                                    },
                                                });
                                            }}
                                            onChange={() => {}}
                                            textField="name"
                                            dataKey="name"
                                            defaultValue={this.props.evidenceTypes[0]}
                                            style={{ fontSize: "14px", fontFamily: "IBMPlexSans" }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row xs={1} sm={1} md={1} lg={3}>
                                <Col xs={12} sm={12} md={6} lg={6} className="p-1">
                                    <FormGroup>
                                        <Label className="font-weight-bold" for="lblEvidence">
                                            <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceEvidence" contentText="Evidence" />
                                        </Label>
                                        <Combobox
                                            data={this.state.evidenceOptions}
                                            onSelect={(value) => {
                                                this.handleChangeEvidenceOption(value.value);
                                            }}
                                            onChange={() => {}}
                                            textField="label"
                                            dataKey="value"
                                            defaultValue={this.state.evidenceOption}
                                            style={{ fontSize: "14px", fontFamily: "IBMPlexSans" }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} className="p-1">
                                    <FormGroup>
                                        <Label className="font-weight-bold" for="lblSupplier">
                                            <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceSupplier" contentText="Supplier" />
                                        </Label>
                                        <Input type="text" name="supplier" id={"supplier"} value={form.supplier.value} onChange={this.handleChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row xs={1} sm={1} md={1} lg={3}>
                                <Col xs={12} sm={12} md={6} lg={6} className="p-1">
                                    <FormGroup>
                                        <Label className="font-weight-bold" for="lblCompletedOn">
                                            <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceCompletedOn" contentText="Completed On" />
                                        </Label>
                                        <DatePicker selected={form.completedOn.value ? new Date(form.completedOn.value) : null} dateFormat="dd/MM/yyyy" onChange={(value) => this.handleChangeDate("completedOn", value)} className="form-control" isClearable />
                                    </FormGroup>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} className="p-1">
                                    <FormGroup>
                                        <Label className="font-weight-bold" for="lblExpiryOn">
                                            <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceExpiresOn" contentText="Expires On" />
                                        </Label>
                                        <DatePicker selected={form.expiryOn.value ? new Date(form.expiryOn.value) : null} dateFormat="dd/MM/yyyy" onChange={(value) => this.handleChangeDate("expiryOn", value)} className="form-control" isClearable />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row xs={1} sm={1} md={1} lg={3} style={{ display: this.state.evidenceOption == "Upload File" ? "" : "none" }}>
                                <Col xs={12} sm={12} md={6} lg={12} className="p-1">
                                    <FormGroup>
                                        <Label className="font-weight-bold" for="lblEvidenceFile">
                                            <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceFile" contentText="Evidence File" />
                                        </Label>
                                        <Input type="file" name="file" id="EvidenceFile" onChange={(e) => this.setEvidenceFile(e.target.files[0])} />
                                        <UncontrolledTooltip target={`EvidenceFile`} placement="bottom" autohide={false}>
                                            <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceFileTooltip" contentText="Choose the Evidence file" />
                                        </UncontrolledTooltip>
                                        {this.state.fileData && this.state.fileExtension && forbiddenExtensions.includes(this.state.fileExtension) && <span style={{ color: "#ff0000", fontSize: "12px" }}>For security this file type is not allowed</span>}
                                    </FormGroup>
                                </Col>
                            </Row>
                            {this.state.evidenceOption == "Specify Link" && (
                                <Row xs={1} sm={1} md={1} lg={3}>
                                    <Col xs={12} sm={12} md={6} lg={12} className="p-1">
                                        <FormGroup>
                                            <Label className="font-weight-bold" for="lblLink">
                                                <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceLink" contentText="Link" />
                                            </Label>
                                            <Input type="text" name="link" id={"link"} value={form.link.value} invalid={form.link.touched && !form.link.valid} onChange={this.handleChange} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col xs={12} sm={12} className="p-1">
                                    <FormGroup>
                                        <Label className="font-weight-bold">
                                            <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceNotes" contentText="Notes" />
                                        </Label>
                                        <Input type="textarea" name="notes" id={"notes"} value={form.notes.value} invalid={form.notes.touched && !form.notes.valid} onChange={this.handleChange} />
                                        {this.state.evidenceOption == "Description in the Notes" && form.notes.touched && form.notes.value && (form.notes.value.length < 30 || form.notes.value.length > 5000) && <span style={{ color: "#ff0000", fontSize: "12px" }}>Min 30, max 5000 characters</span>}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="p-1">
                                    <FormGroup>
                                        <Label className="font-weight-bold" for="exampleCheck" check>
                                            <span>
                                                <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceApplicableGAs" contentText="Applicable GAs" />
                                            </span>
                                        </Label>
                                        <div>
                                            {lorProfiles &&
                                                lorProfiles
                                                    .filter((it) => availableLorCodes.includes(it.lorCode))
                                                    .sort((a, b) => (a.lorCode + a.level > b.lorCode + b.level ? 1 : -1))
                                                    .map((lorProfile, index) => {
                                                        const lor = lors.find((it) => it.lorCode == lorProfile.lorCode);
                                                        const lorLevel = lor ? lor.levels.find((x) => x.levelNumber == lorProfile.level) : null;

                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div style={{ float: "left" }} id={`divChkLor${lorProfile.id}${lorProfile.lorCode}${lorProfile.level}`}>
                                                                    <CustomInput
                                                                        type="checkbox"
                                                                        className="custom-160"
                                                                        name={`chkLor${lorProfile.id}${lorProfile.lorCode}${lorProfile.level}`}
                                                                        id={`chkLor${lorProfile.id}${lorProfile.lorCode}${lorProfile.level}`}
                                                                        checked={!!selectedLors.find((it) => it.lorCode == lorProfile.lorCode && it.level == lorProfile.level)}
                                                                        label={`${lorProfile.lorCode}${lorProfile.level} - ${this.contentTranslator(this.props.contentTranslations, "Lors", lorProfile.lor, lorProfile.lor)} ${lorProfile.level}`}
                                                                        onChange={() => this.handlelorCheckedChange(lorProfile.lor, lorProfile.lorCode, lorProfile.level)}
                                                                        inline
                                                                    />
                                                                </div>
                                                                <UncontrolledTooltip target={`divChkLor${lorProfile.id}${lorProfile.lorCode}${lorProfile.level}`} placement="bottom">
                                                                    {lorLevel && <ContentTranslator page="LorLevels" name={`${lor.name} ${lorLevel.levelName} Description`} contentText={lorLevel.description} />}
                                                                </UncontrolledTooltip>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="p-1">
                                    <FormGroup>
                                        <Label className="font-weight-bold" for="exampleCheck" check>
                                            <span>
                                                <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceApplicableSkills" contentText="Applicable Skills" />
                                            </span>
                                        </Label>
                                        <div>
                                            {skillProfiles &&
                                                skillProfiles
                                                    .filter((it) => availableSkillCodes.includes(it.skillCode))
                                                    .sort((a, b) => (a.skillCode + a.level > b.skillCode + b.level ? 1 : -1))
                                                    .map((skillProfile, index) => {
                                                        const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((it) => it.name == skillProfile.skill && it.skillsCode == skillProfile.skillCode)));
                                                        const subCategory = category && category.subCategories.find((it) => it.skills.some((it) => it.name == skillProfile.skill && it.skillsCode == skillProfile.skillCode));
                                                        const skill = subCategory && subCategory.skills.find((it) => it.name == skillProfile.skill && it.skillsCode == skillProfile.skillCode);
                                                        const skillLevel = skill && skill.levels.find((it) => it.level == skillProfile.level);

                                                        let skillLevelDescription = skillLevel ? skillLevel.description : "";
                                                        skillLevelDescription = this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${skillProfile.skillCode} ${skillProfile.level} Description`, skillLevelDescription);

                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div id={`divChkSkill${skillProfile.id}${skillProfile.skillCode}${skillProfile.level}`} style={{ float: "left" }}>
                                                                    <CustomInput
                                                                        type="checkbox"
                                                                        className="custom-250"
                                                                        name={`chkSkill${skillProfile.id}${skillProfile.skillCode}${skillProfile.level}`}
                                                                        id={`chkSkill${skillProfile.id}${skillProfile.skillCode}${skillProfile.level}`}
                                                                        checked={!!selectedSkills.find((it) => it.skillCode == skillProfile.skillCode && it.level == skillProfile.level)}
                                                                        label={`${skillProfile.skillCode}${skillProfile.level} - ${this.contentTranslator(this.props.contentTranslations, "Skills", skillProfile.skill, skillProfile.skill)} ${skillProfile.level}`}
                                                                        onChange={() => this.handleSkillCheckedChange(skillProfile.skill, skillProfile.skillCode, skillProfile.level)}
                                                                        inline
                                                                    />
                                                                </div>
                                                                <UncontrolledTooltip
                                                                    target={`divChkSkill${skillProfile.id}${skillProfile.skillCode}${skillProfile.level}`}
                                                                    placement="bottom"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: skillLevelDescription,
                                                                    }}
                                                                ></UncontrolledTooltip>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col xs="auto">
                                    <Button
                                        style={{ width: "135px", height: "36px", fontSize: "14px" }}
                                        color="primary"
                                        className={`btn btn-primary ${
                                            this.state.isFormValid &&
                                            !(this.state.evidenceOption == "Upload File" && this.state.fileData && this.state.fileExtension && forbiddenExtensions.includes(this.state.fileExtension)) &&
                                            (selectedSkills.length > 0 || selectedLors.length > 0) &&
                                            (this.state.evidenceOption == "Description in the Notes"
                                                ? form.notes.value && form.notes.value.length >= 30 && form.notes.value.length <= 5000
                                                    ? true
                                                    : false
                                                : this.state.evidenceOption == "Upload File"
                                                ? this.state.fileData
                                                    ? true
                                                    : false
                                                : form.link.value
                                                ? true
                                                : false)
                                                ? "btnPrimary-Style"
                                                : "btnDisabled-Style"
                                        }`}
                                        disabled={
                                            !(
                                                this.state.isFormValid &&
                                                !(this.state.evidenceOption == "Upload File" && this.state.fileData && this.state.fileExtension && forbiddenExtensions.includes(this.state.fileExtension)) &&
                                                (selectedSkills.length > 0 || selectedLors.length > 0) &&
                                                (this.state.evidenceOption == "Description in the Notes"
                                                    ? form.notes.value && form.notes.value.length >= 30 && form.notes.value.length <= 5000
                                                        ? true
                                                        : false
                                                    : this.state.evidenceOption == "Upload File"
                                                    ? this.state.fileData
                                                        ? true
                                                        : false
                                                    : form.link.value
                                                    ? true
                                                    : false)
                                            )
                                        }
                                        onClick={this.submitForm}
                                    >
                                        <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceButtonSaveEvidence" contentText="Save Evidence" />
                                    </Button>
                                    <Button style={{ width: "135px", height: "36px", fontSize: "14px", marginLeft: "10px" }} className="btn btn-light btnSecondary-Style" onClick={this.props.handleClose}>
                                        <ContentTranslator page="SfiaAll" name="Cancel" contentText="Cancel" />
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </React.Fragment>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.evidence.loading,
        error: state.evidence.error,
        evidenceTypes: state.evidence.evidenceTypes,
        updateInProgress: state.evidence.updateInProgress,
        addedEvidence: state.evidence.updatedEvidence,

        managedPeople: state.auth.managedPeople,
        contentTranslations: state.content.contentTranslations,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onEvidence: bindActionCreators(evidenceActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewEvidenceForm);
