import { Layout } from "../Layout";
import React from "react";
import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = ({ component: Component, notLayout, isAuthenticated, authMode, disconnectSignalR, language, setCurrentLanguage, redirectUrl, isLogout, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isAuthenticated) {
                return <Redirect to={{ pathname: "/", state: { redirectUrl: `${props.location.pathname}${props.location.search ? props.location.search : ""}` } }} />;
            } else {
                if (redirectUrl && !isLogout) {
                    localStorage.setItem("redirectUrl", "");
                    localStorage.setItem("isLogout", "FALSE");
                    return <Redirect to={redirectUrl} />;
                } else {
                    return notLayout ? (
                        <Component {...props} />
                    ) : (
                        <Layout disconnectSignalR={disconnectSignalR} language={language} setCurrentLanguage={setCurrentLanguage}>
                            <Component {...props} />
                        </Layout>
                    );
                }
            }
        }}
    />
);

export default ProtectedRoute;
