import * as actionTypes from "../actions/actionTypes";
import { updateState } from "../utility";

const initialState = {
    categories: [],
    error: null,
    loading: false,
    exportData: null,
    loadingExport: false,
    isImporting: false,
    isUpdating: false,
    extraFrameworkCapabilities: [],
    extraFrameworkLevels: [],
};

const getExtraFrameworkCapabilitiesStart = (state) => {
    return updateState(state, { error: null, extraFrameworkCapabilities: [], loading: true });
};

const getExtraFrameworkCapabilitiesSuccess = (state, action) => {
    return updateState(state, {
        extraFrameworkCapabilities: action.extraFrameworkCapabilities,
        error: null,
        loading: false,
    });
};

const getExtraFrameworkCapabilitiesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getExtraFrameworkLevelsStart = (state) => {
    return updateState(state, { error: null, extraFrameworkLevels: [], loading: true });
};

const getExtraFrameworkLevelsSuccess = (state, action) => {
    return updateState(state, {
        extraFrameworkLevels: action.extraFrameworkLevels,
        error: null,
        loading: false,
    });
};

const getExtraFrameworkLevelsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getCategoriesStart = (state) => {
    return updateState(state, { error: null, categories: [], loading: true });
};

const getCategoriesSuccess = (state, action) => {
    const categoryOrders = [
        {
            category: "Strategy and architecture",
            order: 1,
        },
        {
            category: "Change and transformation",
            order: 2,
        },
        {
            category: "Development and implementation",
            order: 3,
        },
        {
            category: "Delivery and operation",
            order: 4,
        },
        {
            category: "People and skills",
            order: 5,
        },
        {
            category: "Relationship and engagement",
            order: 6,
        },
    ];

    let categories = action.categories;
    categories.forEach((category) => {
        const categoryOrder = categoryOrders.find((it) => it.category.trim().toLowerCase() == category.name.trim().toLowerCase());
        if (categoryOrder) {
            category.order = categoryOrder.order + 100;
        } else {
            category.order = 999;
        }
    });

    return updateState(state, {
        categories: categories.sort((a, b) => a.order - b.order || a.oldId - b.oldId),
        error: null,
        loading: false,
    });
};

const getCategoriesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const addSkillAttributeStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const addSkillAttributeSuccess = (state, action) => {
    const categories = state.categories;

    const addedAttribute = action.addedAttribute;
    const category = categories.find((it) => it.id == action.categoryId);
    if (category) {
        const subCategory = category.subCategories.find((it) => it.id == action.subCategoryId);
        if (subCategory) {
            const skill = subCategory.skills.find((it) => it.id == action.skillId);
            if (skill) {
                if (skill.attributes) {
                    skill.attributes.push({
                        id: addedAttribute.id,
                        assessmentId: addedAttribute.assessmentId,
                        attribute: addedAttribute.attribute,
                        link: addedAttribute.link,
                        isHidden: addedAttribute.isHidden,
                    });
                } else {
                    skill.attributes = [
                        {
                            id: addedAttribute.id,
                            assessmentId: addedAttribute.assessmentId,
                            attribute: addedAttribute.attribute,
                            link: addedAttribute.link,
                            isHidden: addedAttribute.isHidden,
                        },
                    ];
                }
            }
        }
    }

    return updateState(state, {
        categories: categories,
        error: null,
        isUpdating: false,
    });
};

const addSkillAttributeFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateSkillAttributeStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateSkillAttributeSuccess = (state, action) => {
    const categories = state.categories;

    const updatedAttribute = action.updatedAttribute;
    const category = categories.find((it) => it.id == action.categoryId);
    if (category) {
        const subCategory = category.subCategories.find((it) => it.id == action.subCategoryId);
        if (subCategory) {
            const skill = subCategory.skills.find((it) => it.id == action.skillId);
            if (skill) {
                const attribute = skill.attributes.find((it) => it.id == updatedAttribute.id);
                if (attribute) {
                    attribute.attribute = updatedAttribute.attribute;
                    attribute.link = updatedAttribute.link;
                    attribute.isHidden = updatedAttribute.isHidden;
                }
            }
        }
    }

    return updateState(state, {
        categories: categories,
        error: null,
        isUpdating: false,
    });
};

const updateSkillAttributeFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateSkillSetIsHiddenStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateSkillSetIsHiddenSuccess = (state, action) => {
    const categories = state.categories;

    const isHidden = action.isHidden;

    const category = categories.find((it) => it.id == action.categoryId);
    if (category) {
        const subCategory = category.subCategories.find((it) => it.id == action.subCategoryId);
        if (subCategory) {
            const skill = subCategory.skills.find((it) => it.id == action.skillId);
            if (skill) {
                skill.isHidden = isHidden;
            }
        }
    }

    return updateState(state, {
        categories: categories,
        error: null,
        isUpdating: false,
    });
};

const updateSkillSetIsHiddenFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteSkillAttributeStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteSkillAttributeSuccess = (state, action) => {
    const categories = state.categories;

    const deletedAttributeId = action.deletedAttributeId;
    const category = categories.find((it) => it.id == action.categoryId);
    if (category) {
        const subCategory = category.subCategories.find((it) => it.id == action.subCategoryId);
        if (subCategory) {
            const skill = subCategory.skills.find((it) => it.id == action.skillId);
            if (skill) {
                const attribute = skill.attributes.find((it) => it.id == deletedAttributeId);
                if (attribute) {
                    var index = skill.attributes.indexOf(attribute);
                    if (index !== -1) {
                        skill.attributes.splice(index, 1);
                    }
                }
            }
        }
    }

    return updateState(state, {
        categories: categories,
        error: null,
        isUpdating: false,
    });
};

const deleteSkillAttributeFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const exportSkillAttributesDataExcelStart = (state) => {
    return updateState(state, { error: null, loadingExport: true });
};

const exportSkillAttributesDataExcelSuccess = (state, action) => {
    return updateState(state, {
        exportData: action.exportData,
        error: null,
        loadingExport: false,
    });
};

const exportSkillAttributesDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingExport: false,
    });
};

const importSkillAttributesDataExcelStart = (state) => {
    return updateState(state, { error: null, isImporting: true, message: null });
};

const importSkillAttributesDataExcelSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        isImporting: false,
    });
};

const importSkillAttributesDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isImporting: false,
    });
};

const exportSkillsHiddenDataExcelStart = (state) => {
    return updateState(state, { error: null, loadingExport: true });
};

const exportSkillsHiddenDataExcelSuccess = (state, action) => {
    return updateState(state, {
        exportData: action.exportData,
        error: null,
        loadingExport: false,
    });
};

const exportSkillsHiddenDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingExport: false,
    });
};

const importSkillsHiddenDataExcelStart = (state) => {
    return updateState(state, { error: null, isImporting: true, message: null });
};

const importSkillsHiddenDataExcelSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        isImporting: false,
    });
};

const importSkillsHiddenDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isImporting: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EXTRAFRAMEWORK_CAPABILITIES_START:
            return getExtraFrameworkCapabilitiesStart(state);
        case actionTypes.GET_EXTRAFRAMEWORK_CAPABILITIES_SUCCESS:
            return getExtraFrameworkCapabilitiesSuccess(state, action);
        case actionTypes.GET_EXTRAFRAMEWORK_CAPABILITIES_FAIL:
            return getExtraFrameworkCapabilitiesFail(state, action);

        case actionTypes.GET_EXTRAFRAMEWORK_LEVELS_START:
            return getExtraFrameworkLevelsStart(state);
        case actionTypes.GET_EXTRAFRAMEWORK_LEVELS_SUCCESS:
            return getExtraFrameworkLevelsSuccess(state, action);
        case actionTypes.GET_EXTRAFRAMEWORK_LEVELS_FAIL:
            return getExtraFrameworkLevelsFail(state, action);

        case actionTypes.GET_CATEGORIES_START:
            return getCategoriesStart(state);
        case actionTypes.GET_CATEGORIES_SUCCESS:
            return getCategoriesSuccess(state, action);
        case actionTypes.GET_CATEGORIES_FAIL:
            return getCategoriesFail(state, action);

        case actionTypes.ADD_SKILL_ATTRIBUTE_DATA_START:
            return addSkillAttributeStart(state);
        case actionTypes.ADD_SKILL_ATTRIBUTE_DATA_SUCCESS:
            return addSkillAttributeSuccess(state, action);
        case actionTypes.ADD_SKILL_ATTRIBUTE_DATA_FAIL:
            return addSkillAttributeFail(state, action);

        case actionTypes.UPDATE_SKILL_ATTRIBUTE_DATA_START:
            return updateSkillAttributeStart(state);
        case actionTypes.UPDATE_SKILL_ATTRIBUTE_DATA_SUCCESS:
            return updateSkillAttributeSuccess(state, action);
        case actionTypes.UPDATE_SKILL_ATTRIBUTE_DATA_FAIL:
            return updateSkillAttributeFail(state, action);

        case actionTypes.UPDATE_SKILL_SET_ISHIDDEN_START:
            return updateSkillSetIsHiddenStart(state);
        case actionTypes.UPDATE_SKILL_SET_ISHIDDEN_SUCCESS:
            return updateSkillSetIsHiddenSuccess(state, action);
        case actionTypes.UPDATE_SKILL_SET_ISHIDDEN_FAIL:
            return updateSkillSetIsHiddenFail(state, action);

        case actionTypes.DELETE_SKILL_ATTRIBUTE_DATA_START:
            return deleteSkillAttributeStart(state);
        case actionTypes.DELETE_SKILL_ATTRIBUTE_DATA_SUCCESS:
            return deleteSkillAttributeSuccess(state, action);
        case actionTypes.DELETE_SKILL_ATTRIBUTE_DATA_FAIL:
            return deleteSkillAttributeFail(state, action);

        case actionTypes.EXPORT_SKILL_ATTRIBUTES_DATA_START:
            return exportSkillAttributesDataExcelStart(state);
        case actionTypes.EXPORT_SKILL_ATTRIBUTES_DATA_SUCCESS:
            return exportSkillAttributesDataExcelSuccess(state, action);
        case actionTypes.EXPORT_SKILL_ATTRIBUTES_DATA_FAIL:
            return exportSkillAttributesDataExcelFail(state, action);

        case actionTypes.IMPORT_SKILL_ATTRIBUTES_DATA_START:
            return importSkillAttributesDataExcelStart(state);
        case actionTypes.IMPORT_SKILL_ATTRIBUTES_DATA_SUCCESS:
            return importSkillAttributesDataExcelSuccess(state, action);
        case actionTypes.IMPORT_SKILL_ATTRIBUTES_DATA_FAIL:
            return importSkillAttributesDataExcelFail(state, action);

        case actionTypes.EXPORT_SKILLS_HIDDEN_DATA_START:
            return exportSkillsHiddenDataExcelStart(state);
        case actionTypes.EXPORT_SKILLS_HIDDEN_DATA_SUCCESS:
            return exportSkillsHiddenDataExcelSuccess(state, action);
        case actionTypes.EXPORT_SKILLS_HIDDEN_DATA_FAIL:
            return exportSkillsHiddenDataExcelFail(state, action);

        case actionTypes.IMPORT_SKILLS_HIDDEN_DATA_START:
            return importSkillsHiddenDataExcelStart(state);
        case actionTypes.IMPORT_SKILLS_HIDDEN_DATA_SUCCESS:
            return importSkillsHiddenDataExcelSuccess(state, action);
        case actionTypes.IMPORT_SKILLS_HIDDEN_DATA_FAIL:
            return importSkillsHiddenDataExcelFail(state, action);

        default:
            return state;
    }
};

export default reducer;
