import { UpOutlined, UserOutlined, SolutionOutlined } from "@ant-design/icons";
import { Col, Row, Typography, Button, Badge, Empty } from "antd";
import React, { useEffect } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import styled from "styled-components";
import { useImmer } from "use-immer";

const { Title, Text } = Typography;

const DivCenter = ({ Component }) => {
  return (
    <div className="p-grid p-align-center vertical-container">
      <div className="layout-wrapper">
        <div className="p-grid">
          <div className="p-col-12">
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
};

const WarperNode = styled.div`
  display: inline-block;
  justify-content: space-between;

  padding: 20px;

  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0 10px 1px #dcdcdc;

  backdrop-filter: blur(15px);

  transition: all 0.5s ease-in-out;
  transform-origin: left top;
  animation: slide 0.5s;
  @keyframes slide {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;

const WarperIconExpand = styled.div`
  margin-bottom: -15px;
  margin-top: 15px;
`;

const WrapperTree = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;

  .rotate-180 {
    transform: rotate(180deg);
    transition: transform 0.5s;
  }
  .rotate-0 {
    transform: rotate(0deg);
    transition: transform 0.5s;
  }
`;
// Utility function to get expanded data from a node
const getExpandedData = (node) => ({
  isExpand: node?.isExpand ?? false,
  key: node.label,
});

const renderNode = (item, func, listExpand) => {
  // Check if the item has children for multiple conditions
  const hasChildren = item.children.length > 0;

  // Find the expand data for the current item
  const expandData = listExpand?.data?.find(
    (expand) => expand.key === item.label
  );

  return (
    <WarperNode>
      <Row
        gutter={{
          xs: 6,
          sm: 12,
          md: 18,
          lg: 24,
        }}
      >
        {/* Render the first column with Badge and Button if item has children */}
        {hasChildren && (
          <Col span={4} className="text-center">
            <div>
              <Badge
                offset={[-30, 35]}
                color="#3C65C2"
                count={
                  expandData?.isExpand === false ? item.children.length : 0
                }
              >
                <Button
                  onClick={() => func(item, expandData)}
                  type="dashed"
                  shape="circle"
                  icon={item.isRoot ? <UserOutlined /> : <SolutionOutlined />}
                />
              </Badge>
            </div>
          </Col>
        )}

        {/* Render the label of the node */}
        <Col
          style={{ marginLeft: "2px" }}
          span={hasChildren ? 19 : 24}
          className="text-center"
        >
          <div>
            {hasChildren ? (
              <Text strong={5}>{item.label}</Text>
            ) : (
              <span style={{ fontSize: "13px" }}>{item.label}</span>
            )}
          </div>
          {/* Display number of children if applicable */}
          {hasChildren && expandData?.isExpand && (
            <div className="mt-2" style={{ fontSize: "13px" }}>
              {item.children.length} {item.isRoot ? "Members" : "Skills"}
            </div>
          )}
        </Col>
      </Row>

      {/* Display the expand/collapse icon if item has children */}
      {hasChildren && (
        <WarperIconExpand className="text-center">
          <Button
            onClick={() => func(item, expandData)}
            type="link"
            shape="circle"
            icon={
              <UpOutlined
                className={!expandData?.isExpand ? "rotate-180" : "rotate-0"}
              />
            }
            size="small"
          />
        </WarperIconExpand>
      )}
    </WarperNode>
  );
};

const renderTreeNode = (data, func, listExpand) => {
  if (data.children.length < 1) return null;

  // Determine if current data should be expanded or not
  const expandData = listExpand?.data?.find(
    (expand) => expand.key === data.label
  );
  if (expandData?.isExpand === false) return null;

  // Render each child as a TreeNode
  return data.children.map((item, index) => (
    <TreeNode key={index} label={renderNode(item, func, listExpand)}>
      {renderTreeNode(item, null, listExpand)}
    </TreeNode>
  ));
};

const OrganizationalChart = ({ data }) => {
  const [listExpand, setListExpand] = useImmer({ data: [] });

  // Toggle expand state for nodes
  const onExpandedClicked = (node, expandData) => {
    if (!node.children.length) return;

    setListExpand((draft) => {
      if (node.isRoot && expandData.isExpand) {
        draft.data.forEach((item) => (item.isExpand = !expandData.isExpand));
      } else {
        const found = draft.data.find((item) => item.key === expandData.key);
        if (found) {
          found.isExpand = !expandData.isExpand;
        }
      }
    });
  };

  // Initialize the expand states based on provided data
  useEffect(() => {
    if (data) {
      const tempListExpand = [
        getExpandedData(data),
        ...data.children.map(getExpandedData),
      ];
      setListExpand((draft) => {
        draft.data = tempListExpand;
      });
    }
  }, [data]);

  // If no data, render an empty component
  if (!data)
    return (
      <DivCenter
        Component={() => (
          <Empty style={{ marginTop: "20px", marginBottom: "20px" }} />
        )}
      />
    );

  // Main organizational chart render
  return (
    <div className="text-center">
      <Title className="mt-3" level={4}>
        Our Team
      </Title>
      <WrapperTree>
        <Tree
          lineWidth={"1px"}
          lineColor={"#3C65C2"}
          lineBorderRadius={"10px"}
          label={renderNode(data, onExpandedClicked, listExpand)}
        >
          {renderTreeNode(data, onExpandedClicked, listExpand)}
        </Tree>
      </WrapperTree>
    </div>
  );
};

export default OrganizationalChart;
