import ContentTranslator from "../translator/ContentTranslator";
import React, { Component, useState } from "react";
import { Button, Col, CustomInput, Input, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row, Spinner } from "reactstrap";

const buttonActionStyle = {
    height: "55px",
    fontSize: 18,
    fontFamily: "IBMPlexSans-Bold",
    textTransform: "capitalize",
};

const answerStyle = {
    fontSize: 14,
    fontFamily: "IBMPlexSans",
};

class SurveyListVersion extends Component {
    static displayName = SurveyListVersion.name;

    constructor(props) {
        super(props);
        this.state = {
            stringVal: "",
            selectedValues: [],
        };
    }

    componentDidMount() {}

    setStringVal = (stringVal) => {
        this.setState({ stringVal });
    };

    handleSelect = (valueId, stringVal) => {
        const { selectedValues } = this.state;

        if (selectedValues.find((it) => it.valueId == valueId)) {
            const index = selectedValues.indexOf(selectedValues.find((it) => it.valueId == valueId));
            if (index !== -1) {
                selectedValues.splice(index, 1);
                this.setState({ selectedValues: [...selectedValues] });
                this.props.selectAnswers([...selectedValues]);
            }
        } else {
            this.setState({ selectedValues: [...selectedValues, { valueId, stringVal }] });
            this.props.selectAnswers([...selectedValues, { valueId, stringVal }]);
        }
    };

    render() {
        const { selectedValues } = this.state;

        const rows = this.props.answersData
            .filter((it) => !it.isHidden)
            .map((row, index) => {
                return (
                    <div key={index}>
                        {index === 0 ? "" : <div className="line mt-2 border-secondary"></div>}
                        <Row className="mt-0 align-items-center" xs="1" sm="1" md="2">
                            <Col sx="12" md="9">
                                <p className="color-dark mb-0" style={answerStyle}>
                                    {row.name != "<Enter StringVal>" ? (
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: this.props.contentTranslator(this.props.contentTranslations, "Values", `${row.id} Label`, row.label),
                                            }}
                                        />
                                    ) : (
                                        <Input type="text" name="stringVal" id="stringVal" value={this.state.stringVal} onChange={(e) => this.setStringVal(e.target.value)} />
                                    )}
                                </p>
                            </Col>
                            <Col sx="12" md="3">
                                {this.props.resultType == "Single" && (
                                    <button className="btn btn-block btn-primary" style={buttonActionStyle} disabled={this.props.inProgress} onClick={() => this.props.selectAnswer(row.id, this.state.stringVal)}>
                                        {this.props.inProgress && this.props.selectedAnswerId && this.props.selectedAnswerId == row.id ? <Spinner type="grow" size="md" color="light" /> : <ContentTranslator page="Question" name="SelectButton" contentText="Select" />}
                                    </button>
                                )}
                                {this.props.resultType == "Multiple" && (
                                    <div style={{ textAlign: "center" }}>
                                        <CustomInput checked={selectedValues.find((it) => it.valueId == row.id)} disabled={this.props.inProgress} type="checkbox" id={`chk${row.id}`} onChange={() => this.handleSelect(row.id, row.stringVal)} />
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                );
            });

        return rows;
    }
}

export default SurveyListVersion;
