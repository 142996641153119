import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Table, Tag, Tooltip, Typography, Empty } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";

const constantData = {
  titleLevel: 5,
  pageSize: 10,
  colors: {
    type1: "#69849c",
    type2: "#a5b8c8",
  },
};

const DivCenter = ({ Component, title }) => {
  return (
    <div className="p-grid p-align-center vertical-container">
      <div className="layout-wrapper">
        <Title level={constantData.titleLevel}>{title}</Title>
        <Component />
      </div>
    </div>
  );
};

const { Title } = Typography;

const WrapperTable = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;

  .ant-table-container table > thead > tr:first-child th {
    text-align: center;
  }

  .ant-table-container table tr .ant-table-cell-row-hover {
    background: rgb(116, 226, 177, 0.2) !important;
  }

  .mr-10 {
    margin-right: 10px;
  }
  .grid {
    display: grid;
  }
  .anticon {
    vertical-align: 1px !important;
  }

  @media (max-width: 1680px) {
    .cell-cm {
      width: 150px;
    }
  }

  @media (max-width: 1580px) {
    .cell-cm {
      width: 100px;
    }
  }

  @media (max-width: 1480px) {
    .cell-cm {
      width: 60px;
    }
  }
  @media (max-width: 1280px) {
    .cell-cm {
      width: auto;
    }
  }
`;

const WrapperSubCell = styled.div`
  width: auto;
  text-wrap: wrap;
`;

const createCenteredTextColumnHeader = (title) => (
  <span style={{ textWrap: "wrap" }}>{title}</span>
);

// Helper function to generate a table column that centers its text content.
// This avoids repetition for multiple columns that require similar formatting.
const createCenteredTextColumn = (
  title,
  dataIndex,
  sorterFunction,
  isResponsive = false
) => ({
  title: createCenteredTextColumnHeader(title), // Column header
  dataIndex, // Data key
  key: dataIndex, // Key for React's list rendering
  sorter: (a, b) => sorterFunction(a[dataIndex], b[dataIndex]), // Sorting function for the column
  render: (text) => (
    <WrapperSubCell className={`text-center ${isResponsive && "cell-cm"}`}>
      {text ?? "-"}
    </WrapperSubCell>
  ), // Centering the text
});

// Utility function to compare text values
const sortByText = (a, b) => (a ?? "").localeCompare(b ?? "");

// Utility function to transform data source for tables
// This maps over the data and transforms it to have keys and all the item properties.
const transformDataSource = (data) =>
  data.map((item, index) => ({
    key: `${index + 1}`,
    ...item,
  }));

// Utility function to generate column definitions based on type and need status
// This will return the appropriate columns based on whether it's a professional or generic skill and if it's a needed skill.
const getColumnDefinitions = (isNeedSkill, type, isResponsive) => {
  const commonSkillLevelColumn = {
    title: createCenteredTextColumnHeader("Skill Level"),
    dataIndex: "skillLevel",
    key: "skillLevel",
    sorter: (a, b) =>
      sortByText(`${a.skillCode} ${a.level}`, `${b.skillCode} ${b.level}`),
    render: (text, record) => (
      <div className="text-center text-nowrap">
        <Tooltip title={`${record.skill} Level ${record.level}`}>
          <Tag
            color={record.subCategoryDetail.colour}
            style={{ color: "black" }}
          >
            {`${record.skillCode} ${record.level}`}
          </Tag>
        </Tooltip>
      </div>
    ),
  };

  if (type === "professional") {
    return isNeedSkill
      ? [
          createCenteredTextColumn("Requirement Name", "jobName", sortByText),
          createCenteredTextColumn("Reference", "jobRef", sortByText),
          createCenteredTextColumn("Sub-Category", "subCategory", sortByText),
          commonSkillLevelColumn,
          {
            title: createCenteredTextColumnHeader("Need Version"),
            dataIndex: "needVersion",
            key: "needVersion",
            sorter: (a, b) =>
              sortByText(`${a.need} ${a.version}`, `${b.need} ${b.version}`),
            render: (text, record) => (
              <div className="text-center text-nowrap">
                <Tag style={{ color: "black" }}>
                  {`${record.need} ${record.version}`}
                </Tag>
              </div>
            ),
          },
        ]
      : [
          createCenteredTextColumn("Full Name", "fullName", sortByText),
          createCenteredTextColumn("Email", "email", sortByText, isResponsive),
          {
            title: createCenteredTextColumnHeader("Profile Status"),
            dataIndex: "profileStatus",
            key: "profileStatus",
            sorter: (a, b) => sortByText(a.profileStatus, b.profileStatus),
            render: (text, record) => (
              <div className="text-center">
                <span className="mr-10">{record.profileType}</span>
                <Tag
                  icon={
                    record.isApproved ? (
                      <CheckCircleOutlined />
                    ) : (
                      <CloseCircleOutlined />
                    )
                  }
                  color={record.isApproved ? "success" : "error"}
                >
                  {record.isApproved ? "Approved " : "Not Approved "}
                </Tag>
              </div>
            ),
          },
          commonSkillLevelColumn,
          createCenteredTextColumn("Capability", "capability", sortByText),
        ];
  } else if (type === "generic") {
    // generic type
    return isNeedSkill
      ? [
          createCenteredTextColumn("Requirement Name", "jobName", sortByText),
          createCenteredTextColumn("Reference", "jobRef", sortByText),
          createCenteredTextColumn(
            "Generic Attributes (GA)",
            "lor",
            sortByText
          ),
          {
            ...commonSkillLevelColumn,
            dataIndex: "lorCode",
            key: "lorCode",
            sorter: (a, b) =>
              sortByText(`${a.lorCode} ${a.level}`, `${b.lorCode} ${b.level}`),
            render: (text, record) => (
              <div className="text-center text-nowrap">
                <Tooltip title={`${record.lor} Level ${record.level}`}>
                  <Tag
                    color={constantData.colors.type2}
                    style={{ color: "black" }}
                  >
                    {`${record.lorCode} ${record.level}`}
                  </Tag>
                </Tooltip>
              </div>
            ),
          },
        ]
      : [
          createCenteredTextColumn("Full Name", "fullName", sortByText),
          createCenteredTextColumn("Email", "email", sortByText),
          createCenteredTextColumn(
            "Generic Attributes (GA)",
            "lor",
            sortByText
          ),
          {
            title: createCenteredTextColumnHeader("Skill Level"),
            dataIndex: "skillLevel",
            key: "skillLevel",
            sorter: (a, b) =>
              sortByText(
                `${a.skillCode || a.lorCode} ${a.level}`,
                `${b.skillCode || b.lorCode} ${b.level}`
              ),
            render: (text, record) => (
              <div className="text-center text-nowrap">
                <Tooltip
                  title={`${record.skill || record.lor} Level ${record.level}`}
                >
                  <Tag
                    color={record?.subCategoryDetail?.colour}
                    style={{ color: "black" }}
                  >
                    {`${record.skillCode || record.lorCode} ${record.level}`}
                  </Tag>
                </Tooltip>
              </div>
            ),
          },
        ];
  }
};

// Component to render the table with a title
// This abstracts the rendering of the table with a title to avoid repeating the same JSX structure.
const RenderTable = ({
  title,
  dataSource,
  columns,
  pageSize,
  isSpilt,
  isNeedSkill,
}) => (
  <>
    <Title level={constantData.titleLevel}>
      {title}{" "}
      {isSpilt && (
        <span className="vs-h-sm-h">
          ({isNeedSkill ? "Skill We Need" : "Skill We Have"})
        </span>
      )}
    </Title>
    <WrapperTable>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize }}
        scroll={{ x: true }}
      />
    </WrapperTable>
  </>
);

const SkillsWeHaveNeedTableNormal = ({
  data = null,
  isNeedSkill = false,
  isShowProfessional = false,
  isShowGeneric = false,
  isSpilt = false,
  pageSize = constantData.pageSize,
  isResponsive = false,
}) => {
  // Memoized calculation for professional table data
  const tableProfessional = useMemo(() => {
    // If not showing professional or no data, return empty data.
    if (!isShowProfessional || !data.professional)
      return { columns: [], dataSource: [] };

    // Return the transformed data source and the appropriate columns
    return {
      dataSource: transformDataSource(data.professional),
      columns: getColumnDefinitions(isNeedSkill, "professional", isResponsive),
    };
  }, [data, isShowProfessional]);

  // Memoized calculation for generic table data
  const tableGeneric = useMemo(() => {
    // If not showing generic or no data, return empty data.
    if (!isShowGeneric || !data.generic) return { columns: [], dataSource: [] };

    // Return the transformed data source and the appropriate columns
    return {
      dataSource: transformDataSource(data.generic),
      columns: getColumnDefinitions(isNeedSkill, "generic", isResponsive),
    };
  }, [data, isShowGeneric]);

  return (
    <div>
      {isShowProfessional &&
        (tableProfessional.dataSource.length > 0 ? (
          // Render the professional table
          <RenderTable
            title="Professional Skills"
            {...tableProfessional}
            pageSize={pageSize}
            isSpilt={isSpilt}
            isNeedSkill={isNeedSkill}
          />
        ) : (
          <DivCenter
            title="Professional Skills"
            Component={() => <Empty description={"No Data"} />}
          />
        ))}
      {isShowGeneric &&
        (tableGeneric.dataSource.length > 0 ? (
          // Render the generic table
          <RenderTable
            title="Generic Attributes"
            {...tableGeneric}
            pageSize={pageSize}
            isSpilt={isSpilt}
            isNeedSkill={isNeedSkill}
          />
        ) : (
          <DivCenter
            title="Generic Attributes"
            Component={() => <Empty description={"No Data"} />}
          />
        ))}
    </div>
  );
};

export { SkillsWeHaveNeedTableNormal };
