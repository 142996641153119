import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Row, Col } from "antd";
import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";

const ContainerConfirm = styled.div`
  padding: 5px;
  width: max-content;
  .btn-success {
    border-color: mediumseagreen;
    background: mediumseagreen;
  }

  .justify-content-right {
    justify-content: right;
  }
`;

// Wrapping the component with React.memo to prevent unnecessary re-renders.
// This is useful when the parent component renders often but props for ConfirmModal don't change.
const ConfirmModal = ({
  open,
  onOk,
  onCancel,
  msg,
  okText,
  cancelText,
  titleText = null,
  isCancel = true,
  titleNode = null,
  questionText = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // Using useCallback to memoize the handler functions.
  // This ensures that the same function reference is used across re-renders
  // unless the dependencies (onOk, onCancel) change.
  const handleOk = useCallback(
    (e) => {
      onOk?.(e); // Optional chaining ensures that onOk is called only if it's provided.
      // Removed setIsOpen as we are now directly using the 'open' prop for controlling visibility.
      setIsOpen(false);
    },
    [onOk] // Dependency array for useCallback.
  );

  const handleCancel = useCallback(
    (e) => {
      onCancel?.(e); // Similar to handleOk, using optional chaining for onCancel.
      // Removed setIsOpen for the same reason as above.
      setIsOpen(false);
    },
    [onCancel] // Dependency array for useCallback.
  );

  const TitleRender = () => titleNode;

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  // Return null to render nothing if the modal is not open.
  // This is an early return pattern, a common optimization in React for conditional rendering
  if (!isOpen) return null;

  return (
    // ContainerConfirm is a wrapper component, likely styled, for the modal content.
    <ContainerConfirm>
      {/* Row is a layout component, probably from a UI library, for arranging items in a row.
          The 'gutter' prop likely adds spacing between the columns in the row. */}
      <Row gutter={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
        {/* Col is a layout component for a column within a row. */}
        <Col>
          {/* ExclamationCircleOutlined is an icon component, styled with inline CSS.
              It is likely used here to draw attention to the modal's content. */}
          <ExclamationCircleOutlined
            style={{ color: "darkorange", fontSize: "32px" }}
          />
        </Col>
        {/* Another column, this time for text content. It's aligned to the left and has a fixed width. */}
        <Col className="text-left" style={{ width: "270px" }}>
          {titleText && (
            <p style={{ fontSize: "18px" }} className="font-weight-bold">
              {titleText}
            </p>
          )}
          {titleNode && <TitleRender />}
          {/* Span elements for text. The first span contains informational text. */}
          {(msg != undefined || msg != "") && <span>{msg} </span>}

          {/* The second span is bold, emphasizing the action's consequence or confirmation. */}
          <span> {questionText ?? "Do you want to proceed ?"}</span>
        </Col>
      </Row>
      {/* Another Row for action buttons, with increased gutter spacing and additional styling for centering and top margin. */}
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        className="mt-4 justify-content-right pl-4 pr-4"
      >
        {/* Button component for the 'OK' action, styled as a primary and success button, with a right margin.
            'handleOk' is a function that will be called when this button is clicked. */}
        <Button type="primary" onClick={handleOk} className="mr-3 btn-success">
          {okText ?? "Ok"}
        </Button>
        {isCancel && (
          <>
            {/* Another Button for the 'Cancel' action, styled as a primary and danger button.
            'handleCancel' is a function that will be called when this button is clicked. */}
            <Button type="primary" onClick={handleCancel} danger>              
              {cancelText ?? "Cancel"}
            </Button>
          </>
        )}
      </Row>
    </ContainerConfirm>
  );
};

export default ConfirmModal;
